import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-prompt-save-contact-modal',
  templateUrl: './prompt-save-contact-modal.component.html',
  styleUrls: ['./prompt-save-contact-modal.component.scss']
})
export class PromptSaveContactModalComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() title: string;
  @Input() message: string;
  @Input() btnTextCancel: string;
  @Input() btnTextConfirm: string;
  @Input() btnTextContinue: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();

  constructor() { } // end constructor()

  ngOnInit() {
    if (!this.title) {
      this.title = 'Contact already been added.';
    }

    if (!this.message) {
      this.message =
        'The contact you are trying to add, has been added already, please try search using cell or email.';
    }

    if (!this.btnTextCancel) {
      this.btnTextCancel = 'Ok';
    }
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onConfirm() {
    this.confirm.emit();
  } // end onConfirm()

  onContinue() {
    this.continue.emit();
  } // end onContinue()

} // end PromptSaveContactModalComponent{}
