import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { CollectionsDocumentDTOHandle } from '../../../services/notes/notes.service';
import { ClientLetterOfDemand, ClientLetterOfDemandView, DEFAULT_CLIENT_LETTER_OF_DEMAND, DEFAULT_CLIENT_LETTER_OF_DEMAND_VIEW, DEFAULT_DOCUMENT, DocumentServiceService, Documents } from '../../../services/documents/document-service.service';
import { DEFAULT_DOCOMENT_TYPE, DocumentTypes } from '../../../services/fee-items/fee-items.service';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { error } from 'console';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

interface FileDocHandle {
  data: File;
  documentType: DocumentTypes;
}

@Component({
  selector: 'app-collection-documents',
  templateUrl: './collection-documents.component.html',
  styleUrls: ['./collection-documents.component.scss']
})
export class CollectionDocumentsComponent implements OnInit {

  title = 'Collection (s) Documents attached to Advocate and Law Firm';

  // File upload
  showUploadDocumentErrorPrompt: boolean;
  selectedDocumentType = 0;
  selectedFile: any;
  showMatterDocumentDialog: boolean;
  documentTitle: string;
  selectedBriefId: number;
  selectedServiceId: number;
  dropzoneActive = false;
  showUploadModalDialog: boolean;
  documentName = '';
  attachmentFile: File;
  fileList: File[] = [];
  fileListHandle: FileDocHandle[] = [];
  attachment: string;
  showDeleteDocumentPrompt: boolean;
  selectedDocumentToDelete: string;
  showdocumentUploadErrorPrompt: boolean;
  selectedDocomentType: DocumentTypes = Object.assign({}, DEFAULT_DOCOMENT_TYPE);
  toDeleteDocumentTypes: Documents = Object.assign({}, DEFAULT_DOCUMENT);
  toEditDocumentTypes: Documents = Object.assign({}, DEFAULT_DOCUMENT);
  invoicelineCounter = 0;

  // edit document type for document
  showEditUploadModalDialog: boolean;
  ShowDiscountErrorMessage: boolean;
  onEXCLVATClick: boolean;
  clientLetterOfDemandView: ClientLetterOfDemandView;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

   // Is Practice Manager
   get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER); // &&
      // Boolean(this.api.serviceProviderID ===
      //   this.api.serviceProviderID));
  }  // end isPracticeManager()


  @Input() tabView: string;
  @Input() isVisible: boolean;
  @Input() otherDocuments: Documents[];
  @Input() documentTypes: DocumentTypes[];
  @Input() clientLetterOfDemandId: number;  
  @Input() clientLetterOfDemand: ClientLetterOfDemand;
  @Input() collectionsDocument: CollectionsDocumentDTOHandle[];
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('rateinput') RateInput: ElementRef;
  @ViewChild('inputDiscount') DiscountInput: ElementRef;
  @ViewChild('inputPaymentReceived') inputPaymentReceived: ElementRef;
  @ViewChild('inputAttachmentFile') inputAttachmentFile: ElementRef;
  @ViewChild('inputUploadFile') inputUploadFile: ElementRef;
  @ViewChild('dp', { static: false }) datepicker?: BsDaterangepickerDirective;

  downloadLink = this.api.apiURL + '/MatterDocument/CollectionDocument?collectionDocumentId=';
  lodDownloadLink = this.api.apiURL + '/LOD/LODDocument?LODDocumentId=';

  constructor(
    private api: ApiService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private documentsService: DocumentServiceService
  ) { } // end constructor()

  ngOnInit() {
    this.clientLetterOfDemandView = Object.assign({}, DEFAULT_CLIENT_LETTER_OF_DEMAND_VIEW);
    if(!this.tabView || this.isPracticeManager) {
      this.tabView === 'collections';
      this.title = 'Collection (s) Documents attached to Advocate and Law Firm';
    } else if (this.tabView === 'other-documents' || !this.isPracticeManager) {
      this.tabView = 'other-documents';
      this.title = 'Other Documents attached to Advocate and Law Firm';
      this.changeTabView(this.tabView);
    }
  } // end ngOnInit()

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  } // end dropzoneState()

  fileAttachmentChange(file) {
    // if (this.workingMatter && this.workingMatter.ServiceID) {
      if (this.getExtension(file.target.files[0].name) === 'pdf' || this.getExtension(file.target.files[0].name) === 'docx' ||
      this.getExtension(file.target.files[0].name) === 'msg') {
        this.attachmentFile = file.target.files[0];
        this.attachment = this.attachmentFile.name;
        this.documentName = 'Add / Attach document';
        this.showUploadModalDialog = true;
        } else {
          this.showdocumentUploadErrorPrompt = true;
        }
    //   } else {
    //     this.inputAttachmentFile.nativeElement.value = '';
    //     this.inputUploadFile.nativeElement.valie = '';
    //     this.showUploadDocumentErrorPrompt = true;
    // }
  } // end fileAttachmentChange()

  handleDrop(file: File) {
    // if (this.workingMatter && this.workingMatter.ServiceID) {
      if (this.getExtension(file[0].name) === 'pdf' || this.getExtension(file[0].name) === 'docx' ||
           this.getExtension(file[0].name) === 'msg') {
          this.attachmentFile = file[0];
          this.attachment = this.attachmentFile.name;
          this.documentName = 'Add / Attach document';
          this.showUploadModalDialog = true;
        // }
        } else {
          this.showdocumentUploadErrorPrompt = true;
        }
    // } else {
    //   this.inputAttachmentFile.nativeElement.value = '';
    //   this.inputUploadFile.nativeElement.valie = '';
    //   this.showUploadDocumentErrorPrompt = true;
    // }
  } // end handleDrop()

  onViewDocument(file: Documents) {
    this.selectedFile = file;
    this.documentTitle = file.DocumentType;
    // this.selectedServiceId = this.workingMatter.ServiceID;
    this.selectedBriefId = file.DocumentId;
    this.showMatterDocumentDialog = true;
  } // end onViewDocument()

  onFileRemove(fileType: string, file: Documents) {
    this.selectedDocumentToDelete = 'Are you sure you want to remove "' +
    fileType + '" from matter (' + this.title + ')?';
    this.toDeleteDocumentTypes = file;
    this.showDeleteDocumentPrompt = true;
  } // end onFileRemove()

  onFileEdit(fileType: string, file: Documents) {
    this.toEditDocumentTypes = file;
    this.documentName = 'Edit document type for ( ' +
      file.DocumentPath + ')';
    this.selectedDocumentType = this.documentTypes.filter(type => type.DocumentType === file.DocumentType)[0].ID;
    this.showEditUploadModalDialog = true;
  } // end onFileEdit()

  changeTabView(tabView: string) {
    this.tabView = tabView;
    if(this.tabView === 'collections') {
      this.title = 'Collection (s) Documents attached to Advocate and Law Firm';
    } else if (this.tabView === 'other-documents') {
      this.title = 'Other Documents attached to Advocate and Law Firm';
      if (this.clientLetterOfDemandId > 0) {
        // console.log('SELECTED DOCUMENTID: ', this.clientLetterOfDemandId);
        this.loadingService.showOverlay();
        this.clientLetterOfDemandView = Object.assign({}, DEFAULT_CLIENT_LETTER_OF_DEMAND_VIEW);
        this.documentsService.getClientLetterOfDemand(this.clientLetterOfDemandId).subscribe({ next: (_data) => {
          this.clientLetterOfDemandView = _data;
        },
        error:  (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete

          this.loadingService.hideOverlay();
        }
      });
      }
    }
  } // end changeTabView()

  dismiss() {
    this.closeModal.emit('collections-document');
  } // end dismiss()

  // getExtension(filename) {
  //   return filename.toString().split('.')[1];
  // } // end getExtension()

 getExtension(filename: string): string {
  const parts = filename.split('.');
  return parts[parts.length - 1].toLowerCase();
} // and getExtension()

  // Document Types
  onHideUploadModalDialog() {
    this.showUploadModalDialog = false;
  } // end onHideUploadModalDialog()

  onUploadFile(file) {
    this.showUploadModalDialog = false;
    this.closeModal.emit('collections-document');
  } // end onUploadFile()
} // end CollectionDocumentsComponent {}
