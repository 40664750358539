import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { ClientContact, DEFAULT_CLIENT_CONTACT } from '../../../services/client-contacts/client-contacts.service';
import { AdvocateDetailStatement } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
// import { TypeaheadMatch } from 'ngx-bootstrap';
import { UtilitiesService } from '../../../services/utilities.service';
import { MattersService, ServiceAttorney, DEFAULT_SERVICE_ATTORNEY } from '../../../services/matters/matters.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-attorney-update-modal',
  templateUrl: './attorney-update-modal.component.html',
  styleUrls: ['./attorney-update-modal.component.scss']
})
export class AttorneyUpdateModalComponent implements OnInit, OnChanges {
  title: string;
  selectedAttorney: ClientContact = Object.assign({}, DEFAULT_CLIENT_CONTACT);
  workingAttorneyData: AdvocateDetailStatement;
  serviceAttorney: ServiceAttorney = Object.assign({}, DEFAULT_SERVICE_ATTORNEY);

  @Input() isVisible: boolean;
  @Input() attorneys: ClientContact[];
  @Input() selectedAttorneyData: AdvocateDetailStatement;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() saveAttorney: EventEmitter<any> = new EventEmitter<any>();

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.workingAttorneyData, this.selectedAttorneyData);
  } // end isDirty()

  get isSaveable(): boolean {
    return this.isDirty;
  } // end isSaveable()

  constructor(
    private util: UtilitiesService,
    private matterService: MattersService,
    private loadingService: LoadingService,
  ) { } // end constructor()

  ngOnInit() {
    this.title = 'Edit Attorney';
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedAttorneyData && changes.selectedAttorneyData.currentValue) {
      this.initData();
    }
  } // end ngOnChanges()

  onSelect(event: TypeaheadMatch): void {
    this.serviceAttorney.ServiceId = this.selectedAttorneyData.ServiceId;
    this.serviceAttorney.Attorney = event.item.ContactID;
  } // end onSelect()

  dismiss() {
    this.closeModal.emit();
  } // end dismiss()

  save() {
    this.loadingService.showOverlay();
    this.matterService.updateAttorney(this.serviceAttorney).subscribe({
      next: (data) => {
        // On next
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.saveAttorney.emit(this.workingAttorneyData);
        this.closeModal.emit();
      }
    });
  } // end save()

  initData() {
    this.workingAttorneyData = this.util.objectCopy(this.selectedAttorneyData);
  } // end initData()

} // end AttorneyUpdateModalComponent{}
