import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddLawfirmAdvocate, AddLawfirmAttorneyService } from '../../../services/addlawfirm/add-lawfirm-attorney.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { LENGTH_LONG, Snack, SnackType, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { TAG_REQUEST_NEW_ADVOCATE_GCB_MODAL } from '../../../services/tags/tags.service';
import * as moment from 'moment';
import { AdvocateHandle, DEFAULT_REGIONALBAR, ExistingAdvocateList, ManageBlackListService, DateParameters } from '../../../services/manage-BlackList/manage-BlackList.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { event } from 'jquery';
import { toInt } from 'ngx-bootstrap/chronos/utils/type-checks';

@Component({
  selector: 'app-request-new-advocate-gcb-modal',
  templateUrl: './request-new-advocate-gcb-modal.component.html',
  styleUrls: ['./request-new-advocate-gcb-modal.component.scss']
})
export class RequestNewAdvocateGcbModalComponent implements OnInit {

  bsValue = new Date();
  @Input() isVisible: boolean;
  @Input() showAdvocateListTab: boolean;
  @Input() CurrentRegionalBar: string;
  @Input() RegionalBarList: any;
  @Input() isGCBUserBar: boolean;
  @Input() currentLawfirmId: number;
  @Output() closeModal: EventEmitter<string> = new EventEmitter();
  @Output() isDirectCloseAdvocateModal: EventEmitter<string> = new EventEmitter();
  @Output() selectedExstAdvocateName: EventEmitter<any> = new EventEmitter<any>();

  title = 'Add Advocate Request';
  addLawfirmAdvocate: AddLawfirmAdvocate = {
    LawfirmName: '',
    ContactNumber: '',
    EmailAddress: '',
    Cell: '',
    RegionalBar: '',
    ReportingAdvocateId: null,
    AdmissionDay: null,
    AdmissionMonth: null,
    AdmissionYear: null,
    //AdmissionDate: null,
    //AdmissionDateStr: ''
  };

  dateParameters: DateParameters = {
    AdmissionDay: null,
    AdmissionMonth: null,
    AdmissionYear: null
  };

  email = '';
  phoneNumber = '';
  cell = '';
  regionalBar = '';
  //admissionDate: Date;
  //admissionDateStr = '';
  advocateName = '';

  showUnsavedChangesPrompt: boolean;
  tabView: string;
  isValidAdvocateName = false;
  isValidRegionalBarName = false;
  isValidAdvocatePhoneNumber = false;
  isValidAdvocateEmail = false;
  isValidAdvocateCell = false;
  isLinkBtnEnable = false;
  isValidAdmissionMonth = false;
  isValidAdmissionYear = false;
  regionalBarName: string;
  regionalBarID: string;
  ExstAdvocateFullName: string;
  selectedExstAdvocateId: number;
  onSelectExstAdv: boolean = false;
  onSelecteRegionalBar: boolean = false;
  onSelectExstField: boolean = true;
  filterExstAdvocate: any;
  filterRegionalBars: any;
  exsistingAdvocateData: any[];
  advocatesHandle: AdvocateHandle[] = [];
  regionalBars: any;
  RegionalBarName: string;
  admissionDay: any[];
  admissionYear: any[];
  checkAdmissionMonth: string = '0';
  checkAdmissionYear: string = '0';
  checkAdmissionDay: string = '0';
  admissionDate: {
    day: string,
    month: string,
    year: string
  }

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private addLawfirmAttorneyService: AddLawfirmAttorneyService,
    private manageBlackListService: ManageBlackListService,
  ) {
    this.isGCBUserBar;
    this.showAdvocateListTab;
    this.isVisible;
  }

  get hasRegionalBarNameError(): boolean {
    return !this.util.hasTextValue(!this.isGCBUserBar ? this.CurrentRegionalBar : this.RegionalBarName);
  } // end hasRegionalBarNameError()

  get hasAdvocateNameError(): boolean {
    return !this.util.hasTextValue(this.ExstAdvocateFullName);
  } // end hasAdvocateNameError()

  get hasAdvocateEmailError(): boolean {
    return !this.util.isValidEmail(this.email);
  } // end hasAdvocateEmailError()

  get hasPhoneNumberError(): boolean {
    return !this.util.isValidPhoneNumber(this.phoneNumber,
      { code: this.userProfileService.userProfile.currencyDetails.code });
  } // end hasPhoneNumberError()

  get hasCellError(): boolean {
    return !this.util.isValidPhoneNumber(this.cell,
      { code: this.userProfileService.userProfile.currencyDetails.code });
  } // end hasCellError()

  get isSubmittable(): Boolean {
    return Boolean(!this.hasAdvocateNameError) && Boolean(!this.hasRegionalBarNameError) && Boolean(!this.hasPhoneNumberError) &&
      Boolean(!this.hasAdvocateEmailError) && Boolean(!this.hasCellError) && Boolean(!this.isValidAdmissionYear)
      && Boolean(!this.isValidAdmissionMonth)
  }

  ngOnInit(): void {
    this.admissionDay = [];
    for (let index = 1; index <= 31; index++) {
      this.admissionDay.push(index);
    }
    this.admissionYear = [];
    let years = moment().year();
    for (let index = 1960; index <= years; index++) {
      this.admissionYear.push({
        year: index
      });
    }
    this.admissionYear.sort((a, b) => (a.year < b.year ? 1 : -1));
  }

  ngOnChanges() {
    this.getExistingAdvocates();
    if (this.RegionalBarList) {
      this.regionalBars = this.RegionalBarList.sort((a, b) => {
        return a.FullName.localeCompare(b.FullName);
      });
      if (!this.isGCBUserBar) {
        this.CurrentRegionalBar = this.RegionalBarList[0].FullName
      }
      this.onLinkBtnEnable();
    }   
  }
  getExistingAdvocates() {
    this.exsistingAdvocateData = [];
    this.manageBlackListService.getExistingAdvocatesList().subscribe({
      next: (response) => {
        if (response) {
          this.exsistingAdvocateData = response;
          if (this.isGCBUserBar) {
            this.regionalBars.forEach(item => {
              this.advocatesHandle.push({
                data: item,
                state: {
                  isVisible: true,
                  isSelected: false,
                  isOpen: false
                }
              });
            });
          }
        }
      },
      complete: () => {
      }
    })
  }
  // send Request for advocate
  sendRequest() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Submitting new advocate request...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, LENGTH_LONG).show();
    this.addLawfirmAdvocate = {
      LawfirmName: this.ExstAdvocateFullName,
      ContactNumber: this.phoneNumber,
      EmailAddress: this.email,
      Cell: this.cell,
      RegionalBar: this.CurrentRegionalBar ? this.CurrentRegionalBar : this.RegionalBarName,
      ReportingAdvocateId: this.selectedExstAdvocateId,
      AdmissionDay: parseInt(this.checkAdmissionDay),
      AdmissionMonth: parseInt(this.checkAdmissionMonth),
      AdmissionYear: parseInt(this.checkAdmissionYear),
      //AdmissionDate: this.admissionDate,
      //AdmissionDateStr: this.admissionDateStr,
    };
    // if (this.admissionDate) {
    //   this.addLawfirmAdvocate.AdmissionDateStr = moment(this.admissionDate).format('YYYY-MM-DD');
    // }
    // else {
    //   this.addLawfirmAdvocate.AdmissionDateStr = null;
    // }
    // if (this.addLawfirmAdvocate.AdmissionDateStr == "Invalid date") {
    //   this.addLawfirmAdvocate.AdmissionDateStr = null;
    // }

    this.addLawfirmAttorneyService.addLawfirmAdvocate(this.addLawfirmAdvocate)
      .subscribe({
        next: (newAdvocate) => {
          console.log(newAdvocate);
        },
        error: (error) => {
          snack.label = 'Something went wrong';
          snack.action = null;
          snack.type = SnackType.ERROR;
          this.loadingService.hideOverlay();
          this.snackbarsService.dismiss().make(snack).show();
          console.error('Something went wrong: ', error);
        },
        complete: () => {
          snack.label = 'Request sent. Advocate will be added after review. ';
          this.clearInputs();
          this.snackbarsService.dismiss().make(snack).show();
          this.dismiss(false);
        }
      });
  } // end sendRequest()

  LinkExstAdvocate() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Please wait...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, LENGTH_LONG).show();
    this.manageBlackListService.LinkExstAdvocate(this.selectedExstAdvocateId, this.CurrentRegionalBar ? this.CurrentRegionalBar : this.RegionalBarName)
      .subscribe({
        next: (newAdvocate) => {
          console.log(newAdvocate);
          this.getExistingAdvocates();
        },
        error: (error) => {
          snack.label = 'Something went wrong';
          snack.action = null;
          snack.type = SnackType.ERROR;
          this.loadingService.hideOverlay();
          this.snackbarsService.dismiss().make(snack).show();
          console.error('Something went wrong: ', error);
        },
        complete: () => {
          this.clearInputs();
          snack.label = 'Advocate linked successfully. ';
          this.selectedExstAdvocateName.emit(this.addLawfirmAdvocate);
          this.snackbarsService.dismiss().make(snack).show();
          this.dismiss(false);
        }
      });
  }
  onBlurAdvocateName() {
    if (!this.util.hasTextValue(!this.isGCBUserBar ? this.CurrentRegionalBar : this.ExstAdvocateFullName)) {
      this.isValidAdvocateName = true;
    } else {
      this.isValidAdvocateName = false;
    }
    this.onLinkBtnEnable();
  } // end onBlurAdvocateName()

  onBlurRegionalBar(event) {
    if (!this.util.hasTextValue(this.RegionalBarName)) {
      if (this.onfilterRegionalBars(!this.isGCBUserBar ? this.CurrentRegionalBar : event.target.value)) {
        this.isValidRegionalBarName = false;
      } else {
        this.isValidRegionalBarName = true;
        this.RegionalBarName = "";
        this.onSelecteRegionalBar = false;
        this.onLinkBtnEnable();
      }
    } else {

      if (this.onfilterRegionalBars(!this.isGCBUserBar ? this.CurrentRegionalBar : this.RegionalBarName))
        this.isValidRegionalBarName = false;
      else {
        this.isValidRegionalBarName = true;
        this.RegionalBarName = "";
      }
    }
  } // end onBlurRegionalBar()

  onBlurAdvEmail() {
    if (!this.util.isValidEmail(this.email)) {
      this.isValidAdvocateEmail = true;
    } else {
      this.isValidAdvocateEmail = false;
    }
  } // end onBlurAdvEmail()

  onBlurAdvPhoneNumber() {
    if (!this.util.isValidPhoneNumber(this.phoneNumber,
      { code: this.userProfileService.userProfile.currencyDetails.code })) {
      this.isValidAdvocatePhoneNumber = true;
    } else {
      this.isValidAdvocatePhoneNumber = false;
    }
  } // end onBlurAdvPhoneNumber()

  onBlurAdvCell() {
    if (!this.util.isValidPhoneNumber(this.cell,
      { code: this.userProfileService.userProfile.currencyDetails.code })) {
      this.isValidAdvocateCell = true;
    } else {
      this.isValidAdvocateCell = false;
    }
  } // end onBlurAdvCell()

  onFocusAdvEmail() {
    this.onBlurRegionalBar(event);
    this.onBlurAdvocateName();
    this.onBlurAdvPhoneNumber();
    this.onBlurAdvCell();
  } // end onFocusAdvEmail()

  onFocusAdvPhoneNumber() {
    this.onBlurRegionalBar(event);
    this.onBlurAdvocateName();
  } // end onFocusAdvPhoneNumber()

  onFocusAdvCell() {
    this.onBlurRegionalBar(event);
    this.onBlurAdvocateName();
  } // end onFocusAdvCell()

  validateAdmissionDate(day, month, year) {
    this.isValidAdmissionMonth = false;
    this.isValidAdmissionYear = false;

    if (day > 0) {
      if (month === null || month === "" || month === undefined || month == "0") {
        this.isValidAdmissionMonth = true;
      }
      if (year === null || year === "" || year === undefined || year == "0") {
        this.isValidAdmissionYear = true;
      }
    }
    if (month > 0) {
      if (year === null || year === "" || year === undefined || year == "0") {
        this.isValidAdmissionYear = true;
      }
    }
  }

  onChangeAdmissionDay(day) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  onChangeAdmissionMonth(month) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  onChangeAdmissionYear(year) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end   onHideUnsavedChangesPrompt()

  onDismissPromptUnsavedChangesModal() {
    this.onHideUnsavedChangesPrompt();
    this.dismiss(false);
  } // end   onDismissPromptUnsavedChangesModal()

  dismiss(isDirectCloseAdvocateModal : boolean) {
    this.loadingService.showOverlay();
    this.clearInputs();
    if (isDirectCloseAdvocateModal)
      this.isDirectCloseAdvocateModal.emit(TAG_REQUEST_NEW_ADVOCATE_GCB_MODAL);
    else
    // setTimeout(() => {
      this.loadingService.showOverlay();
      this.closeModal.emit(TAG_REQUEST_NEW_ADVOCATE_GCB_MODAL);
    // }, 5000);
  }

  clearInputs() {
    this.ExstAdvocateFullName = '';
    this.RegionalBarName = '';
    this.phoneNumber = '';
    this.email = '';
    this.cell = '';
    this.regionalBar = '';
    //this.admissionDate = null;
    this.checkAdmissionDay = '0'
    this.checkAdmissionMonth = '0';
    this.checkAdmissionYear = '0'
    this.isValidAdmissionMonth = false;
    this.isValidAdmissionYear = false;
    this.isValidAdvocateName = false;
    this.isValidRegionalBarName = false;
    this.isValidAdvocateEmail = false;
    this.isValidAdvocateCell = false;
    this.isValidAdvocatePhoneNumber = false;
    this.onSelectExstAdv = false;
    this.isLinkBtnEnable = false;
    this.onSelectExstField = true;
  } // end clearInputs()

  onInputAdvocate(event) {
    // if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
    // }
    this.filterExstAdvocate = this.exsistingAdvocateData.filter(x => x.FullName.toLowerCase() === event.target.value.toLowerCase());
    if (this.filterExstAdvocate.length == 0) {
      this.onSelectExstAdv = false;
      this.isLinkBtnEnable = false;
      this.onSelectExstField = true;
    }
    else {
      this.onSelectExstAdv = true;
      this.onSelectExstField = false;
      this.onLinkBtnEnable();
    }
  }
  onSelectAdvocate(event: TypeaheadMatch): void {
    this.addLawfirmAdvocate.LawfirmName = event.item.FullName
    this.selectedExstAdvocateId = event.item.Id
    this.addLawfirmAdvocate.ReportingAdvocateId = event.item.Id
    this.onSelectExstAdv = true;
    this.onSelectExstField = false;
    this.isValidAdvocateName = false;
    if (this.onfilterRegionalBars(!this.isGCBUserBar ? this.CurrentRegionalBar : this.RegionalBarName)) {
      if (!this.onSelectExstAdv) {
        this.isValidRegionalBarName = true;
        this.onSelectExstField = false;
      }
    }
    else {
      this.onSelecteRegionalBar = false;
    }

    this.onLinkBtnEnable();
  }
  onModelChange(event, selectedAdvocate: ExistingAdvocateList) {
    this.selectedExstAdvocateId = selectedAdvocate.Id;
    this.onLinkBtnEnable();
  }
  onInputRegionalBar(event) {
    if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
    }
    this.filterRegionalBars = this.regionalBars.filter(x => x.FullName.toLowerCase() === event.target.value.toLowerCase());
    if (this.filterRegionalBars.length == 0) {
      this.onSelecteRegionalBar = false;
      this.isLinkBtnEnable = false;
      this.onSelectExstField = true;
    }
    else {
      this.onSelecteRegionalBar = true;
      this.onSelectExstField = false;
      this.onLinkBtnEnable();
    }
  }
  onSelectRegionalBar(event: TypeaheadMatch): void {
    this.addLawfirmAdvocate.RegionalBar = event.item.FullName;
    this.isValidRegionalBarName = false;
    this.onSelecteRegionalBar = true;
    if (!this.onfilterExstAdvocate(!this.isGCBUserBar ? this.CurrentRegionalBar : this.ExstAdvocateFullName))
      this.onSelectExstField = true;
    else {
      this.onSelectExstAdv = true;
      this.onSelectExstField = false;
    }

    this.onLinkBtnEnable();
  }
  onChangeRegionalBarModel(event, selectedRegionalBar: ExistingAdvocateList) {
    this.RegionalBarName = selectedRegionalBar.FullName;
    this.onLinkBtnEnable();
  }

  onLinkBtnEnable() {
    if (this.onSelectExstAdv == true && (!this.isGCBUserBar || this.onSelecteRegionalBar == true))
      this.isLinkBtnEnable = true;
    else
      this.isLinkBtnEnable = false;
  }

  onfilterExstAdvocate(FullName) {
    this.filterExstAdvocate = this.exsistingAdvocateData.filter(x => x.FullName.toLowerCase() === FullName.toLowerCase());
    return this.filterExstAdvocate.length >= 1 ? true : false;
  }

  onfilterRegionalBars(FullName) {
    if (!this.isGCBUserBar)
      return true;
    else
      this.filterRegionalBars = this.regionalBars.filter(x => x.FullName.toLowerCase() === FullName.toLowerCase());
    return this.filterRegionalBars.length >= 1 ? true : false;
  }

}