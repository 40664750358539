import { Injectable } from '@angular/core';

@Injectable()
export class NetworkConnectivityService {

  get isOnline(): boolean {
    return navigator.onLine;
  } // end isOnline()

  constructor() {
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  } // end constructor()

  handleConnectionChange(event) {
    if (event.type === 'offline') {
      alert('You lost connection.');
    }
    if (event.type === 'online') {
      alert('You are now back online.');
    }
  } // end handleConnectionChange()
} // end NetworkConnectivityService{}
