<div class="container-fluid">

  <div class=" border text-center customers-container ">

    <div class="w-16">
      <div class="customers-nav" (click)="onTabClick('all')">
        <a class="all" [class.active]="activeTab === 'all'">All</a>
      </div>
    </div>
    <div class="w-16">
      <div class="customers-nav" (click)="onTabClick('current')">
        <a class="current" [class.active]="activeTab === 'current'"> Current</a>
      </div>
    </div>
    <div class="w-16">
      <div class="customers-nav" (click)="onTabClick('30')">
        <a class="thirty" [class.active]="activeTab === '30'"> 30 Days</a>
      </div>
    </div>
    <div class="w-16">
      <div class="customers-nav" (click)="onTabClick('60')">
        <a class="sixty" [class.active]="activeTab === '60'"> 60 Days</a>
      </div>
    </div>
    <div class="w-16">
      <div class="customers-nav" (click)="onTabClick('90')">
        <a class="ninety" [class.active]="activeTab === '90'"> 90 Days</a>
      </div>
    </div>
    <div class="w-16">
      <div class="customers-nav" (click)="onTabClick('98')">
        <a class="ninetyEight" [class.active]="activeTab === '98'"> Overdue
        </a>
      </div>
    </div>
  </div>

  <div class="row"></div>


  <div class="table-responsive">
    <table class="table table-striped" style="border: 0;">
      <thead class="thead">
        <tr>
          <th class="w-19">Advocate</th>
          <th class="w-21"></th>
          <th class="w-9 text-right">Outstanding</th>
          <th class="w-51"></th>
        </tr>
      </thead>
      <tbody class="tbody">
        <tr *ngFor="let i of tabData;">
          <td class="w-19 ">
            <div (mouseenter)="onHover(i.ClientId, activeTab)" class="tool-tip"><u><a
                  (click)="onViewCustomerReport(i.ClientId, activeTab)">{{i.Debtor}}</a></u>
              <div class="tooltiptext">
                <div class="container mt-5" *ngIf="showTooltipOverlay">
                  <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                </div>
                <div class="container" *ngIf="showTooltipOverlay===false">
                  <div class="row">
                    <div class="col-md-8 text-left"><b>Matter</b></div>
                    <div class="col-md-4 text-right"><b>Amount due</b></div>
                  </div>
                  <div class="row" *ngFor="let customer of tooltipData; let i = index"
                    [ngStyle]="{'margin-bottom': getBottomRow(i, tooltipData.length)}">
                    <div class="col-md-8 text-left">{{customer.Matter}}</div>
                    <div class="col-md-4 text-right">{{customer.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}
                    </div>
                  </div>
                  <div style="position: absolute;bottom:3px;left:0;right:0">Click to open full
                    outstanding invoice report</div>
                </div>
              </div>
            </div>
          </td>
          <td class="w-21">{{i.LatestComments}}</td>
          <td class="w-9 text-right">{{i.Outstanding  | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
          <th class="w-51">
            <div class="">
              <div class="border-rounded-right"
                [ngStyle]="{'background-color':  getBackgroundColor(i.Type), 'width': getBarWidth(i.OutstandingPercentage)}">
                <span class="margin-left"> {{i.OutstandingPercentage}}%</span>
              </div>
            </div>
          </th>
        </tr>
        <tr class="table-light">
          <th><b><u>Total</u></b></th>
          <td></td>
          <th class="text-right"><b>{{totalOutstanding  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>

</div>