import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ContactProcess,
  DEFAULT_CONTACT_PROCESS,
  ReportsService
} from '../../../services/reports/reports.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';

@Component({
  selector: 'app-add-law-firm-process',
  templateUrl: './add-law-firm-process.component.html',
  styleUrls: ['./add-law-firm-process.component.scss']
})
export class AddLawFirmProcessComponent implements OnInit, OnChanges {

  hasSystem: any;
  ableToPorvideFeedback: any;
  willingToProvideFeedback: any;
  receiveFeedback: any;
  plaintiff: any;
  systemName: any;
  otherSystemName: string;
  btnSaveText: string;
  workingContactProcess: ContactProcess;

  @Input() isVisible: boolean;
  @Input() contactProcess: ContactProcess;
  @Input() selectedLawfirm: number;
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();

  get isSaveable(): Boolean {
    return Boolean(this.workingContactProcess.lawFirmProcess)
            && Boolean(this.workingContactProcess.receiveStatusFeedback)
            && Boolean(this.workingContactProcess.plaintiffDefendant)
            && this.isDirty;
  } // end isSaveable()

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.workingContactProcess, this.contactProcess);
  } // end isDirty()

  get showSystemTxt(): boolean {
    return Boolean(this.workingContactProcess.system === 'Other');
  } // end showSystemTxt()

  constructor(
    private util: UtilitiesService,
    private reportsService: ReportsService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService
  ) { } // end constructor()

  ngOnInit() {
    this.loadingService.showOverlay();
    this.hasSystem = [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ];

    this.ableToPorvideFeedback = [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ];

    this.willingToProvideFeedback = [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
      { label: 'Load', value: 'Load' },
    ];

    this.receiveFeedback = [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
      { label: 'Sometimes', value: 'Sometimes' },
    ];

    this.plaintiff = [
      { label: 'Plaintiff', value: 'Plaintiff' },
      { label: 'Defendant', value: 'Defendant' }
    ];

    this.systemName = [
      { label: 'AJS', value: 'AJS' },
      { label: 'Lexpro', value: 'Lexpro' },
      { label: 'Legal Suite', value: 'Legal Suite' },
      { label: 'Ghost Practice', value: 'Ghost Practice' },
      { label: 'Other', value: 'Other' },
    ];

    this.initContactProcess();
    if (this.contactProcess && this.contactProcess.id > 0) {
      const others = this.systemName.filter(a => a.value === this.contactProcess.system);
      if (others && others.length > 0) {
      } else {
        this.workingContactProcess.system = 'Other';
        this.otherSystemName = this.contactProcess.system;
      }
      this.btnSaveText = 'Update';
    } else {
      this.btnSaveText = 'Save';
      this.workingContactProcess = Object.assign({}, DEFAULT_CONTACT_PROCESS);
    }
    this.loadingService.hideOverlay();
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {

    if (changes.contactProcess && changes.contactProcess.currentValue) {
    //   this.initContactProcess();
    //   if (this.contactProcess && this.contactProcess.id > 0) {
    //     this.btnSaveText = 'Update';
    //   } else {
    //     this.btnSaveText = 'Save';
    //     this.workingContactProcess = Object.assign({}, DEFAULT_CONTACT_PROCESS);
      }
    // }
  } // end ngOnChanges()

  dismiss() {
    this.workingContactProcess = Object.assign({}, DEFAULT_CONTACT_PROCESS);
    this.closeModal.emit('close-add-law-firm-process');
  } // end dismiss()

  onAddFirmProcess() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Add/Edit Law Firm Profile ...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();

    this.workingContactProcess.contact_ContactProcess = this.selectedLawfirm;
    if (this.workingContactProcess.system === 'Other') {
      this.workingContactProcess.system = this.otherSystemName;
    }
    if (this.workingContactProcess && this.workingContactProcess.id > 0) {
      this.reportsService.putContactProcess(this.workingContactProcess).subscribe(
        data => {
          // On next
        },
        error => {
          // On error
          snack.label = 'Error updating Law Firm Profile.';
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();

          this.loadingService.hideOverlay();
        },
        () => {
          // On Complete
          snack.label = 'Law Firm Profile Updated.';
          this.snackbarsService.dismiss().make(snack).show();
          this.closeModal.emit('close-add-law-firm-process');
          this.loadingService.hideOverlay();
        }
      );
    } else {
      this.reportsService.postContactProcess(this.workingContactProcess).subscribe({
        next: (data) => {
          // On next
        },
        error: (error) => {
          // On error
          snack.label = 'Error Adding Law Firm Profile.';
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On Complete
          snack.label = 'Law Firm Profile Added.';
          this.snackbarsService.dismiss().make(snack).show();
          this.closeModal.emit('close-add-law-firm-process');
          this.loadingService.hideOverlay();
        }
      });
    }
  } // end onSave()

  initContactProcess() {
    this.workingContactProcess = this.util.objectCopy(this.contactProcess);
  } // end initContactProcess()

} // end AddLawFirmProcessComponent {}
