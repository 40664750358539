<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onDismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div><!--#end .modal-header -->
      <div class="modal-body form">
        <nav class="navbar-tabs" *ngIf="showLawFirmTab"> 
          <button
            class="tab"
            [class.active]="tabView === 'add-law-firm'"
            (click)="changeTabView('add-law-firm')">Add new law firm</button>
          <button
            class="tab"
            [class.active]="tabView === 'add-advocate'"
            (click)="changeTabView('add-advocate')">Add new Advocate</button>
        </nav>

      <div *ngIf="tabView === 'add-law-firm' && showLawFirmTab" >
        <!-- <div class="row"> -->
            <!-- Firm name -->
            <div class="form-group">
              <label for="firm-name">Firm name<span title="Required field"> *</span></label>
              <input
                type="text"
                #lawfirmName
                class="form-control"
                [class.has-error]="isValidLawFirmName"
                [id]="firm-name"
                [(ngModel)]="firmName"
                (blur)="onLawFirmNameBlur()"
                placeholder="Name of the law firm">
                <span
                *ngIf="isValidLawFirmName"
                class="text-danger">Please enter a valid Law firm name.</span>
            </div>
          
            <!-- Attorney -->
            <div class="form-group">
              <label for="attorney">Attorney</label>
              <input
                type="text"
                [id]="attorney"
                [(ngModel)]="attorney"
                (focus)="onFocusAttorney()"
                placeholder="Name of attorney at law firm">
            </div>
          
            <!-- Phone number -->
            <div class="form-group">
              <label for="phone-number">Phone number<span title="Required field"> *</span></label>
              <input
                type="text" 
                [id]="phone-number"
                class="form-control"
                [class.has-error]="isValidPhoneNumber"
                [(ngModel)]="phoneNumber"
                (blur)="onBlurPhoneNumber()"
                placeholder="Law firm phone number">
                <span
                *ngIf="isValidPhoneNumber"
                class="text-danger">Please enter a valid phone number.</span>
            </div>

            <!-- Email Address -->
            <div class="form-group">
              <label for="email">Email<span title="Required field"> *</span></label>
              <input
                type="text"
                class="form-control"
                [class.has-error]="isValidEmail"
                [id]="email"
                [(ngModel)]="email"
                (focus)="onFocusEmail()"
                (blur)="onBlurEmail()"
                placeholder="Law firm email address">
                <span
                *ngIf="isValidEmail"
                class="text-danger">Please enter a valid email address.</span>
            </div>
        <!-- </div> -->
      </div>

      <div *ngIf="tabView === 'add-advocate'">
        <!-- <div class="row"> -->
            <!-- Firm name -->
            <div class="form-group">
              <label for="firm-name">Advocate name<span title="Required field"> *</span></label>
              <input
                type="text"
                class="form-control"
                [class.has-error]="isValidAdvocateName"
                [id]="firm-name"
                [(ngModel)]="firmName"
                (blur)="onBlurAdvocateName()"
                placeholder="Name of the Advocate">
                <span
                *ngIf="isValidAdvocateName"
                class="text-danger">Please enter a valid Advocate name.</span>
            </div>
          
            <!-- Phone number -->
            <div class="form-group">
              <label for="phone-number">Phone number<span title="Required field"> *</span></label>
              <input
                type="text" 
                [id]="phone-number"
                class="form-control"
                [class.has-error]="isValidAdvocatePhoneNumber"
                [(ngModel)]="phoneNumber"
                (focus)="onFocusAdvPhoneNumber()"
                (blur)="onBlurAdvPhoneNumber()"
                placeholder="Advocate cell phone number">
                <span
                *ngIf="isValidAdvocatePhoneNumber"
                class="text-danger">Please enter a valid phone number.</span>
            </div>

            <!-- Email Address -->
            <div class="form-group">
              <label for="email">Email<span title="Required field"> *</span></label>
              <input
                type="text"
                class="form-control"
                [class.has-error]="isValidAdvocateEmail"
                [id]="email"
                [(ngModel)]="email"
                (focus)="onFocusAdvEmail()"
                (blur)="onBlurAdvEmail()"
                placeholder="Advocate email address">
                <span
                *ngIf="isValidAdvocateEmail"
                class="text-danger">Please enter a valid email address.</span>
            </div>

        <!-- </div> -->
      </div>
      </div>
      <!--#end .modal-body -->

      <div class="modal-footer">
        <button
          class="btn btn-success"
          [class.disabled]="!isSubmittable"
          (click)="submitRequest()"
          [disabled]="!isSubmittable">Submit Request</button>
      </div><!--#end .modal-footer -->

    </div><!--#end .modal-dialog -->
  </div><!--#end .modal-content -->
</div><!--#end .modal -->

<app-prompt-unsaved-changes-modal
  [isVisible]="showUnsavedChangesPrompt"
  (cancel)="onHideUnsavedChangesPrompt()"
  (continue)="onDismissPromptUnsavedChangesModal()"
  (confirm)="onDismissPromptUnsavedChangesModal()"></app-prompt-unsaved-changes-modal>
