import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { InvoiceDetails, InvoiceDetailsService } from '../../../services/invoice-details/invoice-details.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { TAG_ADD_INVOICE_NOTE_MODAL } from '../../../services/tags/tags.service';
import * as moment from 'moment';
import { Snack, SnackbarsService, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { Invoice, InvoicesService, InvoiceNote, DEFAULT_INVOICE_NOTE } from '../../../services/invoices/invoices.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { Matter } from '../../../services/matters/matters.service';
import {
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogs,
  ActivityLogsService} from '../../../services/activity-logs/activity-logs.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-add-invoice-note',
  templateUrl: './add-invoice-note.component.html',
  styleUrls: ['./add-invoice-note.component.scss']
})
export class AddInvoiceNoteComponent implements OnInit, OnChanges {

  title = '';
  invoiceNoteTitle = '';
  notes: string;
  @Input() isVisible: boolean;
  @Input() matter: Matter;
  @Input() invoiceDetails: Invoice;
  @Output() closeModal: EventEmitter<string> = new EventEmitter();

  workingInvoice: Invoice;
  invoiceNote: InvoiceNote = DEFAULT_INVOICE_NOTE;
  showUnsavedChangesPrompt: boolean;

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  constructor(
    // private invoiceDetailService: InvoiceDetailsService,
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private invoiceService: InvoicesService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
  ) { } // end constructor()

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.invoiceDetails, this.workingInvoice);
  }

  ngOnInit() {
    setTimeout(() => {
      this.workingInvoice = Object.assign({}, this.invoiceDetails);
    }, 4000);
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.invoiceDetails && changes.invoiceDetails.currentValue) {
      // this.title = 'Note for: (' + this.invoiceDetails.InvoiceParties + ')';
      // this.notes = this.invoiceDetails.Notes;
    }
    if (this.matter) {
      this.invoiceNoteTitle = 'Add Invoice Note For: ' + this.matter.Description;
    }
  } // ngOnChanges()

  onSaveNote() {
    const snack: Snack = {
      label: 'Saving invoice note...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();

    this.loadingService.showOverlay();
    // this.invoiceDetails.Notes = this.notes;
    // this.invoiceDetails.InvoiceDate = moment(this.invoiceDetails.InvoiceDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    this.invoiceNote.Notes = this.workingInvoice.Notes;
    this.invoiceNote.Id = this.workingInvoice.Id;

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Save Invoice note';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-matter)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Invoice';
    this.activityLog.JsonData = JSON.stringify(this.invoiceNote);
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    this.invoiceService.updateInvoiceAddUpdateNote(this.invoiceNote)
    .subscribe({
      next: (invoice_detail) => {
      // On complete
      this.invoiceDetails = Object.assign({}, this.workingInvoice);
    },
    error: (error) => {
      // On error
      snack.label = 'Error saving invoice note.';
      snack.type = SnackType.ERROR;
      snack.action = null;
      this.snackbarsService.dismiss().make(snack).show();
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      snack.label = 'Invoice note saved.';
      snack.action = null;
      snack.type = SnackType.SUCCESS;
      this.snackbarsService.dismiss().make(snack).show();
      this.loadingService.hideOverlay();
      this.dismiss();
    }
  });
  } // end onSaveNote()

  dismiss() {
    if (this.isDirty) {
      this.showUnsavedChangesPrompt = true;
    } else {
      this.notes = '';
      this.invoiceDetails = null;
      this.closeModal.emit(TAG_ADD_INVOICE_NOTE_MODAL);
    }
  } // end dismiss()

  onCancel() {
      this.dismiss();
  }

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onHideUnsavedChangesPrompt()

  leavePage() {
    this.workingInvoice = Object.assign({}, this.invoiceDetails);
    this.closeModal.emit(TAG_ADD_INVOICE_NOTE_MODAL);
    this.onHideUnsavedChangesPrompt();
  } // end leavePage()

} // end AddInvoiceNoteComponent{}
