import { Injectable } from '@angular/core';
import { ToastService, Toast } from './messaging/toast/toast.service';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// import { MsalService } from '@azure/msal-angular';
// import { Adal5Service } from 'adal-angular5';

export const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

/* 0xx Custom */
export const HTTP_STATUS_NETWORK_CONNECTION = 0;

/* 1xx Informational response */
export const HTTP_STATUS_CONTINUE = 100;
export const HTTP_STATUS_SWITCHING_PROTOCOLS = 101;
export const HTTP_STATUS_PROCESSING = 102;
export const HTTP_STATUS_EARLY_HINTS = 103;

/* 2xx Success */
export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_CREATED = 201;
export const HTTP_STATUS_ACCEPTED = 202;
export const HTTP_STATUS_NON_AUTHORITATIVE_INFO = 203;
export const HTTP_STATUS_NO_CONTENT = 204;
export const HTTP_STATUS_RESET_CONTENT = 205;
export const HTTP_STATUS_PARTIAL_CONTENT = 206;
export const HTTP_STATUS_MULTI_STATUS = 207;
export const HTTP_STATUS_ALREADY_REPORTED = 208;
export const HTTP_STATUS_IM_USED = 226;

/* 3xx Redirection */
export const HTTP_STATUS_MULTIPLE_CHOICES = 300;
export const HTTP_STATUS_MOVED_PERMANENTLY = 301;
export const HTTP_STATUS_FOUND = 302;
export const HTTP_STATUS_SEE_OTHER = 303;
export const HTTP_STATUS_NOT_MODIFIED = 304;
export const HTTP_STATUS_USE_PROXY = 305;
export const HTTP_STATUS_SWITCH_PROXY = 306;
export const HTTP_STATUS_TEMPORARY_REDIRECT = 307;
export const HTTP_STATUS_PERMANENT_REDIRECT = 308;

/* 4xx Client errors */
export const HTTP_STATUS_BAD_REQUEST = 400;
export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_PAYMENT_REQUIRED = 402;
export const HTTP_STATUS_FORBIDDEN = 403;
export const HTTP_STATUS_NOT_FOUND = 404;
export const HTTP_STATUS_METHOD_NOT_ALLOWED = 405;
export const HTTP_STATUS_NOT_ACCEPTABLE = 406;
export const HTTP_STATUS_PROXY_AUTHENTICATION_REQUIRED = 407;
export const HTTP_STATUS_REQUEST_TIMEOUT = 408;
export const HTTP_STATUS_CONFLICT = 409;
export const HTTP_STATUS_GONE = 410;
export const HTTP_STATUS_LENGTH_REQUIRED = 411;
export const HTTP_STATUS_PRECONDITION_FAILED = 412;
export const HTTP_STATUS_PAYLOAD_TOO_LARGE = 413;
export const HTTP_STATUS_URI_TOO_LONG = 414;
export const HTTP_STATUS_UNSUPPORTED_MEDIA_TYPE = 415;
export const HTTP_STATUS_RANGE_NOT_SATISFIABLE = 416;
export const HTTP_STATUS_EXPECTATION_FAILED = 417;
export const HTTP_STATUS_IM_A_TEAPOT = 418;
export const HTTP_STATUS_MISDIRECTED_REQUEST = 421;
export const HTTP_STATUS_INPROCESSABLE_ENTITY = 422;
export const HTTP_STATUS_LOCKED = 423;
export const HTTP_STATUS_FAILED_DEPENDENCY = 424;
export const HTTP_STATUS_UPGRADE_REQUIRED = 426;
export const HTTP_STATUS_PRECONDITION_REQUIRED = 428;
export const HTTP_STATUS_TOO_MANY_REQUESTS = 429;
export const HTTP_STATUS_REQUEST_HEADER_FIELDS_TOO_LARGE = 431;
export const HTTP_STATUS_UNAVAILABLE_FOR_LEGAL_REASONS = 451;

/* 5xx Server errors */
export const HTTP_STATUS_INTERNAL_SERVER_ERROR = 500;
export const HTTP_STATUS_NOT_IMPLEMENTED = 501;
export const HTTP_STATUS_BAD_GATEWAY = 502;
export const HTTP_STATUS_SERVICE_UNAVAILABLE = 503;
export const HTTP_STATUS_GATEWAY_TIMEOUT = 504;
export const HTTP_STATUS_HTTP_VERSION_NOT_SUPPORTED = 505;
export const HTTP_STATUS_VARIANT_ALSO_NEGOTIATES = 506;
export const HTTP_STATUS_INSUFFICIENT_STORAGE = 507;
export const HTTP_STATUS_LOOP_DETECTED = 508;
export const HTTP_STATUS_NOT_EXTENDED = 510;
export const HTTP_STATUS_NETWORK_AUTHENTICATION_REQUIRED = 511;

export interface Endpoints {
  addLawFirmAttorney: string;
  addLawFirmAdvocate: string;
  paymentResolution: string;
  activityLogs: string;
  addAssistant: string;
  advocate: string;
  advocateStatement: string;
  byLawFirm: string;
  lawFirmDetail: string;
  attorneysInFirms: string;
  attorneysByFirms: string;
  banks: string;
  bankAccounts: string;
  briefs: string;
  //csdDocuments: string;
  calendarItems: string;
  calendarActionReport: string;
  category: string;
  clientContacts: string;
  chambers: string;
  stickyNotes: string;
  contacts: string;
  collectionsAdmin: string;
  collectionsStatus: string;
  dashboardData: string;
  dashboardDataCurrentMonthly: string;
  dashboardDataCurrentYearly: string;
  dashboardDataInvoicedMonthly: string;
  dashboardDataInvoicedYearly: string;
  dashboardDataCollectedMonthly: string;
  dashboardDataCollectedYearly: string;
  practiceDashboardDataInvoicedMonthly: string;
  practiceDashboardDataInvoicedYearly: string;
  practiceDashboardDataCollectedMonthly: string;
  practiceDashboardDataCollectedYearly: string;
  uploadHistoricalData: string;
  takeOnData: string;
  overdueByLawfirm: string;
  contactStickyNote: string;
  practiceOverdueSummary: string;
  practiceWidgetSummary: string;
  practiceAdvocateHealth: string;
  practiceAdvocateSummary: string;
  practiceAgeAnalysis: string;
  practiceAllCustomer: string;
  practiceByCustomer: string;
  practiceByLawfirm: string;
  practiceLawfirmDetail: string;
  practiceUpdateSelected: string;
  practiceLawfirmDetailSummary: string;
  defendants: string;
  deletedInvoiceReport: string;
  unallocatedTransactionReport: string;
  monthlyMeetingReport: string;
  practiceAssessmentReport: string;
  disbursementReport: string;
  feeItems: string;
  paymentReceived: string;
  feeItemList: string;
  fromCalendarItems: string;
  matterDocument: string;
  //csdDocumentUrl: string;
  invoice: string;
  invoiceDetails: string;
  invoiceDocument: string;
  invoiceHistoryReport: string;
  creditNoteReport: string;
  writeOffReport: string;
  invoiceHistoryByLawFirm: string;
  invoiceLines: string;
  documentType: string;
  invoiceMessage: string;
  lawFirm: string;
  leads: string;
  localities: string;
  messages: string;
  multipleFees: string;
  onboarding: string;
  plaintiffs: string;
  otherClients: string;
  outstandingInvoiceReport: string;
  reports: string;
  reconciliationHistoryReport: string;
  reconciliationHistoryByLawfirmReport: string;
  lawfirmReconciliationHistoryByLawfirmReport: string;
  search: string;
  securityProfile: string;
  sentMessages: string;
  sentMessagesByPage: string;
  sentMessagesBySearch: string;
  clientMessageInbox: string;
  sentMessageAction: string;
  sentServices: string;
  notes: string;
  services: string;
  lod: string;
  documentManager: string;
  invite: string;
  quotation: string;
  subCategory: string;
  subCategoryAll: string;
  syncData: string;
  userProfile: string;
  vatOnPaidInvoicesReport: string;
  defaultReport: string;
  workInProgressMatter: string;
  workInProgressReport: string;
  financialSummaryViewReport: string;
  proformaInvoiceReport: string;
  statementDocumentReport: string;
  statementOfAccountsReport: string;
  matterStatementOfAccountsReport: string;
  statementByAdvocate: string;
  statementByStateAdvocate: string;
  statementByStateAttorney: string;
  threeMonthsLawfirmData: string;
  advocateDetailStatement: string;
  lawfirmPaidStatement: string;
  lawfirmPaidByAdvocateStatement: string;
  lawfirmOustandingStatement: string;
  debtorProgress: string;
  AttorneyAllCustomers: string;
  byIndividualAttorneyCustomers: string;
  auxconReportUtils: string;
  paymentDetail: string;
  invoicedCollectedMonthlySummaryReport: string;
  averagePaymentDaysReport: string;
  ageAnalysisReport: string;
  contactProcess: string;
  allCustomersReport: string;
  lawfirmSummaryOverview: string;
  lawfirmSummaryOverviewList: string;
  byIndividualCustomer: string;
  discounting: string;
  paymentRating: string;
  distinctCustomerList: string;
  currentMonthlyReport: string;
  lawfirmRatings: string;
  contactManager: string;
  searchContact: string;
  pendingInvoice: string;
  proofOfPayment: string;
  proofOfPaymentLawFirms: string;
  proofDocumentAttachment: string;
  allFirmsAveragePaymentDays: string;
  getAllGcbTooltipList: string;
  allFirmsAveragePaymentDaysBySearch: string;
  paymentTrends: string;
  unPaidInvoicesByClientProofOfPayment: string;
  postProofOfPaymentLines: string;
  linesByPopIdProofOfPayment: string;
  removeProofOfPaymentLines: string;
  collectorNotes: string;
  uploadBankStatement: string;
  uploadBankStatementWithDate: string;
  uploadBankStatementNoDuplicate: string;
  bankTransactions: string;
  excludeBankTransaction: string;
  personalBankTransaction: string;
  lawFirmBankTransaction: string;
  referToClient: string;
  possibleMatchesBankTransaction: string;
  processedBankTransactions: string;
  removeFromProcessed: string;
  sendStatements: string;
  bankStatementSummary: string;
  proofOfPaymentAttachment: string;
  allocatedToLawfirm: string;
  liveStatementAllocatedToLawfirm: string;
  allAdvocateReport: string;
  allAdvocateData: string;
  allAttorneyData: string;
  distinctAdvocateList: string;
  subscriptionSummary: string;
  subscriptionDetail: string;
  getAccountPlateformTransaction: string;
  getAccountPlateformTrandsactionLog: string;
  UpdateLastMeetingDate: string;
  manageBlackList: string;
  get_Existing_Advocate_List: string;
  get_LPC_Status_List: string;
  get_LPC_edit_details: string;
  saveBlackListHandler: string;
  LinkExstAdvocate: string;
  blackListDocumentUpload: string;
  getLawfirmAdvocates: string;
  getbyLawfirmApprovedReq: string;
  getbyLawfirmLPCReqList: string;
  updateLPCdetails: string;
  getBlackListFile: string,
  deleteLowfirmListingsItem: string;
  deleteAdvocate: string;
  gcb_approved_Reject: string;
  gcb_approved_Reject_Delete_Request: string;
  gcb_pending_Advocate: string;
  get_blackList_File: string;
  download_LPC_document: string;
  barSocietyNewLawfirmrequest: string;
  singupUser: string,
  GetOfficeUserDetails: string
  sageUserValidation: string;
  advocateSageSetup: string;
  saveAdvocateSageUser: string;
  getCurrentAdvocateSageDetails: string;
  removeAdvocateSageSetup: string;
  practiceManagement: string;
  getSubscriptionDetails: string;
  googlePlaces: string;
  subscriptionBilling: string;
  outlookgenearteAccessToken: string;
  lawfirmStatement: string;
}

@Injectable()
export class ApiService {
  baseURL: string;
  apiURL: string;
  reportsApiURL: string;
  serviceProviderID: number;
  endpoints: Endpoints;
  httpOptions = {
    headers: new HttpHeaders({
      'accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    })
  };
  // ,
  // 'Authorization': 'Bearer key',
  httpFileOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    }),
  };

  // set token(value: string) {
  //   this.adal5Service.
  // }

  ReplaceSlashFromURL(url) {
    var indexNo = url.lastIndexOf("//api");
    return indexNo > 0 ? url.slice(0, indexNo) + url.slice(indexNo).replace("//api", "/api") : url;
  }

  constructor(
    private toastService: ToastService,
    // private adal5Service: Adal5Service
    // private msal: MsalService,
  ) {
    // this.baseURL = 'http://localhost:3821';
    this.baseURL = 'https://api.auxconlegal.com/'; // For Live Site
    // this.baseURL = 'https://auxconapi-prd-staging.azurewebsites.net'; // Staging
    // this.baseURL = 'https://auxcon-api-qa.azurewebsites.net'; // QA
    // this.baseURL = 'https://auxcon-gcb-api.azurewebsites.net/'; // QA
    // this.baseURL = 'https://auxconapicollections.azurewebsites.net'; // For Collection api
    // this.baseURL = 'https://auxconapicore-dev-as.azurewebsites.net'; // AuxconRSA_Dev db
    // this.baseURL = 'https://auxconapicorev1-stagingauxconapi.azurewebsites.net'; // For Live Site
    this.apiURL = this.baseURL + "/api";
    //this.reportsApiURL = "https://auxconreports.azurewebsites.net"; // Must be https  // Remove slash when refactoring
    // this.reportsApiURL = this.reportsApiURL = 'http://localhost:31789/';
    this.serviceProviderID = 0;

    // this.httpOptions.headers =
    //   new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Bearer ' //+ // localStorage.getItem('msal.idtoken')
    //     //  adal5Service.userInfo.tokens
    //   });

    // this.httpFileOptions.headers =
    //   new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Bearer ' +  localStorage.getItem('msal.idtoken')
    //   });

    this.endpoints = {
      addLawFirmAttorney: this.apiURL + '/AddLawFirmAttorney',
      addLawFirmAdvocate: this.apiURL + '/AddLawFirmAttorney/PostRequestNewdvocate',
      paymentResolution: this.apiURL + '/ClientPaymentResolution',
      activityLogs: this.apiURL + '/ActivityMonitor',
      addAssistant: this.apiURL + '/Assistant',
      allAdvocateReport: this.apiURL + '/AllAdvocatesReport',
      allAdvocateData: this.apiURL + '/AllAdvocatesData',
      allAttorneyData: this.apiURL + '/DistinctAttorneyList',
      advocate: this.apiURL + '/Advocate',
      advocateStatement: this.apiURL + '/AdvocateStatement',
      byLawFirm: this.apiURL + '/ByLawFirm',
      lawFirmDetail: this.apiURL + '/LawFirmDetail',
      attorneysInFirms: this.apiURL + '/AttorneysInFirms',
      attorneysByFirms: this.apiURL + '/AttorneysByFirm',
      banks: this.apiURL + '/Banks',
      bankAccounts: this.apiURL + '/BankAccount',
      collectionsStatus: this.apiURL + '/CollectionStatus',
      collectorNotes: this.apiURL + '/CollectorNotes',
      briefs: this.apiURL + '/Briefs',
      getBlackListFile: this.apiURL + '/GCBBlackListResponse/GetAttachedFile',
      calendarActionReport: this.apiURL + '/Reports?ReportName=CalendarActionReport&ServiceProviderId',
      calendarItems: this.apiURL + '/CalendarItems',
      category: this.apiURL + '/Category',
      uploadHistoricalData: this.apiURL + '/TakeOnData/DataImport',
      blackListDocumentUpload: this.apiURL + '/ManageBlackList/UploadBlackListFile',
      clientContacts: this.apiURL + '/ClientContacts',
      AttorneyAllCustomers: this.apiURL + '/AllCustomersAttorney',
      byIndividualAttorneyCustomers: this.apiURL + '/ByIndividualAttorneyCustomer',
      contacts: this.apiURL + '/Contacts',
      collectionsAdmin: this.apiURL + '/CollectionsAdmin',
      takeOnData: this.apiURL + '/TakeOnData',
      chambers: this.apiURL + '/Chambers',
      invite: this.apiURL + '/Invite',
      stickyNotes: this.apiURL + '/StickyNotes',
      dashboardData: this.apiURL + '/DashboardData',
      clientMessageInbox: this.apiURL + '/ClientMessagesInbox',
      dashboardDataCurrentMonthly: this.apiURL + '/DashboardData/CurrentMonthly',
      dashboardDataCurrentYearly: this.apiURL + '/DashboardData/CurrentYearly',
      dashboardDataInvoicedMonthly: this.apiURL + '/DashboardData/InvoicedMonthly',
      dashboardDataInvoicedYearly: this.apiURL + '/DashboardData/InvoicedYearly',
      dashboardDataCollectedMonthly: this.apiURL + '/DashboardData/CollectedMonthly',
      dashboardDataCollectedYearly: this.apiURL + '/DashboardData/CollectedYearly',
      practiceDashboardDataInvoicedMonthly: this.apiURL + '/PracticeDashboard/InvoicedMonthly',
      practiceDashboardDataInvoicedYearly: this.apiURL + '/PracticeDashboard/InvoicedYearly',
      practiceDashboardDataCollectedMonthly: this.apiURL + '/PracticeDashboard/CollectedMonthly',
      practiceDashboardDataCollectedYearly: this.apiURL + '/PracticeDashboard/CollectedYearly',
      practiceWidgetSummary: this.apiURL + '/PracticeDashboard/InvoicedTraget',
      auxconReportUtils: this.apiURL + '/ExcelDocument',
      practiceAdvocateHealth: this.apiURL + '/PracticeAdvocateHealth/AdvocateHealth',
      UpdateLastMeetingDate: this.apiURL + '/PracticeAdvocateHealth/UpdateLastMeetingDate',
      practiceAdvocateSummary: this.apiURL + '/PracticeAdvocateHealth/AdvocateBelowTarget',
      practiceAgeAnalysis: this.apiURL + '/PracticeAdvocateHealth/PracticeAnalysis',
      practiceAllCustomer: this.apiURL + '/PracticeAdvocateHealth/PracticeAllCustomers',
      practiceByCustomer: this.apiURL + '/PracticeAdvocateHealth/PracticeByCustomer',
      practiceByLawfirm: this.apiURL + '/PracticeAdvocateHealth/PracticeByLawfirmOverdue',
      practiceLawfirmDetail: this.apiURL + '/PracticeAdvocateHealth/PracticeLawfirmDetailOverdue',
      practiceLawfirmDetailSummary: this.apiURL + '/PracticeAdvocateHealth/OtherManagersOutstanding',
      practiceUpdateSelected: this.apiURL + '/PracticeAdvocateHealth/UpdateSelectAdvocateDetail',
      practiceOverdueSummary: this.apiURL + '/PracticeDashboard/LawfirmsOverdue',
      overdueByLawfirm: this.apiURL + '/AdvocateOverview',
      contactStickyNote: this.apiURL + '/ContactStickyNote',
      defendants: this.apiURL + '/Defendants',
      deletedInvoiceReport: this.apiURL + '/Reports?ReportName=DeletedInvoiceReport&ServiceProviderId',
      unallocatedTransactionReport: this.apiURL + '/Reports?ReportName=UnallocatedBankTransaction&ServiceProviderId',
      monthlyMeetingReport: this.apiURL + '/Reports?ReportName=MonthlyMeetingReport&ServiceProviderId',
      practiceAssessmentReport: this.apiURL + '/Reports?ReportName=PracticeAssesmentReport&ServiceProviderId',
      disbursementReport: this.apiURL + '/Reports?ReportName=DisbursementReport&ServiceProviderId',
      feeItems: this.apiURL + '/FeeItems',
      paymentReceived: this.apiURL + '/FeeItems/PaymentReceived',
      feeItemList: this.apiURL + '/FeeItems/api/FeeItemList',
      fromCalendarItems: this.apiURL + '/FromCalendarItems',
      matterDocument: this.apiURL + '/MatterDocument?briefId',
      invoice: this.apiURL + '/Invoice',
      invoiceDetails: this.apiURL + '/InvoiceDetails',
      invoiceDocument: this.apiURL + '/InvoiceDocument',
      invoiceLines: this.apiURL + '/InvoiceLines',
      invoiceHistoryByLawFirm: this.apiURL + '/Reports?ReportName=InvoiceHistoryByLawFirm&ServiceProviderId',
      invoiceHistoryReport: this.apiURL + '/Reports?ReportName=InvoiceHistoryReport&ServiceProviderId',
      creditNoteReport: this.apiURL + '/Reports?ReportName=CreditNoteReport&ServiceProviderId',
      writeOffReport: this.apiURL + '/Reports?ReportName=WriteOffReport&ServiceProviderId',
      invoiceMessage: this.apiURL + '/InvoiceMessage',
      lawFirm: this.apiURL + '/LawFirm/LawFirmByAdvocate',
      leads: this.apiURL + '/Leads',
      localities: this.apiURL + '/Localities',
      documentType: this.apiURL + '/DocumentTypes',
      messages: this.apiURL + '/Messages',
      multipleFees: this.apiURL + '/MultipleFees',
      onboarding: this.ReplaceSlashFromURL(this.apiURL) + '/Onboarding',
      plaintiffs: this.apiURL + '/Plaintiffs',
      otherClients: this.apiURL + '/OtherClients',
      outstandingInvoiceReport: this.apiURL + '/Reports?ReportName=OutstandingInvoiceReport&ServiceProviderId',
      reports: this.apiURL + '/Reports',
      reconciliationHistoryReport: this.apiURL + '/Reports?ReportName=ReconciliationHistoryReport&ServiceProviderId',
      reconciliationHistoryByLawfirmReport: this.apiURL + '/Reports?ReportName=ReconciliationHistoryByLawfirmReport',
      lawfirmReconciliationHistoryByLawfirmReport: this.apiURL + '/Reports?ReportName=LawfirmReconciliationHistoryReport',
      search: this.apiURL + '/Search',
      securityProfile: this.apiURL + '/SecurityProfile',
      sentMessages: this.apiURL + '/SentMessages',
      sentMessagesByPage: this.apiURL + '/SentMessagesByPage',
      sentMessagesBySearch: this.apiURL + '/SentMessagesBySearch',
      sentMessageAction: this.apiURL + '/SentMessageAction',
      sentServices: this.apiURL + '/SentServices',
      services: this.apiURL + '/Services',
      lod: this.apiURL + '/LOD',
      documentManager: this.apiURL + '/DocumentManager',
      quotation: this.apiURL + '/Quotation',
      notes: this.apiURL + '/Notes',
      subCategory: this.apiURL + '/SubCategory',
      subCategoryAll: this.apiURL + '/SubCategoryAll',
      statementByAdvocate: this.apiURL + '/ByAdvocate',
      statementByStateAdvocate: this.apiURL + '/ByStateAdvocate',
      statementByStateAttorney: this.apiURL + '/ByStateAttorney',
      threeMonthsLawfirmData: this.apiURL + '/ThreeMonthsOutstandingLawfirm',
      advocateDetailStatement: this.apiURL + '/LawFirmStatement',
      lawfirmPaidStatement: this.apiURL + '/BankTransactions',
      lawfirmPaidByAdvocateStatement: this.apiURL + '/FirmPerAdvocateBankTransaction',
      lawfirmOustandingStatement: this.apiURL + '/OutstandingStatement',
      paymentDetail: this.apiURL + '/PaymentDetail',
      syncData: this.apiURL + '/SyncData',
      userProfile: this.apiURL + '/UserProfile',
      vatOnPaidInvoicesReport: this.apiURL + '/Reports?ReportName=VATReport&ServiceProviderId',
      workInProgressMatter: this.apiURL + '/Reports?ReportName=WorkInProgressMatter&ServiceProviderId',
      workInProgressReport: this.apiURL + '/Reports?ReportName=WorkInProgressReport&ServiceProviderId',
      defaultReport: 'Default?ServiceProviderId',
      financialSummaryViewReport: this.apiURL + '/Reports?ReportName=FinancialSummaryViewReport&ServiceProviderId',
      proformaInvoiceReport: this.apiURL + '/Reports?ReportName=ProformaInvoiceReport&ServiceId',
      statementDocumentReport: this.apiURL + '/StatementDocument?messageID',
      statementOfAccountsReport: this.apiURL + '/Reports?ReportName=StatementOfAccount&ServiceProviderId',
      matterStatementOfAccountsReport: this.apiURL + '/Reports?ReportName=MatterStatementOfAccountReport&ServiceProviderId',
      invoicedCollectedMonthlySummaryReport: this.apiURL + '/PracticeManagement/InvoicedCollectedMonthlySummary',
      practiceManagement: this.apiURL + '/PracticeManagement',
      averagePaymentDaysReport: this.apiURL + '/PracticeManagement/api/AveragePaymentDays',
      debtorProgress: this.apiURL + '/PracticeManagement/DebtorProgress',
      ageAnalysisReport: this.apiURL + '/PracticeManagement/api/AgeAnalysis',
      contactProcess: this.apiURL + '/ProcessController',
      allCustomersReport: this.apiURL + '/PracticeManagement/api/AllCustomersReport',
      lawfirmSummaryOverview: this.apiURL + '/PracticeManagement/LawfirmSummaryOverview',
      lawfirmSummaryOverviewList: this.apiURL + '/PracticeManagement/LawfirmSummaryOverviewList',
      byIndividualCustomer: this.apiURL + '/PracticeManagement/api/ByIndividualCustomer',
      discounting: this.apiURL + '/PracticeManagement/api/DiscountingInvoice',
      subscriptionSummary: this.apiURL + '/PracticeAdvocateHealth/PracticeClientSubscriptions',
      subscriptionDetail: this.apiURL + '/PracticeAdvocateHealth/SubscriptionDetail',
      paymentRating: this.apiURL + '/PracticeManagement/api/PaymentRating',
      distinctCustomerList: this.apiURL + '/PracticeManagement/api/DistinctCustomerList',
      currentMonthlyReport: this.apiURL + '/DashboardData/CurrentMonthly',
      lawfirmRatings: this.apiURL + '/LawFirm/PaymentRating',
      contactManager: this.apiURL + '/ContactManager',
      searchContact: '/SearchContact',
      liveStatementAllocatedToLawfirm: this.apiURL + '/PracticeManagement/api/AllocatedToFirmLiveStatement',
      pendingInvoice: this.apiURL + '/PendingInvoice',
      proofOfPayment: this.apiURL + '/ProofOfPayment',
      proofOfPaymentLawFirms: this.apiURL + '/ProofOfPayment/LawFirms',
      proofDocumentAttachment: this.apiURL + '/ProofOfPayment/Attachment',
      unPaidInvoicesByClientProofOfPayment: this.apiURL + '/ProofOfPayment/UnPaidInvoicesByClient',
      allFirmsAveragePaymentDays: this.apiURL + '/IndustryAnalysis/api/AllFirmsAveragePaymentDays',
      getAllGcbTooltipList: this.apiURL + '/IndustryAnalysis/api/GetAllGcbTooltipList',
      paymentTrends: this.apiURL + '/IndustryAnalysis/api/GCBPaymentTrends',
      allFirmsAveragePaymentDaysBySearch: this.apiURL + '/IndustryAnalysis/api/AllFirmsAveragePaymentDaysBySearch',
      postProofOfPaymentLines: this.apiURL + '/ProofOfPayment/PostProofOfPaymentLines',
      linesByPopIdProofOfPayment: this.apiURL + '/ProofOfPayment/LinesByPopId',
      removeProofOfPaymentLines: this.apiURL + '/ProofOfPayment/RemoveProofOfPaymentLines',
      uploadBankStatement: this.apiURL + '/UploadBankStatement',
      uploadBankStatementWithDate: this.apiURL + '/UploadBankStatementWithDate',
      uploadBankStatementNoDuplicate: this.apiURL + '/UploadBankStatementNoDuplicates',
      bankTransactions: this.apiURL + '/BankTransaction',
      excludeBankTransaction: this.apiURL + '/BankTransaction/api/ExcludeBankTransaction',
      personalBankTransaction: this.apiURL + '/BankTransaction/api/PersonalBankTransaction',
      lawFirmBankTransaction: this.apiURL + '/BankTransaction/api/LawFirmBankTransaction',
      referToClient: this.apiURL + '/BankTransaction/api/ReferToClient',
      possibleMatchesBankTransaction: this.apiURL + '/BankTransaction/PossibleMatches',
      processedBankTransactions: this.apiURL + '/ProcessedBankTransactions',
      removeFromProcessed: this.apiURL + '/ProcessedBankTransactions/RemoveFromProcessed',
      sendStatements: this.apiURL + '/SendStatements',
      bankStatementSummary: this.apiURL + '/BankStatementSummary',
      proofOfPaymentAttachment: this.apiURL + '/ProofOfPayment/Attachment',
      allocatedToLawfirm: this.apiURL + '/PracticeManagement/api/AllocatedToLawFirm',
      distinctAdvocateList: this.apiURL + '/DistinctAdvocateList',
      singupUser: this.apiURL + '/Invite/user/validateUserSingupRequest',
      GetOfficeUserDetails: this.apiURL + '/Invite/GetOfficeUserDetails',
      manageBlackList: this.apiURL + '/ManageBlackList',
      saveBlackListHandler: this.apiURL + '/ManageBlackList/SaveBlackListedLawfirmsDetails',
      LinkExstAdvocate: this.apiURL + '/ManageBlackList/LinkAdvocateToRegionalBar',
      getLawfirmAdvocates: this.apiURL + '/ManageBlackList/GetLawfirmAdvocates',
      deleteLowfirmListingsItem: this.apiURL + '/ManageBlackList/DeleteLawfirmListingItem',
      deleteAdvocate: this.apiURL + '/ManageBlackList/DeleteAdvocate',
      getbyLawfirmApprovedReq: this.apiURL + '/ManageBlackList/GetbyLawfirmApprovedReq',
      getbyLawfirmLPCReqList: this.apiURL + '/ManageBlackList/GetbyLawfirmLPCReqList',
      updateLPCdetails: this.apiURL + '/ManageBlackList/UpdateLPCdetails',
      gcb_approved_Reject: this.apiURL + '/GCBBlackListResponse/ManageGCBResponse',
      gcb_approved_Reject_Delete_Request: this.apiURL + '/GCBBlackListResponse/ManageGCBDeleteResponse',
      gcb_pending_Advocate: this.apiURL + '/ManageBlackList/GetBlacklistPendingReq',
      get_blackList_File: this.apiURL + '/ManageBlackList/GetBlackListUploadedDocument',
      download_LPC_document: this.apiURL + '/ManageBlackList/DownloadLPCDocument',
      barSocietyNewLawfirmrequest: this.apiURL + '/ManageBlackList/BarSocietyNewLawfirmRequest',
      get_Existing_Advocate_List: this.apiURL + '/ManageBlackList/GetUnassignedAdvocates',
      get_LPC_Status_List: this.apiURL + '/ManageBlackList/GetLPCStatusDrp',
      get_LPC_edit_details: this.apiURL + '/ManageBlackList/GetEditLPCDetails',
      getAccountPlateformTransaction: this.apiURL + '/BankTransaction/GetAccountPlateformTransaction',
      getAccountPlateformTrandsactionLog: this.apiURL + '/BankTransaction/GetAccountPlateformTrandsactionLog',
      sageUserValidation: this.apiURL + '/AccountingPlatform',
      advocateSageSetup: this.apiURL + '/AccountingPlatform/GetAdvocateSageSetup',
      saveAdvocateSageUser: this.apiURL + '/AccountingPlatform/SaveAdvocateSageUser',
      getCurrentAdvocateSageDetails: this.apiURL + '/AccountingPlatform/GetCurrentAdvocateSageDetails',
      removeAdvocateSageSetup: this.apiURL + '/AccountingPlatform/RemoveAdvocateSageSetup',
      getSubscriptionDetails: this.apiURL + '/Onboarding/Subscribe/GetSubscriptionDetails',
      googlePlaces: this.apiURL + '/googleplaces',
      subscriptionBilling: this.apiURL + '/SubscriptionBilling',
      outlookgenearteAccessToken: this.apiURL + '/OutlookCalendar',
      lawfirmStatement: this.apiURL + '/LawFirmStatement'
    };
  } // end constructor()

  handleError(response: HttpErrorResponse) {
    let errorMessage = "";
    const toast: Toast = {
      text: "Network connection error!",
    };
    if (response.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", response.error.message);
      errorMessage = response.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = response.error.Message;
      console.error(
        `Backend returned code ${response.status}, ` +
        `body was: ${response.error}`
      );
      console.error(
        "An error occurred:",
        response,
        response.error,
        response.error.message
      );
      switch (response.status) {
        case HTTP_STATUS_NETWORK_CONNECTION:
          toast.text = "No network connection!";
          break;

        case HTTP_STATUS_BAD_REQUEST:
          toast.text = "Bad request.";
          break;
      }

      // if (this.toastService && this.toastService.toastComponent) {
      //   this.toastService.toastComponent.make(toast).show();
      // }
    }
    // return an observable with a user-facing error message
    const source = throwError(errorMessage);
    return source;
  } // end handleError()
} // end ApiService{}
