import { Component, OnInit, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ReportsService, ReportParameters, PracticeManagementParameters, IndustryParameters } from '../../services/reports/reports.service';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, NavigationStart, Router, Event } from '@angular/router';
import { UserProfileService, UserProfile } from '../../services/user-profile/user-profile.service';
import { SnackbarsService, Snack, SnackType } from '../../services/messaging/snackbars/snackbars.service';
import { Contact, ContactsService } from '../../services/contacts/contacts.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../services/utilities.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import * as moment from 'moment';
import { AgeAnalysisData } from '../../services/age-analysis/age-analysis.service';
import {
  StatementAdvocateSummary,
  StatementByAdvocate,
  LiveStatementService
} from '../../services/live-statement/live-statement.service';
import {
  InvoicedCollectedSummaryComponent
} from '../../components/reports/invoiced-collected-summary/invoiced-collected-summary.component';
import { AgeAnalysisReportComponent } from '../../components/reports/age-analysis/age-analysis.component';
import { AllCustomersReportComponent } from '../../components/reports/all-customers-report/all-customers-report.component';
import {
  CustomersReportPaymentRatingComponent
} from '../../components/reports/customer-report-payment-rating/customer-report-payment-rating.component';
import {
  IndividualCustomerReportComponent
} from '../../components/reports/individual-customer-report/individual-customer-report.component';
import { PracticeSummaryComponent } from '../../components/reports/practice-summary/practice-summary.component';
import {
  LoadingOverlayModalOptions,
  DEFAULT_LOADINGOVERLAY_OPTIONS
} from '../../components/modals/loading-overlay-modal/loading-overlay-modal.component';
import { AdvocateDetailStatementService, ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import {
  PATH_REPORTS,
  PATH_REPORT_AGE_ANALYSIS,
  PATH_REPORT_INVOICE_COLLECTED,
  PATH_CUSTOMER_PAYMENT_RATING,
  PATH_REPORT_ALLCUSTOMER_REPORT,
  PATH_THE_INDIVIDUAL_CUSTOMER,
  PATH_ATTORNEY_DASHBOARD
} from '../../services/appdata/app-config.service';
import {
  ClientSubscriptionSummary,
  DEFAULT_SUBSCRIPTION_SUMMARY,
  PracticeAdvocateHealthService
} from '../../services/practice-advocate-health/practice-advocate-health.service';
import { lastValueFrom, SubscriptionLike as ISubscription } from 'rxjs';
import { NavigationService } from '../../services/navigation/navigation.service';


export enum ReportType {
  BusinessReports = 'businessReports',
  FinancialReports = 'financialReports',
  LiveStatement = 'liveStatement',
  PracticeManagementReports = 'practiceManagementReports',
  IndustryReports = 'industryReport',
  SubscriptionSummaryReport = 'subscriptionSummaryReport'
} // end ReportType{}

export enum ReportName {
  CalendarAction = 'calendarAction',
  DeletedInvoice = 'deletedInvoice',
  Disbursement = 'disbursement',
  FinancialSummaryViewReport = 'financialSummaryViewReport',
  InvoicePaid = 'invoicePaid',
  InvoiceHistory = 'invoiceHistory',
  InvoiceHistoryByLawfirm = 'invoiceHistoryByLawfirm',
  Outstanding = 'outstanding',
  Recon = 'recon',
  ReconByLawfirm = 'reconByLawfirm',
  WorkInProgress = 'workInProgress',
  ChooseAReport = 'null',
  StatementOfAccount = 'statementOfAccount',
  LiveStatement = 'liveStatement',
  InvoicedCollectedMonthlySummary = 'invoicedCollectedMonthlySummary',
  AgeAnalysis = 'ageAnalysis',
  AllCustomersReport = 'allCustomersReport',
  CustomerReportPaymentRating = 'customerReportPaymentRating',
  IndividualCustomerReport = 'individualCustomerReport',
  PracticeSummary = 'practiceSummary',
  monthlyMeeting = 'monthlyMeeting',
  practiceAssessment = 'practiceAssessment',
  avarageDaysToPayReport = 'avarageDaysToPayReport',
  UnallocatedBankTransaction = 'unallocatedBankTransaction',
  MatterStatementOfAccountReport = 'matterStatementOfAccountReport',
  creditNoteReport = 'creditNoteReport',
  writeOffReport = 'writeOffReport'
} // end ReportName{}

export const DEFAULT_REPORT_PARAMETERS = {
  fromDate: '',
  toDate: '',
  lawFirmId: 0,
  fromMonth: '1',
  toMonth: '1',
  fromYear: '2013',
  toYear: '2013',
  serviceProviderId: 0,
  serviceId: 0,
  searchText: '',
  format: 'pdf'
};

interface Debtor {
  FullName: string;
  ContactID: number;
}

@Component({
  selector: 'app-advocate-reports',
  templateUrl: './advocate-reports.component.html',
  styleUrls: ['./advocate-reports.component.scss']
})
export class AdvocateReportsComponent implements OnInit, OnChanges, OnDestroy {

  id = null;
  title = 'Reports';
  crumbs: ICrumb[];
  reportUrl: string;
  debtorData: any = [];
  showFinancialReports: boolean;
  showBusinessReports: boolean;
  showLiveStatementReports: boolean;
  _debtorId: number;
  _defaultTab: string;
  debtorName: string;
  showDebtorErrorPrompt = false;
  selectedButton: any = [
    {
      name: ''
    }
  ];

  userProfile: UserProfile;
  reportType: ReportType; // = ReportType.FinancialReports;
  reportName: ReportName; // = ReportName.FinancialSummaryViewReport;
  _reportName = '';

  practiceManagementParameters: PracticeManagementParameters = {
    fromDate: '',
    toDate: ''
  };
  industryParameters: IndustryParameters = {
    fromDate: 'string'
    // toDate: string;
  };
  reportParameters: ReportParameters = {
    fromDate: '',
    toDate: '',
    lawFirmId: 0,
    fromMonth: '1',
    toMonth: '1',
    fromYear: '2013',
    toYear: '2013',
    serviceProviderId: 0,
    serviceId: 0,
    searchText: '',
    format: 'pdf'
  };

  exportToDocumentType: any;

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  lawFirms: Contact[];
  allLawFirms: Contact[];
  SelectedFirm: Contact[];
  AllSelectedFirm: Contact[];
  selectedLawfirm: string;
  toogleright = true;
  showErrorMessageModelPrompt: boolean;
  errorMessage: string;
  erroMessageTitle: string;

  canViewPracticeAndFinancials = false;

  // canDisplayFinancialReport: boolean;

  // For attorney reports
  ageAnalysisData: AgeAnalysisData;
  statementAdvocateSummary: StatementAdvocateSummary;
  statementByAdvocate: StatementByAdvocate[];

  options: LoadingOverlayModalOptions = Object.assign({}, DEFAULT_LOADINGOVERLAY_OPTIONS);

  isDocumentReport = false;
  clientToSearch: string;
  searchMatterStatementText: string;

  showUpgradeReportPrompt: boolean;
  private _subscriptionNavigationStart: ISubscription;
  private _exitRoute: string;

  // Auxcon Subscription
  clientSubscriptionSummary: ClientSubscriptionSummary = Object.assign({}, DEFAULT_SUBSCRIPTION_SUMMARY);


  @ViewChild('InvoicedCollectedSummaryComponent') invoicedCollectedSummaryReport: InvoicedCollectedSummaryComponent;
  @ViewChild('AgeAnalysisReportComponent') ageAnalysisReportComponent: AgeAnalysisReportComponent;
  @ViewChild('AllCustomersReportComponent') allCustomersReportComponent: AllCustomersReportComponent;
  @ViewChild('CustomersReportPaymentRatingComponent') customersReportPaymentRatingComponent: CustomersReportPaymentRatingComponent;
  @ViewChild('IndividualCustomerReportComponent') individualCustomerReportComponent: IndividualCustomerReportComponent;
  @ViewChild('PracticeSummaryComponent') practiceSummaryComponent: PracticeSummaryComponent;


  @ViewChild('pdfViewer') public pdfViewer;
  reportA = '';

  // Is External Attorney
  get isExternalAttorney(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY);
    // return false;
  } // end isExternalAttorney()

  // Is Internal Lawfirm
  get isInternalLawfirm(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
    // return false;
  } // end isInternalLawfirm()

  // Is Internal isLawfirmAssistant
  get isLawfirmAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator) &&
      Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isLawfirmAssistant()

  get isAdhoc(): boolean {
    if (this.profile)
      if (this.profile.profileType && this.profile.profileType == 'Ad-hoc')
        return true
      else
        return false
    return false
  }
  get canViewFinanctialReport(): boolean {
    if (this.userProfileService.userProfile.isImpersonator) {
      if (this.userProfileService.selectedUserProfile.permissions.view_Report_Financials) {
        this.canViewPracticeAndFinancials = true;
      } else {
        this.canViewPracticeAndFinancials = false;
      }
    } else {
      if (this.userProfileService.userProfile.permissions.view_Report_Financials) {
        this.canViewPracticeAndFinancials = true;
      } else {
        this.canViewPracticeAndFinancials = false;
      }
    }
    return this.canViewPracticeAndFinancials;
  } // end canViewFinanctialReport()

  get isAssistantToLawfirm(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator)
      && Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isAssistantToLawfirm()

  // Is Internal Attorney
  get isAttorney(): boolean {
    let attorneyProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      attorneyProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
    } else {
      attorneyProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
    }
    return attorneyProfile;
  } // end isAttorney()

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    let lawfirmProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      lawfirmProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
    } else {
      lawfirmProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
    }
    return lawfirmProfile;
  } // end isLawfirm()

  get canViewPractiseManagement(): boolean {
    return false;
  } // end canViewPractiseManagement()

  get canDisplayFinancialReport(): boolean {
    if (this.userProfile.isImpersonator) {
      return Boolean(this.userProfileService.selectedUserProfile.permissions.view_Report_Financials);
    } else {
      return Boolean(this.userProfile.permissions.view_Report_Financials);
    }
  } // end showFinancialReports()

  get userId(): number {
    return this.userProfileService.userProfile.serviceProviderID;
  } // end userId()

  profile: any;
  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private loadingService: LoadingService,
    private reportsendpoints: ReportsService,
    private contactsService: ContactsService,
    private utilityService: UtilitiesService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private liveStatementService: LiveStatementService,
    private practiceAdvocateHealthService: PracticeAdvocateHealthService,
    private advocateDetailStatementService: AdvocateDetailStatementService,
    private navigation: NavigationService,

  ) {
    document.title = route.snapshot.data.title;
    // this.showFinancialReports = true;
    this.showBusinessReports = false;
    // this.selectedButton = 'None';

  } // end constructor()

  onHideErrorMessageModelPrompt() {
    this.showErrorMessageModelPrompt = false;
  } // end onHideErrorMessageModelPrompt()

  onClientSearchChange(searchTerm: string) {
    if (event) {
      this.clientToSearch = searchTerm;
    }
  } // end onClientSearchChange()

  ngOnInit() {
    this.crumbs = [
      {
        label: 'Reports',
        // link: PATH_REPORTS
      }
    ];

    this._subscriptionNavigationStart = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart && this.isAdhoc) {
        this._exitRoute = event.url;
        if (location.href.includes(PATH_REPORTS)) {
          this.navigation.canNavigate = false;
            this.showUpgradeReportPrompt = true;
          // if (!this._canIgnoreNavigationBlock) {            
          // } else if (!this._canIgnoreNavigationBlock) {
          //   this.navigation.canNavigate = false;
          //   this.showUpgradeReportPrompt = true;
          // } else {
          //   this.navigation.canNavigate = true;
          // }
        }
      }
    });
    
    this.profile = this.userProfileService.userProfile;
    if (this.isAdhoc) {
      this.togglePanel('industryReport', null);
      return;
    }

    this.exportToDocumentType = [
      { label: 'PDF', value: 'pdf' },
      { label: 'EXCEL', value: 'xls' }
    ]
    // Check if it is Attorney user
    if (this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY ||
      this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY ||
      this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM) {

      this.options.message = 'Loading...';
      this.loadingService.showOverlay(this.options);
      // Fetch Age Anaysis Data (Live Statements)
      this.liveStatementService.getAdvocateStatementAdvocateSummary()
        .subscribe({
          next: (advocates) => {
            if (advocates) {
              this.statementByAdvocate = advocates.StatementByAdvocate;
              this.ageAnalysisData = {
                NormalDay: advocates.ZeroDays,
                ZeroDays: advocates.ZeroDays,
                ThirtyDays: advocates.ThirtyDays,
                SixtyDays: advocates.SixtyDays,
                NinetyDays: advocates.NinetyDays,
                NinetyEightDays: advocates.NinetyEightDays,
                InvoiceStatusGroup: advocates.InvoiceStatusGroup,
                Total: advocates.Total
              };
            }
          },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
          },
          complete: () => {
            this.loadingService.hideOverlay();
          }
        });
    }

    if (this.userProfileService.userProfile.serviceProviderID === 29723) {
      this.contactsService.getLawFirmsByPMServiceProvider(this.userProfileService.userProfile.serviceProviderID)
        .subscribe(lawFirms => {
          this.lawFirms = lawFirms;
          this.SelectedFirm = lawFirms;
        });
    } else {
      this.contactsService.getLawFirmsByServiceProvider()
        .subscribe(lawFirms => {
          this.lawFirms = lawFirms;
          this.SelectedFirm = lawFirms;
        });
    }

    // const reportPage = document.getElementById('ReportPage');
    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    if (this.route.snapshot.paramMap.get('id') !== null) {
        const id = this.route.snapshot.paramMap.get('id');
        if (id === 'work-in-progress') {
          
          this.reportType = ReportType.BusinessReports;
          this.togglePanel(ReportType.BusinessReports, id);
          this.reportName = ReportName.WorkInProgress;
          this._reportName = this.reportName;
          this.viewReport();
        } else {
          this.reportType = ReportType.PracticeManagementReports;
          this.togglePanel(ReportType.PracticeManagementReports, id);
        }
      } else if (this.reportsService.selectedReportType) {
        this.reportType = this.reportsService.selectedReportType;
        this.reportName = ReportName.StatementOfAccount;
        this._reportName = this.reportName;
        this.togglePanel(this.reportType, null);
      } else {
      if (this.userProfileService.userProfile) {
        if (this.userProfileService.userProfile.isImpersonator) {
          if (this.userProfileService.selectedUserProfile.permissions.view_Report_Financials) {
            this.reportType = ReportType.PracticeManagementReports;
            this.reportName = ReportName.PracticeSummary;
            this._reportName = this.reportName;
            this.selectedButton.name = ReportName.PracticeSummary;
            this.togglePanel(ReportType.PracticeManagementReports, null);
          } else {
            this.reportType = ReportType.BusinessReports;
            this.togglePanel(ReportType.BusinessReports, null);
            this.reportName = ReportName.StatementOfAccount;
            this._reportName = this.reportName;
            this.viewReport();
          }
        } else {
          if (this.userProfileService.userProfile.permissions.view_Report_Financials) {
            this.reportType = ReportType.PracticeManagementReports;
            this.reportName = ReportName.PracticeSummary;
            this._reportName = this.reportName;
            this.selectedButton.name = ReportName.PracticeSummary;
            this.togglePanel(ReportType.PracticeManagementReports, null);
          } else {
            this.reportType = ReportType.BusinessReports;
            this.togglePanel(ReportType.BusinessReports, null);
            this.reportName = ReportName.StatementOfAccount;
            this._reportName = this.reportName;
            this.viewReport();
          }
        }
      }
    }

    if (this.pdfViewer && this.pdfViewer.pdfSrc) {
      this.pdfViewer.pdfSrc = ''; // pdfSrc can be Blob or Uint8Array
      this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
    }
  } // end ngOnInit()


  get isInvalidFromToYear(): boolean {
    return Boolean(this.reportParameters.fromYear > this.reportParameters.toYear);
  } // end isInvalidFromToYear()

  get isInvalidFromToMonth(): boolean {
    return Boolean(this.reportParameters.fromYear === this.reportParameters.toYear) &&
      Boolean(this.reportParameters.fromMonth > this.reportParameters.toMonth);
  } // end isInvalidFromToYear()

  get isInvalidFromToDate(): boolean {
    return this.utilityService.isInvalidFromToDate(this.reportParameters.fromDate, this.reportParameters.toDate);
  } // end isInvalidFromToDate()

  get isInvalidPracticeManagementFromToDate(): boolean {
    return this.utilityService.isInvalidFromToDate(this.practiceManagementParameters.fromDate, this.practiceManagementParameters.toDate);
  }

  get isInvalidSelectedLawfirm(): boolean {
    return !Boolean(this.selectedLawfirm !== null);
  } // end isInvalidFromToDate()

  get isSubmitable(): boolean {
    return Boolean(!this.isInvalidFromToDate && !this.isInvalidSelectedLawfirm);
  } // end isSubmitable()

  onInput(event) {
    if (event.inputType === 'deleteContentBackward') {
      this.SelectedFirm = this.lawFirms;
      this.AllSelectedFirm = this.allLawFirms;
    }
  } // end onInput()
  onFocus() {
  } // end onInput()

  onSelect(event: TypeaheadMatch): void {
    this.selectedLawfirm = event.item.FullName;
    if (event.item.ContactID && event.item.ContactID !== 0) {
      this.statementOfAccountReportSubmit(event.item.ContactID);
    } else if (this.selectedLawfirm === 'All') {
      this.statementOfAccountReportSubmit(event.item.ContactID);
    }
  } // end onSelect()

  onFirmSelect(event: TypeaheadMatch): void {
    this.selectedLawfirm = event.item.FullName;
    if (event.item.ContactID && event.item.ContactID !== 0) {
      this.reportParameters.lawFirmId = event.item.ContactID;
    }
  } // end onFirmSelect()

  onLawfirmSelect(event: TypeaheadMatch): void {
    this.selectedLawfirm = event.item.FullName;
    if (event.item.ContactID && event.item.ContactID !== 0) {
      this.reportParameters.lawFirmId = event.item.ContactID;
    }
  } // end onLawfirmSelect

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reportParameters && changes.reportParameters.currentValue) {

    }
  } // end ngOnChanges()

  ngOnDestroy() {
    this.reportsService.selectedReportType = null;
    this.reportsService.selectedReport = null;
    this.snackbarsService.dismiss();
  } // end ngOnDestroy()

  togglePanel(panel: string, id: string) {
    if (this.isAdhoc)
      if (panel != 'industryReport') {
        this.showUpgradeReportPrompt = true;
        return
      }
    this.isDocumentReport = true;
    this._reportName = '';
    switch (panel) {
      case 'financialReports':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Financial Report'
          }
        ];
        this.showFinancialReports = !this.showFinancialReports;
        this.showLiveStatementReports = false;
        this.reportName = ReportName.FinancialSummaryViewReport;
        this.reportType = ReportType.FinancialReports;
        this.viewReport();
        this.crumbs.push({
          label: 'Financial Summary View Report'
        });
        break;

      case 'businessReports':
        this.isDocumentReport = true;
        this.loadingService.showOverlay();
        if (this.userProfileService.userProfile.serviceProviderID === 29723) {
          this.contactsService.getLawFirmsByPMServiceProvider(this.userProfileService.userProfile.serviceProviderID)
            .subscribe({
              next: (lawFirms) => {
                this.lawFirms = lawFirms;
                this.SelectedFirm = lawFirms;
              },
              error: (error) => {
                // On Error
                this.loadingService.hideOverlay();
              },
              complete: () => {
                // On complete
                this.loadingService.hideOverlay();
              }
            });
        } else {
          if (this.userProfileService.userProfile.serviceProviderID === 29723) {
            this.contactsService.getLawFirmsByPMServiceProvider(this.userProfileService.userProfile.serviceProviderID)
              .subscribe({
                next: (lawFirms) => {
                  this.lawFirms = lawFirms;
                  this.SelectedFirm = lawFirms;
                },
                error: (error) => {
                  // On Error
                  this.loadingService.hideOverlay();
                },
                complete: () => {
                  // On complete
                  this.loadingService.hideOverlay();
                }
              });
          } else {
            this.contactsService.getLawFirmsByServiceProvider()
              .subscribe({
                next: (lawFirms) => {
                  this.lawFirms = lawFirms;
                  this.SelectedFirm = lawFirms;
                },
                error: (error) => {
                  // On Error
                  this.loadingService.hideOverlay();
                },
                complete: () => {
                  // On complete
                  this.loadingService.hideOverlay();
                }
              });
          }
        }
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Business Report'
          }
        ];
        this.showBusinessReports = !this.showBusinessReports;
        this.showLiveStatementReports = false;
        this.reportName = ReportName.StatementOfAccount;
        this.reportType = ReportType.BusinessReports;
        this.viewReport();
        this.crumbs.push({
          label: 'Statement of Account Report'
        });
        break;

      case 'liveStatement':
        this.isDocumentReport = false;
        this.showBusinessReports = false;
        this.reportName = ReportName.LiveStatement;
        this.reportsService.selectedReport = ReportName.LiveStatement;
        this.reportType = ReportType.LiveStatement;
        break;
      case 'practiceManagementReports':
        this.isDocumentReport = false;
        this.crumbs = [
          {
            label: 'My Practice',
            link: null
          }];
          
        if (id === 'age-analysis') {
          this.reportName = ReportName.AgeAnalysis;
        } else if (id === 'customer-payment-rating') {
          this.reportName = ReportName.CustomerReportPaymentRating;
        } else {
          this.crumbs = [
            {
              label: 'Practice Summary',
              link: null
            }];
          this.reportName = ReportName.PracticeSummary;
        }
        this._reportName = this.reportName;
        this.reportType = ReportType.PracticeManagementReports;
        break;

      case 'industryReport':
        this.isDocumentReport = false;
        this.reportType = ReportType.IndustryReports;
        this.reportName = ReportName.avarageDaysToPayReport;
        this._reportName = this.reportName;
        this.showLiveStatementReports = false;
        this.viewReport();
        this.reportParameters.fromDate = moment().subtract(36, 'months').startOf('month').format('YYYY-MM-DD');
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Industry Reports'
          },
          {
            label: 'Average Days to Pay Report'
          }
        ];
        break;
      case 'subscriptionSummaryReport':
        this.reportType = ReportType.SubscriptionSummaryReport;
        this._reportName = 'subscriptionSummaryReport';
        this.showLiveStatementReports = false;
        this.isDocumentReport = false;
        this.getSubscriptionData();
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Auxcon Subscription'
          }
        ];
        break;
    }
  } // end togglePanel()

  toggleDropright() {
    switch (this.reportName) {
      case 'invoicePaid':
        if (document.getElementById('vatReport').style.right &&
          document.getElementById('vatReport').style.right === '-14%') {
          document.getElementById('vatReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('vatReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'invoiceHistory':
        if (document.getElementById('invoiceHistoryReport').style.right &&
          document.getElementById('invoiceHistoryReport').style.right === '-14%') {
          document.getElementById('invoiceHistoryReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('invoiceHistoryReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'invoiceHistoryByLawfirm':
        if (document.getElementById('invoiceHistoryByLawfirmReport').style.right &&
          document.getElementById('invoiceHistoryByLawfirmReport').style.right === '-14%') {
          document.getElementById('invoiceHistoryByLawfirmReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('invoiceHistoryByLawfirmReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'disbursement':
        if (document.getElementById('disbursementReport').style.right &&
          document.getElementById('disbursementReport').style.right === '-14%') {
          document.getElementById('disbursementReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('disbursementReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'recon':
        if (document.getElementById('reconciliationHistoryReport').style.right &&
          document.getElementById('reconciliationHistoryReport').style.right === '-14%') {
          document.getElementById('reconciliationHistoryReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('reconciliationHistoryReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'calendarAction':
        if (document.getElementById('calendarActionReport').style.right &&
          document.getElementById('calendarActionReport').style.right === '-14%') {
          document.getElementById('calendarActionReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('calendarActionReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'deletedInvoice':
        if (document.getElementById('financialReport').style.right &&
          document.getElementById('financialReport').style.right === '-14%') {
          document.getElementById('financialReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('financialReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'financialSummaryViewReport':
        if (document.getElementById('financialReport').style.right &&
          document.getElementById('financialReport').style.right === '-14%') {
          document.getElementById('financialReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('financialReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'statementOfAccount':
        break;
    }
  } // end toggleDropright()

  viewReport() {

    this._defaultTab = null;

    this._debtorId = null;

    this.reportParameters.fromDate = null;
    this.reportParameters.toDate = null;
    this.selectedLawfirm = null;
    this.toogleright = true;
    this.reportParameters = Object.assign({}, DEFAULT_REPORT_PARAMETERS);

    switch (this.reportName) {
      case 'invoicePaid':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Financial Report'
          },
          {
            label: 'VAT on Invoice Paid Report'
          }
        ];
        this._reportName = this.reportName;
        this.contactsService.getAllLawFirmsByServiceProvider()
          .subscribe(allLawFirms => {
            this.allLawFirms = allLawFirms;
            this.AllSelectedFirm = allLawFirms;
          });
        break;

      case 'liveStatement':
        this.router.navigate([PATH_ATTORNEY_DASHBOARD]);
        break;

      case 'creditNoteReport':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Financial Report'
          },
          {
            label: 'Credit Note Report'
          }
        ];
        this._reportName = this.reportName;
        this.contactsService.getAllLawFirmsByServiceProvider()
          .subscribe(allLawFirms => {
            this.allLawFirms = allLawFirms;
            this.AllSelectedFirm = allLawFirms;
          });

        break;

      case 'writeOffReport':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Financial Report'
          },
          {
            label: 'Write-Off Report'
          }
        ];
        this._reportName = this.reportName;
        this.contactsService.getAllLawFirmsByServiceProvider()
          .subscribe(allLawFirms => {
            this.allLawFirms = allLawFirms;
            this.AllSelectedFirm = allLawFirms;
          });

        break;

      case 'invoiceHistory':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Financial Report'
          },
          {
            label: 'Invoice History Report'
          }
        ];
        this._reportName = this.reportName;
        this.contactsService.getAllLawFirmsByServiceProvider()
          .subscribe(allLawFirms => {
            this.allLawFirms = allLawFirms;
            this.AllSelectedFirm = allLawFirms;
          });

        break;

      case 'invoiceHistoryByLawfirm':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Financial Report'
          },
          {
            label: 'Invoice History By Law Firm Report'
          }
        ];
        this._reportName = this.reportName;
        this.loadingService.showOverlay();
        if (this.userProfileService.userProfile.serviceProviderID === 29723) {
          this.contactsService.getLawFirmsByPMServiceProvider(this.userProfileService.userProfile.serviceProviderID)
            .subscribe(lawFirms => {
              this.allLawFirms = lawFirms;
              this.AllSelectedFirm = lawFirms;
            });
          this.loadingService.hideOverlay();
        } else {
          this.contactsService.getAllLawFirmsByServiceProvider()
            .subscribe({
              next: (allLawFirms) => {
                this.allLawFirms = allLawFirms;
                this.AllSelectedFirm = allLawFirms;
              },
              error: (error) => {
                // On error
                this.loadingService.hideOverlay();
              },
              complete: () => {
                // On Complete
                const _contacts: Contact = {
                  FullName: 'All',
                  ContactID: 0,
                  City: '',
                  ContactTypeID: 0,
                  FullDescription: '',
                  State: ''
                };
                this.AllSelectedFirm.push(_contacts);
                this.AllSelectedFirm.sort((a, b) => a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase()));
                this.loadingService.hideOverlay();
              }
            });
        }
        break;

      case 'disbursement':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Financial Report'
          },
          {
            label: '98 Day Disbursement Report'
          }
        ];
        this._reportName = this.reportName;
        this.contactsService.getAllLawFirmsByServiceProvider()
          .subscribe(allLawFirms => {
            this.allLawFirms = allLawFirms;
            this.AllSelectedFirm = allLawFirms;
          });
        break;

      case 'recon':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Financial Report'
          },
          {
            label: 'Reconciliation History Report'
          }
        ];
        this._reportName = this.reportName;
        this.contactsService.getAllLawFirmsByServiceProvider()
          .subscribe(allLawFirms => {
            this.allLawFirms = allLawFirms;
            this.AllSelectedFirm = allLawFirms;
          });

        break;

      case 'reconByLawfirm':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Financial Report'
          },
          {
            label: 'Reconciliation History by Lawfirm Report'
          }
        ];
        this._reportName = this.reportName;
        this.loadingService.showOverlay();
        if (this.userProfileService.userProfile.serviceProviderID === 29723) {
          this.contactsService.getLawFirmsByPMServiceProvider(this.userProfileService.userProfile.serviceProviderID)
            .subscribe(lawFirms => {
              this.allLawFirms = lawFirms;
              this.AllSelectedFirm = lawFirms;
            });
          this.loadingService.hideOverlay();
        } else {
          this.contactsService.getAllLawFirmsByServiceProvider()
            .subscribe({
              next: (allLawFirms) => {
                this.allLawFirms = allLawFirms;
                this.AllSelectedFirm = allLawFirms;
              },
              error: (error) => {
                // On error
                this.loadingService.hideOverlay();
              },
              complete: () => {
                // On Complete
                const allcontacts: Contact = {
                  FullName: 'All',
                  ContactID: 0,
                  City: '',
                  ContactTypeID: 0,
                  FullDescription: '',
                  State: ''
                };
                this.AllSelectedFirm.push(allcontacts);
                this.AllSelectedFirm.sort((a, b) => a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase()));
                this.loadingService.hideOverlay();
              }
            });
        }
        break;

      case 'workInProgress':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Business Report'
          },
          {
            label: 'Work In Progress Report'
          }
        ];
        this._reportName = this.reportName;
        this.loadingService.showOverlay();
        this.reportUrl = this.api.endpoints.workInProgressReport +
          '=' + this.api.serviceProviderID;
        this.reportsService
          .getReports(this.reportUrl)
          .subscribe({
            next: (res) => {
              this.pdfViewer.name = 'name.pdf';
              this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
              this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
            },
            error: (error) => {

            },
            complete: () => {
              // complete
              this.loadingService.hideOverlay();
            }
          });
        this.selectedButton.name = 'workInProgress';
        break;

      case 'calendarAction':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          },
          {
            label: 'Calendar Action Report'
          }
        ];
        this._reportName = this.reportName;
        break;

      case 'deletedInvoice':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          },
          {
            label: 'Deleted Invoice Report'
          }
        ];
        this._reportName = this.reportName;
        this.loadingService.showOverlay();
        this.reportUrl = this.api.endpoints.deletedInvoiceReport +
          '=' + this.api.serviceProviderID;
        this.reportsService
          .getReports(this.reportUrl)
          .subscribe({
            next: (res) => {
              this.pdfViewer.name = 'name.pdf';
              this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
              this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
            },
            error: (error) => {
              // error
            },
            complete: () => {
              // complete
              this.loadingService.hideOverlay();
            }
          });
        this.selectedButton.name = 'deletedInvoice';
        break;

      case 'unallocatedBankTransaction':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Financial Report',
            // link: null
          },
          {
            label: 'Unallocated Transaction'
          }
        ];
        this._reportName = this.reportName;
        this.loadingService.showOverlay();
        this.reportUrl = this.api.endpoints.unallocatedTransactionReport +
          '=' + this.api.serviceProviderID;
        this.reportsService
          .getReports(this.reportUrl)
          .subscribe({
            next: (res) => {
              this.pdfViewer.name = 'name.pdf';
              this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
              this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
            },
            error: (error) => {
              // error
            },
            complete: () => {
              // complete
              this.loadingService.hideOverlay();
            }
          });
        this.selectedButton.name = 'unallocatedBankTransaction';
        break;

      case 'monthlyMeeting':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Financial Report',
            // link: null
          },
          {
            label: 'Monthly Meeting Report'
          }
        ];
        this._reportName = this.reportName;
        const _tod = new Date();
        const _fromd = new Date(_tod.getFullYear(), _tod.getMonth() - 12, _tod.getDate());
        this.reportParameters.fromDate = _fromd.toDateString();
        this.reportParameters.toDate = _tod.toDateString();
        this.monthlyMeetingReportSubmit();
        break;

      case 'practiceAssessment':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Financial Report',
            // link: null
          },
          {
            label: 'Practice Assessment Report'
          }
        ];
        this._reportName = this.reportName;
        this.loadingService.showOverlay();
        this.reportUrl = this.api.endpoints.practiceAssessmentReport +
          '=' + this.api.serviceProviderID;
        this.reportsService
          .getReports(this.reportUrl)
          .subscribe({
            next: (res) => {
              this.pdfViewer.name = 'name.pdf';
              this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
              this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
            },
            error: (error) => {
              // error
            },
            complete: () => {
              // complete
              this.loadingService.hideOverlay();
            }
          });
        this.selectedButton.name = 'practiceAssessment';
        break;

      case 'outstanding':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          },
          {
            label: 'Outstanding Invoice Report'
          }
        ];
        this._reportName = this.reportName;
        this.loadingService.showOverlay();
        this.reportUrl = this.api.endpoints.outstandingInvoiceReport +
          '=' + this.api.serviceProviderID;
        this.reportsService
          .getReports(this.reportUrl)
          .subscribe({
            next: (res) => {
              this.pdfViewer.name = 'name.pdf';
              this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
              this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
            },
            error: (error) => {
              // Error
            },
            complete: () => {
              // complete
              this.loadingService.hideOverlay();
            }
          });
        this.selectedButton.name = 'outstanding';
        break;

      case 'financialSummaryViewReport':
        this._reportName = this.reportName;
        break;

      case 'statementOfAccount':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          },
          {
            label: 'Statement of Account Report'
          }
        ];
        this.selectedButton.name = 'statementOfAccount';
        this._reportName = this.reportName;
        break;

      case 'matterStatementOfAccountReport':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          },
          {
            label: 'Statement of Account Report'
          }
        ];
        this.selectedButton.name = 'matterStatementOfAccountReport';
        this._reportName = this.reportName;
        break;

      case 'invoicedCollectedMonthlySummary':
        this.router.navigate([PATH_REPORT_INVOICE_COLLECTED]);
        break;

      case 'ageAnalysis':
        this.router.navigate([PATH_REPORT_AGE_ANALYSIS]);
        break;

      case 'allCustomersReport':
        this.router.navigate([PATH_REPORT_ALLCUSTOMER_REPORT]);
        break;

      case 'customerReportPaymentRating':
        this.router.navigate([PATH_CUSTOMER_PAYMENT_RATING]);
        break;

      case 'individualCustomerReport':
        this.reportsService.setIndividualCustomerParameters(null);
        this._reportName = this.reportName;
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'My Practice',
            // link: null
          }
        ];
        this.getDebtorList();
        break;

      case 'practiceSummary':
        this.showLiveStatementReports = false;
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'My Practice',
            // link: null
          },
          {
            label: 'Practice Summary',
            // link: PATH_REPORTS
          }
        ];
        this._reportName = this.reportName;
        break;

      default:
        break;
    }
  } // end viewReport()

  statementOfAccountReportSubmit(lawFirmId) {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.statementOfAccountsReport +
      '=' + this.api.serviceProviderID +
      '&lawFirmId=' + lawFirmId;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe({
        next: (res) => {
          this.pdfViewer.name = 'name.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        },
        error: (error) => {
          // Error
        },
        complete: () => {
          this.loadingService.hideOverlay();
        }
      });
  } // end statementOfAccountReportSubmit()

  matterStatementOfAccountReportSubmit(lawFirmId: number, searchText: string) {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.matterStatementOfAccountsReport +
      '=' + this.api.serviceProviderID +
      '&lawFirmId=' + lawFirmId + '&SearchText=' + searchText;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe({ next: (res) => {
        this.pdfViewer.name = 'statement.pdf';
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      },
        error: (error) => {
          // Error
        },
        complete: () => {
          this.loadingService.hideOverlay();
        }
      });
  } // end matterStatementOfAccountReportSubmit()

  financialReportSubmit() {
    this.loadingService.showOverlay();

    this.reportUrl = this.api.endpoints.financialSummaryViewReport +
      '=' + this.api.serviceProviderID +
      '&fromYear=' + this.reportParameters.fromYear +
      '&fromMonth=' + this.reportParameters.fromMonth +
      '&toYear=' + this.reportParameters.toYear +
      '&toMonth=' + this.reportParameters.toMonth +
      '&format=' + this.reportParameters.format;
      console.log('PARAMETERS: ', this.reportParameters, this.reportUrl);
      if (this.reportParameters.format == 'xls') {
        this.reportsService
          .getReports(this.reportUrl)
          .subscribe({
            next: (res,) => {
              this.advocateDetailStatementService.downloadExcelFile(res,
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
              // this.pdfViewer.name = 'name.pdf';
              // this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
              // this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
            },
            error: (error) => {
              // Error
            },
            complete: () => {
              // Complete
              this.loadingService.hideOverlay();
            }
          });
      } else if (this.reportParameters.format == 'pdf') {
        this.reportsService
          .getReports(this.reportUrl)
          .subscribe({
            next: (res) => {
              this.pdfViewer.name = 'name.pdf';
              this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
              this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
            },
            error: (error) => {
              // Error
            },
            complete: () => {
              // Complete
              this.loadingService.hideOverlay();
            }
          });
      }
    // this.reportsService
    //   .getReports(this.reportUrl)
    //   .subscribe({
    //     next: (res) => {
    //       // this.pdfViewer.name = 'name.pdf';
    //       // this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
    //       // this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf

    //       this.advocateDetailStatementService.downloadExcelFile(res,
    //         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    //     },
    //     error: (error) => {
    //       // Error
    //     },
    //     complete: () => {
    //       // Complete
    //       this.loadingService.hideOverlay();
    //     }
    //   });
  } // end financialReportSubmit()

  calendarActionReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.calendarActionReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe({
        next: (res) => {
          this.pdfViewer.name = 'name.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        },
        error: (error) => {
          // error
        },
        complete: () => {
          // complete
          this.loadingService.hideOverlay();
        }
      });
  } // end calendarActionReportSubmit()

  reconciliationHistoryReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.reconciliationHistoryReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe({
        next: (res) => {
          this.pdfViewer.name = 'name.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        },
        error: () => {
          // Error
        },
        complete: () => {
          // Complete
          this.loadingService.hideOverlay();
        }
      });
  } // end reconciliationHistoryReportSubmit()

  reconciliationHistoryByLawfirmReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.reconciliationHistoryByLawfirmReport +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate +
      '&ServiceProviderId=' + this.api.serviceProviderID +
      '&LawFirmId=' + this.reportParameters.lawFirmId;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe({
        next: (res) => {
          this.pdfViewer.name = 'name.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        },
        error: (error) => {
          // Error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // Complete
          this.loadingService.hideOverlay();
        }
      });
  } // end reconciliationHistoryByLawfirmReportSubmit()

  disbursementReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.disbursementReport + '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe({
        next: (res) => {
          this.pdfViewer.name = 'name.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        },
        error: (error) => {
          // Error
        },
        complete: () => {
          // Complete
          this.loadingService.hideOverlay();
        }
      });
  } // end disbursementReportSubmit()

  vatReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.vatOnPaidInvoicesReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate +
      '&format=' + this.reportParameters.format;
    if (this.reportParameters.format == 'xls') {
      this.reportsService
        .getReports(this.reportUrl)
        .subscribe({
          next: (res,) => {
            this.advocateDetailStatementService.downloadExcelFile(res,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            // this.pdfViewer.name = 'name.pdf';
            // this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            // this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
          },
          error: (error) => {
            // Error
          },
          complete: () => {
            // Complete
            this.loadingService.hideOverlay();
          }
        });
    } else if (this.reportParameters.format == 'pdf') {
      this.reportsService
        .getReports(this.reportUrl)
        .subscribe({
          next: (res) => {
            this.pdfViewer.name = 'name.pdf';
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
          },
          error: (error) => {
            // Error
          },
          complete: () => {
            // Complete
            this.loadingService.hideOverlay();
          }
        });
    }
  } // end vatReportSubmit()

  // vatReportSubmit() {
  //   this.loadingService.showOverlay();
  //   this.reportUrl = this.api.endpoints.vatOnPaidInvoicesReport +
  //     '=' + this.api.serviceProviderID +
  //     '&fromDate=' + this.reportParameters.fromDate +
  //     '&toDate=' + this.reportParameters.toDate;
  //   this.reportsService
  //     .getReports(this.reportUrl)
  //     .subscribe({ next: (res) => {
  //       this.pdfViewer.name = 'name.pdf';
  //       this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
  //       this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
  //     },
  //       error: (error) => {
  //         // Error
  //       },
  //       complete: () => {
  //         // Complete
  //         this.loadingService.hideOverlay();
  //       }
  //     });
  // } // end vatReportSubmit()

  invoiceHistoryReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.invoiceHistoryReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate +
      '&format=' + this.reportParameters.format;
    if (this.reportParameters.format == 'xls') {
      this.reportsService
        .getReports(this.reportUrl)
        .subscribe({
          next: (res,) => {
            this.advocateDetailStatementService.downloadExcelFile(res,
              'text/csv')
            // this.pdfViewer.name = 'name.pdf';
            // this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            // this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf2
          },
          error: (error) => {
            // Error
          },
          complete: () => {
            // Complete
            this.loadingService.hideOverlay();
          }
        });
    } else if (this.reportParameters.format == 'pdf') {
    
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe({
        next: (res) => {
          this.pdfViewer.name = 'name.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        },
        error: (error) => {
          // Error
        },
        complete: () => {
          // Complete
          this.loadingService.hideOverlay();
        }
      });
    }
  } // end invoiceHistoryReportSubmit()

  creditNoteReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.creditNoteReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe({
        next: (res) => {
          this.pdfViewer.name = 'name.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        },
        error: (error) => {
          // Error
        },
        complete: () => {
          // Complete
          this.loadingService.hideOverlay();
        }
      });
  } // end invoiceHistoryReportSubmit()

  writeOffReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.writeOffReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe({
        next: (res) => {
          this.pdfViewer.name = 'name.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        },
        error: (error) => {
          // Error
        },
        complete: () => {
          // Complete
          this.loadingService.hideOverlay();
        }
      });
  } // end invoiceHistoryReportSubmit()

  monthlyMeetingReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.monthlyMeetingReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe({
        next: (res) => {
          this.pdfViewer.name = 'name.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        },
        error: (error) => {
          // error
        },
        complete: () => {
          // complete
          this.loadingService.hideOverlay();
        }
      });
    this.selectedButton.name = 'monthlyMeeting';
  } // end monthlyMeetingReportSubmit()

  invoiceHistoryByLawfirmReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.invoiceHistoryByLawFirm +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate +
      '&lawFirmId=' + this.reportParameters.lawFirmId;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe({
        next: (res) => {
          this.pdfViewer.name = 'name.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        },
        error: (error) => {
          // Error
        },
        complete: () => {
          // Complete
          this.reportParameters.lawFirmId = 0;
          this.loadingService.hideOverlay();
        }
      });
  } // end invoiceHistoryByLawfirmReportSubmit()

  onPracticeManagementFromDateValueChange(value: Date): void {
    if (value) {
      this.practiceManagementParameters.fromDate = moment(value).format('YYYY-MM-DD');
    }
    this.practiceManagementParameters.toDate = this.reportParameters.toDate;
    // if (this.isInvalidPracticeManagementFromToDate === false) {
    //   this.reportsService.setPracticeManagementParameters(this.practiceManagementParameters);
    // }
  }
  onAverageFromDateValueChange(value: Date): void {
    if (value) {
      this.industryParameters.fromDate = moment(value).format('YYYY-MM-DD');
      this.reportsService.setAverageDaysParameters(this.industryParameters);
    }
  }

  onPracticeManagementToDateValueChange(value: Date): void {
    if (value) {
      this.practiceManagementParameters.toDate = moment(value).format('YYYY-MM-DD');
    }
    this.practiceManagementParameters.fromDate = this.reportParameters.fromDate;
    if (this.isInvalidPracticeManagementFromToDate === false) {
      this.reportsService.setPracticeManagementParameters(this.practiceManagementParameters);
    } else {
      this.erroMessageTitle = 'An error occurred';
      this.errorMessage = 'From Date cannot be greater than To Date.';
      this.showErrorMessageModelPrompt = true;
    }
  }

  onFromDateValueChange(value: Date): void {
    this.reportParameters.fromDate = value.toDateString();
  } // end onFromDateValueChange()

  onToDateValueChange(value: Date): void {
    this.reportParameters.toDate = value.toDateString();
  } // end onToDateValueChange()

  onClickViewPracticeManagementReport() {
    if (this.isInvalidPracticeManagementFromToDate === false) {
      this.reportsService.setPracticeManagementParameters(this.practiceManagementParameters);
    } else {
      this.erroMessageTitle = 'An error occurred';
      this.errorMessage = 'From Date cannot be greater than To Date.';
      this.showErrorMessageModelPrompt = true;
    }
  } // end onClickViewPracticeManagementReport()

  onAverageClickViewReport() {
    if (this.reportParameters.fromDate !== null) {
      this.reportsService.setAverageDaysParameters(this.reportParameters);
    } else {
      this.erroMessageTitle = 'An error occurred';
      this.errorMessage = 'Please select from date from date picker';
    }
  }
  onClickMatterStatementReport() {
    if (this.reportName === 'matterStatementOfAccountReport') {
      this.matterStatementOfAccountReportSubmit(this.reportParameters.lawFirmId, this.searchMatterStatementText);
    }
  }
  onClickViewReport() {
    if (this.reportParameters.fromDate !== null && this.reportParameters.toDate !== null &&
      this.utilityService.isInvalidFromToDate(this.reportParameters.toDate, this.reportParameters.fromDate)) {
      if (this.reportName === 'calendarAction') {
        this.calendarActionReportSubmit();
      } else if (this.reportName === 'invoiceHistory') {
        this.invoiceHistoryReportSubmit();
      } else if (this.reportName === 'creditNoteReport') {
        this.creditNoteReportSubmit();
      } else if (this.reportName === 'writeOffReport') {
        this.writeOffReportSubmit();
      } else if (this.reportName === 'recon') {
        this.reconciliationHistoryReportSubmit();
      } else if (this.reportName === 'reconByLawfirm') {
        if (this.selectedLawfirm.trim() === 'All') {
          this.reconciliationHistoryReportSubmit();
        } else {
          this.reconciliationHistoryByLawfirmReportSubmit();
        }
      } else if (this.reportName === 'invoicePaid') {
        this.vatReportSubmit();
      } else if (this.reportName === 'disbursement') {
        this.disbursementReportSubmit();
      } else if (this.reportName === 'monthlyMeeting') {
        this.monthlyMeetingReportSubmit();
      } else if (this.reportName === 'invoiceHistoryByLawfirm') {
        if (this.reportParameters.lawFirmId > 0) {
          this.invoiceHistoryByLawfirmReportSubmit();
        } else if (this.selectedLawfirm) {
          if (this.selectedLawfirm.trim() === 'All') {
            this.invoiceHistoryByLawfirmReportSubmit();
          } else {
            this.erroMessageTitle = 'An error occurred';
            this.errorMessage = 'Please select a Law Firm from predefined list.';
            this.showErrorMessageModelPrompt = true;
          }
        } else {
          this.erroMessageTitle = 'An error occurred';
          this.errorMessage = 'Please select a Law Firm from predefined list.';
          this.showErrorMessageModelPrompt = true;
        }
      }
    } else {
      this.erroMessageTitle = 'An error occurred';
      this.errorMessage = 'From Date cannot be greater than To Date.';
      this.showErrorMessageModelPrompt = true;
    }
  } // end onClickViewReport()

  selectedToMonth(event) {
    if (Number(this.reportParameters.fromYear) < Number(this.reportParameters.toYear) ||
      (Number(this.reportParameters.fromYear) <= Number(this.reportParameters.toYear)) &&
      (Number(this.reportParameters.fromMonth) < Number(this.reportParameters.toMonth))) {
      this.financialReportSubmit();
    } else {
      this.erroMessageTitle = 'An error occurred';
      this.errorMessage = 'From Date cannot be greater than To Date.';
      this.showErrorMessageModelPrompt = true;
    }
  } // end selectedToMonth()

  receiveDebtorID($event) {
    this.reportName = ReportName.IndividualCustomerReport;
    this._reportName = this.reportName;
    this.viewReport();
    this._debtorId = $event;
  }

  receiveAllCustomer($event) {

    this.reportName = ReportName.AllCustomersReport;
    this._reportName = this.reportName;
    this.viewReport();
    this._defaultTab = $event;
  }

  receiveCustomerReport($event) {

    if ($event === ReportName.CustomerReportPaymentRating) {
      this.reportName = ReportName.CustomerReportPaymentRating;
    } else if ($event === ReportName.AgeAnalysis) {
      this.reportName = ReportName.AgeAnalysis;
    } else if ($event === ReportName.InvoicedCollectedMonthlySummary) {
      this.reportName = ReportName.InvoicedCollectedMonthlySummary;
    }
    this._reportName = this.reportName;
    this.viewReport();

  }

  getDebtorList(): void {
    this.reportsService.getDistinctCustomerList().subscribe({ next: (response) => {
      if (response) {
        this.debtorData = response;
        this.debtorData.sort((a, b) => {
          if (a.fullName !== undefined && b.fullName !== undefined) {
          return a.fullName.toUpperCase().localeCompare(b.fullName.toUpperCase());
          }
        });
      }
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        if (this._debtorId == null) {
          // this._debtorId = this.debtorData[0].contactID;
          this.debtorName = '';
        } else {
          const _scope = this;
          const currentDebtor = this.debtorData.filter(function (f) {
            return f.contactID === _scope._debtorId;
          })[0];
          this.debtorName = currentDebtor.fullName;
        }
        this.reportsService.setIndividualCustomerParameters(this._debtorId);
      }
    });
  }

  onDebtorSelect(event: TypeaheadMatch): void {
    if (event) {
      this.reportsService.individualCustomerActiveTab = 'all';
      this.reportsService.individualCustomerFrom = 'the-individual-customer';
      this.router.navigate([PATH_THE_INDIVIDUAL_CUSTOMER + '/' + event.item.contactID]);
    } else {
      this.reportsService.setIndividualCustomerParameters(null);
    }
  }

  onDebtorBlur() {
    setTimeout(() => {
      const _scope = this;
      const debtors = this.debtorData.filter(function (f) {
        return f.fullName === _scope.debtorName;
      });
      if (debtors.length === 0) {
        this.debtorName = '';
        this.showDebtorErrorPrompt = true;
        this.reportsService.setIndividualCustomerParameters(null);
      }
    }, 300);
  }

  onHideDebtorErrorPrompt() {
    this.showDebtorErrorPrompt = false;
  }

  getSubscriptionData() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Loading Subscription summary data...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();

    this.practiceAdvocateHealthService.getSubscriptionSummary().subscribe({
      next:
        (data) => {
          // On next
          this.clientSubscriptionSummary = data;
        },
      error: (error) => {
        // On error
        snack.label = 'Error loading subscription data';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        snack.label = 'Subscription summary data loaded.';
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    });
  } // end getSubscriptionData()

  onHideUpgradeReportPrompt() {
    this.showUpgradeReportPrompt = false;
  } // end   onHideUnsavedChangesPrompt()

  dismiss() {
    this.onHideUpgradeReportPrompt()
  } // end submitRequest()

  onDismiss() {
    // if (this.showUpgr
    this.dismiss();
    //   this.showUpgradeReportPrompt = true;
    // } else {
    //   this.dismiss();
    // }
  } // end onDismiss()

} // end AdvocateReportsComponent{}
