import { Component, OnInit, Input } from '@angular/core';
import {
  ClientSubscriptionSummary,
  ClientSubscriptionDetail,
  PracticeAdvocateHealthService
} from '../../../services/practice-advocate-health/practice-advocate-health.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { Snack, SnackbarsService, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

interface ClientSubscriptionDetailHandle {
  data: ClientSubscriptionDetail;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}
@Component({
  selector: 'app-subscription-summary',
  templateUrl: './subscription-summary.component.html',
  styleUrls: ['./subscription-summary.component.scss']
})
export class SubscriptionSummaryComponent implements OnInit {

  row = '';
  sortBy: string;
  searchTerm = '';
  sortAmount: boolean;
  sortDueAmount: boolean;
  previousSortBy: string;
  sortAscending: boolean;
  sortAdvocateName: boolean;
  sortInvoicedAmount: boolean;

  totalCollectionsAmount = 0;
  totalInvoicedAmount = 0;
  totalDueAmount = 0;

  clientSubscriptionDetail: ClientSubscriptionDetail[] = [];
  clientSubscriptionDetailHandle: ClientSubscriptionDetailHandle[] = [];
  @Input() clientSubscriptionSummary: ClientSubscriptionSummary;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private loadingService: LoadingService,
    private snackbarService: SnackbarsService,
    private userProfileService: UserProfileService,
    private practiceAdvocateHealthService: PracticeAdvocateHealthService
  ) { } // end construction()

  ngOnInit() {
  } // end ngOnInit()

  toggleSort(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch (this.sortBy) {
      case 'advocate-name':
        this.clientSubscriptionDetailHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = true;
            this.sortAmount = false;
            this.sortInvoicedAmount = false;
            this.sortDueAmount = false;
            return a.data.AdvocateName.toUpperCase().localeCompare(b.data.AdvocateName.toUpperCase());
          }
          this.sortAdvocateName = false;
          return b.data.AdvocateName.toUpperCase().localeCompare(a.data.AdvocateName.toUpperCase());
        });
      break;

      case 'amount':
        this.clientSubscriptionDetailHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = false;
            this.sortAmount = true;
            this.sortInvoicedAmount = false;
            this.sortDueAmount = false;
            return a.data.ChargedAmount - b.data.ChargedAmount;
          }
          this.sortAmount = false;
          return b.data.ChargedAmount - a.data.ChargedAmount;
        });
      break;

      case 'invoiced-amount':
        this.clientSubscriptionDetailHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = false;
            this.sortAmount = false;
            this.sortInvoicedAmount = true;
            this.sortDueAmount = false;
            return a.data.InvoicedAmount - b.data.InvoicedAmount;
          }
          this.sortInvoicedAmount = false;
          return b.data.InvoicedAmount - a.data.InvoicedAmount;
        });
      break;

      case 'due-amount':
        this.clientSubscriptionDetailHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = false;
            this.sortAmount = false;
            this.sortInvoicedAmount = false;
            this.sortDueAmount = true;
            return a.data.TotalOwingAuxcon - b.data.TotalOwingAuxcon;
          }
          this.sortDueAmount = false;
          return b.data.TotalOwingAuxcon - a.data.TotalOwingAuxcon;
        });
      break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

  onRowClick(selectedRow: string) {
    this.searchTerm = '';
    this.row = selectedRow;
    this.getSubscriptionDetail(selectedRow);
  } // end onRowClick()

  getGrandTotalAmount(values: ClientSubscriptionDetailHandle[]) {
    this.totalCollectionsAmount = 0;
    this.totalInvoicedAmount = 0;
    this.totalDueAmount = 0;

    for (const i of values) {
      if (i.state.isVisible) {
        this.totalCollectionsAmount += i.data.ChargedAmount;
        this.totalInvoicedAmount += i.data.InvoicedAmount;
        this.totalDueAmount += i.data.TotalOwingAuxcon;
      }
    }
  } // end getGrandTotalAmount()

  getSubscriptionDetail(option: string) {
    const snack: Snack = {
      label: 'Loading subscription details...',
      action: null
    };
    this.snackbarService.make(snack, 5000).show();
    this.loadingService.showOverlay();
    this.clientSubscriptionDetail = [];
    this.clientSubscriptionDetailHandle = [];
    this.practiceAdvocateHealthService.getSubscriptionDetail(option)
    .subscribe({ next: (data) => {
      // On next
      this.clientSubscriptionDetail = data;
    },
    error: (error) => {
      // On error
      snack.label = 'Error loading subscription detail data.';
      snack.type = SnackType.ERROR;
      this.snackbarService.dismiss().make(snack).show();
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      snack.label = 'Subscription detail data loaded.';
      this.snackbarService.dismiss().make(snack).show();
      this.clientSubscriptionDetail.forEach(client => {
        this.clientSubscriptionDetailHandle.push({
          data: client,
          state: {
            isVisible: true,
            isSelected: false,
            isOpen: false
          }
        });
      });
      this.getGrandTotalAmount(this.clientSubscriptionDetailHandle);
      this.loadingService.hideOverlay();
    }
  });
  } // end getSubscriptionDetails()

  performSearch() {
    if (this.searchTerm) {
      this.clientSubscriptionDetailHandle.forEach(client => {
        if (
          !(client.data.AdvocateName.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          client.state.isVisible = false;
        } else {
          client.state.isVisible = true;
        }
      });
    } else {
      this.clientSubscriptionDetailHandle.forEach(client => {
        client.state.isVisible = true;
      });
    }
    this.getGrandTotalAmount(this.clientSubscriptionDetailHandle);
  } // end performSearch()

  clearSearch() {
    this.searchTerm = '';
    this.performSearch();
  } // end clearSearch()
} // end clientSubscriptionSummary{}
