<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="badge" *ngIf="type === types.BAR_SOCIETY">
  <input
    required 
    *ngIf="type === types.BAR_SOCIETY" 
    [(ngModel)]="advocateChambers && advocateChambers.BarSociety" 
    [typeahead]="barSociety"
    typeaheadOptionField="Name" 
    [typeaheadSingleWords]="false" 
    (input)="onInput($event)" 
    class="categotyText"
    placeholder="Select or start typing" 
    (typeaheadOnSelect)="onSelectBarSociety($event)"
    [typeaheadOptionsLimit]="100" 
    typeaheadScrollable=true
    typeaheadOptionsInScrollableView="20" 
    [typeaheadMinLength]="0">
</div>

<div class="badge" *ngIf="type === types.CHAMBERS">
  <input 
    *ngIf="type === types.CHAMBERS" 
    [(ngModel)]="advocateChambers && advocateChambers.Chamber" 
    [typeahead]="chambers"
    typeaheadOptionField="ChamberName" 
    (input)="onInput($event)" 
    class="form-control"
    placeholder="Select or start typing"
    (typeaheadOnSelect)="onSelectChamber($event)" 
    [typeaheadOptionsLimit]="50" 
    typeaheadScrollable=true
    typeaheadOptionsInScrollableView="20" 
    [typeaheadMinLength]="0">
</div>