import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import {
        ProcessPaymentService,
        UnPaidInvoices,
        ProofOfPayment,
        ProofOfPaymentLines } from '../../../services/process-payment/process-payment.service';
import * as moment from 'moment';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
// import { count } from 'rxjs/operators';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import {
    InvoiceDetails,
    InvoiceDetailsService,
    DEFAULT_INVOICE_DETAILS,
} from '../../../services/invoice-details/invoice-details.service';
import {
    ActivityLogs,
    DEFAULT_ACTIVITY_LOGS,
    ActivityLogsService,
} from '../../../services/activity-logs/activity-logs.service';
import {
    NotesObject,
    NotesObjectHandle,
    NotesService,
} from '../../../services/notes/notes.service';

interface UnPaidInvoicesHandle {
    data: UnPaidInvoices;
    state: {
        isVisible: boolean;
        isSelected: boolean;
        isOpen: boolean
    };
}

@Component({
    selector: 'app-unpaid-invoices-proof-of-payment',
    templateUrl: './unpaid-invoices-proof-of-payment.component.html',
    styleUrls: ['./unpaid-invoices-proof-of-payment.component.scss']
})
export class UnpaidInvoicesProofOfPaymentsComponent implements OnInit, OnChanges {

    checkBoxSelected: any = [];
    showActions = false;
    searchTerm: any;

    proofOfPaymentLines: ProofOfPaymentLines;

    promptInvoices = false;

    _unPaidInvoices: any = [];
    selectAll: boolean;

    sortBy: string;
    previousSortBy: string;
    sortAscending: boolean;
    sortDate: boolean;
    sortInvoice: boolean;
    sortLawfirm: boolean;
    sortMatter: boolean;
    sortReference: boolean;
    sortAmount: boolean;
    showProformaInvoice: boolean;
    selectedInvoiceId = 0;

    // for invoice preview
    invoicePlaintiff: string;
    invoiceDefendant: string;
    invoiceDetails: InvoiceDetails;

    // Invoice Note
    selectedTab: string;
    // notes
    notes: NotesObject[] = [];
    notesHandle: NotesObjectHandle[] = [];
    showInvoiceNoteDetail: boolean;
    objectType: string;
    onShowNoteDetail: boolean;
    noteDetailData: NotesObject;

    profileTypes = {
        ADVOCATE: 'Advocate',
        ASSISTANT: 'Assistant',
        ATTORNEY: 'Attorney',
        PRACTICE_MANAGER: 'Practice Manager',
        EXTERNAL_ATTORNEY: 'Attorney (External)',
        LAWFIRM: 'Lawfirm',
        EXTERNAL_LAWFIRM: 'Lawfirm (External)'
    };

    // proofOfPayment: any = [];
    unPaidInvoiceHandles: UnPaidInvoicesHandle[] = [];
    // selectedUnPaidInvoiceHandles: UnPaidInvoicesHandle[] = [];

    // Activity logs
    activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

    @Output() promptPaidInvoices: EventEmitter<number> = new EventEmitter<number>();
    @Input() _unPaidInvoiceHandles: UnPaidInvoicesHandle[];
    @Input() proofOfPayment: any;
    @Input() showUnPaid: EventEmitter<ProofOfPayment> = new EventEmitter<ProofOfPayment>();
    @ViewChild('inputCheckbox') inputCheckbox: ElementRef;

    // Is Practice Manager
    get isPracticeManager(): boolean {
        return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
    }  // end isPracticeManager()

    constructor(
        private notesService: NotesService,
        private loadingService: LoadingService,
        private snackbarsService: SnackbarsService,
        private userProfileService: UserProfileService,
        private activityLogService: ActivityLogsService,
        private invoiceDetailService: InvoiceDetailsService,
        private processPaymentService: ProcessPaymentService,
        ) {
    }

    get currencySymbol(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
        } else {
            return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
        }
    } // end currencySymbol()

    ngOnInit() {
        // this.showUnPaid.subscribe((response) => {
        //     if (response) {
        //         this.promptInvoices = true;
        //         this.proofOfPayment = response;
        //         // this.getUnPaidInvoices(this.proofOfPayment.LawFirmId);
        //     } else {
        //         // this.unPaidInvoices = null;
        //         this.promptInvoices = false;
        //     }
        // });
        // this.selectAll = false;
    } // end ngOnInit()

    ngOnChanges(changes: SimpleChanges) {
        if (changes._unPaidInvoiceHandles && changes._unPaidInvoiceHandles.currentValue) {
            this.unPaidInvoiceHandles = this._unPaidInvoiceHandles;
            this.clearSearch();
        }
    } // end ngOnChanges()

    toggleSort(sortBy: string) {
        this.sortBy = sortBy;
        if (this.sortBy === this.previousSortBy) {
            this.sortAscending = !this.sortAscending;
        }

        switch (this.sortBy) {
            case 'date':
                this.unPaidInvoiceHandles.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = true;
                        this.sortInvoice = false;
                        this.sortLawfirm = false;
                        this.sortMatter = false;
                        this.sortReference = false;
                        this.sortAmount = false;
                        return a.data.Date.toUpperCase().localeCompare(b.data.Date.toUpperCase());
                    }
                    this.sortDate = false;
                    return b.data.Date.toUpperCase().localeCompare(a.data.Date.toUpperCase());
                });
                break;
            case 'invoice':
                this.unPaidInvoiceHandles.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortInvoice = true;
                        this.sortLawfirm = false;
                        this.sortMatter = false;
                        this.sortReference = false;
                        this.sortAmount = false;
                        return a.data.InvoiceNo.toUpperCase().localeCompare(b.data.InvoiceNo.toUpperCase());
                    }
                    this.sortInvoice = false;
                    return b.data.InvoiceNo.toUpperCase().localeCompare(a.data.InvoiceNo.toUpperCase());
                });
                break;
            case 'lawfirm':
                this.unPaidInvoiceHandles.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortInvoice = false;
                        this.sortLawfirm = true;
                        this.sortMatter = false;
                        this.sortReference = false;
                        this.sortAmount = false;
                        return a.data.Client.toUpperCase().localeCompare(b.data.Client.toUpperCase());
                    }
                    this.sortLawfirm = false;
                    return b.data.Client.toUpperCase().localeCompare(a.data.Client.toUpperCase());
                });
                break;
            case 'matter':
                this.unPaidInvoiceHandles.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortInvoice = false;
                        this.sortLawfirm = false;
                        this.sortMatter = true;
                        this.sortReference = false;
                        this.sortAmount = false;
                        return a.data.Matter.toUpperCase().localeCompare(b.data.Matter.toUpperCase());
                    }
                    this.sortMatter = false;
                    return b.data.Matter.toUpperCase().localeCompare(a.data.Matter.toUpperCase());
                });
                break;
            case 'reference':
                this.unPaidInvoiceHandles.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortInvoice = false;
                        this.sortLawfirm = false;
                        this.sortMatter = false;
                        this.sortReference = true;
                        this.sortAmount = false;
                        return a.data.Reference.toUpperCase().localeCompare(b.data.Reference.toUpperCase());
                    }
                    this.sortReference = false;
                    return b.data.Reference.toUpperCase().localeCompare(a.data.Reference.toUpperCase());
                });
                break;
            case 'amount':
                this.unPaidInvoiceHandles.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortInvoice = false;
                        this.sortLawfirm = false;
                        this.sortMatter = false;
                        this.sortReference = false;
                        this.sortAmount = true;
                        return a.data.Amount - b.data.Amount;
                    }
                    this.sortAmount = false;
                    return b.data.Amount - a.data.Amount;
                });
                break;
        }
        this.previousSortBy = this.sortBy;
    } // end toggleSort()

    toggleSelectAll() {
       this.selectAll = !this.selectAll;
       this.unPaidInvoiceHandles.forEach(invoice => {
           if (invoice.state.isVisible) {
            invoice.state.isSelected = this.selectAll;
           }
           this.showActions = this.selectAll;
       });
    } // end toggleSelectAll()

    onViewInvoice(invoiceId: number) {
        this.loadingService.showOverlay();
        this.notes = [];
        this.notesHandle = [];
        this.selectedTab = 'invoice';
        if (invoiceId) {
            let _note = [];
            if (this.isPracticeManager) {
            //  _note =  
            this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID, invoiceId, 4).subscribe({ next: (_data) => {
                _note = _data;
            }});
            } else if (!this.isPracticeManager) {
                // _note =  
                this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID, invoiceId, 4).subscribe({ next: (_data) => {
                    _note = _data;
                }});
            }
            if (!this.isPracticeManager) {
                this.notes = _note.filter(x => x.QuestionText);
            } else {
                this.notes = _note;
            }
            this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
            // this.showProformaInvoice = true;
            // this.invoiceDetails =  
            this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({ next: (_data) => {
                this.invoiceDetails = _data;
            }});
            this.selectedInvoiceId = invoiceId;

            if (this.invoiceDetails) {
                const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                if (desc.length > 0) {
                    this.invoicePlaintiff = desc[0];
                }
                if (desc.length > 1) {
                    this.invoiceDefendant = desc[1];
                }
            }

            this.notes.forEach(_notes => {
                this.notesHandle.push({
                    data: _notes,
                    state: {
                        isVisible: true,
                        isSelected: false,
                        isOpen: false
                    }
                });
            });
        }
        this.showProformaInvoice = true;
        this.loadingService.hideOverlay();
    } // end onViewInvoice()

    toggleCheckbox(invoices: UnPaidInvoicesHandle) {
        invoices.state.isSelected = !invoices.state.isSelected;
        if (this.unPaidInvoiceHandles.find(invoice => invoice.state.isSelected === true)) {
            this.showActions = true;
        } else {
            this.showActions = false;
        }
    } // end toggleCheckbox()

    // onChangeCheckBox(Invoice: number, isChecked: boolean) {
    //     if (isChecked) {
    //         this.checkBoxSelected.push(Invoice);
    //     } else {
    //         let index = this.checkBoxSelected.indexOf(Invoice);
    //         this.checkBoxSelected.splice(index, 1);
    //     }

    //     if (this.checkBoxSelected.length > 0) {
    //         this.showActions = true;
    //     } else {
    //         this.showActions = false;
    //     }
    // }

    getDateFormet(value: Date): string {
        return moment(value).format('DD/MM/YYYY');
    } // end getDateFormet()

    numberConversion(value: number): string {
        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    } // end numberConversion()

    postProofOfPaymentLines() {
        this.loadingService.showOverlay();
        const snack: Snack = {
            label: 'Add Proof of Payment Line',
            action: null
        };
        try {
            if (this.showActions) {
                let data: any = new Array();
                this.unPaidInvoiceHandles.forEach(invoice => {
                    if (invoice.state.isSelected) {
                        // if (data.length === 0) {
                        //     if (invoice.data.Amount === this.proofOfPayment.Amount) {
                                invoice.data.Paid = 0;
                        //     } else if (invoice.data.Amount > this.proofOfPayment.Amount) {
                        //         invoice.data.Paid = this.proofOfPayment.Amount;
                        //     } else if (invoice.data.Amount < this.proofOfPayment.Amount) {
                        //         invoice.data.Paid = invoice.data.Amount;
                        //     }
                        // } else {
                        //     let amount_count = 0;
                        //     data.forEach(inv => {
                        //         amount_count += inv.Amount;
                        //     });

                        //     if (amount_count + invoice.data.Amount < this.proofOfPayment.Amount) {
                        //         invoice.data.Paid = invoice.data.Amount;
                        //     } else if (this.proofOfPayment.Amount - (amount_count) > 0) {
                        //         invoice.data.Paid = this.proofOfPayment.Amount - (amount_count);
                        //     } else {
                        //         invoice.data.Paid = 0;
                            // }
                        // }
                        // invoice.data.Paid = invoice.data.Amount;
                    data.push(invoice.data);
                    }
                });

                // Log activity Login
                const currentDate = new Date();
                this.activityLog.Action = 'Add Proof of payment line';
                this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
                this.activityLog.LoggedApp = 'Web Application (Add-proof-of-payment)';
                if (this.userProfileService.userProfile.isImpersonator) {
                    this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                    this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
                } else {
                    this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                    this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
                }
                this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
                this.activityLog.ActionTable = 'ProofOfPayment';
                this.activityLog.JsonData = JSON.stringify(data);
                this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_data) => {}});

                this.processPaymentService.postProofOfPaymentLines(this.proofOfPayment.Id, this.proofOfPayment.LawFirmId, data).subscribe({ next: (_data) => {
                    // ON next
                    },
                    error : (error) => {
                        // On error
                    },
                    complete: () => {
                        // On complete
                        data = [];
                        this._unPaidInvoiceHandles = this.unPaidInvoiceHandles.filter(invoice => invoice.state.isSelected !== true);
                        this.unPaidInvoiceHandles = this._unPaidInvoiceHandles;
                        this.promptPaidInvoices.emit(this.proofOfPayment.Id);
                        snack.label = 'Added Selected Proof of Payment Line.';
                        snack.type = SnackType.SUCCESS;
                        this.snackbarsService.dismiss().make(snack).show();
                    }
                });
            }
        } catch (e) {
            this.loadingService.hideOverlay();
            snack.label = 'Something went wrong';
            snack.action = null;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
        }
        this.selectAll = false;
        this.loadingService.hideOverlay();
        this.clearSearch();
        this.inputCheckbox.nativeElement.checked = false;
        this.showActions = false;
        this.checkBoxSelected = [];
    } // end postProofOfPaymentLines()

    onKeyDown(event) {
        this.performSearch();
        // switch (event.key) {
        //     case 'Enter':
        //         break;
        // }
    } // end onKeyDown()

    performSearch() {
    //     if (this.searchTerm) {
    //     this.unPaidInvoiceHandles = this.unPaidInvoiceHandles.filter((f) => {
    //         return f.data.Client.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
    //             f.data.Reference.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
    //             f.data.Matter.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
    //             f.data.InvoiceNo.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
    //             f.data.Date.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
    //             f.data.Amount === this.searchTerm ||
    //             f.data.Paid === this.searchTerm;
    //     });
    // } else {
    //         this.unPaidInvoiceHandles = this._unPaidInvoiceHandles;
    // }

        if (this.searchTerm) {
            this.unPaidInvoiceHandles.forEach(invoice => {
                if (
                    !(invoice.data.Date.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(invoice.data.InvoiceNo.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(invoice.data.Matter.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(invoice.data.Reference.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(invoice.data.Amount.toString().match(new RegExp(this.searchTerm, 'gi')))
                ) {
                    invoice.state.isVisible = false;
                } else {
                    invoice.state.isVisible = true;
                }
            });
        } else {
            this.unPaidInvoiceHandles.forEach(invoice => {
                invoice.state.isVisible = true;
            });
        }
    } // performSearch()

    clearSearch() {
        this.searchTerm = '';
        this.performSearch();
    } // end clearSearch()

    closeModal(value: string) {
        switch (value) {
            case 'invoice':
                this.showProformaInvoice = false;
            break;
        }
    } // end closeModal()
}
