import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { AddLawfirmAttorneyService,
          AddLawfirm
} from '../../../../services/addlawfirm/add-lawfirm-attorney.service';

import {
  ToastService, LENGTH_LONG
} from '../../../../services/messaging/toast/toast.service';
import {
  SnackbarsService,
  Snack,
  SnackType
} from '../../../../services/messaging/snackbars/snackbars.service';

import {
  TAG_ADD_ATTORNEY_FIRM_MODAL
} from '../../../../services/tags/tags.service';
import { LoadingService } from '../../../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../../../services/utilities.service';
import { UserProfileService } from '../../../../services/user-profile/user-profile.service';
import * as moment from 'moment';
import { DEFAULT_ACTIVITY_LOGS, ActivityLogs, ActivityLogsService } from '../../../../services/activity-logs/activity-logs.service';

@Component({
  selector: 'app-new-attorney-modal',
  templateUrl: './new-attorney-modal.component.html',
  styleUrls: ['./new-attorney-modal.component.scss']
})
export class NewAttorneyModalComponent implements OnInit {

  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<string> = new EventEmitter();

  title = 'New Attorney Request';
  addLawfirm: AddLawfirm = {
    LawfirmName: '',
    AttorneyName: '',
    ContactNumber: '',
    EmailAddress: ''
  };
  attorney = '';
  emailAddress = '';
  firmName = '';
  phoneNumber = '';

  defaultModel = {
    attorney: '',
    emailAddress: '',
    firmName: '',
    phoneNumber: ''
  };

  // isSubmittable: boolean;
  showUnsavedChangesPrompt: boolean;
  isValidAttName = false;
  isValidLawFirmName = false;
  isValidAttPhoneNumber = false;
  isValidAttEmail = false;

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.defaultModel, {
      attorney: this.attorney,
      emailAddress: this.emailAddress,
      firmName: this.firmName,
      phoneNumber: this.phoneNumber
    });
  } // end isDirty()

  get hasContactEmailError(): boolean {
    return !this.util.isValidEmail(this.emailAddress);
  } // end hasContactEmailError()

  get hasCellNumberError(): boolean {
    return !this.util.isValidPhoneNumber(this.phoneNumber, { code: this.userProfileService.userProfile.currencyDetails.code });
  } // end hasCellNumberError()

  get hasAttorneyNameError(): boolean {
    return !this.util.hasTextValue(this.attorney);
  } // end hasAttorneyNamerError()

  get hasFirmNameError(): boolean {
    return !this.util.hasTextValue(this.firmName);
  } // end hasFirmNameError()

  get isSubmittable(): Boolean {
    return Boolean(!this.hasAttorneyNameError) &&
      Boolean(!this.hasFirmNameError) && Boolean(!this.hasCellNumberError) &&
      Boolean(!this.hasContactEmailError);
  } // end isSubmittable()

  constructor(
    private util: UtilitiesService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private addLawfirmAttorneyService: AddLawfirmAttorneyService,
  ) {
    // this.isSubmittable = false;
  } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  firmNameIsValid(): boolean {
    let isValid = true;
    if (!this.firmName) {
      isValid = false;
    }
    return isValid;
  } // end firmNameIsValid()

  attorneyIsValid(): boolean {
    let isValid = true;
    if (!this.attorney) {
      isValid = false;
    }
    return isValid;
  } // end attorneyIsValid()

  phoneNumberIsValid(): boolean {
    let isValid = true;
    if (!this.phoneNumber) {
      isValid = false;
    }
    return isValid;
  } // end phoneNumberIsValid()

  emailAddressIsValid(): boolean {
    let isValid = true;
    if (!this.emailAddress) {
      isValid = false;
    }
    return isValid;
  } // end emailAddressIsValid()

  submitRequest() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Submitting Attorney / attorney request...',
      action: null
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    this.addLawfirm = {
      LawfirmName: this.firmName,
      AttorneyName: this.attorney,
      ContactNumber: this.phoneNumber,
      EmailAddress: this.emailAddress
    };

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'New attorney request';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (new-attorey-modal)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Invoice';
    this.activityLog.JsonData = JSON.stringify(this.addLawfirm);
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    this.addLawfirmAttorneyService
        .addLawfirmAttorney(this.addLawfirm)
        .subscribe({
          next: (newAttorney) => {
            // On next

          },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
            snack.label = 'Something went wrong, please contact auxcon for your request';
            snack.action = null;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
            console.error('Something went wrong: ', error);
          },
          complete: () => {
            // On complete
            snack.label = 'Attorney / Lawfirm will be added upon receipt of your request. ';
            this.loadingService.hideOverlay();
            this.snackbarsService.dismiss().make(snack).show();
          }
        });
    this.dismiss();
  } // end submitRequest()

  onDismiss() {
    if (this.isDirty) {
      this.showUnsavedChangesPrompt = true;
    } else {
      this.dismiss();
    }
  } // end onDismiss()

  dismiss() {
    this.clearInputs();
    this.closeModal.emit(TAG_ADD_ATTORNEY_FIRM_MODAL);
  } // end submitRequest()

  clearInputs() {
    this.firmName = '';
    this.attorney = '';
    this.phoneNumber = '';
    this.emailAddress = '';
    this.isValidAttName = false;
    this.isValidLawFirmName = false;
    this.isValidAttPhoneNumber = false;
    this.isValidAttEmail = false;
  }// end clearInputs()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end   onHideUnsavedChangesPrompt()

  onDismissPromptUnsavedChangesModal() {
    this.onHideUnsavedChangesPrompt();
    this.dismiss();
  } // end   onDismissPromptUnsavedChangesModal()

  onBlurAttName() {
    if (!this.util.hasTextValue(this.attorney)) {
      this.isValidAttName = true;
    } else {
      this.isValidAttName = false;
    }
  } // end onBlurAttName()

  onFocusLawFirmName() {
    this.onBlurAttName();
  } // end onFocusLawFirmName()

  onBlurLawFirmName() {
    if (!this.util.hasTextValue(this.firmName)) {
      this.isValidLawFirmName = true;
    } else {
      this.isValidLawFirmName = false;
    }
  } // end onBlurLawFirmName()

  onBlurAttPhoneNumber() {
    if (!this.util.isValidPhoneNumber(this.phoneNumber,
      { code: this.userProfileService.userProfile.currencyDetails.code })) {
      this.isValidAttPhoneNumber = true;
    } else {
      this.isValidAttPhoneNumber = false;
    }
  } // end onBlurAttPhoneNumber()

  onFocusAttPhoneNumber() {
    this.onBlurAttName();
    this.onBlurLawFirmName();
  } // end onFocusAttPhoneNumber()

  onBlurEmail() {
    if (!this.util.isValidEmail(this.emailAddress)) {
      this.isValidAttEmail = true;
    } else {
      this.isValidAttEmail = false;
    }
  } // end onBlurEmail()
  onFocusEmail() {
    this.onBlurAttName();
    this.onBlurLawFirmName();
    this.onBlurAttPhoneNumber();
  } // end onFocusEmail()
} // end NewAttorneyModalComponent{}
