import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ReportsService } from '../../../services/reports/reports.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Router } from '@angular/router';
import { PATH_INDIVIDUAL_CUSTOMER } from '../../../services/appdata/app-config.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

@Component({
  moduleId: module.id,
  selector: 'app-customer-report-payment-rating',
  templateUrl: './customer-report-payment-rating.component.html',
  styleUrls: ['./customer-report-payment-rating.component.scss']
})
export class CustomersReportPaymentRatingComponent implements OnInit {
  isDataLoaded = false;
  data: any = [];
  allTotalOutstanding = 0;
  averagePercentage = 0;
  averageDays = 0;
  @Output() onRowClick = new EventEmitter<string>();

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private reportsService: ReportsService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getApiData();
  }

  getApiData(): void {
    this.isDataLoaded = false;
    this.allTotalOutstanding = 0;
    this.averageDays = 0;
    this.averagePercentage = 0;
    this.loadingService.showOverlay();
    this.reportsService.getCustomersPaymentRating().subscribe({ next: (response) => {
      if (response) {
        this.data = response;
        this.data = this.data.sort((a, b) => b.totalOutstanding - a.totalOutstanding);

        for (const item of this.data) {
          this.allTotalOutstanding += item.totalOutstanding;
          this.averagePercentage += item.paidVsInvoiced;
          if (!isNaN(item.averageDaysToPayment)) {
            this.averageDays += +item.averageDaysToPayment;
          }
        }
      }
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        this.averagePercentage = +(this.averagePercentage / this.data.length).toFixed(0);
        this.averageDays = +(this.averageDays / this.data.length).toFixed(0);
        this.loadingService.hideOverlay();
        this.isDataLoaded = true;
      }
    });
  }

  getBarWidth(value: number): string {
    const maxValue = this.data[0].totalOutstanding;
    let width = '0';
    if (value > 0) {
      if (((value * 100) / maxValue) > 0.3) {
        width = ((value * 100) / maxValue).toString();
      } else {
        width = '0.3';
      }
    }
    return width + '%';
  }

  formatAverageDaysToPayment(value: number): string {
    if (value > 98) {
      return '#d06553';
    }
  }

  formatPaidVsInvoiced(value: number): string {
    if (value <= 80) {
      return '#d06553';
    }
  }

  getRowFontweight(value: number): string {
    if (value > 98) {
      return 'bold';
    }
  }

  getRowBackgroundColor(day: number): string {
    if (day > 98) {
      return '#fbe6e2';
    }
  }

  getDays(value: any): string {
    if (isNaN(value)) {
      return value;
    }
    return value + ' Days';
  }

  numberConversion(value: number): string {
    return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  onViewCustomerReport(debtorID: string) {
    this.reportsService.individualCustomerFrom = 'payment-rating';
    this.router.navigate([PATH_INDIVIDUAL_CUSTOMER + '/' + debtorID]);
    // this.onRowClick.emit(debtorID);
  }
}
