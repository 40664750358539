import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onboarding-header',
  templateUrl: './onboarding-header.component.html',
  styleUrls: ['./onboarding-header.component.scss']
})
export class OnboardingHeaderComponent implements OnInit {

  images: any;
  constructor() {
    this.images = {
      logo: {
        path: '../../../../assets/' +
          'images/logos/81f56a2b9e02b9da38ff68cfe82732e6.png'
        // description: 'Auxcon Logo'
      }
    }
  }

  ngOnInit(): void {
  }

}
