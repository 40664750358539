<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid">
  <div *ngIf="isErrorMessage">
    <div class="alert alert-danger" style="margin-left: 15px;padding: 15px;">
      <i class="fa fa-exclamation-triangle"></i> {{ ErrorMessage }}
    </div>
  </div>
  <div *ngIf="!isErrorMessage">
    <div *ngIf="isSent === false">
      <div class="alert alert-warning" style="margin-left: 15px;padding: 15px;" *ngIf="!isCompanyDetailsAvailable">
        <i class="fa fa-exclamation-triangle"></i> We are not able to read the data from sage. You are requested to
        perform the setup again with new company.
        <span style="cursor: pointer; font-weight: 600; text-decoration: underline;" (click)="ResetSageSetup()">Click
          Here</span> to setup again.
      </div>
      <div class="reconcile-body row" style="margin-top: -34px;" *ngIf="isCompanyDetailsAvailable">
        <div class="left-block col-sm-4">
          <form>
            <div class="form-group col-md-12" *ngIf="showHideUserNamePass">
              <label for="sage-username">User Name
                <span title="Required field" style="color: red"> *</span></label>
              <input type="text" class="form-control" id="sage-username" [(ngModel)]="sageUserName"
                (blur)="onBlurSageUserName()" autocomplete="off" name="sageUserName" [disabled]="showHide" />
            </div>
            <div class="form-group col-md-12" *ngIf="showHideUserNamePass">
              <label for="user-password">Password
                <span title="Required field" style="color: red"> *</span></label>
              <input type="password" class="form-control" id="user-password" [(ngModel)]="password"
                [disabled]="showHide" (blur)="onBlurPassword()" autocomplete="off" name="password" />
            </div>
            <div class="form-group col-md-12" *ngIf="!showHide">
              <button class="btn btn-success btn-lg" type="submit" style="float :right;" (click)="CheckSageUserValid()">
                Verify
              </button>
            </div>
            <!-- DropDown Company Div -->
            <div class="form-group col-md-12" *ngIf="showHide">
              <label for="company-name">Company<span title="Required field" *ngIf="!isAlreadySynced" style="color: red">
                  *</span></label>
              <select *ngIf="!GOshowHide" class="form-control" id="company-name" [(ngModel)]="companyId"
                (change)="companyChanged($event)">
                <option [value]="null" disabled>Choose a Company</option>
                <option *ngFor="let company of companyList" [value]="company.ID" [selected]="company.ID === companyId">
                  {{company.Name}}</option>
              </select>
              <input *ngIf="GOshowHide" type="companyName" class="form-control" id="selected-comapny-name"
                [(ngModel)]="currentCompanyName" [value]="currentCompanyName" disabled />
              <i *ngIf="btnGoShowHide" class="fas fa-arrow-alt-circle-right ng-star-inserted" data-toggle="tooltip"
                data-placement="top" title="Go" (click)="getAdvocateSageSetup()"
                style="font-size:25px;color:#dbbb2d; margin-top:10px; float: right;">
              </i>
            </div>
            <div class="form-group col-md-12" *ngIf="GOshowHide">
              <label for="user-password">Integrate transactions From
                <span title="Required field" style="color: red"> *</span></label>
              <div class="input-group">
                <input class="form-control input-date" name="date" style="margin: 0 !important;" type="text"
                  id="date-from" #_dateFrom="bsDatepicker" bsDatepicker [bsValue]="initialBsValue" [maxDate]="bsValue"
                  [(ngModel)]="advocateSageSetup.LastTransactionPostedDate" (blur)="onDateBlurEvent($event)"
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" placement="right" [disabled]="datePickerDisable"
                  autocomplete="off" (bsValueChange)="onDateValueChange($event)">
                <div *ngIf="!datePickerDisable" class="input-group-append" (click)="_dateFrom.toggle()"
                  [attr.aria-expanded]="_dateFrom.isOpen">
                  <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="right-block col-sm-8">
          <div *ngIf="GOshowHide" style="border:solid 1px #8ABD37; background-color: #F4F9ED;">
            <!-- Second Div  -->
            <div class="form-group col-md-12">
              <h4 style="margin-top:10px;" *ngIf="isAlreadySynced"><b>The following SAGE account has been
                  integrated:</b>
              </h4>
              <h4 style="margin-top:10px;" *ngIf="!isAlreadySynced"><b>Details of SAGE account to be integrated:</b>
              </h4>
              <div class="side-scroll-bar" style="max-height: 350px; min-height: 350px;">
                <h5 style="margin-left: 17px;"><b>Banks</b></h5>
                <div class="row" *ngFor="let bankDetail of bankList" style="margin-left: 12px;">
                  <div class="col-md-12">
                    <i class="fa fa-check-circle-o fa-lg" *ngIf="bankDetail.IsMatched"
                      style="color:green;margin-right: 15px;" aria-hidden="true"></i>
                    <i class="fa fa-question-circle-o fa-lg" *ngIf="!bankDetail.IsMatched"
                      style="color:#dbbb2d; margin-right: 15px;" aria-hidden="true"></i>
                    {{bankDetail.Message}}
                  </div>
                </div>
                <h5 style="margin-left: 17px;"><b>Account Types </b></h5>
                <div class="row" *ngFor="let accoountTypeDetails of accountTypeList" style="margin-left:12px;">
                  <div class="col-md-12">
                    <i class="fa fa-check-circle-o fa-lg" *ngIf="accoountTypeDetails.IsMatched"
                      style="color:green;margin-right: 15px;" aria-hidden="true"></i>
                    <i class="fa fa-question-circle-o fa-lg" *ngIf="!accoountTypeDetails.IsMatched"
                      style="color:#dbbb2d; margin-right: 15px;" aria-hidden="true"></i>
                    {{accoountTypeDetails.Message}}
                  </div>
                </div>
                <h5 style="margin-left: 17px;"><b>VAT</b></h5>
                <div class="row" *ngFor="let vatDetail of vatList" style="margin-left: 12px;">
                  <div class="col-md-12">
                    <i class="fa fa-check-circle-o fa-lg" *ngIf="vatDetail.IsMatched"
                      style="color:green; margin-right: 15px;" aria-hidden="true"></i>
                    <i class="fa fa-info-circle fa-lg" *ngIf="!vatDetail.IsMatched"
                      style="color:#dbbb2d; margin-right: 15px;" aria-hidden="true"></i>
                    {{vatDetail.Message}}
                  </div>
                </div>
              </div>
            </div>
            <!---end second div-->
          </div>
        </div>
      </div>
    </div>
    <div class="well footer-buttons" *ngIf="!isAlreadySynced" style="height: 50px;">
      <button class="btn btn-danger" style="height: 30px !important;width: 15% !important;" type="cancel"
        [class.disabled]="!isReset" [disabled]="!isReset" (click)="cancelSageUserSaveDetials()">RESET</button>
      <button class="btn btn-success" style="height: 30px !important;width: 15% !important;" type="submit"
        [class.disabled]="!isSaveable" [disabled]="!isSaveable" (click)="onSageUserSaveHideAlert()">SAVE</button>
    </div>
    <div *ngIf="isSent === true" class="sent-body row ">
      <div class="col-sm-12">
        <div class="row icon">
          <i class="fal fa-check-circle fa-5x" style="color: #8ABD37;"></i>
        </div>
        <div class="row statement">
          <h3>Sage account has been integrated</h3>
        </div>
      </div>
    </div>
  </div>
</div>
<app-alert title="VALUE CAN NOT BE EMPTY" message="Please provide Valid User Name"
  [isVisible]="showUserErrorPromptSageUserName" (cancel)="onHideAlert()" (confirm)="onHideAlert()">
</app-alert>
<app-alert title="VALUE CAN NOT BE EMPTY" message="Please provide Valid Password"
  [isVisible]="showUserErrorPromptPassword" (cancel)="onHideAlert()" (confirm)="onHideAlert()">
</app-alert>
<div *ngIf="totalUnMatchedCount == 0 ">
  <app-prompt-unsaved-changes-modal title="Confirm SAGE integration"
    message="You are about to integrate with the following SAGE account '{{currentCompanyName}}', Do you wish to proceed?"
    [isVisible]="showUserErrorPromptSaveUser" btnTextCancel="Cancel" btnTextContinue="OK"
    (cancel)="onSageCancelSaveUser()" (continue)="saveAdvocateSageDetails()">
  </app-prompt-unsaved-changes-modal>
</div>
<div *ngIf="totalUnMatchedCount > 0 ">
  <app-prompt-unsaved-changes-modal title="Warning SAGE integration"
    message="Cannot perform Save, {{totalUnMatchedCount}} items not matched to Sage"
    [isVisible]="showUserErrorPromptSaveUser" btnTextCancel="Cancel" btnTextContinue="OK"
    (cancel)="onSageCancelSaveUser()" (continue)="onSageCancelSaveUser()">
  </app-prompt-unsaved-changes-modal>
</div>
<app-prompt-unsaved-changes-modal *ngIf="showUnsavedChangesPrompt"
  message="You have unsaved changes. Are you sure you want to leave the page?" [isVisible]="showUnsavedChangesPrompt"
  (cancel)="onHideUnsavedChangesPrompt()" (continue)="leavePage()">
</app-prompt-unsaved-changes-modal>