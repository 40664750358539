import { Injectable } from '@angular/core';
import {
  LoadingOverlayModalComponent,
  LoadingOverlayModalOptions
} from '../../../components/modals/loading-overlay-modal/loading-overlay-modal.component';

@Injectable()
export class LoadingService {

  private _loadingOverlayModal: LoadingOverlayModalComponent;
  constructor() { } // end constructor()

  assign(modal: LoadingOverlayModalComponent) {
    this._loadingOverlayModal = modal;
  } // end assign()

  showOverlay(options: LoadingOverlayModalOptions = {}) {
    if (this._loadingOverlayModal) {
      this._loadingOverlayModal.show(options);
    }
  } // end show()

  hideOverlay() {
    if (this._loadingOverlayModal) {
      this._loadingOverlayModal.hide();
    }
  } // end hide()
} // end LoadingService{}
