<!-- New -->

<div class="row m-0" *ngIf="isDataLoaded===true">
  <!-- #start Invoice overdue -->
   <div class="col-md-6" (click)="onAgeAnalysisClicked()">
    <div class="col widget-container">
      <div class="row  widget1-header text-white" [ngStyle]="{'background-color': agePercentageColor}">
        <div class="col-11 header-text"><b class="b">{{aging98Percentage}}% </b> of your unpaid invoices are
            overdue.</div>
        <div *ngIf="agePercentageColor !== '#e5c100'" class="col-1 fa-icon">
          <i [class.fa-badge-check]="agePercentageColor==='#8abd37'" title="Healthy"
            *ngIf="agePercentageColor==='#8abd37'" class="fal"></i>
          <i [class.fa-exclamation-triangle]="agePercentageColor==='#eb9e02'" title="Caution"
            *ngIf="agePercentageColor==='#eb9e02'" class="fal"></i>
          <i [class.fa-exclamation-circle]="agePercentageColor==='#cf624f'" title="Danger"
            *ngIf="agePercentageColor==='#cf624f'" class="fal"></i>
        </div>
        <div *ngIf="agePercentageColor == '#e5c100'" class="col-1 fa-icon">
          <img alt="neutral" src="./../../../../assets/images/icons/icon-neutral.svg" title="Neutral">
        </div>
      </div> <!-- #end .row  widget3-header text-white -->
       <div class="table-responsive">
        <table class="table text-center" style="border: 0;">
          <thead class="thead">
            <tr>
              <th scope="col" class="w-13 box-container"></th>
              <th scope="col" class="text-white box-container w-15">
                <div class="bg-current box-child" [ngStyle]="{'height': getBarHeight(agingCurrentPercentage) }">
                  <p
                    [ngStyle]="{'margin-top': getBarTextTopMargin(agingCurrentPercentage) , 'color': getBarTextColor(agingCurrentPercentage)}">
                    {{agingCurrentPercentage}}%</p>
                </div>
              </th>
              <th scope="col" class="text-white box-container w-15">
                <div class="bg-30 box-child" [ngStyle]="{'height': getBarHeight(aging30Percentage) }">
                  <p
                    [ngStyle]="{'margin-top': getBarTextTopMargin(aging30Percentage) , 'color': getBarTextColor(aging30Percentage)}">
                    {{aging30Percentage}}%</p>
                </div>
              </th>
              <th scope="col" class="text-white box-container w-15">
                <div class="bg-60 box-child" [ngStyle]="{'height': getBarHeight(aging60Percentage) }">
                  <p
                    [ngStyle]="{'margin-top': getBarTextTopMargin(aging60Percentage) , 'color': getBarTextColor(aging60Percentage)}">
                    {{aging60Percentage}}%</p>
                </div>
              </th>
              <th scope="col" class="text-white box-container w-15">
                <div class=" bg-90 box-child" [ngStyle]="{'height': getBarHeight(aging90Percentage) }">
                  <p
                    [ngStyle]="{'margin-top': getBarTextTopMargin(aging90Percentage) , 'color': getBarTextColor(aging90Percentage)}">
                    {{aging90Percentage}}%</p>
                </div>
              </th>
              <th scope="col" class="text-white box-container w-15">
                <div class="bg-98 box-child" [ngStyle]="{'height': getBarHeight(aging98Percentage) }">
                  <p
                    [ngStyle]="{'margin-top': getBarTextTopMargin(aging98Percentage) , 'color': getBarTextColor(aging98Percentage)}">
                    {{aging98Percentage}}%</p>
                </div>
              </th>
              <!-- <th scope="col" class="box-container"></th> -->
             </tr>
          </thead>
        </table>
      </div>

       <div class="table-responsive">
        <table class="table table-bordered text-right">
          <thead class="thead">
            <tr>
              <th scope="col" class="bg-overdue w-13">Days</th>
              <th scope="col" class="bg-current text-white w-15" style="border-color:#888888 !important">Current
              </th>
              <th scope="col" class="bg-30 text-white w-15" style="border-color:#7a7a7a !important">30</th>
              <th scope="col" class="bg-60 text-white w-15" style="border-color:#646464 !important">60</th>
              <th scope="col" class="bg-90 text-white w-15" style="border-color:#464545 !important">90</th>
              <th scope="col" class="bg-98 text-white w-15" style="border-color:#cf624f !important">Overdue</th>
              <!-- <th scope="col" class="bg-total">Total</th> -->
             </tr>
          </thead>
          <tbody class="tbody">
            <tr *ngFor="let i of _data;">
              <td class="w-13" scope="row">{{i.type}}</td>
              <td class="w-15">{{i.agingCurrentTotal | currency: currencySymbol: currencySymbol:'1.2-2' }}</td>
              <td class="w-15">{{i.aging30Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              <td class="w-15">{{i.aging60Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              <td class="w-15">{{i.aging90Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              <td class="w-15 text-color">{{i.aging98Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              <!-- <td>{{i.totalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div> <!-- #end .col widget-container -->
  </div> <!-- #end .col-md-6 -->

  <!-- #start chart -->
  <div class="col-md-6">
    <div class="col widget-container">
      <div class="row widget1-header text-white" [ngStyle]="{'background-color': chartPercentageColor}">
        <div class="col-11 header-text"> In the next 30 days you have invoices to the value of <b class="b">{{thirtyDayDue | currency: currencySymbol: currencySymbol:'1.2-2'}}</b> that are due.</div>
          <div *ngIf="chartPercentageColor !== '#e5c100'" class="col-1 fa-icon">
            <i [class.fa-badge-check]="chartPercentageColor==='#8abd37'" title="Healthy" *ngIf="chartPercentageColor==='#8abd37'"
              class="fal"></i>
            <i [class.fa-exclamation-triangle]="chartPercentageColor==='#eb9e02'" title="Caution"
              *ngIf="chartPercentageColor==='#eb9e02'" class="fal"></i>
            <i [class.fa-exclamation-circle]="chartPercentageColor==='#cf624f'" title="Danger"
              *ngIf="chartPercentageColor==='#cf624f'" class="fal"></i>
          </div>
          <div *ngIf="chartPercentageColor == '#e5c100'" class="col-1 fa-icon">
            <img alt="neutral" src="./../../../../assets/images/icons/icon-neutral.svg" title="Neutral">
          </div>
      </div> <!-- #end .row -->
    </div> <!-- #end .col .widget-container-->
    <div class="row widget3-body" style="width: 100% !important; margin: 0 !important;">
      <div class="invoiced-collected-container">
        <div class="row headings m-0">
          <div class="col-md-12" style="justify-content: space-between; display: flex; padding-left: 64px; padding-right: 39px;">
            <span class="paid-last-month">Paid last month</span>
            <span class="paid-this-month">Paid this month</span>
            <span class="to-be-paid-next-month" style="font-weight: bold;">To be paid next month</span>
          </div> <!-- #end .row -->
        </div> <!-- #end .row -->
        <div class="row p-0 m-0">
          <jqxChart 
            #myChart
            (click)="onChartClick()"
            [width]="getWidth()" 
            [showBorderLine]="false"
            [height]="200" 
            [title]="''"
            [description]="''" 
            [showLegend]="false" 
            [enableAnimations]="false" 
            [padding]="padding"
            [titlePadding]="titlePadding"
            [source]="sampleData" 
            [xAxis]="xAxis" 
            [seriesGroups]="seriesGroups"
            [colorScheme]="'scheme01'">
          </jqxChart>
        </div>
      </div>
    </div>
  </div>
</div> <!-- #end .row m-0 -->