<div *ngIf="resultItem"
  class="table-row"
  [class.off-white]="bgOffWhite">
  <span class="column-type">
    <span [class]="icon.class">{{icon.text}}</span>
  </span>
  <span class="column-description" [ngSwitch]="resultItem.Category">
    <p class="title">{{title}}</p>
    <p
      class="subtitle"
      [class.clickable]="link"
      (click)="onLinkClick()">{{subtitle}}</p>
    <div
      class="result-detail"
      [@detailState]="detailState"
      [ngSwitch]="resultItem.Category">
      <div *ngSwitchCase="'Service'">
        <p>
          <span class="detail-label">ClientRefNo:</span>
          <span class="detail-value">{{resultItem.Results.ClientRefNo}}</span>
        </p>
        <p>
          <span class="detail-label">Instructions:</span>
          <span class="detail-value">{{resultItem.Results.Instructions}}</span>
        </p>
        <p>
          <span class="detail-label">Status:</span>
          <span class="detail-value">{{resultItem.Results.Status}}</span>
        </p>
      </div>
      <div *ngSwitchCase="'Law Firm'">
        <p>
          <span class="detail-label">Full Name:</span>
          <span class="detail-value">{{resultItem.Results.FullName}}</span>
        </p>
        <p>
          <span class="detail-label">Email:</span>
          <span class="detail-value">{{resultItem.Results.Email}}</span>
        </p>
      </div>
      <div *ngSwitchCase="'Invoice'">
        <p>
          <span class="detail-label">Parties:</span>
          <span class="detail-value">{{resultItem.Results.InvoiceParties}}</span>
        </p>
        <p>
          <span class="detail-label">Invoice No:</span>
          <span class="detail-value">
              <a
              class="invoice-no"
              (click)="onViewInvoice(resultItem.Results.Id)">{{resultItem.Results.InvoiceNo}}</a>
          </span>
        </p>
        <p>
          <span class="detail-label">Invoice Total:</span>
          <span class="detail-value">{{resultItem.Results.InvoiceTotal | currency:currencySymbol}}</span>
        </p>
      </div>
    </div><!--#end .result-detail -->
  </span><!--#end .column-description -->
  <!-- <span class="column-created">{{today | date:'shortDate'}}</span> -->
  <span class="column-action">
    <span
      class="clickable action"
      (click)="toggleDetails()">
      <u>Details</u>
      <i
        class="fa"
        [class.fa-angle-down]="!showDetails"
        [class.fa-angle-up]="showDetails"></i>
    </span>
  </span>
</div>
<app-invoice-modal
  #invoicemodal
  [isVisible]="showInvoice"
  (closeModal)="closeModal('invoice-modal')"></app-invoice-modal>