import { Component, OnInit } from '@angular/core';
import { LoadingService } from "../../services/messaging/loading/loading.service";
import { SnackbarsService, Snack, SnackType, } from "../../services/messaging/snackbars/snackbars.service";
import { CheckSageUserValid } from "../../services/user-profile/user-profile.service";
import { LENGTH_LONG, } from "../../services/messaging/toast/toast.service";
import { ICrumb } from "../../services/advocate-detail-statement/advocate-detail-statement.service";
import { UserSageValidationService, AdvocateSageSetupResponse } from '../../services/user-sage-validation/user-sage-validation.service';
import { Router, NavigationStart, Event } from '@angular/router';
import { PATH_DASHBOARD, PATH_USER_SAGE_VALIDATION } from '../../services/appdata/app-config.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { SubscriptionLike as ISubscription } from 'rxjs';

import {
  UserProfileService,
  UserProfile,
} from "../../services/user-profile/user-profile.service";

import * as moment from 'moment';
const TITLE_PREFIX = "User Sage Validation";
@Component({
  selector: 'app-user-sage-validation',
  templateUrl: './user-sage-validation.component.html',
  styleUrls: ['./user-sage-validation.component.scss']
})
export class UserSageValidationComponent implements OnInit {

  profileTypes = {
    ADVOCATE: "Advocate",
    ASSISTANT: "Assistant",
    ATTORNEY: "Attorney",
    PRACTICE_MANAGER: "Practice Manager",
    EXTERNAL_ATTORNEY: "Attorney (External)",
    LAWFIRM: "Lawfirm",
    EXTERNAL_LAWFIRM: "Lawfirm (External)",
  };

  bsValue = new Date();
  initialBsValue = null
  title: string = TITLE_PREFIX;
  advocateSageSetup: AdvocateSageSetupResponse;
  checkSageUserValid: CheckSageUserValid = {
    SageUserName: "",
    Password: ""
  };
  totalUnMatchedCount: number = 0;
  currentAdvocateSageDetails: string;
  sageUserName: string = '';
  password: string = '';
  lastTransactionPostedDate: Date;
  tempUserName: string = '';
  tempPassword: string = '';
  tempCompanyId: number = 0;
  companyId: number = 0;
  companyList: any = [];
  currentCompanyName: string;
  bankList: any = [];
  accountTypeList: any = [];
  vatList: any = [];
  datePickerDisable: boolean = false
  isAlreadySynced: boolean = true;
  showUserErrorPromptSageUserName = false;
  showUserErrorPromptPassword = false;
  showUserErrorPromptSaveUser = false;
  isValidAttSageUserName;
  showHide: boolean = false;
  GOshowHide: boolean = false;
  showHideUserNamePass: boolean = true;
  isDisabled = true;
  isValidPassword = false;
  isSent = false;
  saveEnable = false;
  btnGoShowHide = false;
  showUnsavedChangesPrompt: boolean;
  showErrorMessagePrompt: boolean;
  crumbs: ICrumb[];
  private _subscriptionNavigationStart: ISubscription;
  private _exitRoute: string;
  private _canIgnoreNavigationBlock: boolean = false;
  isCompanyDetailsAvailable: boolean = true;
  isErrorMessage: boolean = false;
  ErrorMessage: string;
  constructor(
    private router: Router,
    private navigation: NavigationService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userSageValidationService: UserSageValidationService,
    private userProfileService: UserProfileService,
  ) { }
  get isDirty(): boolean {
    return (Boolean(this.sageUserName != this.tempUserName) ||
      Boolean(this.password != this.tempPassword) ||
      Boolean(this.companyId != this.tempCompanyId))
  } // end isDirty()
  get isSaveable(): boolean {
    return Boolean(this.saveEnable)
  }
  get isReset(): boolean {
    return Boolean(!this.isAlreadySynced)
  }
  ngOnInit(): void {
    this.getCurrentAdvocateSageDetails();
    this.isValidAttSageUserName = false;
    this.crumbs = [
      {
        label: " SAGE | SAGE Setup",
      },
    ];
    this._subscriptionNavigationStart = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart && this.isDirty) {
        this._exitRoute = event.url;
        if (location.href.includes(PATH_USER_SAGE_VALIDATION)) {
          if (this.isDirty && !this._canIgnoreNavigationBlock) {
            this.navigation.canNavigate = false;
            this.showUnsavedChangesPrompt = true;
          }
          else {
            this.navigation.canNavigate = true;
          }
        }
      }
    });
  }
  getCurrentAdvocateSageDetails() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: "Getting Advocate SAGE Detail",
      action: null,
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    this.userSageValidationService.getCurrentAdvocateSageDetails().subscribe({
      next: (response) => {
        if (response.ErrorMessage) {
          this.isErrorMessage = true;
          this.ErrorMessage = response.ErrorMessage;
        }
        else if (!response.isCompanyDetailsAvailable)
          this.isCompanyDetailsAvailable = false;
        else {
          this.isCompanyDetailsAvailable = true;
          this.isAlreadySynced = response.isAlreadySynced;
          if (response.isAlreadySynced) {
            this.showHide = true;
            this.advocateSageSetup = response;
            this.bankList = response.BankDetails;
            if (response.lastTransactionPostedDate)
              this.initialBsValue = new Date(response.lastTransactionPostedDate);
            else
              this.initialBsValue = null
            this.lastTransactionPostedDate = response.lastTransactionPostedDate;
            this.accountTypeList = response.AccountTypeDetails;
            this.vatList = response.VatDetails;
            this.datePickerDisable = true;
            this.GOshowHide = true;
            this.showHideUserNamePass = false;
            this.currentCompanyName = response.CompanyName;
            this.tempCompanyId = response.CompanyId;
            this.tempPassword = response.SagePassword;
            this.tempUserName = response.SageUserName;
            this.password = response.SagePassword;
            this.sageUserName = response.SageUserName;
            this.companyId = response.CompanyId;
          }
        }

        this.loadingService.hideOverlay();
      },
      error: (error) => {
        snack.label = error;
        this.showHideUserNamePass = false;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.loadingService.hideOverlay();
      }
    })
  }
  CheckSageUserValid() {
    if (this.sageUserName == "" || this.sageUserName == undefined) {
      this.showUserErrorPromptSageUserName = true;
      return;
    } else if (this.password == "" || this.password == undefined) {
      this.showUserErrorPromptPassword = true;
      return;
    }
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: "Getting SAGE Company details",
      action: null,
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    this.checkSageUserValid = {
      SageUserName: this.sageUserName,
      Password: this.password,
    };
    this.userSageValidationService.checkSageUserValid(this.sageUserName, this.password)
      .subscribe({
        next: (advocateCompanyList) => {
          advocateCompanyList.forEach((item) => {
            this.companyList.push(item);
          })
          this.showHide = true;
          this.loadingService.hideOverlay();
        },
        error: (error) => {
          snack.label = 'incorrect SAGE UserName & Password';
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          snack.label = 'Verified SAGE user ';
          snack.type = SnackType.SUCCESS;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        }
      })
  }
  getAdvocateSageSetup() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: "Getting Advocate Bank Details",
      action: null,
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    this.userSageValidationService.advocateSageSetup(this.sageUserName, this.password, Number(this.companyId))
      .subscribe({
        next: (response) => {
          
          this.advocateSageSetup = response;
          this.isAlreadySynced = response.isAlreadySynced;
          this.bankList = response.BankDetails;
          this.accountTypeList = response.AccountTypeDetails;
          let unMatchedAccountType = this.accountTypeList.filter(x => x.IsMatched == false);
          let unMatchedBankList = this.bankList.filter(x => x.IsMatched == false);
          this.totalUnMatchedCount = (Number(unMatchedAccountType.length) + Number(unMatchedBankList.length));
          this.vatList = response.VatDetails;
          this.GOshowHide = true;
          this.btnGoShowHide = false;
          this.showHideUserNamePass = true;
          this.loadingService.hideOverlay();
        },
        error: (error) => {
          snack.label = error;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
          this.showHideUserNamePass = false;
        },
        complete: () => {
          snack.label = 'Success';
          snack.type = SnackType.SUCCESS;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        }
      })
  }
  saveAdvocateSageDetails() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: "Saving advocate SAGE user details",
      action: null,
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    this.showUserErrorPromptSaveUser = false;
    this.advocateSageSetup.LastTransactionPostedDateStr = moment(this.advocateSageSetup.LastTransactionPostedDate).format('YYYY-MM-DD 00:00:00');
    this.userSageValidationService.saveAdvocateSageUser(this.advocateSageSetup, this.sageUserName, this.password)
      .subscribe({
        next: (response) => {
          this.advocateSageSetup = response;
          this.showHide = true;
          if (this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER) {
            if (this.userProfileService.userProfile.serviceProviderID !== this.userProfileService.selectedUserProfile.serviceProviderID) {
              // PM - adv
              let advocateUserProfile = JSON.parse(sessionStorage.getItem('advocateUserProfil'));
              if (advocateUserProfile && advocateUserProfile.length > 0) {
                advocateUserProfile[0].accountingPlatform = 'Sage';
                sessionStorage.setItem('advocateUserProfil', JSON.stringify(advocateUserProfile));
              }
            }
          }
          else {
            // adv
            let userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
            if (userProfile) {
              userProfile.accountingPlatform = 'Sage';
              sessionStorage.setItem('userProfile', JSON.stringify(userProfile));
            }
          }
          this.loadingService.hideOverlay();
        },
        error: (error) => {
          snack.label = error;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          this.isSent = true;
          this.saveEnable = false;
          this.isAlreadySynced = true;
          this.loadingService.hideOverlay();
          setTimeout(() => {
            this.tempUserName = this.sageUserName;
            this.tempPassword = this.password;
            this.tempCompanyId = this.companyId;
            this.navigation.canNavigate = true;
            this.router.navigate([PATH_DASHBOARD]);
          }, 2500);
        }
      })
  }
  companyChanged($event: any) {
    if ($event) {
      this.companyId = Number($event.target.value);
      this.currentCompanyName = this.companyList.filter((x: any) => x.ID == Number(this.companyId))[0].DisplayName;
      this.btnGoShowHide = true;
    }
  }
  onBlurSageUserName() {
    if (this.sageUserName == "" || this.sageUserName == undefined)
      this.isValidAttSageUserName = true;
    else this.isValidAttSageUserName = false;
  }
  onBlurPassword() {
    if (this.password == "" || this.password == undefined)
      this.isValidPassword = true;
    else this.isValidPassword = false;
  }
  onHideAlert() {
    this.showUserErrorPromptSageUserName = false;
    this.showUserErrorPromptPassword = false;
  }
  onSageUserSaveHideAlert() {
    this.showUserErrorPromptSaveUser = true;
  }
  onSageCancelSaveUser() {
    this.showUserErrorPromptSaveUser = false;
  }
  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  }
  cancelSageUserSaveDetials() {
    this.showHideUserNamePass = true;
    this.sageUserName = null;
    this.password = null;
    this.companyList = [];
    this.companyId = 0;
    this.GOshowHide = false;
    this.showHide = false;
    this.currentCompanyName = null;
  }
  leavePage() {
    this._canIgnoreNavigationBlock = true;
    this.onHideUnsavedChangesPrompt();
    setTimeout((thisObj) => {
      thisObj.router.navigate([this._exitRoute.replace('/', '')]);
    }, 150, this);
  }
  onDateValueChange(e) {
    if (e instanceof Date) {
      this.advocateSageSetup.LastTransactionPostedDate = e;
      this.saveEnable = true;
    }
  }
  onDateBlurEvent(e) {
    const dateObject = new Date(e.target.value);
    if (dateObject.toString() !== 'Invalid Date') {
      this.saveEnable = true;
    } else {
      this.saveEnable = false;
    }
  }
  ResetSageSetup() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: "Reset Sage Setup",
      action: null,
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    this.userSageValidationService.removeAdvocateSageSetup().subscribe({
      next: (response) => {
        this.isCompanyDetailsAvailable = true;
        this.loadingService.hideOverlay();
      },
      error: (error) => {
        snack.label = error;
        this.showHideUserNamePass = false;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.loadingService.hideOverlay();
      }
    })
  }
}