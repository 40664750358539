import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvocateDetailStatementService,
         QueryReport } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-query-report-progress',
  templateUrl: './query-report-progress.component.html',
  styleUrls: ['./query-report-progress.component.scss']
})
export class QueryReportProgressComponent implements OnInit {

  title = 'Notes history';
  @Input() isVisible: boolean;
  @Input() queryReport: QueryReport[];
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private loadingService: LoadingService,
    private advocateDetailStatementService: AdvocateDetailStatementService
  ) { } // end constructor()

  ngOnInit(): void {
      console.log('KANTI ZIKHIPHANI? ', this.queryReport);
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // edn onCancel()

} // end QueryReportProgressComponent()
