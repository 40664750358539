import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-report-to-gcb-questions',
  templateUrl: './report-to-gcb-questions.component.html',
  styleUrls: ['./report-to-gcb-questions.component.scss']
})
export class ReportToGcbQuestionsComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() reportToBarPaymentRequestedQuestion: string;
  @Input() reportToBarDisputeQuestion: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() save: EventEmitter<any> = new EventEmitter<any>();

  get canSave(): Boolean {
    return this.util.isValidText(this.reportToBarDisputeQuestion) && this.util.hasTextValue(this.reportToBarPaymentRequestedQuestion);
  } // end canSave()

  constructor(
    private util: UtilitiesService
  ) { } // end constructor()

  ngOnInit(): void {
  } // end ngOnInit()

  onDisputeQuestion() {
    // console.log('selected dispute option: ', this.reportToBarDisputeQuestion);
  } // end onDisputeQuestion()

  onPaymentRequestedQuestio() {
    // console.log('selected request option: ', this.reportToBarPaymentRequestedQuestion);
  } // end onPaymentRequestedQuestio()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onSave() {
    let ourAnswers = {
      reportToBarPaymentRequestedQuestion: this.reportToBarPaymentRequestedQuestion,
      reportToBarDisputeQuestion: this.reportToBarDisputeQuestion
    }
    this.save.emit(ourAnswers)
  } // end onSave()
} // end ReportToGcbQuestions()
