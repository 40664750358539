import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-success-alert',
  templateUrl: './success-alert.component.html',
  styleUrls: ['./success-alert.component.scss']
})
export class SuccessAlertComponent implements OnInit {

  @Input() title: string;
  @Input() isVisible: boolean;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();

  constructor() { } // end constructor()

  ngOnInit() {
    if (!this.title) {
      this.title = 'Success';
    }
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onContinue() {
    this.continue.emit();
  } // end onContinue()
} // end SuccessAlertComponent{}
