import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Contacts, ContactsHandle, ContactsService, DEFAULT_CONTACTS, RegionalBar, DEFAULT_REGIONALBAR, RegionalBarHandle, } from '../../../services/contacts/contacts.service';
import { UtilitiesService } from '../../../services/utilities.service';
import * as moment from 'moment';
import { Snack, SnackType, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { DateParameters } from '../../../services/manage-BlackList/manage-BlackList.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-add-new-advocate',
  templateUrl: './add-new-advocate.component.html',
  styleUrls: ['./add-new-advocate.component.scss']
})
export class AddNewAdvocateComponent implements OnInit {

  bsValue = new Date();
  title = 'Add Advocate';
  workingAdvocate: Contacts = DEFAULT_CONTACTS;
  professionalLevels: any;
  //ContactsHandle: ContactsHandle[] = [];
  RegionalBarHandle: RegionalBarHandle[] = [];
  regionalBar: RegionalBar[] = [];
  selectedRegionalBarlist: RegionalBar;
  selectedRegionalBar: RegionalBar = DEFAULT_REGIONALBAR;

  @Input() isVisible: boolean;
  @Input() selectedAdvocate: Contacts;
  @Input() AdvocateOtherdetails: any;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaveAdvocate: EventEmitter<any> = new EventEmitter<any>();

  dateParameters: DateParameters = {
    AdmissionDay: null,
    AdmissionMonth: null,
    AdmissionYear: null
  };

  regionalbarlist: any;
  responseRegionalBarList: any;
  regionalBarName: string
  admissionDate: string;
  regionalbar: number;
  admissionDay: any[];
  admissionYear: any[];
  checkAdmissionMonth: number = 0;
  checkAdmissionYear: number = 0;
  checkAdmissionDay: number = 0;
  isValidAdmissionMonth = false;
  isValidAdmissionYear = false;

  get hasAdvocateNameError(): boolean {
    return !this.util.hasTextValue(this.workingAdvocate.FullName);
  } // end hasAdvocateNameError()

  get hasAdvocateEmailError(): boolean {
    return !this.util.isValidEmail(this.workingAdvocate.Email);
  } // end hasAdvocateEmailError()

  get hasPhoneNumberError(): boolean {
    return !this.util.isValidPhoneNumber(this.workingAdvocate.Phone,
      { code: this.userProfileService.userProfile.currencyDetails.code });
  } // end hasPhoneNumberError()

  get hasCellError(): boolean {
    return !this.util.isValidPhoneNumber(this.workingAdvocate.Cell,
      { code: this.userProfileService.userProfile.currencyDetails.code });
  } // end hasCellError()

  get canActivateAdvocate(): boolean {
    return Boolean(this.workingAdvocate && this.workingAdvocate.Id) &&
      Boolean(this.workingAdvocate && !this.workingAdvocate.UserName);
  } // end canActivateAdvocate()

  get isSubmittable(): Boolean {
    return Boolean(!this.hasAdvocateNameError) && Boolean(!this.hasPhoneNumberError) &&
      Boolean(!this.hasAdvocateEmailError) && Boolean(!this.isValidAdmissionYear)
      && Boolean(!this.isValidAdmissionMonth)
  }

  get isSubmittableUpdate(): Boolean {
    return Boolean(!this.hasAdvocateNameError) && Boolean(!this.hasPhoneNumberError) &&
      Boolean(!this.hasAdvocateEmailError) && Boolean(!this.isValidAdmissionYear)
      && Boolean(!this.isValidAdmissionMonth)
  }

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private contactService: ContactsService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
  ) { } // end constructor()

  ngOnInit(): void {
    this.admissionDay = [];
    for (let index = 1; index <= 31; index++) {
      this.admissionDay.push(index);
    }
    this.admissionYear = [];
    let years = moment().year();
    for (let index = 1960; index <= years; index++) {
      this.admissionYear.push({
        year: index
      });
    }
    this.admissionYear.sort((a, b) => (a.year < b.year ? 1 : -1));
    if (this.selectedAdvocate && this.selectedAdvocate.Id) {
      this.title = 'Edit Advocate';

      if (this.selectedAdvocate.AdmissionDay)
        this.checkAdmissionDay = this.selectedAdvocate.AdmissionDay;
      else
        this.checkAdmissionDay = 0;
      if (this.selectedAdvocate.AdmissionMonth)
        this.checkAdmissionMonth = this.selectedAdvocate.AdmissionMonth;
      else
        this.checkAdmissionMonth = 0;
      if (this.selectedAdvocate.AdmissionYear)
        this.checkAdmissionYear = this.selectedAdvocate.AdmissionYear;
      else
        this.checkAdmissionYear = 0;
    }

    this.workingAdvocate = this.util.objectCopy(this.selectedAdvocate);
    this.professionalLevels = [
      { label: 'Junior', value: 'Junior' },
      { label: 'Senior', value: 'Senior' },
    ];
    this.getAllRegionalBar();
  } // end ngOnInit()

  getAllRegionalBar() {
    this.loadingService.showOverlay();
    this.contactService.getAllRegionalBar().subscribe({
      next: (data) => {
        console.log('RETURNED DATA: ', data);
        this.regionalbarlist = data;
        if (data) {
          data.forEach(element => {
            this.RegionalBarHandle.push({
              data: element,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          });
        }
      },
      complete: () => {
        this.loadingService.hideOverlay();
      }
    })
  } // end getAllAdvocates()

  onDismiss() {
    this.closeModal.emit('advocate');
  } // end onDismiss()

  save() {
    this.loadingService.showOverlay();
    this.workingAdvocate.ContactContactType = 2;
    //this.workingAdvocate.AdmissionDateStr = this.workingAdvocate.AdmissionDate ? moment(this.workingAdvocate.AdmissionDate).format('YYYY-MM-DD 00:00:00') : null;
    this.workingAdvocate.AdmissionDay = this.checkAdmissionDay ? this.checkAdmissionDay : null,
      this.workingAdvocate.AdmissionMonth = this.checkAdmissionMonth ? this.checkAdmissionMonth : null,
      this.workingAdvocate.AdmissionYear = this.checkAdmissionYear ? this.checkAdmissionYear : null

    this.contactService.postNewAdvocate(this.workingAdvocate).subscribe({
      next: (data) => {
      },
      error: (error) => {
        const snack: Snack = {
          label: error,
          type: SnackType.ERROR,
          action: null
        };
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        const snack: Snack = {
          label: 'Advocate added successfully.',
          action: null
        };
        this.loadingService.hideOverlay();
        this.snackbarsService.dismiss().make(snack).show();
        this.onSaveAdvocate.emit('saved');
      }
    });
  } // end save()

  onActivateUser() {
    this.loadingService.showOverlay();
    this.contactService.putActivateUser(this.workingAdvocate.Id).subscribe({
      next: (data) => {
        // ON next
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.onSaveAdvocate.emit('updated');
      }
    });
  } // end onActivateUser()

  update(event) {
    this.loadingService.showOverlay();
    //this.workingAdvocate.AdmissionDateStr = this.workingAdvocate.AdmissionDate ? moment(this.workingAdvocate.AdmissionDate).format('YYYY-MM-DD') : null;
    this.workingAdvocate.AdmissionDay = this.checkAdmissionDay ? this.checkAdmissionDay : null,
      this.workingAdvocate.AdmissionMonth = this.checkAdmissionMonth ? this.checkAdmissionMonth : null,
      this.workingAdvocate.AdmissionYear = this.checkAdmissionYear ? this.checkAdmissionYear : null

    if (!this.workingAdvocate.RegionalBarName) {
      this.workingAdvocate.RegionalBarName = null;
      this.workingAdvocate.RegionalBarId = null;
    }

    this.loadingService.showOverlay();
    this.contactService.PutContact(this.workingAdvocate).subscribe({
      next: (data) => {
      },
      error: (error) => {
        const snack: Snack = {
          label: error,
          type: SnackType.ERROR,
          action: null
        };
        this.loadingService.hideOverlay();
        this.snackbarsService.dismiss().make(snack).show();
        console.error('Something went wrong: ', error);
      },
      complete: () => {
        const snack: Snack = {
          label: 'Advocate saved successfully. ',
          action: null
        };
        this.loadingService.hideOverlay();
        this.snackbarsService.dismiss().make(snack).show();
        this.onSaveAdvocate.emit('updated');
      }
    });
  } // end update()

  onInput(event) {
    if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
    }
  }
  onModelChange(event, selectedRegionalBar: RegionalBar) {
    this.workingAdvocate.RegionalBarId = selectedRegionalBar.RegionalBarId;
  } // end onModelChange()
  onSelect(event: TypeaheadMatch): void {
    this.selectedRegionalBarlist = DEFAULT_REGIONALBAR;
    this.selectedRegionalBarlist.RegionalBarId = event.item.Id;
    this.selectedRegionalBarlist.RegionalBarName = event.item.Name;
    this.workingAdvocate.RegionalBarName = event.item.Name
    this.workingAdvocate.RegionalBarId = event.item.Id
  }

  validateAdmissionDate(day, month, year) {
    this.isValidAdmissionMonth = false;
    this.isValidAdmissionYear = false;

    if (day > 0) {
      if (month === null || month === "" || month === undefined || month == "0") {
        this.isValidAdmissionMonth = true;
      }
      if (year === null || year === "" || year === undefined || year == "0") {
        this.isValidAdmissionYear = true;
      }
    }
    if (month > 0) {
      if (year === null || year === "" || year === undefined || year == "0") {
        this.isValidAdmissionYear = true;
      }
    }
  }

  onChangeAdmissionDay(day) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  onChangeAdmissionMonth(month) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  onChangeAdmissionYear(year) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  // onDateValueChange(e) {
  //   if (e instanceof Date) {
  //     this.workingAdvocate.AdmissionDate = e;
  //   }
  // }

} // end AddNewAdvocateComponent {}
