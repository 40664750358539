import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvocateDetailLineItem,
         AdvocateDetailLineItemHandle, 
         AdvocateDetailStatementService, 
         DEFAULT_ADVOCATE_DETAIL_LINE_ITEM,
         DEFAULT_DISPUTE_STATUSES,
         DisputeStatuses} from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-invoice-line-detail-modal',
  templateUrl: './invoice-line-detail-modal.component.html',
  styleUrls: ['./invoice-line-detail-modal.component.scss']
})
export class InvoiceLineDetailModalComponent implements OnInit {
  title: string = 'Invoice Line Details';

  selectedDetailDataHandle: AdvocateDetailLineItemHandle;
  onShowInvoiceLinesDispite: boolean;
  advocateLineItems: AdvocateDetailLineItem = Object.assign({}, DEFAULT_ADVOCATE_DETAIL_LINE_ITEM);
  disputStatues: DisputeStatuses = Object.assign({}, DEFAULT_DISPUTE_STATUSES);
  workingInvoiceDetailDataHandle: AdvocateDetailLineItemHandle[] = [];

  @Input() isVisible: boolean;
  @Input() details: AdvocateDetailLineItem[];
  @Input() invoiceDetailDataHandle: AdvocateDetailLineItemHandle[];
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatedStatus: EventEmitter<any> = new EventEmitter<any>();

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.invoiceDetailDataHandle, this.workingInvoiceDetailDataHandle);
  } // end isDirty()

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    public userProfileService: UserProfileService,
    private advocateDetailService: AdvocateDetailStatementService
  ) { } // end constructor()

  ngOnInit(): void {
    this.workingInvoiceDetailDataHandle = [];
    this.workingInvoiceDetailDataHandle = this.invoiceDetailDataHandle;
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onLineDispute() {
    let dataToSaveData = [];
    let dataToSave = this.invoiceDetailDataHandle.filter(x => x.state.updated === true);
    dataToSave.forEach(x => {
      dataToSaveData.push(x.data);
    })
     this.loadingService.showOverlay();
    this.advocateDetailService.updateDisputeStatusList(dataToSaveData).subscribe({ next: (next) => {
      // On next
    },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.onShowInvoiceLinesDispite = false;
        this.cancel.emit();
        this.loadingService.hideOverlay();
      }
    });
  } // end onLineDispute();

  onLineItemClicked(detail: AdvocateDetailLineItemHandle) {
    this.selectedDetailDataHandle = detail;
    this.disputStatues = Object.assign({}, DEFAULT_DISPUTE_STATUSES);
    this.disputStatues.Note = this.selectedDetailDataHandle.data.DisputeNote;
    this.disputStatues.Source = this.selectedDetailDataHandle.data.DisputeSource;
    this.disputStatues.Status = this.selectedDetailDataHandle.data.DisputeStatus;
    this.disputStatues.SourceId = this.selectedDetailDataHandle.data.Id;
    this.disputStatues.Description = this.selectedDetailDataHandle.data.DisputeDescription;
    this.onShowInvoiceLinesDispite = true;
  } // end onLineItemClicked()

  onInvoiceStatusUpdate(data: AdvocateDetailLineItemHandle) {
    this.invoiceDetailDataHandle.forEach(x => {
      if(x.data.Id === data.data.Id) {
        x = data;
      }
    });
    this.onShowInvoiceLinesDispite = false;
  } // end onInvoiceStatusUpdate()

  closeModal(value: string) {
    switch (value) {
      case 'line-item-dispute':
        this.onShowInvoiceLinesDispite = false;
      break;
    }
  } // end closeModal()
} // end InvoiceLineDetailModalComponent()
