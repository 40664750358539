
import { map, catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService, httpOptions } from '../api.service';
// import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { ToastService } from '../messaging/toast/toast.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import * as moment from 'moment';

export interface ReportEndPoints {
  invoiceHistoryByLawFirm: string;
} // end ReportEndPoints{}

export enum ReportType {
  BusinessReports = 'businessReports',
  FinancialReports = 'financialReports',
  LiveStatement = 'liveStatement',
  PracticeManagementReports = 'practiceManagementReports',
  IndustryReports = 'industryReport'
} // end ReportType{}

export interface ReportParameters {
  fromDate: string;
  toDate: string;
  serviceProviderId: number;
  serviceId: number;
  lawFirmId: number;
  fromYear: string;
  fromMonth: string;
  toYear: string;
  toMonth: string;
  searchText: string;
  format: string;
}
export interface PracticeManagementParameters {
  fromDate: string;
  toDate: string;
}
export interface IndustryParameters {
  fromDate: string;
  // toDate: string;
}

export interface LawfirmSummaryOverview {
  lawfirmId: number;
  advocateId: number;
  lastInvoiceDate: string;
  totalPaidYear: number;
  totalAverage: number;
  partialPaidInvoices: number;
  totalOutstandingInvoices: number;
  lastTransactionDate: string;
  amount: number;
  unAllocated: number;
}

export const DEFAUL_LAWFIRM_SUMMARY_OVERVIEW: LawfirmSummaryOverview = {
  lawfirmId: 0,
  advocateId: 0,
  lastInvoiceDate: '',
  totalPaidYear: 0,
  totalAverage: 0,
  partialPaidInvoices: 0,
  totalOutstandingInvoices: 0,
  lastTransactionDate: '',
  amount: 0,
  unAllocated: 0
};

export interface IndustryReport {
  ClientId: number;
  Client: string;
  PaymentRating: number;
  AvgDaysToPayment: string;
  PaidVsInvoiced: number;
  TotalInvPaid: number;
  InvDue0: number;
  InvDue30: number;
  InvDue60: number;
  InvDue90: number;
  InvDue98: number;
  NoOfInvoices: number;
  TotalDays: number;
  IntAvgDays: number;
  AdvocateCount: number;
  LPCCount: number;
  GCBCount: number;
  LawfirmLocalitie: string;
  GcbReportStatus: GcbReportStatus
} // end IndustryReport{}

export interface GcbReportStatus {
  DateWithRegionalBar: string
}

export interface AllocatedToLawfirm {
  id: number;
  transactionDate: string;
  description: string;
  amount: number;
  transactionAmount: number;
  balance: number;
  invoiceId: number;
  serviceProviderId: number;
  lawFirmId: number;
  advocateFullName: string;
  lawFirmFullName: string;
} // end AllocatedToLawfirm{}

export interface ContactProcess {
  id: number;
  contact_ContactProcess: number;
  contact_ContactPerson: number;
  contactPersonName: string;
  contactPersonEmail: string;
  contactPersonPhone: string;
  lawFirmProcess: string;
  haveSystem: boolean;
  ableToProviceStatusFeedback: boolean;
  willingToProviceStatusFeedback: string;
  receiveStatusFeedback: string;
  system: string;
  plaintiffDefendant: string;
}

export const DEFAULT_CONTACT_PROCESS: ContactProcess = {
  id: 0,
  contact_ContactProcess: 0,
  contact_ContactPerson: 0,
  contactPersonName: '',
  contactPersonEmail: '',
  contactPersonPhone: '',
  lawFirmProcess: '',
  haveSystem: false,
  ableToProviceStatusFeedback: false,
  willingToProviceStatusFeedback: '',
  receiveStatusFeedback: '',
  system: '',
  plaintiffDefendant: '',
};

export interface InLegalFirm {
  clientId: number;
  advocateId: number;
  isInLegal: boolean;
}

export interface LPCFirm {
  clientId: number;
  advocateId: number;
  canCollect: boolean;
  canCOllectReason: string;
}

export const DEFAULT_LPC_FIRM: LPCFirm = {
  clientId: 0,
  advocateId: 0,
  canCollect: false,
  canCOllectReason: ''
}

export const DEFAULT_IN_LEGAL: InLegalFirm = {
  clientId: 0,
  advocateId: 0,
  isInLegal: false
};

export interface AllocatedToLawfirmHandle {
  data: AllocatedToLawfirm;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

export interface ProvisionalTaxView {
  ContactId: number;
  AdvocateName: string;
  FromDate: string;
  Year: number;
  Month: number;
  MonthName: string;
  TaxAmount: number;
  ProvisionalTaxAmount: number;
  TaxType: string
  TaxBasedOn: string;
  CurrencySymbol: string;
}

@Injectable()
export class ReportsService {
  reportendpoints: ReportEndPoints;
  selectedReportType: ReportType;
  selectedReport: string;
  reportUrl: string;
  practiceManagementReportParameters: Subject<PracticeManagementParameters>;
  industryReportParameters: Subject<IndustryParameters>;
  IndividualCustomerReportParameters: Subject<number>;
  widgetReportParameters: Subject<PracticeManagementParameters>;
  allCustomerActiveTab: string;
  individualCustomerActiveTab: string;
  individualCustomerId: number;
  individualCustomerFrom: string;



  constructor(
    private http: HttpClient,
    private api: ApiService,
    private userProfileService: UserProfileService,
    private toastService: ToastService,
  ) {
    this.practiceManagementReportParameters = new Subject<PracticeManagementParameters>();
    this.industryReportParameters = new Subject<IndustryParameters>();
    this.IndividualCustomerReportParameters = new Subject<number>();
    this.widgetReportParameters = new Subject<PracticeManagementParameters>();
  } // end constructor()

  getReports(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'blob'
    }).pipe(map(
      (res) => {
        return new Blob([res], { type: 'application/pdf' });
      }));
  } // end getReports()

  getLawfirmSummaryOverviewList(advocateId: number, lawfirmId: string): Observable<any> {
    return this.http.get<any>(this.api.endpoints.lawfirmSummaryOverviewList +
      '?AdvocateId=' + advocateId + lawfirmId.replace(',', ''), this.api.httpOptions
    ).pipe(catchError(this.api.handleError)
    );
  } // getLawfirmSummaryOverviewList()

  getIndustryReports(skip: number, take: number, startDate: string): Observable<any> {
    return this.http.get<any>(this.api.endpoints.allFirmsAveragePaymentDays +
      '?Skip=' + skip + '&Take=' + take
      + '&StartDate=' + startDate
      , this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getIndustryReports()

  getAllGcbTooltipList(lawfirmid : number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.getAllGcbTooltipList + '?lawfirmId=' + lawfirmid 
      , this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getAllGcbTooltipList()
  
  getGCBPaymentTrends(): Observable<any> {
    return this.http.get<any>(this.api.endpoints.paymentTrends, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getGCBPaymentTrends()

  getIndustryReportsBySearch(searchTerm: string, startDate: string): Observable<any> {
    return this.http.get<any>(this.api.endpoints.allFirmsAveragePaymentDaysBySearch +
      '?SearchTerm=' + searchTerm
      // + '&StartDate=' + startDate
      , this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getIndustryReports()

  getInvoicedCollectedMonthlySummary(fromDate: string, toDate: string): Observable<any> {
    this.reportUrl = this.api.endpoints.invoicedCollectedMonthlySummaryReport +
      '?ServiceProviderId=' + this.api.serviceProviderID +
      '&FromDate=' + fromDate + '&ToDate=' + toDate;

    return this.http.get(this.reportUrl, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getProvisionalTax(fromDate: string, toDate: string): Observable<any> {
    return this.http.get(this.api.endpoints.practiceManagement + '/ProvisionalTaxView?ServiceProviderId=' + this.api.serviceProviderID +
      '&FromDate=' + fromDate + '&ToDate=' + toDate,
      this.api.httpOptions);
  } // end getProvisionalTax()

  setPracticeManagementParameters(paramObj: PracticeManagementParameters): void {
    // paramObj.toDate = moment(paramObj.toDate).format('YYYY-MM-DD HH:mm:ss');
    // paramObj.fromDate = moment(paramObj.fromDate).format('YYYY-MM-DD HH:mm:ss');
    this.practiceManagementReportParameters.next(paramObj);
  }
  setAverageDaysParameters(paramObj: IndustryParameters): void {
    this.industryReportParameters.next(paramObj);
  }

  getAverageDaysParameters(): Observable<IndustryParameters> {
    return this.industryReportParameters.asObservable();
  }
  getPracticeManagementParameters(): Observable<PracticeManagementParameters> {
    return this.practiceManagementReportParameters.asObservable();
  }

  setInvoicedCollectedWidgetParameters(paramObj: PracticeManagementParameters): void {
    this.widgetReportParameters.next(paramObj);
  }

  getInvoicedCollectedWidgetParameters(): Observable<PracticeManagementParameters> {
    return this.widgetReportParameters.asObservable();
  }

  getAgeAnalysis(): Observable<any> {
    return this.http.get(this.api.endpoints.ageAnalysisReport +
      '?serviceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getTaxPeriod(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceManagement +
      '/api/GetTaxPeriod', this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getTaxPeriod()

  getContactProcess(lawfirmId: number): Observable<any> {
    return this.http.get(this.api.endpoints.contactProcess +
      '/GetContactProcess?LawfirmId=' + lawfirmId, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getContactProcess()

  postContactProcess(data: ContactProcess): Observable<any> {
    return this.http.post(this.api.endpoints.contactProcess,
      data, this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  } // #end postContactProcess()

  putContactProcess(data: ContactProcess): Observable<any> {
    return this.http.put(this.api.endpoints.contactProcess +
      '?id=' + data.id, data, this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  }

  putInLegalStatus(advocateId: number, debtorId: number, handoverTo, status: boolean): Observable<any> {
    return this.http.put(this.api.endpoints.contactProcess +
      '/InLegal?ProviderId=' + advocateId +
      '&DebtorId=' + debtorId + '&HandOverTo=' + handoverTo + '&Status=' + status, this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  }

  getAllCustomersReport(): Observable<any> {
    return this.http.get(this.api.endpoints.allCustomersReport +
      '?serviceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getLawfirmSummaryOverview(lawfirmId: number): Observable<any> {
    return this.http.get(this.api.endpoints.lawfirmSummaryOverview +
      '?AdvocateId=' + this.api.serviceProviderID + '&LawfirmId=' + lawfirmId, this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  } // end getLawfirmSummaryOverview()

  getPMLawfirmSummaryOverview(advocateId: number, lawfirmId: number): Observable<any> {
    return this.http.get(this.api.endpoints.lawfirmSummaryOverview +
      '?AdvocateId=' + advocateId + '&LawfirmId=' + lawfirmId, this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  } // end getPMLawfirmSummaryOverview()

  getByIndividualCustomer(clientId: number): Observable<any> {
    return this.http.get(this.api.endpoints.byIndividualCustomer +
      '?serviceProviderId=' + this.api.serviceProviderID + '&clientId=' + clientId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getAllocatedToLawfirm(clientId: number): Observable<any> {
    return this.http.get(this.api.endpoints.allocatedToLawfirm +
      '?ServiceProviderID=' + this.api.serviceProviderID +
      '&ClientID=' + clientId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getAllocatedToLawfirm()

  putDiscountingInvoice(individualCustomer: any): Observable<any> {
    return this.http.put(this.api.endpoints.discounting,
      individualCustomer, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end putDiscountingInvoice()

  setIndividualCustomerParameters(debtorId: number): void {
    this.IndividualCustomerReportParameters.next(debtorId);
  }

  getIndividualCustomerParameters(): Observable<number> {
    return this.IndividualCustomerReportParameters.asObservable();
  }

  getCustomersPaymentRating(): Observable<any> {
    return this.http.get(this.api.endpoints.paymentRating +
      '?serviceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getDistinctCustomerList(): Observable<any> {
    return this.http.get(this.api.endpoints.distinctCustomerList +
      '?serviceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getTaxationDates(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceManagement +
      '/api/TaxationDates?ServiceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getTaxationDates()

  // betaGetReports(): Observable<any> {

  // } // betaGetReports()
}
