import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FromCalendarItem } from '../../../services/from-calendar-items/from-calendar-items.service';
import { Matter, DEFAULT_MATTER } from '../../../services/matters/matters.service';
import { Category } from '../../../services/categories/categories.service';
import { SubCategory, DEFAULT_SUB_CATEGORY } from '../../../services/sub-categories/sub-categories.service';
import * as moment from 'moment';
import { UtilitiesService } from '../../../services/utilities.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { DatalistCategoryComponent } from '../../inputs/datalist-category/datalist-category.component';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { AmazingTimePickerService } from '@jonijnm/amazing-time-picker';

const TAG_NEW_FEE_TITLE = 'Add a new fee to your calendar';
const TAG_EDIT_FEE_TITLE = 'Update fee on your calendar';

interface AddFeeCalendarModalState {
  isVisible: boolean;
  calendarItem: FromCalendarItem;
  defaultMatter: Matter;
  defaultSubCategory: SubCategory;
  matters: Matter[];
  subCategories: SubCategory[];
  startTime: any; // = new Date();
  endTime: any; // = new Date();
  selectedMatter: Matter;
  selectedSubCategory: SubCategory;
  selectedStartDate: string;
  selectedEndDate: string;
  selectedStartTime: string;
  selectedEndTime: string;
  categories: Category[];
  quantityIsReadOnly: boolean;
  showQunatityToolTip: boolean;
  showDeleteFeeModal: boolean;
  showUnsavedChangesPrompt: boolean;
  initialCalendarItem: FromCalendarItem;
}

export const DEFAULT_ADD_FEE_CALENDAR_MODAL_STATE: AddFeeCalendarModalState = {
  isVisible: false,
  calendarItem: null,
  defaultMatter: null,
  defaultSubCategory: null,
  matters: [],
  subCategories: [],
  startTime: '',
  endTime: '',
  selectedMatter: null,
  selectedSubCategory: null,
  selectedStartDate: '',
  selectedEndDate: '',
  selectedStartTime: '',
  selectedEndTime: '',
  categories: [],
  quantityIsReadOnly: false,
  showQunatityToolTip: false,
  showDeleteFeeModal: false,
  showUnsavedChangesPrompt: false,
  initialCalendarItem: null
};

@Component({
  selector: 'app-add-fee-calendar-modal',
  templateUrl: './add-fee-calendar-modal.component.html',
  styleUrls: ['./add-fee-calendar-modal.component.scss']
})
export class AddFeeCalendarModalComponent implements OnInit, OnChanges {

  @Input() isVisible: boolean;
  @Input() isOutlookCalendarEntry: boolean;
  @Input() calendarItem: FromCalendarItem;
  @Input() defaultMatter: Matter;
  @Input() defaultSubCategory: SubCategory;
  @Input() matters: Matter[];
  @Input() subCategories: SubCategory[];
  @Input() startTime: string;
  @Input() endTime: string;
  @Output() calendarItemChange: EventEmitter<FromCalendarItem> = new EventEmitter<FromCalendarItem>();
  @Output() startTimeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() endTimeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<FromCalendarItem> = new EventEmitter<FromCalendarItem>();
  @Output() onHideThisEvent: EventEmitter<FromCalendarItem> = new EventEmitter<FromCalendarItem>();
  @Output() save: EventEmitter<FromCalendarItem> = new EventEmitter<FromCalendarItem>();
  @ViewChild(DatalistCategoryComponent) dataListCategoty: DatalistCategoryComponent;
  @ViewChild('inputRate') focusRate: ElementRef;
  @ViewChild('inputDiscount') focusDiscount: ElementRef;

  title: string = TAG_NEW_FEE_TITLE;
  selectedMatter: Matter;
  rateTypes: string[] = [
    'Hourly',
    'Daily',
    'Once Off',
    'Per Kilometer',
    'Per Page',
    'Disbursement',
    'Non Billable'
  ];
  selectedStartDate: string;
  selectedEndDate: string;
  selectedStartTime: string;
  selectedEndTime: string;
  categories: Category[];
  selectedSubCategory: SubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
  quantityIsReadOnly = false;
  showQunatityToolTip = false;
  showDeleteFeeModal: boolean;
  showUnsavedChangesPrompt: boolean;
  initialCalendarItem: FromCalendarItem;
  isAddNewMatter: boolean;
  EventDetails: any;
  isQunatityDisable: boolean = true;
  showHideClendarPrompt: boolean = false;
  OutlookSubjectHide: string = '';

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  get isQuantityEditable(): boolean {
    if (this.calendarItem.RateType === 'Hourly' || this.calendarItem.RateType === 'Non Billable') {
      // if (this.startTime === '00:00'
      //   && (this.endTime === '00:00' || this.endTime === '00:00:00')) {
      //   return false;
      // }
      // return true;
      return !this.calendarItem.ShowInList;
    } else {
      return !(Boolean(this.calendarItem.RateType === 'Per Page')
        || Boolean(this.calendarItem.RateType === 'Per Kilometer')
      );
    }
  } // end isQuantityEditable(()

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.initialCalendarItem,
      this.calendarItem);
  } // end isDirty(()

  get isSaveable(): boolean {
    if (this.dataListCategoty) {
      return Boolean(!this.dataListCategoty.isValidText) &&
        Boolean(this.selectedMatter) &&
        Boolean(this.selectedMatter.ServiceID) &&
        // Boolean(this.selectedSubCategory) &&
        Boolean(!this.hasEndTimeBeforeStartTimeError) &&
        Boolean(!this.hasStartTimeAfterEndTimeError);
    }
  } // end isSaveable()

  get isDeleteable(): boolean {
    return Boolean(this.calendarItem.CalendarItemID);
  } // end isDeleteable()

  get calendarItemDate(): Date {
    if (!this.calendarItem) {
      const d = moment().format();
      return this.parseLineItemDate(d); // d.substr(0, d.indexOf('T'));
    }
    if (!this.calendarItem.Date) {
      this.calendarItem.Date = moment().format();
      return this.parseLineItemDate(this.calendarItem.Date);
    }

    if (!this.calendarItem.Date.substr(0, this.calendarItem.Date.indexOf('T'))) {
      this.calendarItem.Date = moment(this.calendarItem.Date).format();
      return this.parseLineItemDate(this.calendarItem.Date);
    }
    // .Date.substr(0, this.calendarItem.Date.indexOf('T')));
    return this.parseLineItemDate(this.calendarItem.Date);
    //.Date.substr(0, this.calendarItem.Date.indexOf('T'));
  } // end get calendarItemDate()

  parseLineItemDate(lineDate: string): Date {
    if (lineDate) {
      let date = new Date(lineDate);
      date.setHours(0, 0, 0, 0);
      return date;
    } else
      return null;
  } // end parseLineItemDate()

  get canChangeRateType(): boolean {
    return Boolean(!this.selectedSubCategory.SubCategoryId);
  } // end canChangeRateType()

  get hasStartTimeAfterEndTimeError(): boolean {
    if (this.calendarItem.RateType.localeCompare('Hourly') !== 0 &&
      this.calendarItem.RateType.localeCompare('Daily') !== 0) {
      return false;
    }

    if (this.startTime === '00:00' && (this.endTime === '00:00' || this.endTime === '00:00:00'))
      return false;

    return moment(this.startTime, 'HH:mm').isSameOrAfter(moment(this.endTime, 'HH:mm'));
  }

  get hasEndTimeBeforeStartTimeError(): boolean {
    if (this.calendarItem.RateType.localeCompare('Hourly') !== 0 &&
      this.calendarItem.RateType.localeCompare('Daily') !== 0) {
      return false;
    }

    if (this.startTime === '00:00' && (this.endTime === '00:00' || this.endTime === '00:00:00'))
      return false;

    return moment(this.endTime, 'HH:mm').isSameOrBefore(moment(this.startTime, 'HH:mm'));
  }

  get isSafari(): boolean {
    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
      return false;
    } else {
      return true;
    }
  }

  set calendarItemDate(value) {
    this.calendarItem.Date = moment(value).format();
  } // end set calendarItemDate()

  private today: Date = new Date;
  private _date: string;
  private _history = [];
  private _state: AddFeeCalendarModalState = Object.assign({}, DEFAULT_ADD_FEE_CALENDAR_MODAL_STATE);
  private _initialState: AddFeeCalendarModalState;
  private _canInit = true;

  constructor(
    private util: UtilitiesService,
    private userProfileService: UserProfileService,
    private cpipe: UsercurrencyPipe,
    private atp: AmazingTimePickerService
    // private atp: AmazingTimePickerService
  ) { } // end constructor()

  ngOnInit() {
    this._date = `${this.today.getFullYear()
      }-${this.today.getMonth().toString().padStart(2, '0')
      }-${this.today.getDate().toString().padStart(2, '0')
      }`;
    this.selectedStartDate = this._date;
    this.selectedEndDate = this._date;
    this.util.getOutlookCalendarEntryData().subscribe(data => {
      this.isOutlookCalendarEntry = data;
    });
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.calendarItem && changes.calendarItem.currentValue) {
      if (this.calendarItem.CalendarItemID)
        this.title = TAG_EDIT_FEE_TITLE;
      else {
        this.title = TAG_NEW_FEE_TITLE;
        this.updateFeeTotal();
      }
      this.updateQunatity();
      this.initCalendarItem();
    }

    if (changes.isVisible && changes.isVisible.currentValue) {
      if (this.calendarItem.CalendarItemID) {
        if (this.defaultMatter) {
          this.selectedMatter = this.defaultMatter;
        }

        if (this.defaultSubCategory) {
          this.selectedSubCategory = this.defaultSubCategory;
        }
      }
      // setTimeout(() => {
      //   this.focusRate.nativeElement.value = this.cpipe.transform(this.focusRate.nativeElement.value,
      //     this.userProfileService.userProfile.currencyDetails.symbol, 2);
      // }, 600);
    }

    if (changes.defaultMatter && changes.defaultMatter.currentValue) {
      this.selectedMatter = this.defaultMatter;
    }

    if (changes.defaultSubCategory && changes.defaultSubCategory.currentValue) {
      this.selectedSubCategory = this.defaultSubCategory;
    }

    if (changes.startTime && changes.startTime.currentValue) {
      this.updateQunatity();
      if (!changes.startTime.previousValue) {
        this.initCalendarItem();
      }
    }

    if (changes.endTime && changes.endTime.currentValue) {
      this.updateQunatity();
      if (!changes.endTime.previousValue) {
        this.initCalendarItem();
      }
    }
    this.clearHistory();
  } // end ngOnChanges()

  onFocusRateChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusRate.nativeElement.value = this.cpipe.transform(this.focusRate.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusRate.nativeElement.value = this.cpipe.transform(this.focusRate.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onFocusRateChange()

  onDiscountChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusDiscount.nativeElement.value = this.cpipe.transform(this.focusDiscount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusDiscount.nativeElement.value = this.cpipe.transform(this.focusDiscount.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  }

  onFocusRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusRate.nativeElement.value = this.cpipe.parse(this.focusRate.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusRate.nativeElement.value = this.cpipe.parse(this.focusRate.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onFocusRateClicked()

  onFocusDicountClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusDiscount.nativeElement.value = this.cpipe.parse(this.focusDiscount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusDiscount.nativeElement.value = this.cpipe.parse(this.focusDiscount.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  }

  /**
   * Resets the modal fields to their initial values.
   */
  reset() {
    // this.selectedMatter = Object.assign({}, DEFAULT_MATTER);
    this.selectedMatter = this.defaultMatter;
    this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
    this.onHideUnsavedChangesPrompt();
    this.clearHistory();
  } // end reset()

  onShowUnsavedChangesPrompt() {

    if (this.isDirty) {
      this.showUnsavedChangesPrompt = true;
    } else {
      this.dismiss();
    }
  } // end onShowUnsavedChangesPrompt()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onShowUnsavedChangesPrompt()

  /**
   * Resets and closes the modal.
   */
  dismiss() {
    //   !this.util.objectIsSame(this.initialCalendarItem, this.calendarItem));
    this.reset();
    this.cancel.emit();
  } // end dismiss()

  /**
   * Called when a click is registerd on the modal component.
   * @param event The dispatched click event.
   */
  onModalClick(event) {
    if (
      !event.target.className.includes('fa-question-circle') &&
      !event.target.className.includes('quantity-input')
    ) {
      this.hideToolTip('quantity');
    }
  } // end onModalClick()

  /**
   * Called when the 'Save and Close' button is clicked.
   */
  onSave() {
    this.onHideUnsavedChangesPrompt();
    this.calendarItem.FromDate = moment(this.calendarItem.FromDate).format('YYYY-MM-DD HH:mm:ss');
    this.calendarItem.ToDate = moment(this.calendarItem.ToDate).format('YYYY-MM-DD HH:mm:ss');
    
    setTimeout((thisObj) => {
      this.dismiss();
      this.isOutlookCalendarEntry = false;
    }, 300, this);

    this.save.emit(this.calendarItem);
    
  } // end save()

  /**
   * Called when the 'Delete' button is clicked.
   */
  onShowDeleteFeeModal() {
    this.showDeleteFeeModal = true;
  } // end onShowDeleteFeeModal()

  /**
   * Called when the Delete Fee Modal 'Cancel' event is triggered.
   */
  onCloseDeleteFeeModal() {
    this.showDeleteFeeModal = false;
  } // end onCloseDeleteFeeModal()

  /**
   * Called when a fee deletion is confirmed.
   */
  onDelete() {
    this.delete.emit(this.calendarItem);
    this.dismiss();
  } // end onDelete()

  /**
   * Toggles the IsVatable field between true and false.
   */
  toggleVatable() {
    this.calendarItem.IsVatable = !this.calendarItem.IsVatable;
    this.takeSnapShot();
  } // end toggleVatable()

  /**
   * Called when the Date field changes.
   * @param event The dispatched date change event.
   */
  onDateChange(event) {

    this.updateStartTimeDate(event);
    this.updateEndTimeDate(event);
    this.takeSnapShot();
    this.calendarItemChange.emit(this.calendarItem);
  } // end onDateChange()

  /**
   * Called when a matter is selected from the matted dropdown list.
   * @param {Matter} selectedMatter The selected matter.
   */
  onMatterSelect(selectedMatter: Matter) {
    this.selectedMatter = Object.assign({}, DEFAULT_MATTER);
    // this.selectedMatter = Object.assign({}, selectedMatter);

    this.calendarItem.ServiceID = selectedMatter.ServiceID;
    this.calendarItem.MatterStatus = selectedMatter.Status;
    this.calendarItem.ServiceProviderID = selectedMatter.ServiceProviderID;
    this.calendarItem.Subject = selectedMatter.Description;
    this.selectedMatter = Object.assign({}, selectedMatter);
    this.onRateTypeChange();
    this.updateRate();
    this.takeSnapShot();
    this.calendarItemChange.emit(this.calendarItem);
  } // end onMatterSelect()

  /**
   * Called when the selectedSubCategory changes.
   * @param {SubCategory} subCategory The new selectedSubCategory object.
   */
  onSubCategoryChange(subCategory: SubCategory) {
    this.selectedSubCategory = Object.assign({}, subCategory);

    this.calendarItem.RateType = this.selectedSubCategory.RateType;
    this.calendarItem.SubCategoryID = this.selectedSubCategory.SubCategoryId;
    this.calendarItem.FurtherDescription = this.selectedSubCategory.SubCategory;
    this.onRateTypeChange();
    this.updateRate();
    this.takeSnapShot();
    this.calendarItemChange.emit(this.calendarItem);
  } // end onSubCategoryChange()

  /**
   * Called when the selectedSubCategory input field recieves input.
   * @param event The dispatched input event.
   */
  onSubCategoryInput(event, calendarItem: FromCalendarItem) {
    if (this.selectedSubCategory) {
      if (event.inputType.localeCompare('deleteContentBackward') === 0) {
        if (this.selectedSubCategory.SubCategoryId) {
          this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
          this.calendarItem.SubCategoryID = 0;
          calendarItem.FurtherDescription = event.target.value;
          this.calendarItem.FurtherDescription = event.target.value;

          // this.takeSnapShot();
        } else {
          calendarItem.FurtherDescription = event.target.value;
          this.calendarItem.FurtherDescription = event.target.value;
        }
      } else {
        const description = this.selectedSubCategory.SubCategory;
        this.selectedSubCategory.SubCategory = description;
        calendarItem.FurtherDescription = event.target.value;
        this.calendarItem.FurtherDescription = event.target.value;
        // this.takeSnapShot()
      }
    } else {
      // If not backspace
      // if (event.inputType.localeCompare('deleteContentBackward') !== 0) {
      calendarItem.FurtherDescription = event.target.value;
      this.calendarItem.FurtherDescription = event.target.value;
      // }
    }
    this.takeSnapShot();
    this.calendarItemChange.emit(this.calendarItem);
  } // end onSubCategoryInput()

  /**
   * Called when the RateType field changes.
   */
  onRateTypeChange() {
    this.quantityIsReadOnly = false;
    switch (this.calendarItem.RateType) {
      case 'Hourly':
        // this.calendarItem.Quantity = 1;
        if (this.selectedMatter) {
          this.calendarItem.Rate = this.selectedMatter.HourlyRate;
          this.updateQunatity();
        }
        break;

      case 'Daily':
        this.calendarItem.Quantity = 1;
        this.updateEndTime('00:00');
        this.updateStartTime('00:00');
        if (this.selectedMatter) {
          this.calendarItem.Rate = this.selectedMatter.DailyRate;
        }
        break;

      case 'Once Off':
        this.updateEndTime('00:00');
        this.updateStartTime('00:00');
        this.calendarItem.Quantity = 1;
        this.quantityIsReadOnly = true;
        this.calendarItem.Rate = 0;
        break;

      case 'Per Kilometer':
        this.updateEndTime('00:00');
        this.updateStartTime('00:00');
        this.calendarItem.Quantity = 1;
        if (this.selectedMatter) {
          this.calendarItem.Rate = this.selectedMatter.PerKilometerRate;
        }
        break;

      case 'Per Page':
        this.updateEndTime('00:00');
        this.updateStartTime('00:00');
        this.calendarItem.Quantity = 1;
        this.calendarItem.Rate = 0;
        break;

      case 'Disbursement':
        this.updateEndTime('00:00');
        this.updateStartTime('00:00');
        this.calendarItem.Quantity = 1;
        this.quantityIsReadOnly = true;
        this.calendarItem.Rate = 0;
        break;

      case 'Non Billable':
        this.updateEndTime('00:00');
        this.updateStartTime('00:00');
        this.calendarItem.Quantity = 1;
        this.quantityIsReadOnly = false;
        this.calendarItem.Rate = 0;
        break;
    }
    this.updateRate();
    this.calendarItemChange.emit(this.calendarItem);
  } // end onRateTypeChange()

  /**
   * Called when the Rate field recieves focus.
   * @param event The dispatched focus event.
   */
  onRateFocus(event) {
    // TODO: Finish implementing onRateFocus
    this.util.format(event.target.value);
  } // end onRateFocus()

  onDiscountFocus(event) {
    // TODO: Finish implementing onRateFocus
    this.util.format(event.target.value);
  }

  /**
   * Called when the Rate value changes.
   * @param event The dispatched change event. *OPTIONAL*
   */
  onRateChange(event = null) {
    // TODO: Finish implementing onRateChange
    if (event) {
      this.util.format(event.target.value);
    }
    this.updateFeeTotal();
  } // end onRateChange()

  /**
   * Called when the Quantity is updated.
   */
  onUpdateQuantity() {
    this.updateFeeTotal();
  } // end onUpdateQuantity()

  /**
   * Called when the Start time is updated.
   * @param event The dispatched input event.
   */
  onUpdateStartTime(event) {
    this.updateStartTime(event.target.value);
  } // end onUpdateStartTime()

  OpenStartTime() {
    const amazingStartTime = this.atp.open({
      time: this.startTime,
      arrowStyle: {
        background: '#dbbb2d',
        color: 'white'
      }
    });
    amazingStartTime.afterClose().subscribe(time => {
      this.updateStartTime(time);
    });
  }

  /**
   * Called when the End time is updated.
   * @param event The dispatched input event.
   */
  onUpdateEndTime(event) {
    this.updateEndTime(event.target.value);
  } // end onUpdateEndTime()


  OpenEndTime() {
    const amazingEndTime = this.atp.open({
      time: this.endTime,
      arrowStyle: {
        background: '#dbbb2d',
        color: 'white'
      }
    });
    amazingEndTime.afterClose().subscribe(time => {

      this.updateEndTime(time);
    });
  }

  /**
   * Updates the start time to the given time.
   * @param {string} newStartTime The new start time string.
   */
  updateStartTime(newStartTime: string) {

    let d = moment(this.calendarItem.FromDate);
    const t = moment(newStartTime, 'HH:mm');
    d = d.hour(t.get('hour'));
    d = d.minute(t.get('minutes'));
    this.calendarItem.FromDate = d.format().replace(/\+[\d]+:[\d]+/, '');
    this.calendarItem.Date = this.calendarItem.FromDate;

    this.takeSnapShot();
    this.startTimeChange.emit(newStartTime);
    if (!this.hasStartTimeAfterEndTimeError) {
      this.updateQunatity();
    }
  } // end updateStartTime()

  /**
   * Updates the start time date to the given date.
   * @param {string} newDate The new date string.
   */
  updateStartTimeDate(newDate: string) {
    let d = moment(this.calendarItem.FromDate);
    const t = moment(newDate, 'YYYY-MM-DD');
    d = d.year(t.get('year'));
    d = d.month(t.get('month'));
    d = d.date(t.get('date'));
    this.calendarItem.FromDate = d.format().replace(/\+[\d]+:[\d]+/, '');
    this.calendarItem.Date = this.calendarItem.FromDate;
    this.takeSnapShot();
    this.startTimeChange.emit(d.format('HH:mm'));
    if (!this.hasStartTimeAfterEndTimeError) {
      this.updateQunatity();
    }
  } // end updateStartTimeDate()

  /**
   * Updates the end time to the given time.
   * @param {string} newStartTime The new end time string.
   */
  updateEndTime(newEndTime: string) {
    let d = moment(this.calendarItem.ToDate);
    const t = moment(newEndTime, 'HH:mm');
    d = d.hour(t.get('hour'));
    d = d.minute(t.get('minutes'));
    this.calendarItem.ToDate = d.format().replace(/\+[\d]+:[\d]+/, '');

    this.takeSnapShot();
    this.endTimeChange.emit(newEndTime);
    if (!this.hasEndTimeBeforeStartTimeError) {
      this.updateQunatity();
    }
  } // end updateEndTime()

  /**
   * Updates the end time date to the given date.
   * @param {string} newDate The new date string.
   */
  updateEndTimeDate(newDate: string) {
    let d = moment(this.calendarItem.ToDate);
    const t = moment(newDate, 'YYYY-MM-DD');
    d = d.year(t.get('year'));
    d = d.month(t.get('month'));
    d = d.date(t.get('date'));
    this.calendarItem.ToDate = d.format().replace(/\+[\d]+:[\d]+/, '');

    this.takeSnapShot();
    this.endTimeChange.emit(d.format('HH:mm'));
    if (!this.hasEndTimeBeforeStartTimeError) {
      this.updateQunatity();
    }
  } // end updateEndTimeDate()

  /**
   * Updates the rate based on the current RateType value.
   */
  updateRate() {
    if (this.selectedMatter) {
      switch (this.calendarItem.RateType) {
        case 'Hourly':
          this.calendarItem.Rate = this.selectedMatter.HourlyRate;
          break;

        case 'Daily':
          this.calendarItem.Quantity = 1;
          this.calendarItem.Rate = this.selectedMatter.DailyRate;
          this.updateEndTime('00:00');
          this.updateStartTime('00:00');
          break;

        case 'Once Off':
          this.calendarItem.Quantity = 1;
          this.updateEndTime('00:00');
          this.updateStartTime('00:00');
          break;

        case 'Per Kilometer':
          this.calendarItem.Quantity = 1;
          this.calendarItem.Rate = this.selectedMatter.PerKilometerRate;
          this.updateEndTime('00:00');
          this.updateStartTime('00:00');
          break;

        case 'Per Page':
          this.calendarItem.Quantity = 1;
          this.updateEndTime('00:00');
          this.updateStartTime('00:00');
          break;

        case 'Disbursement':
          this.calendarItem.Quantity = 1;
          this.updateEndTime('00:00');
          this.updateStartTime('00:00');
          break;

        case 'Non Billable':
          this.updateEndTime('00:00');
          this.updateStartTime('00:00');
          this.calendarItem.Rate = 0;
          break;
      }
    }

    this.takeSnapShot();
    this.onRateChange();
  } // end updateRate()

  /**
   * Updates the Quantity based on the current RateType.
   */
  updateQunatity() {
    if (this.calendarItem.RateType.localeCompare('Hourly') === 0) {
      if (this.startTime && this.startTime.length > '00:00'.length) {
        this.startTime = this.startTime.substr(0, '00:00'.length);
      }
      if (this.endTime && this.endTime.length > '00:00'.length) {
        this.endTime = this.endTime.substr(0, '00:00'.length);
      }
      this.calendarItem.FromDate = /[\d]+:[\d]+:00$/.test(this.calendarItem.FromDate)
        ? this.calendarItem.FromDate.replace(/[\d]+:[\d]+:00$/, this.startTime + ':00')
        : this.calendarItem.FromDate.replace(/[\d]+:[\d]+:00$/, this.startTime);

      this.calendarItem.ToDate = /[\d]+:[\d]+:00$/.test(this.calendarItem.ToDate)
        ? this.calendarItem.ToDate.replace(/[\d]+:[\d]+:00$/, this.endTime + ':00')
        : this.calendarItem.ToDate.replace(/[\d]+:[\d]+:00$/, this.endTime);
      if (this.endTime !== '00:00') {
        this.calendarItem.Quantity =
          moment(this.calendarItem.ToDate).diff(moment(this.calendarItem.FromDate), 'hours', true);
        this.calendarItemChange.emit(this.calendarItem);
      }
    }
    this.takeSnapShot();
    this.updateFeeTotal();
  } // end updateQunatity()

  /**
   * Updates the Fee total based on the current Fields.
   */
  updateFeeTotal() {
    this.calendarItem.Total = (this.calendarItem.Rate * this.calendarItem.Quantity) - (this.calendarItem.Discount ? this.calendarItem.Discount : 0);
    this.calendarItemChange.emit(this.calendarItem);
    this.takeSnapShot();
  } // end updateFeeTotal()

  /**
   * Reveals the tooltip of given name.
   * @param {string} toolTip The name of the tooltip.
   */
  showToolTip(toolTip: string) {
    switch (toolTip) {
      case 'quantity':
        this.showQunatityToolTip = true;
        break;
    }
  } // end showToolTip()

  /**
   * Dismisses the tooltip of given name.
   * @param {string} toolTip The name of the tooltip.
   */
  hideToolTip(toolTip: string) {
    switch (toolTip) {
      case 'quantity':
        this.showQunatityToolTip = false;
        break;
    }
  } // end showToolTip()

  /**
   * Toggles the visibility of the tooltip of given name.
   * @param {string} toolTip The name of the tooltip.
   */
  toggleToolTip(toolTip: string) {
    switch (toolTip) {
      case 'quantity':
        this.showQunatityToolTip = !this.showQunatityToolTip;
        break;
    }
  } // end toggleQuantityHelp()

  initCalendarItem() {
    this.initialCalendarItem = this.util.objectCopy(this.calendarItem);
  } // end initCalendarItem()

  private takeSnapShot() {
    if (this._state) {
      this._history.push(this._state);
    }

    const state = {
      isVisible: this.isVisible,
      calendarItem: this.calendarItem,
      defaultMatter: this.defaultMatter,
      defaultSubCategory: this.defaultSubCategory,
      matters: this.matters,
      subCategories: this.subCategories,
      startTime: this.startTime,
      endTime: this.endTime,
      selectedMatter: this.selectedMatter,
      selectedSubCategory: this.selectedSubCategory
    };

    this._state = Object.assign(this._state, state);
  } // end takeSnapShot()

  private clearHistory() {
    this._history = [];
  } // end clearHistory()

  AddNewMatter() {
    this.EventDetails = this.calendarItem.Date;
    this.isAddNewMatter = true;
  }
  closeNewMatterModal(event: any) {
    if (event) {
      this.onMatterSelect(event);
      if (!this.matters.some(matter => matter.ServiceID === event.ServiceID)) {
        this.matters = [...this.matters, event];
      }
    }    
    this.isAddNewMatter = false;
  }

  onHideThis() {
    this.onHideOutlookPrompt();
    this.onHideThisEvent.emit(this.calendarItem);
    this.dismiss();
  }

  onHideThisClick() {
    this.showHideClendarPrompt = true;
  }

  onHideOutlookPrompt() {
    this.showHideClendarPrompt = false;
  }
} // end AddFeeCalendarModalComponent{}