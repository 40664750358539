import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PATH_SEARCH } from '../../../services/appdata/app-config.service';
import { SnackType, Snack, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { InvoiceDocumentService } from '../../../services/invoice-document/invoice-document.service';
import { ApiService } from '../../../services/api.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { InvoiceDetailsService, InvoiceDetails } from '../../../services/invoice-details/invoice-details.service';
import { ActivatedRoute } from '@angular/router';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';

@Component({
  selector: 'app-searched-invoice-preview',
  templateUrl: './searched-invoice-preview.component.html',
  styleUrls: ['./searched-invoice-preview.component.scss']
})
export class SearchedInvoicePreviewComponent implements OnInit {

  crumbs: ICrumb[];
  invoice: InvoiceDetails;
  invoiceId: number;
  plaintiff: string;
  defendant: string;

  _fileName;
  @ViewChild('pdfViewer') public pdfViewer;

  constructor(
    private route: ActivatedRoute,
    private invoiceDetailService: InvoiceDetailsService,
    private loadingService: LoadingService,
    private snackbarService: SnackbarsService,
    private api: ApiService,
    private invoiceDocumentService: InvoiceDocumentService
  ) { } // end constructor()

  ngOnInit() {
    this.invoiceId = +this.route.snapshot.paramMap.get('id');

    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Loading invoice...',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, 5000).show();

    const url = this.api.endpoints.invoiceDocument + '?invoiceID=' + this.invoiceId;
    this.invoiceDocumentService.getInvoiceDocument(url)
      .subscribe(invoiceDoc => {
        // this._fileName = invoiceDoc;
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = invoiceDoc;
        this.pdfViewer.refresh();
      });

    this.invoiceDetailService.getInvoiceDetails(this.invoiceId)
      .subscribe({ next: (invoice_details) => {
        this.invoice = invoice_details;

        const desc = this.invoice.InvoiceParties.split(' // ');
        if (desc.length > 0) {
          this.plaintiff = desc[0];
        }
        if (desc.length > 1) {
          this.defendant = desc[1];
        }
      },
        error: (error) => {
          // On error
          snack.label = 'An error loading invoice,';
          snack.type = SnackType.ERROR;
          this.snackbarService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          snack.label = 'Invoice loaded.';
          snack.type = SnackType.SUCCESS;
          this.snackbarService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        }
      });
    this.crumbs = [
      {
        label: 'Search',
        link: PATH_SEARCH
      },
      {
        label: 'Invoice',
    }];
  } // end ngOnInit()

  @Input()
  set fileName(fileName: string) {
    this._fileName = fileName;
  }
  get fileName() {
    return this._fileName;
  }
} // end SearchedInvoicePreviewComponent{}
