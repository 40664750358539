import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  SimpleChanges,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener
} from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {
  Fee,
  RATE_TYPE_PER_KILOMETER,
  RATE_TYPE_HOURLY,
  RATE_TYPE_ONCE_OFF,
  RATE_TYPE_DAILY,
  RATE_TYPE_DISBURSEMENT,
  RATE_TYPE_NON_BILLABLE,
  RATE_TYPE_PER_PAGE,
  DEFAULT_RATE_TYPE,
  DEFAULT_RATE,
  DEFAULT_QUANTITY,
  DEFAULT_FEE,
  FeeItemsService,
  DocumentTypes,
  DEFAULT_DOCOMENT_TYPE,
} from '../../../../services/fee-items/fee-items.service';
import { UtilitiesService } from '../../../../services/utilities.service';
import { UserProfile, UserProfileService } from '../../../../services/user-profile/user-profile.service';
import { Matter, BriefDocuments, MattersService, DEFAULT_BRIEF_DOCUMENT, DEFAULT_MATTER } from '../../../../services/matters/matters.service';
import {
  SnackbarsService,
  Snack
} from '../../../../services/messaging/snackbars/snackbars.service';
import * as moment from 'moment';
import {
  SubCategory,
  DEFAULT_SUB_CATEGORY
} from '../../../../services/sub-categories/sub-categories.service';
import { UsercurrencyPipe } from '../../../../pipe/usercurrency.pipe';
import { UsercurrencyDirective } from '../../../../directive/usercurrency.directive';
import { LoadingService } from '../../../../services/messaging/loading/loading.service';
import { ApiService } from '../../../../services/api.service';
import {
  ActivityLogs,
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogsService} from '../../../../services/activity-logs/activity-logs.service';
import { QuotationFeeItem } from '../../../../services/quotation/quotation.service';
import { BsDatepickerConfig, BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { LawFirm } from '../../../../services/lists/lists.service';

interface FileDocHandle {
  data: File;
  documentType: DocumentTypes;
}

@Component({
  selector: 'app-matter-fees-table',
  templateUrl: './matter-fees-table.component.html',
  styleUrls: ['./matter-fees-table.component.scss']
})
export class MatterFeesTableComponent implements OnInit, OnChanges {

  templateTypes: any;
  templateMudauTypes: any;
  selectedTemplate = '';
  onArbitrationModalOpen: boolean;
  arbitrationMatter: Matter = Object.assign({}, DEFAULT_MATTER);

  @Input() quotationFees: QuotationFeeItem[];
  @Input() briefDocuments: BriefDocuments[];
  @Input() id: number;
  @Input() lawFirms: LawFirm[];
  @Input() workingFees: Fee[];
  @Input() fees: Fee[];
  @Input() subCategories: SubCategory[];
  @Input() userProfile: UserProfile;
  @Input() workingMatter: Matter;
  @Input() documentTypes: DocumentTypes[];
  @Output() workingFeesChange: EventEmitter<Fee[]> = new EventEmitter<Fee[]>();
  @Output() feeDelete: EventEmitter<Fee> = new EventEmitter<Fee>();
  @Output() workingMatterChange: EventEmitter<Matter> = new EventEmitter<Matter>();
  @Output() onArbitrationSplitUpdate: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('rateinput') RateInput: ElementRef;
  @ViewChild('inputDiscount') DiscountInput: ElementRef;
  @ViewChild('inputPaymentReceived') inputPaymentReceived: ElementRef;
  @ViewChild('inputAttachmentFile') inputAttachmentFile: ElementRef;
  @ViewChild('inputUploadFile') inputUploadFile: ElementRef;
  @ViewChild('dp', { static: false }) datepicker?: BsDaterangepickerDirective;

  activeTab = 'current-fees';
  rateTypes = [
    RATE_TYPE_PER_KILOMETER,
    RATE_TYPE_HOURLY,
    RATE_TYPE_ONCE_OFF,
    RATE_TYPE_DAILY,
    RATE_TYPE_DISBURSEMENT,
    RATE_TYPE_NON_BILLABLE,
    RATE_TYPE_PER_PAGE
  ];

  currentFeesTotal = 0.00;
  currentFeesDiscount = 0;

  useDiscountPercentage: boolean;
  currentFeesGrandTotal = 0.00;

  invoiceNumber = 'AUX000000';
  invoiceAmount = 0.00;
  totalPayments = 0.00;
  totalOutstanding = 0.00;
  showDeleteFeeModal: boolean;
  quantityReadOnly: boolean;
  showAttorneyErrorPrompt: boolean;
  downloadLink = this.api.apiURL + '/MatterDocument?briefId=';

  selectedFee: Fee;
  selectedSubCategory: SubCategory;
  // workingFees: Fee[];

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)',
    PRACTICE_MANAGER: 'Practice Manager'
  };

  showCalendarItemEditPrompt: boolean;
  editCalendarFeeItem: Fee

  private _currentFeesTotal = 0.00;
  private previousFees: Fee[];
  private currency: CurrencyPipe = new CurrencyPipe('en-US');
  private feesReady: boolean;
  private matterReady: boolean;
  private hasError = false;
  private quantityError = false;

  // File upload
  showUploadDocumentErrorPrompt: boolean;
  selectedDocumentType = 0;
  selectedFile: any;
  showMatterDocumentDialog: boolean;
  documentTitle: string;
  selectedBriefId: number;
  selectedServiceId: number;
  dropzoneActive = false;
  showUploadModalDialog: boolean;
  documentName = '';
  attachmentFile: File;
  fileList: File[] = [];
  fileListHandle: FileDocHandle[] = [];
  attachment: string;
  showDeleteDocumentPrompt: boolean;
  selectedDocumentToDelete: string;
  showdocumentUploadErrorPrompt: boolean;
  selectedDocomentType: DocumentTypes = Object.assign({}, DEFAULT_DOCOMENT_TYPE);
  toDeleteDocumentTypes: BriefDocuments = Object.assign({}, DEFAULT_BRIEF_DOCUMENT);
  toEditDocumentTypes: BriefDocuments = Object.assign({}, DEFAULT_BRIEF_DOCUMENT);
  invoicelineCounter = 0;

  // edit document type for document
  showEditUploadModalDialog: boolean;
  ShowDiscountErrorMessage: boolean;
  onEXCLVATClick: boolean;

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  // Is Internal Attorney
  get isAttorney(): boolean {
    let attorneyProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      attorneyProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
    } else {
      attorneyProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
    }
    return attorneyProfile;
  } // end isAttorney()

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    let lawfirmProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      lawfirmProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
    } else {
      lawfirmProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
    }
    return lawfirmProfile;
  } // end isLawfirm()

  get totalHoursWorked(): number {
    let total = 0;
    this.workingFees.forEach(data => {
      if (data.RateType === 'Hourly') {
        total += data.Quantity;
      }
    });
    return total;
  } // end totalHoursWorked()

  get quotationTotal(): number {
    let total = 0;
    this.quotationFees.forEach(data => {
      total += data.Total;
    });
    return total;
  } // end quotationTotal()

   hasRateError(fee: Fee): boolean {
    if (fee.Rate.toString().startsWith('-')) {
      this.hasError = true;
      return true;
    }
    this.hasError = false;
    return false;
  } // end hasRateError()

  get hasAnErrorOnRate(): boolean {
    return Boolean(this.hasError);
  }

  onFeeChangesCheck(fee: Fee, workingFee: Fee): Boolean {
    return !this.util.objectIsSame(fee, workingFee);
  } // end onFeeChangesCheck()

  hasQuantityError(fee: Fee): boolean {
    if (fee.Quantity.toString().startsWith('-')) {
      this.quantityError = true;
      return true;
    }
    this.quantityError = false;
    return false;
  }

  get hasAnErrorOnQuantity(): boolean {
    return Boolean(this.quantityError);
  }
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);}

  get hasVAT(): boolean{
    return Boolean(this.userProfileService.selectedUserProfile.personalDetails.vatNumber);
  }

  get hasFeesOnThisMatter(): boolean {
    let hasFees = false;

    if (this.workingFees.length) {
      this.workingFees.forEach(fee => {
        if (fee.FeeItemID) {
          hasFees = true;
        }
      });
    }

    return hasFees;
  } // end hasFeesOnThisMatter()

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.fees, this.workingFees);
  } // end isDirty()

  get isGeneratable(): boolean {
    return this.hasFeesOnThisMatter &&
      !Boolean(this.isDirty) &&
      !Boolean(this.fees.find(s => s.Rate.toString().startsWith('-'))) &&
      !Boolean(this.fees.find(s => s.FurtherDescription.length === 0)) &&
      !Boolean(this.workingMatter.IsArbitrationSplit === true);
  } // end isGeneratable()

  constructor(
    private api: ApiService,
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private matterService: MattersService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    public userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private currencyDirective: UsercurrencyDirective,
    ) {
      this.feesReady = false;
      this.matterReady = false;
      this.showDeleteFeeModal = false;
      this.quantityReadOnly = true;
      this.useDiscountPercentage = false;
    } // end constructor()

    parseInvoiceLineDate(serviceDate: string): Date {
      if (serviceDate) {
        return new Date(serviceDate);
      } else {
        return null;
      }
    } // end parseInvoiceLineDate()

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  } // end dropzoneState()

  onHideUploadModalDialog() {
    this.showUploadModalDialog = false;
  } // end onHideUploadModalDialog()

  get userServiceProviderId(): number {
    let user_id = 0;
    if (this.userProfileService.userProfile.isImpersonator) {
      user_id = this.userProfileService.selectedUserProfile.serviceProviderID;
    } else {
      user_id = this.userProfileService.userProfile.serviceProviderID;
    }
    return user_id;
  } // end userServiceProviderId()

  toggleExcludeVAT(){
    if(this.workingMatter.EXCLVAT === false){
    this.onEXCLVATClick = true}
    else{
      this.workingMatter.EXCLVAT = !this.workingMatter.EXCLVAT
    }
  }
  onEXCLVATClickContinue(){
    this.workingMatter.EXCLVAT = !this.workingMatter.EXCLVAT
    this.onEXCLVATClick = false
  }

  onEXCLVATClickCancel(){
    this.onEXCLVATClick = false
  }

 ngOnInit() {
   this.templateTypes = [
     { id: 'Template one', name: 'Full invoice' },
     { id: 'Template two', name: 'Merits settled, Quantum pp' },
     { id: 'Template three', name: 'POC & pre-trial' },
     { id: 'Template four', name: 'Pre hearing pre-trial' },
   ];
   this.templateMudauTypes = [
     { id: 'Pre-populated fee template', name: 'Pre-populated fee template' }
   ];
  } // end ngOnInit()

  addArbitration() {
    this.arbitrationMatter = this.util.objectCopy(this.workingMatter);
    this.onArbitrationModalOpen = true;
  } // end addArbitration()

  onArbitrationModalClose() {
    this.onArbitrationModalOpen = false;
  } // end onArbitrationModalClose()

  onArbitrationSaved() {
    this.onArbitrationSplitUpdate.emit();
    this.onArbitrationModalOpen = false;
  } // end onArbitrationSaved()

  selectedTemplateChange() {
    this.workingFees = [];
    if (this.selectedTemplate) {
      //#region Standard Templates for aux28678
      if (this.selectedTemplate === 'Template one') {

        //#region Fee Template One
        const fee = Object.assign({}, DEFAULT_FEE);
        fee.Date = moment().toJSON();
        fee.FurtherDescription = 'Peruse brief, instructions, pleadings, notices documentation and consider \n \n' +
          'Brief & Instruction:; Pre-trial minutes:;  ' +
          'Pleadings:; Notices and Discovery Affidavits 95:; Correspondence:; \n \n ' +
          'Documentation: Claim documents:' + '\n' +
          '\tMedico legal reports: \n\tRAF4 forms: \n\tJoint minutes:\n\t' +
          'Actuary:\n \n' +
          'Prepare pre-trial minute in compliance with Practice Directive 2 of 2019, ' +
          'and conduct pre-trial in preparation for Judicial Management Meeting on \n\n' +
          'Total pages:',
        fee.Rate = 2100;
        fee.Quantity = 8;
        fee.Total = fee.Rate * fee.Quantity;
        fee.MatterStatus = this.workingMatter.Status;
        fee.CanEdit = true;
        this.workingFees.push(fee);
         // #endregion Fee Template One

         //#region Fee Template Two
        const fee1 = Object.assign({}, DEFAULT_FEE);
        fee1.Date = moment().toJSON();
        fee1.FurtherDescription = 'Attend Judicial management meeting at Court before The Honourable Justice',
        fee1.Rate = 2100;
        fee1.Quantity = 1;
        fee1.Total = fee1.Rate * fee1.Quantity;
        fee1.MatterStatus = this.workingMatter.Status;
        fee1.CanEdit = true;
        this.workingFees.push(fee1);
        //#endregion Fee Template Two

        //#region Fee Template Three
        const fee2 = Object.assign({}, DEFAULT_FEE);
        fee2.Date = moment().toJSON();
        fee2.FurtherDescription = 'Peruse further documentation received in preparation for trial: \n\n' +
        'Documentation: Correspondence:\n\tRAF4 forms:\n\tJoint minutes:' +
        '\n\t(Industrial Psychologists and Actuaries) \n\tActuary:\n\n' +
        'Total pages:',
        fee2.Rate = 2100;
        fee2.Quantity = 1;
        fee2.Total = fee2.Rate * fee2.Quantity;
        fee2.MatterStatus = this.workingMatter.Status;
        fee2.CanEdit = true;
        this.workingFees.push(fee2);
        //#endregion Fee Template Three

        //#region Fee Template Four
        const fee3 = Object.assign({}, DEFAULT_FEE);
        fee3.Date = moment().toJSON();
        fee3.FurtherDescription = 'Prepare the draft order and do research on case law (for full settlement and ' +
        'for purposes of scale of costs and duties of experts to court) and draft Index, Joint memorandum on settlement, ' +
        'and attachments, – ordered by The Honourable Justice\n\n' +
        'Total pages:',
        fee3.Rate = 2100;
        fee3.Quantity = 3;
        fee3.Total = fee3.Rate * fee3.Quantity;
        fee3.MatterStatus = this.workingMatter.Status;
        fee3.CanEdit = true;
        this.workingFees.push(fee3);
        //#endregion Fee Template Four

        //#region Fee Template Five
        const fee4 = Object.assign({}, DEFAULT_FEE);
        fee4.Date = moment().toJSON();
        fee4.FurtherDescription = 'Professional Fee: Attend Court',
        fee4.Rate = 2100;
        fee4.Quantity = 10;
        fee4.Total = fee4.Rate * fee4.Quantity;
        fee4.MatterStatus = this.workingMatter.Status;
        fee4.CanEdit = true;
        this.workingFees.push(fee4);
        //#endregion Fee Template Five

        this.workingFeesChange.emit(this.workingFees);
        this.calculateCurrentFeesTotal();
      } else if (this.selectedTemplate === 'Template two') {
        // Data for template two
        // Line item one
        const fee = Object.assign({}, DEFAULT_FEE);
        fee.Date = moment().toJSON();
        fee.FurtherDescription = 'Peruse brief, instructions, further pleadings, notices documentation and consider. ' +
        'Brief, Instructions & documentation:; Pre-trial minutes:; \n' +
        'Pleadings:, Notices:, Correspondence:\n' +
        'Documents: Claim documents:\n\tMedico-legal reports:\n\tRAF4 reports:\n\t' +
        'Narrative Test:\n\n' +
        'Draft a memorandum (single spacing)\n\n' +
        'Draft and prepare draft order and joint memorandum on settlement\n\n' +
        'Total pages:',
        fee.Rate = 2100;
        fee.Quantity = 6;
        fee.Total = fee.Rate * fee.Quantity;
        fee.MatterStatus = this.workingMatter.Status;
        fee.CanEdit = true;
        this.workingFees.push(fee);

        // Line item two
        const fee1 = Object.assign({}, DEFAULT_FEE);
        fee1.Date = moment().toJSON();
        fee1.FurtherDescription = 'PROFESSIONAL FEE: Attend Court make draft order an order of court. \n\n' +
        'Merits: 100 % and Sec 17(4)(a) Undertaking.Generals postponed sine die(rejected to be referred to HPCSA)',
        fee1.Rate = 21000;
        fee1.Quantity = 1;
        fee1.RateType = 'Once Off',
        fee1.Total = fee1.Rate * fee1.Quantity;
        fee1.MatterStatus = this.workingMatter.Status;
        fee1.CanEdit = true;
        this.workingFees.push(fee1);

        this.workingFeesChange.emit(this.workingFees);
        this.calculateCurrentFeesTotal();
      } else if (this.selectedTemplate === 'Template three') {
        // Invoice Template Three
        // Line item one
        const fee = Object.assign({}, DEFAULT_FEE);
        fee.Date = moment().toJSON();
        fee.FurtherDescription = 'Draft and finalize particulars of claim.',
        fee.Rate = 1000;
        fee.Quantity = 1;
        fee.RateType = 'Once Off',
        fee.Total = fee.Rate * fee.Quantity;
        fee.MatterStatus = this.workingMatter.Status;
        fee.CanEdit = true;
        this.workingFees.push(fee);

        // Line item two
        const fee1 = Object.assign({}, DEFAULT_FEE);
        fee1.Date = moment().toJSON();
        fee1.FurtherDescription = 'Draft and finalize pre-trial minute and attend pre-trail conference.',
        fee1.Rate = 1000;
        fee1.Quantity = 1;
        fee1.RateType = 'Once Off',
        fee1.Total = fee1.Rate * fee1.Quantity;
        fee1.MatterStatus = this.workingMatter.Status;
        fee1.CanEdit = true;
        this.workingFees.push(fee1);

        // Line item three
        const fee2 = Object.assign({}, DEFAULT_FEE);
        fee2.Date = moment().toJSON();
        fee2.FurtherDescription = 'Draft and finalize pre-hearing pre-trail minute.',
        fee2.Rate = 1000;
        fee2.Quantity = 1;
        fee2.RateType = 'Once Off',
        fee2.Total = fee2.Rate * fee2.Quantity;
        fee2.MatterStatus = this.workingMatter.Status;
        fee2.CanEdit = true;
        this.workingFees.push(fee2);

        this.workingFeesChange.emit(this.workingFees);
        this.calculateCurrentFeesTotal();
      } else if (this.selectedTemplate === 'Template four') {
        // Invoice Template Four
        // Line item one
        const fee = Object.assign({}, DEFAULT_FEE);
        fee.Date = moment().toJSON();
        fee.FurtherDescription = 'Peruse brief, instructions, documentation, pleadings and notices and consider. ' +
        'Draft the pre hearing pre-trial minute.',
        fee.Rate = 2000;
        fee.Quantity = 1;
        fee.Total = fee.Rate * fee.Quantity;
        fee.MatterStatus = this.workingMatter.Status;
        fee.CanEdit = true;
        this.workingFees.push(fee);

        // Line item two
        const fee1 = Object.assign({}, DEFAULT_FEE);
        fee1.Date = moment().toJSON();
        fee1.FurtherDescription = 'Attend pre-trial conference in front of ',
        fee1.Rate = 1000;
        fee1.Quantity = 1;
        fee1.RateType = 'Once Off',
        fee1.Total = fee1.Rate * fee1.Quantity;
        fee1.MatterStatus = this.workingMatter.Status;
        fee1.CanEdit = true;
        this.workingFees.push(fee1);

        this.workingFeesChange.emit(this.workingFees);
        this.calculateCurrentFeesTotal();
      }
      //#endregion Standard Templates
    }
  } // end selectedTemplateChange()

  selectedMudauTemplateChange() {
    this.workingFees = [];
    if (this.selectedTemplate) {
      //#region Standard Templates for Mudau aux29213
      if (this.selectedTemplate === 'Pre-populated fee template') {

        //#region Pre-populated fee template item one
        const fee = Object.assign({}, DEFAULT_FEE);
        fee.Date = moment().toJSON();
        fee.FurtherDescription = 'Receipt and perusal @ ______ per hour: \n \n' +
          ' + Trial Bundle A (Index Pleadings) for ___ hours.\n' +
          ' + Trial Bundle B (Index Notices) for ___ hours.\n' +
          ' + Trial Bundle C (Index Expert reports) for ___ hour. \n' +
          ' + Trial Bundle D (Index to Pre-trial conferences) for ___ hour.';
        fee.Rate = 1600;
        fee.Quantity = 10;
        fee.Total = fee.Rate * fee.Quantity;
        fee.MatterStatus = this.workingMatter.Status;
        fee.CanEdit = true;
        this.workingFees.push(fee);
         // #endregion Pre-populated fee template item one

        //#region Pre-populated fee template item two
        const feeTwo = Object.assign({}, DEFAULT_FEE);
        feeTwo.Date = moment().toJSON();
        feeTwo.FurtherDescription = 'Preparation for trial: \n \n' +
          ' + Research for relavant legal principles and case law consideration for ___ hours @ _____ per hour.\n' +
          ' + Trial preparing on evidence gathered and legal principles collected for ___ hours @ _____ per hour.';
        feeTwo.Rate = 1600;
        feeTwo.Quantity = 10;
        feeTwo.Total = feeTwo.Rate * feeTwo.Quantity;
        feeTwo.MatterStatus = this.workingMatter.Status;
        feeTwo.CanEdit = true;
        this.workingFees.push(feeTwo);
         // #endregion Pre-populated fee template item two

        //#region Pre-populated fee template item three
        const feeThree = Object.assign({}, DEFAULT_FEE);
        feeThree.Date = moment().toJSON();
        feeThree.FurtherDescription = 'Drafting Practice Note on the __________ for an hour @ _____ per hour \n \n' +
          'Consultation:\n' +
          'Witness for ___ hours @ _____ per hour\n' +
          'Actuary for an hour on the ___________\n' +
          'Industrial psychologist for an hour on the __________\n' +
          'Education psychologist for an hour on the __________';
        feeThree.Rate = 1600;
        feeThree.Quantity = 4;
        feeThree.Total = feeThree.Rate * feeThree.Quantity;
        feeThree.MatterStatus = this.workingMatter.Status;
        feeThree.CanEdit = true;
        this.workingFees.push(feeThree);
         // #endregion Pre-populated fee template item three

        //#region Pre-populated fee template item four
        const feeFour = Object.assign({}, DEFAULT_FEE);
        feeFour.Date = moment().toJSON();
        feeFour.FurtherDescription = 'Drafted Heads of argument for __ hours @ _____ per hour';
        feeFour.Rate = 1600;
        feeFour.Quantity = 10;
        feeFour.Total = feeFour.Rate * feeFour.Quantity;
        feeFour.MatterStatus = this.workingMatter.Status;
        feeFour.CanEdit = true;
        this.workingFees.push(feeFour);
         // #endregion Pre-populated fee template item four

        //#region Pre-populated fee template item five
        const feeFive = Object.assign({}, DEFAULT_FEE);
        feeFive.Date = moment().toJSON();
        feeFive.FurtherDescription = 'Attended Court for Day 1 Trial on the __________ @ _____ (Day Fee).';
        feeFive.Rate = 1600;
        feeFive.Quantity = 10;
        feeFive.Total = feeFive.Rate * feeFive.Quantity;
        feeFive.MatterStatus = this.workingMatter.Status;
        feeFive.CanEdit = true;
        this.workingFees.push(feeFive);
         // #endregion Pre-populated fee template item five

        //#region Pre-populated fee template item six
        const feeSix = Object.assign({}, DEFAULT_FEE);
        feeSix.Date = moment().toJSON();
        feeSix.FurtherDescription = 'Attended Court for Day 2 Trial on the __________ @ _____ (Day Fee).';
        feeSix.Rate = 1600;
        feeSix.Quantity = 10;
        feeSix.Total = feeSix.Rate * feeSix.Quantity;
        feeSix.MatterStatus = this.workingMatter.Status;
        feeSix.CanEdit = true;
        this.workingFees.push(feeSix);
         // #endregion Pre-populated fee template item six

        //#region Pre-populated fee template item seven
        const feeSeven = Object.assign({}, DEFAULT_FEE);
        feeSeven.Date = moment().toJSON();
        feeSeven.FurtherDescription = 'Finalising Drafting Heads of argument for ___ hours @ _____ per hour.';
        feeSeven.Rate = 1600;
        feeSeven.Quantity = 2;
        feeSeven.Total = feeSeven.Rate * feeSeven.Quantity;
        feeSeven.MatterStatus = this.workingMatter.Status;
        feeSeven.CanEdit = true;
        this.workingFees.push(feeSeven);
         // #endregion Pre-populated fee template item seven

        //#region Pre-populated fee template item eight
        const feeEight = Object.assign({}, DEFAULT_FEE);
        feeEight.Date = moment().toJSON();
        feeEight.FurtherDescription = 'Attended Court for Day 3 Trial on the __________ @ _____ (Day Fee).';
        feeEight.Rate = 1600;
        feeEight.Quantity = 10;
        feeEight.Total = feeEight.Rate * feeEight.Quantity;
        feeEight.MatterStatus = this.workingMatter.Status;
        feeEight.CanEdit = true;
        this.workingFees.push(feeEight);
         // #endregion Pre-populated fee template item eight


        this.workingFeesChange.emit(this.workingFees);
        this.calculateCurrentFeesTotal();
      }
      //#endregion Standard Templates for Mudau aux29213
    }
  } // end selectedMudauTemplateChange()

  fileAttachmentChange(file) {
    if (this.workingMatter && this.workingMatter.ServiceID) {
      if (this.getExtension(file.target.files[0].name) === 'pdf' || this.getExtension(file.target.files[0].name) === 'docx' ||
      this.getExtension(file.target.files[0].name) === 'msg') {
        this.attachmentFile = file.target.files[0];
        this.attachment = this.attachmentFile.name;
        this.documentName = 'Add document to matter (' + this.workingMatter.Description + ')';
        this.showUploadModalDialog = true;
        } else {
          this.showdocumentUploadErrorPrompt = true;
        }
      } else {
        this.inputAttachmentFile.nativeElement.value = '';
        this.inputUploadFile.nativeElement.valie = '';
        this.showUploadDocumentErrorPrompt = true;
    }
  } // end fileAttachmentChange()

  handleDrop(file: File) {
    if (this.workingMatter && this.workingMatter.ServiceID) {
      if (this.getExtension(file[0].name) === 'pdf' || this.getExtension(file[0].name) === 'docx' ||
           this.getExtension(file[0].name) === 'msg') {
          this.attachmentFile = file[0];
          this.attachment = this.attachmentFile.name;
          this.documentName = 'Add document to matter ( ' +
          this.workingMatter.Description + ')';
          this.showUploadModalDialog = true;
        // }
        } else {
          this.showdocumentUploadErrorPrompt = true;
        }
    } else {
      this.inputAttachmentFile.nativeElement.value = '';
      this.inputUploadFile.nativeElement.valie = '';
      this.showUploadDocumentErrorPrompt = true;
    }
  } // end handleDrop()

  onHidedocumentUploadErrorPrompt() {
    this.inputAttachmentFile.nativeElement.value = '';
    this.inputUploadFile.nativeElement.value = '';
    this.attachment = '';
    this.showdocumentUploadErrorPrompt = false;
  } // end onHidedocumentUploadErrorPrompt()

  onUploadFile(file) {
    this.briefDocuments.push(file);
    this.inputAttachmentFile.nativeElement.value = '';
    this.inputUploadFile.nativeElement.value = '';
    this.attachment = '';
    this.showUploadModalDialog = false;
  } // end onUploadFile()

  onFileRemove(fileType: string, file: BriefDocuments) {
    this.selectedDocumentToDelete = 'Are you sure you want to remove "' +
    fileType + '" from matter (' + this.workingMatter.Description + ')?';
    this.toDeleteDocumentTypes = file;
    this.showDeleteDocumentPrompt = true;
  } // end onFileRemove()

  onFileEdit(fileType: string, file: BriefDocuments) {
    this.toEditDocumentTypes = file;
    this.documentName = 'Edit document type for ( ' +
      file.DocumentPath + ')';
    this.selectedDocumentType = this.documentTypes.filter(type => type.DocumentType === file.DocumentType)[0].ID;
    this.showEditUploadModalDialog = true;
  } // end onFileEdit()

  onHideEditUploadModalDialog() {
    this.showEditUploadModalDialog = false;
  } // end onHideEditUploadModalDialog()

  onEditUploadFile(event) {
    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Edit matter document';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-matter(matter-fees-table))';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'BriefService';
    this.activityLog.JsonData = JSON.stringify(this.toDeleteDocumentTypes);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

    this.matterService.updateBriefDocumentType(event.BriefId, event).subscribe({next: (updateDoc) => {
       this.briefDocuments = this.briefDocuments.filter(s => s.BriefId !== updateDoc.BriefId);
       this.briefDocuments.push(updateDoc);
    }});
    this.showEditUploadModalDialog = false;
    this.loadingService.hideOverlay();
  } // end onEditUploadFile()

  onViewDocument(file: BriefDocuments) {
    this.selectedFile = file;
    this.documentTitle = file.DocumentType;
    this.selectedServiceId = this.workingMatter.ServiceID;
    this.selectedBriefId = file.BriefId;
    this.showMatterDocumentDialog = true;
  } // end onViewDocument()

  onDocClose() {
    this.showMatterDocumentDialog = false;
  } // end onDocClose()

  onHideDeleteDocumentPrompt() {
    this.showDeleteDocumentPrompt = false;
  } // end onHideDeleteDocumentPrompt()

  onConfirmDeleteDocumentPrompt() {
    this.loadingService.showOverlay();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete matter document';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-matter(matter-fees-table))';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'BriefService';
    this.activityLog.JsonData = JSON.stringify(this.toDeleteDocumentTypes);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

    // const deleted =
    this.matterService.deleteDocument(this.toDeleteDocumentTypes.BriefId).subscribe({next: (deleted) => {
      this.briefDocuments = this.briefDocuments.filter(s => s.BriefId !== deleted);
    }});
    this.showDeleteDocumentPrompt = false;
    this.loadingService.hideOverlay();
  } // end onConfirmDeleteDocumentPrompt()

  getExtension(filename) {
    return filename.toString().split('.')[1];
  } // end getExtension()

  onHideUploadDocumentErrorPrompt() {
    this.showUploadDocumentErrorPrompt = false;
  } // end onHideUploadDocumentErrorPrompt()

  onConfirmUploadDocumentErrorPrompt() {
    this.showUploadDocumentErrorPrompt = false;
  } // end onConfirmUploadDocumentErrorPrompt()

  onHideAttorneyErrorPrompt() {
    this.showAttorneyErrorPrompt = false;
  } // end onHideAttorneyErrorPrompt()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.workingFees && changes.workingFees.currentValue) {
      this.feesReady = true;
      this.workingFees.sort((a, b) => a.Date.toUpperCase().localeCompare(b.Date.toUpperCase()));
      this.initFeeDescriptions();
      this.calculateCurrentFeesTotal();
    }

    if (changes.workingMatter && changes.workingMatter.currentValue) {
      this.matterReady = true;
      this.initFeeDescriptions();
    }
  } // end ngOnChanges()


  onHourlyRateChange(fee: Fee, event) {
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onHourlyRateChange()

  onHourlyRateClicked(fee: Fee, event) {
    // if (fee.CaptureSource === 'Calendar' && fee.CanContinueToEditCalendarItems === false) {
    //   this.editCalendarFeeItem = fee;
    //   this.showCalendarItemEditPrompt = true;
    // } else {
      if (this.userProfileService.userProfile.isImpersonator) {
        event.target.value = this.cpipe.parse(event.target.value,
          this.userProfileService.selectedUserProfile.currencyDetails.symbol);
        } else {
        event.target.value = this.cpipe.parse(event.target.value,
          this.userProfileService.userProfile.currencyDetails.symbol);
      }
    // }
  } // end onHourlyRateClicked()

  onDiscountInputChange() {
    if(Boolean(Number(this.currentFeesTotal) < Number(this.workingMatter.Discount))){
      this.ShowDiscountErrorMessage = true;
    }
    else{
    if (this.userProfileService.userProfile.isImpersonator) {
      this.DiscountInput.nativeElement.value = this.cpipe.transform(this.DiscountInput.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.DiscountInput.nativeElement.value = this.cpipe.transform(this.DiscountInput.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  }
  } // end onDiscountInputChange()

  onPaymentReceivedInputChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.transform(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.transform(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onPaymentReceivedInputChange()

  onDiscountInputClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.DiscountInput.nativeElement.value = this.cpipe.parse(this.DiscountInput.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
      } else {
      this.DiscountInput.nativeElement.value = this.cpipe.parse(this.DiscountInput.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
      }
  } // end onHourlyRateClicked()

  onShowDiscountErrorMessage(){
    this.ShowDiscountErrorMessage = false;
  }

  onPaymentReceivedInputClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.parse(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
      } else {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.parse(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
      }
  } // end onPaymentReceivedInputClicked()

  initFeeDescriptions() {
    if (this.feesReady && this.matterReady) {
      this.workingFees.map(fee => {
        if (!fee.FurtherDescription) {
          fee.FurtherDescription = fee.Subject
                                      .replace(/^[\w\s/\d.]+-\s/gi, '');
          fee.FurtherDescription = fee.FurtherDescription
                                      .replace(/[\s]+-[\s]+[\w]+$/gi, '')
                                      .replace(' - ' + this.workingMatter.Status, '')
                                      .replace(' - ' + fee.MatterStatus, '')
                                      .trim();
        }
      });
    }
  } // end initFeeDescriptions()

  setActiveTab(tab: string) {
    this.activeTab = tab;
  } // end setActiveTab()

  getFeeDescription(subject: string): string {
    let feeDescription = '';
    const startIndex = subject.indexOf(' -');
    const endIndex = subject.indexOf(' -', startIndex);
    feeDescription = subject.substr(startIndex, (endIndex - startIndex));
    return feeDescription;
  } // end getFeeDescription()

  addAnotherFee() {
    if (this.workingMatter.ClientRefNo !== '' && this.workingMatter.InternalRefNo !== '' && this.workingMatter.PlaintiffFullName !== '') {
      const rateType: string = DEFAULT_RATE_TYPE;
      let rate: number = DEFAULT_RATE;
      let qty: number = DEFAULT_QUANTITY;

      switch (rateType) {
        case RATE_TYPE_PER_KILOMETER:
          rate = this.workingMatter && this.workingMatter.PerKilometerRate
                  ? this.workingMatter.PerKilometerRate
                  : this.userProfile.billingDetails.defaultPerKilometerRate;
                  qty = 1;
                  this.quantityReadOnly = false;
          break;

        case RATE_TYPE_HOURLY:
          rate = this.workingMatter && this.workingMatter.HourlyRate
                  ? this.workingMatter.HourlyRate
                  : this.userProfile.billingDetails.defaultHourlyRate;
                  qty = 1;
                  this.quantityReadOnly = false;
          break;

        case RATE_TYPE_DAILY:
          rate = this.workingMatter && this.workingMatter.DailyRate
                  ? this.workingMatter.DailyRate
                  : this.userProfile.billingDetails.defaultDailyRate;
                  qty = 1;
                  this.quantityReadOnly = false;
          break;

        case RATE_TYPE_ONCE_OFF:
          qty = 1;
          this.quantityReadOnly = true;
          break;
      }
      // this.invoicelineCounter = this.workingFees && this.workingFees.length;

      if (this.workingFees && this.workingFees.length > 0) {
        this.invoicelineCounter = this.workingFees.length + 1;
      } else {
        this.invoicelineCounter = 0;
      }

      const fee = Object.assign({}, DEFAULT_FEE);
      fee.Date = moment().toJSON();
      fee.Rate = rate;
      fee.Total = rate * qty;
      fee.Quantity = qty;
      fee.Date = moment().toJSON();
      fee.MatterStatus = this.workingMatter.Status;
      fee.CanEdit = true;
      fee.LineCounter = this.invoicelineCounter;


      // Reset the selectedSubCategory
      this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);

      this.workingFees.push(fee);
      this.workingFeesChange.emit(this.workingFees);

      this.calculateCurrentFeesTotal();
    } else {
      this.showAttorneyErrorPrompt = true;
    }
  } // end addAnotherFee()

  promptDeleteFee(fee: Fee) {
    this.selectedFee = fee;
    this.openModal('delete-fee-modal');
  } // end deleteFee()

  /**
   * Removes a fee from the collection of fees assocciated with the loaded
   * matter.
   * @param {Fee} deletableFee The fee to be deleted.
   */
  deleteFee(deletableFee: Fee) {
    // TODO: Delete the fee
    this.previousFees = this.util.objectCopy(this.workingFees);
    this.workingFees = this.workingFees.filter(fee => {
      return !this.util.objectIsSame(deletableFee, fee);
    });
    this.workingFeesChange.emit(this.workingFees);

    if (deletableFee.FeeItemID) {
      this.feeDelete.emit(deletableFee);
    }

    this.calculateCurrentFeesTotal();
  } // end deleteFee()

  /**
   * Reverses the most recent fee deletion.
   * @param {MatterFeesTableComponent} thisObj The calling object instance.
   */
  undoDelete(thisObj) {
    if (thisObj.previousFees) {
      this.snackbarsService.snackbarComponent.dismiss();
      thisObj.fees = thisObj.previousFees;
      thisObj.calculateCurrentFeesTotal();
      const snack: Snack = {
        label: 'Fee restored',
        action: null
      };
      this.snackbarsService.snackbarComponent.make(snack).show();
    }
  } // end undoDelete()


  /**
   * Opens a modal of the given name.
   * @param {string} modal The name of the modal to be opened.
   */
  openModal(modal: string) {
    switch (modal) {
      case 'delete-fee-modal':
        this.showDeleteFeeModal = true;
        break;
    }
  } // end openModal()

  /**
   * Closes a modal of the given name.
   * @param {string} modal The name of the modal to be closed.
   */
  closeModal(modal: string) {
    switch (modal) {
      case 'delete-fee-modal':
        this.showDeleteFeeModal = false;
        break;
    }
  } // end closeModal()

  /**
   * Called when the discount value changes.
   */
  onDiscountChange() {
    // if (this.useDiscountPercentage) {
    //   this.currentFeesDiscount =
    //     this.util.numberClamp(this.currentFeesDiscount, 0, 100);
    //   this.workingMatter.DiscountPercentage = this.currentFeesDiscount;
    // } else {
    //   this.workingMatter.DiscountPercentage =
    //     (this.currentFeesDiscount / this.currentFeesDiscount) * 100;
    // }
    // this.workingMatter.Discount = this.currentFeesDiscount;
    // this.workingMatterChange.emit(this.workingMatter);

    this.calculateCurrentFeesTotal();
  } // end onDiscountChange()

  onPaymentReceivedChange() {
    this.calculateCurrentFeesTotal();
  } // end onPaymentReceivedChange()

  /**
   * Toggles the useDiscountPercentage flag between true and false.
   */
  toggleUseDiscountPercentage() {
    this.useDiscountPercentage = !this.useDiscountPercentage;
    let discount;

    if (this.useDiscountPercentage) {
      discount = (this.currentFeesDiscount / this.currentFeesTotal) * 100;
    } else {
      discount = (this.currentFeesDiscount / 100) * this.currentFeesTotal;
    }
    this.currentFeesDiscount = discount;
    this.onDiscountChange();
  } // end toggleUseDiscountPercentage()

  /**
   * Returns a displayable date string, suitbale for use with inputs of type
   * calendar, from a given date string.
   * @param {string} date The date string;
   */
  parseDate(date: string) {
    return this.util.parseDate(date);
  } // end parseDate()
  onValueChange(fee: Fee, value: Date): void {
    // this.editCalendarFeeItem = DEFAULT_FEE;
      fee.Date = moment(value).format('YYYY-MM-DDTHH:mm:ss');
      var workingFee = this.fees.filter(c => c.FeeItemID == fee.FeeItemID)[0];
      if (this.onFeeChangesCheck(fee, workingFee) == true) {
        fee.Edited = true;
    }
  }

  // onDateValueBlur(fee: Fee, value) {
  //   if (fee.CaptureSource === 'Calendar' && fee.CanContinueToEditCalendarItems === false) {
  //     this.editCalendarFeeItem = fee;
  //     // this.editCalendarFeeItem.Date = moment(value).format('YYYY-MM-DDTHH:mm:ss');

  //     this.showCalendarItemEditPrompt = true;
  //   //   this.bsConfig = Object.assign({}, { minDate: this.minDate });
  //   // this.datepicker?.setConfig();

  //   // setTimeout(() => {
  //   //   this.datepicker?.toggle();
  //   // });
  //   }
  // } // end onDateValueBlur()

  onHideCalendarItemEditPrompt() {
    this.showCalendarItemEditPrompt = false;
  } // end onHideCalendarItemEditPrompt()

  onConfirmCalendarItemEditPrompt() {
    // this.workingFees.forEach(fee => {
    //   if (fee.FeeItemID == this.editCalendarFeeItem.FeeItemID) {
    //     fee = this.editCalendarFeeItem;
    //   }
    // });
    this.workingFees.forEach(fee =>  {
      if (fee === this.editCalendarFeeItem) {
        fee.CanContinueToEditCalendarItems = true;
      }
    })
    this.showCalendarItemEditPrompt = false;
    // this.bsConfig = Object.assign({}, { minDate: this.minDate });
    // this.datepicker?.setConfig();

    // setTimeout(() => {
    //   this.datepicker?.toggle();
    // });
  } // end onConfirmCalendarItemEditPrompt()

  /**
   * Parses through a fee subject and gets the fee description.
   * @param {Fee} fee The fee item object to be parsed.
   * @returns {string} Returns the fee description string.
   */
  getDescription(fee: Fee): string {
    const subject = fee.Subject
                      .replace(/^[\w\s/\d.]+-\s/gi, '')
                      .replace(/[\s]+-[\s]+[\w]+$/gi, '')
                      .replace(' - ' + this.workingMatter.Status, '')
                      .replace(' - ' + fee.MatterStatus, '').trim();

    return subject;
  } // end getDescription()

  /**
   * Culculates the total for a given fee based on the fee rate and quantitiy.
   * @param {number} rate The fee rate.
   * @param {number} qty The fee quantity.
   * @returns {string} Returns the calculated fee total as a currency
   * formatted string.
   */
  calculateFeeTotal(fee: Fee) {
    fee.Total = (fee.Rate * fee.Quantity);
      var workingFee = this.fees.filter(c => c.FeeItemID == fee.FeeItemID)[0];
      if (this.onFeeChangesCheck(fee, workingFee) == true) {
        fee.Edited = true;
      }
    this.calculateCurrentFeesTotal();
  } // end calculateFeeTotal()

  /**
   * Auto select all text on input
   * @param $event(inputId)
   */
  selectAllContent($event) {
    $event.target.select();
  } // end selectAllContent($event)

  /**
   * Calculates the sum of fee totals.
   */
  calculateCurrentFeesTotal() {
    let total = 0;
    this.workingFees.forEach(fee => {
      total += fee.Total;
    });

    this.currentFeesTotal = total;
    this.calculateCurrentFeesGrandTotal();
  } // end calculateCurrentFeesTotal()

  /**
   * Calculates the sum of fee totals taking into account the discount rate.
   */
  calculateCurrentFeesGrandTotal() {
    if (this.workingMatter.PaymentReceived) {
      this.workingMatter.Total = (this.currentFeesTotal - this.workingMatter.Discount) - this.workingMatter.PaymentReceived;
    } else {
      this.workingMatter.Total = this.currentFeesTotal - this.workingMatter.Discount;
    }
  } // end calculateCurrentFeesGrandTotal()

  /**
   * Called when the date of the given fee is changed.
   * @param {Fee} fee The updated fee.
   * @param event The change event.
   */
  // onDateChange(fee: Fee, event) {
  //   fee.Date = moment(event.target.value).toJSON();
  //   this.workingFeesChange.emit(this.workingFees);
  // } // end onDateChange()

  onFeeDescriptionSelect(subCategory: SubCategory, fee: Fee) {
    this.selectedSubCategory = Object.assign({}, subCategory);

    fee.SubCategoryID = this.selectedSubCategory.SubCategoryId;
    fee.FurtherDescription = this.selectedSubCategory.SubCategory;
    fee.RateType = this.selectedSubCategory.RateType;
    // fee.Rate =
    //   this.util.getRateFromRateType(this.userProfile, fee.RateType);
    this.onRateTypeChange(fee);
    this.onRateChange(fee);
    this.workingFeesChange.emit(this.workingFees);
    this.calculateFeeTotal(fee);
  } // end onFeeDescriptionSelect()

  /**
   * Called when the fee description input.
   * @param event The input event.
   */
  onFeeDescriptionInput(event, fee: Fee) {
    if (this.selectedSubCategory) {
      if (event && event.inputType && event.inputType.localeCompare('deleteContentBackward') === 0) {
        // Assuming a SubCategory has been selected
        if (fee.SubCategoryID) {
          this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
          this.selectedSubCategory.SubCategoryId = 0;
          fee.SubCategoryID = 0;
          fee.FurtherDescription = event.target.value;
        }
      } else {
        const description = this.selectedSubCategory.SubCategory;
        this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
        this.selectedSubCategory.SubCategory = description;
        fee.FurtherDescription = event.target.value;
      }
    } else {
      // If not backspace
      this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
      this.selectedSubCategory.SubCategoryId = 0;
      fee.FurtherDescription = event.target.value;
      fee.SubCategoryID = 0;
    }
    var workingFee = this.fees.filter(c => c.FeeItemID == fee.FeeItemID)[0];
    if (this.onFeeChangesCheck(fee, workingFee) == true) {
      fee.Edited = true;
    }
    //this.workingFeesChange.emit(this.workingFees);
  } // end onFeeDescriptionInput()

  onSpecialFeeDescriptionInput(event, fee: Fee) {
    var workingFee = this.fees.filter(c => c.FeeItemID == fee.FeeItemID)[0];
    if (this.onFeeChangesCheck(fee, workingFee) == true) {
      fee.Edited = true;
    }
  } // end onSpecialFeeDescriptionInput()

  /**
   * Called when the ratetype of a fee is changed.
   * @param {Fee} fee The just updated fee.
   */
  onRateTypeChange(fee: Fee) {
    fee.Quantity = DEFAULT_QUANTITY;
    switch (fee.RateType) {
      case RATE_TYPE_PER_KILOMETER:
        fee.Rate = this.workingMatter.PerKilometerRate
                    ? this.workingMatter.PerKilometerRate
                    : this.userProfile.billingDetails.defaultPerKilometerRate;
                    fee.Quantity = 1;
                    this.quantityReadOnly = false;
        break;

      case RATE_TYPE_HOURLY:
        fee.Rate = this.workingMatter.HourlyRate
                    ? this.workingMatter.HourlyRate
                    : this.userProfile.billingDetails.defaultHourlyRate;
                    fee.Quantity = 1;
                    this.quantityReadOnly = false;
        break;

      case RATE_TYPE_ONCE_OFF:
        fee.Rate = 1.00;
        fee.Quantity = 1;
        this.quantityReadOnly = true;
        break;

      case RATE_TYPE_DAILY:
        fee.Rate = this.workingMatter.DailyRate
                    ? this.workingMatter.DailyRate
                    : this.userProfile.billingDetails.defaultDailyRate;
                    fee.Quantity = 1;
                    this.quantityReadOnly = false;
        break;

      case RATE_TYPE_DISBURSEMENT:
        fee.Quantity = 1;
        fee.Rate = 1.00;
        this.quantityReadOnly = true;
        break;

      case RATE_TYPE_NON_BILLABLE:
        fee.Rate = 0;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;

      case RATE_TYPE_PER_PAGE:
        fee.Rate = 1.00;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;
    }
    this.calculateFeeTotal(fee);
  } // end onRateTypeChange()

  onRateFocus(event, fee = null) {
    this.util.formatCurrencyInput(event.target);

    if (fee) {
      this.calculateFeeTotal(fee);
    }
  } // end onRateFocus()

  onRateChange(event, fee: Fee = null) {
    this.util.formatCurrencyInput(event.target);
    if (fee) {
      this.calculateFeeTotal(fee);
    }
  } // end onRateChange()

  canChangeRateType(fee: Fee): boolean {
    return Boolean(!fee.SubCategoryID);
  } // end canChangeRateType()

  onQuantityFocus(fee: Fee, event) {
    if (fee.CaptureSource === 'Calendar' && fee.CanContinueToEditCalendarItems === false) {
      this.editCalendarFeeItem = fee;
      this.showCalendarItemEditPrompt = true;
    }
  } // end onQuantityFocus()

  onTotalFocus(fee: Fee, event) {
    if (fee.CaptureSource === 'Calendar' && fee.CanContinueToEditCalendarItems === false) {
      this.editCalendarFeeItem = fee;
      this.showCalendarItemEditPrompt = true;
    }
  } // end onTotalFocus()

  /**
   * Sets the IsVatable checkbox checked property.
   * @param event The mouse event object.
   * @param value The new vatable value.
   */
  setIsVatable(event, value) {
    event.target.checked = value;
  } // end setIsVatable()

  /**
   * Toggles the vatability of the given fee.
   * @param {Fee} fee The vatable fee.
   */
  toggleIsVatable(fee: Fee) {
    if (fee.CanEdit && fee.RateType === 'Disbursement') {
      fee.IsVatable = !fee.IsVatable;
    }
  } // end toggleIsVatable()
} // end MatterFeesTableComponent{}
