import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import {
  ToastService,
  LENGTH_SHORT,
  LENGTH_LONG,
  Toast
} from '../../../services/messaging/toast/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('toastState', [
      state('inactive', style({
        transform: 'translateY(0px)'
      })),
      state('active', style({
        transform: 'translateY(128px)'
      })),
      transition('inactive => active', animate('300ms linear')),
      transition('active => inactive', animate('300ms ease-out')),
    ])
  ]
})
export class ToastComponent implements OnInit {

  text: string = 'single-line message.';
  duration: number = LENGTH_SHORT; // in milliseconds
  isHidden: boolean = true;
  
  private timeoutHandle: any;

  get toastState() {
    return this.isHidden ? 'inactive' : 'active';
  } // end toastState()
  
  constructor(
    private toastService: ToastService
  ) {} // end constructor()

  ngOnInit() {
    this.toastService.setToastComponent(this);
  } // end ngOnInit()

  make(toast: Toast) {
    if (toast) {
      this.text = toast.text;
    }
    return this;
  } // end make()

  show() {
    this.isHidden = false;
    this.timeoutHandle = setTimeout(this.cancel, this.duration, this);
    return this;
  } // end show()

  cancel(thisObj: any = null) {
    if (thisObj) {
      // For calls made by setTimeout
      thisObj.isHidden = true;
    } else {
      this.isHidden = true;
    }
    clearTimeout(this.timeoutHandle);
  } // end cancel()
} // end ToastComponent{}
