import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { BriefDocuments, DEFAULT_BRIEF_DOCUMENT, Matter, MattersService } from '../../../services/matters/matters.service';
import { DocumentTypes } from '../../../services/fee-items/fee-items.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { FileUploadService } from '../../../services/file-upload/file-upload.service';
// import { } from '@angular/core/testing';

@Component({
  selector: 'app-files-to-be-sent',
  templateUrl: './files-to-be-sent.component.html',
  styleUrls: ['./files-to-be-sent.component.scss']
})
export class FilesToBeSentComponent implements OnInit, OnChanges {

  selectedDocument = 0;
  showAddNewBrief = false;
  attachment: string;
  formData: FormData = new FormData();
  attachmentFile: File;
  selectedDocumentType = 0;
  title = 'Add / Attach matter document(s)';

  @Input() isVisible: boolean;
  @Input() briefDocuments: BriefDocuments[];
  @Input() documentTypes: DocumentTypes[];
  @Input() serviceId: number;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedDoc: EventEmitter<BriefDocuments> = new EventEmitter<BriefDocuments>();
  @ViewChild('inputAttachmentFile') inputAttachmentFile: ElementRef;
  @ViewChild('inputUploadFile') inputUploadFile: ElementRef;
  @ViewChild('documentType') documentType: ElementRef;

  get canAddDocument(): boolean {
    let isvalidDoc = false;
    if (this.inputAttachmentFile && this.inputAttachmentFile.nativeElement.value &&
      this.inputUploadFile && this.inputUploadFile.nativeElement.value) {
      if (this.selectedDocumentType > 0) {
        isvalidDoc = true;
      }
    } else if (this.selectedDocument) {
      isvalidDoc = true;
    } else {
      isvalidDoc = false;
    }
    return isvalidDoc;
  } // end canAddDocument()

  constructor(
    private matterService: MattersService,
    private loadingService: LoadingService,
    private fileUploadService: FileUploadService
  ) { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    this.briefDocuments = this.briefDocuments.filter(s => s.Status === false);
  } // end ngOnChnages()

  toggleShowAddNewBrief() {
    this.showAddNewBrief = !this.showAddNewBrief;
    this.clearModal();
  } // end toggleShowAddNewBrief()

  fileAttachmentChange(file) {
    this.attachmentFile = file.target.files[0];
    if (this.getExtension(file.target.files[0].name) === 'pdf' ||
        this.getExtension(file.target.files[0].name) === 'docx') {
        this.attachment = this.attachmentFile.name;
    } else {

    }
  } // end fileAttachmentChange()

  promptAttachment() {
    jQuery('#attachmentUpload').val(null);
    jQuery('#attachmentUpload').trigger('click');
  } // end promptAttachment()

  getExtension(filename) {
    return filename.toString().split('.')[1];
  } // end getExtension()

clearModal() {
  this.selectedDocument = 0;
  this.attachment = '';
  this.attachmentFile = null;
  if (this.inputAttachmentFile && this.inputAttachmentFile.nativeElement.value
    && this.inputUploadFile && this.inputUploadFile.nativeElement.value) {
    this.inputAttachmentFile.nativeElement.value = '';
    this.inputUploadFile.nativeElement.value = '';
    }
} // end clearModal()

  onCancel() {
    this.clearModal();
    this.cancel.emit();
    this.showAddNewBrief = false;
  } // end onCancel()

  onAddSelected() {
    if (this.showAddNewBrief) {
      this.loadingService.showOverlay();
      let briefId = 0;
      this.formData = new FormData();
      this.formData.append('BriefDocument', this.attachmentFile);
      this.fileUploadService.postBriefDocumentAttachement(this.serviceId, +this.selectedDocumentType, this.formData)
      .subscribe({
        next: (doc) => {
        // on next
        briefId = doc;
      },
      error: (error) => {
        // On next
        
        this.formData.delete('BriefDocument');
        this.loadingService.hideOverlay();
      },
     complete: () => {
        this.clearModal();
        this.showAddNewBrief = false;

        this.matterService.getSingleDocument(this.serviceId, briefId).subscribe({next: (docs) => {
          this.selectedDoc.emit(docs);
        }});
        this.loadingService.hideOverlay();
        this.showAddNewBrief = false;
      }
    });
    } else {
      
      const selectedDocumentFile: BriefDocuments = this.briefDocuments.filter(doc => doc.BriefId === +this.selectedDocument)[0];
      this.selectedDoc.emit(selectedDocumentFile);
      this.selectedDocument = 0;
      this.showAddNewBrief = false;
    }
  } // end onAddSelected()

} // end FilesToBeSentComponent{}
