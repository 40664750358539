import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {
  ApiService,
  HTTP_STATUS_NETWORK_CONNECTION,
  HTTP_STATUS_BAD_REQUEST
} from '../api.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import {
  ToastService,
  Toast
} from '../messaging/toast/toast.service';

export interface FeeTemplate {
  SubCategoryId: number;
  Category: string;
  SubCategory: string;
  RateType: string;
  ShowTimeAs: string;
  DefaultRate: number;
  UserName: string;
  IsMandatory: boolean;
}

export interface FeeTemplateResponse {
  Id: number;
  Description: string;
  Action: string;
  RateType: string;
  ShowTimeAs: string;
  UserName: string;
  DefaultRate: number;
  Actions: Array<any>;
} // end FeeTemplateResponse{}

export const DEFAULT_FEE_TEMPLATE = {
  SubCategoryId: 0,
  Category: '',
  SubCategory: '',
  RateType: 'Hourly',
  ShowTimeAs: 'Free',
  DefaultRate: 0,
  UserName: '',
  IsMandatory: false
}; // end DEFAULT_FEE_TEMPLATE{}

export const categories: string[] = [
  'PhoneCall',
  'Draft',
  'Peruse',
  'Attendance',
  'Consulting',
  'Preparation',
  'Travel',
  'Correspondence',
  'Diarise',
  'DeleteAction'
];
export const DEFAULT_CATEGORY_INDEX = 0;

export const rateTypes: string[] = [
  'Per Kilometer',
  'Hourly',
  'Once Off',
  'Daily',
  'Disbursement',
  'Non Billable',
  'Per Page'
];
export const DEFAULT_HOURLY_INDEX = 1;

export const AVAILABILITIES: string[] = [
  'Free',
  'Busy',
  'Tentative',
  'OutOffice'
];
export const DEFAULT_AVAILABILITY_INDEX = 0;

@Injectable()
export class FeeTemplateService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private toastService: ToastService
  ) { }

  /**
   * GET: returns an observable list of Fee templates corresponding to the
   * given user name.
   * @param {string} userName The user name of the related fee templates.
   * @returns {Observable<any>} Returns Observable with the newly created
   * fee template.
   */
  getFeeTemplates(userName: string): Observable<any> {
    return this.http.get(this.api.endpoints.subCategoryAll +
      '?UserName=' + userName, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getFeeTemplates()

  /**
   * PUT: update an existing Fee Template.
   * @param {FeeTemplate} feeTemplate The fee template to be updated.
   * @returns {Observable<any>} Returns Observable with the newly created
   * fee template.
   */
  updateFeeTemplate(feeTemplate: FeeTemplate): Observable<any> {
    return this.http.put(this.api.endpoints.subCategoryAll +
      '?id=' + feeTemplate.SubCategoryId, feeTemplate, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end updateFeeTemplates()

  /**
   * POST: insert new Fee Template data into the database.
   * @param {FeeTemplate} feeTemplate The new fee template.
   * @returns {Observable<any>} Returns Observable with the newly created
   * fee template.
   */
  createFeeTemplate(feeTemplate: FeeTemplate): Observable<any> {
    return this.http.post(this.api.endpoints.subCategoryAll,
      feeTemplate, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end createFeeTemplate()
} // FeeTemplateService{}
