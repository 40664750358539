import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter} from '@angular/core';
import { Matter } from '../../../services/matters/matters.service';
import { InvoiceDetails } from '../../../services/invoice-details/invoice-details.service';
import { MessageTemplate } from '../../../services/invoice-message/invoice-message.service';

@Component({
  selector: 'app-single-invoice-popup-menu',
  templateUrl: './single-invoice-popup-menu.component.html',
  styleUrls: ['./single-invoice-popup-menu.component.scss']
})
export class SingleInvoicePopupMenuComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() invoiceId: number;
  @Input() type: string;
  @Input() messageTemplateHandle: MessageTemplate;
  @Output() previewMessage: EventEmitter<any> = new EventEmitter();
  @Output() removeInvoice: EventEmitter<any> =  new EventEmitter();

  options: any = {
    previewMessage: false,
    removeInvoice: false
  };

  constructor() { } // end constructor()

  ngOnInit() {
    this.options.previewMessage = (this.type === 'Revise');
    this.options.removeInvoice = (this.type === 'Revise');
  } // end ngOnInit()

  dismiss() {
    this.isVisible = false;
  } // end dismiss()

  onPreviewMessage() {
    this.previewMessage.emit(this.messageTemplateHandle);
    this.dismiss();
  } // end onPreviewMessage()

  onRemoveInvoice() {
    this.removeInvoice.emit(this.messageTemplateHandle);
    this.dismiss();
  } // end onRemoveMessage()
}
