import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService, httpOptions } from '../../api.service';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

export interface Message {
  ID: number;
  MessageDate: string;
  Source: string;
  SourceId: number;
  Recipients: string;
  EMailAddresses: string;
  Description: string;
  DocumentPath: string;
  ContactId: number;
  Status: string;
  Subject: string;
  Body: string;
  RolloverService: boolean;
  SendMessage: boolean;
  ServiceId: number;
}

export const DEFAULT_MESSAGE_SEND: Message = {
  ID: 0,
  MessageDate: '',
  Source: '',
  SourceId: 0,
  Recipients: '',
  EMailAddresses: '',
  Description: '',
  DocumentPath: '',
  ContactId: 0,
  Status: '',
  Subject: '',
  Body: '',
  RolloverService: true,
  SendMessage: true,
  ServiceId: 0
};

@Injectable()
export class MessagesService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  /**
   * GET: retrieves messages data for a particular invoice.
   * @param {number} invoiceId The unique identifier of the invoice to
   * be emailed.
   * @returns {Observable<*>} Returns data which will be used to pre-populate
   * an email message for a given Invoice (ID)
   */
  getMessage(invoiceId: number, type: string): Observable<any> {
    return this.http.get<Message>(this.api.endpoints.messages +
      '?InvoiceId=' + invoiceId + '&type=' + type, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end get()

  getQuotationMessage(invoiceId: number, type: string): Observable<any> {
    return this.http.get<Message>(this.api.endpoints.messages +
      '/QuotationMessage?InvoiceId=' + invoiceId + '&type=' + type, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end get()

  /**
   * PUT: update message for sending an Invoice via Email.
   * This will allow updating of Email message data in preparation for sending
   * an Invoice, including:
   * - Change Email subject
   * - Change Email body
   * - Change receipients
   * @param {Message} message The message to be updated/sent.
   * @returns {Observable<any>} Returns an Observable object containing an
   * object containing the updated message's messageId and a response text.
   */
  updateMessage(message: Message): Observable<any> {
    message.SendMessage = false;
    return this.http.put(this.api.endpoints.messages +
      '?id=' + message.ID, message, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end put()

  sendMessage(message: Message): Observable<any> {
    message.SendMessage = true;
    return this.http.put(this.api.endpoints.messages +
      '?id=' + message.ID, message, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end sendMessage()

  /**
   * DELETE: deletes message data for the message of the given message id.
   * @param {number} messageId The unique message identification number.
   * @returns {Observable<*>} Returns an Observable.
   */
  deleteMessage(messageId: number): Observable<any> {
    return this.http.delete(this.api.endpoints.messages +
      '/' + messageId, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end delete()

  /**
   * The
   * @param {number} invoiceId The unique identifier of the invoice.
   */
  getInvoiceDocumentPath(invoiceId: number): string {
    return this.api.endpoints.invoiceDocument + '?invoiceID=' + invoiceId;
  } // end getInvoiceDocumentPath()


  getCreditNoteMessage(creditNoteId: number): Observable<any> {
    return this.http.get<Message>(this.api.endpoints.messages + '/GetCreditNoteMessage?CreditNoteId=' + creditNoteId, this.api.httpOptions).pipe(
      retry(3),
      catchError(this.api.handleError)
    );
  }


  putCreditNoteMessage(message: Message): Observable<any> {
    return this.http.put(this.api.endpoints.messages +
      '?id=' + message.ID, message, this.api.httpOptions).pipe(
        retry(0),
        catchError(this.api.handleError)
      );
  }
} // end MessagesService{}
