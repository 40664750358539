import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ContactLocalities, Contacts, DEFAULT_CONTACT_LOCALITIES } from '../../../services/contacts/contacts.service';

@Component({
  selector: 'app-datalist-localities',
  templateUrl: './datalist-localities.component.html',
  styleUrls: ['./datalist-localities.component.scss']
})
export class DatalistLocalitiesComponent implements OnInit {

  @Input() type: string;
  @Input() selectedLocality: ContactLocalities;
  @Input() isManageBlacklistUser : boolean;
  @Input() contactLocalities: ContactLocalities[];
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  constructor() { } // end constructor()

  ngOnInit(): void {
    this.isManageBlacklistUser;
  } // end ngOnInit()

  onInput(event) {
    if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {

    }
  } // end onInput()

  onSelectContactLocality(event: TypeaheadMatch): void {
    this.selectedLocality = DEFAULT_CONTACT_LOCALITIES;
    this.selectedLocality.Id = event.item.Id;
    this.selectedLocality.Description = event.item.Description;
    this.select.emit(this.selectedLocality);

  } // end onSelectContactLocality()

} // end DatalistLocalitiesComponent {}
