<form name="form" #f="ngForm" novalidate>
  <div class="modal">
    <div class="modal-content">
      <div class="modal-dialog">

        <div class="modal-header">
          <h2>{{title}}</h2>
          <button class="modal-dismiss" (click)="dismiss('Write-off')">
            <i class="fal fa-times-circle"></i>
          </button>
        </div>
        <div class="modal-body form">


          <div class="row m-0">

            <div class="col-sm-3 pl-5 pr-5">
              <label for="">Write-off amount</label>

              <div class="input-group">
                <label for="">{{totalAmount | currency: currencySymbol}}</label>

              </div>
            </div>
            <div class="col-sm-3 pl-5 pr-5">
              <label for="">Write-off Date <span title="Required field"> *</span>&nbsp;</label>
              <div class="input-group">
                <input
                  readonly
                  type="text"
                  name="dateInput"
                  [ngStyle]="{'border-color' : isDate == false ? '#cf624f':'#e7e4e4'}"
                  [value]="writeOff.Date | date:'yyyy-MM-dd'"
                  required>
                <!-- <div class="input-group-append" (click)="_writeOffDate.toggle()"
                  [attr.aria-expanded]="_writeOffDate.isOpen">
                  <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                </div> -->
              </div>
            </div>

            <div class="col-sm-6 pl-5 pr-5">
              <label for="">Notes <span title="Required field"> *</span>&nbsp;</label>
              <div class="input-group">
                <input
                   #Notes="ngModel" 
                   (blur)="onWriteOffNotesBlur()"
                  [ngStyle]="{'border-color' : isNotes == false ? '#cf624f':'#e7e4e4'}" 
                  type="text" name="Notes"
                  [(ngModel)]="writeOff.Notes" 
                  class="form-control" 
                  autocomplete="off" required>
              </div>
            </div>

          </div>
          <div class="row m-0">
            <div class="table-responsive">
              <table class="table ">
                <tbody>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Invoice</th>
                    <th scope="col">Law Firm</th>
                    <th scope="col">Matter</th>
                    <th scope="col">Reference</th>
                    <th scope="col" class="text-right">Inv Amount</th>
                    <th scope="col" class="text-right">Paid</th>
                    <th scope="col" class="text-right ">Due</th>
                    <th scope="col" class="text-right pr-3 ">Write Off</th>
                  </tr>
                  <tr *ngFor="let invoice of invoices; let i = index">
                    <td [title]="invoice.data.Date | date:'yyyy-MM-dd'">{{invoice.data.Date | date:'yyyy-MM-dd'}}
                    </td>
                    <td [title]="invoice.data.InvoiceNo">{{invoice.data.InvoiceNo}}</td>
                    <td [title]="invoice.data.Client" class="text-truncate">{{invoice.data.Client}}</td>
                    <td [title]="invoice.data.Matter" class="text-truncate">{{invoice.data.Matter}}</td>
                    <td [title]="invoice.data.Reference" class="text-truncate">{{invoice.data.Reference}}</td>
                    <td [title]="invoice.data.Amount | currency:currencySymbol" class="text-right">
                      {{invoice.data.Amount  | currency: currencySymbol}}
                    </td>
                    <td [title]="invoice.data.Paid | currency:currencySymbol" class="text-right">
                      {{invoice.data.Paid | currency: currencySymbol}}
                    </td>
                    <td [title]="(invoice.data.Amount - invoice.data.Paid) | currency:currencySymbol"
                      class="text-right">
                      {{(invoice.data.Amount - invoice.data.Paid) | currency: currencySymbol}}
                    </td>
                    <td class="text-right paid">
                      <input
                        type="text" 
                        name="WriteOffInput{{i}}" 
                        id="amount"
                        [attr.disabled]="invoice.data.WriteOff === '0' || invoice.data.WriteOff === 0  ? 'disabled' : null"
                        [ngModel]="invoice.data.WriteOff | currency: currencySymbol: currencySymbol:'1.2-2'"
                        class="form-control text-right write-off-notes" 
                        autocomplete="off" 
                        [ngModelOptions]="{updateOn: 'blur'}"
                        (ngModelChange)="invoice.data.WriteOff = $event"
                        (ngModelChange)="onTotalsCalculateChange('edit')" (blur)="onAmountChange(invoice.data, $event)"
                        (focus)="onAmountClicked(invoice.data, $event)" (focus)="$event.target.select()"
                        pattern="[P-R\s]*[0-9,.]+" required #inputAmount>
                    </td>
                    <app-prompt-unsaved-changes-modal
                      message="Make sure the invoice due amount is not more than write off amount."
                      [isVisible]="showErrorMessagePrompt" btnTextCancel="OK" btnTextContinue="Cancel"
                      (cancel)="onHideErrorMessagePrompt(invoice.data, $event)" title="Error Message"
                      (continue)="onHideErrorMessagePrompt(invoice.data, $event)">
                    </app-prompt-unsaved-changes-modal>
                    <br>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>

        </div>
        <!--#end .modal-body -->

        <div class="modal-footer">
          <button class="btn btn-danger" (click)="dismiss('Write-off')">CANCEL</button>
          <button class="btn btn-success" (click)="onWriteOffAmount()" [disabled]="!f.form.valid">WRITE-OFF</button>
        </div>
        <!--#end .modal-footer  [disabled]="!f.form.valid"-->
      </div>
      <!--#end .modal-dialog -->
    </div>
    <!--#end .modal-content -->
  </div>
  <!--#end .modal -->
</form>