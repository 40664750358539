<div class="container-fluid">
  <app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>

  <app-lawfirm-age-analysis
    *ngIf="!isExternalAttorney && ageAnalysisData"
    (onRowClick)="receiveAllCustomer($event)"
    [data]="ageAnalysisData"
    [threeMonthsFirmData]="threeMonthsFirmData"></app-lawfirm-age-analysis>
    <app-live-statement
    [data]="statementByAdvocate"
    *ngIf="!showReportTable"></app-live-statement>
</div>
