<form name="form" #f="ngForm" novalidate>
    <div class="modal">
        <div class="modal-content">
            <div class="modal-dialog">

                <div class="modal-header">
                    <h2>Import Bank Statements</h2>
                    <button class="modal-dismiss" (click)="closeModel('close')">
                        <i class="fal fa-times-circle"></i>
                    </button>
                </div>
                <div class="modal-body form">
                    <div class="row m-0 pl-0 pb-0">
                        <div class="col-sm-9 pl-0 pr-5">
                            <div class="custom-file">
                                <label for="last-transaction">Bank name: </label>
                                <span class="transaction-date">
                                    {{bankStatementSummary.BankName}}
                                </span>
                            </div>
                            <div class="custom-file">
                                <label for="last-transaction">Last Transaction: </label>
                                <span class="transaction-date">
                                    {{bankStatementSummary.LastTransactionDate | date:'yyyy-MM-dd'}}
                                </span>
                            </div>
                            <div class="custom-file">
                                <label for="last-transaction">Exclude debit transactions: </label>
                                <app-small-checkbox [(checked)]="excludeNegatives" (click)="toggleCheckbox()"></app-small-checkbox>
                            </div>
                            <div class="custom-file">
                                <label for="last-transaction">Import historical transactions: </label>
                                <app-small-checkbox [(checked)]="isHistorical" (click)="toggleCheckboxHistorical()"></app-small-checkbox>
                            </div>
                            <div class="custom-file" style="display: -webkit-box;" *ngIf="isHistorical">
                                <div class="column-report-date" *ngIf="isHistorical">
                                    <label for="date" style="padding-right: 10px;" class="fromTo-date">From</label>
                                    <input
                                        type="text"
                                        name="fromDate"
                                        class="input-date"
                                        #_fromDate="bsDatepicker"
                                        bsDatepicker
                                        placeholder="2010-01-01"
                                        placement="bottom"
                                        [value]="importDateParameter.fromDate | date:'yyyy-MM-dd'"
                                        (ngModel)="importDateParameter.fromDate=$event.target.value"
                                        (bsValueChange)="onValueStartChange($event)"
                                        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
                                    <i
                                        class="fa fa-calendar"
                                        (click)="_fromDate.toggle()"
                                        [attr.aria-expanded]="_fromDate.isOpen"></i>
                                </div>
                                <div class="column-report-date" *ngIf="isHistorical">
                                    <label for="date" style="padding-right: 10px;" class="fromTo-date">To</label>
                                    <input
                                        type="text"
                                        name="toDate"
                                        class="input-date"
                                        #_toDate="bsDatepicker"
                                        bsDatepicker
                                        placeholder="2010-01-01"
                                        placement="bottom"
                                        [value]="importDateParameter.toDate | date:'yyyy-MM-dd'"
                                        (ngModel)="importDateParameter.toDate=$event.target.value"
                                        (bsValueChange)="onValueEndChange($event)"
                                        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
                                    <i
                                        class="fa fa-calendar"
                                        (click)="_toDate.toggle()"
                                        [attr.aria-expanded]="_toDate.isOpen"></i>
                                </div>
                            </div>
                            <div class="custom-file" style="padding-top: 5px">
                                <input
                                    #inputAttachmentFile
                                    type="text"
                                    name="attachmentText"
                                    (click)="promptAttachment()"
                                    autocomplete="off"
                                    class="form-control"
                                    [(ngModel)]="attachment"
                                    required>
                                <input
                                    #inputUploadFile
                                    type="file"
                                    accept=".ofx"
                                    name="fileUpload" 
                                    id="attachmentUpload"
                                    class="custom-file-input"
                                    (change)="attachmentChange($event)"
                                    style="display: none">
                            </div>
                        </div>
                        <div class="col-sm-3 pl-5 pr-5">
                            <button class="btn btn-success form-control browse-file" (click)="promptAttachment()">Browse
                                to file</button>
                        </div>
                    </div>
                    <div class="row pl-3 m-0 pb-0">
                        <label class="text-danger" for="">{{message}}</label>
                    </div>
                </div>
                <!--#end .modal-body -->

                <div class="modal-footer">
                    <button class="btn btn-success" [class.disabled]="!isHistoricalValid" (click)="importFile()"
                        [disabled]="!isHistoricalValid">Import File</button>
                </div>
                <!--#end .modal-footer -->
            </div>
            <!--#end .modal-dialog -->
        </div>
        <!--#end .modal-content -->
    </div>
    <!--#end .modal -->
</form>

<app-import-error-message 
    title="No duplicate"
    [fromDate]="errorFromDate"
    [toDate]="errorToDate"
    [isVisible]="showErrorDuplicatePrompt"
    btnTextCancel="Cancel"
    btnTextContinue="Proceed"
    (cancel)="onHideErrorDuplicatePrompt()"
    (continue)="onProceedUploadStatement()"></app-import-error-message>