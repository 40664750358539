<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onDismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div><!--#end .modal-header -->

      <div class="modal-body form">
      <div class="container-fluid">
          <div class="row">
            <ul class="modal-header-list">
              <li *ngFor="let item of matters">{{item.data.Description}}</li>
            </ul>
          </div> <!-- #end .row -->
      </div> <!-- #end container-fluid -->

        <div class="row">
          <div class="form-group">
            <label for="choose-category">Choose category</label>
            <app-datalist-category 
              type="sub-category" 
              [list]="subCategories"
              [value]="fee.FurtherDescription"
              [category]="selectedSubCategory"
              (categoryChange)="onSubCategorySelect($event)" 
              (input)="onSubCategoryInput($event)">
            </app-datalist-category>
          </div><!--#end .form-grouop -->
        </div><!--#end .row -->

        <div class="row">
          <div class="form-group">
            <label 
              for="rate-type"
              >Rate type</label>
            <input 
              *ngIf="!canChangeRateType" 
              type="text" 
              [value]="fee.RateType" 
              readonly>
            <select 
              *ngIf="canChangeRateType" 
              id="rate-type" 
              (change)="onRateChange(fee)" 
              [(ngModel)]="fee.RateType">
              <option 
                *ngFor="let type of rateTypes" 
                [value]="type">{{type}}</option>
            </select>
            <!-- </div> #end .container-fluid -->
          </div><!--#end .form-group -->

          <div class="form-group" [ngSwitch]="fee.RateType">
            <label for="rate">Rate</label>
           <input
            *ngSwitchCase="'Non Billable'"
            type="text"
            currencyMask
            [options]="{ prefix: currencySymbol }"
            [ngModel]="fee.Rate"
            readonly>
            <input
            *ngSwitchDefault
              type="text"
              [ngModel]="fee.Rate | currency: currencySymbol: currencySymbol:'1.2-2'"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="fee.Rate=$event"
              (ngModelChange)="updateFeeTotal()"
               (blur)="onHourlyRateChange()"
              (click)="onHourlyRateClicked()"
              (click)="$event.target.select()"
              #rateinput
              pattern="[R\s]*[0-9,.]+">
          </div><!--#end .form-group -->

          <div class="form-group">
            <label for="date">Date</label>
            <input
            type="text"
            bsDatepicker
            [value]="fee.Date | date:'yyyy-MM-dd'"
            (ngModel)="fee.Date = $event.target.value"
            (bsValueChange)="onDateChange($event)"
            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
            placement="right">
             <!-- <app-date-picker 
              name="date" 
              ngDefaultControl format="YYYY-MM-DD" 
              (input)="onDateChange($event)" 
              [(ngModel)]="calendarItemDate"></app-date-picker> -->
          </div><!--#end .form-group -->
          
          <div class="form-group">
            <label for="quantity">Quantity
              <i 
                class="fa fa-question-circle clickable tooltip" 
                [class.active]="showQunatityToolTip" 
                (blur)="hideToolTip('quantity')"
                (click)="toggleToolTip('quantity')">
                <span 
                  class="tooltiptext">Quantity is not editable for Once Off & Disbursement rate types.</span>
              </i>
            </label>
            <input 
              *ngIf="fee.RateType !== 'Once Off'" 
              type="number" 
              class="quantity-input" 
              (input)="onUpdateQuantity()"
              [(ngModel)]="fee.Quantity" 
              [readOnly]="quantityIsReadOnly">
            <input 
              *ngIf="fee.RateType === 'Once Off'" 
              type="number" 
              class="quantity-input" 
              title="Quantity is not editable for Once Off & Disbursement rate types."
              [value]="fee.Quantity | number:'1.0-2'" 
              (focus)="showToolTip('quantity')" 
              (blur)="hideToolTip('quantity')"
              readOnly>
          </div><!--#end .form-group -->
        
        </div><!--#end .row -->

        <div class="row" *ngIf="fee.RateType === 'Disbursement'">
          <div class="form-group">
            <app-checkbox 
              class="clickable" 
              [checked]="fee.IsVatable" 
              (click)="toggleVatable()"></app-checkbox>
            <label 
              for="vatable" 
              class="clickable" 
              (click)="toggleVatable()">Vatable</label>
          </div><!--#end .form-group -->
        </div><!--#end .row -->
        
        <div class="row">
          <div class="form-group">
            <div class="fee-total">
              <span class="column-total-label">Amount</span>
              <span class="column-total-value">{{fee.Total | currency:currencySymbol}}</span>
            </div><!--#end .fee-total -->
          </div><!--#end .form-group -->
        </div><!--#end .row -->

      </div><!--#end .modal-body -->

      <div class="modal-footer">
        <button 
          class="btn btn-success btn-end" 
          [class.disabled]="!isSaveable" 
          (click)="onSave()" 
          [disabled]="!isSaveable">Add
          Fees
        </button>
      </div><!--#end .modal-footer -->

    </div><!--#end .modal-dialog -->
  </div><!--#end .modal-content -->
</div><!--#end .modal -->

<app-prompt-unsaved-changes-modal
  message="You have unsaved changes. Are you sure you want to leave?"
  [isVisible]="showUnsavedChangesPrompt"
  (cancel)="onHideUnsavedChangesPrompt()"
  (continue)="dismiss()"></app-prompt-unsaved-changes-modal>