import { Component, OnInit, Input } from '@angular/core';
import { StatementByAdvocate, LiveStatementService } from '../../../services/live-statement/live-statement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PATH_ADVOCATE_DETAIL } from '../../../services/appdata/app-config.service';
import { ApiService } from '../../../services/api.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';

@Component({
  selector: 'app-advocate-detail-report-table',
  templateUrl: './advocate-detail-report-table.component.html',
  styleUrls: ['./advocate-detail-report-table.component.scss']
})
export class AdvocateDetailReportTableComponent implements OnInit {

  title = 'Live statement';
  activeTab: string;
  data: StatementByAdvocate[];
  showReportTable: boolean;

  orderBy: string;
  sortAscending: boolean;
  orderingOptions = [];

  constructor(
    private router: Router,
    private liveStatementService: LiveStatementService,
    private route: ActivatedRoute,
    private api: ApiService,
    // private loadingService: LoadingService,
    private snackbarsService: SnackbarsService
  ) { } // end constructor()

  get grandTotal(): number {
    if (!this.data) {
      return 0;
    }
    let total = 0;

    this.data.forEach(item => {
      total += item.TotalOutstanding;
    });
    return total;
  }

  ngOnInit() {

    // this.loadingService.showOverlay();

    const snack: Snack = {
      label: 'Loading live statement..',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack).show();

    this.liveStatementService.getAdvocateStatementAdvocateSummary()
    .subscribe({ next: (advocates) => {
      if (advocates) {
        this.data = advocates.StatementByAdvocate;
      }
    },
    error: (error) => {
      // On error
      snack.label = 'An error loading live statement.';
      snack.type = SnackType.ERROR;
      this.snackbarsService.dismiss().make(snack).show();
      // this.loadingService.hideOverlay();
    },
    complete: () => {
      snack.label = 'Live statement loaded.';
      snack.type = SnackType.SUCCESS;
      this.snackbarsService.dismiss().make(snack).show();
      // this.loadingService.hideOverlay();
    }
  });

    this.setActiveTab('view-as-table');
    this.orderingOptions = [
      'advocate name',
      'email',
      'cell number',
      'current matters',
      'total outstanding'
    ];
  } // end ngOnInit()

  onPageClick(event) {

  } // end onPageClick()

  setActiveTab(tab: string) {
    this.activeTab = tab;
  } // end setActiveTab()

  viewReport(advocate: StatementByAdvocate) {
    if (advocate) {
      // this.router.navigate([PATH_ADVOCATE_DETAIL]);
      this.router.navigate([PATH_ADVOCATE_DETAIL, advocate.ClientId, advocate.ServiceProviderId]);
    }
  } // end viewReport()

  togglePopupMenu() {

  } // end togglePopupMenu()

  filterStatements() {

  } // end filterStatement()

} // end AdvocateDetailReportTableComponent{}
