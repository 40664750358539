<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
      <div class="modal-dialog">
  
        <div class="modal-header">
          <h2>{{title}}</h2>
          <button class="modal-dismiss" (click)="onCancel()">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!--#end .modal-header -->
  
        <div class="modal-body">
          <div class="container-fluid">
            <div class="col-md-3" class="form-group" style="display: flex; margin-top: 10px;" >
                <label style="width: 34%; font-size: 14px;"><b>Query / Dispute:</b></label>
                  <div class="input-group">
                    <select
                    *ngIf="disputStatues.Description !== 'Query Pending'"
                        class="form-control"
                        id="bank-name"
                        [(ngModel)]="disputStatues.Status">
                        <option [value]="null">Select Query / Dispute</option>
                        <option
                            *ngFor="let status of invoiceLineDisputeList"
                            [value]="status.value"
                            [selected]="status.value === disputStatues.Status">
                            {{status.label}}</option>
                    </select>

                    <input
                    *ngIf="disputStatues.Description === 'Query Pending'"
                          class="form-control"
                          type="text"
                          id="invoice-status-note"
                          [(ngModel)]="disputStatues.Status"
                          autocomplete="nocxua"
                          readonly>
                  </div>
              </div>
                  <div class="form-group" style="display: flex;">
                    <label style="width: 34%; font-size: 14px;" for="id-number"><b>Comment:</b></label>
                    <input
                    *ngIf="disputStatues.Description !== 'Query Pending'"
                      class="form-control"
                      type="text"
                      id="invoice-status-note"
                      [(ngModel)]="disputStatues.Note"
                      autocomplete="nocxua">

                      <input
                        *ngIf="disputStatues.Description === 'Query Pending'"
                        class="form-control"
                        type="text"
                        id="invoice-status-note"
                        [(ngModel)]="disputStatues.Note"
                        autocomplete="nocxua"
                        readonly>
                  </div>

                  <div class="col-md-3" class="form-group" style="display: flex;">
                    <label style="width: 34%; font-size: 14px;"><b>Status:</b></label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          type="text"
                          id="invoice-status-note"
                          [(ngModel)]="disputStatues.Description"
                          autocomplete="nocxua"
                          readonly>
                        <!-- <label>{{disputStatues.Description}}</label> -->
                    </div>
                  </div>
          </div> <!-- #end .container-fluid -->
        </div>
        <!--#end .modal-body -->
  
        <div class="modal-footer">
          <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
          <button
            class="btn btn-success"
            [class.disabled]="!isDirty"
            [disabled]="!isDirty"
            (click)="onLineDispute()">Save</button>
          <!-- <span></span> -->
          <!-- <span></span>
          <button class="btn btn-info" (click)="onConfirm()">{{btnTextConfirm}}</button> -->
        </div>
        <!--#end .modal-footer -->
  
      </div>
      <!--#end .modal-dialog -->
    </div>
    <!--#end .modal-content -->
  </div>
  <!--#end .modal -->