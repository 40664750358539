import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { SubscriptionGuard } from './subscription.guard';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  route: ActivatedRouteSnapshot;
  state: RouterStateSnapshot;

  constructor(
    private router: Router,
    private authService: MsalService,
    private subscriptionGuard: SubscriptionGuard
  ) {
  } // end constructor()

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    this.subscriptionGuard.canActivate(this.route, this.state);
    return true;
  } // end canActivate()

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(childRoute, state);
  } // end canActivateChild()

  isAuthenticate(): boolean {
    // Logic For Implementation MSAL Logic
    if (this.authService.instance.getAllAccounts().length > 0)
      return true;
    else
      return false;
  }

} // end AuthenticationGuard{}
