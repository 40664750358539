import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ViewChildren,
  QueryList
} from '@angular/core';

import { Router } from '@angular/router';
import { MattersService, Matter, DEFAULT_MATTER } from '../../../../services/matters/matters.service';

import {
  PATH_MATTERS,
  PATH_INVOICE_EMAIL,
  PATH_INVOICE_REVIEW,
  PATH_PROCESS_INVOICES,
  AUXCON_EMAIL_SUPPORT,
  PATH_ADVOCATE_INVOICE_PREVIEW,
  PATH_QUOTATION,
  PATH_QUOTATION_PREVIEW,
  PATH_DASHBOARD
} from '../../../../services/appdata/app-config.service';
import { UserProfileService, UserPermissions } from '../../../../services/user-profile/user-profile.service';
import { SnackbarsService, Snack, SnackType } from '../../../../services/messaging/snackbars/snackbars.service';
import { ToastService } from '../../../../services/messaging/toast/toast.service';
import { DataService } from '../../../../services/messaging/data/data.service';
import { MultipleFeesService, DEFAULT_MULTIPLE_FEE } from '../../../../services/multiple-fees/multiple-fees.service';
import { LoadingService } from '../../../../services/messaging/loading/loading.service';
import { InvoicesService, Invoice, DEFAULT_INVOICE } from '../../../../services/invoices/invoices.service';
import {
  DEFAULT_LOADINGOVERLAY_OPTIONS,
  LoadingOverlayModalOptions } from '../../../../components/modals/loading-overlay-modal/loading-overlay-modal.component';
import * as moment from 'moment';
import {
  ActivityLogs,
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogsService} from '../../../../services/activity-logs/activity-logs.service';
  import {
    MatterQuotationDTO,
    QuotationService,
    MatterQuotationHandle,
    DEFAULT_MATTER_QUOTATION_DTO
  } from '../../../../services/quotation/quotation.service';
import { DocumentServiceService, FolderData } from '../../../../services/documents/document-service.service';

const MULTIPLE_MATTERS_MENU_ID = -1;
const FIELD_DATE                = 'date';
const FIELD_MATTER_DETAILS      = 'matter details';
const FIELD_LAW_FIRM            = 'law firm';
const FIELD_REFERENCE           = 'reference';
const FIELD_CLIENT              = 'client';
const FIELD_ATTORNEY_REFERENCE  = 'attorney reference';
const FIELD_WIP                 = 'wip';
const FIELD_STATUS              = 'status';

interface MatterHandle {
  data: Matter;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

@Component({
  selector: 'app-briefs-and-matters-table',
  templateUrl: './briefs-and-matters-table.component.html',
  styleUrls: ['./briefs-and-matters-table.component.scss']
})
export class BriefsAndMattersTableComponent implements OnInit {
  activeTab: string;
  searchValue: string;
  searchOptions: any;
  selectedMattersIDs: any;
  matters: Matter[];
  matterHandles: MatterHandle[] = [];
  activeMatterHandles: MatterHandle;
  unfilteredMatters: any;
  selectedMatter: any;
  selectedMatters: any = null;
  selectedQuotations: any = null;
  selectedMatterHandles: MatterHandle[] = [];
  briefs: any;
  isLoading: boolean;
  sortBy: string;
  sortAscending: boolean;
  allSelected: boolean;
  selectAllQuotation: boolean;
  selectAllAcceptedQuotation: boolean;
  showDeleteMatterModal: boolean;
  showdeleteMultipleMatterModal: boolean;
  showMultiplteMattersPopupMenu: boolean;
  showMultiplteHistoricalsPopupMenu: boolean;
  showAddMultipleFeesModal: boolean;
  showProformaInvoice: boolean;
  menuIDs: any = {};
  menus: any = [];
  openMenuID: number;
  permissions: UserPermissions;
  orderBy = '';
  orderByQuotation = '';
  orderingOptions: string[] = [];
  onShowMultipleMatterWithNoFeesChangesPrompt = false;
  // manualAcceptReject: string;
  showQuotationAcceptRejectReason: boolean;
  acceptedRejectedMatterQuotation: MatterQuotationDTO = Object.assign({}, DEFAULT_MATTER_QUOTATION_DTO);
  invoice: Invoice = Object.assign({}, DEFAULT_INVOICE);
  // allSelected: boolean;
  options: LoadingOverlayModalOptions = Object.assign({}, DEFAULT_LOADINGOVERLAY_OPTIONS);

  sortDate: boolean;
  sortMatter: boolean;
  sortLawfirm: boolean;
  sortAttorney: boolean;
  sortWIP: boolean;
  sortStatus: boolean;

  // Multiple Invoice Popup
  errorTitle: string;
  errorMessage: string;

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  //#region  MatterQuotation
  sortQuotationDate: boolean;
  sortQuotationMatter: boolean;
  sortQuotationLawfirm: boolean;
  sortQuotationAttorney: boolean;
  sortQuotationReference: boolean;
  sortQuotationWIP: boolean;
  sortQuotationStatus: boolean;

  showDeleteQuotationModal: boolean;
  orderingOptionsQuotation: string[] = [];
  sortByQuotation: string;
  previousSortByQuotation: string;
  searchTermQuotation = '';
  sortAscendingQuotation: boolean;
  matterQuotation: MatterQuotationDTO[] = [];
  matterQuotationHandle: MatterQuotationHandle[] = [];
  selectedMatterQuotation: MatterQuotationDTO;
  showdeleteMultipleQuotationModal: boolean;
  //#endregion MatterQuotation

  //#region  Historical Data
  orderHistoricalBy = '';
  previousSortHistoricalBy: string;
  sortHistoricalDate: boolean;
  sortHistoricalMatter: boolean;
  sortHistoricalLawfirm: boolean;
  sortHistoricalAttorney: boolean;
  sortHistoricalWIP: boolean;
  sortHistoricalStatus: boolean;
  sortHistoricalBy: string;
  sortHistoricalAscending: boolean;
  showImportHistorical: boolean;
  showImportAuxconData: boolean;
  historicalData: Matter[] = [];
  historicalDataHandles: MatterHandle[] = [];
  //#endregion Historical Data

  files: any[];
  folders: FolderData[];
  onShowImportHistoricalDataModal = false;

  @ViewChild('table') table: ElementRef;
  @ViewChild('deleteMatterModal') deleteMatterModal: ElementRef;
  @ViewChild('deleteMultipleMatterModal') deleteMultipleMatterModal: ElementRef;
  @ViewChild('deleteMultipleQuotationModal') deleteMultipleQuotationModal: ElementRef;
  @ViewChild('proformaInvoice') proformaInvoice: ElementRef;
  @ViewChildren('checkbox') checkboxes: QueryList<ElementRef>;
  @ViewChildren('popupMenu') popupMenus: QueryList<ElementRef>;
  @ViewChildren('popupQuotationMenu') popupQuotationMenus: QueryList<ElementRef>;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  showDeleteMatterAlert: boolean;
  supportEmailLink = AUXCON_EMAIL_SUPPORT;

  get requestedQuotationCount(): number {
    return this.matterQuotation.filter(matter => matter.QuotationStateStatus === 'Created' || matter.QuotationStateStatus === 'Pending' ||
      matter.QuotationStateStatus === 'Rejected' || !matter.QuotationStateStatus ||
      matter.QuotationStateStatus === 'Manual Rejected').length;
  } // end requestedQuotationCount()

  get acceptedQuotationCount(): number {
    return this.matterQuotation.filter(matter => matter.QuotationStateStatus === 'Accepted' ||
      matter.QuotationStateStatus === 'Manual Accepted').length;
  } // end acceptedQuotationCount()

  get folderCount(): number {
    console.log('COUNT FOLDERS: ', this.folders, this.folders.length)
    return this.folders && this.folders.length;
  } // end folderCount()

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  private previousSortBy: string;

  get signInAsPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER)
  //  || Boolean(this.userProfileService.userProfile.serviceProviderID === 40922);
  }  // end signInAsPracticeManager()

  get importCaptureFeeUsers(): boolean {
      return Boolean(this.userProfileService.selectedUserProfile && this.userProfileService.selectedUserProfile.serviceProviderID === 9243) ||
             Boolean(this.userProfileService.selectedUserProfile && this.userProfileService.selectedUserProfile.serviceProviderID === 32796) ||
             Boolean(this.userProfileService.selectedUserProfile && this.userProfileService.selectedUserProfile.serviceProviderID === 40922) ||
             Boolean(this.userProfileService.userProfile.serviceProviderID === 9243) ||
             Boolean(this.userProfileService.userProfile.serviceProviderID === 32796) 
            || Boolean(this.userProfileService.userProfile.serviceProviderID === 40922)
  } // end importCaptureFeeUsers()

  get internalAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator) &&
      !Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY)
      && Boolean(this.userProfileService.userProfile.serviceProviderID === 9091 ||
        this.userProfileService.userProfile.serviceProviderID === 29096 ||
        this.userProfileService.userProfile.serviceProviderID === 21647);
  } // end internalAssistant()

  constructor(
    private router: Router,
    private dataService: DataService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private mattersService: MattersService,
    private userProfile: UserProfileService,
    private invoiceService: InvoicesService,
    private quotationService: QuotationService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private documentService: DocumentServiceService,
    private activityLogService: ActivityLogsService,
    private multipleFeesService: MultipleFeesService,
  ) {
    this.isLoading = true;
    this.sortAscending = false;
    this.activeTab = 'current-matters';
    this.userProfileService.currentOrRequestedTab = 'current-matters';
  } // end constructor()

  ngOnInit() {
    this.options.message = 'Loading ...';
    this.loadingService.showOverlay(this.options);

    //#region QuotationMatter
    this.orderingOptionsQuotation = [
      'date',
      'matter details',
      'attorney reference',
      'wip',
      'status'
    ];
    // this.getQuotationMatters();
    //#endregion QuotationMatter
    this.activeTab = 'current-matters';
    localStorage.setItem('selectedMatterTab', this.activeTab);
    this.searchValue = '';
    if (this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY) {
      this.orderingOptions = [
        'date',
        'matter details',
        'client',
        'reference',
        'wip',
        'status'
      ];
    } else {
      this.orderingOptions = [
        'date',
        'matter details',
        'law firm',
        'attorney reference',
        'wip',
        'status'
      ];
    }

    this.searchOptions = {
      keys: [
        'ServiceDate',
        'Description',
        'InternalRefNo',
        'ClientRefNo',
        'Status'
      ],
      id: 'ServiceID'
    };
    this.selectedMattersIDs = [];
    this.sortBy = 'date';
    if (this.activeTab === 'current-matters') {
      this.isLoading = true;
      this.mattersService.getMatters().subscribe({ next: (matters) => {
        // On next
        this.matters = matters;
        this.matters.forEach(matter => {
          if (matter.Status === 'Quotation') {
          } else {
            this.matterHandles.push({
              data: matter,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          }
        });

        // Sort
        this.toggleSort(this.sortBy);
      },
      error: (error) => {
        // On error
        // console.log('Something went wrong: ', error);
      },
      complete: () => {
        // On complete
        this.isLoading = false; // Ready to display
        this.loadFolders();
        this.loadingService.hideOverlay();
      }
    });
    } else if (this.activeTab === 'quotation') {
      // TODO: Load briefs
    }

    this.allSelected = false;
    if (this.userProfileService.userProfile.isImpersonator) {
     this.permissions = this.userProfileService.selectedUserProfile.permissions;
    } else {
      this.permissions = this.userProfile.userProfile.permissions;
    }
    this.getQuotationMatters();
  } // end ngOnInit()

  getArbitrationColor(value: boolean): string{
    if (value === true) {
      return '#cf624f';
    }
  } // end getArbitrationColor()

   getCurrentMatters() {
    this.isLoading = true;
    this.matterHandles = [];
    this.matters = [];
      this.mattersService.getMatters().subscribe({ next: (matters) => {
        // On next
        this.matters = matters;
        this.matters.forEach(matter => {
          if (matter.Status === 'Quotation') {
          } else {
            this.matterHandles.push({
              data: matter,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          }
        });

        // Sort
        this.toggleSort(this.sortBy);
      },
      error: (error) => {
        // On error
        // console.log('Something went wrong: ', error);
      },
      complete: () => {
        // On complete
        this.isLoading = false; // Ready to display
        this.loadingService.hideOverlay();
      }
    });
  } // end getCurrentMatters()

  get hasSelectedMatters(): boolean {
    return Boolean(this.matterHandles.find(matter => matter.state.isSelected === true));
  } // end hasSelectedMatters()

  get hasSelectedHistoricalInvoices(): boolean {
    return Boolean(this.historicalDataHandles.find(matter => matter.state.isSelected === true));
  } // end hasSelectedHistoricalInvoices()

  get hasSelectedQuotations(): boolean {
    return Boolean(this.matterQuotationHandle.find(matter => matter.state.isSelected === true));
  } // end hasSelectedQuotations()

  get isAttorney(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY) ||
            Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
  } // end profileType()

  toggleSort(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      // toggle ascending
      this.sortAscending = !this.sortAscending;
    }
    switch (this.sortBy) {
      case 'date':
        this.matterHandles.sort((a, b) => {
          if (this.sortAscending) {
            this.sortDate = true;
            this.sortLawfirm = false;
            this.sortMatter = false;
            this.sortAttorney = false;
            this.sortWIP = false;
            this.sortStatus = false;
            return a.data.ServiceDate.toUpperCase().localeCompare(b.data.ServiceDate.toUpperCase());
          }
          this.sortDate = false;
          return b.data.ServiceDate.toUpperCase().localeCompare(a.data.ServiceDate.toUpperCase());
        });
        break;

      case 'matter-details':
        this.matterHandles.sort((a, b) => {
          if (this.sortAscending) {
            this.sortMatter = true;
            this.sortLawfirm = false;
            this.sortDate = false;
            this.sortAttorney = false;
            this.sortWIP = false;
            this.sortStatus = false;
            return a.data.Description.toUpperCase().localeCompare(b.data.Description.toUpperCase());
          }
          this.sortMatter = false;
          return b.data.Description.toUpperCase().localeCompare(a.data.Description.toUpperCase());
        });
        break;

      case 'law-firm':
        this.matterHandles.sort((a, b) => {
          if (this.sortAscending) {
            this.sortLawfirm = true;
            this.sortDate = false;
            this.sortMatter = false;
            this.sortAttorney = false;
            this.sortWIP = false;
            this.sortStatus = false;
            return a.data.InternalRefNo.toUpperCase().localeCompare(b.data.InternalRefNo.toUpperCase());
          }
          this.sortLawfirm = false;
          return b.data.InternalRefNo.toUpperCase().localeCompare(a.data.InternalRefNo.toUpperCase());
        });
        break;

      case 'attorney-reference':
        this.matterHandles.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAttorney = true;
            this.sortLawfirm = false;
            this.sortDate = false;
            this.sortMatter = false;
            this.sortWIP = false;
            this.sortStatus = false;
            return a.data.ClientRefNo.toUpperCase().localeCompare(b.data.ClientRefNo.toUpperCase());
          }
          this.sortAttorney = false;
          return b.data.ClientRefNo.toUpperCase().localeCompare(a.data.ClientRefNo.toUpperCase());
        });
        break;

        case 'due':
        this.matterHandles.sort((a, b) => {
          if (this.sortAscending) {
            this.sortWIP = true;
            this.sortLawfirm = false;
            this.sortDate = false;
            this.sortMatter = false;
            this.sortAttorney = false;
            this.sortStatus = false;
            return a.data.Total - b.data.Total;
          }
          this.sortWIP = false;
          return b.data.Total - a.data.Total;
        });
        break;

      case 'status':
        this.matterHandles.sort((a, b) => {
          if (this.sortAscending) {
            this.sortStatus = true;
            this.sortLawfirm = false;
            this.sortDate = false;
            this.sortMatter = false;
            this.sortAttorney = false;
            this.sortWIP = false;
            return a.data.Status.toUpperCase().localeCompare(b.data.Status.toUpperCase());
          }
          this.sortStatus = false;
          return b.data.Status.toUpperCase().localeCompare(a.data.Status.toUpperCase());
        });
        break;
    }
    this.previousSortBy = this.sortBy;
  } // end sort()

  toggleHistoricalSort(sortBy: string) {
    this.sortHistoricalBy = sortBy;
    if (this.sortHistoricalBy === this.previousSortHistoricalBy) {
      // toggle ascending
      this.sortHistoricalAscending = !this.sortHistoricalAscending;
    }
    switch (this.sortHistoricalBy) {
      case 'date':
        this.historicalDataHandles.sort((a, b) => {
          if (this.sortAscending) {
            this.sortHistoricalDate = true;
            this.sortHistoricalLawfirm = false;
            this.sortHistoricalMatter = false;
            this.sortHistoricalAttorney = false;
            this.sortHistoricalWIP = false;
            this.sortHistoricalStatus = false;
            return a.data.ServiceDate.toUpperCase().localeCompare(b.data.ServiceDate.toUpperCase());
          }
          this.sortHistoricalDate = false;
          return b.data.ServiceDate.toUpperCase().localeCompare(a.data.ServiceDate.toUpperCase());
        });
        break;

      case 'matter-details':
        this.historicalDataHandles.sort((a, b) => {
          if (this.sortHistoricalAscending) {
            this.sortHistoricalMatter = true;
            this.sortHistoricalLawfirm = false;
            this.sortHistoricalDate = false;
            this.sortHistoricalAttorney = false;
            this.sortHistoricalWIP = false;
            this.sortHistoricalStatus = false;
            return a.data.Description.toUpperCase().localeCompare(b.data.Description.toUpperCase());
          }
          this.sortHistoricalMatter = false;
          return b.data.Description.toUpperCase().localeCompare(a.data.Description.toUpperCase());
        });
        break;

      case 'law-firm':
        this.historicalDataHandles.sort((a, b) => {
          if (this.sortHistoricalAscending) {
            this.sortHistoricalLawfirm = true;
            this.sortHistoricalDate = false;
            this.sortHistoricalMatter = false;
            this.sortHistoricalAttorney = false;
            this.sortHistoricalWIP = false;
            this.sortHistoricalStatus = false;
            return a.data.InternalRefNo.toUpperCase().localeCompare(b.data.InternalRefNo.toUpperCase());
          }
          this.sortHistoricalLawfirm = false;
          return b.data.InternalRefNo.toUpperCase().localeCompare(a.data.InternalRefNo.toUpperCase());
        });
        break;

      case 'attorney-reference':
        this.historicalDataHandles.sort((a, b) => {
          if (this.sortHistoricalAscending) {
            this.sortHistoricalAttorney = true;
            this.sortHistoricalLawfirm = false;
            this.sortHistoricalDate = false;
            this.sortHistoricalMatter = false;
            this.sortHistoricalWIP = false;
            this.sortHistoricalStatus = false;
            return a.data.ClientRefNo.toUpperCase().localeCompare(b.data.ClientRefNo.toUpperCase());
          }
          this.sortHistoricalAttorney = false;
          return b.data.ClientRefNo.toUpperCase().localeCompare(a.data.ClientRefNo.toUpperCase());
        });
        break;

        case 'due':
        this.historicalDataHandles.sort((a, b) => {
          if (this.sortHistoricalAscending) {
            this.sortHistoricalWIP = true;
            this.sortHistoricalLawfirm = false;
            this.sortHistoricalDate = false;
            this.sortHistoricalMatter = false;
            this.sortHistoricalAttorney = false;
            this.sortHistoricalStatus = false;
            return a.data.Total - b.data.Total;
          }
          this.sortHistoricalWIP = false;
          return b.data.Total - a.data.Total;
        });
        break;

      case 'status':
        this.historicalDataHandles.sort((a, b) => {
          if (this.sortHistoricalAscending) {
            this.sortHistoricalStatus = true;
            this.sortHistoricalLawfirm = false;
            this.sortHistoricalDate = false;
            this.sortHistoricalMatter = false;
            this.sortHistoricalAttorney = false;
            this.sortHistoricalWIP = false;
            return a.data.Status.toUpperCase().localeCompare(b.data.Status.toUpperCase());
          }
          this.sortHistoricalStatus = false;
          return b.data.Status.toUpperCase().localeCompare(a.data.Status.toUpperCase());
        });
        break;
    }
    this.previousSortHistoricalBy = this.sortHistoricalBy;
  } // end toggleHistoricalSort()

  toggleQuotationSort(sortBy: string) {
    this.sortByQuotation = sortBy;
    if (this.sortByQuotation === this.previousSortByQuotation) {
      // toggle ascending
      this.sortAscendingQuotation = !this.sortAscendingQuotation;
    }
    switch (this.sortByQuotation) {
      case 'date':
        this.matterQuotationHandle.sort((a, b) => {
          if (this.sortAscendingQuotation) {
            this.sortQuotationDate = true;
            this.sortQuotationLawfirm = false;
            this.sortQuotationMatter = false;
            this.sortQuotationAttorney = false;
            this.sortQuotationWIP= false;
            this.sortQuotationStatus= false;
            return a.data.MatterQuotationDate.toUpperCase().localeCompare(b.data.MatterQuotationDate.toUpperCase());
          }
          this.sortQuotationDate = false;
          return b.data.MatterQuotationDate.toUpperCase().localeCompare(a.data.MatterQuotationDate.toUpperCase());
        });
        break;

      case 'matter-details':
        this.matterQuotationHandle.sort((a, b) => {
          if (this.sortAscendingQuotation) {
            this.sortQuotationDate = false;
            this.sortQuotationLawfirm = false;
            this.sortQuotationMatter = true;
            this.sortQuotationAttorney = false;
            this.sortQuotationWIP= false;
            this.sortQuotationStatus= false;
            return a.data.Description.toUpperCase().localeCompare(b.data.Description.toUpperCase());
          }
          this.sortQuotationMatter = false;
          return b.data.Description.toUpperCase().localeCompare(a.data.Description.toUpperCase());
        });
        break;

      case 'law-firm':
        this.matterQuotationHandle.sort((a, b) => {
          if (this.sortAscendingQuotation) {
            this.sortQuotationDate = false;
            this.sortQuotationLawfirm = true;
            this.sortQuotationMatter = false;
            this.sortQuotationAttorney = false;
            this.sortQuotationWIP= false;
            this.sortQuotationStatus= false;
            return a.data.LawFirm.toUpperCase().localeCompare(b.data.LawFirm.toUpperCase());
          }
          this.sortQuotationLawfirm = false;
          return b.data.LawFirm.toUpperCase().localeCompare(a.data.LawFirm.toUpperCase());
        });
        break;

      case 'attorney-reference':
        this.matterQuotationHandle.sort((a, b) => {
          if (this.sortAscendingQuotation) {
            this.sortQuotationDate = false;
            this.sortQuotationLawfirm = false;
            this.sortQuotationMatter = false;
            this.sortQuotationAttorney = true;
            this.sortQuotationWIP= false;
            this.sortQuotationStatus= false;
            return a.data.ClientRefNo.toUpperCase().localeCompare(b.data.ClientRefNo.toUpperCase());
          }
          this.sortQuotationAttorney = false;
          return b.data.ClientRefNo.toUpperCase().localeCompare(a.data.ClientRefNo.toUpperCase());
        });
        break;

        case 'due':
        this.matterQuotationHandle.sort((a, b) => {
          if (this.sortAscendingQuotation) {
            this.sortQuotationDate = false;
            this.sortQuotationLawfirm = false;
            this.sortQuotationMatter = false;
            this.sortQuotationAttorney = false;
            this.sortQuotationWIP= true;
            this.sortQuotationStatus= false;
            return a.data.Total - b.data.Total;
          }
          this.sortQuotationWIP = false;
          return b.data.Total - a.data.Total;
        });
        break;

      case 'status':
        this.matterQuotationHandle.sort((a, b) => {
          if (this.sortAscendingQuotation) {
            this.sortQuotationDate = false;
            this.sortQuotationLawfirm = false;
            this.sortQuotationMatter = false;
            this.sortQuotationAttorney = false;
            this.sortQuotationWIP= false;
            this.sortQuotationStatus= false;
            return a.data.QuotationStateStatus.toUpperCase().localeCompare(b.data.QuotationStateStatus.toUpperCase());
          }
          this.sortQuotationStatus = false;
          return b.data.QuotationStateStatus.toUpperCase().localeCompare(a.data.QuotationStateStatus.toUpperCase());
        });
        break;
    }
    this.previousSortByQuotation = this.sortByQuotation;
  } // end toggleQuotationSort()

  setActiveTab(tab: string) {
    this.activeTab = tab;
    this.userProfileService.currentOrRequestedTab = tab;
    localStorage.setItem('selectedMatterTab', tab);
    
    this.selectAllQuotation = false;
    switch (tab) {
      case 'quotation':
        this.requestedQuotation();
        // console.log('QUOTATION: ', this.matterQuotationHandle);
      break;

      case 'accepted-quotation':
        this.acceptedQuotation();
      break;

      case 'take-on-data':
        this.getHistoricalDataFromApi();
      break;

      case 'historical-data':
        this.loadFolders();
      break;
    }
  } // end setActiveTab()

  orderMatters() {
    // switch (this.activeTab) { // Determines which list between current matter & briefs to order.

    // }
    switch (this.orderBy) { // Determines the field to order the current matter by

      case FIELD_DATE:
      this.matterHandles.sort((a, b) => {
        if (this.sortAscending) {
          return a.data
          .ServiceDate.toUpperCase().localeCompare(b.data.ServiceDate.toUpperCase());
        }

        return b.data.ServiceDate.toUpperCase().localeCompare(a.data.ServiceDate.toUpperCase());
      });
      break;

      case FIELD_MATTER_DETAILS:
      this.matterHandles.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Description.toUpperCase().localeCompare(b.data.Description.toUpperCase());
        }

        return b.data.Description.toUpperCase().localeCompare(a.data.Description.toUpperCase());
      });
      break;

      case FIELD_LAW_FIRM:
      this.matterHandles.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.InternalRefNo.toUpperCase().localeCompare(b.data.InternalRefNo.toUpperCase());
        }

        return b.data.InternalRefNo.toUpperCase().localeCompare(a.data.InternalRefNo.toUpperCase());
      });
      break;

      case FIELD_CLIENT:
      this.matterHandles.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.InternalRefNo.toUpperCase().localeCompare(b.data.InternalRefNo.toUpperCase());
        }

        return b.data.InternalRefNo.toUpperCase().localeCompare(a.data.InternalRefNo.toUpperCase());
      });
      break;

      case FIELD_ATTORNEY_REFERENCE:
      this.matterHandles.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.ClientRefNo.toUpperCase().localeCompare(b.data.ClientRefNo.toUpperCase());
        }

        return b.data.ClientRefNo.toUpperCase().localeCompare(a.data.ClientRefNo.toUpperCase());
      });
      break;

      case FIELD_REFERENCE:
      this.matterHandles.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.ClientRefNo.toUpperCase().localeCompare(b.data.ClientRefNo.toUpperCase());
        }

        return b.data.ClientRefNo.toUpperCase().localeCompare(a.data.ClientRefNo.toUpperCase());
      });
      break;

      case FIELD_WIP:
      this.matterHandles.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Total - b.data.Total;
        }

        return b.data.Total - a.data.Total;
      });
      break;

      case FIELD_STATUS:
      this.matterHandles.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Status.toUpperCase().localeCompare(b.data.Status.toUpperCase());
        }

        return b.data.Status.toUpperCase().localeCompare(a.data.Status.toUpperCase());
      });
      break;
    }
  } // end orderMatters()

  orderHistorical() {
    switch (this.orderHistoricalBy) {

      case FIELD_DATE:
      this.historicalDataHandles.sort((a, b) => {
        if (this.sortHistoricalAscending) {
          return a.data
          .ServiceDate.toUpperCase().localeCompare(b.data.ServiceDate.toUpperCase());
        }

        return b.data.ServiceDate.toUpperCase().localeCompare(a.data.ServiceDate.toUpperCase());
      });
      break;

      case FIELD_MATTER_DETAILS:
      this.historicalDataHandles.sort((a, b) => {
        if (this.sortHistoricalAscending) {
          return a.data.Description.toUpperCase().localeCompare(b.data.Description.toUpperCase());
        }

        return b.data.Description.toUpperCase().localeCompare(a.data.Description.toUpperCase());
      });
      break;

      case FIELD_LAW_FIRM:
      this.historicalDataHandles.sort((a, b) => {
        if (this.sortHistoricalAscending) {
          return a.data.InternalRefNo.toUpperCase().localeCompare(b.data.InternalRefNo.toUpperCase());
        }

        return b.data.InternalRefNo.toUpperCase().localeCompare(a.data.InternalRefNo.toUpperCase());
      });
      break;

      case FIELD_CLIENT:
      this.historicalDataHandles.sort((a, b) => {
        if (this.sortHistoricalAscending) {
          return a.data.InternalRefNo.toUpperCase().localeCompare(b.data.InternalRefNo.toUpperCase());
        }

        return b.data.InternalRefNo.toUpperCase().localeCompare(a.data.InternalRefNo.toUpperCase());
      });
      break;

      case FIELD_ATTORNEY_REFERENCE:
      this.historicalDataHandles.sort((a, b) => {
        if (this.sortHistoricalAscending) {
          return a.data.ClientRefNo.toUpperCase().localeCompare(b.data.ClientRefNo.toUpperCase());
        }

        return b.data.ClientRefNo.toUpperCase().localeCompare(a.data.ClientRefNo.toUpperCase());
      });
      break;

      case FIELD_REFERENCE:
      this.historicalDataHandles.sort((a, b) => {
        if (this.sortHistoricalAscending) {
          return a.data.ClientRefNo.toUpperCase().localeCompare(b.data.ClientRefNo.toUpperCase());
        }

        return b.data.ClientRefNo.toUpperCase().localeCompare(a.data.ClientRefNo.toUpperCase());
      });
      break;

      case FIELD_WIP:
      this.historicalDataHandles.sort((a, b) => {
        if (this.sortHistoricalAscending) {
          return a.data.Total - b.data.Total;
        }

        return b.data.Total - a.data.Total;
      });
      break;

      case FIELD_STATUS:
      this.historicalDataHandles.sort((a, b) => {
        if (this.sortHistoricalAscending) {
          return a.data.Status.toUpperCase().localeCompare(b.data.Status.toUpperCase());
        }

        return b.data.Status.toUpperCase().localeCompare(a.data.Status.toUpperCase());
      });
      break;
    }
  } // end orderHistorical()

  filterMatters() {
    if (this.searchValue) {
      this.matterHandles.forEach(matter => {
        if (
          !(matter.data.ServiceDate.match(new RegExp(this.searchValue, 'gi'))) &&
          !(matter.data.Description.match(new RegExp(this.searchValue, 'gi'))) &&
          !(matter.data.InternalRefNo.match(new RegExp(this.searchValue, 'gi'))) &&
          !(matter.data.ClientRefNo.match(new RegExp(this.searchValue, 'gi'))) &&
          !(matter.data.Status.match(new RegExp(this.searchValue, 'gi')))
        ) {
          matter.state.isVisible = false;
        } else {
          matter.state.isVisible = true;
        }
      });
    } else {
      this.matterHandles.forEach(matter => {
        matter.state.isVisible = true;
      });
    }
  } // end filterMatters($event)

  filterHistorical() {
    if (this.searchValue) {
      this.historicalDataHandles.forEach(matter => {
        if (
          !(matter.data.ServiceDate.match(new RegExp(this.searchValue, 'gi'))) &&
          !(matter.data.Description.match(new RegExp(this.searchValue, 'gi'))) &&
          !(matter.data.InternalRefNo.match(new RegExp(this.searchValue, 'gi'))) &&
          !(matter.data.ClientRefNo.match(new RegExp(this.searchValue, 'gi'))) &&
          !(matter.data.Status.match(new RegExp(this.searchValue, 'gi')))
        ) {
          matter.state.isVisible = false;
        } else {
          matter.state.isVisible = true;
        }
      });
    } else {
      this.historicalDataHandles.forEach(matter => {
        matter.state.isVisible = true;
      });
    }
  } // end filterHistorical()

  clearSearch() {
    this.searchValue = null;
    this.matterHandles.forEach(matter => {
      matter.state.isVisible = true;
    });
  }
  openModal(modal: string) {
    if (this.hasSelectedMatters) {
      switch (modal) {
        case 'add-fee-to-matters':
          this.selectedMatters = this.matterHandles.filter(s => s.state.isSelected === true);
          this.showAddMultipleFeesModal = !this.showAddMultipleFeesModal;
          break;

        case 'proforma-invoice':
        break;
      }
    } else {
      const snack: Snack = {
        label: 'Please select matters to continue',
        action: null
      };
      this.snackbarsService.make(snack, 5000).show();
    }
  } // end openModal()

  closeModal(modal: string) {
    switch (modal) {
      case 'delete-matter-modal':
        this.showDeleteMatterModal = false;
        break;

        case 'delete-multiple-matter-modal':
          if(this.activeTab === 'take-on-data')
          {
            this.getHistoricalDataFromApi();
          }
        this.showdeleteMultipleMatterModal = false;
        this.toggleSelectAllForce(false);
        break;

      case 'add-multiple-fees-modal':
        this.showAddMultipleFeesModal = false;
        break;

      case 'proforma-invoice':
        this.showProformaInvoice = false;
      break;

      case 'delete-quotation':
        this.showDeleteQuotationModal = false;
      break;

      case 'delete-multiple-quotation-modal':
        this.showdeleteMultipleQuotationModal = false;
      break;

      case 'quotation-note':
        this.showQuotationAcceptRejectReason = false;
      break;

      case 'historical':
        this.getHistoricalDataFromApi();
        this.showImportHistorical = false;
      break;

      case 'auxcon-data':
        this.getCurrentMatters();
        this.showImportAuxconData = false;
      break;
    }
  } // end closeModal()

  togglePopupMenu(matter: MatterHandle = null) {
    matter.state.isOpen = !matter.state.isOpen;
    if (this.activeMatterHandles &&
      this.activeMatterHandles !== matter) {
        this.activeMatterHandles.state.isOpen = false;
      }
      this.activeMatterHandles = matter;
  } // end togglePopupMenu{}

  togglePopupMultipleMenu() {
    this.showMultiplteMattersPopupMenu = !this.showMultiplteMattersPopupMenu;
  } // end togglePopupMultipleMenu()

  togglePopupMultipleHistoricalMenu() {
    this.showMultiplteHistoricalsPopupMenu = !this.showMultiplteHistoricalsPopupMenu;
  } // end togglePopupMultipleHistoricalMenu()

  onDismissMenu(matter: MatterHandle) {
    matter.state.isOpen = false;
    this.activeMatterHandles = null;
  } // end onDismiss()

  onDismissMultipleMatterMenu() {
    if (this.showMultiplteMattersPopupMenu) {
      this.showMultiplteMattersPopupMenu = false;
    }
  } // end onDismissMultipleMatterMenu()

  onDismissMultipleHistoricalMenu() {
    if (this.showMultiplteHistoricalsPopupMenu) {
      this.showMultiplteHistoricalsPopupMenu = false;
    }
  } // end onDismissMultipleHistoricalMenu()

  /**
   * Closes all popup menus with the exception of the give MatterHandle.
   * @param {MatterHandle} exception The matterHandle that should not be
   * affected. If null is passed, all menues are cloesed. DEFAULT = null.
   */
  // dismissAllPopupMenus(exception: MatterHandle = null) {
  //   this.matterHandles.forEach(matter => {
  //     if (exception &&
  //         exception.data.ServiceID === matter.data.ServiceID) {
  //       return;
  //     }
  //     // matter.state.isOpen = false;
  //   });
  // } // end dismissAllPopupMenus()

  promptDeleteMatter(matter: Matter) {
    this.selectedMatter = matter;
    this.showDeleteMatterModal  = true;
  } // end deleteMatter()

  deleteMatter(event: any) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Deleting matter...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete matter';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-Dashboard)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Services, Invoice';
    this.activityLog.JsonData = JSON.stringify(event.matter);
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    this.mattersService
        .deleteMatter(event.matter.ServiceID, event.comments)
        .subscribe({
          next: (next) => {
            // On next
          },
          error: (error) => {
            // On error
            const msg = 'Error deleting matter.';
            // console.error(msg, error);
            this.showDeleteMatterErrorAlert();
            this.loadingService.hideOverlay();
          },
          complete: () => {
            // On success
            const filtered = this.matterHandles.filter(matter => {
              return (matter.data.ServiceID !== event.matter.ServiceID);
            });
            if(this.activeTab === 'take-on-data') {
              this.getHistoricalDataFromApi();
            }
            this.matterHandles = filtered;
            snack.label = 'Matter deleted.';
            this.snackbarsService.snackbarComponent.dismiss();
            this.snackbarsService.snackbarComponent.make(snack).show();
            this.loadingService.hideOverlay();
          }
        });
  } // end deleteMatter()

  saveMultipleFees(event: any) {
    this.selectedMatters = this.matterHandles.filter(s => s.state.isSelected === true);
    const multipleFee  = Object.assign({}, DEFAULT_MULTIPLE_FEE);
    multipleFee.FeeItem = event;
    const matters = [];
    this.selectedMatters.forEach(matter => {matters.push(matter.data); } );
    multipleFee.Services = matters;
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Saving fees...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Add multiple fees';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-Dashboard)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'InvoiceLines, Actions';
    this.activityLog.JsonData = JSON.stringify(multipleFee);
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    this.multipleFeesService
      .createFee(multipleFee)
      .subscribe({
        next: (next) => {
          // On next
        },
        error: (error) => {
          // On error
          const msg = 'Error saving fees.';
          // console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On success
          snack.label = 'Fees saved';
          this.matterHandles = [];
          this.snackbarsService.dismiss().make(snack).show();
          this.ngOnInit();
          this.toggleSort(this.sortBy);
          // this.loadingService.hideOverlay();
        }
      });
  } // end saveMultipleFees()

  /**
   * Navigates the user to the Invoice Preview page.
   * @param {Matter} matter The matter to be previewed.
   */
  previewInvoice(matter: Matter) {
    // this.router.navigate([PATH_INVOICE_REVIEW, matter.InvoiceId, matter.ServiceID]);
    this.router.navigate([PATH_ADVOCATE_INVOICE_PREVIEW, matter.InvoiceId]);
  } // end previewInvoice()

  /**
   * Navigates the user to the Invoice email page.
   * @param {Matter} matter The matter to be previewed.
   */
  previewMessage(matter: Matter) {
    this.router.navigate([PATH_INVOICE_EMAIL, matter.InvoiceId, matter.ServiceID]);
  } // end previewMessage()

  /**
   * Navigatest the user to the Matters page for the given matter.
   * @param {Matter} matter The matter to be editted.
   */
  openMatter(matter: Matter) {
    if (matter.Status === 'Outbox') {
      this.loadingService.showOverlay();
      this.invoiceService.updateReviseInvoice(matter.ServiceID, this.invoice).subscribe({
        next: (reviseInvoice) => {
          // On next
        }, error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
          this.router.navigate([PATH_MATTERS, matter.ServiceID]);
        }
      });
    } else {
      this.router.navigate([PATH_MATTERS, matter.ServiceID]);
    }
  } // end openMatter()

  viewQuotation(matter: Matter) {
    this.selectedMatter = matter;
    this.showProformaInvoice = true;
  } // end viewQuotation()

  onPageClick(event) {
    if (event.target.className) {
      if (!event.target.classList.contains('fa-angle-down') &&
      this.activeMatterHandles) {
        // Close the open menu
        this.onDismissMenu(this.activeMatterHandles);
      } else if (!event.target.classList.contains('fa-angle-up') &&
      !this.activeMatterHandles) {
        this.onDismissMultipleMatterMenu();
      }
    }
  } // end onPageClick()

  toggleChecbox(matter: MatterHandle) {
    matter.state.isSelected = !matter.state.isSelected;
  } // end toggleCheckbox()

  toggleSelectAll() {
    this.allSelected = !this.allSelected;
    this.matterHandles.forEach(matter => {
      matter.state.isSelected = this.allSelected;
    });
  } // end toggleSelectAll()

  toggleHistoricalSelectAll() {
    this.allSelected = !this.allSelected;
    this.historicalDataHandles.forEach(matter => {
      matter.state.isSelected = this.allSelected;
    });
  } // end toggleHistoricalSelectAll()

  toggleQuotationSelectAll() {
    this.selectAllQuotation = !this.selectAllQuotation;
    this.matterQuotationHandle.forEach(quotation => {
      // if (quotation.data.QuotationStateStatus === 'Created' || quotation.data.QuotationStateStatus === 'Pending' ||
      //   quotation.data.QuotationStateStatus === 'Rejected' || !quotation.data.QuotationStateStatus) {
          quotation.state.isSelected = this.selectAllQuotation;
        // }
    });
  } // end toggleQuotationSelectAll()

  toggleSelectAllForce(show: boolean) {
    this.allSelected = show;
    this.matterHandles.forEach(matter => {
      matter.state.isSelected = this.allSelected;
    });
  } // end toggleSelectAll()

  addMatter() {
    this.router.navigate([PATH_MATTERS]);
  } // end addMatter()

  toggleAscending() {
    this.sortAscending = !this.sortAscending;
    this.orderMatters();
  } // end toggleAscending()

  toggleHistoricalAscending() {
    this.sortHistoricalAscending = !this.sortHistoricalAscending;
    this.orderHistorical();
  } // end toggleHistoricalAscending()

  toggleAscendingQuotation() {
    this.sortAscendingQuotation = !this.sortAscendingQuotation;
    this.orderQuotation();
  } // end toggleAscendingQuotation()

  orderQuotation() {
    switch (this.orderByQuotation) { // Determines the field to order the current matter by

      case FIELD_DATE:
      this.matterQuotationHandle.sort((a, b) => {
        if (this.sortAscendingQuotation) {
          return a.data
          .MatterQuotationDate.toUpperCase().localeCompare(b.data.MatterQuotationDate.toUpperCase());
        }

        return b.data.MatterQuotationDate.toUpperCase().localeCompare(a.data.MatterQuotationDate.toUpperCase());
      });
      break;

      case FIELD_MATTER_DETAILS:
      this.matterQuotationHandle.sort((a, b) => {
        if (this.sortAscendingQuotation) {
          return a.data.Description.toUpperCase().localeCompare(b.data.Description.toUpperCase());
        }

        return b.data.Description.toUpperCase().localeCompare(a.data.Description.toUpperCase());
      });
      break;

      case FIELD_LAW_FIRM:
      this.matterQuotationHandle.sort((a, b) => {
        if (this.sortAscendingQuotation) {
          return a.data.InternalRefNo.toUpperCase().localeCompare(b.data.InternalRefNo.toUpperCase());
        }

        return b.data.InternalRefNo.toUpperCase().localeCompare(a.data.InternalRefNo.toUpperCase());
      });
      break;

      case FIELD_CLIENT:
      this.matterQuotationHandle.sort((a, b) => {
        if (this.sortAscendingQuotation) {
          return a.data.InternalRefNo.toUpperCase().localeCompare(b.data.InternalRefNo.toUpperCase());
        }

        return b.data.InternalRefNo.toUpperCase().localeCompare(a.data.InternalRefNo.toUpperCase());
      });
      break;

      case FIELD_ATTORNEY_REFERENCE:
      this.matterQuotationHandle.sort((a, b) => {
        if (this.sortAscendingQuotation) {
          return a.data.ClientRefNo.toUpperCase().localeCompare(b.data.ClientRefNo.toUpperCase());
        }

        return b.data.ClientRefNo.toUpperCase().localeCompare(a.data.ClientRefNo.toUpperCase());
      });
      break;

      case FIELD_REFERENCE:
      this.matterQuotationHandle.sort((a, b) => {
        if (this.sortAscendingQuotation) {
          return a.data.ClientRefNo.toUpperCase().localeCompare(b.data.ClientRefNo.toUpperCase());
        }

        return b.data.ClientRefNo.toUpperCase().localeCompare(a.data.ClientRefNo.toUpperCase());
      });
      break;

      case FIELD_WIP:
      this.matterQuotationHandle.sort((a, b) => {
        if (this.sortAscendingQuotation) {
          return a.data.Total - b.data.Total;
        }

        return b.data.Total - a.data.Total;
      });
      break;

      case FIELD_STATUS:
      this.matterQuotationHandle.sort((a, b) => {
        if (this.sortAscendingQuotation) {
          return a.data.Status.toUpperCase().localeCompare(b.data.Status.toUpperCase());
        }

        return b.data.Status.toUpperCase().localeCompare(a.data.Status.toUpperCase());
      });
      break;
    }
  } // end orderQuotation()

  onGenerateMultipleInvoices() {
    if (this.hasSelectedMatters) {
      this.selectedMatters = this.matterHandles.filter(s => s.state.isSelected === true);
        if (this.selectedMatters.find(hasFees => hasFees.data.Total === 0)) {
          this.errorTitle = 'Generate invoice error';
          this.errorMessage = 'One or more of your invoices does not contain fees.';
          this.onShowMultipleMatterWithNoFeesChangesPrompt = true;
      } else {
        if (this.selectedMatters.length > 0) {
          this.dataService.data = { matters: this.selectedMatters };
          this.router.navigate([PATH_PROCESS_INVOICES]);
        }
      }
    } else {
      const snack: Snack = {
        label: 'Please select matters to continue',
        action: null
      };
      this.snackbarsService.make(snack, 5000).show();
    }
  } // end onGenerateMultipleInvoices()

  onGenerateMultipleHistoricalInvoices() {
    if (this.hasSelectedHistoricalInvoices) {
      this.selectedMatters = this.historicalDataHandles.filter(s => s.state.isSelected === true);
        if (this.selectedMatters.find(hasFees => hasFees.data.Total === 0)) {
          this.errorTitle = 'Generate invoice error';
          this.errorMessage = 'One or more of your invoices does not contain fees.';
          this.onShowMultipleMatterWithNoFeesChangesPrompt = true;
      } else {
        if (this.selectedMatters.length > 0) {
          this.dataService.data = { matters: this.selectedMatters };
          this.router.navigate([PATH_PROCESS_INVOICES]);
        }
      }
    } else {
      const snack: Snack = {
        label: 'Please select matters to continue',
        action: null
      };
      this.snackbarsService.make(snack, 5000).show();
    }
  } // end onGenerateMultipleHistoricalInvoices()

  onHidemultipleMatterWithNoFeesChangesPrompt() {
    this.onShowMultipleMatterWithNoFeesChangesPrompt = false;
  }

  onDeleteMultipleQuotations() {
    // console.log('SELECTED QUOTATION: ', this.matterQuotationHandle);
    if (this.hasSelectedQuotations) {
      this.selectedQuotations = this.matterQuotationHandle.filter(c => c.state.isSelected === true);
      this.showdeleteMultipleQuotationModal = true;
    } else {
      const snack: Snack = {
        label: 'Please select quotations to continue...',
        action: null
      };
      this.snackbarsService.make(snack, 5000).show();
    }
  } // end onDeleteMultipleQuotation()

  onDeleteMultipleMatters() {
    if (this.hasSelectedMatters) {
      this.selectedMatters = this.matterHandles.filter(s => s.state.isSelected === true);
      this.showdeleteMultipleMatterModal = true;
    } else {
      const snack: Snack = {
        label: 'Please select matters to continue',
        action: null
      };
      this.snackbarsService.make(snack, 5000).show();
    }

  } // end onDeleteMultipleMatters()

  onDeleteMultipleHistoricalInvoices() {
    if (this.hasSelectedHistoricalInvoices) {
      this.selectedMatters = this.historicalDataHandles.filter(s => s.state.isSelected === true);
      this.showdeleteMultipleMatterModal = true;
    } else {
      const snack: Snack = {
        label: 'Please select matters to continue',
        action: null
      };
      this.snackbarsService.make(snack, 5000).show();
    }

  } // end onDeleteMultipleHistoricalInvoices()

  showDeleteMatterErrorAlert() {
    this.showDeleteMatterAlert = true;
  } // end showDeleteMatterErrorAlert()

  dismissDeleteMatterErrorAlert() {
    this.showDeleteMatterAlert = false;
  } // end dismissDeleteMatterErrorAlert()

  importAdvocateHistoricalData() {
    this.onShowImportHistoricalDataModal = true;
  } // end importAdvocateHistoricalData()

  onCloseImportHistoricalDataModal() {
    this.onShowImportHistoricalDataModal = false;
  } // end onCloseImportHistoricalDataModal()

  onHistoricalDataImported(event) {
    this.setActiveTab('historical-data');
    this.onShowImportHistoricalDataModal = false;
  } // end onHistoricalDataImported()

  loadFolders(): void {
    this.documentService.getFolders().subscribe({ next: (data) => {
        this.folders = data;
      },
      error: (error) => {
        console.error('Error loading folders', error);
      }
    });
  } // end loadFolders()
  
  loadFiles(folder: string): void {
    this.documentService.getFiles(folder).subscribe({ next: (data) => {
        this.files = data;
      },
      error: (error) => {
        console.error('Error loading files', error);
      }
    });
  } // end loadFiles()

  downloadFolder(folder: string): void {
    this.loadingService.showOverlay();
    this.documentService.downloadFolder(folder).subscribe({ next: (blob) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = `${folder}.zip`;
        a.click();
        URL.revokeObjectURL(objectUrl);
      },
      error: (error) => {
        console.error('Error downloading folder', error);
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay()
      }
   });
  } // end downloadFolder()

  //#region MatterQuotation Methods
  getQuotationMatters() {
    this.matterQuotation = [];
    this.quotationService.getMatterQuotation().subscribe({ next:
      (data) => {
        // On next
        this.matterQuotation = data;
      },
      error: (error) => {
        // On error

      },
      complete: () => {
    }
  });
  } // end getQuotationMatters()

  requestedQuotation() {
    this.matterQuotationHandle = [];
    if (this.matterQuotation) {
      this.matterQuotation.forEach(matter => {
        if (matter.QuotationStateStatus === 'Created' || matter.QuotationStateStatus === 'Pending' ||
          matter.QuotationStateStatus === 'Rejected' || matter.QuotationStateStatus === 'Manual Rejected' || !matter.QuotationStateStatus) {
          this.matterQuotationHandle.push({
            data: matter,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          });
        }
      });
    }
  } // end requestedQuotation()

  acceptedQuotation() {
    this.matterQuotationHandle = [];
    if (this.matterQuotation) {
      this.matterQuotation.forEach(matter => {
        if (matter.QuotationStateStatus === 'Accepted' || matter.QuotationStateStatus === 'Manual Accepted') {
          this.matterQuotationHandle.push({
            data: matter,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          });
        }
      });
    }
  } // end acceptedQuotation()

  searchQuotation() {
    if (this.searchTermQuotation) {
      this.matterQuotationHandle.forEach(matter => {
        if (
          !(matter.data.MatterQuotationDate.match(new RegExp(this.searchTermQuotation, 'gi'))) &&
          !(matter.data.Description.match(new RegExp(this.searchTermQuotation, 'gi'))) &&
          !(matter.data.InternalRefNo.match(new RegExp(this.searchTermQuotation, 'gi'))) &&
          !(matter.data.ClientRefNo.match(new RegExp(this.searchTermQuotation, 'gi'))) &&
          !(matter.data.Status.match(new RegExp(this.searchTermQuotation, 'gi')))
        ) {
          matter.state.isVisible = false;
        } else {
          matter.state.isVisible = true;
        }
      });
    } else {
      this.matterQuotationHandle.forEach(matter => {
        matter.state.isVisible = true;
      });
    }
  } // end searchQuotation()

  toggleCheckboxQuotation(data: MatterQuotationHandle) {
    data.state.isSelected = !data.state.isSelected;
  } // end toggleCheckboxQuotation()

  onDismissQuotationMenu(data: MatterQuotationHandle) {
    data.state.isOpen = false;
  } // end onDismissQuotationMenu()

  promptDeleteMatterQuotation(event) {
    this.selectedMatterQuotation = event;
    this.showDeleteQuotationModal = true;
  } // end promptDeleteMatterQuotation()

  previewQuotation(event) {

  } // end previewQuotation()

  previewQuotationMessage(quotation: MatterQuotationDTO) {
    this.router.navigate([PATH_QUOTATION_PREVIEW, quotation.QuotationId]);
  } // end previewQuotationMessage()

  openMatterQuotation(quotation: MatterQuotationDTO) {
    if (quotation.Status === 'Outbox') {
      const snack: Snack = {
        label: 'Revise quotation...',
        action: null
      };
      this.snackbarsService.make(snack, 5000).show();
      this.quotationService.reviseQuotation(quotation.QuotationId).subscribe({ next:
        (revisedQuotation) => {
          // On next
        },
        error: (error) => {
          // On next
          snack.label = 'Error revising quotation.';
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
        },
        complete: () => {
          // On complete
          snack.label = 'Quotation revised';
          snack.type = SnackType.SUCCESS;
          this.snackbarsService.dismiss().make(snack).show();
          this.router.navigate([PATH_QUOTATION, quotation.MatterQuotationId]);
      }
    });
    } else {
      this.router.navigate([PATH_QUOTATION, quotation.MatterQuotationId]);
    }
  } // end openMatterQuotation()

  openAcceptedQuotation(quotation: MatterQuotationDTO) {
    let MatterId = 0;
    this.loadingService.showOverlay();
    if (quotation.QuotationLinkId > 0) {
      this.router.navigate([PATH_QUOTATION, quotation.MatterQuotationId]);

    } else {
      this.quotationService.getServiceQuotationLink(quotation.MatterQuotationId)
      .subscribe({ next: (matter) => {
        // On next
        MatterId = matter.Services_Id;
        console.log('SELECTED ACCEPTED QUOTE: ', matter);
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        // this.router.navigate([PATH_MATTERS, MatterId]);
        this.router.navigate([PATH_QUOTATION, quotation.MatterQuotationId]);

        this.loadingService.hideOverlay();
      }
    });
    }
  } // end openAcceptedQuotation()

  togglePopupQuotationMenu(data: MatterQuotationHandle) {
    data.state.isOpen = !data.state.isOpen;
  } // end togglePopupQuotationMenu()

  addQuotationMatter() {
    this.router.navigate([PATH_QUOTATION]);
  } // end addQuotationMatter()

  // onMoveToAccepted(quotation: MatterQuotationDTO) {
  //   this.acceptedRejectedMatterQuotation = Object.assign({}, DEFAULT_MATTER_QUOTATION_DTO);
  //   // this.manualAcceptReject = 'Accepted';
  //   this.showQuotationAcceptRejectReason = true;
  //   this.acceptedRejectedMatterQuotation = quotation;
  // } // onMoveToAccepted()

  // onMoveToRejected(quotation: MatterQuotationDTO) {
  //   this.acceptedRejectedMatterQuotation = Object.assign({}, DEFAULT_MATTER_QUOTATION_DTO);
  //   // this.manualAcceptReject = 'Rejected';
  //   this.showQuotationAcceptRejectReason = true;
  //   this.acceptedRejectedMatterQuotation = quotation;
  // } // onMoveToRejected()

  onAcceptReject(quotation: MatterQuotationDTO, manualAcceptReject: string) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Accept quotation...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Quotation accepted or rejected on behalf of client';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-dashboard)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'MatterQuotation, Quotation';
    // this.activityLog.JsonData = quotation;
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();
    // quotation.AcceptRejectNotes = 'Manual ' + manualAcceptReject + ' ' + quotation.AcceptRejectNotes;
    this.quotationService.updateAcceptQuotation(quotation, manualAcceptReject).subscribe({ next: (data) => {
      // On next
    },
      error: (error) => {
        // On error
        snack.label = 'Error accepting/rejecting quotation.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On Complete
        snack.label = 'Quotation accepted/rejected.';
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.matterQuotation = [];
        this.showQuotationAcceptRejectReason = false;
        // this.matterQuotation =  this.quotationService.getMatterQuotation().toPromise();
        this.requestedQuotation();
        this.loadingService.hideOverlay();
      }
    });
  } // onAcceptReject()

  deleteQuotation(quotation) {
    this.loadingService.showOverlay();
    // console.log('QUOTATION TO DELETE: ', quotation);

    const snack: Snack = {
      label: 'Deleting quotation...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete quotation';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-dashboard)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'MatterQuotation, Quotation';
    this.activityLog.JsonData = quotation;
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    this.quotationService.onRemoveQuotation(
      quotation.quotation.MatterQuotationId,
      quotation.comments).subscribe({ next: (next) => {
        // On Next
      },
      error: (error) => {
        // On error
        snack.label = 'Error deleting quotation.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete

        const filtered = this.matterQuotationHandle.filter(m => {
          return (m.data.MatterQuotationId !== quotation.quotation.MatterQuotationId);
        });
        this.matterQuotationHandle = filtered;
        snack.label = 'Quotation deleted.';
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    });
  } // end deleteQuotation()
  //#endregion MatterQuotation Methods

  //#region  Historical Data
  // Import Historical Data
  importHistoricalData() {
    this.showImportHistorical = true;
  } // end HistoricalData()

  importMonthlyBilling() {
    this.showImportAuxconData = true;
  } // end importMonthlyBilling()

  getHistoricalDataFromApi() {
    this.loadingService.showOverlay();
    this.historicalDataHandles = [];
    this.historicalData = [];

    this.mattersService.getHistoricalData().subscribe({ next: (data) => {
      this.historicalData = data;
      if (this.historicalData.length > 0) {
        this.historicalData.forEach(matter => {
          this.historicalDataHandles.push({
            data: matter,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          })
        })
      }
    }, error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    }, complete: () => {
      // On complete
      this.loadingService.hideOverlay();
    }
  });
  } // end G=getHistoricalDataFromApi()
  //#endregion Historical Data
} // end BriefsAndMattersTableComponent{}
