<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">
      <div class="modal-header">
        <h2>Payment detail for {{data.length ? data[0].InvoiceNo: ''}}</h2>
        <button class="modal-dismiss" (click)="onDismiss()">
          <i class="fa fa-close"></i>
        </button> <!-- #end .modal-dismiss -->
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <div class="table">
          <div class="table-head">
            <div class="table-row">
              <span class="column-payment-date">Payment date</span>
              <span class="column-reference">Transaction Description</span>
              <span class="column-split-payment">Split payment</span>
              <!-- <span class="column-payment-total">Invoice Total</span> -->
              <span class="column-payment-paid">Paid</span>
              <!-- <span class="column-payment-due">Due</span> -->
              <span class="column-payment-status">Payment status</span>
            </div> <!-- #end .table-row -->
          </div> <!-- #end .table-head -->

          <div class="table-body">
            <div class="table-row" *ngFor="let line of data">
              <span class="column-payment-date">{{line.PaymentDate | date:'yyyy-MM-dd'}}</span>
              <span class="column-reference" [title]="line.Reference">{{line.Reference}}</span>
              <span class="column-split-payment">{{line.Split}}</span>
              <!-- <span class="column-payment-total">{{selectedInvoice.invoiceAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</span> -->
              <span class="column-payment-paid">{{line.Amount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
              <!-- <span class="column-payment-due">{{line.Outstanding  | currency: currencySymbol: currencySymbol:'1.2-2'}}</span> -->
              <span 
                class="column-payment-status" 
                [class.text-success]="line.Status === 'Full' || line.Status === '0 days'"
                [class.text-primary]="line.Status === 'Partial'"  
                [class.text-danger]="line.Status !== 'Full' 
                && line.Status !== 'Partial'">{{line.Status}}</span>
            </div> <!-- #end .table-row -->

          </div> <!-- #end .table-body -->

          <div class="row">
            <div class="form-group" hidden>
              <label for="notes">Notes on payment</label>
              <textarea cols="30" rows="10" placeholder="Enter your note on payment here"></textarea>
            </div> <!-- #end .form-group -->
          </div> <!-- #end .row -->

        </div> <!-- #end .table -->
        <div class="container-fluid">
          <div class="table-totals">
            <span class="column-total-invoiced" style="font-weight: bold">Total Invoiced:</span>
            <span class="column-total-invoiced-amount-" style="font-weight: bold">{{totalInvoiced  | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
          
            <span class="column-total-paid" style="font-weight: bold">Total Paid:</span>
            <span class="column-total-payment-amount" style="font-weight: bold">{{totalPaymentAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
          
            <span class="column-total-due" style="font-weight: bold">Total Outstanding:</span>
            <span class="column-total-due-amount" style="font-weight: bold">{{totalOutstandingAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
          </div> <!-- #end .table-totals -->
          </div> <!-- end .container-fluid -->
      </div> <!-- #end .modal-body form -->

      <div class="modal-footer">
        <button
          class="btn btn-success btn-end"
          [disabled]="!isSaveable"
          (click)="onSavePaymentDetail()">{{confirmText}}</button>
      </div> <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal-->