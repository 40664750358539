import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export interface BankAccounts {
    Id: number;
    ContactId: number;
    BranchCode: string;
    AccountNumber: string;
    AccountType: string;
    Bank: string;
    BankId: number;
    SPAccType: string;
    AccountHolder: string;
    LastStatementSent: string;
    PracticeManagerAccess: boolean;
    StatementReminderDay : number;
    Enabled: boolean;
    BankName: string;
} // end BankAccounts{}
export const DEFAULT_BANK_ACCOUNT: BankAccounts = {
    Id: 0,
    ContactId: 0,
    BranchCode: '',
    AccountNumber: '',
    AccountType: '',
    Bank: '',
    BankId: 0,
    SPAccType: '',
    AccountHolder: '',
    LastStatementSent: '',
    PracticeManagerAccess: false,
    StatementReminderDay : 0,
    Enabled: false,
    BankName: ''
}; // end BankAccounts{}

@Injectable()
export class BankAccountsService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  getBankAccounts(): Observable<any> {
    return this.http.get<BankAccounts[]>(
      this.api.endpoints.bankAccounts +
      '?ServiceProviderId=' + this.api.serviceProviderID,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError )
    );
  } // end getBankAccounts()

  addBankAccount(bankAccount: BankAccounts): Observable<BankAccounts> {
    return this.http.post<any>(
      this.api.endpoints.bankAccounts +
      '?ServiceProviderId=' + this.api.serviceProviderID,
      bankAccount,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end addBankAccount()

  updateBankAccount(bankAccount: BankAccounts): Observable<BankAccounts> {
    return this.http.put<any>(
      this.api.endpoints.bankAccounts +
      '?ServiceProviderId=' + this.api.serviceProviderID,
      bankAccount, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end updateBankAcccount()

  deleteBankAccount(id: number): Observable<BankAccounts> {
    return this.http.delete<any>(this.api.endpoints.bankAccounts +
      '?ServiceProviderId=' + this.api.serviceProviderID +
      '&id=' + id, this.api.httpOptions
      ).pipe(
        catchError(this.api.handleError)
      );
  } // end deleteBankAccount()

 } // end BankAccountsService{}
