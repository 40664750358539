import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AdvocatePracticeSummaryHandle,
  AdvocatePracticeSummary,
  PracticeAdvocateHealthService
  } from '../../../services/practice-advocate-health/practice-advocate-health.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { PATH_PRACTICE_MANAGER_REPORTS } from '../../../services/appdata/app-config.service';

export enum ReportType {
  BusinessReports = 'businessReports',
  FinancialReports = 'financialReports',
  LiveStatement = 'liveStatement',
  PracticeManagementReports = 'practiceManagementReports',
  IndustryReports = 'industryReport',
  MyAdvocates = 'myAdvocates'
} // end ReportType{}
export enum ReportName {
  AdvocateSummary = 'advocateSummary',
  AdvocateDetail = 'advocateDetail',
  AgeAnalysis = 'ageAnalysis',
  PracticeAllCustomer = 'practiceAllCustomer',
  PracticeByLawfirm = 'practiceByLawfirm'
} // end ReportName{}

export enum WidgetType {
  InvoicedTarget = 'invoicedTarget',
  CollectedTarget = 'collectedTarget',
  AdvocateReview = 'advocateReview',
  InvoicingAdvocate = 'invoicingAdvocate',
  AgeAnalysis = 'ageAnalysis'
} // end Widgettype{}

@Component({
  selector: 'app-practice-over-summary',
  templateUrl: './practice-over-summary.component.html',
  styleUrls: ['./practice-over-summary.component.scss']
})
export class PracticeOverSummaryComponent implements OnInit {

  numberOfAdvocates: number;
  c_numberOfAdvocates: number;
  meeting_numberOfAdvocates: number;
  col_numberOfAdvocates: number;
  advoctesBelowTarget: AdvocatePracticeSummaryHandle[] = [];
  advoctesBelowCollectionsTarget: AdvocatePracticeSummaryHandle[] = [];
  advoctesDueforReview: AdvocatePracticeSummaryHandle[] = [];
  advoctesAboveInvoicedCollected: AdvocatePracticeSummaryHandle[] = [];

  advocatesBelowTargetPercentageColor = '';
  advocatesBelowCollectionTargetPercentageColor = '';
  advocatesDueForReviewPercentageColor = '';
  advocatesExceedingCollectedPercentageColor = '';

  @Input() adovatePracticeSummary: AdvocatePracticeSummary[];
  @Output() widgetClick: EventEmitter<string> = new EventEmitter<string>();

  get totalNumberOfAdvocates(): number {

    return this.userProfileService.userProfile.impersonates.filter(me => me.serviceProviderID
      !== this.userProfileService.userProfile.serviceProviderID).length;
  } // end totalNumberOfAdvocates()

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private practiceAdvocateHealthService: PracticeAdvocateHealthService
  ) { } // end constructor()

  ngOnInit() {
    if (this.adovatePracticeSummary) {
      this.advoctesBelowTarget = [];
      this.advoctesBelowCollectionsTarget = [];
      this.advoctesDueforReview = [];
      this.adovatePracticeSummary.forEach(advocate => {
        if (advocate.InvoiceTarget > advocate.TotalInvoicedLastMonth) {
          const targetPercentage = Math.round(advocate.TotalInvoicedLastMonth * 100 / advocate.InvoiceTarget);
          const below20Percent = 100 - targetPercentage;
          if (below20Percent > 20) {
            advocate.InvoicedBelowTarget = below20Percent;
            this.advoctesBelowTarget.push({
              data: advocate,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          }
        }

        if (advocate.CollectionTarget > advocate.TotalCollectedLastMonth) {
          const targetPercentage = Math.round(advocate.TotalCollectedLastMonth * 100 / advocate.CollectionTarget);
          const below20Percent = 100 - targetPercentage;
          if (below20Percent > 20) {
            advocate.CollectedBelowTarget = below20Percent;
            this.advoctesBelowCollectionsTarget.push({
              data: advocate,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          }
        }

        if (advocate.CollectionTarget < advocate.TotalCollectedLastMonth &&
          advocate.InvoiceTarget < advocate.TotalInvoicedLastMonth) {
          const targetInvoicedPercentage = Math.round(advocate.TotalInvoicedLastMonth * 100 / advocate.InvoiceTarget);
          const targetCollectedPercentage = Math.round(advocate.TotalCollectedLastMonth * 100 / advocate.CollectionTarget);
          const collectedAbove20Percent = targetCollectedPercentage - 100;
          const invoicedAbove20Percent = targetInvoicedPercentage - 100;

          advocate.CollectedAboveTarget = collectedAbove20Percent;
          advocate.InvoiceAboveTarget = invoicedAbove20Percent;
          this.advoctesAboveInvoicedCollected.push({
              data: advocate,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
        }
        if (moment().diff(moment(advocate.LastMeetingDate), 'days') >= 63) {
          this.advoctesDueforReview.push({
              data: advocate,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          }
      });
    }
    this.advoctesBelowTarget.sort((a, b) => {
      return b.data.InvoicedBelowTarget - a.data.InvoicedBelowTarget;
    });
    this.advoctesBelowCollectionsTarget.sort((a, b) => {
      return b.data.CollectedBelowTarget - a.data.CollectedBelowTarget;
    });
    this.advoctesAboveInvoicedCollected.sort((a, b) => {
      return b.data.CollectedAboveTarget - a.data.CollectedAboveTarget;
    });
    this.numberOfAdvocates = this.advoctesBelowTarget.length;
    this.c_numberOfAdvocates = this.advoctesBelowCollectionsTarget.length;
    this.meeting_numberOfAdvocates = this.advoctesDueforReview.length;
    this.col_numberOfAdvocates = this.advoctesAboveInvoicedCollected.length;

    // Advocates below 20% invoicing target
    const belowInvoicingtarget = this.numberOfAdvocates / this.totalNumberOfAdvocates * 100;
    if (Math.round(belowInvoicingtarget) >= 0 && Math.round(belowInvoicingtarget) <= 25) {
      this.advocatesBelowTargetPercentageColor = '#8abd37';
    } else if (Math.round(belowInvoicingtarget) >= 26 && Math.round(belowInvoicingtarget) <= 50) {
      this.advocatesBelowTargetPercentageColor = '#e5c100';
    } else if (Math.round(belowInvoicingtarget) >= 51 && Math.round(belowInvoicingtarget) <= 75) {
      this.advocatesBelowTargetPercentageColor = '#eb9e02';
    } else if (Math.round(belowInvoicingtarget) >= 76) {
      this.advocatesBelowTargetPercentageColor = '#cf624f';
    }

    // Advocates below 20% Collections target
    const belowCollectionsgtarget = this.c_numberOfAdvocates / this.totalNumberOfAdvocates * 100;
    if (Math.round(belowCollectionsgtarget) >= 0 && Math.round(belowCollectionsgtarget) <= 25) {
      this.advocatesBelowCollectionTargetPercentageColor = '#8abd37';
    } else if (Math.round(belowCollectionsgtarget) >= 26 && Math.round(belowCollectionsgtarget) <= 50) {
      this.advocatesBelowCollectionTargetPercentageColor = '#e5c100';
    } else if (Math.round(belowCollectionsgtarget) >= 51 && Math.round(belowCollectionsgtarget) <= 75) {
      this.advocatesBelowCollectionTargetPercentageColor = '#eb9e02';
    } else if (Math.round(belowCollectionsgtarget) >= 76) {
      this.advocatesBelowCollectionTargetPercentageColor = '#cf624f';
    }

    // Advocates due for review
    const dueForReview = this.meeting_numberOfAdvocates / this.totalNumberOfAdvocates * 100;
    if (Math.round(dueForReview) >= 0 && Math.round(dueForReview) <= 25) {
      this.advocatesDueForReviewPercentageColor = '#8abd37';
    } else if (Math.round(dueForReview) >= 26 && Math.round(dueForReview) <= 50) {
      this.advocatesDueForReviewPercentageColor = '#e5c100';
    } else if (Math.round(dueForReview) >= 51 && Math.round(dueForReview) <= 75) {
      this.advocatesDueForReviewPercentageColor = '#eb9e02';
    } else if (Math.round(dueForReview) >= 76) {
      this.advocatesDueForReviewPercentageColor = '#cf624f';
    }

    // Advocates due for review
    const exceedingCollected = this.col_numberOfAdvocates / this.totalNumberOfAdvocates * 100;
    if (Math.round(exceedingCollected) >= 0 && Math.round(exceedingCollected) <= 25) {
      this.advocatesExceedingCollectedPercentageColor = '#cf624f';
    } else if (Math.round(exceedingCollected) >= 26 && Math.round(exceedingCollected) <= 50) {
      this.advocatesExceedingCollectedPercentageColor = '#eb9e02';
    } else if (Math.round(exceedingCollected) >= 51 && Math.round(exceedingCollected) <= 75) {
      this.advocatesExceedingCollectedPercentageColor = '#e5c100';
    } else if (Math.round(exceedingCollected) >= 76) {
      this.advocatesExceedingCollectedPercentageColor = '#8abd37';
    }
  } // end ngOnInit()

  onWidget(type: string) {
    switch (type) {
      case 'invoicedTarget':
        this.practiceAdvocateHealthService.reportSortType = WidgetType.InvoicedTarget;
        this.widgetClick.emit(type);
        break;

      case 'collectedTarget':
        this.practiceAdvocateHealthService.reportSortType = WidgetType.CollectedTarget;
        this.widgetClick.emit(type);
        break;

      case 'advocateReview':
        this.practiceAdvocateHealthService.reportSortType = WidgetType.AdvocateReview;
        this.widgetClick.emit(type);
        break;

      case 'invoicingAdvocate':
        this.practiceAdvocateHealthService.reportSortType = WidgetType.InvoicingAdvocate;
        this.widgetClick.emit(type);
        break;
    }
  }

  getCollectedTargetColor(data: AdvocatePracticeSummary): string {
    const collectedData = data.TotalCollectedThisMonth / data.CollectionTarget * 100;
    if (Math.round(collectedData) >= 0 && Math.round(collectedData) <= 25) {
      return '#cf624f';
    } else if (Math.round(collectedData) >= 26 && Math.round(collectedData) <= 50) {
      return '#eb9e02';
    } else if (Math.round(collectedData) >= 51 && Math.round(collectedData) <= 75) {
      return '#e5c100';
    } else if (Math.round(collectedData) >= 76) {
      return '#8abd37';
    }
  } // end getCollectedTargetColor()

  getAgeColor(data: AdvocatePracticeSummary): string {
    if (data.Age < 1) {
      return 'cf624f';
    }
  } // end getAgeColor()

} // end PracticeOverSummaryComponent{}
