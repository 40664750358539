import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { ArbitrationView, InvoicesService } from '../../services/invoices/invoices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitiesService } from '../../services/utilities.service';
import { DEFAULT_INVOICE_DETAILS, InvoiceDetails, InvoiceDetailsService } from '../../services/invoice-details/invoice-details.service';
import { InvoiceMessageService } from '../../services/invoice-message/invoice-message.service';
import { DataService } from '../../services/messaging/data/data.service';

export interface ArbitrationViewHandle {
  data: ArbitrationView;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
    isSaveable: boolean;
    isLineErrorVisible: boolean;
    isFutureDatedErrorVisible: boolean;
    isLoad: boolean
    isGenerated: boolean,
    ErrorMessage: string
  };
}

@Component({
  selector: 'app-advocate-arbitration-invoices',
  templateUrl: './advocate-arbitration-invoices.component.html',
  styleUrls: ['./advocate-arbitration-invoices.component.scss']
})
export class AdvocateArbitrationInvoicesComponent implements OnInit {

  id: number;
  crumbs: ICrumb[];
 //#region  Arbitration 
 arbitrationView: ArbitrationView[] = [];
 arbitrationViewHandle: ArbitrationViewHandle[] = [];
 //#endregion Arbitration 

 invoicePlaintiff: string;
 invoiceDefendant: string;
 selectedInvoiceId: number;
 selectedTab: string;
 showProformaInvoice: boolean;
 invoiceDetails: InvoiceDetails = this.util.objectCopy(DEFAULT_INVOICE_DETAILS);
 isPageDataLoaded: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private util: UtilitiesService,
    private dataService: DataService,
    private loadingService: LoadingService,
    private invoiceService: InvoicesService,
    private changeDetectorRef: ChangeDetectorRef,
    private invoiceDetailService: InvoiceDetailsService,
    private invoiceMessageService: InvoiceMessageService
  ) { } // end constructor()

  async ngOnInit() {
    // this.arbitrationView = [];
    this.arbitrationViewHandle = [];
    this.loadingService.showOverlay();
    this.arbitrationViewHandle = this.dataService.data.arbitrationData;
    
    console.log('ARBITRATION FEE DATA SERVICE: ', this.arbitrationViewHandle);
    this.crumbs = [
      {label: 'Generate Arbitration Invoice'}
    ];
    let Ids = [];
    
    
    // this.id = +this.route.snapshot.paramMap.get('id');
    // if(this.id) {
      // Arbitration Split Invoices
      
      
      // this.loadingService.showOverlay();
      // const state = Object.assign({}, {
      //   isVisible: true,
      //   isSelected: false,
      //   isOpen: false,
      //   isSaveable: false,
      //   isFutureDatedErrorVisible: false,
      //   isLineErrorVisible: false,
      //   isLoad: true,
      //   isGenerated: false,
      //   ErrorMessage: ''
      // });
      this.arbitrationViewHandle.forEach(c => {
        Ids.push(c.data.Id);
      })

      // this.arbitrationView .forEach(arb => {
      //   console.log('ARBITRATION INVOICES: ', arb);
      //   Ids.push(arb.Id);
      //   this.arbitrationViewHandle.push({
      //     data: arb,
      //     state: state
      //   });
      //   // Trigger UI update
      this.changeDetectorRef.detectChanges();
      // });

      for (let i = 0; i < Ids.length; i++) {
        await this.generateDocumentHandler(Ids[i]);
      }

      // this.invoiceService.getArbitrationViewData(this.id).subscribe({  next: (arbitration) => {
      //     // On next
          
      //     if(arbitration) {
            
                        
      //     }
      //   },
      //   error: (error) => {
      //     // On error
      //     // this.loadingService.hideOverlay();
      //     console.log('ARBITRATION INVOICES: error', this.arbitrationViewHandle);
      //   },
      //   complete: async () => {
      //     // On complete   
      //     this.isPageDataLoaded = true;  
      //     for (let i = 0; i < Ids.length; i++) {
      //       await this.generateDocumentHandler(Ids[i]);
      //     }
      //     this.changeDetectorRef.detectChanges(); 
      //     console.log('ARBITRATION INVOICES: complete', this.arbitrationViewHandle);     
      //     // this.loadingService.hideOverlay();
      //   }
      // });
    // }
  } // end ngOnInit()

  trackByArbitration(index: number, arbitration: any): number {
    return arbitration.data.Id; // or any unique identifier in your arbitration object
  }

  async generateDocumentHandler(InvoiceId: number) {
    try {
      const res = await this.invoiceMessageService.generateDocument(InvoiceId);
      this.arbitrationViewHandle.forEach(element => {
        if (element.data.Id == res.InvoiceId) {
          element.state.isLoad = false;
          element.state.isGenerated = res.isGenerated ? true : false;
          element.state.ErrorMessage = res.isGenerated ? '' : res.ErrorMessage;

          if (res.invMsgTemplate.InvoiceDetails.InvoiceDate === null)
            res.invMsgTemplate.InvoiceDetails.InvoiceDate = new Date().toJSON();

          // element.data.ContactId = this.apiService.serviceProviderID;
          // element.data.InvoiceID = res.invMsgTemplate.InvoiceID;
          // element.data.ServiceId = res.invMsgTemplate.ServiceId;
          // element.data.ID = res.invMsgTemplate.MessageTemplate.ID;
          // element.data.Source = res.invMsgTemplate.MessageTemplate.Source;
          // element.data.SourceId = res.invMsgTemplate.MessageTemplate.SourceId;
          element.data.Recipients = res.invMsgTemplate.MessageTemplate.Recipients;
          // element.data.EMailAddresses = res.invMsgTemplate.MessageTemplate.EMailAddresses;
          // element.data.Description = res.invMsgTemplate.MessageTemplate.Description;
          // element.data.DocumentPath = res.invMsgTemplate.MessageTemplate.DocumentPath;
          // element.data.Status = res.invMsgTemplate.MessageTemplate.Status;
          // element.data.Subject = res.invMsgTemplate.MessageTemplate.Subject;
          // element.data.Body = res.invMsgTemplate.MessageTemplate.Body;
          // element.data.RolloverService = res.invMsgTemplate.MessageTemplate.RolloverService;
          // element.data.SendMessage = res.invMsgTemplate.MessageTemplate.SendMessage;
          // element.data.MessageDate = res.invMsgTemplate.InvoiceDetails.InvoiceDate;
          // element.data.ClientName = res.invMsgTemplate.InvoiceDetails.ClientName;
          // element.data.InvoiceNote = res.invMsgTemplate.InvoiceDetails.InvoiceNote;
          // element.data.InvoiceTotal = res.invMsgTemplate.InvoiceDetails.InvoiceTotalExcl;
          // element.data.InvoiceParties = res.invMsgTemplate.InvoiceDetails.InvoiceParties;
          // element.data.InvoiceNo = res.invMsgTemplate.InvoiceDetails.InvoiceNo;
          // element.data.InvoiceDate = res.invMsgTemplate.InvoiceDetails.InvoiceDate;
          this.changeDetectorRef.detectChanges();
          this.loadingService.hideOverlay();
        }
      });
    } catch (error) {
      console.error('Error generating document:', error);
    }
  }

  onReceipientInput(event, messageTemplate) {
    // console.log('ON_RECEIPIENT_INPUT', event, messageTemplate);
    // this.messageTemplateHandleChange.emit(this.messageTemplateHandle);
  } // end onReceipientInput()

  hasValidEmails(emails: any): Boolean {
      // emails.state.isSaveable = Boolean(this.util.areValidEmails(emails.data.Recipients));
    return !this.util.areValidEmails(emails.data.Recipients);
  } // end hasValidEmails()

  onViewInvoice(i: any) {
    //  this.invoicesTransaction = i;
    this.loadingService.showOverlay();
    this.selectedTab = 'invoice';
    const invoiceId = i.Id;
    // this.notes = [];
    // this.notesHandle = [];
    if (invoiceId) {
        let _note = [];
        let _notes = [];
        // if (this.isPracticeManager) {
        //     this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
        //       invoiceId, 4).subscribe({next: (_next) => {
        //           _notes = _next;
        //           this.notes = _notes;
        //       },
        //       complete: () => {
        //           _notes.forEach(_notes => {
        //               this.notesHandle.push({
        //                   data: _notes,
        //                   state: {
        //                       isVisible: true,
        //                       isSelected: false,
        //                       isOpen: false
        //                   }
        //               });
        //           });

        //           this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
        //           // this.showProformaInvoice = true;
        //                   this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({next: (_invoice_detail) => {
        //                       this.invoiceDetails  = _invoice_detail;
        //                       if (this.invoiceDetails) {
        //                           const desc = this.invoiceDetails.InvoiceParties.split(' // ');
        //                           if (desc.length > 0) {
        //                               this.invoicePlaintiff = desc[0];
        //                           }
        //                           if (desc.length > 1) {
        //                               this.invoiceDefendant = desc[1];
        //                           }
        //                       }
        //                       this.getAllQuotedFees(this.invoiceDetails.ServiceId);
        //                   },
        //                       complete: () => {
        //                           // On complete
        //                           this.selectedInvoiceId = invoiceId;
        //                           this.loadingService.hideOverlay();
        //                           this.showProformaInvoice = true;
        //                       }
        //                   });
        //               }
        //           });
        //   } else if (!this.isPracticeManager) {
              // this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
              //     invoiceId, 4).subscribe({next: (_next) => {
              //         _note = _next;
              //     }});
              this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
              // this.showProformaInvoice = true;
              this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({next: (_invoice_detail) => {
                  this.invoiceDetails = _invoice_detail;
                  this.selectedInvoiceId = invoiceId;
                  // this.getAllQuotedFees(this.invoiceDetails.ServiceId);
                  if (this.invoiceDetails) {
                          const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                          if (desc.length > 0) {
                              this.invoicePlaintiff = desc[0];
                          }
                          if (desc.length > 1) {
                              this.invoiceDefendant = desc[1];
                          }
                      }
                  },
                  complete: () => {
                      this.showProformaInvoice = true;
                      this.loadingService.hideOverlay();
                  }
              });
          }
          // if (!this.isPracticeManager) {
          //     this.notes = _note.filter(x => x.QuestionText);
          // }
              // this.notes = _note;


          // this.notes.forEach(_notes => {
          //     this.notesHandle.push({
          //         data: _notes,
          //         state: {
          //             isVisible: true,
          //             isSelected: false,
          //             isOpen: false
          //         }
          //     });
          // });
      // }
  } // end onViewInvoice()

//   getAllQuotedFees(serviceId: number) {
//     this.feesQuoted = [];
//     this.quotationService.getActualFeesQuoted(serviceId).subscribe({next: (_next) => {
//         this.feesQuoted = _next;
//     }});
// } // end getAllQuotedFees()

  // onSaveInvoiceDetails(message: MessageTemplate) {
  //   // this.loadingService.showOverlay();
  //   const snack: Snack = {
  //     label: 'Saving invoice details...',
  //     action: null
  //   };

  //   this.snackbarsService.snackbarComponent.make(snack, 5000).show();
  //   this.invoiceDetails.InvoiceNote = message.InvoiceNote;
  //   // this.invoiceDetails.InvoiceDate = new Date().toJSON();
  //   this.invoiceDetails.InvoiceDate = message.InvoiceDate;
  //   console.log('WORKING_INVOICE_DETAILS: ', this.invoiceDetails.InvoiceDate);
  //   if (this.invoiceNote) {
  //     this.invoiceDetails.InvoiceNote = this.invoiceNote;
  //   }
  //   this.invoiceDetailsService
  //     .updateInvoiceDetails(this.invoiceDetails.Id, this.invoiceDetails)
  //     .subscribe({ next: (invoiceDetails) => {
  //       this.invoiceDetails = invoiceDetails;
  //     },
  //       error: (error) => {
  //         // this.loadingService.hideOverlay();
  //         const msg = 'Error saving details.';
  //         console.error(msg, error);
  //         snack.label = msg;
  //         snack.action = {
  //           label: 'Retry',
  //           handler: this.onSaveInvoiceDetails,
  //           params: this
  //         };
  //         // snack.type = SnackType.ERROR;
  //         this.snackbarsService.dismiss().make(snack).show();
  //       },
  //       complete: () => {
  //         // On Complete
  //         // this.loadingService.hideOverlay();
  //         snack.label = 'Invoice details saved.';
  //         this.snackbarsService.dismiss().make(snack).show();
  //         this.invoiceNote = '';
  //       }
  //     });
  //   // this._subscritpions.push(uidSub);
  // } // end onSaveInvoiceDetails()

  onDismissMenu(event) {

  } // end onDismissMenu()

  removeInvoice(event) {

  } // end removeInvoice()

  togglePopupMenu(event) {

  } // end togglePopupMenu()
  closeModal() {
    this.showProformaInvoice = false;
  } // end closeModal()

} // end AdvocateArbitrationInvoiceComponent{}
