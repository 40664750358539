import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Invoice } from '../../../services/invoices/invoices.service';
import { UsercurrencyDirective } from '../../../directive/usercurrency.directive';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { AttorneysService } from '../../../services/attorneys/attorneys.service';
import { ClientContact } from '../../../services/client-contacts/client-contacts.service';
import { Defendant } from '../../../services/defendants/defendants.service';
import { LawFirm, LIST_NAME_LAW_FIRMS } from '../../../services/lists/lists.service';
import { HistoricalData, Matter } from '../../../services/matters/matters.service';
import { SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { OtherClients } from '../../../services/other-clients/other-clients.service';
import { Plaintiff } from '../../../services/plaintiffs/plaintiffs.service';
import { UserProfile, UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../../services/utilities.service';
import * as moment from 'moment';
import { IndexedDBService } from '../../../services/indexed-db/indexed-db.service';

@Component({
  selector: 'app-historical-details',
  templateUrl: './historical-details.component.html',
  styleUrls: ['./historical-details.component.scss']
})
export class HistoricalDetailsComponent implements OnInit {

  showMatterExistsPrompt: boolean;

  @Input() isLoading: boolean;
  @Input() workingHistorical: HistoricalData;
  @Input() invoice: Invoice;
  @Input() matterPlaintiffs: string;
  @Input() matterDefendants: string;
  @Input() lawFirms: LawFirm[];
  @Input() attorneys: ClientContact[];
  @Input() plaintiffs: Plaintiff[];
  @Input() otherClients: OtherClients[];
  @Input() defendants: Defendant[];
  @Input() attorneyRefs: string[];
  @Input() instructions: string[];
  @Input() historical: HistoricalData;
  // @Input() matters: HistoricalData[];
  // @Input() _matters: HistoricalData[];
  @Input() userProfile: UserProfile;
  @Output() workingHistoricalChange: EventEmitter<HistoricalData> = new EventEmitter<HistoricalData>();
  @Output() matterPlaintiffsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterOtherClientsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterDefendantsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterLawfirmChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterAttorneyChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterDetailsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() addPlaintiff: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('paymentTermsRef') paymentTermsRef: ElementRef;
  @ViewChild('inputHourly') focusHourly: ElementRef;
  @ViewChild('inputDaily') focusDaily: ElementRef;
  @ViewChild('inputPerKilometer') focusPerKilometer: ElementRef;

  title = '';

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  plaintiff: string;
  defendant: string;
  avaragePaymentRating: number;
  dropdowns: any = {
    showLawFirms: false,
    showAttorneys: false,
    showPlaintiffs: false,
    showDefendants: false,
    showAttorneyRefferences: false,
    showInstructions: false
  };

  searchlist: string[];
  lawFirmNames: string[] = [];
  isLawfirmRatingLoading: boolean;
  lawfirmRatingLabel: string;

  // Payment Terms
  paymentTerms: any;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  // Is Internal Attorney
  get isAttorney(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
  } // end isAttorney()

  // Is Internal isAttorneyAssistant
  get isAttorneyAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator) &&
    Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
  } // end isAttorneyAssistant()

  // Is Internal isLawfirmAssistant
  get isLawfirmAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator) &&
    Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isLawfirmAssistant()

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isLawfirm()

  get isDirty(): boolean {
    this.workingHistorical.HourlyRate = Number.parseFloat(this.workingHistorical.HourlyRate.toString());
    this.workingHistorical.DailyRate = Number.parseFloat(this.workingHistorical.DailyRate.toString());
    this.workingHistorical.PerKilometerRate = Number.parseFloat(this.workingHistorical.PerKilometerRate.toString());
    // return !this.util.objectIsSame(this.workingHistorical, this.matter);
    return false;
  } // end get isDirty()

  get isLawfirmSelected(): boolean {
    if (this.workingHistorical) {
      return !Boolean(this.workingHistorical.ContactID > 0);
    } else {
      return true;
    }
  } // end get isLawfirmSelected()

  get isRafMatter(): boolean {
    const raf = 'RAF';
    const roadAccidentFund = 'Road Accident Fund';
    const theRoadAccidentFund = 'The Road Accident Fund';
    const padOngeluksFonds = 'Pad Ongeluks Fonds';
    const pof = 'POF';
    return Boolean(this.workingHistorical.DefendantFullName.match(new RegExp(raf, 'gi'))) ||
      Boolean(this.workingHistorical.DefendantFullName.match(new RegExp(roadAccidentFund, 'gi'))) ||
      Boolean(this.workingHistorical.DefendantFullName.match(new RegExp(theRoadAccidentFund, 'gi'))) ||
      Boolean(this.workingHistorical.DefendantFullName.match(new RegExp(padOngeluksFonds, 'gi'))) ||
      Boolean(this.workingHistorical.DefendantFullName.match(new RegExp(pof, 'gi')));
  } // end isRafMatter()

  images: any;

  constructor(
    private router: Router,
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private snackbarsService: SnackbarsService,
    private indexedDbService: IndexedDBService,
    private attorneysService: AttorneysService,
    private userProfileService: UserProfileService,
    private currencyDirective: UsercurrencyDirective
  ) {
    this.isLoading = true;
    this.images = {
      spinner: {
        path: '../../../../../assets/images/logos/spinner.gif',
        label: 'Auxcon spinner'
      } // spinner{}
    };
  } // end constructor()

  ngOnInit(): void {
    console.log('check')
    this.paymentTerms = [
      { id: 0, name: '0 Days' },
      { id: 30, name: '30 Days' },
      { id: 60, name: '60 Days' },
      { id: 90, name: '90 Days' },
      { id: 98, name: '98 Days' },
      { id: 99, name: 'Contingency'}
    ];

    if (this.userProfile && this.userProfile.serviceProviderID === 13553
      || this.userProfileService.selectedUserProfile && this.userProfileService.selectedUserProfile.serviceProviderID === 13553) {
      this.paymentTerms.push(
        { id: 7, name: '7 Days' }
      )
      this.paymentTerms.sort((a, b) => {
        return a.id - b.id;
      })
    }

    if (!this.lawFirms) {
      // if (localStorage.getItem(LIST_NAME_LAW_FIRMS)) {
      //   this.lawFirms = JSON.parse(localStorage.getItem(LIST_NAME_LAW_FIRMS));
      // }
      this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData').then((storedData) => {
        if (storedData) {
            this.lawFirms = storedData.data;
        } else {
            // Handle the case where no data is returned
            this.lawFirms = []; // or any default value
        }
    }).catch((error) => {
        console.error('Error retrieving law firms data from IndexedDB', error);
        this.lawFirms = []; // or any default value in case of an error
    });
    }

    if (this.workingHistorical && this.workingHistorical.ServiceID) {
      // this.attorneys =  this.attorneysService.getAttorneysByLawFirms(this.workingHistorical.ContactID).toPromise();
    }
    this.isLawfirmRatingLoading = false;
  } // end ngOnInit()

  ngAfterViewInit() {
    if (this.workingHistorical.ContactID && this.userProfile.profileType !== this.profileTypes.ATTORNEY
      && this.userProfile.profileType !== this.profileTypes.LAWFIRM) {
      this.attorneysService.getLawfirmRating(this.workingHistorical.ContactID).subscribe({ next:
        (avarage_rating) => {
          if (avarage_rating) {
            this.avaragePaymentRating = avarage_rating.IntAvgDays;
            if (avarage_rating.AverageDaysToPayment === 'Not Applicable') {
              this.lawfirmRatingLabel = 'No data available.';
            } else {
              this.lawfirmRatingLabel = 'On avarage pay within ' + this.avaragePaymentRating + ' days';
            }
          } else {
            this.lawfirmRatingLabel = 'No data available.';
          }
        },
        error: (error) => {
          // On error
          this.lawfirmRatingLabel = 'No data available.';
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.workingHistorical.ServiceID > 0) {
      this.title = this.workingHistorical.Description;
    } else {
      this.title = 'new matter';
    }
    console.log('check law firm', changes.lawFirms)
    if (changes.lawFirms && changes.lawFirms.currentValue) {
      this.isLoading = false;
    }
    if (changes.matter && changes.matter.currentValue) {
      //this.workingMatter = this.util.objectCopy(this.matter);
   }
   this.ngAfterViewInit();
   console.log('Working Historical: ', this.workingHistorical)
   console.log('Historing: ',  this.historical)
   console.log('NEW DATE: ', new Date().toISOString())
  } // end ngOnCHanges()

  sortAttorneysList() {
    if (this.attorneys.length > 0) {
      this.attorneys.sort((a, b) => {
        if (a && a.ClientFullName !== undefined && b && b.ClientFullName !== undefined) {
        return a.ContactFullName.toUpperCase().localeCompare(a.ContactFullName.toUpperCase());
        }
      });
    }
  }

  onModelChange(type: string, contact: any) {
    switch (type) {
      case 'law-firms':
        // console.log('SELECTED: ', contact)
        // Re-build attorneys list
        if (contact.ContactID) {
          // this.workingHistorical.ContactID = contact.ContactID;
          // this.workingHistorical.NewClientDetail = contact.FullName;
          this.isLawfirmRatingLoading = true;
          this.attorneysService.getLawfirmRating(contact.ContactID).subscribe({next:
            (avarage_rating) => {
              if (avarage_rating) {
                this.avaragePaymentRating = avarage_rating.IntAvgDays;
                if (avarage_rating.AverageDaysToPayment === 'Not Applicable') {
                  this.lawfirmRatingLabel = 'No data available.';
                } else {
                  this.lawfirmRatingLabel = 'On avarage pay within ' + this.avaragePaymentRating +  ' days';
                }
                // this.lawfirmRatingLabel = 'On avarage pay within ' + this.avaragePaymentRating + ' days';
              } else {
                this.lawfirmRatingLabel = 'No data available.';
              }
            },
            error: (error) => {
              // On error
              console.log('ERROR getLawfirmRating: ', error)
              this.isLawfirmRatingLoading = false;
              this.lawfirmRatingLabel = 'No data available.';
            },
            complete: () => {
              // On complete
              this.isLawfirmRatingLoading = false;
            }
          });
          this.attorneysService.getAttorneysByLawFirms(contact.ContactID).subscribe({ next:
            (contacts) => {
              this.attorneys = contacts;
              this.sortAttorneysList();
            },
            error: (error) => {
              const msg = 'Error loading Attorney list.';
              console.error(msg, error);

            },
            complete: () => {
              if (contact.FullName) {
                 // Reset attorneys field
                this.workingHistorical.ClientContactID = 0;
                this.workingHistorical.NewClientDetail = '';
            }
          }
        });
        } else {
          contact.FullName = '';
          contact.ContactID = 0;
        }
        break;

      case 'attorneys':
        //this.workingHistorical.NewClientDetail = contact.FullName;
        // this.workingHistorical.DefendantFullName = contact.FullName;
        this.workingHistorical.ClientContactID = contact.ContactID;
        this.matterAttorneyChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'plaintiffs':
        // if (contact.target.value) {
        //this.workingHistorical.PlaintiffFullName = contact.FullName;
        this.workingHistorical.PlaintiffContactID = contact.ContactID;
        // }
        this.matterPlaintiffsChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;
      case 'other-clients':
        this.workingHistorical.PlaintiffContactID = contact.ContactID;
        // }
        this.matterOtherClientsChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        this.workingHistorical.PlaintiffContactID = 0;
        break;

      case 'defendants':
        // if (contact.target.value) {
        //this.workingHistorical.DefendantFullName = contact.FullName;
        this.workingHistorical.DefendantContactID = contact.ContactID;
        // }
        this.matterDefendantsChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'attorney-reference':
        this.workingHistorical.ClientRefNo = contact.FullName;
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'instructions':
        this.workingHistorical.Instructions = contact.FullName;
        contact.FullName = '';
        contact.ContactID = 0;
        break;
    }
    //this.workingHistoricalChange.emit(this.workingHistorical);
  } // end onModelChange()

  onReferenceChangeCheck() {
    // console.log('ON REFERENCE CHANGE: ', this.workingHistorical)
    if (this.workingHistorical.ServiceID === 0) {
      // const ref_matter = this._matters.filter(a => a.ClientRefNo === this.workingHistorical.ClientRefNo);
      // if (ref_matter.length > 0) {
      //   this.showMatterExistsPrompt = true;
      // }
    }
  } // end onReferenceChangeCheck()

  onHourlyRateChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusHourly.nativeElement.value =
      this.cpipe.transform(this.focusHourly.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
        this.focusHourly.nativeElement.value =
        this.cpipe.transform(this.focusHourly.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onHourlyRateChange()

  onHourlyRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusHourly.nativeElement.value =
      this.cpipe.parse(this.focusHourly.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusHourly.nativeElement.value =
      this.cpipe.parse(this.focusHourly.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onHourlyRateClicked()

  onDailyRateChange() {
    if (!this.focusDaily.nativeElement.value.startsWith('R')) {
      if (this.userProfileService.userProfile.isImpersonator) {
        this.focusDaily.nativeElement.value =
        this.cpipe.transform(this.focusDaily.nativeElement.value,
          this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
      } else {
        this.focusDaily.nativeElement.value =
        this.cpipe.transform(this.focusDaily.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol, 2);
      }
    }
  } // end onDailyRateChange()

  onDailyRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusDaily.nativeElement.value =
      this.cpipe.parse(this.focusDaily.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
      } else {
      this.focusDaily.nativeElement.value =
      this.cpipe.parse(this.focusDaily.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
      }
  } // end onDailyRateClicked()

  parseServiceDate(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return null;
    }
  } // end parseServiceDate()

  onDateValueChange(value: Date): void {
    this.workingHistorical.ServiceDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  } // end onDateValueChange()

} // end HistoricalDetailsComponent()
