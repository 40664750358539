import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

export interface ClientContact {
  ID: number;
  ClientID: number;
  ClientFullName: string;
  City: string;
  State: string;
  ContactID: number;
  ContactFullName: string;
  ClientFullDescription: string;
}

export const DEFAULT_CLIENT_CONTACT = {
  ID: 0,
  ClientID: 0,
  ClientFullName: '',
  City: '',
  State: '',
  ContactID: 0,
  ContactFullName: '',
  ClientFullDescription: ''
};

@Injectable()
export class ClientContactsService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
  ) { }

  /**
   * GET: returns all contacts for a given client (id). In the case of legal,
   * will return all attorneys associated with a Law Firm.
   * @param {number} id The unique identifier of the client.
   * @returns {Observable} Returns all contacts for a given client (id).
   */
  getClientContacts(id: number): Observable<any> {
    return this.http.get(this.api.endpoints.clientContacts +
    '/' + id, this.api.httpOptions).pipe(
      retry(3),
      catchError(this.api.handleError)
    );
  } // end getClientContacts()
}
