import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { SnackbarsService } from '../messaging/snackbars/snackbars.service';
import { UtilitiesService } from '../utilities.service';
import { of ,  Observable } from 'rxjs';
import { CHART_COLOR_GREEN, CHART_COLOR_YELLOW, CHART_COLOR_RED } from '../appdata/app-config.service';
import { catchError } from 'rxjs/operators';

const DEFAULT_MIN_TICK_VALUE = 0;
const DEFAULT_MAX_TICK_VALUE = 30000000;
const CHART_BAR_THICKNESS = 25;
const CHART_BAR_PERCENTAGE = 25;
const BASE_FONT_COLOR = '#4a4a4a';

export interface InvoicedMonthly {
  ServiceProviderId: number;
  InvoicedMaxValue: number;
  InvoicedOptimalRangeStartValue: number;
  InvoicedOptimalRangeEndValue: number;
  WorkSummary: string;
  WorkScore: number;
  SymbolValue: string;
  ThisDescription: string;
  LastDescription: string;
  Last2Description: string;
  ThisTotalExcl: string;
  LastTotalExcl: string;
  Last2TotalExcl: string;
  Target: string;
  Record: string;
}

export interface InvoicedYearly {
  ServiceProviderId: number;
  InvoicedMaxValue: number;
  InvoicedOptimalRangeStartValue: number;
  InvoicedOptimalRangeEndValue: number;
  SymbolValue: string;
  ThisDescription: string;
  LastDescription: string;
  Last2Description: string;
  ThisTotalExcl: string;
  LastTotalExcl: string;
  Last2TotalExcl: string;
  Target: string;
  Record: string;
}

export interface CollectedMonthly {
  ServiceProviderId: number;
  MaxValue: number;
  OptimalRangeStartValue: number;
  OptimalRangeEndValue: number;
  SymbolValue: string;
  ThisDescription: string;
  LastDescription: string;
  Last2Description: string;
  ThisTotalExcl: string;
  LastTotalExcl: string;
  Last2TotalExcl: string;
  Target: string;
  Record: string;
}

export interface CollectedYearly {
  ServiceProviderId: number;
  MaxValue: number;
  OptimalRangeStartValue: number;
  OptimalRangeEndValue: number;
  SymbolValue: string;
  ThisDescription: string;
  LastDescription: string;
  Last2Description: string;
  ThisTotalExcl: string;
  LastTotalExcl: string;
  Last2TotalExcl: string;
  Target: string;
  Record: string;
}

export interface PracticeWidgetSummary {
  InvoicedTarget: number;
  CollectionTarget: number;
  AdvocateReviews: number;
  InvoicingAdvocates: number;
}
export const DEFAULT_PRACTICE_WIDGET_SUMMARY: PracticeWidgetSummary = {
  AdvocateReviews: 0,
  CollectionTarget: 0,
  InvoicedTarget: 0,
  InvoicingAdvocates: 0
};

export interface OutstandingBookSummary {
  LawfirmId: number;
  Lawfirm: string;
  Due: number;
}

export const DEFAULT_OUTSTANDING_BOOK_SUMMARY: OutstandingBookSummary = {
  LawfirmId: 0,
  Lawfirm: '',
  Due: 0
};

export interface PracticeOverDueSummary {
  LawfirmPercentage: number;
  TotalOverdue: number;
}

export const DEFAULT_PRACTICE_OVERDUE_SUMMARY: PracticeOverDueSummary = {
  LawfirmPercentage: 0,
  TotalOverdue: 0
};

export interface OverDueSummary {
  LawfirmPercentage: number;
  TotalOverdue: number;
  outstandingBookSummary: OutstandingBookSummary[];
}

export const DEFAUL_OVERDUE_SUMMARY: OverDueSummary = {
  outstandingBookSummary: [],
  LawfirmPercentage: 0,
  TotalOverdue: 0
};

@Injectable()
export class PracticeManagerDashboardService {

  invoicedMonthlyData: any = {
    monthly: {
      invoicedThismonthLabel: '',
      invoicedLastMonthLabel: '',
      invoicedMonthBeforeLast: '',

      // invoicedTotalValue: 0,
      invoicedThismonthValue: 0,
      invoicedLastMonthValue: 0,
      invoicedMonthBeforValue: 0,

      invoicedRangeStart: 0,
      invoicedRangeEnd: 0,
      invoicedMaxValue: 0,
      // invoicedScore: 0,
      invoicedMonthlyTarget: '',
      invoicedMonthlyRecord: ''
    }
  };

  invoicedYearlyData: any = {
    yearly: {
      thisYearInvoicedLabel: '',
      lastYearInvoicedLabel: '',
      last2YearsInvoicedLabel: '',

      thisYearInvoicedTotalValue: 0,
      lastYearInvoicedValue: 0,
      last2YearsInvoicedValue: 0,

      thisYearInvoicedRangeStart: 0,
      thisYearInvoicedRangeEnd: 0,
      thisYearInvoicedMaxValue: 0,
      thisYearInvoicedScore: 0,
      InvoicedYearlyTarget: '',
      InvoicedYearlyRecord: ''
    }
  };

  collectedMonthlyData: any = {
    monthly: {
      receiptThismonthLabel: '',
      receiptLastMonthLabel: '',
      receiptMonthBeforeLast: '',

      // receiptTotalValue: 0,
      receiptThismonthValue: 0,
      receiptLastMonthValue: 0,
      receiptMonthBeforValue: 0,

      receiptRangeStart: 0,
      receiptRangeEnd: 0,
      receiptMaxValue: 0,
      // receiptScore: 0,
      receiptMonthlyTarget: '',
      receiptMonthlyRecord: ''
    }
  };

  collectedYearlyData: any = {
    yearly: {
      receiptThisYearLabel: '',
      receiptLastYearLabel: '',
      receiptYearBeforeLast: '',

      receiptTotalYearValue: 0,
      receiptThisYearValue: 0,
      receiptLastYearValue: 0,
      receiptYearBeforValue: 0,

      receiptYearRangeStart: 0,
      receiptYearRangeEnd: 0,
      receiptYearMaxValue: 0,
      receiptYearScore: 0,
      receiptYearlyTarget: '',
      receiptYearlyRecord: '',
    }
  };

  invoicedMonthly: InvoicedMonthly;
  invoicedYearly: InvoicedYearly;
  collectedMonthly: CollectedMonthly;
  collectedYearly: CollectedYearly;

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private snackbarsService: SnackbarsService,
    private util: UtilitiesService
  ) { } // end constructor()

  getInvoicedData(invoicedMonthly: InvoicedMonthly): Observable<any> {
    const invoicedValues = {
      monthly: [
        invoicedMonthly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedMonthly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedMonthly.Last2TotalExcl.replace(/[A-Z\s,]/gi, '')
      ]
    };
    const invoicedColors = {
      monthly: this.getBarChartColors(invoicedValues.monthly,
        invoicedMonthly.InvoicedMaxValue)
    };

    this.invoicedMonthlyData = {
      monthly: {
        invoicedThismonthLabel: invoicedMonthly.ThisDescription,
        invoicedLastMonthLabel: invoicedMonthly.LastDescription,
        invoicedMonthBeforeLast: invoicedMonthly.Last2Description,

        // invoicedTotalValue: invoicedMonthly.workScore,
        invoicedThismonthValue: invoicedMonthly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedLastMonthValue: invoicedMonthly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedMonthBeforValue: invoicedMonthly.Last2TotalExcl.replace(/[A-Z\s,]/gi, ''),

        invoicedRangeStart: invoicedMonthly.InvoicedOptimalRangeStartValue,
        invoicedRangeEnd: invoicedMonthly.InvoicedOptimalRangeEndValue,
        invoicedMaxValue: invoicedMonthly.InvoicedMaxValue,
        // invoicedScore: invoicedMonthly.invoicedMaxValue,
        invoicedMonthlyTarget: invoicedMonthly.Target,
        invoicedMonthlyRecord: invoicedMonthly.Record
      }
    };
    return of(this.invoicedMonthlyData);
  } // end getInvoicedData()

  getInvoicedYearlyData(invoicedYearly: InvoicedYearly): Observable<any> {
    const invoicedValues = {
      yearly: [
        invoicedYearly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedYearly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedYearly.Last2TotalExcl.replace(/[A-Z\s,]/gi, '')
      ]
    };
    const invoicedYearlyRecord =
      invoicedYearly.Record.replace(/[A-Z\s,]/gi, '');

    const invoicedColors = {
      yearly: this.getBarChartColors(invoicedValues.yearly,
        invoicedYearlyRecord)
    };

    this.invoicedYearlyData = {
      yearly: {
        thisYearInvoicedLabel: invoicedYearly.ThisDescription,
        lastYearInvoicedLabel: invoicedYearly.LastDescription,
        last2YearsInvoicedLabel: invoicedYearly.Last2Description,

        thisYearInvoicedTotalValue: invoicedYearly
          .ThisTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        lastYearInvoicedValue: invoicedYearly
          .LastTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        last2YearsInvoicedValue: invoicedYearly
          .Last2TotalExcl
          .replace(/[A-Z\s,]/gi, ''),

        thisYearInvoicedRangeStart: invoicedYearly.InvoicedOptimalRangeStartValue,
        thisYearInvoicedRangeEnd: invoicedYearly.InvoicedOptimalRangeEndValue,
        thisYearInvoicedMaxValue: invoicedYearly.InvoicedMaxValue,
        InvoicedYearlyTarget: invoicedYearly.Target,
        InvoicedYearlyRecord: invoicedYearly.Record
      }
    };
    return of(this.invoicedYearlyData);
  } // end getInvoicedYearlyData()

  getCollectedData(collectedMonthly: CollectedMonthly): Observable<any> {
    const collectedValues = {
      monthly: [
        collectedMonthly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        collectedMonthly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        collectedMonthly.Last2TotalExcl.replace(/[A-Z\s,]/gi, '')
      ]
    };

    const collectedMonthlyTarget =
      collectedMonthly.Target.replace(/[A-Z\s,]/gi, '');
    const collectedMonthlyRecord =
      collectedMonthly.Record.replace(/[A-Z\s,]/gi, '');

    const collectedColors = {
      monthly: this.getBarChartColors(collectedValues.monthly,
        collectedMonthlyRecord)
    };

    this.collectedMonthlyData = {
      monthly: {
        receiptThismonthLabel: collectedMonthly.ThisDescription,
        receiptLastMonthLabel: collectedMonthly.LastDescription,
        receiptMonthBeforeLast: collectedMonthly.Last2Description,

        // receiptTotalValue: collectedMonthly.collectedScore,
        receiptThismonthValue: collectedMonthly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        receiptLastMonthValue: collectedMonthly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        receiptMonthBeforValue: collectedMonthly.Last2TotalExcl.replace(/[A-Z\s,]/gi, ''),

        receiptRangeStart: collectedMonthly.OptimalRangeStartValue,
        receiptRangeEnd: collectedMonthly.OptimalRangeEndValue,
        receiptMaxValue: collectedMonthly.MaxValue,
        // receiptScore: collectedMonthly.workScore,
        receiptMonthlyTarget: collectedMonthly.Target,
        receiptMonthlyRecord: collectedMonthly.Record
      }
    };
    return of(this.collectedMonthlyData);
  } // end getCollectedData()

  getCollectedYearlyData(collectedYearly: CollectedYearly): Observable<any> {
    const collectedValues = {
      yearly: [
        collectedYearly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        collectedYearly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        collectedYearly.Last2TotalExcl.replace(/[A-Z\s,]/gi, '')
      ]
    };

    const collectedYearlyTarget =
      collectedYearly.Target.replace(/[A-Z\s,]/gi, '');
    const collectedYearlyRecord =
      collectedYearly.Record.replace(/[A-Z\s,]/gi, '');

    const collectedColors = {
      yearly: this.getBarChartColors(collectedValues.yearly,
        collectedYearlyRecord)
    };

    this.collectedYearlyData = {
      yearly: {
        receiptThisYearLabel: collectedYearly.ThisDescription,
        receiptLastYearLabel: collectedYearly.LastDescription,
        receiptYearBeforeLast: collectedYearly.Last2Description,

        // receiptTotalYearValue: dashboardData.collectedScore,
        receiptThisYearValue: collectedYearly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        receiptLastYearValue: collectedYearly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        receiptYearBeforValue: collectedYearly.Last2TotalExcl.replace(/[A-Z\s,]/gi, ''),

        receiptYearRangeStart: collectedYearly.OptimalRangeStartValue,
        receiptYearRangeEnd: collectedYearly.OptimalRangeEndValue,
        receiptYearMaxValue: collectedYearly.MaxValue,
        // receiptYearScore: dashboardData.collectedScore,
        receiptYearlyTarget: collectedYearly.Target,
        receiptYearlyRecord: collectedYearly.Record
      }
    };
    return of(this.collectedYearlyData);
  } // end getCollectedData()

  getBarChartColors(
    chartValues: Array<any>,
    targetValue: any = null
  ): Array<string> {
    const barChartColors = [];

    const threshold = targetValue
      ? Math.round(targetValue as number)
      : Math.round(Math.max(...chartValues as number[]));

    chartValues.forEach(value => {
      const roundedValue = Math.round(value);

      if (roundedValue >= threshold) {
        barChartColors.push(CHART_COLOR_GREEN);

      } else if (roundedValue < threshold &&
        roundedValue >= (1 / 2) * threshold) {
        barChartColors.push(CHART_COLOR_YELLOW);

      } else if (roundedValue < (1 / 2) * threshold) {
        barChartColors.push(CHART_COLOR_RED);

      }
    });

    return barChartColors;
  } // end getBarChartColors()

  /**
   * Returns the number of ticks to render for a chart based on the
   * given chart values. This function returns tick values that will
   * produce the most even spacing between ticks.
   * @param {Array<*>} chartValues An array of chart values.
   * @return {number} Returns the number of ticks to render for a chart.
   */
  getMaxTicks(chartValues: Array<number>, maxValue: number = null): number {
    let maxTick = DEFAULT_MAX_TICK_VALUE;
    if (!maxValue) {
      maxValue = Math.max(...chartValues);
    }
    const baseTickValue = DEFAULT_MAX_TICK_VALUE;

    if (maxValue < (baseTickValue / 8)) {
      maxTick = (baseTickValue / 8);
    } else if (maxValue < (baseTickValue / 4)) {
      maxTick = (baseTickValue / 4);
    } else if (maxValue < (baseTickValue / 2)) {
      maxTick = (baseTickValue / 2);
    } else if (maxValue < baseTickValue) {
      maxTick = baseTickValue;
    } else if (maxValue > baseTickValue) {
      maxTick = (baseTickValue * 2);
    } else if (maxValue > baseTickValue * 2) {
      maxTick = (baseTickValue * 4);
    } else if (maxValue > baseTickValue * 4) {
      maxTick = (baseTickValue * 8);
    }

    return maxTick;
  } // end getMaxTicks()

  getInvoicedMonthly(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceDashboardDataInvoicedMonthly +
      '?practiceManagerId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getInvoicedMonthly()

  getInvoicedYearly(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceDashboardDataInvoicedYearly +
      '?practiceManagerId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getInvoicedYearly()

  getCollectedMonthly(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceDashboardDataCollectedMonthly +
      '?practiceManagerId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getCollectedMonthly()

  getCollectedYearly(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceDashboardDataCollectedYearly +
      '?practiceManagerId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getCollectedYearly()

  getPracticeWidget(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceWidgetSummary +
      '?practiceManagerId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getPracticeWidget()

  getPracticedueOverSummary(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceOverdueSummary +
      '?practiceManagerId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getPracticeOverdueSummary()

} // end PracticeManagerDashboardService{}
