<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h5 style="width: 95%;">{{title}}</h5>
                <button class="modal-dismiss"  (click)="onCancel()">
                    <i class="far fa-times-circle"></i>
                </button>
            </div>

            <div  class="modal-body">
                <div class="container-fluid">
                    <div class="row">

                    </div>

                    <div class="row">
                        <label for="documentType" style="width: 100%;">Document Type</label>
                        <select
                            id="documentType"
                            [(ngModel)]="selectedDocumentType"
                            style="width: 100%;">
                            <option value="0" disabled selected hidden>Select document type</option>
                            <option *ngFor="let document of documentTypes" [value]="document.ID">{{document.DocumentType}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn btn-danger" (click)="onCancel()">{{btnTextCancel}}</button>
                <button
                    [class.disabled]="!canAddFile"
                    [disabled]="!canAddFile"
                    class="btn btn-success"
                    (click)="onAddFile()">{{btnTextAddFile}}</button>
            </div>

        </div>
    </div>
</div>