import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { UtilitiesService } from '../../services/utilities.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../services/messaging/snackbars/snackbars.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { ActivityLogsService, DEFAULT_ACTIVITY_LOGS, ActivityLogs } from '../../services/activity-logs/activity-logs.service';
import { ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import { InvoiceDocumentService } from '../../services/invoice-document/invoice-document.service';
import {
  QuotationSummary,
  DEFAULT_QUOTATION_SUMMARY,
  QuotationService,
  DEFAULT_QUOTATION_NOTE,
  QuotationNote
} from '../../services/quotation/quotation.service';
import * as moment from 'moment';
import { PATH_QUOTATION, PATH_QUOTATION_EMAIL } from '../../services/appdata/app-config.service';
import { MessagesService } from '../../services/messaging/messages/messages.service';

@Component({
  selector: 'app-advocate-quotation-preview',
  templateUrl: './advocate-quotation-preview.component.html',
  styleUrls: ['./advocate-quotation-preview.component.scss']
})
export class AdvocateQuotationPreviewComponent implements OnInit {

  fileName;
  quotationId = 0;
  crumbs: ICrumb[];
  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);
  quotationNote: QuotationNote = Object.assign({}, DEFAULT_QUOTATION_NOTE);
  workingQuotation: QuotationSummary = Object.assign({}, DEFAULT_QUOTATION_SUMMARY);

  @ViewChild('pdfViewer') public pdfViewer;

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private messagesServices: MessagesService,
    private quotationService: QuotationService,
    private snackbarsService: SnackbarsService,
    private navigationService: NavigationService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private invoiceDocumentService: InvoiceDocumentService,
  ) { } // end constructor()

  ngOnInit() {
    this.quotationId = +this.route.snapshot.paramMap.get('id');
    this.crumbs = [
      {
        label: 'Quotation Preview'
      }
    ];
    this.loadingService.showOverlay();
    this.quotationService.getQuotationById(this.quotationId).subscribe({
      next: (_quote) => {
        this.workingQuotation = _quote;
      }
    });
    const url = this.api.endpoints.invoiceDocument +
      '/Quotation?quotationId=' + this.quotationId;

    const snack: Snack = {
      label: 'Loading quotation document...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.invoiceDocumentService.getQuotationDocument(url)
      .subscribe({ next: (quotation_document) => {
        // On next
        this.pdfViewer.name = 'Quotation.pdf';
        this.pdfViewer.pdfSrc = quotation_document;
        this.pdfViewer.refresh();
      },
      error: (error) => {
        // On error
        snack.label = 'Error loading quotation.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },  complete: () => {
        // On complete
        snack.label = 'Quotation document loaded.';
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
    }
  });
  } // end ngOnInit()

  onSaveNote() {
    const snack: Snack = {
      label: 'Saving quotation note...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.loadingService.showOverlay();

    //  this.messagesServices.getQuotationMessage(this.quotationId, 'Quotation').toPromise();
    this.quotationNote.Notes = this.workingQuotation.Notes;
    this.quotationNote.Id = this.workingQuotation.Id;
    this.quotationService.updateQuotationNote(this.quotationNote)
      .subscribe({ next: (note) => {
        // On next
      },
      error: (error) => {
        // On error

        const currectDate = new Date();
        this.activityLog.Action = 'Save Quotation note';
        this.activityLog.ActionTimeStamp = moment(currectDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (Advocate-quotation-preview)';
        if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'Quotation - Failed: ' + error.Message;
        this.activityLog.JsonData = JSON.stringify(this.quotationNote);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_error_note) => {}});

        snack.label = 'Error saving quotation note.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        // Log this.activity
        const currectDate = new Date();
        this.activityLog.Action = 'Save Quotation note';
        this.activityLog.ActionTimeStamp = moment(currectDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (Advocate-quotation-preview)';
        if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'Quotation - Success';
        this.activityLog.JsonData = JSON.stringify(this.quotationNote);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_savenote) => {}});

        this.onSendViaEmail();

        snack.label = 'Quotation note saved';
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        // this.loadingService.hideOverlay();
      }
    });
  } // end onSaveNote()

  onSendViaEmail() {
    // this.loadingService.showOverlay();
    this.router.navigate([PATH_QUOTATION_EMAIL, this.quotationId]);
    this.loadingService.hideOverlay();
  } // end onSendViaEmail()

  onEditQuotation() {
    if (this.workingQuotation.QuotationStatus === 'Outbox') {
      const snack: Snack = {
        label: 'Revise quotation...',
        action: null
      };
      this.snackbarsService.make(snack, 5000).show();
      this.quotationService.reviseQuotation(this.workingQuotation.Id).subscribe({ next: 
        (revisedQuotation) => {
          // On next
        },
        error: (error) => {
          // On next
          snack.label = 'Error revising quotation.';
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
        },
        complete: () => {
          // On complete
          snack.label = 'Quotation revised';
          snack.type = SnackType.SUCCESS;
          this.snackbarsService.dismiss().make(snack).show();
          this.router.navigate([PATH_QUOTATION, this.workingQuotation.MatterQuotationId]);
        }
      });
    } else {
      this.router.navigate([PATH_QUOTATION, this.workingQuotation.MatterQuotationId]);
    }
  } // end onEditQuotation()
} // end AdvocateQuotationPreviewComponent{}
