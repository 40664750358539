import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgeAnalysisData, DEFAULT_AGE_ANALYSIS_DATA } from '../../services/age-analysis/age-analysis.service';
import {
  LiveStatementService,
  StatementAdvocateSummary,
  StatementByAdvocate,
  ThreeMonthsOutstandingLawFirm
} from '../../services/live-statement/live-statement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../services/messaging/snackbars/snackbars.service';
import {
  ICrumb,
  AdvocateDetailStatementService,
  AdvocateDetailStatement,
  LawfirmPaidStatement} from '../../services/advocate-detail-statement/advocate-detail-statement.service';
// import * as moment from 'moment';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { interval, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-attorney-dashboard',
  templateUrl: './attorney-dashboard.component.html',
  styleUrls: ['./attorney-dashboard.component.scss']
})
export class AttorneyDashboardComponent implements OnInit, OnDestroy {

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    LAWFIRM: 'Lawfirm',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTENAL_LAWFIRM: 'Lawfirm (External)',
    EXTENAL_ATTORNEY: 'Attorney (External)',
    BAR_SOCIETY: 'Bar Society - User',
    GCB: 'GCB - User',
    ADHOC: 'Ad-hoc'
  };

  ageAnalysisData: AgeAnalysisData;
  statementAdvocateSummary: StatementAdvocateSummary;
  statementByAdvocate: StatementByAdvocate[];
  threeMonthsFirmData: ThreeMonthsOutstandingLawFirm;
  showReportTable: boolean;
  crumbs: ICrumb[];
  advocateDetailStatement: AdvocateDetailStatement[];
  lawfirmPaidStatement: LawfirmPaidStatement[] = [];

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private liveStatementService: LiveStatementService,
    private advocateDetailStatementService: AdvocateDetailStatementService
  ) { }

  // Is Internal Attorney
  get isExternalAttorney(): boolean {
    if (!this.userProfileService)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTENAL_ATTORNEY);
  } // end isExternalAttorney()

  async ngOnInit() {
    this.userProfileService.isInAttorneyDashboardScreen = true;
    this.loadingService.showOverlay();
    this.ageAnalysisData = Object.assign({}, DEFAULT_AGE_ANALYSIS_DATA);

    const snack: Snack = {
      label: 'Loading live statement..',
      action: null
    };

    this.crumbs = [
      {
        label: 'LIVE STATEMENT'
      }
    ];
    if (this.userProfileService.userProfile.profileType !== this.profileTypes.EXTENAL_ATTORNEY) {
      this.getChartData();

    }
    this.snackbarsService.snackbarComponent.make(snack).show();
    // Fetch Age Analysis Data
    // Get live statement data
    this.liveStatementService.getAdvocateStatementByStateAdvocateSummary()
    .subscribe({ next: (advocates) => {
      if (advocates) {
        this.statementByAdvocate = advocates.StatementByAdvocate;
        this.ageAnalysisData = {
          NormalDay: advocates.ZeroDays,
          ZeroDays: advocates.ZeroDays,
          ThirtyDays: advocates.ThirtyDays,
          SixtyDays: advocates.SixtyDays,
          NinetyDays: advocates.NinetyDays,
          NinetyEightDays: advocates.NinetyEightDays,
          InvoiceStatusGroup: advocates.InvoiceStatusGroup,
          Total: advocates.Total
        };
      }
    },
    error: (error) => {
      // On error
      snack.label = 'An error loading live statement.';
      snack.type = SnackType.ERROR;
      this.snackbarsService.dismiss().make(snack).show();
      this.loadingService.hideOverlay();
    },
    complete: () => {
      snack.label = 'Live statement loaded.';
      snack.type = SnackType.SUCCESS;
      this.snackbarsService.dismiss().make(snack).show();
      this.loadingService.hideOverlay();
    }
  });

//   // Parse the value from sessionStorage
// let storedAdvocateDetailStatement = JSON.parse(sessionStorage.getItem('advocateDetailStatement'));
// // Check if storedAdvocateDetailStatement exists in sessionStorage
// if (storedAdvocateDetailStatement && storedAdvocateDetailStatement.length > 0) {
//   // If advocateDetailStatement exists, compare it with the current this.advocateDetailStatement
//   if (JSON.stringify(this.advocateDetailStatement) !== JSON.stringify(storedAdvocateDetailStatement)) {
//     // If they are not the same, reload from service and update sessionStorage
//     await lastValueFrom(this.advocateDetailStatementService.getLawfirmStatement(this.api.serviceProviderID)).then((data) => {
//       this.advocateDetailStatement = data;
//       sessionStorage.setItem('advocateDetailStatement', JSON.stringify(this.advocateDetailStatement));
//       // console.log('ATTORNEY DASHOBOARD DATA VVI: ', data);
//     });
//   }
// } else {
//   // If sessionStorage doesn't have data, fetch from service and set it
//   await lastValueFrom(this.advocateDetailStatementService.getLawfirmStatement(this.api.serviceProviderID)).then((data) => {
//     this.advocateDetailStatement = data;
//     // console.log('ATTORNEY DASHOBOARD DATA VVPI: ', data);
//     sessionStorage.setItem('advocateDetailStatement', JSON.stringify(this.advocateDetailStatement));
//   });
// }

    this.lawfirmPaidStatement = JSON.parse(localStorage.getItem('lawfirmPaidStatement'));
    if (this.lawfirmPaidStatement && this.lawfirmPaidStatement.length > 0) {

    } else {
      await lastValueFrom(this.advocateDetailStatementService.getPaidStatement()).then((data) => {
        this.lawfirmPaidStatement = data;
        localStorage.setItem('lawfirmPaidStatement', JSON.stringify(this.lawfirmPaidStatement));
      });
    }
  } // end ngOnInit()

  // async getOutStandingInvoices() {
  //   // Outstanding invoices
  //   this.advocateDetailStatement = JSON.parse(sessionStorage.getItem('advocateDetailStatement'));
  //   if (this.advocateDetailStatement && this.advocateDetailStatement.length > 0) {

  //   } else {
  //     this.advocateDetailStatementService.getLawfirmStatement(this.api.serviceProviderID).subscribe({ next: (data) => {
  //       this.advocateDetailStatement = data;
  //     },
  //   complete: () => {
  //     sessionStorage.setItem('advocateDetailStatement', JSON.stringify(this.advocateDetailStatement));
  //   }});
  //   }
  // } // end getOutStandingInvoices()

  // async getBankTransactions() {
  //   // Bank transaction and paid invoices
  //   this.lawfirmPaidStatement = JSON.parse(localStorage.getItem('lawfirmPaidStatement'));
  //   if (this.lawfirmPaidStatement && this.lawfirmPaidStatement.length > 0) {

  //   } else {
  //     this.advocateDetailStatementService.getPaidStatement().subscribe({ next: (data) => {
  //       this.lawfirmPaidStatement = data;
  //     },
  //   complete: () => {
  //     localStorage.setItem('lawfirmPaidStatement', JSON.stringify(this.lawfirmPaidStatement));
  //   }});
  //   }
  // } // end getBankTransactions()

  ngOnDestroy() {
    this.userProfileService.isInAttorneyDashboardScreen = false;
  } // end ngOnDestroy()

  receiveAllCustomer(event) {

  } // end receiveAllCustomer()

  getChartData() {
    this.liveStatementService.getThreeMonthsLawfirmData().subscribe({ next: (data) => {
      this.threeMonthsFirmData = data;
    }});
  } // end getChartData()
} // end AttorneyDashboardComponent()
