import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AdvocateDetailStatement } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { MattersService, ServiceReference, DEFAULT_SERVICE_REFERENCE } from '../../../services/matters/matters.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-update-reference-modal',
  templateUrl: './update-reference-modal.component.html',
  styleUrls: ['./update-reference-modal.component.scss']
})
export class UpdateReferenceModalComponent implements OnInit, OnChanges {

  title: string;
  @Input() isVisible: boolean;
  @Input() referenceData: AdvocateDetailStatement;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() saveReference: EventEmitter<any> = new EventEmitter<any>();

  workingReferenceData: AdvocateDetailStatement;
  serviceReference: ServiceReference = Object.assign({}, DEFAULT_SERVICE_REFERENCE);

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.workingReferenceData,  this.referenceData);
  } // end isDirty()

  get isSaveable(): boolean {
    return this.isDirty;
  } // end isSaveable()

  constructor(
    private util: UtilitiesService,
    private matterService: MattersService,
    private loadingService: LoadingService,
  ) { } // end constructor()

  ngOnInit() {
    this.title = 'Edit Reference';
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.referenceData && changes.referenceData.currentValue) {
      this.title = 'Edit Reference for: ' + this.referenceData.ServiceDescription;
      this.initData();
    }
  } // end ngOnChanges()

  dismiss() {
    this.closeModal.emit();
  } // end dismiss()

  save() {
    this.loadingService.showOverlay();
    this.serviceReference.Reference = this.workingReferenceData.Reference;
    this.serviceReference.ServiceId = this.workingReferenceData.ServiceId;
    this.matterService.updateReference(this.serviceReference).subscribe({
      next: (data) => {
        // On Next
      },
      error: (error) => {
        // on Error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.saveReference.emit(this.workingReferenceData);
        // this.loadingService.hideOverlay();
      }
    });
  } // end save()

  initData() {
    this.workingReferenceData = this.util.objectCopy(this.referenceData);
  } // end initData()

} // end UpdateReferenceModalComponent{}
