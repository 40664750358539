import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appFileDrop]'
})
export class FileDropDirective {

  @Output() fileDropped = new EventEmitter<FileList>();
  @Output() filesHovered = new EventEmitter<boolean>();

  constructor() { } // end constructor()

  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();

    let transfer = $event.dataTransfer;
    this.fileDropped.emit(transfer.files);
    this.filesHovered.emit(false);
  } // end onDrop()

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    event.preventDefault();

    this.filesHovered.emit(true);
  } // end onDragOver()

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    this.filesHovered.emit(false);
  }

} // end FileDropDirective{}
