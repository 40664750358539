import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancelled-subscription',
  templateUrl: './cancelled-subscription.component.html',
  styleUrls: ['./cancelled-subscription.component.scss']
})
export class CancelledSubscriptionComponent implements OnInit {

  constructor() { } // end constrcutor()

  ngOnInit() {
  } // end ngOnInit()

} // end CancelledSubscriptionComponent{}
