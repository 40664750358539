import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { 
    ContactLocalities,
    Contacts,
    ContactsService,
    DEFAULT_CONTACTS,
    DEFAULT_CONTACT_LOCALITIES } from '../../../services/contacts/contacts.service';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-add-new-law-firm',
  templateUrl: './add-new-law-firm.component.html',
  styleUrls: ['./add-new-law-firm.component.scss']
})
export class AddNewLawFirmComponent implements OnInit {

  title = 'Add new Law Firm';
  btnSave = 'Save';
  message: string;
  showPhoneNumberAlreadyExists: boolean;
  workingLawfirm: Contacts = DEFAULT_CONTACTS;
  selectedLocality: ContactLocalities = DEFAULT_CONTACT_LOCALITIES;
  contactLocalities: ContactLocalities[] = [];

  @Input() isVisible: boolean;
  @Input() selectedLawfirm: Contacts;
  @Input() isManageBlacklistUser: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaveLawFirm: EventEmitter<any> = new EventEmitter<any>();


  get isDirty(): boolean {
    return !this.util.objectIsSame(this.selectedLawfirm, this.workingLawfirm);
  } // end isDirty()

  get canSaveFirm(): boolean {
    return this.isDirty &&
    Boolean(this.workingLawfirm.FullName) &&
    Boolean(this.workingLawfirm.ContactLocality) &&
    Boolean(this.workingLawfirm.Phone) &&
    Boolean(this.workingLawfirm.Email) &&
    Boolean(this.workingLawfirm.StreetAddress);
  } // end canSaveFirm()

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private contactService: ContactsService
  ) { } // end constructor()

  ngOnInit(): void {
    this.contactService.getLocalities().subscribe({ next: (data) => {
      // On next
      this.contactLocalities = data;
      if (this.selectedLawfirm && this.selectedLawfirm.Id) {
        this.selectedLocality = this.contactLocalities.filter(s => s.Id === this.selectedLawfirm.ContactLocality)[0];
      }
    }});
    if (this.selectedLawfirm && this.selectedLawfirm.Id) {
      this.title = 'Edit Law Firm Details';
    }
    this.workingLawfirm =  this.util.objectCopy(this.selectedLawfirm);    
  } // end ngOnInit()

  onDismiss() {
    this.closeModal.emit('law-firm');
  } // end onDismiss()

  onPhoneNumberBlur() {
    if (this.workingLawfirm && this.workingLawfirm.Phone) {
      this.contactService.getContactByPhone(this.workingLawfirm.Phone).subscribe({ next: (data) => {
        if (data) {
          this.message = 'The phone number you have entered already exists on the Auxcon system and is linked to: ' + data.FullName;
          this.showPhoneNumberAlreadyExists = true;
        }
      }});
    }
  } // end onPhoneNumberBlur()

  onHidePhoneNumberAlreadyExists() {
    this.showPhoneNumberAlreadyExists = false;
  } // end onHidePhoneNumberAlreadyExists()

  continue() {
    this.showPhoneNumberAlreadyExists = false;
  } // end continue()

  save() {
    this.loadingService.showOverlay();
    this.workingLawfirm.ContactContactType = 6;    
    this.contactService.postNewFirm(this.workingLawfirm).subscribe({ next: (data) => {
      // On next
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.onSaveLawFirm.emit('saved');
      // this.loadingService.hideOverlay();
    }
  });
  } // end save()

  update() {
    this.loadingService.showOverlay();
    this.contactService.putFirm(this.workingLawfirm).subscribe({next: (data) => {
      // ON next
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      // this.loadingService.hideOverlay();
      this.onSaveLawFirm.emit('saved');
    }
  })
  } // end update()

  onModelChange(event , selectedContactLocality: ContactLocalities) {
    this.workingLawfirm.ContactLocality = selectedContactLocality.Id;
  } // end onModelChange()

} // end AddNewLawFirmComponent {}
