<app-breadcrumb
[crumbs]="crumbs"></app-breadcrumb>
<app-advocate-detail-table
[line]="advocateDetailStatement"
[attorneyDetailStatement]="attorneyDetailStatement"
[lawfirmPaidStatement]="lawfirmPaidStatement"
[allocatedToLawFirm]="allocatedToLawFirm"
(selectedAdv)="getAdvocateStatement($event)"
[btnGenerate]="generateButton"
  *ngIf="!showReportTable"></app-advocate-detail-table>
  <app-advocate-detail-report-table
  *ngIf="showReportTable"></app-advocate-detail-report-table>
