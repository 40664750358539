import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { catchError, retry } from 'rxjs/operators';

export interface AdvocateChambers {
  Id: number;
  ContactId: number;
  BarSocietyId: number;
  BarSociety: string;
  ChamberId: number;
  Chamber: string;
  RoomNumber: string;
  Email: string;
  Phone: string;
  Address: string;
  MembershipStartDate: string;
  MembershipEndDate: string;
  MembershipStatus: string;
  Website: string;
}

export const DEFAULT_ADVOCATE_CHAMBERS: AdvocateChambers = {
  Id: 0,
  ContactId: 0,
  BarSocietyId: 0,
  BarSociety: '',
  ChamberId: 0,
  Chamber: '',
  RoomNumber: '',
  Email: '',
  Phone: '',
  Address: '',
  MembershipStartDate: '',
  MembershipEndDate: '',
  MembershipStatus: '',
  Website: '',
};

export interface BarSociety {
  Id: number;
  Name: string;
}

export const DEFAULT_BAR_SOCIETY: BarSociety = {
  Id: 0,
  Name: ''
};

export interface Chamber {
  Id: number;
  BarSocietyId: number;
  ChamberName: string;
  Email: string;
  Phone: string;
  Address: string;
  Website: string;
}

export const DEFAULT_CHAMBERS: Chamber = {
  Id: 0,
  BarSocietyId: 0,
  ChamberName: '',
  Email: '',
  Phone: '',
  Address: '',
  Website: '',
};

@Injectable()
export class ChambersService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  getBarSociety(): Observable<any> {
    return this.http.get(this.api.endpoints.chambers +
      '/BarSociety', this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getBarSociety()

  getChambers(barId: number): Observable<any> {
    return this.http.get(this.api.endpoints.chambers +
      '/Chambers?barId=' + barId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getChambers()

  getContactChamber(): Observable<any> {
    return this.http.get(this.api.endpoints.chambers +
      '/ContactChamber?ServiceProvider=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getContactChamber()

  putAdvocateChambers(data: AdvocateChambers): Observable<AdvocateChambers> {
    return this.http.put<AdvocateChambers>(this.api.endpoints.chambers +
      '/AddEditAdvocateChamber', data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end putAdvocateChambers()
} // end ChambersService{}
