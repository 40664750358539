import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  AdvocateHealthHandle,
  AdvocateHealth,
  PracticeAdvocateHealthService
} from '../../../services/practice-advocate-health/practice-advocate-health.service';
import { UserProfileService, UserProfile } from '../../../services/user-profile/user-profile.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import * as moment from 'moment';
import { PATH_DASHBOARD } from '../../../services/appdata/app-config.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

enum WidgetType {
  InvoicedTarget = 'invoicedTarget',
  CollectedTarget = 'collectedTarget',
  AdvocateReview = 'advocateReview',
  InvoicingAdvocate = 'invoicingAdvocate'
}

@Component({
  selector: 'app-practice-advocate-health-table',
  templateUrl: './practice-advocate-health-table.component.html',
  styleUrls: ['./practice-advocate-health-table.component.scss']
})
export class PracticeAdvocateHealthTableComponent implements OnInit, OnChanges {

  lastMet = 0;
  lastInvoiceSent = 0;
  lastTransaction = 0;
  lastStatement = 0;
  belowAboveInvoiced = 0;
  belowAboveInvoicedText = '';
  belowAboveInvoicedTextColor = '';
  belowAboveCollected = 0;
  belowAboveCollectedText = '';
  LastDate: Date;
  belowAboveCollectedLastMonthText = '';
  belowAboveLastMonthCollected: number;
  showTooltipOverlay = false;
  allSelected: boolean = false;
  // Sorting
  sortAdvocate: boolean;
  sortLastMeeting: boolean;
  sortLastInvoiced: boolean;
  sortLastTransaction: boolean;
  sortLastStatement: boolean;
  sortInvoicedThreeMonths: boolean;
  sortCollectedthreeMonths: boolean;
  sortCollectedLastMonths: boolean;
  sortTotalOutstanding: boolean;
  sortLastNotificationDate: boolean;
  sortBy: string;
  sortAscending = false;
  sortCollections = false;
  sortIsAccess = false;
  previousSortBy: string;
  showAdvocateMeetingDateModel: boolean = false;
  // Search
  searchterm: string;

  // Order
  sortData = '';

  @Input() advocateHealthHandle: AdvocateHealthHandle[];

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  get totalOutstanding(): number {
    let _due = 0;
    for (const item of this.advocateHealthHandle) {
      _due += item.data.Total_Due_98;
    }
    return _due;
  } // end totalOutstanding()

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private snackbarsService: SnackbarsService,
    private practiceAdvocateHealthService: PracticeAdvocateHealthService
  ) { } // end constructor()

  ngOnInit() {
    this.loadingService.showOverlay();
    if (this.practiceAdvocateHealthService.reportSortType === WidgetType.InvoicedTarget) {
      this.sortData = 'orderInvoicedInThreeMonths';
      this.sortAscending = true;
      this.orderData();
    } else if (this.practiceAdvocateHealthService.reportSortType === WidgetType.CollectedTarget) {
      this.sortData = 'orderCollectedInThreeMonths';
      this.sortAscending = true;
      this.orderData();
    } else if (this.practiceAdvocateHealthService.reportSortType === WidgetType.AdvocateReview) {
      this.sortData = 'orderLastMeetingDate';
      this.sortAscending = false;
      this.orderData();
    } else if (this.practiceAdvocateHealthService.reportSortType === WidgetType.InvoicingAdvocate) {
      this.sortData = 'orderInvoicedInThreeMonths';
      this.sortAscending = false;
      this.orderData();
    } else {
      this.advocateHealthHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Total_Due_98 - b.data.Total_Due_98;
        }
        return b.data.Total_Due_98 - a.data.Total_Due_98;
      });
    }
    this.loadingService.hideOverlay();
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {

  } // end ngOnChanges()

  toggleSort(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch (this.sortBy) {
      case 'advocate':
        this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = true;
            this.sortLastMeeting = false;
            this.sortLastInvoiced = false;
            this.sortLastStatement = false;
            this.sortInvoicedThreeMonths = false;
            this.sortCollectedthreeMonths = false;
            this.sortCollections = false;
            this.sortIsAccess = false;
            this.sortCollectedLastMonths = false;
            this.sortTotalOutstanding = false;
            this.sortLastNotificationDate = false;
            return a.data.Advocate.toUpperCase().localeCompare(b.data.Advocate.toUpperCase());
          }
          return b.data.Advocate.toUpperCase().localeCompare(a.data.Advocate.toUpperCase());
        });
        break;

      case 'is-access':
        this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = true;
            this.sortLastMeeting = false;
            this.sortLastInvoiced = false;
            this.sortLastStatement = false;
            this.sortInvoicedThreeMonths = false;
            this.sortCollectedthreeMonths = false;
            this.sortCollectedLastMonths = false;
            this.sortTotalOutstanding = false;
            this.sortLastNotificationDate = false;
            this.sortCollections = false;
            this.sortIsAccess = true;
            return (a.data.IsAccess == true ? 'Yes' : 'N0').toUpperCase().localeCompare((b.data.IsAccess == true ? 'Yes' : 'N0').toUpperCase());
          }
          return (b.data.IsAccess == true ? 'Yes' : 'N0').toUpperCase().localeCompare((a.data.IsAccess == true ? 'Yes' : 'N0').toUpperCase());
        });
        break;
      case 'collections':
        this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = true;
            this.sortLastMeeting = false;
            this.sortLastInvoiced = false;
            this.sortLastStatement = false;
            this.sortInvoicedThreeMonths = false;
            this.sortCollectedthreeMonths = false;
            this.sortCollectedLastMonths = false;
            this.sortCollections = true;
            this.sortIsAccess = false;
            this.sortTotalOutstanding = false;
            this.sortLastNotificationDate = false;
            return (a.data.Collection == true ? 'Yes' : 'N0').toUpperCase().localeCompare((b.data.Collection == true ? 'Yes' : 'N0').toUpperCase());
          }
          return (b.data.Collection == true ? 'Yes' : 'N0').toUpperCase().localeCompare((a.data.Collection == true ? 'Yes' : 'N0').toUpperCase());
        });
        break;

      case 'last-meeting':
        this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = false;
            this.sortLastMeeting = true;
            this.sortLastInvoiced = false;
            this.sortLastTransaction = false;
            this.sortCollections = false;
            this.sortIsAccess = false;
            this.sortLastStatement = false;
            this.sortInvoicedThreeMonths = false;
            this.sortCollectedthreeMonths = false;
            this.sortLastNotificationDate = false;
            this.sortCollectedLastMonths = false;
            this.sortTotalOutstanding = false;
            return a.data.LastMeetingDate.toUpperCase().localeCompare(b.data.LastMeetingDate.toUpperCase());
          }
          return b.data.LastMeetingDate.toUpperCase().localeCompare(a.data.LastMeetingDate.toUpperCase());
        });
        break;
      case 'last-invoiced':
        this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = false;
            this.sortLastMeeting = false;
            this.sortLastInvoiced = true;
            this.sortCollections = false;
            this.sortIsAccess = false;
            this.sortLastTransaction = false;
            this.sortLastStatement = false;
            this.sortLastNotificationDate = false;
            this.sortInvoicedThreeMonths = false;
            this.sortCollectedthreeMonths = false;
            this.sortCollectedLastMonths = false;
            this.sortTotalOutstanding = false;
            return a.data.Last_Invoice_Created.toUpperCase().localeCompare(b.data.Last_Invoice_Created.toUpperCase());
          }
          return b.data.Last_Invoice_Created.toUpperCase().localeCompare(a.data.Last_Invoice_Created.toUpperCase());
        });
        break;
      case 'transaction-date':
        this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = false;
            this.sortLastMeeting = false;
            this.sortLastInvoiced = false;
            this.sortCollections = false;
            this.sortIsAccess = false;
            this.sortLastTransaction = true;
            this.sortLastNotificationDate = false;
            this.sortLastStatement = false;
            this.sortInvoicedThreeMonths = false;
            this.sortCollectedthreeMonths = false;
            this.sortCollectedLastMonths = false;
            this.sortTotalOutstanding = false;
            return a.data.Last_Transaction.toUpperCase().localeCompare(b.data.Last_Transaction.toUpperCase());
          }
          return b.data.Last_Transaction.toUpperCase().localeCompare(a.data.Last_Transaction.toUpperCase());
        });
        break;
      case 'statement-reminder-date':
        this.advocateHealthHandle.sort((a, b) => {
          // if(a.data.LastStatementReminderDate){
          //   a.data.LastStatementReminderDate = '1901-01-01T00:00:00';
          // }
          // if(b.data.LastStatementReminderDate){
          //   b.data.LastStatementReminderDate = '1901-01-01T00:00:00';
          // }

          if (this.sortAscending) {
            this.sortAdvocate = false;
            this.sortLastMeeting = false;
            this.sortLastNotificationDate = true;
            this.sortCollections = false;
            this.sortIsAccess = false;
            this.sortLastInvoiced = false;
            this.sortLastTransaction = false;
            this.sortLastStatement = false;
            this.sortInvoicedThreeMonths = false;
            this.sortCollectedthreeMonths = false;
            this.sortCollectedLastMonths = false;
            this.sortTotalOutstanding = false;
            return a.data.LastStatementReminderDate.toUpperCase().localeCompare(b.data.LastStatementReminderDate.toUpperCase());
          }
          return b.data.LastStatementReminderDate.toUpperCase().localeCompare(a.data.LastStatementReminderDate.toUpperCase());
        });
        break;
      case 'statement-date':
        this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = false;
            this.sortLastMeeting = false;
            this.sortLastInvoiced = false;
            this.sortLastTransaction = false;
            this.sortLastNotificationDate = false;
            this.sortLastStatement = true;
            this.sortInvoicedThreeMonths = false;
            this.sortCollections = false;
            this.sortIsAccess = false;
            this.sortCollectedthreeMonths = false;
            this.sortCollectedLastMonths = false;
            this.sortTotalOutstanding = false;
            return a.data.Last_Statement_Date.toUpperCase().localeCompare(b.data.Last_Statement_Date.toUpperCase());
          }
          return b.data.Last_Statement_Date.toUpperCase().localeCompare(a.data.Last_Statement_Date.toUpperCase());
        });
        break;
      case 'invoiced-three-months':
        this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = false;
            this.sortLastMeeting = false;
            this.sortLastInvoiced = false;
            this.sortLastTransaction = false;
            this.sortLastStatement = false;
            this.sortLastNotificationDate = false;
            this.sortCollections = false;
            this.sortIsAccess = false;
            this.sortInvoicedThreeMonths = true;
            this.sortCollectedthreeMonths = false;
            this.sortCollectedLastMonths = false;
            this.sortTotalOutstanding = false;
            return a.data.Total_Invoiced_120 - b.data.Total_Invoiced_120;
          }
          return b.data.Total_Invoiced_120 - a.data.Total_Invoiced_120;
        });
        break;
      case 'collected-three-months':
        this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = false;
            this.sortLastMeeting = false;
            this.sortLastInvoiced = false;
            this.sortLastTransaction = false;
            this.sortLastStatement = false;
            this.sortCollections = false;
            this.sortIsAccess = false;
            this.sortInvoicedThreeMonths = false;
            this.sortCollectedthreeMonths = true;
            this.sortLastNotificationDate = false;
            this.sortCollectedLastMonths = false;
            this.sortTotalOutstanding = false;
            return a.data.Paid120Days - b.data.Paid120Days;
          }
          return b.data.Paid120Days - a.data.Paid120Days;
        });
        break;
      case 'collected-last-months':
        this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = false;
            this.sortLastMeeting = false;
            this.sortLastInvoiced = false;
            this.sortLastNotificationDate = false;
            this.sortLastTransaction = false;
            this.sortCollections = false;
            this.sortIsAccess = false;
            this.sortLastStatement = false;
            this.sortInvoicedThreeMonths = false;
            this.sortCollectedthreeMonths = false;
            this.sortCollectedLastMonths = true;
            this.sortTotalOutstanding = false;
            return a.data.CollectedLastMonth - b.data.CollectedLastMonth;
          }
          return b.data.CollectedLastMonth - a.data.CollectedLastMonth;
        });
        break;
      case 'total-outstanding':
        this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = false;
            this.sortLastMeeting = false;
            this.sortLastInvoiced = false;
            this.sortLastTransaction = false;
            this.sortLastNotificationDate = false;
            this.sortLastStatement = false;
            this.sortInvoicedThreeMonths = false;
            this.sortCollectedthreeMonths = false;
            this.sortCollections = false;
            this.sortIsAccess = false;
            this.sortCollectedLastMonths = false;
            this.sortTotalOutstanding = true;
            return a.data.Total_Due_98 - b.data.Total_Due_98;
          }
          return b.data.Total_Due_98 - a.data.Total_Due_98;
        });
        break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

  persormSearch() {
    if (this.searchterm) {
      this.advocateHealthHandle.forEach(advocate => {
        if (
          !(advocate.data.Advocate.match(new RegExp(this.searchterm, 'gi')))
        ) {
          advocate.state.isVisible = false;
        } else {
          advocate.state.isVisible = true;
        }
      });
    } else {
      this.advocateHealthHandle.forEach(advocate => {
        advocate.state.isVisible = true;
      });
    }
  } // end persormSearch()

  toggleAscending() {
    this.sortAscending = !this.sortAscending;
    this.orderData();
  }

  orderData() {
    switch (this.sortData) {
      case 'orderLastMeetingDate':
        this.advocateHealthHandle = this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {

            return (moment().diff(moment(a.data.LastMeetingDate), 'week')) -
              (moment().diff(moment(b.data.LastMeetingDate), 'week'));
          }
          return (moment().diff(moment(b.data.LastMeetingDate), 'week')) -
            (moment().diff(moment(a.data.LastMeetingDate), 'week'));
        });
        break;
      case 'collection':
        this.toggleSort('collections');
        break;
      case 'bsAccess':
        this.toggleSort('is-access');
        break;

      case 'orderLastInvoiceDate':
        this.advocateHealthHandle = this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            return (moment().diff(moment(a.data.Last_Invoice_Created), 'week')) -
              (moment().diff(moment(b.data.Last_Invoice_Created), 'week'));
          }
          return (moment().diff(moment(b.data.Last_Invoice_Created), 'week')) -
            (moment().diff(moment(a.data.Last_Invoice_Created), 'week'));
        });
        break;

      case 'orderLastTransactionDate':
        this.advocateHealthHandle = this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            return (moment(a.data.Last_Statement_Date).diff(moment(a.data.Last_Transaction), 'week')) -
              (moment(b.data.Last_Statement_Date).diff(moment(b.data.Last_Transaction), 'week'));
          }
          return (moment(b.data.Last_Statement_Date).diff(moment(b.data.Last_Transaction), 'week')) -
            (moment(a.data.Last_Statement_Date).diff(moment(a.data.Last_Transaction), 'week'));
        });
        break;

      case 'orderLastStatementDate':
        this.advocateHealthHandle = this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            return (moment().diff(moment(a.data.Last_Statement_Date), 'week')) -
              (moment().diff(moment(b.data.Last_Statement_Date), 'week'));
          }
          return (moment().diff(moment(b.data.Last_Statement_Date), 'week')) -
            (moment().diff(moment(a.data.Last_Statement_Date), 'week'));
        });
        break;

      case 'orderInvoicedInThreeMonths':
        this.advocateHealthHandle = this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            return Math.round((a.data.Total_Invoiced_120 / (a.data.FeeTarget * 3) * 100) - 100) -
              Math.round((b.data.Total_Invoiced_120 / (b.data.FeeTarget * 3) * 100) - 100);
          }
          return Math.round((b.data.Total_Invoiced_120 / (b.data.FeeTarget * 3) * 100) - 100) -
            Math.round((a.data.Total_Invoiced_120 / (a.data.FeeTarget * 3) * 100) - 100);
        });
        break;

      case 'orderCollectedInThreeMonths':
        this.advocateHealthHandle = this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            return Math.round((a.data.Paid120Days / (a.data.IncomeTarget * 3) * 100) - 100) -
              Math.round((b.data.Paid120Days / (b.data.IncomeTarget * 3) * 100) - 100);
          }
          return Math.round((b.data.Paid120Days / (b.data.IncomeTarget * 3) * 100) - 100) -
            Math.round((a.data.Paid120Days / (a.data.IncomeTarget * 3) * 100) - 100);
        });
        break;

      case 'orderCollectedLastMonths':
        this.advocateHealthHandle = this.advocateHealthHandle.sort((a, b) => {
          if (this.sortAscending) {
            return Math.round(((a.data.CollectedLastMonth / (a.data.IncomeTarget)) * 100) - 100) -
              Math.round(((b.data.CollectedLastMonth / (b.data.IncomeTarget)) * 100) - 100);
          }
          return Math.round(((b.data.CollectedLastMonth / (b.data.IncomeTarget)) * 100) - 100) -
            Math.round(((a.data.CollectedLastMonth / (a.data.IncomeTarget)) * 100) - 100);
        });
        break;

      case 'lastNotificationDate':
        this.advocateHealthHandle = this.advocateHealthHandle.sort((a, b) => {
          //   if(a.data.LastStatementReminderDate){
          //     a.data.LastStatementReminderDate = '1901-01-01T00:00:00';
          //   }
          //   if(b.data.LastStatementReminderDate){
          //     b.data.LastStatementReminderDate = '1901-01-01T00:00:00';
          //   }
          if (this.sortAscending) {
            return (moment().diff(moment(a.data.LastStatementReminderDate), 'week')) -
              (moment().diff(moment(b.data.LastStatementReminderDate), 'week'));
          }
          return (moment().diff(moment(b.data.LastStatementReminderDate), 'week')) -
            (moment().diff(moment(a.data.LastStatementReminderDate), 'week'));
        });
        break;
    }
  } // end orderData()

  getBarWidth(value: number): string {
    let width = '0.95';
    const percentageValue = value / this.totalOutstanding * 100;
    if (percentageValue >= 1) {
      width = (percentageValue * 0.95).toString();
    }
    return width + '%';
  } // end getBarWidth()

  getTextColor(type: string, value: AdvocateHealth): string {
    if (type === 'totalInvoiced') {
      const invoiced = Math.round(((value.Total_Invoiced_120 / (value.FeeTarget * 3)) * 100) - 100);
      if (invoiced > 0) {
        this.belowAboveInvoicedTextColor = '#8abd37';
      } else {
        this.belowAboveInvoicedTextColor = '#cf624f';
      }
    }
    return this.belowAboveInvoicedTextColor;
  } // end getTextColor()

  getBackgroundColor(type: string): string {
    return '#8abd37';
  } // end getBackgroundColor()

  getCellColor(type: string, advocate: AdvocateHealthHandle): string {
    const lastmeeting = moment().diff(moment(advocate.data.LastMeetingDate), 'week');
    let color = '';
    if (type === 'meetingDate') {
      // if (advocate.state.calculateLastMeetingDate > 8) {
      //   color = '#cf624f';
      //   if (advocate.data.LastMeetingDate === '1901-01-01') {
      //     color = 'transparent';
      //   }
      // }
      if (lastmeeting > 8) {
        color = '#cf624f';
        if (advocate.data.LastMeetingDate === '1901-01-01') {
          color = 'transparent';
        }
      }
    } else if (type === 'invoice') {
      if (advocate.state.calculateLastInvoiceDate > 2) {
        color = '#cf624f';
        if (advocate.data.Last_Invoice_Created === '1901-01-01') {
          color = 'transparent';
        }
      }
    }
    return color;
  } // end getCellColor()

  getStatementCellColor(type: string, advocate: AdvocateHealthHandle): string {
    let color = '';
    if (type === 'statementDate') {
      if (advocate.state.calculateLastStatementDate > 2) {
        color = '#cf624f';
      }
    }
    if (advocate.data.Last_Statement_Date === '1901-01-01') {
      color = 'transparent';
    }
    return color;
  } // end getStatementCellColor()

  getTransactionCellColor(type: string, advocate: AdvocateHealthHandle): string {
    let color = '';
    if (type === 'transactionDate') {
      if (advocate.state.calculateLastTransactionDate > 3) {
        color = '#cf624f';
      } else if (moment().diff(moment(advocate.data.Last_Transaction), 'week') > 3) {
        color = '#cf624f';
      }
    }
    if (advocate.data.Last_Transaction === '1901-01-01') {
      color = 'transparent';
    }
    return color;
  } // end getTransactionCellColor()

  getFontWeight(type: string, advocate: AdvocateHealthHandle): string {
    const lastmeeting = moment().diff(moment(advocate.data.LastMeetingDate), 'week');
    let fontWeight = '';
    if (type === 'meetingDate') {
      // if (advocate.state.calculateLastMeetingDate > 8) {
      //   fontWeight = 'Bold';
      // }
      if (lastmeeting > 8) {
        fontWeight = 'Bold';
      }
    } else if (type === 'invoice') {
      if (advocate.state.calculateLastInvoiceDate > 2) {
        fontWeight = 'Bold';
      }
    } else if (type === 'transactionDate') {
      if (advocate.state.calculateLastTransactionDate > 3) {
        fontWeight = 'Bold';
      } else if (moment().diff(moment(advocate.data.Last_Transaction), 'week') > 3) {
        fontWeight = 'Bold';
      }
    } else if (type === 'statementDate') {
      if (advocate.state.calculateLastStatementDate > 2) {
        fontWeight = 'Bold';
      }
      // else if (moment().diff(moment(advocate.data.last_Transaction), 'week') > 3) {
      //   fontWeight = 'Bold';
      // }
    } else if (type === 'totalInvoiced') {
      if (advocate.state.invoicedColor === '#cf624f') {
        fontWeight = 'Bold';
      }
    } else if (type === 'collected') {
      if (advocate.state.collectedColor === '#cf624f') {
        fontWeight = 'Bold';
      }
    } else if (type === 'collectedThisMonth') {
      if (advocate.state.collectedThisMonthColor === '#cf624f') {
        fontWeight = 'Bold';
      }
    }
    return fontWeight;
  } // end getFontWeight()

  onHover(type: string, value: any, invoiceCollected: any): void {
    this.showTooltipOverlay = true;
    if (type === 'meetingDate') {
      this.lastMet = moment().diff(moment(value), 'week');
      this.showTooltipOverlay = false;
    } else if (type === 'collectedThisMonth') {
      this.belowAboveLastMonthCollected = Math.round(((value / (invoiceCollected)) * 100) - 100);
      if (this.belowAboveLastMonthCollected > 0) {
        this.belowAboveCollectedLastMonthText = 'above';
      } else {
        this.belowAboveCollectedLastMonthText = 'below';
        this.belowAboveLastMonthCollected = Math.abs(this.belowAboveLastMonthCollected);
      }
      this.showTooltipOverlay = false;
    } else if (type === 'invoice') {
      this.lastInvoiceSent = moment().diff(moment(value), 'week');
      this.showTooltipOverlay = false;
    } else if (type === 'chart') {
      this.showTooltipOverlay = false;
    } else if (type === 'transactionDate') {
      this.lastTransaction = moment().diff(moment(value), 'week');
      this.showTooltipOverlay = false;
    } else if (type === 'statementDate') {
      this.lastStatement = moment().diff(moment(value), 'week');
      this.showTooltipOverlay = false;
    } else if (type === 'totalInvoiced') {
      this.belowAboveInvoiced = Math.round(((value / (invoiceCollected * 3)) * 100) - 100);
      if (this.belowAboveInvoiced > 0) {
        this.belowAboveInvoicedText = 'above';
      } else {
        this.belowAboveInvoicedText = 'below';
        this.belowAboveInvoiced = Math.abs(this.belowAboveInvoiced);
      }
      this.showTooltipOverlay = false;
    } else if (type === 'collected') {
      this.belowAboveCollected = Math.round(((value / (invoiceCollected * 3)) * 100) - 100);
      if (this.belowAboveCollected > 0) {
        this.belowAboveCollectedText = 'above';
      } else {
        this.belowAboveCollectedText = 'below';
        this.belowAboveCollected = Math.abs(this.belowAboveCollected);
      }
      this.showTooltipOverlay = false;
    }
    // if (id) {
    // } else {
    //   this.showTooltipOverlay = false;
    // }
  } // end onHover()

  onAdvocateSelect(data: AdvocateHealthHandle) {
    const selectedUs: UserProfile = this.userProfileService.userProfile.impersonates
      .filter(s => s.serviceProviderID === data.data.Id)[0];
    sessionStorage.setItem('advocateUserProfil', JSON.stringify(selectedUs));
    this.router.navigate([PATH_DASHBOARD]);
    this.userProfileService.refreshSelectedProfile = true;
  } // end onAdvocateSelect()

  toggleSelectAll() {
    this.allSelected = !this.allSelected;
    this.advocateHealthHandle.forEach(advocate => {
      advocate.state.isSelected = this.allSelected;
    });
  } // end toggleSelectAll()

  toggleChecbox(advocate: AdvocateHealthHandle) {
    advocate.data.Selected = !advocate.data.Selected;
    this.practiceAdvocateHealthService.updateSelected(advocate.data).subscribe({ next: (data) => {
      // On next
    }})
  } // end toggleCheckbox()

  onAdvocateLastMeetingDate(eventDate: any) {
    this.LastDate = eventDate;
    this.showAdvocateMeetingDateModel = true;
  }

  closeModel() {
    this.showAdvocateMeetingDateModel = false;
  }


  SaveLastMeetingDate(event: any) {
    const snack: Snack = {
      label: 'Updating Last Meeting Date',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.practiceAdvocateHealthService.updateLastMeetingDate(event).subscribe({
      next:
        (data) => {
          const msg = 'Updated Last Meeting Date';
          snack.label = msg;
          this.snackbarsService.dismiss().make(snack).show();
          this.showAdvocateMeetingDateModel = false;
        },
      error: (error) => {
        const msg = 'Error In Updating Last Meeting Date.';
        snack.label = msg;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.showAdvocateMeetingDateModel = false;
      },
      complete: () => {
        this.showAdvocateMeetingDateModel = false;
      }
    });
  }
} // end PracticeAdvocateHealthTableComponent()
