<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">
            <div class="modal-header">
                <h2>Add New Matter</h2>
                <button class="modal-dismiss" (click)="onClose($event, true)">
                    <i class="fa fa-close"></i>
                </button>
            </div>
            <div class="modal-body form">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Law Firm / Advocate<span title="Required field"> *</span></label>
                            <app-datalist-lawfirm type="law-firms" iconClass="fa fa-black-tie fa-lg" [list]="lawFirms"
                                [(matter)]="matter"
                                (select)="onModelChange('law-firms', $event)"></app-datalist-lawfirm>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Attorneys</label>
                            <app-datalist-lawfirm type="attorneys" iconClass="fa fa-black-tie fa-lg" [list]="attorneys"
                                [matter]="matter" (select)="onModelChange('attorneys', $event)"></app-datalist-lawfirm>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Palintiffss / Respondents<span title="Required field"> *</span></label>
                            <app-datalist-plaintiffs type="plaintiffs" iconClass="fa fa-black-tie fa-lg"
                                [plaintiffsList]="plaintiffs" [matter]="matter"
                                (select)="onModelChange('plaintiffs', $event)"></app-datalist-plaintiffs>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Defendants / Respondents</label>
                            <app-datalist-plaintiffs type="defendants" iconClass="fa fa-black-tie fa-lg"
                                [defendantsList]="defendants" [matter]="matter"
                                (select)="onModelChange('defendants', $event)"></app-datalist-plaintiffs>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Hourly rate<span title="Required field"> *</span></label>
                            <input type="form-control"
                                [ngModel]="NewMatterModel.Hourlyrate | currency: currencySymbol: currencySymbol:'1.2-2'"
                                (blur)="onHourlyRateChange()" (focus)="onHourlyRateClicked()"
                                (focus)="$event.target.select()" pattern="[P-R\s]*[0-9,.]+" autocomplete="off"
                                #inputHourly required />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Daily rate<span title="Required field"> *</span></label>
                            <input type="form-control"
                                [ngModel]="NewMatterModel.Dailyrate | currency: currencySymbol: currencySymbol:'1.2-2'"
                                (blur)="onDailyRateChange()" (focus)="onDailyRateClicked()"
                                (focus)="$event.target.select()" pattern="[P-R\s]*[0-9,.]+" autocomplete="off"
                                #inputDaily required />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Per kilometer rate</label>
                            <input type="form-control"
                                [ngModel]="NewMatterModel.PerkilometerRate | currency: currencySymbol: currencySymbol:'1.2-2'"
                                (blur)="onPerkilometerRateChange()" (focus)="onPerkilometerRateClicked()"
                                (focus)="$event.target.select()" pattern="[P-R\s]*[0-9,.]+" #inputPerKilometer
                                autocomplete="off" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Term<span title="Required field"> *</span></label>
                            <select id="paymentTerms" [(ngModel)]="NewMatterModel.PaymentTerm">
                                <option *ngFor="let age of paymentTerms" [value]="age.id"
                                    [selected]="age.id === NewMatterModel.PaymentTerm">{{age.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Attorney Reference<span title="Required field"> *</span></label>
                            <input type="text" [(ngModel)]="NewMatterModel.AttorneyReference"
                                (input)="onModelChange('attorney-reference', {FullName: $event.target.value})"
                                (blur)="onReferenceChangeCheck()" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Instructions</label>
                            <input type="text" [(ngModel)]="NewMatterModel.Instructions" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Date of appearance</label>
                            <input readonly type="text" id="text" bsDatepicker
                                [value]="NewMatterModel.DateOfAppearance | date:'yyyy-MM-dd'"
                                (ngModel)="NewMatterModel.DateOfAppearance=$event.target.value"
                                (bsValueChange)="onDateChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                                placement="right">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Case Number</label>
                            <input type="text" [(ngModel)]="NewMatterModel.CaseNumber" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success btn-end" [class.disabled]="!isSaveable" [disabled]="!isSaveable"
                    (click)="onDone()">Done</button>
            </div>
        </div>
    </div>
</div>
<app-prompt-unsaved-changes-modal title="MATTER ALREADY EXIST"
    message="Kindly take note that there is already a matter with the same reference under you current matters. Please navigate to your dashboard and search for the matter."
    [isVisible]="showMatterExistsPrompt" (cancel)="onHideMatterExistsPrompt()" btnTextCancel="Cancel"
    btnTextContinue="Dashboard" (continue)="navigateToDashboard()"></app-prompt-unsaved-changes-modal>
<app-prompt-unsaved-changes-modal message="Are you sure you want to leave this screen? You have unsaved changes."
    [isVisible]="showUnsavedChangesPrompt" (cancel)="onHideUnsavedChangesPrompt()" (continue)="dismiss()"
    (confirm)="onSave()"></app-prompt-unsaved-changes-modal>