import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, Event } from '@angular/router';
import { PATH_MATTERS, PATH_DASHBOARD, PATH_INVOICE_PREVIEW } from '../../services/appdata/app-config.service';
import { Message, MessagesService } from '../../services/messaging/messages/messages.service';
import { SnackbarsService, Snack } from '../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { UtilitiesService } from '../../services/utilities.service';
// import {  as ISubscription } from 'rxjs';
import { ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import { ApiService } from '../../services/api.service';
import { MattersService, BriefDocuments, DEFAULT_BRIEF_DOCUMENT } from '../../services/matters/matters.service';
import { DocumentTypes, FeeItemsService } from '../../services/fee-items/fee-items.service';
import {
  ActivityLogs,
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogsService } from '../../services/activity-logs/activity-logs.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import * as moment from 'moment';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { SendQuotation, DEFAULT_SEND_QUOTATION, QuotationService } from '../../services/quotation/quotation.service';

@Component({
  selector: 'app-advocate-invoice-email',
  templateUrl: './advocate-invoice-email.component.html',
  styleUrls: ['./advocate-invoice-email.component.scss']
})
export class AdvocateInvoiceEmailComponent implements OnInit, OnDestroy {

  crumbs: ICrumb[];
  title = 'Send invoice via email';
  id: number;
  invoiceId: number;
  selectedURL: string;
  private _subscritpions: ISubscription[] = [];
  message: Message = {
    ID: 0,
    MessageDate: '',
    Source: '',
    SourceId: 0,
    Recipients: '',
    EMailAddresses: '',
    Description: '',
    DocumentPath: '',
    ContactId: 0,
    Status: '',
    Subject: '',
    Body: '',
    RolloverService: false,
    SendMessage: false,
    ServiceId: 0
  };
  downloadLink = this.api.apiURL + '/InvoiceDocument?invoiceID=';
  briefDownloadLink = this.api.apiURL + '/MatterDocument?briefId=';
  showLeavePagePrompt: boolean;

  // Matter documents
  showDocumentsToAddDialog: boolean;
  briefDocuments: BriefDocuments[] = [];
  briefDocumentsToBeAdded: BriefDocuments[] = [];
  documentTypes: DocumentTypes[];

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);
  sendInvoiceModel: SendQuotation = Object.assign({}, DEFAULT_SEND_QUOTATION);

  @ViewChild('body') body: ElementRef;

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private util: UtilitiesService,
    private matterService: MattersService,
    private loadingService: LoadingService,
    private feeItemsService: FeeItemsService,
    private messagesService: MessagesService,
    private snackbarsService: SnackbarsService,
    private quotationService: QuotationService,
    private navigationService: NavigationService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
  ) {
    document.title = route.snapshot.data.title;
  } // end constructor()

  get messageBodyWithoutStyle(): string {
    if (this.message && this.message.Body) {
    this.message.Body = this.message.Body.replace('Your Mailing', '');
    return this.message.Body.substring(0, this.message.Body.indexOf('<style '))
      + this.message.Body.substring(this.message.Body.indexOf('</style>'));
    }
  } // end messageBodyWithoutStyle()

  get hasContactEmailError(): boolean {
    return !this.util.areValidEmails(this.message.Recipients);
  } // end hasContactEmailError()

  get isSaveable(): Boolean {
    return this.util.areValidEmails(this.message.Recipients);
  } // end isSaveable()

  ngOnInit() {
    this.crumbs = [
      {
        label: 'Send invoice via email'
      }
    ];
    let navSub = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.selectedURL = event.url;
        // this.onNavigationStart(event);
        this.navigationService.canNavigate = false;
        this.showLeavePagePrompt = true;
      } else {
        // this.navigationService.canNavigate = true;
      }
    });
    this._subscritpions.push(navSub);

    this.loadingService.showOverlay();
    this.id = +this.route.snapshot.paramMap.get('serviceId');
    this.invoiceId = +this.route.snapshot.paramMap.get('id');

    this.downloadLink += this.invoiceId;
    this.messagesService.getMessage(this.invoiceId, 'Invoice').subscribe({ next: 
      (message) => {
        // On next
        this.message = message;
      },
      error: (error) => {
        this.loadingService.hideOverlay();
        const msg = 'Error loading messages.';
        console.error(msg, error);
      },
      complete: () => {
        this.loadingService.hideOverlay();
        // On complete
        this.getMatterDocuments();
        // this.renderHTMLBody();
      }
    });
    this.feeItemsService.getDocumentType().subscribe({next: (docTypes) => {
      this.documentTypes = docTypes;
    }});
  } // end ngOnInit()

  getMatterDocuments() {
    this.briefDocuments = [];
    this.matterService.getDocumentList(this.id).subscribe({next: (b_docs) => {
      this.briefDocuments = b_docs;
    }});
    this.documentTypes.sort((a, b) => a.DocumentType.toUpperCase().localeCompare(b.DocumentType.toUpperCase()));
    this.briefDocuments.forEach(d => {
      d.DocumentPath = this.getExtension(d.DocumentPath);
    });
  } // end getMatterDocuments()

  getExtension(filename) {
    // console.log('CHECK DOC NAME: ', filename);
    return filename.toString().split('/')[1];
  } // end getExtension()

  ngOnDestroy() {
    this._subscritpions.forEach((sub) => {
      sub.unsubscribe();
    });
  } // end ngOnDestroy()

  addAnotherDocument() {
    this.showDocumentsToAddDialog = true;
  } // end addAnotherDocument()

  onAddDocument(selectedDoc: BriefDocuments) {
    this.loadingService.showOverlay();
    if (selectedDoc.BriefId > 0) {

      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Add matter document';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-Invoice-email)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'BriefDocument';
      this.activityLog.JsonData = JSON.stringify(selectedDoc);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

      this.matterService.addDocumentToSend(this.id, selectedDoc.BriefId, 'Add')
     .subscribe({ next: (added) => {
       // On next
     },
     error: (error) => {
       // On error
       this.loadingService.hideOverlay();
     },
     complete: () => {
       // On Complete
       this.getMatterDocuments();
       this.loadingService.hideOverlay();
     }
    });
    //  console.log('ADDED FILE: ', added);
      this.matterService.addDocumentToSend(this.id, selectedDoc.BriefId, 'Add').subscribe({next: (added) => {
        console.log('ADDED FILE: ', added);
      }});
      this.getMatterDocuments();
      // this.briefDocuments = this.briefDocuments.filter(s => s !== selectedDoc);
      // this.briefDocumentsToBeAdded.push(selectedDoc);
      // selectedDoc = Object.assign({}, DEFAULT_BRIEF_DOCUMENT);
    } else {
      this.loadingService.hideOverlay();
    }
    this.showDocumentsToAddDialog = false;
  } // end onAddDocument()

  removeAnotherDocument(selectedDoc: BriefDocuments) {
    this.loadingService.showOverlay();
    if (selectedDoc) {

      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Remove matter document';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-Invoice-email)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'BriefDocument';
      this.activityLog.JsonData = JSON.stringify(selectedDoc);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

      this.matterService.addDocumentToSend(this.id, selectedDoc.BriefId, 'Remove').subscribe({next: (Removed) => {

      }});
      this.getMatterDocuments();
      this.loadingService.hideOverlay();
    } else {
      this.loadingService.hideOverlay();
    }
  } // end removeAnotherDocument()

  onHideDocumentsToAddDialog() {
    this.showDocumentsToAddDialog = false;
  } // end onHideDocumentsToAddDialog()

  /**
  * Triggered when navigation starts.
  * @param {any} event
  */
  onNavigationStart(event: NavigationStart) {
    const route = event.url;
    this.navigationService.canNavigate = false;
    this.showLeavePagePrompt = true;
  } // end onNavigationStart()

  onHideLeavePagePrompt() {
    this.showLeavePagePrompt = false;
  } // end onShowUnsavedChangesPrompt()

  onViewInvoice(invoiceId: number) {
    this.router.navigate([PATH_INVOICE_PREVIEW + '/' + invoiceId]);
  } // end onViewInvoice()

  onDismiss() {
    this.router.navigate([this.selectedURL]);
    this.showLeavePagePrompt = false;
    this.navigationService.canNavigate = true;
  }

  renderHTMLBody() {
    const parser = new DOMParser();
    this.body.nativeElement.append(
      parser.parseFromString(this.message.Body, 'text/html')
    );
  } // end renderHTMLBody()

  editMatter() {
    this.router.navigate([PATH_MATTERS, this.id]);
  } // end editMatter()

  sendInvoice() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Sending invoice...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Send Invoice';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-Invoice-email)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Invoice';
    this.activityLog.JsonData = JSON.stringify(this.message);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

    // Remove white spaces 
    this.message.Recipients = this.message.Recipients.trim();
    
    if (this.userProfileService.userProfile.serviceProviderID === 29267) {
      this.message.Recipients = 'Kristoff@dbmlaw.co.za';
      // this.message.Recipients = 'supports@auxcon.co.za';
    }

    this.messagesService.sendMessage(this.message).subscribe({ next: 
      (next) => {
        // On next
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
        const msg = 'Error sending message.';
        console.error(msg, error);
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
        snack.label = 'Invoice sent.';
        this.snackbarsService.dismiss().make(snack).show();
        this.router.navigate(['/' + PATH_DASHBOARD]);
        this.showLeavePagePrompt = false;
        this.navigationService.canNavigate = true;
      }
    });
  } // end sendInoice()

  onSendInvoice() {
    this.loadingService.showOverlay();
    this.sendInvoiceModel = Object.assign({}, DEFAULT_SEND_QUOTATION);
    const email = this.message.Recipients.split(';');
    this.sendInvoiceModel.MessageId = this.message.ID;
    this.sendInvoiceModel.Subject = this.message.Subject;
    this.sendInvoiceModel.MessageBody = this.message.Body;
    this.sendInvoiceModel.Email = this.message.Recipients;
     this.quotationService.postSendQuotation(this.sendInvoiceModel).subscribe({next: (_send) => {}});
  } // end onSendQuotation()

  toggleKeepActiveInCurrentMatters() {
    this.message.RolloverService = !this.message.RolloverService;
  } // end toggleKeepActiveInCurrentMatters()
} // end AdvocateInvoiceEmailComponent{}
