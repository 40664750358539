<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
      <div class="modal-dialog">
  
        <div class="modal-header">
          <h2>{{title}}</h2>
          <button class="modal-dismiss" (click)="onCancel()">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!--#end .modal-header -->
  
        <div class="modal-body">
          <div class="container-fluid">
            <div class="col-md-3" class="form-group" >
              <label><b>Current Status</b></label>
                <div class="input-group">
                  <label>{{advocateDetailStatement.InvoiceStatus}}</label>
              </div>
            </div>
            <div class="col-md-3" class="form-group" >
                <label>Status Update</label>
                  <div class="input-group">
                    <select
                        class="form-control"
                        id="bank-name"
                        [(ngModel)]="invoiceStatus">
                        <option [value]="null">Select Status</option>
                        <option
                            *ngFor="let status of invoiceStatusList"
                            [value]="status.value"
                            [selected]="status.value === invoiceStatus">
                            {{status.label}}</option>
                    </select>
                  </div>
               </div>
          </div> <!-- #end .container-fluid -->
        </div>
        <!--#end .modal-body -->
  
        <div class="modal-footer">
          <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
          <button class="btn btn-success" (click)="onStatusUpdate()">Update Status</button>
          <!-- <span></span> -->
          <!-- <span></span>
          <button class="btn btn-info" (click)="onConfirm()">{{btnTextConfirm}}</button> -->
        </div>
        <!--#end .modal-footer -->
  
      </div>
      <!--#end .modal-dialog -->
    </div>
    <!--#end .modal-content -->
  </div>
  <!--#end .modal -->