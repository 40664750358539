<form name="form" #f="ngForm" novalidate>
  <div class="modal">
    <div class="modal-content">
      <div class="modal-dialog">

        <div class="modal-header">
          <h2>{{title}} | INV {{invoiceDetails.InvoiceNo}}</h2>
          <button class="modal-dismiss" (click)="dismiss()">
            <i class="fal fa-times-circle"></i>
          </button>
        </div>
        <!--#end .modal-header -->

        <div class="modal-body form">

          <div class="row m-0">
            <div class="col-sm-6 pl-0 pr-5">
              <label for="">Credit Date <span title="Required field"> *</span>&nbsp;</label>
              <div class="input-group">
                <input
                 type="text" 
                 name="dateInput" 
                 [(ngModel)]="addCredit.Date" 
                 class="form-control" 
                 autocomplete="off"
                  disabled>
                <!-- <div class="input-group-append" (click)="_addCreditDate.toggle()"
                  [attr.aria-expanded]="_addCreditDate.isOpen">
                  <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                </div> -->
              </div>
            </div>
            <div class="col-sm-6 pl-5 pr-0">
              <label for="">Credit Amount Excl VAT <span title="Required field"> *</span>&nbsp;</label>
              <div class="input-group text-right">
                <input
                  type="text" 
                  name="AmountExcl" 
                  id="amountExcl"
                  [ngStyle]="{'border-color' : isAmount == false ? '#cf624f':'#e7e4e4'}"
                  [ngModel]="addCredit.AmountExcl | currency: currencySymbol: currencySymbol:'1.2-2'"
                  class="form-control text-right" 
                  autocomplete="off" 
                  [ngModelOptions]="{updateOn: 'blur'}"
                  (ngModelChange)="addCredit.AmountExcl=$event" 
                  (blur)="onAmountExclChange(addCredit.AmountExcl, $event)"
                  (focus)="onAmountExclClicked(addCredit.AmountExcl, $event)" 
                  (focus)="$event.target.select()"
                  pattern="[P-R\s]*[0-9,.]+" 
                  required 
                  #inputAmountExcl>            
              </div>
            </div>
           
            <div class="col-sm-6 pl-5 pr-0">
            </div>
            
            <div class="col-sm-6 pl-5 pr-0">
              <label for="">Credit Amount Incl VAT <span title="Required field"> *</span>&nbsp;</label>
              <div class="input-group text-right">
                <input 
                  type="text" 
                  name="Amount" 
                  id="amount" 
                  [ngStyle]="{'border-color' : isAmount == false ? '#cf624f':'#e7e4e4'}"
                  [ngModel]="addCredit.Amount | currency: currencySymbol: currencySymbol:'1.2-2'" 
                  class="form-control text-right"
                  autocomplete="off" 
                  [ngModelOptions]="{updateOn: 'blur'}" 
                  (ngModelChange)="addCredit.Amount=$event"
                  (blur)="onAmountChange(addCredit.Amount, $event)" 
                  (focus)="onAmountClicked(addCredit.Amount, $event)"
                  (focus)="$event.target.select()" 
                  pattern="[P-R\s]*[0-9,.]+" 
                  required 
                  #inputAmount>            
              </div>
            </div>

          </div>
          <app-prompt-unsaved-changes-modal
            message="Credit amount is more than the invoice amount."
            [isVisible]="showErrorMessagePrompt" 
            title="Error Message" 
            btnTextCancel="CANCEL" 
            btnTextContinue="OK"
            (cancel)="onHideErrorMessagePrompt()" 
            (continue)="onHideErrorMessagePrompt()">
          </app-prompt-unsaved-changes-modal>
          <div class="row m-0">
            <div class="col-sm-12 pl-0 pr-0">
              <label for="">Notes <span title="Required field"> *</span>&nbsp;</label>
              <div class="input-group">
                <textarea #Notes="ngModel" [ngStyle]="{'border-color' : isNotes == false ? '#cf624f':'#e7e4e4'}"
                  (blur)="onCreditNotesBlur()" type="text" name="Notes" [(ngModel)]="addCredit.Notes"
                  class="form-control" autocomplete="off" required></textarea>
              </div>
            </div>
          </div>


        </div>
        <!--#end .modal-body -->

        <div class="modal-footer">
          <button class="btn btn-danger" (click)="dismiss()">CANCEL</button>
          <button class="btn btn-success" (click)="onShowAddCreditNote()" [class.disabled]="!f.form.valid"
            [disabled]="!f.form.valid || addCredit.Amount == 0 ">{{btnText}}</button>
        </div>
        <!--#end .modal-footer -->

      </div>
      <!--#end .modal-dialog -->
    </div>
    <!--#end .modal-content -->
  </div>
  <!--#end .modal -->
</form>

<app-prompt-unsaved-changes-modal
  title="CREATE CREDIT NOTE"
  [message]="sendCreditNoteMessage"
  [isVisible]="showAddCreditNotePrompt" 
  (cancel)="onHideAddCreditNotePrompt()" 
  btnTextContinue="Proceed"
  btnTextCancel="Cancel"
  (continue)="onAddCredit()">
</app-prompt-unsaved-changes-modal

>