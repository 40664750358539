<script src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"></script>
<div class="row row-nav">
  <nav class="navbar-tabs">
    <a class="tab"
      [class.active]="activeTab === 'take-on-data'"
      (click)="setActiveTab('take-on-data')">Historical fees</a>
  </nav><!--#end .navbar-tabs -->
</div><!--#end .navbar-tabs -->

<div class="row">
  <div class="well well-fluid">
    <div class="form-group" *ngIf="(userProfileService.userProfile.serviceProviderID === 28678 || userProfileService.userProfile.serviceProviderID === 28921) && workingHistoricalFees.length === 0">
      <label for="template-type">Select Template Type</label>
      <select
        style="width: 15%;"
        id="template-type"
        [(ngModel)]="selectedTemplate"
        (ngModelChange)="selectedTemplateChange()">
        <option hidden [value]="">Select fee template</option>
        <option
          *ngFor="let template of templateTypes"
          [value]="template.id"
          [selected]="template.id === selectedTemplate">{{template.name}}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="(userProfileService.userProfile.serviceProviderID === 29213) && workingHistoricalFees.length === 0">
      <label for="template-type">Select Template Type</label>
      <select
        style="width: 15%;"
        id="template-type"
        [(ngModel)]="selectedTemplate"
        (ngModelChange)="selectedMudauTemplateChange()">
        <option hidden [value]="">Select fee template</option>
        <option
          *ngFor="let template of templateMudauTypes"
          [value]="template.id"
          [selected]="template.id === selectedTemplate">{{template.name}}</option>
      </select>
    </div>
    <div class="table table-current-fees" *ngIf="activeTab === 'take-on-data'">
      <div class="table-head">
        <div class="table-row">

          <span class="column-date">Date</span>
          <span class="column-description">Description<span class="requiredField" title="Required field">*</span></span>
          <span class="column-type">Type</span>
          <span class="column-rate">Rate</span>
          <span class="column-quantity">Quantity</span>
          <span class="column-total">Total</span>
          <span class="column-delete">Delete</span>
          <span class="column-vatable">Vatable</span>

        </div><!--#end .table-row -->
      </div><!--#end .table-head -->

      <div class="table-body form">
        <div class="table-row" *ngFor="let fee of workingHistoricalFees">
          <span class="column-date">
            <input
              *ngIf="fee.CanEdit"
              type="text"
              bsDatepicker
              [bsValue]="parseLineDate(fee.Date)"
              (ngModel)="fee.Date=$event.target.value"
              (bsValueChange)="onValueChange(fee, $event)"
              [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
              placement="right">
            <input
              *ngIf="!fee.CanEdit"
              type="text"
              bsDatepicker
              [value]="parseDate(fee.Date) | date:'yyyy-MM-dd'"
              readonly>
          </span>
          <span class="column-description">
            <!-- <input
              *ngIf="fee.CanEdit"
              type="text"
              placeholder="Select or start typing"
              (blur)="calculateFeeTotal(fee)"
              [(ngModel)]="fee.FurtherDescription"> -->
            <app-datalist-category
              *ngIf="userProfileService.userProfile.serviceProviderID !== 28678 &&
              userProfileService.userProfile.serviceProviderID !== 28921 &&
              userProfileService.userProfile.serviceProviderID !== 30323 &&
              userProfileService.userProfile.serviceProviderID !== 30336 &&
              userProfileService.userProfile.serviceProviderID !== 29213 && fee.CanEdit"
              type="sub-category"
              [list]="subCategories"
              [value]="fee.FurtherDescription"
              [category]="selectedSubCategory"
              (categoryChange)="onFeeDescriptionSelect($event, fee)"
              (input)="onFeeDescriptionInput($event, fee)"
              (keyup.backspace)="onFeeDescriptionInput($event, fee)"></app-datalist-category>
              <textarea
                *ngIf="(userProfileService.userProfile.serviceProviderID === 28678 ||
                userProfileService.userProfile.serviceProviderID === 29213 ||
                userProfileService.userProfile.serviceProviderID === 30323 ||
                userProfileService.userProfile.serviceProviderID === 30336 ||
                userProfileService.userProfile.serviceProviderID === 28921) && fee.CanEdit"
                style="width: 100%; height: 30px;"
                class="form-group"
                [(ngModel)]="fee.FurtherDescription"
                [value]="fee.SubCategoryID === 0"
                placeholder="Enter fee description"></textarea>
            <!-- <app-datalist-category-multiline
              *ngIf="userProfileService.userProfile.serviceProviderID === 28678 && fee.CanEdit"
              type="sub-category"
              [list]="subCategories"
              [value]="fee.FurtherDescription"
              [category]="selectedSubCategory"
              (categoryChange)="onFeeDescriptionSelect($event, fee)"
              (input)="onFeeDescriptionInput($event, fee)"
              (keyup.backspace)="onFeeDescriptionInput($event, fee)"></app-datalist-category-multiline> -->
            <!-- <app-datalist-fee-description
              [list]="subCategories"
              [(model)]="fee.FurtherDescription"
              (input)="onFeeDescriptionInput($event, fee)"
              (select)="onFeeDescriptionSelect($event, fee)"></app-datalist-fee-description> -->
            <input
              *ngIf="!fee.CanEdit"
              type="text"
              placeholder="Select or start typing"
              [value]="fee.FurtherDescription"
              readonly>
          </span>
          <span class="column-type">
            <select
              *ngIf="canChangeRateType(fee)"
              (change)="onRateTypeChange(fee)"
              [(ngModel)]="fee.RateType">
              <option
                *ngFor="let type of rateTypes"
                [value]="type">{{type}}</option>
            </select>
            <input
              *ngIf="!canChangeRateType(fee)"
              type="text"
              [(ngModel)]="fee.RateType"
              readonly>
          </span>
          <div class="column-rate">
            <input
              *ngIf="fee.CanEdit"
              class="form-group"
              [class.has-error]="hasAnErrorOnRate"
              type="text"
              style="text-align: right;"
              [ngModel]="fee.Rate | currency: currencySymbol: currencySymbol:'1.2-2'"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="fee.Rate=$event"
              (ngModelChange)="calculateFeeTotal(fee)"
              (blur)="onHourlyRateChange(fee, $event)"
              (focus)="onHourlyRateClicked(fee, $event)"
              (focus)="$event.target.select()"
              pattern="[R\s P\s]*[0-9,.]+"
              #rateinput
              [readonly]="fee.RateType === 'Non Billable'">
              <p
              *ngIf="hasRateError(fee)"
              class="text-danger">Negative value not allowed, please use discount field</p>
            <input
              *ngIf="!fee.CanEdit"
              type="text"
              style="text-align: right;"
              [value]="fee.Rate | currency:currencySymbol"
              readonly>
          </div>
          <span class="column-quantity">
            <input
              type="number"
              (focus)="onQuantityFocus($event)"
              (input)="calculateFeeTotal(fee)"
              [(ngModel)]="fee.Quantity"
              (click)="$event.target.select()"
              [readonly]="fee.RateType == 'Once Off' || fee.RateType == 'Disbursement' ? true : false">
          </span>
          <div class="column-total">
            <input
              type="text"
              class="form-group"
              [class.has-error]="hasAnErrorOnRate"
              class="input-currency"
              [value]= "fee.Total | currency: currencySymbol"
              readonly>
          </div>
          <span class="column-delete">
            <i
              class="fa fa-trash fa-2x auxcon-red clickable"
              (click)="promptDeleteFee(fee)"></i>
          </span>
          <span class="column-vatable">
            <app-checkbox
              *ngIf="fee.CanEdit && fee.RateType === 'Disbursement'"
              [(checked)]="fee.IsVatable"
              (click)="toggleIsVatable(fee)"></app-checkbox>
            <!-- <input
              *ngIf="fee.CanEdit  && fee.RateType === 'Disbursement'"
              type="checkbox"
              [(ngModel)]="fee.IsVatable"> -->
            <input
              *ngIf="!fee.CanEdit && fee.RateType === 'Disbursement'"
              type="checkbox"
              title="This property is read-only."
              [checked]="fee.IsVatable"
              (click)="setIsVatable($event, fee.IsVatable)">
          </span><!--#end .column-vatable -->
        </div><!--#end .table-row -->

        <!-- Fee totals section -->
        <div class="table-row total-row">
          <span class="column-add-another-fee">
            <span class="clickable" (click)="addAnotherFee()">
              <i class="fa fa-plus-circle auxcon-green" style="margin-right: 5px;"></i>
              <u class="auxcon-green">add another fee</u>
            </span>
          </span>
          <span class="column-fees-total-label">
            <b>Total</b>
          </span>

          <!-- <span
            class="column-fees-total"
            [innerText]="currentFeesTotal | currency:currencySymbol"></span> -->
          <span>
            <input
              type="text"
              class="input-styleless"
              [value]="currentFeesTotal | currency:currencySymbol"
              readonly>
          </span>
        </div><!--#end .table-row.total-row -->
        <div class="column-fees-total-label row">

        </div>
        <div class="table-row total-row">
          <span class="column-add-another-fee">

          </span>
          <span class="column-fees-total-label" *ngIf="isPracticeManager">
            <span class="column-fees-total-label" style="width :45px; display: contents;"  >Exclude VAT</span>
          </span>
          <span style="text-align: right">
            <span> <app-checkbox
              (click)="toggleExcludeVAT()"
              style="margin-left: 15px"
              [(checked)]="workingHistorical.EXCLVAT"
              ></app-checkbox></span>
          </span>
        </div>

        <!-- Fees discount -->
        <!-- <div class="table-row discount-row">
          <span class="column-fees-discount-label">
            <b>Discount</b>
          </span>
          <span class="column-fees-discount">
              <input
              class="input-currency"
              *ngIf="!useDiscountPercentage"
              type="text"
              id="hourlyRate"
              [ngModel]="workingHistorical.Discount | currency: currencySymbol: currencySymbol:'1.2-2'"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="workingHistorical.Discount=$event.replace('-', '')"
              (ngModelChange)="onDiscountChange()"
              (blur)="onDiscountInputChange()"
              (click)="onDiscountInputClicked()"
              (click)="$event.target.select()"
              pattern="[P-R\s]*[0-9,.]+"
              autocomplete="off"
              #inputDiscount
              required>
          </span>
        </div> -->

        <!-- Payment received -->
        <!-- <div *ngIf="isAttorney || isLawfirm || userServiceProviderId === 30336" class="table-row discount-row">
          <span class="column-fees-discount-label">
            <b>Payment Received</b>
          </span>
          <span class="column-fees-discount">

              <input
              class="input-currency"
              *ngIf="!usePaymentReceivedPercentage"
              type="text"
              id="hourlyRate"
              [ngModel]="workingHistorical.PaymentReceived | currency: currencySymbol: currencySymbol:'1.2-2'"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="workingHistorical.PaymentReceived=$event.replace('-', '')"
              (ngModelChange)="onPaymentReceivedChange()"
              (blur)="onPaymentReceivedInputChange()"
              (click)="onPaymentReceivedInputClicked()"
              (click)="$event.target.select()"
              pattern="[P-R\s]*[0-9,.]+"
              autocomplete="off"
              #inputPaymentReceived
              required>
          </span>
        </div> -->

        <!-- <div class="table-row grand-total-row">
          <span
            class="column-fees-grand-total-label">Total after discount & payment</span>
          <span
            class="column-fees-grand-total" [innerText]="workingHistorical.Total | currency:currencySymbol"></span>
        </div> -->

        <!-- <div class="table-row grand-total-row" *ngIf="totalHoursWorked > 0">
          <span class="column-fees-grand-total-label">Total hours worked</span>
          <span class="column-fees-grand-total">{{totalHoursWorked}} Hours</span>
        </div> -->


      </div><!--#end .table-body -->
    </div><!--#end .table.table-current-fees -->

    <!-- Quotation fees -->
    <!-- <div class="table-responsive" *ngIf="activeTab === 'quotation-fees' && (quotationFees && quotationFees.length > 0)">
      <table class="table table-striped">
       <tbody>
         <tr>
           <th scope="col" class="w6">Date</th>
           <th scope="col" class="w50">Description</th>
           <th scope="col" class="w10">Type</th>
           <th scope="col" class="w10">Rate</th>
           <th scope="col" class="w10">Quantity</th>
           <th scope="col" class="w10">Total</th>
         </tr>
         <tr class="tr" *ngFor="let quotationfee of quotationFees">
           <td>{{quotationfee.LineDate | date:'yyyy-MM-dd'}}</td>
           <td>{{quotationfee.FurtherDescription}}</td>
           <td>{{quotationfee.RateType}}</td>
           <td>{{quotationfee.Rate | currency:currencySymbol}}</td>
           <td>{{quotationfee.Quantity}}</td>
           <td>{{quotationfee.Total | currency:currencySymbol}}</td>
         </tr>
       </tbody>
       <tfoot>
         <tr>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td><b>Total</b></td>
           <td><b>{{quotationTotal | currency:currencySymbol}}</b></td>
         </tr>
       </tfoot>
      </table>
    </div> -->

    <!-- Past invoices table -->
    <div *ngIf="activeTab === 'past-invoices'" hidden>
      <div class="past-invoice-header">
        <span>Invoice number: {{invoiceNumber}}</span>
        <span></span>
        <span>Invoice amount: {{invoiceAmount | currency:currencySymbol}}</span>
        <span></span>
        <span>Total payments: {{totalPayments | currency:currencySymbol}}</span>
        <span></span>
        <span>Total outstanding: {{totalOutstanding | currency:currencySymbol}}</span>
      </div><!--#end .past-invoice-header -->
      <div class="table table-past-invoices">
        <div class="table-head">
          <span>Date</span>
          <span>Description</span>
          <span>Type</span>
          <span>Rate</span>
          <span>Qty</span>
          <span>Amount</span>
        </div>
      </div>
      <div class="table-body">
        <div *ngIf="!invoice">Invoice has not been generated.</div>
      </div><!--#end .table-body -->
    </div>

    <!-- Brief & other documents -->
    <div *ngIf="activeTab === 'brief-document'" style="max-height: 258px; overflow-y: scroll;" hidden>

      <div class="fileList" *ngFor="let file of briefDocuments">
        <div class="added-file" *ngIf="file">
          <div class="file-header">
            <h5 style="margin-top: 5px;"><b>{{file.DocumentType}}</b></h5>

            <button class="file-dismiss"
              style="border: 0 !important; background-color:transparent !important; margin-top: 5px;">
              <i class="fa fa-pencil" style="margin-right: 5px;" (click)="onFileEdit(file.DocumentType, file)" aria-hidden="true"></i>
              <i class="fas fa-trash" (click)="onFileRemove(file.DocumentType, file)" aria-expanded="false"></i>
            </button>
          </div>
          <div
            class="file-body"
            *ngIf="getExtension(file.DocumentPath) === 'pdf'"
            (click)="onViewDocument(file)">
            <i class="fas fa-file-pdf" aria-expanded="false"></i>
            <!-- <i class="fas fa-file-word"
            *ngIf="getExtension(file.DocumentPath) === 'docx'"></i>  -->
            <u>{{file.DocumentPath}}</u>
          </div>
          <div class="file-body"
            *ngIf="getExtension(file.DocumentPath) === 'docx' || getExtension(file.DocumentPath) === 'msg'">
            <!-- <i class="fas fa-file-pdf" *ngIf="getExtension(file.DocumentPath) === 'pdf'" aria-expanded="false"></i> -->
            <i class="fas fa-file-word"></i> <a [href]="downloadLink + file.BriefId"
              target="_blank">{{file.DocumentPath}}</a>
          </div>
          <div class="file-footer">
            Added: {{file.DateInserted | date: 'yyyy-MM-dd'}}
          </div>
        </div>
      </div>

     <form
        class="dropzone"
        #inputAttachmentFile
        appFileDrop
        (fileDropped)="handleDrop($event)"
        (filesHovered)="dropzoneState($event)"
        [ngClass]="{'active': dropzoneActive}"
        id="dropFileForm" action="" method="POST">
       <input type="file"
          #inputUploadFile
          name="files[]"
          id="fileInput"
          accept=".pdf, .docx, .msg"
          multiple
          (change)="fileAttachmentChange($event)">
       <label
          for="fileInput"
          id="fileLabel"
          style="margin-bottom: 0!important;">
         <i class="fas fa-file-plus fa-2x"></i> <br/>
         To <b>add a document</b>, drag & drop a document here or <u>browse to it</u>
       </label>
     </form>
    </div>

  </div><!--#end .well .well-fluid -->
</div><!--#end .row -->

<!-- Delete fee modal -->
<app-delete-fee-modal
  [isVisible]="showDeleteFeeModal"
  [fee]="selectedFee"
  (confirm)="deleteFee($event)"
  (cancel)="closeModal('delete-fee-modal')"></app-delete-fee-modal>
  <!-- <app-alert
    title="REQUIRED FIELDS"
    message="One or more of the required fields are not completed"
    [isVisible]="showAttorneyErrorPrompt"
    (cancel)="onHideAttorneyErrorPrompt()"
    (confirm)="onHideAttorneyErrorPrompt()"></app-alert> -->

  <!-- <app-alert
    title="Incorrect Format"
    message="Document format not allowed. Please upload the document in a PDF or Word format."
    [isVisible]="showdocumentUploadErrorPrompt"
    (cancel)="onHidedocumentUploadErrorPrompt()"
    (confirm)="onHidedocumentUploadErrorPrompt()"></app-alert> -->

  <!-- <app-prompt-unsaved-changes-modal
    title="Remove document from matter"
    [message]="selectedDocumentToDelete"
    [isVisible]="showDeleteDocumentPrompt"
    (cancel)="onHideDeleteDocumentPrompt()"
    btnTextCancel="No, Cancel"
    btnTextContinue="Yes, Remove"
    (continue)="onConfirmDeleteDocumentPrompt()"></app-prompt-unsaved-changes-modal> -->

  <!-- <app-prompt-unsaved-changes-modal
    title="Unsaved matter"
    message="The matter is not saved. Please save the matter before uploading documents."
    [isVisible]="showUploadDocumentErrorPrompt"
    (cancel)="onHideUploadDocumentErrorPrompt()"
    btnTextCancel="Cancel"
    btnTextContinue="Ok"
    (continue)="onConfirmUploadDocumentErrorPrompt()"></app-prompt-unsaved-changes-modal> -->

<!-- <app-upload-file-type-modal
  [isVisible]="showUploadModalDialog"
  (cancel)="onHideUploadModalDialog()"
  (upload)="onUploadFile($event)"
  btnTextCancel="Cancel"
  btnTextAddFile="Add document"
  [documentTypes]="documentTypes"
  [attachmentFile]="attachmentFile"
  [workingHistorical]="workingHistorical"
  [(briefDocuments)]="briefDocuments"
  [title]="documentName"></app-upload-file-type-modal> -->
<!--
<app-upload-file-type-edit-modal
  [isVisible]="showEditUploadModalDialog"
  (cancel)="onHideEditUploadModalDialog()"
  (upload)="onEditUploadFile($event)"
  btnTextCancel="Cancel"
  btnTextAddFile="Edit document type"
  [documentTypes]="documentTypes"
  [selectedDocumentType]="selectedDocumentType"
  [attachmentFile]="attachmentFile"
  [workingHistorical]="workingHistorical"
  [selectedBriefDocuments]="toEditDocumentTypes"
  [title]="documentName"></app-upload-file-type-edit-modal> -->

  <!-- <app-client-matter-documents
    *ngIf="showMatterDocumentDialog"
    [isVisible]="showMatterDocumentDialog"
    [title]="documentTitle"
    [serviceId]="selectedServiceId"
    [briefId]="selectedBriefId"
    [selectedFile]="selectedFile"
    (closeModal)="onDocClose()"></app-client-matter-documents> -->
    <app-prompt-unsaved-changes-modal
    *ngIf="onEXCLVATClick"
    title="Exclude VAT"
    message="You are about to exclude VAT on a VAT registered user. Do you wish to proceed?"
    [isVisible]="onEXCLVATClick"
    (cancel)="onEXCLVATClickCancel()"
    (continue)="onEXCLVATClickContinue()"
    btnTextCancel="Cancel"
    btnTextContinue="Proceed">
  </app-prompt-unsaved-changes-modal>
