import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { SentMessage } from '../../../services/sent-messages/sent-messages.service';
import { MessageHandle } from '../../../pages/advocate-messages/advocate-messages.component';

@Component({
  selector: 'app-sent-message-popup-menu',
  templateUrl: './sent-message-popup-menu.component.html',
  styleUrls: ['./sent-message-popup-menu.component.scss']
})
export class SentMessagePopupMenuComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() message: MessageHandle;
  @Output() forwardInvoice: EventEmitter<any> = new EventEmitter<any>();
  @Output() resendInvoice: EventEmitter<any> = new EventEmitter<any>();
  @Output() editInvoice: EventEmitter<any> = new EventEmitter<any>();
  @Output() continueWithMatter: EventEmitter<any> = new EventEmitter();
  @Output() dismiss: EventEmitter<MessageHandle> = new EventEmitter<MessageHandle>();
  @Output() download: EventEmitter<any> = new EventEmitter<any>();

  options: any = {
    previewMessage: true,
    viewQuotation: true,
    deleteMatter: true
  };

  constructor() {} // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  /**
   * Emits a dismiss event to the parent component.
   */
  onDismiss() {
    this.dismiss.emit(this.message);
  } // end onDismiss()

  /**
   * Called when the "Download" option is selected.
   */
  onDownloadInvoice() {

  } // end onDownloadInvoice()

  /**
   * Called when the "Forward invoice" option is selected.
   */
  onForwardInvoice() {
    this.onDismiss();
    this.forwardInvoice.emit(this.message);
  } // end onForwardInvoice()

  /**
   * Called when the "Resend invoice" option is selected.
   */
  onResendInvoice() {
    this.onDismiss();
    this.resendInvoice.emit(this.message);
  } // end onResendInvoice()

  /**
   * Called when the "Edit invoice" option is selected.
   */
  onEditInvoice() {
    this.onDismiss();
    this.editInvoice.emit(this.message);
  } // end onEditInvoice()

  /**
   * Called when the "Continue with matter" option is selected.
   */
  onContinueWithMatter() {
    this.onDismiss();
    this.continueWithMatter.emit(this.message);
  } // end onContinueWithMatter()
} // end SentMessagePopupMenuComponent{}
