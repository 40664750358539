<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>{{title}}</h2>
                <button class="modal-dismiss" (click)="onCancel()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body form">

                <div class="container-fluid">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <th scope="col" class="w-10">Advocate</th>
                            <th scope="col" class="w-10">InvoiceNo</th>
                            <th scope="col" class="w-10">Reference</th>
                            <th scope="col" class="w-10">Attorney</th>
                            <th scope="col" class="w-15">PartyDetails</th>
                            <th scope="col" class="w-5">Type</th> <!-- InstructionText -->
                            <th scope="col" class="w-10">RequestDate</th> 
                            <th scope="col" class="w-10">ResolvedDate</th>
                            <th scope="col" class="w-20">Note</th>
                          </tr>
                          <tr *ngFor="let advocate of queryReport">
                            <td scope="row">{{advocate.Advocate}}</td>
                            <td scope="row">{{advocate.InvoiceNo}}</td>
                            <td scope="row">{{advocate.Reference}}</td>
                            <td scope="row">{{advocate.Attorney}}</td>
                            <td scope="row">{{advocate.PartyDetails}}</td>
                            <td scope="row">{{advocate.Type}}</td>
                            <td scope="row">{{advocate.RequestDate | date:'yyyy-MM-dd'}}</td>
                            <td scope="row">{{advocate.ResolvedDate | date:'yyyy-MM-dd'}}</td>
                            <td scope="row">{{advocate.Note}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

            <div class="modal-footer">
                <button
                  class="btn btn-danger"
                  (click)="onCancel()">Cancel</button>
                
                <button
                  class="btn btn-success"
                  (click)="onCancel()">Ok</button>
              </div>
        </div>
    </div>
</div>