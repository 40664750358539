import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import {
  SnackbarsService,
  Snack,
  LENGTH_SHORT,
  LENGTH_LONG,
  SnackType
} from '../../../services/messaging/snackbars/snackbars.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  animations: [
    trigger('snackbarState', [
      state('inactive', style({
        transform: 'translateY(0px)'
      })),
      state('active', style({
        transform: 'translateY(-102px)'
      })),
      transition('inactive => active', animate('300ms linear')),
      transition('active => inactive', animate('300ms ease-out'))
    ])
  ]
})
export class SnackbarComponent implements OnInit, AfterViewInit {
  @Output() shown: EventEmitter<any> = new EventEmitter();
  @Output() dismissed: EventEmitter<any> = new EventEmitter();

  @ViewChild('container') container: ElementRef;

  action: any;
  text: string = 'single-line message.';
  duration: number = LENGTH_LONG; // in milliseconds
  isHidden: boolean = true;

  width: number;
  height: number;

  type: SnackType;
  private timeoutHandle: any;

  get snackbarState() {
    return this.isHidden ? 'inactive' : 'active';
  } // end get snackbarState()

  constructor(
    private snackbarsService: SnackbarsService
  ) { } // end constructor()

  ngOnInit() {
    this.action = {
      label: 'action'
    };

    this.snackbarsService.setSnackbarComponent(this);
  } // end ngOnInit()

  ngAfterViewInit() {
    if (this.container) {
      this.width = this.container.nativeElement.style.clientWidth;
      this.height = this.container.nativeElement.style.clientHeight;
      this.container.nativeElement.style.left = (window.innerWidth / 2) - (this.width / 2);
    }
  } // end ngAfterViewInit()

  /**
   * Calls the given handler function and dismisses the snackbar once done.
   */
  onAction() {
    if (this.action && this.action.handler) {
      this.action.handler.call(this, this.action.params);
    }
    this.dismiss();
  } // end onAction()

  /**
   * Constructs and prepares the snack.
   * @param {Snack} snack The snack to be toasted.
   * @param {number} duration The length of the toast
   */
  make(snack: Snack, duration: number = null): SnackbarComponent {
    if (snack) {
      this.text = snack.label;
      this.action = snack.action;
    }

    if (duration) {
      this.duration = duration;
    } else {
      duration = LENGTH_SHORT;
    }

    if (snack.type) {
      this.type = snack.type;
    }

    return this;
  } // end make()

  show(): SnackbarComponent {
    if (!this.isHidden) {
      clearTimeout(this.timeoutHandle);
    }
    this.isHidden = false;
    this.timeoutHandle = setTimeout(this.dismiss, this.duration, this);

    return this;
  } // end show()

  dismiss(thisObj: any = null): SnackbarComponent {
    if (thisObj) {
      // For calls from setTimeout use thisObj
      thisObj.isHidden = true;
      thisObj.type = null;
    } else {
      this.isHidden = true;
      this.type = null;
    }
    clearTimeout(this.timeoutHandle);

    return this;
  } // end dismiss()
} // end SnackbarComponent{}
