import { NonNullAssert } from '@angular/compiler';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MattersService } from '../../../services/matters/matters.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Snack, SnackbarsService, SnackType } from '../../../services/messaging/snackbars/snackbars.service';

@Component({
  selector: 'app-import-historical',
  templateUrl: './import-historical.component.html',
  styleUrls: ['./import-historical.component.scss']
})
export class ImportHistoricalComponent implements OnInit {

  message: string;
  attachmentFile: File;
  attachmentName = '';
  attachment: string;
  formData: FormData = new FormData();

  get isValidCSVFile(): boolean {
    let isV = false;
    if (this.inputAttachmentFile && this.inputAttachmentFile.nativeElement.value &&
      this.inputUploadFile && this.inputUploadFile.nativeElement.value) {
        isV = true;
      }

      return isV;
  }

  @Input() isVisible: boolean;
  @Output() closeModel = new EventEmitter<any>();
  @ViewChild('inputAttachmentFile') inputAttachmentFile: ElementRef;
  @ViewChild('inputUploadFile') inputUploadFile: ElementRef;

  constructor(
    private loadingService: LoadingService,
    private matterService: MattersService,
    private snackbarsService: SnackbarsService
  ) { } // end constructor()

  ngOnInit(): void {
  } // end ngOnInit()

  attachmentChange(e) {
    // this.attachmentFile = null;
    this.attachmentFile = <File>e.target.files[0];
    const ext = this.getExtension(this.attachmentFile.name);
    if (ext.toLowerCase() === 'csv') {
      this.attachment = this.attachmentFile.name;
      this.message = '';
    } else {
      this.attachment = null;
      this.message = 'Only allowed file with .csv extension';
    }
  } // end attachmentChange()

  promptAttachment() {
    jQuery('#attachmentUpload').val(null);
    jQuery('#attachmentUpload').trigger('click');
  } // end promptAttachment()

  getExtension(filename) {
    return filename.toString().split('.')[1];
  } // end getExtension()

  closeModal() {
    this.closeModel.emit();
  } // closeModal()

  importData() {
    const snack: Snack = {
      label: 'Import Historical Data',
      action: null
    };
    this.loadingService.showOverlay();

    const ext = this.getExtension(this.attachment);
    if (this.attachmentFile && this.attachment !== undefined && ext.toLowerCase() === 'csv') {

      const formData = new FormData();
      formData.append('Historicaldatafile', this.attachmentFile);
      formData.append('Historicaldataname', this.attachmentFile.name);
      
      this.matterService.postUploadHistoricalData(formData).subscribe({ next: (historical) => {
        // On next
      },
      error: (error) => {
        // On error
        this.formData.delete('Historicaldata');
        this.inputAttachmentFile.nativeElement.value = '';
        this.inputUploadFile.nativeElement.value = '';
        this.message = error.error.Message;
        this.attachment = '';
        snack.label = error.error.Message;
        snack.action = null;
        snack.type = SnackType.ERROR;
        this.loadingService.hideOverlay();
        this.snackbarsService.dismiss().make(snack).show();
      },
      complete: () => {
        // on complete
        this.message = '';
        snack.label = 'Historical Data Imported Successfully.';
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
        this.closeModal();
      }
    })
    } else {
      this.message = 'Only allowed file with .csv extension';
    }
  } // end importData()

} // end ImportHistoricalComponent()
