import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-status-modal',
  templateUrl: './online-status-modal.component.html',
  styleUrls: ['./online-status-modal.component.scss']
})
export class OnlineStatusModalComponent implements OnInit {

  @Input() onlineStatusMessage: string;
  @Input() onlineStatus: string;
  constructor() { }

  ngOnInit() {
  }

}
