import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarsService, Snack, SnackType, LENGTH_SHORT, } from "../../services/messaging/snackbars/snackbars.service";
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { ManageBlackListRequest, ManageBlackListService, MANAGE_BLACKLIST_REQUEST } from '../../services/manage-BlackList/manage-BlackList.service';
import { TAG_MANAGE_BLACKLIST_MODAL } from '../../services/tags/tags.service';
import { Contacts, DEFAULT_CONTACTS } from '../../services/contacts/contacts.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-manage-BlackList',
  templateUrl: './manage-BlackList.component.html',
  styleUrls: ['./manage-BlackList.component.scss']
})

export class ManageBlackListComponent implements OnInit {
  showNewGCBHandlerModal: boolean;
  showNewLPCHandlerModal: boolean;
  showBarSocietyNewLawfirmReqModal: boolean;
  viewAttachedPdf: boolean = false;
  searchTerm: any;
  sortBy: string;
  sortAscending: boolean;
  sortLawfirm_Client: boolean;
  sortLPC_Report: boolean;
  sortGCB_Report: boolean;
  sortRegional_Bar: boolean;
  sortLawfirm: boolean;
  sortReporting_Advocate: boolean;
  sortReporting_Date: boolean;
  mainMasterData: any = [];
  mainPendingMasterData: any[];
  displayTotalCount: number;
  displayAdvocatesDrp: string = '';
  selectedLawfirmId: number;
  searchPendingTerm: any;
  selectedLawfirmName: string;
  tags: any;
  tabType: string = 'lawfirmsReports';
  statusUpdate: string;
  blackListLawfirmId: number;
  // cancelErrorMessage: string;
  // onShowApprovedRejected: boolean = false;
  // onShowCancel: boolean = false;
  // statusApproved: boolean = false;
  // statusRejected: boolean = false;
  currentLawfirmId: number;
  showApproveChangesPrompt: boolean;
  showRejectChangesPrompt: boolean;
  status: string;
  isGCBUser: boolean = false;
  isSocietyBar: boolean = false;
  isGCBUserBar: boolean = false;
  showAddEditLawfirm: boolean;
  selectedLawfirm: Contacts = DEFAULT_CONTACTS;
  manageGCBPaddingReportData: any[]
  blackListLawfirm: ManageBlackListRequest = MANAGE_BLACKLIST_REQUEST
  isManageBlacklistUser: boolean = false;
  skip: number = 0;
  currentIndex = 0;
  currentCount = 0;
  searchItems = new Subject<string>()
  @ViewChild('pdfViewer') public pdfViewer;
  private previousSortBy: string;
  isSearch: boolean = true;
  isLoadmoreForSnakBar: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private manageBlackListService: ManageBlackListService,
  ) {
    this.tags = {
      modals: {
        gcb_Modal: TAG_MANAGE_BLACKLIST_MODAL,
      }
    };
  }

  ngOnInit(): void {
    this.getBlackListHandlerdata();
    this.manageGCBPaddingList();
    this.profileType();

    this.searchItems
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.isLoadmoreForSnakBar = false;
        this.mainMasterData = [];
        this.currentIndex = 0;
        this.currentCount = 0;
        this.getBlackListHandlerdata();
      });
  }

  profileType() {
    let profileType = JSON.parse(sessionStorage.getItem('userProfile')).profileType;
    if (profileType) {
      if (profileType == 'Bar Society - User') {
        this.isSocietyBar = true;
        this.isGCBUserBar = false;
      }
      else if (profileType == 'GCB - User') {
        this.isSocietyBar = false;
        this.isGCBUserBar = true;
      }
    }
  }

  getBlackListHandlerdata() {
    if (!this.isLoadmoreForSnakBar)
      this.loadingService.showOverlay();
    else {
      const snack: Snack = {
        label: "Please wait...",
        action: null,
      };
      this.snackbarsService.make(snack, LENGTH_SHORT).show();
    }
    this.manageBlackListService.getManageBlackListLawfirms((this.currentIndex * 2000), this.searchTerm)
      .subscribe({
        next: (response) => {
          this.displayTotalCount = response.TotalCount;
          if (this.displayTotalCount <= 2000)
            this.currentCount = this.displayTotalCount;
          if (response.BlackList) {
            response.BlackList.forEach(element => {
              this.mainMasterData.push(element);
            });
          }
        },
        error: (error) => {
          const snack: Snack = {
            label: error,
            type: SnackType.ERROR,
            action: null,
          };
          this.snackbarsService.dismiss().make(snack).show();
        },
        complete: () => {
          this.loadingService.hideOverlay();
        },
      });
  }
  downloadBlackListDocument(filePath: string) {
    if (!filePath)
      return;
    else {
      this.viewAttachedPdf = true
      this.manageBlackListService.getBlackListUploadedDocumentFile(filePath)
        .subscribe({
          next: (response) => {
            this.pdfViewer.name = 'name.pdf';
            this.pdfViewer.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
            this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
          },
        })
    }
  }
  dismiss() {
    this.viewAttachedPdf = false;
  } // end dismiss()
  openModal(lawfirmData: any, isGCBUser: boolean) {
    this.selectedLawfirmId = Number(lawfirmData.LawfirmId)
    this.selectedLawfirmName = lawfirmData.Lawfirm;
    this.isGCBUser = isGCBUser;
    this.showNewGCBHandlerModal = true;
    this.profileType();
  }
  openModalLPC(lawfirmData: any, isGCBUser: boolean) {
    this.selectedLawfirmId = Number(lawfirmData.LawfirmId)
    this.selectedLawfirmName = lawfirmData.Lawfirm;
    this.showNewLPCHandlerModal = true;
    this.profileType();
  }
  openNewLawfirmRequestModal() {
    this.showBarSocietyNewLawfirmReqModal = true;
  }
  getPendingAdvocateBlackList() {
    this.mainPendingMasterData = [];
    this.loadingService.showOverlay();
    this.manageBlackListService.getPendingBlackListAdvocate(this.isSocietyBar)
      .subscribe({
        next: (response) => {
          if (response) {
            response.forEach(element => {
              this.mainPendingMasterData.push({
                data: element,
                state: {
                  isVisible: true,
                  isSelected: false,
                  isOpen: false,
                }
              })
            });
            this.loadingService.hideOverlay();
            this.performPendingReqSearch();
          }
        },
      })
  }
  closeModal(refreshGrid: boolean) {
    this.showNewGCBHandlerModal = false;
    // if (refreshGrid) {
    //   if (!this.selectedLawfirmId)
    //     return;
    //   this.mainMasterData.forEach((item: any) => {
    //     if (item.LawfirmId == this.selectedLawfirmId)
    //       item.LPCCount = item.LPCCount + 1;
    //   })
    // }
  }
  closeModalLPC(refreshGrid: boolean) {
    this.showNewLPCHandlerModal = false;
    this.loadingService.hideOverlay();
    if (refreshGrid) {
      if (!this.selectedLawfirmId)
        return;
      this.mainMasterData.forEach((item: any) => {
        if (item.LawfirmId == this.selectedLawfirmId)
          item.LPCCount = item.LPCCount != 0 ? item.LPCCount - 1 : 0;
      })
    }
  }
  closeNewLawfirmRequestModal() {
    this.showBarSocietyNewLawfirmReqModal = false;
    this.loadingService.hideOverlay();
  }
  toggleSort(sortBy: string) {
    this.loadingService.showOverlay();
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }
    switch (this.sortBy) {
      case 'lawfirm_Client':
        this.sortLawfirm_Client = !this.sortLawfirm_Client;
        this.sortLPC_Report = false;
        this.sortGCB_Report = false;
        this.mainMasterData.sort((a, b) => {
          if (this.sortAscending) {
            return a.Lawfirm.toUpperCase().localeCompare(b.Lawfirm.toUpperCase());
          }
          return b.Lawfirm.toUpperCase().localeCompare(a.Lawfirm.toUpperCase());
        });
        break;
      case 'lPC_Report':
        this.sortLawfirm_Client = false;
        this.sortLPC_Report = !this.sortLPC_Report;
        this.sortGCB_Report = false;
        this.mainMasterData.sort((a, b) => {
          if (this.sortAscending) {
            return a.LPCCount - b.LPCCount;
          }
          return b.LPCCount - a.LPCCount;
        });
        break;
      case 'gCB_Report':
        this.sortLawfirm_Client = false;
        this.sortLPC_Report = false;
        this.sortGCB_Report = !this.sortGCB_Report;
        this.mainMasterData.sort((a, b) => {
          if (this.sortAscending) {
            return a.GCBCount - b.GCBCount;
          }
          return b.GCBCount - a.GCBCount;
        });
        break;
    }
    this.previousSortBy = this.sortBy;
    this.loadingService.hideOverlay();
  } // end sort()
  toggleSortPending(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }
    switch (this.sortBy) {
      case 'regional_Bar':
        this.sortRegional_Bar = !this.sortRegional_Bar;
        this.sortLawfirm = false;
        this.sortReporting_Advocate = false;
        this.sortReporting_Date = false;
        this.mainPendingMasterData.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.Name.toUpperCase().localeCompare(b.data.Name.toUpperCase());
          }
          return b.data.Name.toUpperCase().localeCompare(a.data.Name.toUpperCase());
        });
        break;
      case 'lawfirm':
        this.sortRegional_Bar = false;
        this.sortLawfirm = !this.sortLawfirm;
        this.sortReporting_Advocate = false;
        this.sortReporting_Date = false;
        this.mainPendingMasterData.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.LawfirmFullName.toUpperCase().localeCompare(b.data.LawfirmFullName.toUpperCase());
          }
          return b.data.LawfirmFullName.toUpperCase().localeCompare(a.data.LawfirmFullName.toUpperCase());
        });
        break;
      case 'reporting_Advocate':
        this.sortRegional_Bar = false;
        this.sortLawfirm = false;
        this.sortReporting_Advocate = !this.sortReporting_Advocate;
        this.sortReporting_Date = false;
        this.mainPendingMasterData.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.ReportingAdvocateFullName.toUpperCase().localeCompare(b.data.ReportingAdvocateFullName.toUpperCase());
          }
          return b.data.ReportingAdvocateFullName.toUpperCase().localeCompare(a.data.ReportingAdvocateFullName.toUpperCase());
        });
        break;
      case 'reporting_Date':
        this.sortRegional_Bar = false;
        this.sortLawfirm = false;
        this.sortReporting_Advocate = false;
        this.sortReporting_Date = !this.sortReporting_Date;
        this.mainPendingMasterData.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.ReportDate.localeCompare(b.data.ReportDate);
          }
          return b.data.ReportDate.localeCompare(a.data.ReportDate);
        });
        break;
    }
    this.previousSortBy = this.sortBy;
  } // end sort()

  LoadMoreDataOfManageDefList() {
    this.isLoadmoreForSnakBar = true;

    this.currentCount = (this.currentIndex + 2) * 2000
    this.currentIndex = this.currentIndex + 1;
    this.getBlackListHandlerdata();
  }

  togglePanel(panel: string) {
    switch (panel) {
      case 'lawfirmsReports':
        this.tabType = 'lawfirmsReports';
        this.getBlackListHandlerdata();
        this.clearSearch();
        break;
      case 'advocateList':
        this.tabType = 'advocateList';
        break;
      case 'pendingRequestReports':
        this.tabType = 'pendingRequestReports';
        this.getPendingAdvocateBlackList();
        this.clearSearch();
        break;
    }
  } // end togglePanel()

  performPendingReqSearch() {
    if (this.searchPendingTerm) {
      this.mainPendingMasterData.forEach((element) => {
        if (!(element.data.LawfirmFullName.match(new RegExp(this.searchPendingTerm, 'gi'))) &&
          !(element.data.Name.match(new RegExp(this.searchPendingTerm, 'gi'))) &&
          !(element.data.ReportingAdvocateFullName.match(new RegExp(this.searchPendingTerm, 'gi')))
        ) {
          element.state.isVisible = false;
        } else {
          element.state.isVisible = true;
        }
      })
    }
    else {
      this.mainPendingMasterData.forEach((responseData) => {
        responseData.state.isVisible = true;
      });
    }
  }
  clearSearch() {
    this.searchTerm = '';
    this.searchPendingTerm = '';
    //this.performSearch();
    this.performPendingReqSearch();
  }
  onApproved_Rejected() {
    this.loadingService.showOverlay();
    let encyptedString = window.btoa(`LawfirmBlackRequest__Status=${this.statusUpdate}&ID=${this.blackListLawfirmId}__LawfirmBlackRequest`)
    this.manageBlackListService.onApproved_Rejected(encyptedString, false)
      .subscribe({
        next: (response) => {
          this.loadingService.hideOverlay();
          this.togglePanel('pendingRequestReports');
        },
      })
  }
  manageGCBPaddingList() {
    this.manageGCBPaddingReportData = [];
    this.loadingService.showOverlay();
    this.manageBlackListService.getbyLawfirmApprovedReqList(this.currentLawfirmId, this.status)
      .subscribe({
        next: (response) => {
          this.manageGCBPaddingReportData = response;
          if (this.currentLawfirmId != null) {
            response.forEach(item => {
              this.manageGCBPaddingReportData.push()
            });
          }
        },
      })
  }
  onShowApproveChangesPrompt(data: any, status: string) {
    if (!data)
      return;
    else {
      this.blackListLawfirmId = data.LawfirmBlackListID;
      this.statusUpdate = status;
      this.showApproveChangesPrompt = true;
    }
  }
  onHideApproveChangesPrompt() {
    this.showApproveChangesPrompt = false;
  }
  onShowRejectChangesPrompt(data: any, status: string) {
    if (!data)
      return;
    else {
      this.blackListLawfirmId = data.LawfirmBlackListID
      this.statusUpdate = status;
      this.showRejectChangesPrompt = true;
    }
  }
  onHideRejectChangesPrompt() {
    this.showRejectChangesPrompt = false;
  }
  onActionClickEvent() {
    this.onApproved_Rejected();
    this.onHideApproveChangesPrompt();
    this.onHideRejectChangesPrompt();
  }
  refreshExistingGrid(isGCBDirectRemoval: boolean) {
    if (!this.selectedLawfirmId)
      return;
    if (isGCBDirectRemoval) {
      this.mainMasterData.forEach((item: any) => {
        if (item.LawfirmId == this.selectedLawfirmId)
          item.GCBCount = item.GCBCount != 0 ? item.GCBCount - 1 : 0;
      })
    }
  }
  onEditLawFirm(data: Contacts) {
    this.selectedLawfirm = data;
    this.showAddEditLawfirm = true;
  } // end onEditLawFirm()
  onCloseModal() {
    this.showAddEditLawfirm = false;
  } // end onCloseModal()
  onSaveNewLawfirm(event) {
    this.getBlackListHandlerdata();
    this.showAddEditLawfirm = false;
  } // end onSaveNewLawfirm()

}
