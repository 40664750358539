import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService, httpOptions } from '../api.service';
import { ToastService } from '../messaging/toast/toast.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export interface Plaintiff {
  FullName: string;
  ContactID: number;
} // end Plaintiff{}

// export interface OtherClients {
//   FullName: string;
//   ContactID: number;
//   EmailAddress: string;
//   IDNumber: string;
//   MobileNumber: string;
//   WorkPhone: string;
//   WorkFax: string;
//   VATNumber: string;
//   StreetAddress: string;
// } // end OtherClients{}

export const DEFAULT_PLAINTIFF_TEMPLATE = {
  FullName: '',
  ContactID: 0
}; // end DEFAULT_PLAINTIFF_TEMPLATE{}

// export const DEFAULT_OTHERCLIENTS_TEMPLATE = {
//   FullName: '',
//   ContactID: 0,
//   EmailAddress: '',
//   IDNumber: '',
//   MobileNumber: '',
//   WorkPhone: '',
//   WorkFax: '',
//   VATNumber: '',
//   StreetAddress: ''
// }; // end DEFAULT_OTHERCLIENTS_TEMPLATE{}

@Injectable()
export class PlaintiffsService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private toastService: ToastService
  ) { } // end constructor()

  getPlaintiffs(): Observable<any> {
    return this.http.get(this.api.endpoints.plaintiffs +
      '?ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getPlaintiffs()

  // getOtherClients(): Observable<any> {
  //   return this.http.get(this.api.endpoints.plaintiffs +
  //     '/' + this.api.serviceProviderID,
  //     this.api.httpOptions)
  //     .pipe(
  //       retry(3),
  //       catchError(this.api.handleError)
  //     );
  // } // end getOtherClients()

  createPlaintiff(plaintiff: Plaintiff): Observable<any> {
    return this.http.post(this.api.endpoints.plaintiffs +
      '?ServiceProviderID=' + this.api.serviceProviderID,
      plaintiff, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end createPlaintiff()

  createOtherClients(otherClients: Plaintiff): Observable<any> {
    return this.http.post(this.api.endpoints.otherClients +
      '?ServiceProviderID=' + this.api.serviceProviderID,
      otherClients, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end createOtherClients()

} // end PlaintiffsService{}
