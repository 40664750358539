<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="badge">
    <input
      required 
      [(ngModel)]="selectedLocality && selectedLocality.Description" 
      [typeahead]="contactLocalities"
      typeaheadOptionField="Description" 
      [typeaheadSingleWords]="false" 
      (input)="onInput($event)" 
      class="categotyText"
      placeholder="Select or start typing" 
      (typeaheadOnSelect)="onSelectContactLocality($event)"
      [typeaheadOptionsLimit]="100" 
      typeaheadScrollable=true
      typeaheadOptionsInScrollableView="20" 
      [typeaheadMinLength]="0"
      [disabled] = "isManageBlacklistUser ? null : 'disabled'">
  </div>
