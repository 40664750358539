import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { InvoiceDetails, InvoiceDetailsService, DEFAULT_INVOICE_DETAILS } from '../../../services/invoice-details/invoice-details.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { InvoiceDocumentService } from '../../../services/invoice-document/invoice-document.service';
import { ApiService } from '../../../services/api.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import {
  NotesObject,
  Notes,
  NotesService,
  NoteCategory,
  DEFAULT_NOTES_OBJECT,
  NotesObjectHandle,
  InstructionResponses,
} from '../../../services/notes/notes.service';
import * as moment from 'moment';

@Component({
  selector: 'app-lawfirm-invoice-preview',
  templateUrl: './lawfirm-invoice-preview.component.html',
  styleUrls: ['./lawfirm-invoice-preview.component.scss']
})
export class LawfirmInvoicePreviewComponent implements OnInit, AfterViewInit {

  reportUrl: string;
  invoice: InvoiceDetails;
  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  _fileName;
  @ViewChild('pdfViewer') public pdfViewer;
  @Input() invoiceDetails: InvoiceDetails;
  @Input() invoiceId: number;
  @Input() plaintiff: string;
  @Input() defendant: string;
  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private api: ApiService,
    private notesService: NotesService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private invoiceDetailService: InvoiceDetailsService,
    private invoiceDocumentService: InvoiceDocumentService
  ) { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  ngAfterViewInit() {
  this.loadingService.showOverlay();
  this.reportUrl = this.api.endpoints.invoiceDocument +
    '?invoiceID=' + this.invoiceId;

  this.invoiceDocumentService.getInvoiceDocument(this.reportUrl).subscribe({
    next: (invoiceDoc) => {
    this.pdfViewer.name = 'Invoice.pdf';
    this.pdfViewer.pdfSrc = invoiceDoc;
    this.pdfViewer.refresh();
  },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.loadingService.hideOverlay();
    }
  });
  } // end ngAfterViewInit()

  @Input()
  set fileName(fileName: string) {
    this._fileName = fileName;
  }
  get fileName() {
    return this._fileName;
  }

  dismiss() {
    this.closeModal.emit('invoice');
  } // end dismiss()
} // end LawfirmInvoicePreviewComponent{}
