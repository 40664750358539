import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild
} from '@angular/core';
import * as moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  
  @Input() id: string;
  @Input() name: string;
  @Input() format: string;
  @Input() model: string;
  @Input() value: string;
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() input: EventEmitter<Event> = new EventEmitter<Event>();

  isOpen: boolean;

  get date(): string {
    return this._date;
  } // end date()

  set date(value: string) {
    this._date = value;
  } // end date()

  private _date: string;

  constructor() { } // end constructor()

  ngOnInit() {
    if (!this.format) {
      this.format = DEFAULT_DATE_FORMAT;
    }

    if (!this.model) {
      this.model = moment().format(this.format);
    }
  } // end ngOnInit()

  onBlur(event) {} // end onBlur()

  onFocus(event) {} // end onFocus()

  onInput(event) {
    this.input.emit(event);
  } // end onInput()

  onDateSelect(date) {
    this.date = date;
    this.model = this.date;
    this.modelChange.emit(this.date);
    this.onInput({
      target: {
        value: this.date
      }
    });
  } // end onDateSelect()

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  } // end toggleDropdown()

  showModal() {
    this.isOpen = true;
  } // end showModal()

  hideModal() {
    this.isOpen = false;
  } // hideModal()
} // end DatePickerComponent{}
