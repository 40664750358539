import { Component, OnInit } from '@angular/core';
import {
  FeeTemplate,
  FeeTemplateService,
  DEFAULT_FEE_TEMPLATE
} from '../../services/fee-template/fee-template.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { SnackbarsService, Snack, SnackType } from '../../services/messaging/snackbars/snackbars.service';
import { ActivatedRoute } from '@angular/router';
// import { ThrowStmt } from '@angular/compiler';
import { ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import * as moment from 'moment';
import { DEFAULT_ACTIVITY_LOGS, ActivityLogs, ActivityLogsService } from '../../services/activity-logs/activity-logs.service';

const FIELD_DESCRIPTION               = 'Description';
const FIELD_CATEGORY                  = 'Category';
const FIELD_RATE_TYPE                 = 'Rate type';

interface FeeTemplatesHandle {
  data: FeeTemplate;
  state: {
    isVisible: boolean;
  };
}

@Component({
  selector: 'app-advocate-edit-fee-templates',
  templateUrl: './advocate-edit-fee-templates.component.html',
  styleUrls: ['./advocate-edit-fee-templates.component.scss']
})
export class AdvocateEditFeeTemplatesComponent implements OnInit {
  title = 'Edit fee templates';
  crumbs: ICrumb[];
  searchTerm = '';
  feeTemplates: FeeTemplate[] = [];
  feeTemplatesHandles: FeeTemplatesHandle[] = [];
  activeFeeTemplate: FeeTemplate;
  showFeeTemplateModal = false;
  sortDescription: boolean;
  sortCategory: boolean;
  sortRateType: boolean;

  sortAscending = false;
  orderBy = 'Description';
  orderOptions: string[] = [
    'Description',
    'Category',
    'Rate type'
  ];

  isLoading = false;

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  constructor(
    private route: ActivatedRoute,
    private userProfileService: UserProfileService,
    private snackbarsService: SnackbarsService,
    private feeTemplatesService: FeeTemplateService,
    private activityLogService: ActivityLogsService,
  ) {
    document.title = route.snapshot.data.title;
  } // end constructor()

  ngOnInit() {
    this.crumbs = [
      {
        label: 'Add | Edit fee templates'
      }
    ];
    this.fetchFeeTemplates();
  } // ngOnInit()

  fetchFeeTemplates() {
    const snack: Snack = {
      label: 'Loading fee templates',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();

    this.isLoading = true;
    let userName = '';
    if (this.userProfileService.userProfile.isImpersonator) {
      userName = this.userProfileService.selectedUserProfile.auxconUserName;
    } else {
      userName = this.userProfileService.userProfile.auxconUserName;
    }
    this.feeTemplatesService
      .getFeeTemplates(userName)
      .subscribe({ next: 
        (templates) => {
          // On next
          this.feeTemplates = templates;
        },
        error: (error) => {
          // On error
          const msg = 'Error loading fee templates.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
        },
        complete: () => {
          // On complete
          this.sort();
          // this.buildFeeTemplateHandlesList();
        }
      });
  } // end fetchFeeTemplates()

  buildFeeTemplateHandlesList() {
    this.feeTemplatesHandles = [];
    this.feeTemplates.forEach(temp => {
      this.feeTemplatesHandles.push({
        data: temp,
        state: {
          isVisible: true
        }
      });
    });
    this.isLoading = false;
  } // buildFeeTemplateHandlesList()

  onOrderBy(orderBy: string) {
    if (orderBy.localeCompare(this.orderBy) === 0) {
      // Toggle Sort
      this.toggleSortAscending();
      this.sort();
    } else {
      this.orderBy = orderBy;
      this.sort();
    }
  } // end onOrderBy()

  /**
   * Sorts the fee templates based on the value of the orderBy variable.
   */
  sort() {
    switch (this.orderBy) {

      case 'Description':
      this.sortDescription = true;
      this.sortCategory = false;
      this.sortRateType = false;
        if (this.sortAscending) {
          this.feeTemplates.sort((a, b) => {
            return a.SubCategory
              .toUpperCase()
              .localeCompare(b.SubCategory.toUpperCase());
          });
        } else {
          this.sortDescription = false;
          this.feeTemplates.sort((a, b) => {
            return b.SubCategory
              .toUpperCase()
              .localeCompare(a.SubCategory.toUpperCase());
          });
        }
        break;

      case 'Category':
        this.sortDescription = false;
        this.sortCategory = true;
        this.sortRateType = false;
        if (this.sortAscending) {
          this.feeTemplates.sort((a, b) => {
            return a.Category
              .toUpperCase()
              .localeCompare(b.Category.toUpperCase());
          });
        } else {
          this.sortCategory = false;
          this.feeTemplates.sort((a, b) => {
            return b.Category
              .toUpperCase()
              .localeCompare(a.Category.toUpperCase());
          });
        }
        break;

      case 'Rate type':
        this.sortDescription =  false;
        this.sortCategory = false;
        this.sortRateType = true;
        if (this.sortAscending) {
          this.feeTemplates.sort((a, b) => {
            return a.RateType
              .toUpperCase()
              .localeCompare(b.RateType.toUpperCase());
          });
        } else {
          this.sortRateType = false;
          this.feeTemplates.sort((a, b) => {
            return b.RateType
              .toUpperCase()
              .localeCompare(a.RateType.toUpperCase());
          });
        }
        break;
    } // end switch()
    this.buildFeeTemplateHandlesList();
    this.filterFeeTemplates();
  } // end sort()

  orderFeeTemplate() {
    switch (this.orderBy) {
      case FIELD_DESCRIPTION:
      this.feeTemplatesHandles.sort((a,  b) => {
        if (this.sortAscending) {
          return a.data.SubCategory.toUpperCase().localeCompare(b.data.SubCategory.toUpperCase());
        }
        return b.data.SubCategory.toUpperCase().localeCompare(a.data.SubCategory.toUpperCase());
      });
      break;

      case FIELD_CATEGORY:
        this.feeTemplatesHandles.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.Category.toUpperCase().localeCompare(b.data.Category.toUpperCase());
          }
          return b.data.Category.toUpperCase().localeCompare(a.data.Category.toUpperCase());
        });
      break;

      case FIELD_RATE_TYPE:
        this.feeTemplatesHandles.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.RateType.toUpperCase().localeCompare(b.data.RateType.toUpperCase());
          }
          return b.data.RateType.toUpperCase().localeCompare(a.data.RateType.toUpperCase());
        });
      break;
    }
  } // end orderFeeTemplate()

  toggleSortAscending() {
    this.sortAscending = !this.sortAscending;
    this.orderFeeTemplate();
  } // end toggleSortAscending()

  onEditTemplate(template: FeeTemplate) {
    this.activeFeeTemplate = template;
    this.showFeeTemplateModal = true;
  } // end onEditTemplate()

  onAddNewFeeTemplate() {
    this.activeFeeTemplate = Object.assign({}, DEFAULT_FEE_TEMPLATE);
    this.showFeeTemplateModal = true;
  } // end onAddNewFeeTemplate()

  filterFeeTemplates() {
    if (this.searchTerm) {
      // Do filter
      this.feeTemplatesHandles.forEach(temp => {
        temp.state.isVisible = false;
        if (temp.data.SubCategory.match(new RegExp(this.searchTerm, 'gi')) ||
            temp.data.Category.match(new RegExp(this.searchTerm, 'gi')) ||
            temp.data.RateType.match(new RegExp(this.searchTerm, 'gi'))) {
          temp.state.isVisible = true;
        }
      });
    } else {
      // Reset all items
      this.feeTemplatesHandles.forEach(temp => {
        temp.state.isVisible = true;
      });
    }
  } // end filterFeeTemplates()

  onCloseModal() {
    this.activeFeeTemplate = null;
    this.showFeeTemplateModal = false;
  } // end onCloseModal()

  onSaveFeeTemplate(feeTemplate: FeeTemplate) {
    // TODO: Fix fee template modal clearing bug
    const isNewFeeTemplate: boolean = feeTemplate.SubCategoryId < 1;
    const snack: Snack = {
      label: isNewFeeTemplate
        ? 'Creating fee template...'
        : 'Saving fee template...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();

    if (!isNewFeeTemplate) {
      // Update fee template

      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Add new fee template';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-edit-fee-template)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'ActionTypes';
      this.activityLog.JsonData = JSON.stringify(feeTemplate);
      // this.activityLogService.addActivityLog(this.activityLog).toPromise();

      this.feeTemplatesService.updateFeeTemplate(feeTemplate).subscribe({ next: (next) => {
        // On next
        this.fetchFeeTemplates();
        },
        error: (error) => {
          const msg = 'Error loading Fee templates.';
          snack.type = SnackType.ERROR;
          console.error(msg, error);
        },
        complete: () => {
          // On complete
          snack.label = 'Fee template saved.';
          this.snackbarsService.dismiss().make(snack).show();
        }
      });
    } else {
      let userName = '';
      if (this.userProfileService.userProfile.isImpersonator) {
        userName = this.userProfileService.selectedUserProfile.auxconUserName;
      } else {
        userName = this.userProfileService.userProfile.auxconUserName;
      }
      feeTemplate.UserName = userName;
      // Create new fee template
      this.feeTemplatesService.createFeeTemplate(feeTemplate).subscribe({ next: 
        (feeTemplateResponse) => {
          // On next
        },
        error: (error) => {
          const msg = 'Error saving new fee template.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
        },
        complete: () => {
          // On complete
          snack.label = 'Fee template created.';
          this.snackbarsService.dismiss().make(snack).show();
          this.fetchFeeTemplates();
        }
      });
    }
  } // end onSaveFeeTemplate()

} // end AdvocateEditFeeTemplatesComponent{}

// openModal(tags.modals.feeTemplateModal)

