<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">


<div class="container-fluid">
    <h2 class="page-title">PROCESS</h2>
    <div class="process-body">
        <div class="row">
            <div class="col-sm-2" *ngIf="profile.serviceProviderID === 29723">
                <a (click)="onClick('processed-bank-transaction')">
                    <i class="fal fa-clipboard-list-check"></i>
                    <p>Processed Bank Transactions</p>
                </a>
            </div>
            <div class="col-sm-2">
                <a (click)="onClick('import-bank-statements')" *ngIf="profile.serviceProviderID !== 29723">
                    <i class="fal fa-file-download"></i>
                    <p>Import Bank Statements</p>
                </a>
            </div>
            <div class="col-sm-2" hidden>
                <a>
                    <i class="fal fa-sync"></i>
                    <p>Sync Bank Statements</p>
                </a>
            </div>
            <div class="col-sm-2">
                <a (click)="onClick('proof-of-payments')" *ngIf="profile.serviceProviderID !== 29723">
                    <i class="fal fa-hand-receiving">
                        <i *ngIf="popCount != 0" style="color: #8abd37" class="fas fa-circle"><b>{{popCount}}</b></i>
                    </i>
                    <p>Proof of Payments</p>
                </a>
            </div>
            <div class="col-sm-2">
                <a class="a" (click)="onClick('reconcile-bank-transactions')"
                    *ngIf="profile.serviceProviderID !== 29723">
                    <i class="fal fa-clipboard-list">
                        <i *ngIf="count != 0" class="fas fa-circle"><b>{{count}}</b></i>
                    </i>
                    <p>Reconcile New Bank Transactions</p>
                </a>
            </div>
            <div class="col-sm-2" *ngIf="profile.serviceProviderID !== 29723">
                <a (click)="onClick('processed-bank-transaction')">
                    <i class="fal fa-clipboard-list-check"></i>
                    <p>Processed Bank Transactions</p>
                </a>
            </div>
            <div class="col-sm-2">
                <a (click)="onClick('statement-of-account')" *ngIf="profile.serviceProviderID !== 29723">
                    <i class="fal fa-file-alt"></i>
                    <p>Statement of Account</p>
                </a>
            </div>
            <div class="col-sm-2">
                <a (click)="onClick('manage-bank-accounts')" *ngIf="profile.serviceProviderID !== 29723">
                    <i class="fal fa-piggy-bank"></i>
                    <p>Manage Bank Accounts</p>
                </a>
            </div>
            <div class="col-sm-2">
                <a (click)="onClick('account-transaction')">
                    <i class="fal fa fa-bank"></i>
                    <p>Account Transactions</p>
                </a>
            </div>
            <div class="col-sm-2" hidden>
                <a>
                    <i class="fal fa-circle"></i>
                    <p>Item Name</p>
                </a>
            </div>
            <div class="col-sm-2" hidden>
                <a>
                    <i class="fal fa-circle"></i>
                    <p>Item Name</p>
                </a>
            </div>
            <div class="col-sm-2" hidden>
                <a>
                    <i class="fal fa-circle"></i>
                    <p>Item Name</p>
                </a>
            </div>
            <div class="col-sm-2" hidden>
                <a>
                    <i class="fal fa-circle"></i>
                    <p>Item Name</p>
                </a>
            </div>
            <div class="col-sm-2" hidden>
                <a>
                    <i class="fal fa-circle"></i>
                    <p>Item Name</p>
                </a>
            </div>
            <div class="col-sm-2" hidden>
                <a>
                    <i class="fal fa-circle"></i>
                    <p>Item Name</p>
                </a>
            </div>
        </div>


    </div>
</div>

<app-import-bank-statements *ngIf="isUploadBankStatement" (refreshPage)="onCloseModel($event)"
    (isImported)="isImported($event)" [bankStatementSummary]="bankStatementSummary">
</app-import-bank-statements>
<app-success-alert title="Statement imported" [isVisible]="showImportedSuccessModal"
    (cancel)="onCloseImportedSuccessModal()" (continue)="onContinue()"></app-success-alert>