<div class="container-fluid">
  <div class="row column-display-row" *ngIf="advocateHealthHandle.length > 0">
    <div class="col-sm-6 offset-sm-6 text-left p-0">
      <div class="column-display-area">
        <div class="display-options">
          <span class="column-search">
            <button class="btn btn-text">
              <i class="fa fa-search"></i>
            </button>
            <input type="search" placeholder="Search Advocates" (keyup)="persormSearch()" [(ngModel)]="searchterm">
          </span> <!-- #end .column-search -->
          <span class="column-order-by">
            <label for="sort-by">Critical:</label>
            <select id="sort-by" [(ngModel)]="sortData" (change)="orderData()">
              <option value="" disabled selected hidden>Filter criticals</option>
              <option value="orderLastMeetingDate">Last meeting date</option>
              <option value="orderLastInvoiceDate">Last invoice date</option>
              <option value="orderLastTransactionDate">Last transaction date</option>
              <option value="orderLastStatementDate">Last statement date</option>
              <option value="orderInvoicedInThreeMonths">Invoiced in last 3 months</option>
              <option value="orderCollectedInThreeMonths">Collected in last 3 months</option>
              <option value="lastNotificationDate">Last Notification Date</option>
              <option value="collection">Collection</option>
              <option value="bsAccess">B/S Access</option>
              <option value="orderCollectedLastMonths">Collected last months</option>
            </select>
            <button class="btn btn-toggle" (click)="toggleAscending()">
              <i class="fa" [class.fa-sort-amount-desc]="!sortAscending" [class.fa-sort-amount-asc]="sortAscending"></i>
            </button>
          </span> <!-- #end .column-order-by -->
        </div> <!-- #end .display-options -->
      </div> <!-- #end column-display-area -->
    </div> <!-- #end .col-sm-6 .offset-sm-6 .text-left p-0 -->
  </div> <!-- #end .row .column-display-row -->

  <div class="table-responsive" *ngIf="advocateHealthHandle.length > 0">
    <table class="table table-striped" style="border: 0;">
      <thead class="thead">
        <tr>
          <th>
            <app-checkbox [(checked)]="allSelected" (click)="toggleSelectAll()"></app-checkbox>
          </th>
          <th class="w-20" (click)="toggleSort('advocate')">Advocate <i class="fa fa-sort"
              [class.fa-sort-down]="sortAdvocate"></i></th>
          <th class="w-8" (click)="toggleSort('last-meeting')">Last meeting date <i class="fa fa-sort"
              [class.fa-sort-down]="sortLastMeeting"></i></th>
          <th class="w-8" (click)="toggleSort('last-invoiced')">Last invoice date <i class="fa fa-sort"
              [class.fa-sort-down]="sortLastInvoiced"></i></th>
          <th class="w-8" (click)="toggleSort('transaction-date')">Last transaction date <i class="fa fa-sort"
              [class.fa-sort-down]="sortLastTransaction"></i></th>
          <th class="w-8" (click)="toggleSort('statement-date')">Last statement date <i class="fa fa-sort"
              [class.fa-sort-down]="sortLastStatement"></i></th>
          <th class="w-8" (click)="toggleSort('statement-reminder-date')">Last Notification date <i class="fa fa-sort"
              [class.fa-sort-down]="sortLastNotificationDate"></i></th>
          <th class="w-9" (click)="toggleSort('invoiced-three-months')">Invoiced in last 3 months <i class="fa fa-sort"
              [class.fa-sort-down]="sortInvoicedThreeMonths"></i></th>
          <th class="w-9" (click)="toggleSort('collected-three-months')">Collected in last 3 months <i
              class="fa fa-sort" [class.fa-sort-down]="sortCollectedthreeMonths"></i></th>
          <th class="w-8" (click)="toggleSort('collected-last-months')">Collected last months <i class="fa fa-sort"
              [class.fa-sort-down]="sortCollectedLastMonths"></i></th>
          <th class="w-8" (click)="toggleSort('is-access')">B/S Access <i class="fa fa-sort"
              [class.fa-sort-down]="sortIsAccess"></i></th>
          <th class="w-8" (click)="toggleSort('collections')">Collection <i class="fa fa-sort"
              [class.fa-sort-down]="sortCollections"></i></th>
          <!-- <th class="w-30" (click)="toggleSort('total-outstanding')">Total outstanding <i class="fa fa-sort"
              [class.fa-sort-down]="sortTotalOutstanding"></i></th> -->
        </tr>
      </thead> <!-- #end .thead -->
      <tbody class="tbody">
        <tr *ngFor="let advocate of advocateHealthHandle">
          <td *ngIf="advocate.state.isVisible" style="cursor: pointer;">
            <span class="column-check">
              <app-checkbox [(checked)]="advocate.data.Selected" (click)="toggleChecbox(advocate)"></app-checkbox>
            </span>
          </td>
          <td class="w-20" *ngIf="advocate.state.isVisible" style="cursor: pointer;"
            (click)="onAdvocateSelect(advocate)"><u>{{advocate.data.Advocate}}</u></td>
          <td class="w-8" *ngIf="advocate.state.isVisible" (click)="onAdvocateLastMeetingDate(advocate.data)">
            <div
              [ngStyle]="{'color': getCellColor('meetingDate', advocate), 'font-weight': getFontWeight('meetingDate', advocate)}"
              (mouseenter)="onHover('meetingDate', advocate.data.LastMeetingDate, 0)" class="tool-tip">
              {{advocate.data.LastMeetingDate | date:'yyyy-MM-dd'}}
              <div class="tooltiptext">
                <div class="container mt-5" *ngIf="showTooltipOverlay">
                  <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                </div>
                <div class="container" *ngIf="showTooltipOverlay===false">
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-10"><b>Last meeting was more than {{lastMet}} weeks ago</b></div>
                  </div>
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-8 text-left" style="padding: 0 !important; margin: 0 !important">Last meeting
                      date</div>
                    <div class="col-md-4 text-right" style="padding: 0 !important; margin: 0 !important">
                      {{advocate.data.LastMeetingDate | date:'yyyy-MM-dd'}}</div>
                  </div>
                  <!-- <div style="position: absolute;bottom:3px;left:0;right:0"><u>Schedule meeting now ></u></div> -->
                </div>
              </div>
            </div>
          </td>
          <td class="w-8" *ngIf="advocate.state.isVisible">
            <div
              [ngStyle]="{'color': getCellColor('invoice', advocate), 'font-weight': getFontWeight('invoice', advocate)}"
              (mouseenter)="onHover('invoice', advocate.data.Last_Invoice_Created, 0)" class="tool-tip">
              {{advocate.data.Last_Invoice_Created | date:'yyyy-MM-dd'}}
              <div class="tooltiptext">
                <div class="container mt-5" style="padding: 0 !important; margin: 0 !important"
                  *ngIf="showTooltipOverlay">
                  <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                </div>
                <div class="container" *ngIf="showTooltipOverlay===false">
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-10" style="padding: 0 !important; margin: 0 !important"><b>Last invoice sent was
                        more than {{lastInvoiceSent}} weeks ago</b></div>
                  </div>
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-8 text-left" style="padding: 0 !important; margin: 0 !important">Last invoice
                      date</div>
                    <div class="col-md-4 text-right" style="padding: 0 !important; margin: 0 !important">
                      {{advocate.data.Last_Invoice_Created | date:'yyyy-MM-dd'}}</div>
                  </div>
                  <!-- <div style="position: absolute;bottom:3px;left:0;right:0"><u>Follow up with customer now ></u></div> -->
                </div>
              </div>
            </div>
          </td>
          <td class="w-8" *ngIf="advocate.state.isVisible">
            <div
              [ngStyle]="{'color': getTransactionCellColor('transactionDate', advocate), 'font-weight': getFontWeight('transactionDate', advocate)}"
              (mouseenter)="onHover('transactionDate', advocate.data.Last_Transaction, 0)" class="tool-tip">
              {{advocate.data.Last_Transaction | date:'yyyy-MM-dd'}}
              <div class="tooltiptext">
                <div class="container mt-5" style="padding: 0 !important; margin: 0 !important"
                  *ngIf="showTooltipOverlay">
                  <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                </div>
                <div class="container" *ngIf="showTooltipOverlay===false">
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-10" style="padding: 0 !important; margin: 0 !important"><b>Last transaction was
                        more than {{lastTransaction}} weeks ago</b></div>
                  </div>
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-8 text-left" style="padding: 0 !important; margin: 0 !important">Last transaction
                      date</div>
                    <div class="col-md-4 text-right" style="padding: 0 !important; margin: 0 !important">
                      {{advocate.data.Last_Transaction | date:'yyyy-MM-dd'}}</div>
                  </div>
                  <!-- <div style="position: absolute;bottom:3px;left:0;right:0"><u>Action: Call a customer now ></u></div> -->
                </div>
              </div>
            </div>
          </td>
          <td class="w-8" *ngIf="advocate.state.isVisible">
            <div
              [ngStyle]="{'color': getStatementCellColor('statementDate', advocate), 'font-weight': getFontWeight('statementDate', advocate)}"
              (mouseenter)="onHover('statementDate', advocate.data.Last_Statement_Date, 0)" class="tool-tip">
              {{advocate.data.Last_Statement_Date | date:'yyyy-MM-dd'}}
              <div class="tooltiptext">
                <div class="container mt-5" style="padding: 0 !important; margin: 0 !important"
                  *ngIf="showTooltipOverlay">
                  <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                </div>
                <div class="container" *ngIf="showTooltipOverlay===false">
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-10" style="padding: 0 !important; margin: 0 !important"><b>Last statement was
                        more than {{lastStatement}} weeks ago</b></div>
                  </div>
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-8 text-left" style="padding: 0 !important; margin: 0 !important">Last statement
                      date</div>
                    <div class="col-md-4 text-right" style="padding: 0 !important; margin: 0 !important">
                      {{advocate.data.Last_Statement_Date | date:'yyyy-MM-dd'}}</div>
                  </div>
                  <!-- <div style="position: absolute;bottom:3px;left:0;right:0"><u>Action: Call a customer now ></u></div> -->
                </div>
              </div>
            </div>
          </td>
          <td class="w-8" *ngIf="advocate.state.isVisible">
            <div class="tool-tip">
              {{advocate.data.LastStatementReminderDate == null ? '-': advocate.data.LastStatementReminderDate |
              date:'yyyy-MM-dd'}}
            </div>
          </td>
          <td class="w-9" *ngIf="advocate.state.isVisible">
            <div (mouseenter)="onHover('totalInvoiced', advocate.data.Total_Invoiced_120, advocate.data.FeeTarget)"
              class="tool-tip"
              [ngStyle]="{'color': advocate.state.invoicedColor, 'font-weight': getFontWeight('totalInvoiced', advocate)}">
              {{advocate.data.Total_Invoiced_120 | currency: currencySymbol: currencySymbol:'1.2-2'}}
              <div class="tooltiptext">
                <div class="container mt-5" style="padding: 0 !important; margin: 0 !important"
                  *ngIf="showTooltipOverlay">
                  <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                </div>
                <div class="container" *ngIf="showTooltipOverlay===false">
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-10" style="padding: 0 !important; margin: 0 !important"><b>Customer is
                        {{belowAboveInvoiced}}% {{belowAboveInvoicedText}} invoice target</b></div>
                  </div>
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-4 text-right" style="padding: 0 !important; margin: 0 !important"></div>
                  </div>
                  <!-- <div style="position: absolute;bottom:3px;left:0;right:0"><u>Action: Call a customer now ></u></div> -->
                </div>
              </div>
            </div>
          </td>
          <td class="w-9" *ngIf="advocate.state.isVisible">
            <div (mouseenter)="onHover('collected', advocate.data.Paid120Days, advocate.data.IncomeTarget)"
              class="tool-tip"
              [ngStyle]="{'color': advocate.state.collectedColor, 'font-weight': getFontWeight('collected', advocate)}">
              {{advocate.data.Paid120Days | currency: currencySymbol: currencySymbol:'1.2-2'}}
              <div class="tooltiptext">
                <div class="container mt-5" style="padding: 0 !important; margin: 0 !important"
                  *ngIf="showTooltipOverlay">
                  <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                </div>
                <div class="container" *ngIf="showTooltipOverlay===false">
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-10" style="padding: 0 !important; margin: 0 !important"><b>Customer is
                        {{belowAboveCollected}}% {{belowAboveCollectedText}} collected target</b></div>
                  </div>
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-4 text-right" style="padding: 0 !important; margin: 0 !important"></div>
                  </div>
                  <!-- <div style="position: absolute;bottom:3px;left:0;right:0"><u>Action: Call a customer now ></u></div> -->
                </div>
              </div>
            </div>
          </td>
          <td class="w-8" *ngIf="advocate.state.isVisible">
            <div
              (mouseenter)="onHover('collectedThisMonth', advocate.data.CollectedLastMonth, advocate.data.IncomeTarget)"
              class="tool-tip"
              [ngStyle]="{'color': advocate.state.collectedThisMonthColor, 'font-weight': getFontWeight('collectedThisMonth', advocate)}">
              {{advocate.data.CollectedLastMonth | currency: currencySymbol: currencySymbol:'1.2-2'}}
              <div class="tooltiptext">
                <div class="container mt-5" style="padding: 0 !important; margin: 0 !important"
                  *ngIf="showTooltipOverlay">
                  <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                </div>
                <div class="container" *ngIf="showTooltipOverlay===false">
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-10" style="padding: 0 !important; margin: 0 !important"><b>Customer is
                        {{belowAboveLastMonthCollected}}%
                        {{belowAboveCollectedLastMonthText}} collected target</b></div>
                  </div>
                  <div class="row" style="padding: 0 !important; margin: 0 !important">
                    <div class="col-md-4 text-right" style="padding: 0 !important; margin: 0 !important"></div>
                  </div>
                  <!-- <div style="position: absolute;bottom:3px;left:0;right:0"><u>Action: Call a customer now ></u></div> -->
                </div>
              </div>
            </div>
          </td>

          <td class="w-8" *ngIf="advocate.state.isVisible">
            {{advocate.data.IsAccess === true ? 'Yes' : 'No'}}
          </td>
          <td class="w-8" *ngIf="advocate.state.isVisible">
            {{advocate.data.collect}}
          </td>
          <!-- <td class="w-30" *ngIf="advocate.state.isVisible">
            <div class="">
              <div class="border-rounded-right"
                [ngStyle]="{'background-color': getBackgroundColor(event), 'width': getBarWidth(advocate.data.Total_Due_98)}">
                <div class="tool-tip" (mouseenter)="onHover('chart', 0,0)">&nbsp;
                  <div class="tooltiptext">
                    <div class="container" style="padding: 0 !important; margin: 0 !important"
                      *ngIf="showTooltipOverlay===false">
                      <div class="row" style="padding: 0 !important; margin: 0 !important">
                        <div class="col-md-10 text-left" style="padding: 0 !important; margin: 0 !important"><b>Total
                            Outstanding</b></div>
                      </div>
                      <div class="row" style="padding: 0 !important; margin: 0 !important">
                        <div class="col-md-10 text-left" style="padding: 0 !important; margin: 0 !important">Total
                          outstanding {{advocate.data.Total_Due_98 | currency: currencySymbol: currencySymbol:'1.2-2'}}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td> -->
        </tr>
      </tbody> <!-- #end .tbody -->
    </table> <!-- #end .table .table-striped -->
  </div> <!-- #end .table-responsive -->

</div> <!-- #end .container-fluid -->

<app-manage-meeting-date [isVisible]="showAdvocateMeetingDateModel" [AdvocateDetails]="LastDate"
  (closemodal)="closeModel()" (saveLastMeetingDate)="SaveLastMeetingDate($event)">
</app-manage-meeting-date>