import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
 } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { ReportsService } from '../../../services/reports/reports.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { AllCustomerReport } from '../../../components/reports/individual-customer-report/individual-customer-report.component';
import * as moment from 'moment';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-discounting-modal',
  templateUrl: './discounting-modal.component.html',
  styleUrls: ['./discounting-modal.component.scss']
})
export class DiscountingModalComponent implements OnInit {

  title = 'Discounting Invoice';
  @Input() isVisible: boolean;
  @Input() individualCustomerData: AllCustomerReport;
  @Output() saveDetails: EventEmitter<any> = new EventEmitter<any>();

  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  get isSaveable(): boolean {
    return !Boolean(this.util.isValidText(this.individualCustomerData.discountingDueDate))
  } // end isSaveable()

  constructor(
    private util: UtilitiesService,
    private loadingDialog: LoadingService,
    private reportService: ReportsService,
    private userProfileService: UserProfileService
  ) { } // end constructor{}

  ngOnInit() {
  } // end ngOnInit()

  onDateValueChange(value: Date): void {
    this.individualCustomerData.discountingDueDate = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS');
  } // end onDateValueChange()

  onSave() {
    this.saveDetails.emit(this.individualCustomerData);
  } // end onSave()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()
} // end DiscountingModalComponent{}
