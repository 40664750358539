<div class="overview-container">
    <h2 class="chart-summary-heading">overview</h2>
    <div class="overview-summary overview-summary-top-row">
        <div class="overview-child-block overview-child-block-right">
            <a (click)="onItemClick('customer-payment-rating')">
                <div class="overview-child-head" [ngStyle]="{'background-color': averageDayColor}">
                    Average Days to payment
                </div>
                <div class="overview-child-body ">
                    <h1 class="overview-title" [ngStyle]="{'color': averageDayColor}" >{{averagePaymentDays}} Days</h1>
                    <p>on <u>average taken to pay</u><br>from date of invoice</p>
                </div>
            </a>
        </div>
        <div class="overview-child-block">
            <a (click)="onItemClick('work-in-progress')">
                <div class="overview-child-head projected-collect-header" [ngStyle]="{'background-color': workSummaryColor}">
                    Work in Progress
                </div>
                <div class="overview-child-body ">
                    <h1 class="projected-collect overview-title" [ngStyle]="{'color': workSummaryColor}">{{workSummary}}</h1>
                <p>To view work in progress<br><u>click here</u></p>
                </div>
            </a>
        </div>
    </div>
    <div class="overview-summary">
        <div class="overview-child-block overview-child-block-right">
            <a (click)="onItemClick('age-analysis')">
                <div class="overview-child-head" [ngStyle]="{'background-color': agePercentageColor}">
                    Age Analysis
                </div>
                <div class="overview-child-body">
                    <h1 class="overview-title" [ngStyle]="{'color': agePercentageColor}">{{agePercentage}}%</h1>
                    <p>Of your total book ({{outstandingAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}) <br>is <u>overdue.</u></p>
                </div>
            </a>
        </div>
        <div class="overview-child-block">
            <a (click)="onItemClick('report-tax-liability')">
                <div class="overview-child-head action-needed">
                    Tax Liability
                    <i class="far fa-info-circle tooltipp" style="font-size: 18px; float: right; padding-right: 5px;">
                        <div class="bottom" style="text-align:start;">
                            <h3 style="font-size: 1.1rem !important;"><strong>Disclaimer:</strong></h3>
                            <p>The Tax Liability information contained on this site is provided for awareness purposes only, is dependent on your input and does not constitute a formal tax calculation that can be used to complete your tax returns. You should not rely upon the information that has been provided as a basis for making any business, legal or any other decisions</p>
                            <i></i>
                        </div>
                    </i>
                </div>
                <div class="overview-child-body">
                    <p style="margin-top: 8px;"></p>
                    <p *ngIf="isRegisteredforVAT" style="margin: 0px 10px; text-align: left; font-size: 12px;"><b class="action-needed-color">VAT:</b> {{vat | currency:currencySymbol}}</p>
                    <p style="margin: 0px 10px; text-align: left; font-size: 12px;"><b class="action-needed-color">Provisional Tax:</b> {{provisionalTax | currency:currencySymbol}}</p>
                    <p style="margin: 0px 10px; text-align: left; font-size: 12px;"><b class="action-needed-color">Provisional Tax (PTD):</b> {{ptd | currency:currencySymbol}}</p>
                    <p style="text-align: center; margin-top: 6px;">To view your tax liability <u>click here</u></p>
                </div>
            </a>
        </div>
    </div>
</div>