import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { UtilitiesService } from '../../services/utilities.service';
import {
  NotesService,
  NotesObject,
  NotesObjectHandle,
  InstructionResponses,
  DEFAULT_INSTRUCTION_RESPONSES,
  Notes,
  DEFAULT_NOTES_OBJECT,
  NoteCategory,
  MultiInvoiceNote
} from '../../services/notes/notes.service';
import { ToastService } from '../../services/messaging/toast/toast.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { MattersService } from '../../services/matters/matters.service';
import { SnackbarsService } from '../../services/messaging/snackbars/snackbars.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import {
  ActivityLogsService,
  ActivityLogs,
  DEFAULT_ACTIVITY_LOGS
} from '../../services/activity-logs/activity-logs.service';
import { SentMessagesService } from '../../services/sent-messages/sent-messages.service';
import { InvoiceDetailsService, InvoiceDetails, DEFAULT_INVOICE_DETAILS } from '../../services/invoice-details/invoice-details.service';
import { ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
// import { TypeaheadMatch } from 'ngx-bootstrap';
import * as moment from 'moment';
import { Subject, debounceTime, distinctUntilChanged, lastValueFrom } from 'rxjs';

const TAB_INBOX = 'inbox';
const TAB_SENT_ITEMS = 'sent-items';

@Component({
  selector: 'app-practise-manager-messages',
  templateUrl: './practise-manager-messages.component.html',
  styleUrls: ['./practise-manager-messages.component.scss']
})
export class PractiseManagerMessagesComponent implements OnInit {
  title = 'Messages';
  searchTerm = '';
  crumbs: ICrumb[];
  tabs: any = {
    inbox: TAB_INBOX,
    sentItems: TAB_SENT_ITEMS
  };
  activeTab: string = TAB_INBOX;
  outbox: any = [];
  sortBy: string;
  sortAscending: boolean;
  notes: NotesObject[] = [];
  // notesHandle: NotesObjectHandle[] = [];
  sentNotesHandle: NotesObjectHandle[] = [];
  selectedNote: NotesObject;
  openedNote: NotesObjectHandle;
  orderingOptions: string[] = [
    '',
    'date',
    'from',
    'type',
    'reference',
    'subject'
  ];

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  sortNoteDate: boolean;
  sortFrom: boolean;
  sortNoteType: boolean;
  sortReference: boolean;
  sortNoteSubject: boolean;
  orderBy = '';
  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);
  private previousSortBy: string;
  instructionResponses: InstructionResponses[] = [];
  inboxMessageCounter = 0;
  selectedResponseId = 0;
  selectedResponseText: string;
  resolutionText: string;
  instructionText = '';
  selectedResponse: InstructionResponses;

  // Invoice Preview Modal
  showProformaInvoice: boolean;
  invoiceDetails: InvoiceDetails;
  invoicesTransaction: any;
  invoicePlaintiff: string;
  invoiceDefendant: string;
  selectedInvoiceId: number;
  noteCategory: NoteCategory[] = [];
  selectedTab: string;
  selectedNotesHandle: NotesObjectHandle[] = [];
  selectedNotes: NotesObject[] = [];
  lastInstructionNotes: NotesObject

  onShowMultiInvoiceNote: boolean;
  lawfirmName: string;
  multiInvoiceNote: MultiInvoiceNote[] = [];
  noteDetailData: NotesObject;
  multipleObjectNotes: NotesObject[] = [];
  collectorChecked: boolean; // If this is true, we are sending an instruction to a collector
  advocateChecked = true;  // if this is true, we are requesting an instruction to the client.

  currentCount = 0;
  displayTotalCount = 0;
  currentIndex = 0;
  mainInboxData: any = [];
  takeItems = 200;
  searchItems = new Subject<string>()
  displayListCount: 0;

  // Is Practice Manager
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER &&
      Boolean(this.userProfileService.userProfile.serviceProviderID ===
        this.userProfileService.selectedUserProfile.serviceProviderID));
  } // end isPracticeManager()

  get isSaveable(): boolean {
    return Boolean(this.resolutionText);
  } // end isSaveable()

  get isInstructionSaveable(): boolean {
    return Boolean(this.instructionText);
  } // end isSaveable()

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private util: UtilitiesService,
    private notesService: NotesService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private mattersService: MattersService,
    private snackbarService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private sentMessagesService: SentMessagesService,
    private invoiceDetailService: InvoiceDetailsService,
  ) {
    this.sortAscending = false;
  } // end constructor()

  ngOnInit() {
    this.crumbs = [
      {
        label: 'Messages | Inbox'
      }
    ];

    this.getInboxMessageCounter();
    this.sortBy = 'date';
    this.getAllInboxNotes();

    this.searchItems.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.mainInboxData = [];
      this.currentIndex = 0;
      this.currentCount = 0;
      this.getAllInboxNotes();
    });
  } // end ngOnInit()

  setActiveTab(tab: string) {
    this.activeTab = tab;
  } // end setActiveTab()

  // Get all inbox notes for current advocate
  getAllInboxNotes(isRefreshButtonClick: boolean = false) {
    this.notes = [];
    if(isRefreshButtonClick)
      this.mainInboxData = [];
      
    this.loadingService.showOverlay();

    let IndexCount = isRefreshButtonClick ? 0 : (this.currentIndex * this.takeItems);
    let takeSentItems = isRefreshButtonClick ? this.displayListCount : this.takeItems;

    this.notesService.getAllPMNotes(this.userProfileService.userProfile.serviceProviderID, this.searchTerm, IndexCount, takeSentItems).subscribe({
      next: (_data) => {

        this.displayTotalCount = _data.totalCount;

        if (this.displayTotalCount <= this.takeItems)
          this.currentCount = this.displayTotalCount;

        if (_data.data) {
          if (isRefreshButtonClick)
            this.mainInboxData = [];

          _data.data.forEach(element => {
            this.mainInboxData.push({
              data: element,
              state: {
                isVisible: true,
                isOpen: false,
                isSelected: false
              }
            });
          });
          this.displayListCount = this.mainInboxData.length;
        }
      },
      error: (error) => {
      },
      complete: () => {
        this.notes.sort((a, b) => { return b.CaptureDate.toUpperCase().localeCompare(a.CaptureDate.toUpperCase()); });
        this.notes.forEach(x => { this.unShiftData(x); });
        this.loadingService.hideOverlay();
        // this.initSentNotes();
        // this.initNotes();
      }
    });
  } // end getAllInboxNotes()

  LoadMore() {
    this.currentCount = (this.currentIndex + 2) * this.takeItems;
    this.currentIndex = this.currentIndex + 1;
    this.getAllInboxNotes();
  }

  refreshList() {
    this.getAllInboxNotes(true);
  }

  // initNotes() {
  //   this.notesHandle = [];
  //   this.notes.forEach(n => {
  //     if (n.NoteInsertedLevel == 1 && n.UserOwner !== n.UserCreator) {
  //       if (!n.InternalText)
  //         n.InternalText = '-';
  //       if (!n.ObjectTypeDescription)
  //         n.ObjectTypeDescription = '-';
  //       this.notesHandle.push({
  //         data: n,
  //         state: {
  //           isVisible: true,
  //           isOpen: false,
  //           isSelected: false
  //         }
  //       });
  //     }
  //   });
  //   this.notesHandle.sort((a, b) => {
  //     return b.data.CaptureDate.toUpperCase().localeCompare(a.data.CaptureDate.toUpperCase());
  //   });
  //   this.loadingService.hideOverlay();
  // } // end initNotes{}

  // initSentNotes() {
  //   this.sentNotesHandle = [];
  //   this.notes.forEach(n => {
  //     if (n.NoteInsertedLevel == 2) {
  //       this.sentNotesHandle.push({
  //         data: n,
  //         state: {
  //           isVisible: true,
  //           isOpen: false,
  //           isSelected: false
  //         }
  //       });
  //     }
  //   });
  //   this.sentNotesHandle.sort((a, b) => {
  //     return b.data.CaptureDate.toUpperCase().localeCompare(a.data.CaptureDate.toUpperCase());
  //   });
  // } // end initSentNotes()






  filterInboxMessages() {

  } // end filterInboxMessages()

  onNoteResponseSelect() {
    if (this.selectedResponseId) {
      this.selectedResponse = Object.assign({}, DEFAULT_INSTRUCTION_RESPONSES);
      this.selectedResponse = this.instructionResponses.filter(x => x.Id === Number(this.selectedResponseId))[0];
    }
  } // end onNoteResponseSelect()

  performSearch() {
    if (this.searchTerm) {
      this.mainInboxData.forEach(note => {
        if (
          !(note.data.UserAssignedFullName.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(note.data.ObjectTypeDescription.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(note.data.Category.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(note.data.Subject.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(note.data.InternalText.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          note.state.isVisible = false;
        } else {
          note.state.isVisible = true;
        }
      });
    } else {
      this.mainInboxData.forEach(note => {
        note.state.isVisible = true;
      });
    }
  } // end performSearch()

  closeModal() {
    this.showProformaInvoice = false;
  } // end closeModal()

  toggleAscending() {

  } // end toggleAscending()

  toggleSort(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch (this.sortBy) {
      case 'inbox-date':
        this.mainInboxData.sort((a, b) => {
          if (this.sortAscending) {
            this.sortNoteDate = true;
            this.sortFrom = false;
            this.sortNoteType = false;
            this.sortReference = false;
            this.sortNoteSubject = false;
            return a.data.CaptureDate.toUpperCase().localeCompare(b.data.CaptureDate.toUpperCase());
          }
          this.sortNoteDate = false;
          return b.data.CaptureDate.toUpperCase().localeCompare(a.data.CaptureDate.toUpperCase());
        })
        break;

      case 'inbox-from':
        this.mainInboxData.sort((a, b) => {
          if (this.sortAscending) {
            this.sortNoteDate = false;
            this.sortFrom = true;
            this.sortNoteType = false;
            this.sortReference = false;
            this.sortNoteSubject = false;
            return a.data.UserAssignedFullName.toUpperCase().localeCompare(b.data.UserAssignedFullName.toUpperCase());
          }
          this.sortFrom = false;
          return b.data.UserAssignedFullName.toUpperCase().localeCompare(a.data.UserAssignedFullName.toUpperCase());
        })
        break;

      case 'inbox-in-re':
        this.mainInboxData.sort((a, b) => {
          if (this.sortAscending) {
            this.sortNoteDate = false;
            this.sortFrom = false;
            this.sortNoteType = true;
            this.sortReference = false;
            this.sortNoteSubject = false;
            return a.data.ObjectTypeDescription.toUpperCase().localeCompare(b.data.ObjectTypeDescription.toUpperCase());
          }
          this.sortNoteType = false;
          return b.data.ObjectTypeDescription.toUpperCase().localeCompare(a.data.ObjectTypeDescription.toUpperCase());
        })
        break;

      case 'inbox-category':
        this.mainInboxData.sort((a, b) => {
          if (this.sortAscending) {
            this.sortNoteDate = false;
            this.sortFrom = false;
            this.sortNoteType = false;
            this.sortReference = true;
            this.sortNoteSubject = false;
            return a.data.Category.toUpperCase().localeCompare(b.data.Category.toUpperCase());
          }
          this.sortReference = false;
          return b.data.Category.toUpperCase().localeCompare(a.data.Category.toUpperCase());
        })
        break;

      case 'inbox-subcategory':
        this.mainInboxData.sort((a, b) => {
          if (this.sortAscending) {
            this.sortNoteDate = false;
            this.sortFrom = false;
            this.sortNoteType = false;
            this.sortReference = false;
            this.sortNoteSubject = true;
            return a.data.Subject.toUpperCase().localeCompare(b.data.Subject.toUpperCase());
          }
          this.sortNoteSubject = false;
          return b.data.Subject.toUpperCase().localeCompare(a.data.Subject.toUpperCase());
        })
        break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

  orderMessages() {

  } // end orderMessages()

  getUnReadMessageFont(value: boolean): string {
    if (value === false) {
      return 'Bold';
    }
  } // #end getUnReadMessageFont()

  async onInvoicePreview(data: NotesObject) {
    this.loadingService.showOverlay();
    // console.log('INVOICE TYPE: ', data, data.ObjectTypeDescription, data.ObjectTypeDescription === 'Multiple Invoices' );
    if (!data.MutliObjects && data.ObjectTypeDescription !== 'Multiple Invoices') {
      this.selectedTab = 'invoice';
      const invoiceId: number = data.ObjectId;
      this.selectedNotes = [];
      this.selectedNotesHandle = [];
      if (invoiceId) {
        let _note = [];
        if (this.isPracticeManager) {
          // _note =  
          this.notesService.getNotes(data.UserAssigned, invoiceId, 4).subscribe({
            next: (_data) => {
              // On next
              _note = _data;
            },
            complete: () => {
              this.selectedNotes = _note;
              this.selectedNotes.forEach(_notes => {
                this.selectedNotesHandle.push({
                  data: _notes,
                  state: {
                    isVisible: true,
                    isSelected: false,
                    isOpen: false
                  }
                });
              });
            }
          });
        } else if (!this.isPracticeManager) {
          // _note =  
          this.notesService.getNotes(data.UserAssigned, invoiceId, 4).subscribe({
            next: (_data) => {
              // On next
              _note = _data;
              this.selectedNotes = _note.filter(x => x.QuestionText);
              this.selectedNotes.forEach(_notes => {
                this.selectedNotesHandle.push({
                  data: _notes,
                  state: {
                    isVisible: true,
                    isSelected: false,
                    isOpen: false
                  }
                });
              });
            }
          });
        }

        this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
        // this.showProformaInvoice = true;
        await lastValueFrom(this.invoiceDetailService.getInvoiceDetails(invoiceId)).then((_data) => {
          // On next
          this.invoiceDetails = _data;
          this.selectedInvoiceId = invoiceId;
          if (this.invoiceDetails) {
            const desc = this.invoiceDetails.InvoiceParties.split(' // ');
            if (desc.length > 0) {
              this.invoicePlaintiff = desc[0];
            }
            if (desc.length > 1) {
              this.invoiceDefendant = desc[1];
            }
          }
        });
      }
      this.showProformaInvoice = true;
      this.loadingService.hideOverlay();
    } else if (data.MutliObjects && data.ObjectTypeDescription.includes('Multiple Invoices')) {

      this.multiInvoiceNote = [];
      this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
      this.noteDetailData = data;
      this.lawfirmName = data.NoteAbout;
      this.multiInvoiceNote = [];
      this.notesService.getNotesObjects(data.MutliObjects).subscribe({
        next: (_data) => {
          // On next
          const _multiObject = _data;
          this.multiInvoiceNote = _multiObject.filter(x => x.PracticeManagerStatus === 'Active');
          this.onShowMultiInvoiceNote = true;
          this.loadingService.hideOverlay();
        }
      });
    }
  } // end onInvoicePreview()

  onShowMultiInvoiceNoteModalDismiss() {
    this.onShowMultiInvoiceNote = false;
  } // end onShowMultiInvoiceNoteModalDismiss()

  unShiftData(data: NotesObject) {
    const _data = this.notes.filter(x => x.ObjectId === data.ObjectId && x.Category === data.Category
      && x.Subject === data.Subject);
    _data.sort((a, b) => {
      return b.CaptureDate.toUpperCase().localeCompare(b.CaptureDate.toUpperCase());
    });

    if (data === _data[_data.length - 1]) {
      const activityDate = moment(data.ActionDate).format('YYYY-MM-DD');
      const today = moment().format('YYYY-MM-DD');
      if (data.QuestionId > 0 && data.InstructionResponseId === 0
        && new Date(activityDate) <= new Date(today)
      ) {
        this.notes = this.notes.filter(x => x !== data);
        this.notes.unshift(data);
      }
    }
  } // end unShiftData()

  getActionReminder(data: NotesObject): boolean {
    let hasRemider = false;
    const _data = this.mainInboxData.filter(x => x.data.ObjectId === data.ObjectId && x.data.Category === data.Category
      && x.data.Subject === data.Subject);
    _data.sort((a, b) => {
      return b.data.CaptureDate.toUpperCase().localeCompare(b.data.CaptureDate.toUpperCase());
    });

    if (data === _data[_data.length - 1].data) {
      const activityDate = moment(data.ActionDate).format('YYYY-MM-DD');
      const today = moment().format('YYYY-MM-DD');
      if (data.QuestionId > 0 && data.InstructionResponseId === 0
        && new Date(activityDate) <= new Date(today)
      ) {
        hasRemider = true;
      }
    }
    return hasRemider;
  } // end getActionReminder()

  getSentItemsActionReminder(data: NotesObject): boolean {
    let hasRemider = false;
    const _data = this.sentNotesHandle.filter(x => x.data.ObjectId === data.ObjectId && x.data.Category === data.Category
      && x.data.Subject === data.Subject);
    _data.sort((a, b) => {
      return b.data.CaptureDate.toUpperCase().localeCompare(b.data.CaptureDate.toUpperCase());
    });

    if (data === _data[_data.length - 1].data) {
      const activityDate = moment(data.ActionDate).format('YYYY-MM-DD');
      const today = moment().format('YYYY-MM-DD');
      if (data.QuestionId > 0 && data.InstructionResponseId === 0
        && new Date(activityDate) <= new Date(today)
      ) {
        hasRemider = true;
      }
    }
    return hasRemider;
  } // end getSentItemsActionReminder()

  onMessageRowClick(data: NotesObject) {
    this.instructionResponses = [];
    this.loadingService.showOverlay();
    this.selectedNote = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.selectedNote = data;

    if (data.UserAssignedStatus === false) {
      data.UserAssignedStatus = true;
      this.notesService.putNoteInboxStatus(data).subscribe({ next: (_put) => { } });
      this.notes.forEach(_note => {
        if (_note === data) {
          _note.UserAssignedStatus = true;
        }
      });
    }
    this.notesService.getPMInstructionResponses().subscribe({
      next:
        (instruction) => {
          // On next
          this.instructionResponses = instruction;
        },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.getInboxMessageCounter();
        this.loadingService.hideOverlay();
        // this.showAdvocateNoteDetail = true;
      }
    });
  } // end onMessageRowClick()

  onPMNoteDismiss(notes: NotesObjectHandle) {
    this.loadingService.showOverlay();
    this.notesService.putPMNoteDismiss(notes.data.Id).subscribe({
      next: (_data) => {
        // On next
        const _note = _data;
        this.mainInboxData = this.mainInboxData.filter(a => a.data.Id != _note.Id);

      }
    });
    this.loadingService.hideOverlay();
  } // onPMNoteDismiss()

  onRowClickExpand(notes: NotesObjectHandle) {
    this.selectedNote = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.selectedResponseId = 0;
    this.selectedNote = notes.data;
    notes.state.isOpen = !notes.state.isOpen;

    this.selectedResponseText = '';
    this.selectedResponse = Object.assign({}, DEFAULT_INSTRUCTION_RESPONSES);
    this.openedNote = notes;
    this.instructionResponses = [];
    this.loadingService.showOverlay();
    this.lastInstructionNotes = Object.assign({}, DEFAULT_NOTES_OBJECT);

    // Get last instruction to be inserted.
    if (notes.state.isOpen === true) {
      this.notesService.getLastInstructionNote(notes.data.UserCreator, notes.data.UserAssigned, notes.data.ObjectId, notes.data.Id).subscribe({
        next: (_data) => {
          this.lastInstructionNotes = _data;
        }
      });

      if (notes.data.ObjectTypeDescription === 'Multiple Invoices') {
        this.getMultiObjectNotes(notes.data.UserAssigned, notes.data.MutliObjects);
      }

      if (notes.data.InstructionResponseId === 0) { // notes.data.QuestionId > 0 &&
        this.notesService.getPMInstructionResponses().subscribe({
          next: (_data) => {
            // On next
            this.instructionResponses = _data;
          }
        });
      }

      if (notes.data.UserCreatorStatus === false) {
        if (notes.data.ObjectTypeDescription === 'Multiple Invoices') {
          if (this.multipleObjectNotes) {
            this.multipleObjectNotes.forEach(_data => {
              _data.UserCreatorStatus = true;
              this.notesService.putNoteInboxStatus(_data).subscribe({ next: (_next) => { } });
            });
          }
          notes.data.UserCreatorStatus = true;
        } else {
          notes.data.UserCreatorStatus = true;
          this.notesService.putNoteInboxStatus(notes.data).subscribe({ next: (_next) => { } });
          this.notes.forEach(_note => {
            if (_note === notes.data) {
              _note.UserCreatorStatus = true;
            }
          });
        }
      }
    }

    this.mainInboxData.forEach(s => {
      if (s.state.isOpen && notes !== s) {
        s.state.isOpen = false;
      }
    });
    // this.notesService.getInstructionResponses(this.selectedNote.QuestionId).subscribe(
    //   instruction => {
    //     // On next
    //     this.instructionResponses = instruction;
    //   },
    //   error => {
    //     // On error
    //     this.loadingService.hideOverlay();
    //   },
    //   () => {
    //     // On complete
    //     this.getInboxMessageCounter();
    this.loadingService.hideOverlay();
    //   });
  } // end onRowClickExpand()

  getMultiObjectNotes(advocateId: number, multiObjectId: number) {
    this.multipleObjectNotes = [];
    this.notesService.getMultipleObjectNotes(advocateId, multiObjectId).subscribe({
      next: (_data) => {
        // On next
        this.multipleObjectNotes = _data;
      }
    });
  } // end getMultiObjectNotes()

  onSentRowClickExpand(notes: NotesObjectHandle) {
    // this.openedNote = notes;
    // this.selectedNote = Object.assign({}, DEFAULT_NOTES_OBJECT);
    // this.selectedNote = notes.data;
    notes.state.isOpen = !notes.state.isOpen;
    // if (notes.data.UserOwnerStatus === false) {
    //   notes.data.UserOwnerStatus = true;
    //    this.notesService.putNoteInboxStatus(notes.data).toPromise();
    //   this.notes.forEach(_note => {
    //     if (_note === notes.data) {
    //       _note.UserOwnerStatus = true;
    //     }
    //   });
    // }
    this.sentNotesHandle.forEach(s => {
      if (s.state.isOpen && notes !== s) {
        s.state.isOpen = false;
      }
    });
    // this.notesService.getInstructionResponses(this.selectedNote.QuestionId).subscribe(
    //   instruction => {
    //     // On next
    //     this.instructionResponses = instruction;
    //   },
    //   error => {
    //     // On error
    //     this.loadingService.hideOverlay();
    //   },
    //   () => {
    //     // On complete
    //     this.getInboxMessageCounter();
    // this.loadingService.hideOverlay();
    //   });
  } // end onSentRowClickExpand()

  onResolutionSave() {
    this.loadingService.showOverlay();
    if (this.selectedNote.MutliObjects) {
      this.multipleObjectNotes.forEach(data => {
        const noteToSave: Notes = {
          Id: data.Id,
          ObjectId: data.ObjectId,
          Subject: this.selectedNote.Subject,
          NoteTypeId: this.selectedNote.NoteTypeId,
          InternalText: this.selectedNote.InternalText,
          ExternalText: this.selectedNote.ExternalText,
          UserOwner: this.selectedNote.UserOwner,
          UserCreator: this.selectedNote.UserCreator,
          UserAssigned: this.selectedNote.UserAssigned,
          Priority: this.selectedNote.Priority,
          CaptureDate: this.selectedNote.CaptureDate,
          ActionDate: this.selectedNote.ActionDate,
          ActivityDate: this.selectedNote.ActivityDate,
          InstructionResponseId: this.selectedNote.InstructionResponseId,
          InstructionText: this.selectedNote.InstructionText,
          ResolutionText: this.resolutionText,
          InternalInstructionNote: this.selectedNote.InternalInstructionNote,
          MutliObjects: data.MutliObjects,
          InstructionCaptureDate: data.InstructionCaptureDate,
          ResolutionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          IsVisibleUserOwner: this.selectedNote.IsVisibleUserOwner,
          IsVisibleUserCreator: this.selectedNote.IsVisibleUserCreator,
          IsVisibleUserAssigned: this.collectorChecked === true ? false : true
        };
        this.selectedNote.UserAssignedStatus = false;
        this.notesService.putNoteInboxStatus(this.selectedNote).subscribe({ next: (_next) => { } });
        this.notesService.putNote(noteToSave).subscribe({ next: (_next) => { } });
      });
    } else {
      const noteToSave: Notes = {
        Id: this.selectedNote.Id,
        ObjectId: this.selectedNote.ObjectId,
        Subject: this.selectedNote.Subject,
        NoteTypeId: this.selectedNote.NoteTypeId,
        InternalText: this.selectedNote.InternalText,
        ExternalText: this.selectedNote.ExternalText,
        UserOwner: this.selectedNote.UserOwner,
        UserCreator: this.selectedNote.UserCreator,
        UserAssigned: this.selectedNote.UserAssigned,
        Priority: this.selectedNote.Priority,
        CaptureDate: this.selectedNote.CaptureDate,
        ActionDate: this.selectedNote.ActionDate,
        ActivityDate: this.selectedNote.ActivityDate,
        InstructionResponseId: this.selectedNote.InstructionResponseId,
        InstructionText: this.selectedNote.InstructionText,
        ResolutionText: this.resolutionText,
        InternalInstructionNote: this.selectedNote.InternalInstructionNote,
        MutliObjects: this.selectedNote.MutliObjects,
        InstructionCaptureDate: this.selectedNote.InstructionCaptureDate,
        ResolutionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        IsVisibleUserOwner: this.selectedNote.IsVisibleUserOwner,
        IsVisibleUserCreator: this.selectedNote.IsVisibleUserCreator,
        IsVisibleUserAssigned: this.collectorChecked === true ? false : true
      };
      this.selectedNote.UserAssignedStatus = false;
      this.notesService.putNoteInboxStatus(this.selectedNote).subscribe({ next: (_next) => { } });
      this.notesService.putNote(noteToSave).subscribe({ next: (_next) => { } });
    }
    this.getAllInboxNotes();
    this.resolutionText = '';
    this.loadingService.hideOverlay();
  } // end onResolutionSave()

  toggleColAdvCheckbox(value: string) {
    switch (value) {
      case 'collector':
        this.advocateChecked = false;
        this.collectorChecked = true;
        break;

      case 'advocate':
        this.advocateChecked = true;
        this.collectorChecked = false;
        break;
    }
  } // end toggleColAdvCheckbox()

  onInstructionSave() {
    this.loadingService.showOverlay();

    if (this.selectedNote && this.selectedNote.ObjectTypeDescription !== 'Multiple Invoices') {
      const noteToSave: Notes = {
        Id: this.selectedNote.Id,
        ObjectId: this.selectedNote.ObjectId,
        Subject: this.selectedNote.Subject,
        NoteTypeId: this.selectedNote.NoteTypeId,
        InternalText: this.selectedNote.InternalText,
        ExternalText: this.collectorChecked === true ?
          this.selectedNote.ExternalText : this.instructionText,
        UserOwner: this.selectedNote.UserOwner,
        UserCreator: this.selectedNote.UserCreator,
        UserAssigned: this.selectedNote.UserAssigned,
        Priority: this.selectedNote.Priority,
        CaptureDate: this.selectedNote.CaptureDate,
        ActionDate: this.selectedNote.ActionDate,
        ActivityDate: this.selectedNote.ActivityDate,
        InstructionResponseId: this.collectorChecked === true ? this.selectedResponse.Id : 0,
        InstructionText: this.collectorChecked === true ? this.instructionText : '',
        ResolutionText: '',
        InternalInstructionNote: this.selectedNote.InternalInstructionNote,
        MutliObjects: this.selectedNote.MutliObjects,
        InstructionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        ResolutionCaptureDate: null,
        IsVisibleUserOwner: this.collectorChecked === true ? true : false,
        IsVisibleUserCreator: true,
        IsVisibleUserAssigned: this.collectorChecked === true ? false : true

      };
      this.selectedNote.UserOwnerStatus = this.collectorChecked === true ? false : true;
      this.selectedNote.UserCreatorStatus = true;
      this.selectedNote.UserAssignedStatus = this.collectorChecked === true ? true : false;
      this.notesService.putNoteInboxStatus(this.selectedNote).subscribe({ next: (_next) => { } });
      this.notesService.putNote(noteToSave).subscribe({
        next: (updatedNote) => {
          // On next
        },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.instructionText = '';
          this.selectedResponseId = 0;
          this.selectedResponse = Object.assign({}, DEFAULT_INSTRUCTION_RESPONSES);
          this.getAllInboxNotes(true);
          // this.loadingService.hideOverlay();
        }
      });
    } else if (this.selectedNote && this.selectedNote.ObjectTypeDescription === 'Multiple Invoices') {
      // if (this.multipleObjectNotes) {
      this.multipleObjectNotes.forEach(_data => {
        this.selectedNote.Id = _data.Id;
        this.selectedNote.ObjectId = _data.ObjectId;
        this.selectedNote.Subject = _data.Subject;
        this.selectedNote.NoteTypeId = _data.NoteTypeId;
        this.selectedNote.UserCreatorStatus = true;
        this.selectedNote.UserOwnerStatus = this.collectorChecked === true ? false : true;
        this.selectedNote.UserAssignedStatus = this.collectorChecked === true ? true : false;

        const noteToSave: Notes = {
          Id: _data.Id,
          ObjectId: _data.ObjectId,
          Subject: _data.Subject,
          NoteTypeId: _data.NoteTypeId,
          InternalText: this.selectedNote.InternalText,
          ExternalText: this.collectorChecked === true ?
            this.selectedNote.ExternalText : this.instructionText,
          UserOwner: this.selectedNote.UserOwner,
          UserCreator: this.selectedNote.UserCreator,
          UserAssigned: this.selectedNote.UserAssigned,
          Priority: this.selectedNote.Priority,
          CaptureDate: this.selectedNote.CaptureDate,
          ActionDate: this.selectedNote.ActionDate,
          ActivityDate: this.selectedNote.ActivityDate,
          InstructionResponseId: this.collectorChecked === true ? this.selectedResponse.Id : 0,
          InstructionText: this.collectorChecked === true ? this.instructionText : '',
          ResolutionText: '',
          InternalInstructionNote: this.selectedNote.InternalInstructionNote,
          MutliObjects: _data.MutliObjects,
          InstructionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          ResolutionCaptureDate: null,
          IsVisibleUserOwner: this.collectorChecked === true ? true : false,
          IsVisibleUserCreator: false,
          IsVisibleUserAssigned: this.collectorChecked === true ? false : true
        };

        this.selectedNote.UserOwnerStatus = this.collectorChecked === true ? false : true;
        this.selectedNote.UserCreatorStatus = true;
        this.selectedNote.UserAssignedStatus = this.collectorChecked === true ? true : false;

        this.notesService.putNoteInboxStatus(this.selectedNote).subscribe({ next: (_next) => { } });
        this.notesService.putNote(noteToSave).subscribe({ next: (_next) => { } });
      });
      this.instructionText = '';
      this.selectedResponseId = 0;
      this.selectedResponse = Object.assign({}, DEFAULT_INSTRUCTION_RESPONSES);
      this.getAllInboxNotes(true);
      // this.loadingService.hideOverlay();
      // }
    }
  } // end onInstructionSave()

  onMultiInvoiceNoteUpdat() {
    this.getAllInboxNotes();
    this.onShowMultiInvoiceNote = false;
  } // end onMultiInvoiceNoteUpdat()

  getInboxMessageCounter() {
    if (this.isPracticeManager) {
      this.notesService.getPMUnreadMessagesCounter(this.userProfileService.userProfile.serviceProviderID).subscribe({
        next: (_data) => {
          // On next
          this.inboxMessageCounter = _data;
        }
      });
    }
  } // end getInboxMessageCounter()
} // end PractiseManagerMessagesComponent{}
