import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CONTACT_TYPE_ID_LAWFIRM } from '../appdata/app-config.service';

export interface Contact {
  ContactID: number;
  FullName: string;
  ContactTypeID: number;
  City?: string;
  State?: string;
  FullDescription: string;
} // end Conctact{}

export interface RegionalBar {
  RegionalBarId : number;
  RegionalBarName : string;
}

export interface Contacts {
  AccountHolder: string;
  AccountNumber: string;
  AccountType: string;
  BranchCode: string;
  Cell: string;
  CollectThreshold: string;
  ContactContactType: number;
  Locality: string
  ContactLocality: number;
  DefaultDailyRate: number;
  DefaultHourlyRate: number;
  DefaultPerKilometerRate: number;
  Docex: string;
  Email: string;
  Fax: string;
  FeeTarget: number;
  FullName : string;
  Id: number;
  Identity: string;
  Image: string;
  IncomeTarget: number;
  LastStatementDownload: string;
  Level: string;
  OfficeRoom: string;
  Phone: string;
  PostalAddress: string;
  SecurityLinks: string;
  Speciality: string;
  Status: string;
  StreetAddress: string;
  Suburb: string;
  ToCollect: number;
  UserName: string;
  Vatno: string;
  WebSite: string;
  ZipCode: string;
  IsSelected: boolean;
  AdmissionDay : number;
  AdmissionMonth : number;
  AdmissionYear : number;
  AdmissionDate : string;
  AdmissionDateStr : string;
  RegionalBarId : number;
  RegionalBarName : string;
}

export const DEFAULT_CONTACTS: Contacts = {
  AccountHolder: '',
  AccountNumber: '',
  AccountType: '',
  BranchCode: '',
  Cell: '',
  CollectThreshold: '',
  ContactContactType: 0,
  Locality: '',
  ContactLocality: 0,
  DefaultDailyRate: 0,
  DefaultHourlyRate: 0,
  DefaultPerKilometerRate: 0,
  Docex: '',
  Email: '',
  Fax: '',
  FeeTarget: 0,
  FullName : '',
  Id: 0,
  Identity: '',
  Image: '',
  IncomeTarget: 0,
  LastStatementDownload: '',
  Level: '',
  OfficeRoom: '',
  Phone: '',
  PostalAddress: '',
  SecurityLinks: '',
  Speciality: '',
  Status: '',
  StreetAddress: '',
  Suburb: '',
  ToCollect: 0,
  UserName: '',
  Vatno: '',
  WebSite: '',
  ZipCode: '',
  IsSelected: false,
  AdmissionDay : null,
  AdmissionMonth : null,
  AdmissionYear : null,
  AdmissionDate : null,
  AdmissionDateStr : '',
  RegionalBarId : 0,
  RegionalBarName : '',
}

export interface RegionalBarHandle {
  data: RegionalBar;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  }
}
export const DEFAULT_REGIONALBAR: RegionalBar = {
  RegionalBarId : 0,
  RegionalBarName : '',
};
export interface ContactsHandle {
  data: Contacts;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  }
}

export interface ContactsPagination {
  TotalCount: number;
  PageSize: number;
  CurrentPage: number;
  TotalPages: number;
  HasNext: boolean;
  HasPrevious: boolean;
  results: Contacts[];
}

export const DEFAULT_CONTACTS_PAGINATION: ContactsPagination = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 0,
  HasNext: false,
  HasPrevious: false,
  results: [] = []
}

export interface ContactLocalities {
  Id: number;
  City: string;
  Country: string;
  Description: string;
  State: string;
};

export const DEFAULT_CONTACT_LOCALITIES: ContactLocalities = {
  Id: 0,
  City: '',
  Country: '',
  Description: '',
  State: ''
};

export interface CollectionsDailyReportView {
  LawfirmId: number;
  Lawfirm: string;
  TotalAmount: number;
  AdvocateCount: number;
  InvoiceCount: number;
  CollectorId: number;
  CollectionsDate: string;
  Collector: string;
  ObjectName: string;
} // end CollectionsDailyReportView()

export interface CollectionsDailyReportSummary {
  CollectorId: number;
  Collector: string;
  AssignedFiles: number;
  DiarisedFiles: number;
  RecentInstructionsFiles: number;
  TotalFiles: number;
  AssignedNotWorkedFiles: number;
  DiarisedNotWorkedFiles: number;
  RecentInstructionNotWorkedFiles: number;
  TotalFilesNotWorked: number;
} // end CollectionsDailyReportSummary()

export interface CollectionsDailyReportDetail {
  LawfirmId: number;
  Lawfirm: string;
  CollectorId: number;
  TotalAmount: number;
  AdvocateCount: number;
  InvoiceCount: number;
  AssignedDate: string;
  LastWorkedDate: string;
  Status: string;
} // end CollectionsDailyReportDetail()

export interface CollectionsDailyReportDetailHandle {
  data: CollectionsDailyReportDetail;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

@Injectable()
export class ContactsService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  getContact(id: number): Observable<any> {
    return this.http.get(this.api.endpoints.contacts +
      '/' + id, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getContact()

  getContacts(contactTypeID: number): Observable<any> {
    return this.http.get(this.api.endpoints.contacts +
      '?ContactTypeID=' + contactTypeID +
      '&ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getContacts()

  getLawFirms(): Observable<any> {
    return this.http.get(this.api.endpoints.contacts +
      '?ContactTypeID=' + CONTACT_TYPE_ID_LAWFIRM +
      '&ServiceProviderID=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getLawFirms()

  getLawFirmsByServiceProvider(): Observable<any> {
    return this.http.get(this.api.endpoints.lawFirm +
      '?ServiceProviderID=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getLawFirmsByServiceProvider()

  getLawFirmsByPMServiceProvider(practiceManagerId: number): Observable<any> {
    return this.http.get(this.api.endpoints.lawFirm +
      '?ServiceProviderID=' + this.api.serviceProviderID +
      '&PracticeManagerId=' + practiceManagerId, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getLawFirmsByPMServiceProvider()

  getCollectionsReport(collectionStartDate: string, collectionEndDate: string): Observable<any> {
    return this.http.get(this.api.endpoints.collectionsAdmin +
      '/GetCollectionsDailyReportSummary?collectionStartDate=' + collectionStartDate + '&collectionEndDate=' + collectionEndDate,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getCollectionsReport()

  getCollectionsReportDetail(collectorId: number, collectionStartDate: string, collectionEndDate: string): Observable<any> {
    return this.http.get(this.api.endpoints.collectionsAdmin +
      '/GetCollectionsDailyReportDetail?collectorId=' + collectorId + '&collectionStartDate=' + collectionStartDate + '&collectionEndDate=' + collectionEndDate,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getCollectionsReportDetail()

  getAllLawFirmsByServiceProvider(): Observable<any> {
    return this.http.get(this.api.endpoints.lawFirm +
      '?ServiceProviderID=' + this.api.serviceProviderID +
      '&returnAll=false'
      , this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getAllLawFirmsByServiceProvider()

  // Edit Advocate - orig
  getAllContactsByContactType(contactTypeId: number): Observable<any> {
    return this.http.get(this.api.endpoints.contactManager +
      '/AllDataByType?TypeId=' + contactTypeId ,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getAllContactsByContactType()

  onDeleteAdvocate(Id: number, regionalBarId : number) {
    return this.http.delete<any>(this.api.endpoints.contactManager +
      '/RemoveAdvocateFromRegionalbar?Id=' + Id + '&RegionalBarId=' + regionalBarId,
      this.api.httpOptions)
      .pipe(
      catchError(this.api.handleError)
    );
  }
  
  getAllRegionalBar(): Observable<any> {
    return this.http.get(this.api.endpoints.contactManager +
      '/GetSocityBarList' ,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getAllContactsByContactType()

  getAttorneyByLawfirm(lawfirmId: number): Observable<any> {
    return this.http.get(this.api.endpoints.contactManager +
      '/AttorneyInTheFirm?LawfirmId=' + lawfirmId +
      '&ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getAttorneyByLawfirmId()

  getContactByPhone(phoneNumber: string): Observable<any> {
    return this.http.get(this.api.endpoints.contactManager +
      '/GetContactByPhone?searchString=' + phoneNumber + '&ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getContactByPhone()

  getLocalities(): Observable<any> {
    return this.http.get(this.api.endpoints.contactManager +
      '/GetLocalities?ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getLocalities()

  getAdvocateBySecretary(secretaryId: number): Observable<any> {
    return this.http.get(this.api.endpoints.contactManager +
      '/GetAdvocateForSecretary?secretaryId=' + secretaryId,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getAdvocateBySecretary()

  postNewAttorneyInFirm(attorneyData: Contacts, lawfirmId: number): Observable<any> {
    const contact = {
      id: 0,
      fullName:  attorneyData.FullName,
      cell: attorneyData.Cell,
      phone: attorneyData.Phone,
      email: attorneyData.Email,
      status: attorneyData.Status,
      fax: attorneyData.Fax
    };
    return this.http.post<any>(
      this.api.endpoints.contactManager +
      '/PostNewAttorney?LawfirmId=' + lawfirmId, contact,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end postNewAttorneyInFirm()

  postNewFirm(newFirmData: Contacts): Observable<any> {
    const contact = {
      id: 0,
      fullName:  newFirmData.FullName,
      contactLocality: newFirmData.ContactLocality,
      phone: newFirmData.Phone,
      email: newFirmData.Email,
      cell: newFirmData.Cell,
      fax: newFirmData.Fax,
      vatno: newFirmData.Vatno,
      postalAddress: newFirmData.PostalAddress,
      streetAddress: newFirmData.StreetAddress,
      contactContactType: newFirmData.ContactContactType,
      status: newFirmData.Status,
    };
    return this.http.post<any>(
      this.api.endpoints.contactManager +
      '/PostNewLawFirm', contact,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end postNewFirm()

  putFirm(firm: Contacts): Observable<any> {
    const contact = {
      id: firm.Id,
      fullName:  firm.FullName,
      contactLocality: firm.ContactLocality,
      phone: firm.Phone,
      email: firm.Email,
      cell: firm.Cell,
      fax: firm.Fax,
      vatno: firm.Vatno,
      postalAddress: firm.PostalAddress,
      streetAddress: firm.StreetAddress,
      contactContactType: firm.ContactContactType,
      status: firm.Status,
    };
    return this.http.put<any>(
      this.api.endpoints.contactManager +
      '/PutLawFirm', contact,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end putFirm()

  PutAttorneyInFirm(attorneyData: Contacts): Observable<any> {
    const contact = {
      id: attorneyData.Id,
      fullName:  attorneyData.FullName,
      cell: attorneyData.Cell,
      phone: attorneyData.Phone,
      email: attorneyData.Email,
      status: attorneyData.Status,
      fax: attorneyData.Fax
    };
    return this.http.put<any>(
      this.api.endpoints.contactManager +
      '/PutAttorney', contact,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end postNewAttorneyInFirm()

   //add new Advocate - orig
  postNewAdvocate(newAdvocate: Contacts): Observable<any> {
    const contact = {
      id: 0,
      fullName:  newAdvocate.FullName,
      contactLocality: newAdvocate.ContactLocality,
      phone: newAdvocate.Phone,
      email: newAdvocate.Email,
      cell: newAdvocate.Cell,
      fax: newAdvocate.Fax,
      vatno: newAdvocate.Vatno,
      postalAddress: newAdvocate.PostalAddress,
      streetAddress: newAdvocate.StreetAddress,
      contactContactType: newAdvocate.ContactContactType,
      status: newAdvocate.Status,
      admissionDay : newAdvocate.AdmissionDay,
      admissionMonth : newAdvocate.AdmissionMonth,
      admissionYear : newAdvocate.AdmissionYear,
      admissionDate : newAdvocate.AdmissionDateStr,
      regionalbarId : newAdvocate.RegionalBarId,
      regionalBarName : newAdvocate.RegionalBarName,
    };
    return this.http.post<any>(
      this.api.endpoints.contactManager +
      '/PostNewAdvocate' , contact,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end postNewAdvocate()

  putActivateUser(contactId: number): Observable<any> {
    return this.http.put<any>(
      this.api.endpoints.contactManager +
      '/PutActivateUser?contactId=' + contactId,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end postNewAttorneyInFirm()

  putAssignAdvocateToAssistant(assistantId: number, advocateId: number, permissionId: number): Observable<any> {
    return this.http.put<any>(
      this.api.endpoints.contactManager +
      '/PutAssignAdvocateToSecretary?assistantId=' + assistantId + '&advocateId=' + advocateId + '&permissionId=' + permissionId,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end putAssignAdvocateToAssistant()

  //update Advocate details - orig
  PutContact(contactData: Contacts): Observable<any> {
    const contact = {
      id: contactData.Id,
      fullName:  contactData.FullName,
      identity: contactData.Identity,
      level: contactData.Level,
      cell: contactData.Cell,
      phone: contactData.Phone,
      email: contactData.Email,
      fax: contactData.Fax,
      vatno: contactData.Vatno,
      officeRoom: contactData.OfficeRoom,
      streetAddress: contactData.StreetAddress,
      suburb: contactData.Suburb,
      admissionDay : contactData.AdmissionDay,
      admissionMonth : contactData.AdmissionMonth,
      admissionYear : contactData.AdmissionYear,
      admissionDate : contactData.AdmissionDateStr,
      regionalbarId : contactData.RegionalBarId,
      regionalBarName : contactData.RegionalBarName,
    };
    return this.http.put<any>(
      this.api.endpoints.contactManager +
      '/PutAdvocate', contact,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end postNewContact()

  deleteAdvocateToAssistant(assistantId: number, advocateId: number): Observable<any> {
    return this.http.put<any>(
      this.api.endpoints.contactManager +
      '/DeleteAssignAdvocateToSecretary?assistantId=' + assistantId + '&advocateId=' + advocateId,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end deleteAdvocateToAssistant()

  mergeLawfirms(oldId: number, newId: number): Observable<any> {
    return this.http.put<any> (this.api.endpoints.contactManager +
      '/MergeLawfirms?IdToBeRemoved=' + oldId + '&NewLawfirmId=' + newId,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end mergeLawfirms()

  getLawfirmByPage(pageNumber: number, pageSize: number, typeId: number): Observable<any> {
    return this.http.get(this.api.endpoints.contactManager +
      '/GetContactPegination?PageNumber=' + pageNumber + 
      '&PageSize=' + pageSize + '&typeId=' + typeId,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end getLawfirmByPage()

  searchForLawfirm(searchText: string): Observable<any> {
    return this.http.get(this.api.endpoints.contactManager +
      '/SearchLawfirm?searchString=' + searchText + 
      '&ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end searchForLawfirm()
} // end ContactsService{}
