<div class="container-fluid" *ngIf="!isOnLoading">
  <h1 class="page-title">ADD NEW | MATTER & FEES</h1>
</div>

<app-matter-details *ngIf="!isOnLoading && activeScreen === 'current-matters'" [isLoading]="matterDetailsLoading"
  [(workingMatter)]="workingMatter" [_matters]="_matters" [matter]="matter" [lawFirms]="lawFirms"
  [attorneys]="attorneys" [plaintiffs]="plaintiffs" [otherClients]="otherClients" [defendants]="defendants"
  [attorneyRefs]="attorneyRefs" [instructions]="instructions" [userProfile]="userProfile"></app-matter-details>

<app-historical-details *ngIf="!isOnLoading && activeScreen === 'take-on-data'"
  [isLoading]="historicalDataDetailsLoading" [(workingHistorical)]="workingHistorical" [historical]="historical"
  [lawFirms]="lawFirms" [attorneys]="attorneys" [plaintiffs]="plaintiffs" [otherClients]="otherClients"
  [defendants]="defendants" [attorneyRefs]="attorneyRefs" [instructions]="instructions"
  [userProfile]="userProfile"></app-historical-details>

<!--  -->
<app-matter-fees-table *ngIf="!isOnLoading && activeScreen === 'current-matters'" (feeDelete)="onFeeDelete($event)"
  [userProfile]="userProfile" [lawFirms]="lawFirms" [subCategories]="subCategories" [(workingFees)]="workingFees" [(fees)]="fees"
  [documentTypes]="documentTypes" [briefDocuments]="briefDocuments" [quotationFees]="quotationFees"
  [(workingMatter)]="workingMatter" (onArbitrationSplitUpdate)="ngOnInit()"></app-matter-fees-table>

<app-historical-fee-table *ngIf="!isOnLoading && activeScreen === 'take-on-data'"
  (feeDelete)="onHistoricalFeeDelete($event)" [userProfile]="userProfile" [subCategories]="subCategories"
  [(workingHistorical)]="workingHistorical"
  [(workingHistoricalFees)]="workingHistoricalFees"></app-historical-fee-table>

<app-delete-matter-modal [matter]="workingMatter" [isVisible]="showDeleteMatterModal"
  (closeModal)="closeModal('delete-matter-modal')" (confirmDelete)="deleteMatter($event)"></app-delete-matter-modal>

<app-delete-matter-modal [matter]="workingHistorical" [isVisible]="showDeleteHistoricalModal"
  (closeModal)="closeModal('delete-historical-modal')"
  (confirmDelete)="deleteHistorical($event)"></app-delete-matter-modal>

<div class="row matter-actions" *ngIf="!isOnLoading">
  <button *ngIf="activeScreen === 'current-matters'" class="btn btn-danger" [class.disabled]="!isDeletable"
    [disabled]="!isDeletable" (click)="promptDelete()">Delete this matter</button>

  <button *ngIf="activeScreen === 'take-on-data'" class="btn btn-danger" [class.disabled]="!isHistoricalDeletable"
    [disabled]="!isHistoricalDeletable" (click)="promptDeleteHistorical()">Delete this matter</button>

  <div></div>
  <button *ngIf="activeScreen === 'current-matters'" class="btn btn-success" [class.disabled]="!canAddNote"
    [disabled]="!canAddNote" [class.disabled] (click)="onAddInvoiceNote()">Add invoice note</button>

  <button *ngIf="activeScreen === 'take-on-data'" class="btn btn-success"
    [class.disabled]="!canAddHistoricalInvoiceNote" [disabled]="!canAddHistoricalInvoiceNote" [class.disabled]
    (click)="onAddInvoiceNote()">Add invoice note</button>
  <div></div>
  <button *ngIf="activeScreen === 'current-matters'" class="btn btn-info" [class.disabled]="!isGeneratable"
    [disabled]="!isGeneratable" (click)="generateInvoice()">Generate invoice</button>

  <button *ngIf="activeScreen === 'take-on-data'" class="btn btn-info" [class.disabled]="!isHistoricalGeneratable"
    [disabled]="!isHistoricalGeneratable" (click)="generateHistoricalInvoice()">Generate invoice</button>
  <div></div>
  <button *ngIf="userProfileService.userProfile.serviceProviderID === 28883" class="btn btn-info"
    [class.disabled]="!isGeneratable" [disabled]="!isGeneratable"
    (click)="onGenerateInvoiceLineToExcel()">Export</button>
  <div *ngIf="userProfileService.userProfile.serviceProviderID === 28883"></div>

  <button *ngIf="activeScreen === 'current-matters'" class="btn btn-success" [class.disabled]="!isSaveable"
    [disabled]="!isSaveable" (click)="save()">Save this matter</button>

  <button *ngIf="activeScreen === 'take-on-data'" class="btn btn-success" [class.disabled]="!isHistoricalSaveable"
    [disabled]="!isHistoricalSaveable" (click)="saveHistorical()">Save this matter</button>
</div>

<app-prompt-unsaved-changes-modal message="You have unsaved changes. Are you sure you want to leave the page?"
  [isVisible]="showUnsavedChangesPrompt" (cancel)="onHideUnsavedChangesPrompt()"
  (continue)="leavePage()"></app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal title="Fees exceeded quotation amount"
  message="Your fees has exceeded your agreed quotation amount. Do you wish to proceed?"
  [isVisible]="showFeeTotalExceedQuotedPrompt" (cancel)="onHideFeeTotalExceedQuotedPrompt()" btnTextCancel="No, Cancel"
  btnTextContinue="Yes, Save" (continue)="ignoreAndSave()"></app-prompt-unsaved-changes-modal>

<app-add-invoice-note [isVisible]="showAddInvoiceNoteModal" [invoiceDetails]="invoice" [matter]="workingMatter"
  (closeModal)="closeModal('add-invoice-note')"></app-add-invoice-note>
<!-- <app-alert
    title="REQUIRED FIELDS"
    message="One or more of the required fields are not completed"
    [isVisible]="showAttorneyErrorPrompt"
    (cancel)="onHideAttorneyErrorPrompt()"
    (confirm)="onHideAttorneyErrorPrompt()"></app-alert> -->