import { Component, OnInit, ViewChildren, QueryList,
  ElementRef, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Matter, MattersService } from '../../../services/matters/matters.service';
import { Router } from '@angular/router';
import { SnackbarsService, Snack } from '../../../services/messaging/snackbars/snackbars.service';
import { DataService } from '../../../services/messaging/data/data.service';
import { PATH_INVOICE_EMAIL, PATH_INVOICE_REVIEW } from '../../../services/appdata/app-config.service';
import { InvoiceDetails, InvoiceDetailsService, DEFAULT_INVOICE_DETAILS } from '../../../services/invoice-details/invoice-details.service';
import { FeeItemsService } from '../../../services/fee-items/fee-items.service';
import { MessageSend, MessageTemplate } from '../../../services/invoice-message/invoice-message.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import * as moment from 'moment';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';


interface MatterHandle {
  data: Matter;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

interface MessageTemplateHandle {
  data: MessageTemplate;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
    isSaveable: boolean;
    isLineErrorVisible: boolean;
    isFutureDatedErrorVisible: boolean;
  };
}

@Component({
  selector: 'app-multiple-invoices-table',
  templateUrl: './multiple-invoices-table.component.html',
  styleUrls: ['./multiple-invoices-table.component.scss']
})
export class MultipleInvoicesTableComponent implements OnInit, OnChanges {

  title = 'Generate Multiple Invoices';
  selectedMessage: any;
  showInvoiceReviewModal: boolean;
  todaysDate = new Date().toJSON();
  @Input() messageTemplateHandle: MessageTemplateHandle[];
  @Input() isHistoricalData: Boolean;
  @Output() messageTemplateHandleChange: EventEmitter<MessageTemplateHandle[]> = new EventEmitter<MessageTemplateHandle[]>();
  @ViewChildren('popupMenu') popupMenus: QueryList<ElementRef>;
  @ViewChild('invoicereviewmodal') invoicereviewmodal: ElementRef;

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  item: any;
  matters: Matter[];
  matterHandles: MatterHandle[] = [];
  newMatterHandle: MatterHandle[] = [];
  invoiceDetails: InvoiceDetails = this.util.objectCopy(DEFAULT_INVOICE_DETAILS);
  activeMessageHandle: MessageTemplateHandle;
  // invoiceDetails: InvoiceHandle [] = [];
  invoiceNote = '';

  constructor(
    private router: Router,
    private mattersService: MattersService,
    private snackbarsService: SnackbarsService,
    private dataService: DataService,
    private invoiceDetailsService: InvoiceDetailsService,
    private feeItemsService: FeeItemsService,
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService
  ) { }

  get isAttorney(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
  } // end profileType()

  ngOnInit() {

    // console.log('This is out matters: ' + this.invoiceDetails);
  } // end ngOnInit()

  hasValidEmails(emails: any): Boolean {
    emails.state.isSaveable = Boolean(this.util.areValidEmails(emails.data.Recipients));
    return !this.util.areValidEmails(emails.data.Recipients);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  closeModal(modal: string) {
    switch (modal) {
      case 'invoice-review-modal':
      this.showInvoiceReviewModal = false;
      break;
    }
  } // end closeModal()

  togglePopupMenu(matter: MessageTemplateHandle = null) {
    matter.state.isOpen = !matter.state.isOpen;
    this.activeMessageHandle = Object.assign({}, matter);
      this.dismissAllPopupMenus(matter);
  } // end togglePopupMenu{}

  /**
  * Closes all popup menus with the exception of the give MatterHandle.
  * @param {MatterHandle} exception The matterHandle that should not be
  * affected. If null is passed, all menues are cloesed. DEFAULT = null.
  */
  dismissAllPopupMenus(exception: MessageTemplateHandle = null) {
    this.messageTemplateHandle.forEach(matter => {
      if (exception &&
        exception.data.ID === matter.data.ID) {
        return;
      }
      matter.state.isOpen = false;
    });
  } // end dismissAllPopupMenus()

   /**
   * Navigates the user to the Invoice Preview page.
   * @param {MessageTemplate} message The matter to be previewed.
   */
  previewMessage(message: MessageTemplate) {
    this.loadingService.showOverlay();
    this.getInvoiceDetail(message.SourceId, message);
    console.log('message: ', message);
    setTimeout(() => {
      this.showInvoiceReviewModal = true;
      this.loadingService.hideOverlay();
      this.selectedMessage = message;
    }, 3000);
  } // end previewMessage()

  getInvoiceDetail(invoiceId: number, message: MessageTemplate) {
    this.invoiceDetailsService
      .getInvoiceDetails(invoiceId)
      .subscribe({ next: (details) => {
        this.invoiceDetails = details;
        this.onSaveInvoiceDetails(message);
      },
        error: (error) => {
          console.log('Error loading Invoice details', error);
        },
        complete: () => {
          // OnComplete
    }
  });
  }

  onPageClick(event) {
    console.log('SELECTED_MESSAGE: ', this.selectedMessage);
    if (event.target.className) {
      if (!event.target.classList.contains('fa-angle-down') &&
        this.activeMessageHandle) {
        // Close the open menu
        this.onDismissMenu(this.activeMessageHandle);
      } else if (!event.target.classList.contains('fa-angle-up') &&
        !this.activeMessageHandle) {
        this.dismissAllPopupMenus();
      }
    }
  } // end onPageClick()

  parseDate(date: string) {
    console.log('date: ', date);
    let d = '';
    if (date) {
      d = date;
    } else {
      d = new Date().toJSON();
    }
    return this.util.parseDate(d);
  } // end parseDate()

  onDismissMenu(message: MessageTemplateHandle) {
    message.state.isOpen = false;
    this.activeMessageHandle = null;
  } // end onDismissMenu()

  onSaveInvoiceDetails(message: MessageTemplate) {
    // this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Saving invoice details...',
      action: null
    };

    this.snackbarsService.snackbarComponent.make(snack, 5000).show();
    this.invoiceDetails.InvoiceNote = message.InvoiceNote;
    // this.invoiceDetails.InvoiceDate = new Date().toJSON();
    this.invoiceDetails.InvoiceDate = message.InvoiceDate;
    console.log('WORKING_INVOICE_DETAILS: ', this.invoiceDetails.InvoiceDate);
    if (this.invoiceNote) {
      this.invoiceDetails.InvoiceNote = this.invoiceNote;
    }
    this.invoiceDetailsService
      .updateInvoiceDetails(this.invoiceDetails.Id, this.invoiceDetails)
      .subscribe({ next: (invoiceDetails) => {
        this.invoiceDetails = invoiceDetails;
      },
        error: (error) => {
          // this.loadingService.hideOverlay();
          const msg = 'Error saving details.';
          console.error(msg, error);
          snack.label = msg;
          snack.action = {
            label: 'Retry',
            handler: this.onSaveInvoiceDetails,
            params: this
          };
          // snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
        },
        complete: () => {
          // On Complete
          // this.loadingService.hideOverlay();
          snack.label = 'Invoice details saved.';
          this.snackbarsService.dismiss().make(snack).show();
          this.invoiceNote = '';
        }
      });
    // this._subscritpions.push(uidSub);
  } // end onSaveInvoiceDetails()

  removeInvoice(message: MessageTemplate) {
    this.removeSelectedInvoice(message);
  } // end removeInvoice()

  removeSelectedInvoice(message: MessageTemplate) {
    const buffer = this.messageTemplateHandle.filter(value => {
      return value.data.ID !== message.ID;
    });

    this.messageTemplateHandle = buffer;
    this.messageTemplateHandleChange.emit(this.messageTemplateHandle);
  } // end removeSelectedInvoice()

  onReceipientInput(event, messageTemplate) {
    // console.log('ON_RECEIPIENT_INPUT', event, messageTemplate);
    this.messageTemplateHandleChange.emit(this.messageTemplateHandle);
  } // end onReceipientInput()

  // onNoteInput(event, messageTemplate) {
  //   // console.log('ON_NOTE_INPUT', event, messageTemplate);
  //   this.invoiceNote = event.target.value;
  //   this.messageTemplateHandleChange.emit(this.messageTemplateHandle);
  // } // end onNoteInput()

  /**
   * Called when the value of the date changes.
   * @param {*} event The date change event.
   */
  onDateChange(message: MessageTemplateHandle, value: Date): void {
    message.data.InvoiceDate = value ? value.toDateString() : '';
    if (value) {
      this.invoiceDetails.InvoiceDate = moment(value).format();
    }
    message.state.isFutureDatedErrorVisible = this.isFutureDated(message.data.InvoiceDate);
    message.state.isLineErrorVisible = false;
    if (!message.state.isFutureDatedErrorVisible) {
      message.state.isLineErrorVisible = this.hasInvoiceDateLessThanError(message.data.InvoiceHighestDate, message.data.InvoiceDate);
    }
    message.state.isSaveable = message.state.isFutureDatedErrorVisible && message.state.isLineErrorVisible;

  } // end onDateChange()

  hasInvoiceDateLessThanError(highestDate: string, invDate: string): boolean {
    let highDate = new Date(highestDate);
      let invoiceDate = new Date(invDate);
      let hd = moment(highDate.toDateString());
      let id = moment(invoiceDate.toDateString());
      if (id.isSameOrAfter(hd)) {
        return false;
      }
      return true;
  }

  isFutureDated(invDate: string): boolean {
    let invoiceDate = new Date(invDate);
    let now = moment().format('YYYY-MM-DD');
    let id = moment(invoiceDate.toDateString());
    if (id.isAfter(now)) {
      return true;
    }
    return false;
  }
}
