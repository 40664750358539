import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdvocateDetailStatementService,
  AdvocateDetailStatement,
  ICrumb} from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { PATH_ANALYTICS, PATH_ADVOCATE_DETAIL, PATH_REPORTS, PATH_ATTORNEY_DASHBOARD } from '../../../services/appdata/app-config.service';
import { InvoiceDocumentService } from '../../../services/invoice-document/invoice-document.service';
import { ApiService } from '../../../services/api.service';
import { ReportsService } from '../../../services/reports/reports.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit, OnDestroy {

  invoice: AdvocateDetailStatement;
  crumbs: ICrumb[];
  invoiceId: number;
  plaintiff: string;
  defendant: string;
  advocateName: string;
  fullName: string[];

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    LAWFIRM: 'Lawfirm'
  };

  _fileName;
  @ViewChild('pdfViewer') public pdfViewer;

  constructor(
    private route: ActivatedRoute,
    private advocateDetailService: AdvocateDetailStatementService,
    private invoiceDocumentService: InvoiceDocumentService,
    private api: ApiService,
    private reportService: ReportsService,
    private userProfileService: UserProfileService
  ) { } // end constructor()

  get invoiceTerms(): string {
    let invoice_terms = '';
    if (this.invoice.ZeroDays > 0) {
      invoice_terms = '0 days';
    } else if (this.invoice.ThirtyDays > 0) {
      invoice_terms = '30 days';
    } else if (this.invoice.SixtyDays > 0) {
      invoice_terms = '60 days';
    } else if (this.invoice.NinetyDays > 0) {
      invoice_terms = '90 days';
    } else if (this.invoice.NinetyEightDays > 0) {
      invoice_terms = '98 days';
    }

    return invoice_terms;
  }

  ngOnInit() {
    this.invoiceId = +this.route.snapshot.paramMap.get('id');
    this.invoice = this.advocateDetailService.selectedInvoice;

    const desc = this.advocateDetailService.selectedInvoice.ServiceDescription.split(' // ');
    if (desc.length > 0) {
      this.plaintiff = desc[0];
    }
    if (desc.length > 1) {
      this.defendant = desc[1];
    }

     this.advocateName = this.advocateDetailService.selectedInvoice.ProviderFullName.replace('Advocate', '');

    const url = this.api.endpoints.invoiceDocument + '?invoiceID=' + this.invoiceId;
    this.invoiceDocumentService.getInvoiceDocument(url)
    .subscribe(invoiceDoc => {
      // this._fileName = invoiceDoc;
      this.pdfViewer.name = 'name.pdf';
      this.pdfViewer.pdfSrc = invoiceDoc;
      this.pdfViewer.refresh();
    });
    if (this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM) {
        this.crumbs = [
          {
            label: 'Analytics',
            link: PATH_ATTORNEY_DASHBOARD
          },
          {
            label: 'Advocate statement',
            link: PATH_ADVOCATE_DETAIL + '/'
          },
          {
            label: this.invoice.ProviderFullName,
            link: PATH_ADVOCATE_DETAIL + '/' + this.advocateDetailService.lawfirmId + '/' + this.advocateDetailService.advocateId
          },
          {
            label: this.invoice.InvoiceNo
          }
        ];
      } else {
      this.crumbs = [
          {
            label: 'Analytics',
            link: PATH_REPORTS + '/'
          },
          {
            label: 'Advocate statement',
            link: PATH_ADVOCATE_DETAIL + '/'
          },
          {
            label: this.invoice.ProviderFullName,
            link: PATH_ADVOCATE_DETAIL + '/' + this.advocateDetailService.lawfirmId + '/' + this.advocateDetailService.advocateId
          },
          {
            label: this.invoice.InvoiceNo
          }
          // {
          //   label: this.invoiceId
          // }
        ];
      }
  } // end ngOnInit()

  ngOnDestroy() {
    this.reportService.selectedReport = 'liveStatement';
  } // end ngOnDestroy()

  resendInvoice() {

  } // end resendInvoice()

  toggleDropdownMenu() {

  } // end toggleDropdownMenu()

  @Input()
  set fileName(fileName: string) {
    this._fileName = fileName;
  }
  get fileName() {
    return this._fileName;
  }

} // end InvoiceComponent{}
