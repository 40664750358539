import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  DebtorAdvocateDetailHandle,
  OtherPracticeManagers,
  DebtorAdvocateDetail,
  DEFAULT_ADVOCATE_DETAIL
} from '../../../../services/practice-advocate-health/practice-advocate-health.service';
import { UserProfileService } from '../../../../services/user-profile/user-profile.service';
import { SnackbarsService, Snack, SnackType } from '../../../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../../../services/messaging/loading/loading.service';
import {
    ReportsService,
    LawfirmSummaryOverview,
    DEFAUL_LAWFIRM_SUMMARY_OVERVIEW,
  } from '../../../../services/reports/reports.service';

@Component({
  selector: 'app-practice-firm-detail',
  templateUrl: './practice-firm-detail.component.html',
  styleUrls: ['./practice-firm-detail.component.scss']
})
export class PracticeFirmDetailComponent implements OnInit, OnChanges {

  // Sorting
  sortBy: string;
  sortAscending: boolean;
  previousSortBy: string;
  sortAdvocate: boolean;
  sortInvoiceCount: boolean;
  sortAmount: boolean;

  onShowHandOverLawfirm: boolean;
  inLegalFirm: DebtorAdvocateDetail;

  // Search
  searchTerm: string;

  showTooltipOverlay = false;
  lawfirmSummaryData: LawfirmSummaryOverview;

  @Input() advocateDetailHandle: DebtorAdvocateDetailHandle[];
  @Input() otherPracticeManagers: OtherPracticeManagers[];

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private reportsService: ReportsService,
    private loadingService: LoadingService,
    private snackBarService: SnackbarsService,
    private userProfileService: UserProfileService,
  ) { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.advocateDetailHandle && changes.advocateDetailHandle.currentValue) {
      this.searchTerm = '';
    }
  } // end ngOnChanges()

  onInLegalChange(data: DebtorAdvocateDetail) {
    if (data.IsInLegal === false) {
      this.inLegalFirm = Object.assign({}, DEFAULT_ADVOCATE_DETAIL);
      this.inLegalFirm = data;
      this.onShowHandOverLawfirm = true;
    } else {
      this.loadingService.showOverlay();
      data.IsInLegal = !data.IsInLegal;
      const snack: Snack = {
        label: 'Updating inLegal status.',
        action: null
      };
      this.snackBarService.make(snack, 5000).show();
      this.reportsService.putInLegalStatus(data.AdvocateId, data.ClientId, 0, data.IsInLegal).subscribe({
      next: (response) => {
        // On next

      }, error: (error) => {
        // On error
          snack.label = 'Something went wrong, please contact development department.';
          snack.type = SnackType.ERROR;
          this.snackBarService.dismiss().make(snack, 5000).show();
          this.loadingService.hideOverlay();
      },
      complete: () => {
        snack.label = 'In Legal status updated.';
        snack.type = SnackType.SUCCESS;
        this.snackBarService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      }
    });
    }
  } // end onInLegalChange()

  onShowHandOverLawfirmDismiss() {
    this.onShowHandOverLawfirm = false;
  } // end onShowHandOverLawfirmDismiss()

  onLegalChangeConfirm(event) {
    this.loadingService.showOverlay();
    this.inLegalFirm.IsInLegal = !this.inLegalFirm.IsInLegal;
    const snack: Snack = {
      label: 'Update inLegal status.',
      action: null
    };
    this.snackBarService.make(snack, 5000).show();
    this.reportsService.putInLegalStatus(this.inLegalFirm.AdvocateId, this.inLegalFirm.ClientId, event, this.inLegalFirm.IsInLegal)
      .subscribe({ next: (response) => {
        // On next
      }, error: (error) => {
        // On Error
        snack.label = error + 'Something went wrong, please contact development department.';
        snack.type = SnackType.ERROR;
        this.snackBarService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      }, complete: () => {
        // On complete
        snack.label = 'In Legal status updated.';
        snack.type = SnackType.SUCCESS;
        this.snackBarService.dismiss().make(snack, 5000).show();
        this.onShowHandOverLawfirm = false;
        this.loadingService.hideOverlay();
      }
    });
  } // end onLegalChangeConfirm()

  performSearch() {
    if (this.searchTerm) {
      this.advocateDetailHandle.forEach(advocate => {
        if (
          !(advocate.data.AdvocateName.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          advocate.state.isVisible = false;
        } else {
          advocate.state.isVisible = true;
        }
      });
    } else {
      this.advocateDetailHandle.forEach(advocate => {
        advocate.state.isVisible = true;
      });
    }
  } // end performSearch()

  getInLegalBackgroungColor(value: boolean): string {
    if (value === true) {
      return '#f6d6d5';
    }
  } // end getInLegalBackgroungColor()

  getBarWidth(value: number): string {
    let width = '0.95';
    if (value >= 1) {
      width = (value * 0.95).toString();
    }
    return width + '%';
  } // end getBarWidth()

  toggleSort(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }
    switch (this.sortBy) {
      case 'advocate' :
        this.advocateDetailHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = true;
            this.sortInvoiceCount = false;
            this.sortAmount = false;
            return a.data.AdvocateName.toUpperCase().localeCompare(b.data.AdvocateName.toUpperCase());
          }
          this.sortAdvocate = false;
          return b.data.AdvocateName.toUpperCase().localeCompare(a.data.AdvocateName.toUpperCase());
        });
      break;

      case 'invoice-count' :
        this.advocateDetailHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = false;
            this.sortInvoiceCount = true;
            this.sortAmount = false;
            return a.data.InvoiceCount - b.data.InvoiceCount;
          }
          this.sortInvoiceCount = false;
          return b.data.InvoiceCount - a.data.InvoiceCount;
        });
      break;

      case 'amount' :
        this.advocateDetailHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocate = false;
            this.sortInvoiceCount = false;
            this.sortAmount = true;
            return a.data.Outstanding - b.data.Outstanding;
          }
          this.sortAmount = false;
          return b.data.Outstanding - a.data.Outstanding;
        });
      break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

  onLawfirmSummaryHover(data: DebtorAdvocateDetail) {
    this.lawfirmSummaryData = Object.assign({}, DEFAUL_LAWFIRM_SUMMARY_OVERVIEW);
    this.showTooltipOverlay = true;
    this.reportsService.getPMLawfirmSummaryOverview(data.AdvocateId, data.ClientId).subscribe({ next: (response) => {
      this.lawfirmSummaryData = response;
    },
      error: (error) => {
        // On error
      },
      complete: () => {
        // On Complete
        this.showTooltipOverlay = false;
      }
    });
  } // end onLawfirmSummaryHover()

} // end PracticeFirmDetailComponent{}
