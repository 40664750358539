import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import {
  ApiService
} from '../api.service';
import { catchError, retry } from 'rxjs/operators';
import { Fee } from '../fee-items/fee-items.service';

export interface ServiceReference {
  ServiceId: number;
  Reference: string;
}
export interface ServiceAttorney {
  ServiceId: number;
  Attorney: number;
}

export const DEFAULT_SERVICE_REFERENCE: ServiceReference = {
  ServiceId: 0,
  Reference: ''
};
export const DEFAULT_SERVICE_ATTORNEY: ServiceAttorney = {
  ServiceId: 0,
  Attorney: 0
};

export interface BriefDocumentHandle {
  data: BriefDocuments;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

export interface BriefDocuments {
  BriefId: number;
  DocumentPath: string;
  Status: boolean;
  DocumentType: string;
  DocumentTypeID: number;
  DateInserted: string;
}

export const DEFAULT_BRIEF_DOCUMENT: BriefDocuments = {
  BriefId: 0,
  DocumentPath: '',
  Status: false,
  DocumentType: '',
  DocumentTypeID: 0,
  DateInserted: ''
};

export interface Matter {
  ServiceID: number;
  ServiceDate: string;
  Description: string;
  ClientRefNo: string;
  Instructions: string;
  DailyRate: number;
  HourlyRate: number;
  PerKilometerRate: number;
  Status: string;
  ServiceProviderID: number;
  ClientContactID: number;
  ContactID: number;
  PlaintiffContactID: number;
  PlaintiffFullName: string;
  DefendantContactID: number;
  DefendantFullName: string;
  OldMatterGuid: string;
  NewClientDetail: string;
  Submit: boolean;
  RejectReason: string;
  InternalRefNo: string;
  InvoiceId: number;
  XmlData: string;
  LawFirm: string;
  ServiceAction: number;
  Discount: number;
  DiscountPercentage: number;
  PaymentReceived: number;
  Total: number;
  PaymentTerms: number;
  Briefs: number;
  DateOfAppearance: string;
  CaseNumber: string;
  RAFClaim: string;
  MAAA: string;
  EXCLVAT: boolean;
  TrialDateText: string;
  ArbitrationPercentage: number;
  IsArbitrationSplit: boolean;
} // end Matter{}

export interface HistoricalData {
  ServiceID: number;
  ServiceDate: string;
  Description: string;
  ClientRefNo: string;
  Instructions: string;
  DailyRate: number;
  HourlyRate: number;
  PerKilometerRate: number;
  Status: string;
  ServiceProviderID: number;
  ClientContactID: number;
  ContactID: number;
  PlaintiffContactID: number;
  PlaintiffFullName: string;
  DefendantContactID: number;
  DefendantFullName: string;
  OldMatterGuid: string;
  NewClientDetail: string;
  Submit: boolean;
  RejectReason: string;
  InternalRefNo: string;
  InvoiceId: number;
  XmlData: string;
  LawFirm: string;
  ServiceAction: number;
  Discount: number;
  DiscountPercentage: number;
  PaymentReceived: number;
  Total: number;
  PaymentTerms: number;
  InvoiceNo: string;
  EXCLVAT: boolean;
} // end Matter{}

export const DEFAULT_HISTORICAL_DATA: HistoricalData = {
  ServiceID: 0,
  ServiceDate: '',
  Description: '',
  ClientRefNo: '',
  Instructions: '',
  DailyRate: 0,
  HourlyRate: 0,
  PerKilometerRate: 0,
  Status: '',
  ServiceProviderID: 0,
  ClientContactID: 0,
  ContactID: 0,
  PlaintiffContactID: 0,
  PlaintiffFullName: '',
  DefendantContactID: 0,
  DefendantFullName: '',
  OldMatterGuid: '',
  NewClientDetail: '',
  Submit: false,
  RejectReason: '',
  InternalRefNo: '',
  InvoiceId: 0,
  XmlData: '',
  LawFirm: '',
  ServiceAction: 0,
  Discount: 0,
  DiscountPercentage: 0,
  PaymentReceived: 0,
  Total: 0,
  PaymentTerms: 0,
  InvoiceNo: '',
  EXCLVAT: false,
}; // end DEFAULT_MATTER{}

export const DEFAULT_MATTER: Matter = {
  ServiceID: 0,
  ServiceDate: '',
  Description: '',
  ClientRefNo: '',
  Instructions: '',
  DailyRate: 0,
  HourlyRate: 0,
  PerKilometerRate: 0,
  Status: '',
  ServiceProviderID: 0,
  ClientContactID: 0,
  ContactID: 0,
  PlaintiffContactID: 0,
  PlaintiffFullName: '',
  DefendantContactID: 0,
  DefendantFullName: '',
  OldMatterGuid: '',
  NewClientDetail: '',
  Submit: false,
  RejectReason: '',
  InternalRefNo: '',
  InvoiceId: 0,
  XmlData: '',
  LawFirm: '',
  ServiceAction: 0,
  Discount: 0,
  DiscountPercentage: 0,
  PaymentReceived: 0,
  Total: 0,
  PaymentTerms: 0,
  Briefs: 0,
  DateOfAppearance: '',
  CaseNumber: '',
  RAFClaim: '',
  MAAA: '',
  EXCLVAT: false,
  TrialDateText: '',
  ArbitrationPercentage: 0,
  IsArbitrationSplit: false
}; // end DEFAULT_MATTER{}

export interface ArbitrationSplit {
  Id: number;
  ServiceId: number;
  Services: Matter;
  SecondaryFirmId: number;
  SecondaryAttorneyId: number;
  SecondaryReference: string;
  SplitPercentage: number;
} // end ArbitrationSplit{}

export const DEFAULT_ARBITRATION_SPLIT: ArbitrationSplit = {
  Id: 0,
  ServiceId: 0,
  Services: DEFAULT_MATTER,
  SecondaryFirmId: 0,
  SecondaryAttorneyId: 0,
  SecondaryReference: '',
  SplitPercentage: 0
} // end DEFAULT_ARBITRATION_SPLIT{}

@Injectable()
export class MattersService {

  private matters: Matter[];
  private briefs: any;
  private url: string;
  activeScreen: string;

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) {
  } // end constructor()

  /**
   * GET: Select details of Service/Matter Records
   * Will select all service records for given service provider id.
   * @param {number} id The Service Provider ID
   */
  getMatters(): Observable<any> {
    return this.http.get<any>(this.api.endpoints.services +
      '/' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getMatters()

  getSentMatters(): Observable<any> {
    return this.http.get(this.api.endpoints.sentServices +
      '/' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getSentMatters()

  /**
   * POST: Insert new Service/Matter data.
   * @param {Matter} matter The new matter.
   * @returns {Observable<Matter>} Returns the newly added matter upon success.
   */
  addMatter(matter: Matter): Observable<Matter> {
    const postableMatter = {
      serviceID: 0,
      serviceDate: matter.ServiceDate,
      description: matter.Description,
      clientRefNo: matter.ClientRefNo,
      instructions: matter.Instructions,
      dailyRate: matter.DailyRate,
      hourlyRate: matter.HourlyRate,
      perKilometerRate: matter.PerKilometerRate,
      status: matter.Status,
      serviceProviderID: matter.ServiceProviderID,
      clientContactID: matter.ClientContactID,
      contactID: matter.ContactID,
      plaintiffContactID: matter.PlaintiffContactID,
      plaintiffFullName: matter.PlaintiffFullName,
      defendantContactID: matter.DefendantContactID,
      defendantFullName: matter.DefendantFullName,
      submit: matter.Submit,
      serviceAction: matter.ServiceAction,
      PaymentReceived: matter.PaymentReceived,
      PaymentTerms: matter.PaymentTerms,
      discount: matter.Discount,
      CaseNumber: matter.CaseNumber,
      rAFClaim: matter.RAFClaim,
      dateOfAppearance: matter.DateOfAppearance,
      maaa: matter.MAAA,
      exclvat: matter.EXCLVAT,
      trialDateText: matter.TrialDateText
    };
    return this.http.post<any>(
        this.api.endpoints.services,
        postableMatter,
        this.api.httpOptions
      ).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end addMatter()

  /**
   * PUT: Update Service/Matter data.
   * @param {number} id The unique Service ID number.
   * @param {Matter} matter The updated matter object.
   * @returns {Observable<Matter>} Returns the updated matter upon success.
   */
  updateMatter(id: number, matter: Matter): Observable<any> {
    return this.http.put<Matter>(this.api.endpoints.services + '?id=' +
      id, matter, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateMatter()

  // Update Matter/Invoice reference
  updateReference(serviceReference: ServiceReference): Observable<any> {
    return this.http.put<ServiceReference>(this.api.endpoints.services +
      '/EditReference', serviceReference, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end updateReference()

  // Update Matter/Invoice rAttorney
  updateAttorney(serviceAttorney: ServiceAttorney): Observable<any> {
    return this.http.put<ServiceAttorney>(this.api.endpoints.services +
      '/EditAttorney', serviceAttorney, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end updateAttorney()

  updateBriefDocumentType(briefId: number, briefDocument: BriefDocuments): Observable<any> {
    return this.http.put<BriefDocuments>(this.api.endpoints.services +
      '/EditDocumentType?briefId=' + briefId, briefDocument, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end updateBriefDocumentType()

  /**
   * DELETE: delete the service from the server.
   * @param {number} id The unique Service ID.
   * @param {string} deleteReason The reason for the deletion.
   */
  deleteMatter(id: number, deleteReason: string): Observable<{}> {
    return this.http.delete<Matter>(this.api.endpoints.services + '?id=' +
      id + '&deleteReason=' + deleteReason, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end deleteMatter()

  getMatterFromInvoiceId(invoiceId: number): Observable<any> {
    if (this.matters) {
      return of(this.matters.find(matter => {
        return matter.InvoiceId === invoiceId;
      }));
    }

    let foundMatter: Matter;
    this.getMatters().subscribe({ next:
      (matters) => {
        this.matters = matters;
      },
      error: (error) => {
        const msg = 'Error loading matters';
        console.error(msg, error);
      },
      complete: () => {
        foundMatter = this.matters.find(matter => {
          return matter.InvoiceId === invoiceId;
        });
      }
    });
    return of(foundMatter);
  } // end getMatterFromInvoiceId()

  getDocumentList(serviceId: number): Observable<any> {
    return this.http.get(this.api.endpoints.services +
      '/DocumentList?ServiceProviderId=' + this.api.serviceProviderID +
      '&ServiceId=' + serviceId, this.api.httpOptions).pipe(
        catchError(this.api.handleError)
      );
  } // getDocumentList{}

  getAttorneyDocumentList(serviceProvider: number, serviceId: number): Observable<any> {
    return this.http.get(this.api.endpoints.services +
      '/DocumentList?ServiceProviderId=' + serviceProvider +
      '&ServiceId=' + serviceId, this.api.httpOptions).pipe(
        catchError(this.api.handleError)
      );
  } // getAttorneyDocumentList{}

  getSingleDocument(serviceId: number, briefId: number): Observable<any> {
    return this.http.get(this.api.endpoints.services +
      '/SingleDocument?ServiceProviderId=' + this.api.serviceProviderID +
      '&ServiceId=' + serviceId +
      '&BriefDocumentId=' + briefId, this.api.httpOptions).pipe(
        catchError(this.api.handleError)
      );
  } // getSingleDocument{}

  deleteDocument(briefDocumentId: number): Observable<any> {
    return this.http.delete(this.api.endpoints.services +
      '/RemoveDocument?BriefDocumentId=' + briefDocumentId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end deleteDocument()

  addDocumentToSend(serviceId: number, briefId: number, status: string) {
    return this.http.put(this.api.endpoints.services +
      '/AddDocumentsToSent?ServiceId=' + serviceId + '&briefId=' + briefId + '&status=' + status, null,
      this.api.httpOptions).pipe(
        catchError(this.api.handleError)
      );
  } // end addDocumentToSend()

  //#region  Historical Data
  postUploadHistoricalData(file: FormData): Observable<any> {
    return this.http.request('POST', this.api.endpoints.uploadHistoricalData +
      '?ServiceProviderId=' + this.api.serviceProviderID,
      {
        body: file
      });
  } // end postUploadHistoricalData()

  getHistoricalData(): Observable<any> {
    return this.http.get(this.api.endpoints.takeOnData +
      '/GetTakeOnData?ServiceProviderId=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  } // end getHistoricalData()

  getHistoricalById(serviceId: number): Observable<any> {
    return this.http.get(this.api.endpoints.takeOnData +
      '/GetTakeOnDataById?ServiceProviderId=' + this.api.serviceProviderID +
      '&ServiceId=' + serviceId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getHistoricalById()

  getHistoricalFeeData(serviceId: number): Observable<any> {
    return this.http.get(this.api.endpoints.takeOnData +
      '/GetHistoricalFees?ServiceProviderID=' + this.api.serviceProviderID +
      '&ServiceID=' + serviceId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getHistoricalFeeData()

  updateHistoricalService(id: number, matter: HistoricalData): Observable<any> {
    return this.http.put<HistoricalData>(this.api.endpoints.takeOnData +
      '/UpdateHistoricalService?id=' + id, matter, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateHistoricalService()

  addHistoricalData(matter: HistoricalData): Observable<HistoricalData> {
    const postableMatter = {
      serviceID: 0,
      serviceDate: matter.ServiceDate,
      description: matter.Description,
      clientRefNo: matter.ClientRefNo,
      instructions: matter.Instructions,
      dailyRate: matter.DailyRate,
      hourlyRate: matter.HourlyRate,
      perKilometerRate: matter.PerKilometerRate,
      status: matter.Status,
      serviceProviderID: matter.ServiceProviderID,
      clientContactID: matter.ClientContactID,
      contactID: matter.ContactID,
      plaintiffContactID: matter.PlaintiffContactID,
      plaintiffFullName: matter.PlaintiffFullName,
      defendantContactID: matter.DefendantContactID,
      defendantFullName: matter.DefendantFullName,
      submit: matter.Submit,
      serviceAction: matter.ServiceAction,
      PaymentReceived: matter.PaymentReceived,
      PaymentTerms: matter.PaymentTerms,
      discount: matter.Discount,
      InvoiceNo: matter.InvoiceNo
    };
    return this.http.post<any>(
        this.api.endpoints.takeOnData +
        '/PostHistoricalService',
        postableMatter,
        this.api.httpOptions
      ).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end addHistoricalData()

  updateHistoricalFees(fees: Fee[]): Observable<any> {
    return this.http.put(this.api.endpoints.takeOnData +
      '/PutFeeItemList?ServiceProviderID=' + this.api.serviceProviderID,
      {feeItemList: fees}, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateHistoricalFees()

  postUploadAuxconData(file: FormData): Observable<any> {
    return this.http.request('POST', this.api.endpoints.takeOnData +
      '/AuxconDataImport?ServiceProviderId=' + this.api.serviceProviderID,
      {
        body: file
      });
  } // end postUploadAuxconData()
//#endregion Historical Data
} // end MatterService{}
