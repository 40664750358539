import { Injectable } from "@angular/core";

export const PATH_ANALYTICS = 'analytics';
export const PATH_LANDING = 'advocate-landing';
export const PATH_PRACTICE_MANAGER_DASHBOARD = 'practice-manager-dashboard';
export const PATH_PRACTICE_ADVOCATE_OVERVIEW = 'advocate-overview';
export const PATH_DASHBOARD = 'advocate-dashboard';
export const PATH_LAWFIRM_DASHBOARD = 'lawfirm-dashboard';
export const PATH_CALENDAR = 'advocate-calendar';
export const PATH_LAWFIRM_CALENDAR = 'lawfirm-calendar';
export const PATH_REPORTS = 'advocate-reports';
export const PATH_PRACTICE_MANAGER_REPORTS = 'practice-manager-reports';
export const PATH_PRACTICE_ALL_CUSTOMER = PATH_PRACTICE_MANAGER_REPORTS + '/practice-all-customer';
export const PATH_PRACTICE_ADVOCATE_DETAIL = 'practice-advocate-detail';
export const PATH_CUSTOMER_PAYMENT_RATING = PATH_REPORTS + '/customer-payment-rating';
export const PATH_INDIVIDUAL_CUSTOMER = PATH_CUSTOMER_PAYMENT_RATING + '/individual-customer';
export const PATH_CUSTOMER_INVOICE_PREVIEW = PATH_INDIVIDUAL_CUSTOMER + '/invoice-preview';
export const PATH_REPORT_AGE_ANALYSIS = PATH_REPORTS + '/report-age-analysis';
export const PATH_REPORT_ALL_CUSTOMERS = PATH_REPORT_AGE_ANALYSIS + '/report-all-customers';
export const PATH_AGE_ANALYSIS_INDIVIDUAL_CUSTOMER = PATH_REPORT_ALL_CUSTOMERS + '/age-analysis-individual-customer';
export const PATH_AGE_ANALYSIS_CUSTOMER_INVOICE_PREVIEW = PATH_AGE_ANALYSIS_INDIVIDUAL_CUSTOMER + '/invoice-preview';
export const PATH_REPORT_INVOICE_COLLECTED = PATH_REPORTS + '/report-invoice-collected';
export const PATH_REPORT_TAX_LIABILITY = PATH_REPORTS + '/report-tax-liability';
export const PATH_REPORT_ALLCUSTOMER_REPORT = PATH_REPORTS + '/report-allcustomer-report';
export const PATH_RINDIVIDUAL_CUSTOMER = PATH_REPORT_ALLCUSTOMER_REPORT + '/individual-customer';
export const PATH_RINVOICE_PREVIEW = PATH_RINDIVIDUAL_CUSTOMER + '/invoice-preview';
export const PATH_THE_INDIVIDUAL_CUSTOMER = PATH_REPORTS + '/individual-customer';
export const PATH_THE_INVOICE_PREVIEW = PATH_THE_INDIVIDUAL_CUSTOMER + '/invoice-preview';
export const PATH_SEARCH = 'advocate-search';
export const PATH_SEARCH_INVOICE_PREVIEW = PATH_SEARCH + '/invoice-preview';
export const PATH_LAWFIRM_SEARCH = 'lawfirm-search';
export const PATH_MESSAGES = 'advocate-messages';
export const PATH_MANAGE_BLACKLIST = 'manage-defaulterlist';
export const PATH_BLACKLIST_PDF_VIEWER = 'manage-blacklist-pdf-viewer';
export const PATH_PRACTICE_MANAGER_MESSAGES = 'practise-manager-messages';
export const PATH_LAWFIRM_MESSAGES = 'lawfirm-messages';
export const PATH_MATTERS = 'advocate-matters';
export const PATH_QUOTATION = 'advocate-quotation';
export const PATH_INVOICE_EMAIL = 'advocate-invoice-email';
export const PATH_QUOTATION_EMAIL = 'advocate-quotation-email';
export const PATH_ALL_ADVOCATES = 'all-advocates';
export const PATH_INVOICE_REVIEW = 'advocate-invoice-review';
export const PATH_EDIT_MY_TEAM = 'app-advocate-edit-my-team';
export const PATH_EDIT_FEE_TEMPLATES = 'advocate-edit-fee-templates';
export const PATH_SIGN_IN = 'advocate-sign-in';
export const PATH_SIGN_IN_LOADING = 'advocate-sign-in-loading';
export const PATH_SIGN_OUT = 'advocate-sign-out';
export const PATH_CANCELLED_SUBSCRIPTION = 'inactive-profile';
export const PATH_PROCESS_INVOICES = 'advocate-process-invoices';
export const PATH_ATTORNEY_DASHBOARD = 'attorney-dashboard';
export const PATH_ATTORNEY_AGE_ANALYSIS = PATH_ATTORNEY_DASHBOARD + '/attorney-age-analysis';
export const PATH_ATTORNEY_ALL_CUSTOMER = PATH_ATTORNEY_AGE_ANALYSIS + '/all-customer-report';
export const PATH_ATTORNEY_PER_CUSTOMER = PATH_ATTORNEY_ALL_CUSTOMER + '/per-advocate-report';
export const PATH_INVOICE = 'invoice';
export const PATH_ADD_EDIT_CLIENTS = 'add-edit-clients';
export const PATH_ADVOCATE_DETAIL = PATH_ANALYTICS + '/advocate-detail';
export const PATH_UNPAID_INVOICES = PATH_ANALYTICS + '/unpaid-invoices';
export const PATH_INVOICE_PREVIEW = PATH_MESSAGES + '/invoice-preview';
export const PATH_ADVOCATE_INVOICE_PREVIEW = 'advocate-invoice-preview';
export const PATH_PROCESS = 'advocate-process';
export const PATH_LAWFIRM_PROCESS = 'lawfirm-process';
export const PATH_PROOF_OF_PAYMENTS = PATH_PROCESS + '/proof-of-payments';
export const PATH_RECONCILE_BANK_TRANSACTIONS = PATH_PROCESS + '/reconcile-bank-transactions';
export const PATH_RECONCILE_BANK_TRANSACTIONS_INVOICE = PATH_RECONCILE_BANK_TRANSACTIONS + '/invoice-preview';
export const PATH_SEND_STATEMENT = PATH_PROCESS + '/send-statement';
export const PATH_MANAGE_BANK_ACCOUNTS = PATH_PROCESS + '/manage-bank-accounts';
export const PATH_QUOTATION_PREVIEW = 'quotation-preview';
export const PATH_CREATE_NEW_USER = "create-new-user";
export const PATH_USER_SAGE_VALIDATION = "advocate-sage-setup";
export const PATH_OUTLOOK_SYNC = "outlook-sync-setup";
export const PATH_AUXCON_ADMIN = 'admin';
export const PATH_CONTACT_MANAGEMENT = PATH_AUXCON_ADMIN + '/contact-management';
export const PATH_PLATEFORM_BANK_TRANSACTION = "plateform-bank-transactions";
export const PATH_COLLECTION_REPORT = PATH_AUXCON_ADMIN + '/collection-report';
export const PATH_USER_SINGUP = 'user-signup';
export const PATH_GCB_RESPONSE_HANDLER = 'gcb-response-confirmation';
export const PATH_ONBOARDING_REGISTER = 'onboarding/register';
export const PATH_ONBOARDING_REGISTER_AD_HOC = 'onboarding/register-ad-hoc';
export const PATH_ONBOARDING_VERIFY_USER = 'onboarding/verify-user';
export const PATH_SUBSCRIPTION_BILLING = 'subscription-billing';
export const PATH_SUBSCRIPTION_PROMPT = 'prompt-subscription';
export const PATH_PAYMENT_RESPONSE = 'payment-response';
export const PATH_AD_HOC_COLLECTIONS = 'ad-hoc-instruction';
export const PATH_OUTLOOK_HANDLER = 'outlook-handler';
export const PATH_ARBITRATION_INVOICES = 'arbitration-invoices';

export const CONTACT_TYPE_ID_TECHNICAL_SUPPORT = 1;
export const CONTACT_TYPE_ID_ADVOCATE = 2;
export const CONTACT_TYPE_ID_ADVOCATE_CHAMBERS = 3;
export const CONTACT_TYPE_ID_ADVOCATE_ASSISTANT = 4;
export const CONTACT_TYPE_ID_ADVOCATE_RELATIONS_MANAGER = 5;
export const CONTACT_TYPE_ID_LAWFIRM = 6;
export const CONTACT_TYPE_ID_ATTORNEY = 7;
export const CONTACT_TYPE_ID_OTHER_CLIENT = 8;
export const CONTACT_TYPE_ID_ATTORNEY_ACCOUNTANT = 9;
export const CONTACT_TYPE_ID_ADVOCATE_ASSISTANCE_FIRM = 10;
export const CONTACT_TYPE_ID_SHERIFF_COMPONENT = 11;
export const CONTACT_TYPE_ID_SHERIFF_SOFTWARE_VENDOR = 12;
export const CONTACT_TYPE_ID_SHERIFF_MAC_ADDRESS = 13;
export const CONTACT_TYPE_ID_SHERIFF = 14;
export const CONTACT_TYPE_ID_ATTORNEY_COMPONENT = 15;
export const CONTACT_TYPE_ID_ATTORNEY_SOFTWARE_VENDOR = 16;
export const CONTACT_TYPE_ID_BAR = 17;
export const CONTACT_TYPE_ID_BAR_SOCIETY = 18;
export const CONTACT_TYPE_ID_CLIENT_CONTACT = 19;
export const CONTACT_TYPE_ID_C_SHARP_DEVELOPMENT_CONTRACTOR = 20;
export const CONTACT_TYPE_ID_PLAINTIFFS = 21;
export const CONTACT_TYPE_ID_ATTORNEYS = 22;

export const CHART_COLOR_BLUE = "#5E97D8";
export const CHART_COLOR_RED = "#D57F70";
export const CHART_COLOR_GREEN = "#99BA62";
export const CHART_COLOR_YELLOW = "#DCB967";
export const CHART_COLOR_GREY = "#B9B9B9";
export const CHART_COLOR_WHITE = "#FFFFFF";

export const DB_NAME = "auxcon-attorney-db";
export const DB_VERSION = 1;

export const AUXCON_EMAIL_SUPPORT = "support@auxcon.co.za";
export const AUXCON_EMAIL_INFO = "info@auxcon.co.za";
export const AUXCON_EMAIL_TEST = "supports@auxcon.co.za";

@Injectable()
export class AppConfigService {
  baseURL: string;
  adalTest: string;

  constructor() {
    this.baseURL = "http://localhost:4200/";
    this.adalTest = `https://login.microsoftonline.com/jobsfundoutlook769.onmicrosoft.com/oauth2/authorize
      ?response_type=id_token
      &client_id=375e584f-e837-47d3-a346-c505ddfd469f
      &redirect_uri=http%3A%2F%2Flocalhost%3A3000
      &state=bf14d964-c6ed-414a-aa5a-1846f2711671
      &nux=1
      &client-request-id=ccb12ea3-15bd-439a-accc-548b56faf6de
      &x-client-SKU=Js
      &x-client-Ver=1.0.15
      &nonce=48f32032-ff37-44dd-b099-3f2b4b9ddfd0`;
  } // end constructor()
} // end AppConfigService{}
