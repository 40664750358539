<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>

<div class="container-fluid">
    <nav class="report-nav">
        <a class="tab"
        [class.active]="activeTab === 'law-firms'"
        (click)="togglePanel('law-firms')">Law Firm / Attorney</a>

        <a class="tab"
        [class.active]="activeTab === 'advocates'"
        (click)="togglePanel('advocates')">Advocates</a>

        <a class="tab"
        [class.active]="activeTab === 'secretary'"
        (click)="togglePanel('secretary')">Secretary</a>
    </nav>
    <div class="contact-body row" [class.hide]="activeTab !== 'law-firms'">
        <div class="left-block col-sm-8">
            <div class="block-title">Law Firms</div>

    <!-- #start search -->
      <div class="row contact-search input-group">
        <input
          type="search"
          #inputCheckbox
          class="form-control"
          placeholder="Search for Law firm"
          [(ngModel)]="lawfirmSearchTerm"
          (keyup.enter)="performLawfirmSearch()">
          <div class="input-group-append">
            <i *ngIf="!lawfirmSearchTerm" class="far fa-search"></i>
            <i *ngIf="lawfirmSearchTerm" (click)="clearLawfirmSearch()" class="far fa-close"></i>
          </div>
      </div> <!-- #end .row reconcile-search input-group -->

            <div class="row m-0">
                <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th scope="col" (click)="toggleSortLawfirm('firm-name')">
                                   
                                </th>
                                <th scope="col" (click)="toggleSortLawfirm('firm-name')">Firm Name
                                    <span><i class="fa fa-sort" [class.fa.sort-down]="sortLawfirmName"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSortLawfirm('firm-name')">Law Firm ID
                                    <span><i class="fa fa-sort" [class.fa.sort-down]="sortLawfirmName"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSortLawfirm('firm-phone')">Phone Number
                                    <span><i class="fa fa-sort" [class.fa.sort-down]="sortLawfirmPhone"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSortLawfirm('firm-email')">Email 
                                    <span><i class="fa fa-sort" [class.fa.sort-down]="sortLawfirmEmail"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSortLawfirm('firm-vat')">VAT Number 
                                    <span><i class="fa fa-sort" [class.fa.sort-down]="sortLawfirmVat"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSortLawfirm('firm-street-address')">Street Address 
                                    <span><i class="fa fa-sort" [class.fa.sort-down]="sortLawfirmStreetAddress"></i></span>
                                </th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                            <tr class="tr" *ngFor="let firm of contactsPagination.results | paginate: { itemsPerPage: contactsPagination.PageSize, currentPage: contactsPagination.CurrentPage, totalItems: contactsPagination.TotalCount  }"
                                [class.active]="row == firm.Id">
                                <td style="width: 2%;">
                                    <app-small-checkbox
                                        [(checked)]="firm.IsSelected"
                                        (click)="toggleCheckbox(firm)"></app-small-checkbox>
                                </td>
                                <td style="width: 18%;" (click)="onLawfirmRowClick(firm)">{{ firm.FullName }}</td>
                                <td style="width: 10%;" (click)="onLawfirmRowClick(firm)">{{ firm.Id }}</td>
                                <td style="width: 10%;"  (click)="onLawfirmRowClick(firm)">{{ firm.Phone }}</td>
                                <td style="width: 200px; -ms-word-break: break-all; word-break: break-all; white-space: pre-wrap"  (click)="onLawfirmRowClick(firm)">{{ firm.Email }}</td>
                                <td style="width: 10%;"  (click)="onLawfirmRowClick(firm)">{{ firm.Vatno }}</td>
                                <td style="width: 16%;"  (click)="onLawfirmRowClick(firm)">{{ firm.StreetAddress }}</td>    
                                <td style="width: 2%;"><i class="fal fa-eye" style="color: #8ABD37;"></i></td>         
                                <td (click)="onEditLawFirm(firm)" style="width: 2%;"><i class="fal fa-edit" style="color: #E5C100;"></i></td>                    
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls
                        previousLabel="Prev"
                        nextLabel="Next"
                        (pageChange)="onTableDataChange($event)">
                    </pagination-controls>
                </div>
            </div>
            <button class="btn btn-success"
                (click)="addNewContact()">Add new Contact</button>
                &nbsp; &nbsp; &nbsp;
            <button class="btn btn-info"
                [class.disabled]="!canSaveMergeLawFirms"
                [disabled]="!canSaveMergeLawFirms"
                (click)="onMergeLawfirms()">Merge Law firms</button>
        </div>

        <div class="right-block col-sm-4">
            <div class="block-title">Attorneys</div>
            <!-- #start search -->
            <div class="row contact-search input-group">
                <input
                type="search"
                #inputCheckbox
                class="form-control"
                placeholder="Search for an Attorney"
                [(ngModel)]="attorneySearchTerm"
                (keyup)="performAttorneySearch()">
                <div class="input-group-append">
                    <i *ngIf="!attorneySearchTerm" class="far fa-search"></i>
                    <i *ngIf="attorneySearchTerm" (click)="clearAttorneySearch()" class="far fa-close"></i>
                </div>
            </div> <!-- #end .row reconcile-search input-group -->

            <div class="row m-0">
                <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th scope="col" (click)="toggleSortAttorney('attorney-name')">Attorney Name
                                    <span><i class="fa fa-sort" [class.fa.sort-down]="sortAttorneyName"></i></span>
                                </th>
                                <!-- <th scope="col" (click)="toggleSortAttorney('attorney-phone')">Phone Number
                                    <span><i class="fa fa-sort" [class.fa.sort-down]="sortAttorneyPhone"></i></span>
                                </th> -->
                                <th scope="col" (click)="toggleSortAttorney('attorney-cell')">Cell Number 
                                    <span><i class="fa fa-sort" [class.fa.sort-down]="sortAttorneyCell"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSortAttorney('attorney-email')">Email 
                                    <span><i class="fa fa-sort" [class.fa.sort-down]="sortAttorneyEmail"></i></span>
                                </th>
                                <!-- <th scope="col"></th> -->
                                <th scope="col"></th>
                            </tr>
                            <tr class="tr" *ngFor="let firm of attorneyHandle"
                                [class.active]="row == firm.data.Id">
                                <td *ngIf="firm.state.isVisible" style="width: 30%;">{{ firm.data.FullName }}</td>
                                <!-- <td *ngIf="firm.state.isVisible" style="width: 25%;">{{ firm.data.Phone }}</td> -->
                                <td *ngIf="firm.state.isVisible" style="width: 26%;">{{ firm.data.Cell }}</td>
                                <td *ngIf="firm.state.isVisible" style="width: 20%;">{{ firm.data.Email }}</td>
                                <!-- <td *ngIf="firm.state.isVisible" style="width: 2%;" class="clickable"><i class="fal fa-eye" style="color: #8ABD37;"></i></td>          -->
                                <td *ngIf="firm.state.isVisible" style="width: 2%;" class="clickable" (click)="onEditAttorney(firm.data)"><i class="fal fa-edit" style="color: #E5C100;"></i></td>                    
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button class="btn btn-primary"
                [class.disabled]="!canAddAttorney"
                [disabled]="!canAddAttorney"
                (click)="addNewAttorney()">Add an Attorney</button>
            </div>
        </div>
    </div>
    <div class="contact-body row" [class.hide]="activeTab !== 'advocates'">
        <app-advocates
         *ngIf="activeTab === 'advocates'"></app-advocates>
    </div>
    <div class="contact-body row" [class.hide]="activeTab !== 'secretary'">
        <app-secretary
            *ngIf="activeTab === 'secretary'"></app-secretary>
    </div>
</div>

<app-add-new-attorney
    *ngIf="showAddNewAttorney"
    [isVisible]="showAddNewAttorney"
    [selectedLawfirm]="selectedLawfirm"
    (onSaveAttorney)="onAttorneySave($event)"
    (closeModal)="onCloseModal($event)"></app-add-new-attorney>

<app-edit-existing-attorney
    *ngIf="showEditAttorney"
    [isVisible]="showEditAttorney"
    [selectedLawfirm]="selectedLawfirm"
    [selectedAttorney]="selectedAttorney"
    (onSaveAttorney)="onAttorneyUpdate($event)"
    (closeModal)="onCloseModal($event)"></app-edit-existing-attorney>

<app-add-new-law-firm
    *ngIf="showAddEditLawfirm"
    [isVisible]="showAddEditLawfirm"
    [selectedLawfirm]="selectedLawfirm"
    [isManageBlacklistUser]="isManageBlacklistUser"
    (closeModal)="onCloseModal($event)"
    (onSaveLawFirm)="onSaveNewLawfirm($event)"></app-add-new-law-firm>

<app-prompt-unsaved-changes-modal
    title="Merge Law firms"
    [message]="mergeMessage"
    [isVisible]="showMergeContactsPrompt"
    (cancel)="onHideMergeContactsPrompt()"
    (continue)="continueMerge()"
    btnTextCancel="No, Cancel"
    btnTextContinue="Proceed"></app-prompt-unsaved-changes-modal>