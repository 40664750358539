
<div class="well">
    <label class="subheading">Matter details ({{title}})</label>
    <app-circular-indicator
      message="Loading..."
      [isVisible]="isLoading"></app-circular-indicator>
  
    <form [class.hide]="isLoading" class="form" autocomplete="off">
  
      <div class="form-group">
        <label for="date">Invoice Date</label>
        <input
          type="text" id="text"
          bsDatepicker
          [bsValue]="parseServiceDate(workingHistorical.ServiceDate)"
          (ngModel)="workingHistorical.ServiceDate=$event.target.value"
          (bsValueChange)="onDateValueChange($event)"
          [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
          placement="right">
      </div><!--#end .form-group -->
  
      <div class="form-group">
        <label for="lawFirms" *ngIf="!isAttorney && !isLawfirm && !isLawfirmAssistant">Law firms / Advocates <span title="Required field"> *</span>&nbsp;
          <span *ngIf="isLawfirmRatingLoading">
            <img [src]="images.spinner.path" [alt]="images.spinner.label"></span><span *ngIf="!isLawfirmSelected && !isLawfirmRatingLoading" class="fa fa-question-circle tool-tip">
          <span class="tooltiptext"><p>{{lawfirmRatingLabel}}</p></span>
        </span></label>
        <app-datalist-lawfirm
          type="law-firms" *ngIf="!isAttorney && !isLawfirm && !isLawfirmAssistant"
          iconClass="fa fa-black-tie fa-lg"
          [list]="lawFirms"
          [(matter)]="workingHistorical"
          (select)="onModelChange('law-firms', $event)"></app-datalist-lawfirm>
  
        <label for="clients" *ngIf="isAttorney || isLawfirm || isLawfirmAssistant">Clients<span title="Required field"> *</span></label>
        <app-datalist-lawfirm
          type="other-clients" *ngIf="isAttorney || isLawfirm || isLawfirmAssistant"
          iconClass="fa fa-black-tie fa-lg"
          [otherClients]="otherClients"
          [matter]="workingHistorical"
          (select)="onModelChange('other-clients', $event)"></app-datalist-lawfirm>
        <!-- <app-datalist-contact
          type="law-firms"
          iconClass="fa fa-black-tie fa-lg"
          [list]="lawFirms"
          [matter]="workingHistorical"
          (select)="onModelChange('law-firms', $event)"></app-datalist-contact> -->
      </div><!--#end .form-group -->
  
      <div class="form-group" *ngIf="!isAttorney && !isLawfirm && !isLawfirmAssistant">
        <label for="attorneys">Attorneys</label>
        <app-datalist-lawfirm
          type="attorneys"
          iconClass="fa fa-black-tie fa-lg"
          [list]="attorneys"
          [matter]="workingHistorical"
          (select)="onModelChange('attorneys', $event)"></app-datalist-lawfirm>
        <!-- <app-datalist-contact
          type="attorneys"
          iconClass="fa fa-black-tie fa-lg"
          [list]="attorneys"
          [matter]="workingHistorical"
          (select)="onModelChange('attorneys', $event)"></app-datalist-contact> -->
      </div><!--#end .form-group -->
  
      <div class="form-group">
        <label for="plaintiffs">Plaintiffs/Applicants<span title="Required field"> *</span></label>
        <app-datalist-plaintiffs
          type="plaintiffs"
          iconClass="fa fa-black-tie fa-lg"
          [plaintiffsList]="plaintiffs"
          [matter]="workingHistorical"
          (select)="onModelChange('plaintiffs', $event)"></app-datalist-plaintiffs>
        <!-- <app-datalist-contact
          type="plaintiffs"
          [list]="plaintiffs"
          [iconClass]="'fa fa-institution fa-lg'"
          [required]="true"
          [matter]="workingHistorical"
          (select)="onModelChange('plaintiffs', $event)"></app-datalist-contact>  -->
      </div><!--#end .form-group -->
  
      <div class="form-group">
        <label for="defendants">Defendants/Respondents</label>
        <app-datalist-plaintiffs
         type="defendants" 
         iconClass="fa fa-black-tie fa-lg" 
         [defendantsList]="defendants" 
         [matter]="workingHistorical"
         (select)="onModelChange('defendants', $event)"></app-datalist-plaintiffs>
        <!-- <app-datalist-contact
          type="defendants"
          [list]="defendants"
          [iconClass]="'fa fa-institution fa-lg'"
          [matter]="workingHistorical"
          (select)="onModelChange('defendants', $event)"></app-datalist-contact> -->
      </div><!--#end .form-group -->
  
    </form><!--#end .form -->
  
    <div class="form" [class.hide]="isLoading">
  
        <div class="form-group">
            <label for="date">Invoice No <span title="Required field"> *</span></label>
            <input
                required
                type="text"
                placeholder="Invoice Number"
                [(ngModel)]="workingHistorical.InvoiceNo"
                (click)="$event.target.select()">
          </div><!--#end .form-group -->

      <div class="form-group">
        <label for="hourlyRate">Hourly rate<span title="Required field"> *</span></label>
        <input
        type="text"
        id="hourlyRate"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingHistorical.HourlyRate | currency: currencySymbol: currencySymbol:'1.2-2'"
        (ngModelChange)="workingHistorical.HourlyRate=$event"
        (ngModelChange)="workingHistorical.DailyRate=$event * 10"
        (blur)="onHourlyRateChange()"
        (focus)="onHourlyRateClicked()"
        (focus)="$event.target.select()"
        pattern="[P-R\s]*[0-9,.]+"
        autocomplete="off"
        #inputHourly
        required>
      </div><!--#end .form-group -->
  
      <div class="form-group">
        <label for="dailyRate">Daily rate<span title="Required field"> *</span></label>
        <input
          type="text"
          id="dailyRate"
          [ngModelOptions]="{updateOn: 'blur'}"
          [ngModel]="workingHistorical.DailyRate | currency: currencySymbol: currencySymbol:'1.2-2'"
          (ngModelChange)="workingHistorical.DailyRate=$event"
          (blur)="onDailyRateChange()"
          (focus)="onDailyRateClicked()"
          (focus)="$event.target.select()"
          pattern="[P-R\s]*[0-9,.]+"
          autocomplete="off"
          #inputDaily
          required>
      </div><!--#end .form-group -->
  
      <div class="form-group">
        <label for="paymentTerms">Payment term</label>
        <select
          id="paymentTerms" 
          [(ngModel)]="workingHistorical.PaymentTerms">
          <option *ngFor="let age of paymentTerms" [value]="age.id" [selected]="age.id === workingHistorical.PaymentTerms">
            {{age.name}}</option>
        </select>
      </div> <!-- #end .form-group -->
  
      <div class="form-group">
        <label for="attorneyReference"  *ngIf="isAttorney || isLawfirm || isLawfirmAssistant">Reference<span title="Required field"> *</span></label>
        <label for="attorneyReference"  *ngIf="!isAttorney && !isLawfirm && !isLawfirmAssistant">Attorney reference<span title="Required field"> *</span></label>
        <input
          required
          type="text"
          placeholder="Attorney reference"
          [(ngModel)]="workingHistorical.ClientRefNo"
          (input)="onModelChange('attorney-reference', {FullName: $event.target.value})"
          (blur)="onReferenceChangeCheck()"
          (click)="$event.target.select()">
      </div><!--#end .form-group -->
  
      <div class="form-group">
        <label for="instructions">Instructions</label>
        <input
          type="text"
          placeholder="Instructions"
          [(ngModel)]="workingHistorical.Instructions"
          (input)="onModelChange('instructions', {FullName: $event.target.value})"
          (click)="$event.target.select()">
      </div><!--#end .form-group -->
  
    </div><!--#end .form -->
  
    <div class="form" [class.hide]="isLoading" *ngIf="isRafMatter">
      <div class="form-group" *ngIf="isRafMatter">
        <label for="date-of-appearance">Date of appearance</label>
        <input
          readonly
          type="text" 
          id="text" 
          bsDatepicker 
          [value]="workingHistorical.DateOfAppearance | date:'yyyy-MM-dd'"
          (ngModel)="workingHistorical.DateOfAppearance=$event.target.value" 
          (bsValueChange)="onAppearanceDateChange($event)"
          [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" 
          placement="right">
      </div>
      <!--#end .form-group -->
      
      <div class="form-group">
        <label for="case-number">Case number</label>
        <input 
          type="text" 
          placeholder="Case number" 
          [(ngModel)]="workingHistorical.CaseNumber" 
          (click)="$event.target.select()">
      </div>
      <!--#end .form-group -->
      
      <div class="form-group" *ngIf="isRafMatter">
        <label for="raf-claim">RAF claim, link or ID number</label>
        <input
          type="text" 
          placeholder="RAF claim" 
          [(ngModel)]="workingHistorical.RAFClaim" 
          (click)="$event.target.select()">
      </div>
      <!--#end .form-group -->
  
      <div class="form-group" *ngIf="isRafMatter">
  
      </div> <!-- #end .form-group -->
  
      <div class="form-group" *ngIf="isRafMatter">
  
      </div> <!-- #end .form-group -->
  
      <div class="form-group" *ngIf="isRafMatter">
  
      </div> <!-- #end .form-group -->
    </div> <!-- #end .form -->
  
  </div><!--#end .well -->
  
  <!-- <app-prompt-unsaved-changes-modal
    title="MATTER ALREADY EXIST"
    message="Kindly take note that there is already a matter with the same reference under you current matters. Please navigate to your dashboard and search for the matter."
    [isVisible]="showMatterExistsPrompt"
    (cancel)="onHideMatterExistsPrompt()"
    btnTextCancel="Cancel"
    btnTextContinue="Dasboard"
    (continue)="navigateToDashboard()"></app-prompt-unsaved-changes-modal> -->