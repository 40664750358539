import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { ReportsService, AllocatedToLawfirm, AllocatedToLawfirmHandle } from '../../../services/reports/reports.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import {
    PATH_INVOICE_PREVIEW,
    PATH_CUSTOMER_INVOICE_PREVIEW,
    PATH_AGE_ANALYSIS_CUSTOMER_INVOICE_PREVIEW,
    PATH_RINDIVIDUAL_CUSTOMER,
    PATH_RINVOICE_PREVIEW,
    PATH_THE_INVOICE_PREVIEW
} from '../../../services/appdata/app-config.service';
import { AdvocateDetailStatement } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { PaymentDetail, PaymentDetailService } from '../../../services/payment-detail/payment-detail.service';
import { Snack, SnackbarsService, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { ReturnStatement } from '@angular/compiler';
import {
    InvoiceDetails,
    InvoiceDetailsService,
    DEFAULT_INVOICE_DETAILS,
} from '../../../services/invoice-details/invoice-details.service';
import {
    NotesObject,
    NotesService,
    Notes,
    DEFAULT_NOTES_OBJECT,
    NoteCategory,
    NotesObjectHandle,
    MultiInvoiceNote,
    MultiInvoiceNoteHandle,
    MultiObjectNote,
    InstructionResponses,
} from '../../../services/notes/notes.service';
import { LIST_NAME_LAW_FIRMS } from '../../../services/lists/lists.service';
import { QuotationService, QuotationFeeItem } from '../../../services/quotation/quotation.service';
import { WriteOffTransactionsLines,
    DEFAULT_WRITEOFF_TRANSACTIONS_LINE } from '../../../services/process-payment/process-payment.service';
import { ContactsService } from '../../../services/contacts/contacts.service';
import { IndexedDBService } from '../../../services/indexed-db/indexed-db.service';
import { InvoiceDocumentService } from '../../../services/invoice-document/invoice-document.service';

export interface AllCustomerReportHandle {
    data: AllCustomerReport;
    state: {
        isVisible: boolean;
        isSelected: boolean;
        isOpen: boolean;
        isAdHocSelected: boolean
    };
}
export interface AllCustomerReport {
    serviceProviderId: number;
    clientId: number;
    invoiceDate: string;
    matter: string;
    invoiceNumber: string;
    daysOverdue: number;
    due: number;
    paid: number;
    invoiceAmount: number;
    type: string;
    invoiceId: number;
    hasPaid: boolean;
    daysOutstanding: number;
    totalDaysOutstanding: number;
    paymentDays: number;
    attorney: string;
    attorneyRef: string;
    paymentDate: string;
    discounting: boolean;
    discountingDueDate: string;
    adHocCollections: boolean;
    notesDetail: NotesObject;
}

export const DEFAULT_ALL_CUSTOMER_REPORT: AllCustomerReport = {
    serviceProviderId: 0,
    clientId: 0,
    invoiceDate: '',
    matter: '',
    invoiceNumber: '',
    daysOverdue: 0,
    due: 0,
    paid: 0,
    invoiceAmount: 0,
    type: '',
    invoiceId: 0,
    hasPaid: false,
    daysOutstanding: 0,
    totalDaysOutstanding: 0,
    paymentDays: 0,
    attorney: '',
    attorneyRef: '',
    paymentDate: '',
    discounting: false,
    discountingDueDate: '',
    adHocCollections: false,
    notesDetail: DEFAULT_NOTES_OBJECT
};

@Component({
    // moduleId: module.id,
    selector: 'app-individual-customer-report',
    templateUrl: './individual-customer-report.component.html',
    styleUrls: ['./individual-customer-report.component.scss']
})
export class IndividualCustomerReportComponent implements OnInit, OnChanges {
    isDataLoaded = false;
    activeTab = 'overdue';
    apiData: any = [];
    currentApiData: AllCustomerReport[] = []; // Object.assign({}, DEFAULT_ALL_CUSTOMER_REPORT);
    adHocInvoiceToBeRemove: AllCustomerReport = Object.assign({}, DEFAULT_ALL_CUSTOMER_REPORT);
    overdueData: any = [];
    currentData: any = [];
    allData: any = [];
    paidData: any = [];
    tabData: AllCustomerReport[] = [];
    tabDataHandle: AllCustomerReportHandle[] = [];
    sortAscending = false;
    lawfirmName: string;
    discountingInvoice: AllCustomerReport;
    showDiscounting: boolean;
    isDiscounting: boolean;
    showDisableDiscountingPrompt: boolean;

    _tabData: any = [];
    searchTerm: any;
    sortData: String = 'invoiceDate';
    selectedTab: string;

    totalInvoiceAmount = 0;
    totalDue = 0;
    totalPaid = 0;
    unAllocatedTTransaction = 0;
    unAllocatedTAmount = 0;
    showPaymentDetailModal: boolean;
    paymentDetails: PaymentDetail[];
    selectedInvoice: AdvocateDetailStatement;
    noPayment: boolean;
    pageFrom: string;

    sortBy: string;
    previousSortBy: string;

    sortDate: boolean;
    sortMatter: boolean;
    sortInvoiceNo: boolean;
    sortDaysOverDue: boolean;
    sortDue: boolean;
    sortPaid: boolean;
    sortAmount: boolean;
    sortDaysOutstanding: boolean;
    sortAttorney: boolean;
    sortAttorneyRef: boolean;

    sortUnallocatedDate: boolean;
    sortUnallocatedDescription: boolean;
    sortUnallocatedTransactionAmount: boolean;
    sortUnallocatedAmount: boolean;
    count = 0;
    invoiceStartDate = new Date('2010-01-01');
    invoiceEndDate = new Date();

    // Invoice Preview Modal
    showProformaInvoice: boolean;
    invoiceDetails: InvoiceDetails;
    invoicesTransaction: any;
    invoicePlaintiff: string;
    invoiceDefendant: string;
    selectedInvoiceId: number;
    noteCategory: NoteCategory[] = [];
    feesQuoted: QuotationFeeItem[] = [];

    //#region Ad-Hoc Collections
        onRequestAdHocMessage: string;
        onRequestRemovalAdHocMessage: string;
        showRequestAdHocPrompt: boolean;
        showRequestRemovalAdHocPrompt: boolean;
        isAdHocCollections: boolean;
    //#endregion Ad-Hoc Collections

    profileTypes = {
        ADVOCATE: 'Advocate',
        ASSISTANT: 'Assistant',
        ATTORNEY: 'Attorney',
        PRACTICE_MANAGER: 'Practice Manager',
        EXTERNAL_ATTORNEY: 'Attorney (External)',
        LAWFIRM: 'Lawfirm',
        EXTERNAL_LAWFIRM: 'Lawfirm (External)'
    };

    // notes
    notes: NotesObject[] = [];
    notesHandle: NotesObjectHandle[] = [];
    showInvoiceNoteDetail: boolean;
    objectType: string;
    onShowNoteDetail: boolean;
    noteDetailData: NotesObject;
    multiInvoiceNote: MultiInvoiceNote[] = [];
    multiInvoiceNoteHandle: MultiInvoiceNoteHandle[] = [];
    onShowMultiInvoiceNote: boolean;
    instructionResponses: InstructionResponses[] = [];

        // allocatedToLawFirmHandle: AllocatedToLawfirmHandle[] = [];
    allocatedToLawFirmHandle: AllocatedToLawfirmHandle[] = [];
    @Input() allocatedToLawFirm: AllocatedToLawfirm[];
    @Input() debtorName: string;
    @Input() debtorId: number;
    @ViewChild('inputCheckboxSelectAll') inputCheckboxSelectAll: ElementRef;

    get currencySymbol(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
        } else {
            return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
        }
    } // end currencySymbol()

    get isAssistantToLawfirm(): boolean {
        return Boolean(this.userProfileService.userProfile.isImpersonator)
            && Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
    } // end isAssistantToLawfirm()

    // Is Internal Attorney
    get isAttorney(): boolean {
        let attorneyProfile = false;
        if (this.userProfileService.userProfile.isImpersonator) {
            attorneyProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
        } else {
            attorneyProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
        }
        return attorneyProfile;
    } // end isAttorney()

    // Is Internal Lawfirm
    get isLawfirm(): boolean {
        let lawfirmProfile = false;
        if (this.userProfileService.userProfile.isImpersonator) {
            lawfirmProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
        } else {
            lawfirmProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
        }
        return lawfirmProfile;
    } // end isLawfirm()

    // Is Practice Manager
    get isPracticeManager(): boolean {
        return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
    }  // end isPracticeManager()

    get showAddNoteButton(): boolean {
        return Boolean(this.tabDataHandle && this.tabDataHandle.find(x => x.state.isSelected === true));
    } // end showAddNoteButton()

    get showAddAdHocButton(): boolean {
        return Boolean(this.tabDataHandle && this.tabDataHandle.find(x => x.state.isAdHocSelected === true));
    } // end showAddAdHocButton()

    onShowWriteOffModal: boolean;
    toWriteOffTransactionLine: InvoiceDetails[] = [];

    constructor(
        private router: Router,
        private notesService: NotesService,
        private reportsService: ReportsService,
        private loadingService: LoadingService,
        private contactService: ContactsService,
        private indexedDbService: IndexedDBService,
        private snackbarsService: SnackbarsService,
        private quotationService: QuotationService,
        private userProfileService: UserProfileService,
        private paymentDetailService: PaymentDetailService,
        private invoiceDetailService: InvoiceDetailsService,
        private invoiceDocumentService: InvoiceDocumentService
    ) { } // end conatructor()

    ngOnInit(): void {
        this.pageFrom = this.reportsService.individualCustomerFrom;
        if (this.reportsService.individualCustomerActiveTab) {
            this.activeTab = this.reportsService.individualCustomerActiveTab;
        }
    } // end ngOnInit()

    ngOnChanges(changes: SimpleChanges) {
        if (changes.debtorId && changes.debtorId.currentValue) {
            if (this.debtorId > 0) {
                this.getApiData();
            }
        }
    } // end ngOnChanges()

    toggleUnAllocatedSort(sortAllocated: string) {
        this.sortBy = sortAllocated;
        if (this.sortBy === this.previousSortBy) {
            this.sortAscending = !this.sortAscending;
        }

        switch (this.sortBy) {
            case 'date':
                this.allocatedToLawFirmHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortUnallocatedDate = true;
                        this.sortUnallocatedDescription = false;
                        this.sortUnallocatedTransactionAmount = false;
                        this.sortUnallocatedAmount = false;
                        return a.data.transactionDate.toUpperCase().localeCompare(b.data.transactionDate.toUpperCase());
                    }
                    return b.data.transactionDate.toUpperCase().localeCompare(a.data.transactionDate.toUpperCase());
                });
                break;
            case 'description':
                this.allocatedToLawFirmHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortUnallocatedDate = false;
                        this.sortUnallocatedDescription = true;
                        this.sortUnallocatedTransactionAmount = false;
                        this.sortUnallocatedAmount = false;
                        return a.data.description.toUpperCase().localeCompare(b.data.description.toUpperCase());
                    }
                    return b.data.description.toUpperCase().localeCompare(a.data.description.toUpperCase());
                });
                break;
            case 'transaction-amount':
                this.allocatedToLawFirmHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortUnallocatedDate = false;
                        this.sortUnallocatedDescription = false;
                        this.sortUnallocatedTransactionAmount = true;
                        this.sortUnallocatedAmount = false;
                        return a.data.transactionAmount - b.data.transactionAmount;
                    }
                    return b.data.transactionAmount = a.data.transactionAmount;
                });
                break;
            case 'amount':
                this.allocatedToLawFirmHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortUnallocatedDate = true;
                        this.sortUnallocatedDescription = false;
                        this.sortUnallocatedTransactionAmount = false;
                        this.sortUnallocatedAmount = false;
                        return a.data.amount - b.data.amount;
                    }
                    return b.data.amount - a.data.amount;
                });
                break;
        }
        this.previousSortBy = this.sortBy;
    } // end toggleUnAllocatedSort()

    toggleSort(sortBy: string) {
        this.sortBy = sortBy;
        if (this.sortBy === this.previousSortBy) {
            this.sortAscending = !this.sortAscending;
        }

        switch (this.sortBy) {
            case 'date':
                this.tabDataHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = true;
                        this.sortMatter = false;
                        this.sortInvoiceNo = false;
                        this.sortDaysOverDue = false;
                        this.sortDue = false;
                        this.sortPaid = false;
                        this.sortAmount = false;
                        this.sortDaysOutstanding = false;
                        this.sortAttorney = false;
                        this.sortAttorneyRef = false;
                        return a.data.invoiceDate.toUpperCase().localeCompare(b.data.invoiceDate.toUpperCase());
                    }
                    return b.data.invoiceDate.toUpperCase().localeCompare(a.data.invoiceDate.toUpperCase());
                });
                break;
            case 'matter':
                this.tabDataHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortMatter = true;
                        this.sortInvoiceNo = false;
                        this.sortDaysOverDue = false;
                        this.sortDue = false;
                        this.sortPaid = false;
                        this.sortAmount = false;
                        this.sortDaysOutstanding = false;
                        this.sortAttorney = false;
                        this.sortAttorneyRef = false;
                        return a.data.matter.toUpperCase().localeCompare(b.data.matter.toUpperCase());
                    }
                    return b.data.matter.toUpperCase().localeCompare(a.data.matter.toUpperCase());
                });
                break;
            case 'attorney':
                this.tabDataHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortMatter = false;
                        this.sortInvoiceNo = false;
                        this.sortDaysOverDue = false;
                        this.sortDue = false;
                        this.sortPaid = false;
                        this.sortAmount = false;
                        this.sortDaysOutstanding = false;
                        this.sortAttorney = true;
                        this.sortAttorneyRef = false;
                        return a.data.attorney.toUpperCase().localeCompare(b.data.attorney.toUpperCase());
                    }
                    return b.data.attorney.toUpperCase().localeCompare(a.data.attorney.toUpperCase());
                });
                break;
            case 'attorney-ref':
                this.tabDataHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortMatter = false;
                        this.sortInvoiceNo = false;
                        this.sortDaysOverDue = false;
                        this.sortDue = false;
                        this.sortPaid = false;
                        this.sortAmount = false;
                        this.sortDaysOutstanding = false;
                        this.sortAttorney = false;
                        this.sortAttorneyRef = true;
                        return a.data.attorneyRef.toUpperCase().localeCompare(b.data.attorneyRef.toUpperCase());
                    }
                    return b.data.attorneyRef.toUpperCase().localeCompare(a.data.attorneyRef.toUpperCase());
                });
                break;
            case 'invoice-number':
                this.tabDataHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortMatter = false;
                        this.sortInvoiceNo = true;
                        this.sortDaysOverDue = false;
                        this.sortDue = false;
                        this.sortPaid = false;
                        this.sortAmount = false;
                        this.sortDaysOutstanding = false;
                        this.sortAttorney = false;
                        this.sortAttorneyRef = false;
                        return a.data.invoiceNumber.toUpperCase().localeCompare(b.data.invoiceNumber.toUpperCase());
                    }
                    return b.data.invoiceNumber.toUpperCase().localeCompare(a.data.invoiceNumber.toUpperCase());
                });
                break;
            case 'days-overdue':
                this.tabDataHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortMatter = false;
                        this.sortInvoiceNo = false;
                        this.sortDaysOverDue = false;
                        this.sortDue = false;
                        this.sortPaid = false;
                        this.sortAmount = false;
                        this.sortDaysOutstanding = true;
                        this.sortAttorney = false;
                        this.sortAttorneyRef = false;
                        return a.data.daysOverdue - b.data.daysOverdue;
                    }
                    return b.data.daysOverdue - a.data.daysOverdue;
                });
                break;
            case 'due':
                this.tabDataHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortMatter = false;
                        this.sortInvoiceNo = false;
                        this.sortDaysOverDue = false;
                        this.sortDue = true;
                        this.sortPaid = false;
                        this.sortAmount = false;
                        this.sortDaysOutstanding = false;
                        this.sortAttorney = false;
                        this.sortAttorneyRef = false;
                        return a.data.due - b.data.due;
                    }
                    return b.data.due - a.data.due;
                });
                break;
            case 'days-outstanding':
                this.tabDataHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortMatter = false;
                        this.sortInvoiceNo = false;
                        this.sortDaysOverDue = false;
                        this.sortDue = true;
                        this.sortPaid = false;
                        this.sortAmount = false;
                        this.sortDaysOutstanding = false;
                        this.sortAttorney = false;
                        this.sortAttorneyRef = false;
                        return a.data.totalDaysOutstanding - b.data.totalDaysOutstanding;
                    }
                    return b.data.totalDaysOutstanding - a.data.totalDaysOutstanding;
                });
                break;
            case 'paid':
                this.tabDataHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortMatter = false;
                        this.sortInvoiceNo = false;
                        this.sortDaysOverDue = false;
                        this.sortDue = false;
                        this.sortPaid = true;
                        this.sortAmount = false;
                        this.sortDaysOutstanding = false;
                        this.sortAttorney = false;
                        this.sortAttorneyRef = false;
                        return a.data.paid - b.data.paid;
                    }
                    return b.data.paid - a.data.paid;
                });
                break;
            case 'amount':
                this.tabDataHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortMatter = false;
                        this.sortInvoiceNo = false;
                        this.sortDaysOverDue = false;
                        this.sortDue = false;
                        this.sortPaid = false;
                        this.sortAmount = true;
                        this.sortDaysOutstanding = true;
                        this.sortAttorney = false;
                        this.sortAttorneyRef = false;
                        return a.data.invoiceAmount - b.data.invoiceAmount;
                    }
                    return b.data.invoiceAmount - a.data.invoiceAmount;
                });
                break;
        }
        this.previousSortBy = this.sortBy;
    } // end toggleSort()

    onTabClick(tab: string) {
        this.searchTerm = '';
        this.activeTab = tab;
        this.getSelectedTabData();
    } // end onTabClick()

    getApiData(): void {
    this.isDataLoaded = false;
    this.apiData = [];
    this.currentApiData = [];
    this.allData = [];
    this.overdueData = [];
    this.currentData = [];
    this.currentData = [];
    this.paidData = [];

    this.loadingService.showOverlay();
    this.reportsService.getByIndividualCustomer(this.debtorId).subscribe({ next: (response) => {
        if (response) {
            this.apiData = response;
            this.currentApiData = response;
            this.paidData = this.apiData.filter(c => c.hasPaid === true);
            for (const data of this.apiData) {
                if (data.type === 'Overdue' || data.type === 'Contingency') {
                    this.overdueData.push(data);
                } else if (data.type === 'Current') {
                    this.currentData.push(data);
                }

                if (data.attorney) {

                } else {
                    data.attorney = 'N/A';
                }
                // else if (data.type === 'Paid') {
                //     this.paidData.push(data);
                // }
            }
        }
    },
        error: (error) => {
            // Error
        },
        complete: () => {
            this.activeTab = this.setTab(this.reportsService.individualCustomerActiveTab);
            this.getSelectedTabData();
            this.isDataLoaded = true;
        }
    });
    } // end getApoData()

    setTab(activeTab: string): string {
        let type = '';
        if (activeTab === 'all') {
            type = 'all';
        } else if (activeTab === 'current') {
            type = 'current';
        } else if (activeTab === '30') {
            type = 'current';
        } else if (activeTab === '60') {
            type = 'current';
        } else if (activeTab === '90') {
            type = 'current';
        } else {
            type = 'overdue';
        }
        return type;
    } // end setTab()

    // getSelectedTabData(): void {
    // this.tabData = [];
    // this._tabData = [];
    // this.sortData = 'invoiceDate';
    // this.clearSearch();
    // const Moment = require('moment');
    // switch (this.activeTab) {
    //     case 'overdue':
    //         this.tabData = this.overdueData.filter(s => s.due > 0)
    //         .sort((a, b) => b.invoiceDate.toUpperCase().localeCompare(a.invoiceDate.toUpperCase()));
    //         this.getTotalAmount(this.tabData);
    //         this._tabData = this.overdueData;
    //     break;
    //     case 'current':
    //         this.tabData = this.currentData.filter(s => s.due > 0)
    //             .sort((a, b) => b.invoiceDate.toUpperCase().localeCompare(a.invoiceDate.toUpperCase()));
    //         this.getTotalAmount(this.tabData);
    //         this._tabData = this.currentData;
    //     break;
    //     case 'paid':
    //         this.invoiceStartDate = new Date('2010-01-01');
    //         this.invoiceEndDate = new Date();
    //         this.tabData = this.paidData.sort((a, b) => b.invoiceDate.toUpperCase().localeCompare(a.invoiceDate.toUpperCase()));
    //         this.getTotalAmount(this.tabData);
    //         this._tabData = this.paidData;
    //     break;
    //     case 'all':
    //         this.tabData = this.apiData.filter(s => s.due > 0)
    //                         .sort((a, b) => b.invoiceDate.toUpperCase().localeCompare(a.invoiceDate.toUpperCase()));
    //         this.getTotalAmount(this.tabData);
    //         this._tabData = this.apiData;
    //     break;
    //     case 'unallocated':
    //         if (this.allocatedToLawFirm.length > 0) {
    //             this.allocatedToLawFirmHandle = [];
    //             this.allocatedToLawFirm.forEach(unallocated => {
    //                 this.allocatedToLawFirmHandle.push({
    //                     data: unallocated,
    //                     state: {
    //                         isVisible: true,
    //                         isSelected: false,
    //                         isOpen: false
    //                     }
    //                 });
    //             });
    //         }
    //         this.getUnAllocatedAmounts(this.allocatedToLawFirmHandle);
    //     break;
    //     // this.reportsService.getIndividualCustomerParameters().subscribe(debtorId => {
    //     this.isDataLoaded = false;
    //     this.apiData = [];
    //     this.allData = [];
    //     this.overdueData = [];
    //     this.currentData = [];
    //     this.currentData = [];
    //     this.paidData = [];

    //     // if (debtorId == null) {
    //     //     return;
    //     // }

    //     this.loadingService.showOverlay();
    //     this.reportsService.getByIndividualCustomer(this.debtorId).subscribe(response => {
    //         if (response) {
    //             this.apiData = response;
    //             this.paidData = this.apiData.filter(c => c.hasPaid === true);
    //             for (const data of this.apiData) {
    //                 if (data.type === 'Overdue') {
    //                     this.overdueData.push(data);
    //                 } else if (data.type === 'Current') {
    //                     this.currentData.push(data);
    //                 }

    //                 if (data.attorney) {

    //                 } else {
    //                     data.attorney = 'N/A';
    //                 }
    //                 // else if (data.type === 'Paid') {
    //                 //     this.paidData.push(data);
    //                 // }
    //             }
    //         }
    //     },
    //         error => {
    //             // Error
    //         },
    //         () => {
    //             if (this.reportsService.individualCustomerActiveTab) {
    //                 if (this.reportsService.individualCustomerActiveTab === 'current' ||
    //                     this.reportsService.individualCustomerActiveTab === '30' ||
    //                     this.reportsService.individualCustomerActiveTab === '60' ||
    //                     this.reportsService.individualCustomerActiveTab === '90') {
    //                     this.activeTab = 'current';
    //                 } if (this.reportsService.individualCustomerActiveTab === 'all') {
    //                     this.activeTab = 'all';
    //                 } else {
    //                     this.activeTab = 'overdue';
    //                 }
    //             }

    //             this.getSelectedTabData();
    //             // this.loadingService.hideOverlay();
    //             this.isDataLoaded = true;
    //         });
    //     // }, error => {
    //     //     // Error
    //     // });
    // }
    getSelectedTabData(): void {
        this.tabData = [];
        this.tabDataHandle = [];
        this._tabData = [];
        this.sortData = 'invoiceDate';
        this.clearSearch();
        const Moment = require('moment');
        switch (this.activeTab) {
            case 'overdue':
                this.tabData = this.overdueData.filter(s => s.due > 0)
                .sort((a, b) => b.invoiceDate.toUpperCase().localeCompare(a.invoiceDate.toUpperCase()));
                this._tabData = this.overdueData;
                this.tabData.forEach(_data => {
                    this.tabDataHandle.push({
                        data: _data,
                        state: {
                            isVisible: true,
                            isOpen: false,
                            isSelected: false,
                            isAdHocSelected: false
                        }
                    });
                });
            this.getTotalAmount(this.tabDataHandle);
            break;
            case 'current':
                this.tabData = this.currentData.filter(s => s.due > 0)
                .sort((a, b) => b.invoiceDate.toUpperCase().localeCompare(a.invoiceDate.toUpperCase()));
                this._tabData = this.currentData;
                this.tabData.forEach(_data => {
                    this.tabDataHandle.push({
                        data: _data,
                        state: {
                            isVisible: true,
                            isOpen: false,
                            isSelected: false,
                            isAdHocSelected: false
                        }
                    });
                });
            this.getTotalAmount(this.tabDataHandle);
            break;
            case 'paid':
                this.invoiceStartDate = new Date('2010-01-01');
                this.invoiceEndDate = new Date();
                this.tabData = this.paidData.sort((a, b) => b.paymentDate.toUpperCase().localeCompare(a.paymentDate.toUpperCase()));
                this._tabData = this.paidData;
                this.tabData.forEach(_data => {
                    this.tabDataHandle.push({
                        data: _data,
                        state: {
                            isVisible: true,
                            isOpen: false,
                            isSelected: false,
                            isAdHocSelected: false
                        }
                    });
                });
                this.getTotalAmount(this.tabDataHandle);
            break;
            case 'all':
                this.tabData = this.apiData.filter(s => s.due > 0)
                .sort((a, b) => b.invoiceDate.toUpperCase().localeCompare(a.invoiceDate.toUpperCase()));
                this._tabData = this.apiData;
                this.tabData.forEach(_data => {
                    this.tabDataHandle.push({
                        data: _data,
                        state: {
                            isVisible: true,
                            isOpen: false,
                            isSelected: false,
                            isAdHocSelected: false
                        }
                    });
                });
            this.getTotalAmount(this.tabDataHandle);
            break;
            case 'unallocated':
                this.allocatedToLawFirmHandle = [];
                if (this.allocatedToLawFirm.length > 0) {
                    this.allocatedToLawFirm.forEach(unallocated => {
                        this.allocatedToLawFirmHandle.push({
                            data: unallocated,
                            state: {
                                isVisible: true,
                                isSelected: false,
                                isOpen: false
                            }
                        });
                    });
                }
            this.getUnAllocatedAmounts(this.allocatedToLawFirmHandle);
            // this.unAllocatedToLawFirm = this.reportsService.getAllocatedToLawfirm()
            break;
        }
        this.count = this.allocatedToLawFirm.length;
        this.loadingService.hideOverlay();
        // }
        // this.count = this.allocatedToLawFirm.length;
        // this.loadingService.hideOverlay();
} // end selectedTabData()

    toggleCheckbox(data: AllCustomerReportHandle) {
        data.state.isSelected = !data.state.isSelected;
    } // end toggleCheckbox()

    toggleAdHocCheckbox(data: AllCustomerReportHandle) {
        let _sourceData = Object.assign({}, DEFAULT_ALL_CUSTOMER_REPORT);
        _sourceData = this.currentApiData.filter(x => x.invoiceId == data.data.invoiceId)[0];
        if (!data.state.isAdHocSelected) {
            data.state.isAdHocSelected = !data.state.isAdHocSelected;
        } else {
            if (_sourceData.adHocCollections == true) {
                this.onRequestRemovalAdHocMessage = 'You are about to request a removal of the ad-hoc collections on ' + data.data.invoiceNumber + ' for ' + this.debtorName + '. Your Practice Manager will revert with regards to the removal. Do you wish to proceed?';
                this.showRequestRemovalAdHocPrompt = true;
            } else {
                data.state.isAdHocSelected = !data.state.isAdHocSelected;
            }
        }
    } // end toggleAdHocCheckbox()

    onAdHocInvoiceRemove(data: AllCustomerReportHandle) {
        this.adHocInvoiceToBeRemove = Object.assign({}, DEFAULT_ALL_CUSTOMER_REPORT);
        this.onRequestRemovalAdHocMessage = 'You are about to request a removal of the ad-hoc collections on ' + data.data.invoiceNumber + ' for ' + this.debtorName + '. Your Practice Manager will revert with regards to the removal. Do you wish to proceed?';
        this.adHocInvoiceToBeRemove = data.data;
        this.showRequestRemovalAdHocPrompt = true;
    } // end onAdHocInvoiceRemove()

    onRequestRemovalAdHoc() {
        // console.log('SELECTED Invoice to be removed from Ad-Hoc Collection: ', this.adHocInvoiceToBeRemove);
        this.loadingService.showOverlay();
        this.notesService.putRemoveAdHoc(this.adHocInvoiceToBeRemove.invoiceId, this.isPracticeManager).subscribe({ next: (data) => {
            // On next
        },
        error: (error) => {
            // On error
            this.loadingService.hideOverlay();
        },
        complete: () => {
            this.showRequestRemovalAdHocPrompt = false;
            this.getApiData();
            this.loadingService.hideOverlay();
        }
    })
    } // end onRequestRemovalAdHoc()

    //tabDataHandle
    onHideRequestRemovalAdHocPrompt() {
        this.showRequestRemovalAdHocPrompt = false;
    } // end onHideRequestRemovalAdHocPrompt()

    onSendAdHocRequest() {
        this.onRequestAdHocMessage = 'You are about to request collections assistance on an invoice(s) for ' + this.debtorName + '. There will be an 8.5% collection fee charged. Do you wish to proceed?';
        this.showRequestAdHocPrompt = true;
    } // end onSendAdHocRequest()

    toggleSelectAllAdHoc() {
        this.tabDataHandle.forEach(data => {
            if (data.state.isVisible) {
                data.state.isAdHocSelected = this.inputCheckboxSelectAll.nativeElement.checked;
            }
        });
    } // end toggleSelectAllAdHoc()

    async onRequestAdHoc() {
        this.loadingService.showOverlay();
        this.multiInvoiceNote = [];
        this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
        // const firms = JSON.parse(localStorage.getItem(LIST_NAME_LAW_FIRMS));
        const storedData = await this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData');
        const firms = storedData ? storedData.data : null;
        // this.lawfirmName
        let _firm = firms.filter(c => c.ContactID === this.debtorId)[0]; // .FullName;
        if (_firm) {
            this.lawfirmName = _firm.FullName;
        } else {
            this.contactService.getContact(this.debtorId).subscribe({ next: (_next) => {
                // On next
                if (_next) {
                    this.lawfirmName = _next.FullName;
                }
            }})
        }
        this.tabDataHandle.forEach(data => {
            if (data.state.isAdHocSelected) {
                const invoice: MultiInvoiceNote = {
                    Id: 0,
                    InvoiceId: data.data.invoiceId,
                    InvoiceDate: data.data.invoiceDate,
                    InvoiceNo: data.data.invoiceNumber,
                    InvoiceTotal: data.data.invoiceAmount,
                    Due: data.data.due,
                    Paid: data.data.paid,
                    Aging: data.data.daysOverdue === 98 ? 'Overdue' : data.data.daysOverdue.toString(),
                    Attorney: this.lawfirmName,
                    AttorneyRef: data.data.attorneyRef,
                    Matter: data.data.matter,
                    AdvocateStatus: 'Active',
                    PracticeManagerStatus: 'Active',
                    AdHocCollections: data.state.isAdHocSelected,
                    ObjectLinkId: 0
                };
                this.multiInvoiceNote.push(invoice);
            }
        });
        this.noteDetailData.ObjectId = this.debtorId;
        this.instructionResponses = [];
        if (this.userProfileService.userProfile.profileType === this.profileTypes.ADVOCATE) {
            this.notesService.getPMInstructionResponses().subscribe({next: (_next) => {
                this.instructionResponses  = _next.filter(c => c.Id === 174)
            }});
        } else {
            this.notesService.getPMInstructionResponses().subscribe({next: (_next) => {
                this.instructionResponses  = _next; //.filter(c => c.Id === 174)
            }});
        }
        this.getNoteCategories();
        this.loadingService.hideOverlay();
        this.isAdHocCollections = true;
        this.onShowMultiInvoiceNote = true;
        this.showRequestAdHocPrompt = false;
    } // end onRequestAdHoc()

    onHideRequestAdHocPrompt() {
        //let adhocSelected = this.tabDataHandle;
        //adhocSelected =
        this.tabDataHandle.filter(c => c.data.adHocCollections === false && c.state.isAdHocSelected === true).forEach(adhocSelected => {
            // console.log('SELECT tabDataHandle: ', adhocSelected);
            adhocSelected.state.isAdHocSelected = false;
        // if (adhocSelected.data.adHocCollections === this.currentApiData.filter(x => x.invoiceId === adhocSelected.data.invoiceId)[0].adHocCollections) {
        //     adhocSelected.data.adHocCollections = false;
        // } else {
        //   adhocSelected.data.adHocCollections = false;
        // }
      })
        this.showRequestAdHocPrompt = false;

    } // end onHideRequestAdHocPrompt()

    onInvoiceStartDateChange(value: Date): void {
        this.invoiceStartDate = value;
    } // end onInvoiceStartDateChange()

    onInvoiceEndDateChange(value: Date): void {
        if (this.activeTab === 'paid' && this.invoiceStartDate && value) {
            this.tabDataHandle = [];
            this.invoiceEndDate = value;
            this.tabData = this.paidData.filter(data => moment(data.paymentDate).format('YYYY-MM-DD') <= moment(value.toDateString()).format('YYYY-MM-DD') &&
                moment(data.paymentDate).format('YYYY-MM-DD') >= moment(this.invoiceStartDate).format('YYYY-MM-DD'));
            this.tabData.forEach(_data => {
                this.tabDataHandle.push({
                    data: _data,
                    state: {
                        isVisible: true,
                        isOpen: false,
                        isSelected: false,
                        isAdHocSelected: false
                    }
                });
            });
        }
        this.getTotalAmount(this.tabDataHandle);
    } // end onInvoiceEndDateChange()

    onDateFieldRefresh() {
        this.tabData = this.paidData;
    } // end onDateFieldRefresh()

    getTotalAmount(value: any): void {
        this.totalInvoiceAmount = 0;
        this.totalDue = 0;
        this.totalPaid = 0;
        for (const i of value) {
            if (i.state.isVisible) {
                this.totalInvoiceAmount += i.data.invoiceAmount;
                this.totalDue += i.data.due;
                this.totalPaid += i.data.paid;
            }
        }
    } // end getTotalAmount()

    getUnAllocatedAmounts(value: any) {
        this.unAllocatedTTransaction = 0;
        this.unAllocatedTAmount = 0;

        for (const i of value) {
            if (i.state.isVisible) {
                this.unAllocatedTTransaction += i.data.transactionAmount;
                this.unAllocatedTAmount += i.data.amount;
            }
        }
    } // end getUnAllocatedAmounts()

    getDayFontColor(value: number): string {
        if (value > 98) {
            return '#d06553';
        }
    } // end getDayFontColor()

    getDayFontweight(value: number): string {
        if (value > 98) {
            return 'bold';
        }
    } // getDayFontweight()

    getDateFormet(value: Date): string {
        return moment(value).format('DD/MM/YY');
    } // end getDateFormat()

    numberConversion(value: number): string {
        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    } // end numberConversion()

    onViewInvoice(i: any) {
      //  this.invoicesTransaction = i;
      this.loadingService.showOverlay();
      this.selectedTab = 'invoice';
      const invoiceId = i.invoiceId;
      this.notes = [];
      this.notesHandle = [];
      if (invoiceId) {
          let _note = [];
          let _notes = [];
          if (this.isPracticeManager) {
              this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
                invoiceId, 4).subscribe({next: (_next) => {
                    _notes = _next;
                    this.notes = _notes;
                },
                complete: () => {
                    _notes.forEach(_notes => {
                        this.notesHandle.push({
                            data: _notes,
                            state: {
                                isVisible: true,
                                isSelected: false,
                                isOpen: false
                            }
                        });
                    });

                    this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
                    // this.showProformaInvoice = true;
                            this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({next: (_invoice_detail) => {
                                this.invoiceDetails  = _invoice_detail;
                                if (this.invoiceDetails) {
                                    const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                                    if (desc.length > 0) {
                                        this.invoicePlaintiff = desc[0];
                                    }
                                    if (desc.length > 1) {
                                        this.invoiceDefendant = desc[1];
                                    }
                                }
                                this.getAllQuotedFees(this.invoiceDetails.ServiceId);
                            },
                                complete: () => {
                                    // On complete
                                    this.selectedInvoiceId = invoiceId;
                                    this.loadingService.hideOverlay();
                                    this.showProformaInvoice = true;
                                }
                            });
                        }
                    });
            } else if (!this.isPracticeManager) {
                this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
                    invoiceId, 4).subscribe({next: (_next) => {
                        _note = _next;
                    }});
                this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
                // this.showProformaInvoice = true;
                this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({next: (_invoice_detail) => {
                    this.invoiceDetails = _invoice_detail;
                    this.selectedInvoiceId = invoiceId;
                    this.getAllQuotedFees(this.invoiceDetails.ServiceId);
                    if (this.invoiceDetails) {
                            const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                            if (desc.length > 0) {
                                this.invoicePlaintiff = desc[0];
                            }
                            if (desc.length > 1) {
                                this.invoiceDefendant = desc[1];
                            }
                        }
                    },
                    complete: () => {
                        this.showProformaInvoice = true;
                        this.loadingService.hideOverlay();
                    }
                });
            }
            if (!this.isPracticeManager) {
                this.notes = _note.filter(x => x.QuestionText);
            }
                // this.notes = _note;


            // this.notes.forEach(_notes => {
            //     this.notesHandle.push({
            //         data: _notes,
            //         state: {
            //             isVisible: true,
            //             isSelected: false,
            //             isOpen: false
            //         }
            //     });
            // });
        }
    } // end onViewInvoice()

    closeModal() {
        this.getApiData();
        this.showProformaInvoice = false;
    } // end closeModal()

    toggleAscending() {
        this.sortAscending = !this.sortAscending;
        this.orderData();
    } // end toggleAscending()

    toggleAscendingUnallocated() {
        this.sortAscending = !this.sortAscending;
        this.orderUnallocatedData();
    } // end toggleAscendingUnallocated()

    clearSearch() {
        this.searchTerm = '';
        this.performSearch();
    } // end clearSearch()

    performSearch() {
        switch (this.activeTab) {
            case 'overdue':
                if (this.searchTerm) {
                    this.tabDataHandle.forEach(data => {
                        if (
                            !(data.data.due.toString().match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.matter.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceNumber.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.attorney.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.attorneyRef.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceDate.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.totalDaysOutstanding.toString().match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceAmount.toString().match(new RegExp(this.searchTerm, 'gi')))
                        ) {
                            data.state.isVisible = false;
                        } else {
                            data.state.isVisible = true;
                        }
                    });
                    this.getTotalAmount(this.tabDataHandle);
                } else {
                    this.tabDataHandle.forEach(data => {
                        data.state.isVisible = true;
                    });
                    this.getTotalAmount(this.tabDataHandle);
                }
                // this.tabData = this.overdueData.filter(s => s.due > 0).filter((f) => {
                //     return f.matter.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         f.invoiceNumber.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         f.attorney.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         this.getDateFormet(f.invoiceDate) === this.searchTerm ||
                //         f.daysOverdue === this.searchTerm ||
                //         'R' + this.numberConversion(f.invoiceAmount) === this.searchTerm ||
                //         'R' + this.numberConversion(f.due) === this.searchTerm;
                // });
                break;
            case 'current':
                // this.tabData = this.currentData.filter(s => s.due > 0).filter((f) => {
                //     return f.matter.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         f.invoiceNumber.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         f.attorney.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         this.getDateFormet(f.invoiceDate) === this.searchTerm ||
                //         f.daysOverdue === this.searchTerm ||
                //         'R' + this.numberConversion(f.invoiceAmount) === this.searchTerm ||
                //         'R' + this.numberConversion(f.due) === this.searchTerm;
                // });
                if (this.searchTerm) {
                    this.tabDataHandle.forEach(data => {
                        if (
                            !(data.data.due.toString().match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.matter.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceNumber.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.attorney.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.attorneyRef.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceDate.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.totalDaysOutstanding.toString().match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceAmount.toString().match(new RegExp(this.searchTerm, 'gi')))
                        ) {
                            data.state.isVisible = false;
                        } else {
                            data.state.isVisible = true;
                        }
                    });
                    this.getTotalAmount(this.tabDataHandle);
                } else {
                    this.tabDataHandle.forEach(data => {
                        data.state.isVisible = true;
                    });
                    this.getTotalAmount(this.tabDataHandle);
                }
                break;
            case 'paid':
                // this.tabData = this.paidData.filter((f) => {
                //     return f.matter.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         f.invoiceNumber.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         f.attorney.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         this.getDateFormet(f.invoiceDate) === this.searchTerm ||
                //         f.daysOverdue === this.searchTerm ||
                //         'R' + this.numberConversion(f.invoiceAmount) === this.searchTerm ||
                //         'R' + this.numberConversion(f.due) === this.searchTerm;
                // });
                if (this.searchTerm) {
                    this.tabDataHandle.forEach(data => {
                        if (
                            !(data.data.due.toString().match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.matter.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceNumber.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.attorneyRef.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.attorney.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceDate.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.totalDaysOutstanding.toString().match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceAmount.toString().match(new RegExp(this.searchTerm, 'gi')))
                        ) {
                            data.state.isVisible = false;
                        } else {
                            data.state.isVisible = true;
                        }
                    });
                    this.getTotalAmount(this.tabDataHandle);
                } else {
                    this.tabDataHandle.forEach(data => {
                        data.state.isVisible = true;
                    });
                    this.getTotalAmount(this.tabDataHandle);
                }
                break;
            case 'all':
                // this.tabData = this.apiData.filter(s => s.due > 0).filter((f) => {
                //     return f.matter.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         f.invoiceNumber.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         f.attorney.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                //         this.getDateFormet(f.invoiceDate) === this.searchTerm ||
                //         f.daysOverdue === this.searchTerm ||
                //         'R' + this.numberConversion(f.invoiceAmount) === this.searchTerm ||
                //         'R' + this.numberConversion(f.due) === this.searchTerm;
                // });
                if (this.searchTerm) {
                    this.tabDataHandle.forEach(data => {
                        if (
                            !(data.data.due.toString().match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.matter.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceNumber.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.attorney.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.attorneyRef.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceDate.match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.totalDaysOutstanding.toString().match(new RegExp(this.searchTerm, 'gi'))) &&
                            !(data.data.invoiceAmount.toString().match(new RegExp(this.searchTerm, 'gi')))
                        ) {
                            data.state.isVisible = false;
                        } else {
                            data.state.isVisible = true;
                        }
                    });
                    this.getTotalAmount(this.tabDataHandle);
                } else {
                    this.tabDataHandle.forEach(data => {
                        data.state.isVisible = true;
                    });
                    this.getTotalAmount(this.tabDataHandle);
                }
                break;
            // case 'unallocated':
            //     this.tabData = this.allocatedToLawFirmHandle.filter((f) => {
            //         return f.data.matter.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
            //             f.invoiceNumber.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
            //             this.getDateFormet(f.invoiceDate) === this.searchTerm ||
            //             f.daysOverdue === this.searchTerm ||
            //             'R' + this.numberConversion(f.invoiceAmount) === this.searchTerm ||
            //             'R' + this.numberConversion(f.due) === this.searchTerm;
            //     });
            //     this.getTotalAmount(this.tabData);
            // break;
        }
    } // end performSearch()

    performUnallocatedSearch() {
        if (this.searchTerm) {
            this.allocatedToLawFirmHandle.forEach(allocated => {
                if (
                    !(allocated.data.transactionDate.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(allocated.data.description.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(allocated.data.amount.toString().match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(allocated.data.transactionAmount.toString().match(new RegExp(this.searchTerm, 'gi')))
                ) {
                    allocated.state.isVisible = false;
                } else {
                    allocated.state.isVisible = true;
                }
            });
        } else {
            this.allocatedToLawFirmHandle.forEach(allocated => {
                allocated.state.isVisible = true;
            });
        }
        this.getUnAllocatedAmounts(this.allocatedToLawFirmHandle);
    } // end performUnallocatedSearch()

    onKeyDown(event) {
        switch (event.key) {
            case 'Enter':
                this.performSearch();
                break;
        }
    } // end onKeyDown()

    openPaymentDetailModal(invoiceStatement: any) {
        this.paymentDetails = [];
        this.loadingService.showOverlay();

        if (invoiceStatement.paid > 0) {

            const snack: Snack = {
                label: 'Loading payment details..',
                action: null
            };
            this.snackbarsService.snackbarComponent.make(snack).show();

            // Fetch payment details for selected invoice.
            this.paymentDetailService.getPaymentDetail(invoiceStatement.invoiceId)
                .subscribe({ next: (invoice_detail) => {
                    this.paymentDetails = invoice_detail;
                    this.selectedInvoice = invoiceStatement;
                    this.showPaymentDetailModal = true;
                    this.noPayment = true;
                },
                    error: (error) => {
                        // On error
                        snack.label = 'An error loading payment details.';
                        snack.type = SnackType.ERROR;
                        this.snackbarsService.dismiss().make(snack).show();
                        this.loadingService.hideOverlay();
                    },
                    complete: () => {
                        // On complete
                        snack.label = 'Payment details loaded';
                        snack.type = SnackType.SUCCESS;
                        this.snackbarsService.dismiss().make(snack).show();
                        this.loadingService.hideOverlay();
                    }
                });
        } else {
            this.loadingService.hideOverlay();
            this.noPayment = false;
            if (invoiceStatement) {
                const p_detail: PaymentDetail = {
                    InvoiceAmount: 0,
                    InvoiceNo: invoiceStatement.invoiceNo,
                    Amount: 0,
                    Outstanding: invoiceStatement.due,
                    PaymentDate: '',
                    Reference: '',
                    Split: '',
                    Status: 'No Payment',
                    TransactionAmount: 0
                };
                this.selectedInvoice = invoiceStatement;
                this.paymentDetails.push(p_detail);
                this.showPaymentDetailModal = true;
            }
        }
        // this.selectedInvoiceStatement = [];
        // this.lines.forEach(line => {
        //   if (line.InvoiceNo === invoiceStatement.InvoiceNo) {
        //     this.selectedInvoiceStatement.push(line);
        //   }
        // });
    } // end openPaymentDetailModal()

    closePaymentDetailModal() {
        this.showPaymentDetailModal = false;
    } // end closePaymentDetailModal()

    savePaymentDetail(statement) {
        this.showPaymentDetailModal = false;
    } // end savePaymentDetail()

    orderData() {
        const Moment = require('moment');
        switch (this.sortData) { // Determines which list of messages to order
            case 'invoiceDate':
                if (this.sortAscending === false) {
                    this.tabDataHandle = this.tabDataHandle.sort((a, b) => new Moment(b.data.invoiceDate) - new Moment(a.data.invoiceDate));
                } else {
                    this.tabDataHandle = this.tabDataHandle.sort((a, b) => new Moment(a.data.invoiceDate) - new Moment(b.data.invoiceDate));
                }
                break;
            case 'matter':
                this.tabDataHandle
                    .sort((a, b) => {
                        if (this.sortAscending) {
                            return a.data
                                .matter
                                .toUpperCase()
                                .localeCompare(b.data.matter.toUpperCase());
                        }

                        return b.data.matter
                            .toUpperCase()
                            .localeCompare(a.data.matter.toUpperCase());
                    });
                break;
            case 'attorney':
                this.tabDataHandle
                    .sort((a, b) => {
                        if (this.sortAscending) {
                            return a.data
                                .attorney
                                .toUpperCase()
                                .localeCompare(b.data.attorney.toUpperCase());
                        }

                        return b.data.attorney
                            .toUpperCase()
                            .localeCompare(a.data.attorney.toUpperCase());
                    });
                break;
            case 'invoiceNumber':
                this.tabDataHandle
                    .sort((a, b) => {
                        if (this.sortAscending) {
                            return a.data
                                .invoiceNumber
                                .toUpperCase()
                                .localeCompare(b.data.invoiceNumber.toUpperCase());
                        }
                        return b.data.invoiceNumber
                            .toUpperCase()
                            .localeCompare(a.data.invoiceNumber.toUpperCase());
                    });
                break;
            case 'daysOverdue':
                if (this.sortAscending === false) {
                    this.tabDataHandle = this.tabDataHandle.sort((a, b) => b.data.daysOverdue - a.data.daysOverdue);
                } else {
                    this.tabDataHandle = this.tabDataHandle.sort((a, b) => a.data.daysOverdue - b.data.daysOverdue);
                }
                break;
            case 'due':
                if (this.sortAscending === false) {
                    this.tabDataHandle = this.tabDataHandle.sort((a, b) => b.data.due - a.data.due);
                } else {
                    this.tabDataHandle = this.tabDataHandle.sort((a, b) => a.data.due - b.data.due);
                }
                break;
            case 'invoiceAmount':
                if (this.sortAscending === false) {
                    this.tabDataHandle = this.tabDataHandle.sort((a, b) => b.data.invoiceAmount - a.data.invoiceAmount);
                } else {
                    this.tabDataHandle = this.tabDataHandle.sort((a, b) => a.data.invoiceAmount - b.data.invoiceAmount);
                }
                break;
        } // end switch()
    } // end orderData()

    orderUnallocatedData() {
        const Moment = require('moment');
        switch (this.sortData) { // Determines which list of messages to order
            case 'sortUnallocatedDate':
                if (this.sortAscending === false) {
                    this.allocatedToLawFirmHandle.sort((a, b) =>
                        new Moment(b.data.transactionDate) - new Moment(a.data.transactionDate));
                } else {
                    this.allocatedToLawFirmHandle.sort((a, b) =>
                        new Moment(a.data.transactionDate) - new Moment(b.data.transactionDate));
                }
                break;
            case 'sortUnallocatedDescription':
                this.allocatedToLawFirmHandle
                    .sort((a, b) => {
                        if (this.sortAscending) {
                            return a
                                .data.description
                                .toUpperCase()
                                .localeCompare(b.data.description.toUpperCase());
                        }

                        return b.data.description
                            .toUpperCase()
                            .localeCompare(a.data.description.toUpperCase());
                    });
                // this.allocatedToLawFirmHandle = this.allocatedToLawFirmHandle;
                break;
            case 'sortUnallocatedTransactionAmount':
                if (this.sortAscending === false) {
                    this.allocatedToLawFirmHandle.sort((a, b) => b.data.transactionAmount - a.data.transactionAmount);
                } else {
                    this.allocatedToLawFirmHandle.sort((a, b) => a.data.transactionAmount - b.data.transactionAmount);
                }
                break;
            case 'sortUnallocatedAmount':
                if (this.sortAscending === false) {
                    this.allocatedToLawFirmHandle.sort((a, b) => b.data.amount - a.data.amount);
                } else {
                    this.allocatedToLawFirmHandle.sort((a, b) => a.data.amount - b.data.amount);
                }
                break;

        } // end switch()
    } // end orderUnallocatedData()

    getCellColor(data: any): string {
        if (data.attorney === 'N/A') {
            return 'transparent';
        }
    } // end getCellColor()

    incomingMessage(data: any[]): boolean {
     let unOpenedMessage = false;
     data.forEach(s => {
         if (s.userCreatorStatus === false) {
             unOpenedMessage = true;
         }
     });
     return unOpenedMessage;
    } // end incomingMessage()

    incomingAdvMessage(data: any[]): boolean {
     let unOpenedMessage = false;
     data.forEach(s => {
         if (s.userAssignedStatus === false && (s.externalText || s.questionText)) {
             unOpenedMessage = true;
         }
     });
     return unOpenedMessage;
    } // end incomingAdvMessage()

    hasAdvMessage(data: any[]): boolean {
     return data.find(x => x.personal === false);
    } // end hasAdvMessage()

    hasIncomingMessage(data: any[]): boolean {
     let hasMessage = false;
     if (data) {
        hasMessage = true;
     }
     return hasMessage;
    } // end hasIncomingMessage()

    onNotesOpen(data: any[], invoiceId: number) {
        this.notesHandle = [];
        this.loadingService.showOverlay();
        this.selectedTab = 'conversation';
        if (invoiceId) {
            let _notes = [];
            if (this.isPracticeManager) {
                this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
                    invoiceId, 4).subscribe({next: (_next) => {
                        _notes = _next;
                        this.notes = _notes;
                    },
                    complete: () => {
                        _notes.forEach(_notes => {
                            this.notesHandle.push({
                                data: _notes,
                                state: {
                                    isVisible: true,
                                    isSelected: false,
                                    isOpen: false
                                }
                            });
                        });

                        this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
                        // this.showProformaInvoice = true;
                        this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({next: (_invoice_detail) => {
                            this.invoiceDetails  = _invoice_detail;
                            if (this.invoiceDetails) {
                                const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                                if (desc.length > 0) {
                                    this.invoicePlaintiff = desc[0];
                                }
                                if (desc.length > 1) {
                                    this.invoiceDefendant = desc[1];
                                }
                            }
                            this.getAllQuotedFees(this.invoiceDetails.ServiceId);
                        }});
                        this.selectedInvoiceId = invoiceId;
                        this.loadingService.hideOverlay();
                        this.showProformaInvoice = true;
                    }
                });
            } else if (!this.isPracticeManager) {
                this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
                    invoiceId, 4).subscribe({next: (_next) => {
                        _notes = _next;
                        this.notes = _notes;
                    },
                    error: (error) => {
                        // On error
                    },
                    complete: () => {
                        // On complete
                        _notes.forEach(_notes => {
                            this.notesHandle.push({
                                data: _notes,
                                state: {
                                    isVisible: true,
                                    isSelected: false,
                                    isOpen: false
                                }
                            });
                        });

                        this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
                        // this.showProformaInvoice = true;
                        this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({next: (_invoice_detail) => {
                            this.invoiceDetails  = _invoice_detail;
                            if (this.invoiceDetails) {
                                const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                                if (desc.length > 0) {
                                    this.invoicePlaintiff = desc[0];
                                }
                                if (desc.length > 1) {
                                    this.invoiceDefendant = desc[1];
                                }
                            }
                            this.getAllQuotedFees(this.invoiceDetails.ServiceId);
                        }});
                        this.selectedInvoiceId = invoiceId;
                        this.loadingService.hideOverlay();
                        this.showProformaInvoice = true;
                    }
                });
            }
            // if (!this.isPracticeManager) {
            //     this.notes = _notes; // .filter(x => x.QuestionText);
            // } else {
            //     this.notes = _notes;
            // }
        }
    } // end onNotesOpen()

    toggleNoteCheckbox() {

    } // end toggleNoteCheckbox()

    closeInvoiceNoteModal() {
        this.showInvoiceNoteDetail = false;
    } // end closeInvoiceNoteModal()

    onAddNewNote(invoiceId: number) {
        this.loadingService.showOverlay();
        this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
        this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
        this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({next: (_next) => {
            this.invoiceDetails = _next;
        },
        error: (error) => {
            // On error
            this.loadingService.hideOverlay();
        },
        complete: () => {
            // On complete
            if (this.invoiceDetails) {
                const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                if (desc.length > 0) {
                    this.invoicePlaintiff = desc[0];
                }
                if (desc.length > 1) {
                    this.invoiceDefendant = desc[1];
                }
                this.noteDetailData.ObjectId = this.invoiceDetails.Id;
                this.noteDetailData.ObjectTypeDescription = this.invoiceDetails.InvoiceNo;
                this.noteDetailData.CaptureDate = moment().toString();
            }
                this.getNoteCategories();
                this.instructionResponses = [];
                this.notesService.getPMInstructionResponses().subscribe({next: (_next) => {
                    this.instructionResponses = _next;
                }});
                this.objectType = 'invoice';
                this.onShowNoteDetail = true;
                this.loadingService.hideOverlay();
            }
        });
    } // end onAddNewNote()

    onInstructionIssued(note: Notes) {
        note.NoteTypeId = 306;
        this.loadingService.showOverlay();
        this.notesService.postInvoiceInstructionNote(note).subscribe({ next: (added_note) => {
            // On next
          },
          error: (error) => {
            // On Error
            this.loadingService.hideOverlay();
          },
          complete: () => {
            // On complete
            this.loadingService.hideOverlay();
            this.getApiData();
            this.onShowNoteDetail = false;
          }
        });
      } // end onInstructionIssued();

    onNoteSave(note: Notes) {
        this.loadingService.showOverlay();
        this.notesService.postNote(note).subscribe({
            next: (added_note) => {
                    // On next
            },
            error: (error) => {
                // On Error
                this.loadingService.hideOverlay();
            },
            complete: () => {
                // On complete
                this.loadingService.hideOverlay();
                this.getApiData();
                this.onShowNoteDetail = false;
        }
    });
    } // end onNoteSave()

    getNoteCategories() {
        this.notesService.getNoteCategory().subscribe({next: (_next) => {
            this.noteCategory = _next;
            this.noteCategory = this.noteCategory.filter(x => x.ObjectTypeId === 4);
            this.noteCategory.sort((a, b) => {
                return a.Category.toUpperCase().localeCompare(b.Category.toUpperCase());
            });
        }});
        // Filter Catefories to only display Invoice ObjectType.
    } // end getNoteCategories()

    onDownloadMultipleInvoices() {
        this.loadingService.showOverlay();
        let invoiceIds: number[] = [];
        let Invoices = this.tabDataHandle.filter(a => a.state.isSelected === true);
        Invoices.forEach(data => {
            invoiceIds.push(data.data.invoiceId);
        });
        this.invoiceDocumentService.getMultipleInvoiceDocument(invoiceIds).subscribe({ next: (invoiceDocs: Blob) => {
            const url = window.URL.createObjectURL(invoiceDocs);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'invoices.zip'; // Assuming the API returns a zip file
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(url);
            anchor.remove();
        }, 
            error:(error) => {
                // On error
                console.log('ERROR: ', error);
                this.loadingService.hideOverlay();
            },
            complete: () => {
                // on complete
                this.tabDataHandle.filter(a => a.state.isSelected).forEach(s => {
                    s.state.isSelected = false;
                });
                this.loadingService.hideOverlay();
            }
        });
    } // end onDownloadMultipleInvoices()

   async onWriteOff() {
        this.loadingService.showOverlay();
        let writeOffData = this.tabDataHandle.filter(a => a.state.isSelected === true);
        if (writeOffData) {
            this.toWriteOffTransactionLine = [];
           for await (const e of writeOffData) {
                let data: InvoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
                // if(e.state.isSelected) {
                    data.Id = e.data.invoiceId
                    data.InvoiceDate = e.data.invoiceDate;
                    data.InvoiceNo = e.data.invoiceNumber;
                    data.InvoiceTotal = e.data.invoiceAmount;
                    data.TotalPaidAmount = e.data.paid;
                    data.OutstandingBalance = e.data.due;

                    this.toWriteOffTransactionLine.push(data);
                // }
            };
            this.loadingService.hideOverlay();
            this.onShowWriteOffModal = true;
        }
    } // end onWriteOff()

    onCloseWriteoff() {
        this.onShowWriteOffModal = false;
    } // end onCloseWriteoff()

    onSaveWriteOff() {
        this.getApiData();
        this.onShowWriteOffModal = false;
    } // end onSaveWriteOff()

    async onSendMultiInvoiceNote() {
        this.loadingService.showOverlay();
        this.multiInvoiceNote = [];
        this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
        // const firms = JSON.parse(localStorage.getItem(LIST_NAME_LAW_FIRMS));
        const storedData = await this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData');
        const firms = storedData ? storedData.data : null;
        // this.lawfirmName
        let _firm = firms.filter(c => c.ContactID === this.debtorId)[0]; // .FullName;
        if (_firm) {
            this.lawfirmName = _firm.FullName;
        } else {
            this.contactService.getContact(this.debtorId).subscribe({ next: (_next) => {
                // On next
                if (_next) {
                    this.lawfirmName = _next.FullName;
                }
            }})
        }
        this.tabDataHandle.forEach(data => {
            if (data.state.isSelected) {
                const invoice: MultiInvoiceNote = {
                    Id: 0,
                    InvoiceId: data.data.invoiceId,
                    InvoiceDate: data.data.invoiceDate,
                    InvoiceNo: data.data.invoiceNumber,
                    InvoiceTotal: data.data.invoiceAmount,
                    Due: data.data.due,
                    Paid: data.data.paid,
                    Aging: data.data.daysOverdue === 98 ? 'Overdue' : data.data.daysOverdue.toString(),
                    Attorney: data.data.attorney,
                    AttorneyRef: data.data.attorneyRef,
                    Matter: data.data.matter,
                    AdvocateStatus: 'Active',
                    PracticeManagerStatus: 'Active',
                    AdHocCollections: data.data.adHocCollections,
                    ObjectLinkId: 0
                };
                this.multiInvoiceNote.push(invoice);
            }
        });
        this.noteDetailData.ObjectId = this.debtorId;
        this.instructionResponses = [];
        this.notesService.getPMInstructionResponses().subscribe({next: (_next) => {
            this.instructionResponses  = _next;
        }});
        this.getNoteCategories();
        this.loadingService.hideOverlay();
        this.onShowMultiInvoiceNote = true;
    } // end onSendMultiInvoiceNote()

    onShowMultiInvoiceNoteModalDismiss() {
      this.tabDataHandle.filter(c => c.data.adHocCollections === true).forEach(adhocSelected =>{
        adhocSelected.data.adHocCollections = false;
      })
        this.onShowMultiInvoiceNote = false;
    } // end onShowMultiInvoiceNoteModalDismiss()

    onShowNoteDetailModalDismiss() {
        this.onShowNoteDetail = false;
    } // end onShowNoteDetailModalDismiss()

    OnAddResolution(note: Notes) {

    } // end OnAddResolution()

    onSaveMultiNote(note: MultiObjectNote) {
        // this.loadingService.showOverlay();
         this.notesService.postMultipeNote(note).subscribe({next: (_save) => {},
        complete: () =>{
          this.getApiData();
        this.onShowMultiInvoiceNote = false;
        }});
        this.getApiData();
        this.onShowMultiInvoiceNote = false;
    } // end onSaveMultiNote()

    onSaveInstructionOnMultiInvoice(note: MultiObjectNote) {
        if (this.isPracticeManager && this.isAdHocCollections == true) {
            // console.log('IS THIS PM AD-HOC COLLECTION REQUEST TRUE?', this.isAdHocCollections);
                this.notesService.approveAdHocInstructionNote(note).subscribe({next: (_save) => {},
                    complete: () => {

                    this.getApiData();
                    this.onShowMultiInvoiceNote = false;
                    }
                });
        } else {
            if (this.isAdHocCollections == true) {
                // console.log('IS THIS ADVOCATE AD-HOC COLLECTION REQUEST TRUE?', this.isAdHocCollections);
                this.notesService.postAdHocInstructionNote(note, this.isAdHocCollections).subscribe({next: (_save) => {},
                    complete: () => {

                    this.getApiData();
                    this.onShowMultiInvoiceNote = false;
                    }
                });
            } else {
                // console.log('IS THIS AD-HOC COLLECTION REQUEST FALSE?', this.isAdHocCollections);
                this.notesService.postInstructionOnMultipeNote(note).subscribe({next: (_save) => {},
                    complete: () => {

                    this.getApiData();
                    this.onShowMultiInvoiceNote = false;
                    }
                });
            }
        }
        //  complete: () => {this.getApiData();
        //   this.onShowMultiInvoiceNote = false;}

    } // end onSaveInstructionOnMultiInvoice()

    getAllQuotedFees(serviceId: number) {
        this.feesQuoted = [];
        this.quotationService.getActualFeesQuoted(serviceId).subscribe({next: (_next) => {
            this.feesQuoted = _next;
        }});
    } // end getAllQuotedFees()

    toggleDiscountingCheckbox(invoice: AllCustomerReport) {
        // this.isDiscounting = !this.isDiscounting;
        invoice.discounting = !invoice.discounting;
        this.discountingInvoice = Object.assign({}, DEFAULT_ALL_CUSTOMER_REPORT);
        this.discountingInvoice = invoice;
        if (invoice.discounting) {
            this.showDiscounting = true;
        } else {
            this.showDisableDiscountingPrompt = true;
        }
    } // end toggleDiscountingCheckbox()

    onSaveDiscounting(data) {
        data.discountingDueDate = moment(data.discountingDueDate).format('YYYY-MM-DD HH:mm:ss');
        this.loadingService.showOverlay();
        this.reportsService.putDiscountingInvoice(data).subscribe({
            next: (_data) => {
                // On next
            },
            error: (error) => {
                // On error
                this.loadingService.hideOverlay();
                this.showDiscounting = false;
            },
            complete: () => {
                // On complete
                setTimeout(() => {
                    if (this.debtorId > 0) {
                        this.getApiData();
                    }
                }, 500);
                this.loadingService.hideOverlay();
                this.showDiscounting = false;
        }
    });
    } // end onSaveDiscounting()

    getBackgroundColor(data): string {
        if (data.discounting && this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER) {
            return '#EFD966';
        }
    } // end getBackgroundColor()

    getADHOCBackgroungColor(data): string{
      if(data.data.adHocCollections === true){
        return '#ccccff'}
    }

    onCancelDiscounting() {
        this.getApiData();
        this.discountingInvoice = Object.assign({}, DEFAULT_ALL_CUSTOMER_REPORT);
        this.showDiscounting = false;
    } // end onCancelDiscounting()

    onHideDisableDiscountingPrompt() {
        // this.tabDataHandle.filter(s => s.data === this.discountingInvoice)
        this.showDisableDiscountingPrompt = false;
    } // end onHideDisableDiscountingPrompt()

    onDisableDiscounting() {
        this.discountingInvoice.discounting = false;
        this.discountingInvoice.discountingDueDate = moment(moment().format()).format('YYYY-MM-DD HH:mm:ss');
        this.loadingService.showOverlay();
        this.reportsService.putDiscountingInvoice(this.discountingInvoice).subscribe({
            next: (_data) => {
                // On next
            },
            error: (error) => {
                // On error
                this.loadingService.hideOverlay();
                this.showDisableDiscountingPrompt = false;
            },
            complete: () => {
                // On complete
                setTimeout(() => {
                    if (this.debtorId > 0) {
                        this.getApiData();
                    }
                }, 500);
                this.discountingInvoice = Object.assign({}, DEFAULT_ALL_CUSTOMER_REPORT);
                this.loadingService.hideOverlay();
                this.showDisableDiscountingPrompt = false;
        }
    });
    } // end onDisableDiscounting();
}
