import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { catchError, retry } from 'rxjs/operators';

export interface StickyNotes {
  Id: number;
  CaptureDate: string;
  ActionDate: string;
  ClientId: number;
  NoteLoggedById: number;
  NoteLoggedBy: string;
  NoteSubject: string;
  Note: string;
  NoteLoggedByProfileType: string;
}

export const DEFAULT_STICKY_NOTES: StickyNotes = {
  Id: 0,
  CaptureDate: '',
  ActionDate: '',
  ClientId: 0,
  NoteLoggedById: 0,
  NoteLoggedBy: '',
  NoteSubject: '',
  Note: '',
  NoteLoggedByProfileType: '',
};

export interface StickyNoteCategory {
  Id: number;
  Category: string;
}

export const DEFAULT_STICKY_NOTE_CATEGORY: StickyNoteCategory = {
  Id: 0,
  Category: ''
};

export interface StickyNoteTypes {
  Id: number;
  Name: string;
  Description: string;
  CategoryId: number;
}

export const DEFAULT_STICKY_NOTE_TYPE: StickyNoteTypes = {
  Id: 0,
  Name: '',
  Description: '',
  CategoryId: 0
};

export interface StickyNoteHandle {
  data: StickyNotes;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

@Injectable()
export class StickyNoteService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  getStickyNotes(): Observable<any> {
    return this.http.get(this.api.endpoints.stickyNotes +
      '/StickyNotes?ServiceProvider=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getStickNotes()

  getStickyNoteCategory(): Observable<any> {
    return this.http.get(this.api.endpoints.stickyNotes +
      '/StickyNoteCategory', this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getStickyNoteCategory()

  getStickyNoteType(categoryId: number): Observable<any> {
    return this.http.get(this.api.endpoints.stickyNotes +
      '/StickyNoteType?categoryId=' + categoryId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getStickyNotetype()

  postStickyNote(data: StickyNotes): Observable<any> {
    return this.http.post<StickyNotes>(this.api.endpoints.stickyNotes +
      '/AddStickyNote', data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end postStickyNote()
} // end StickyNoteService{}
