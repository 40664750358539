import { Component, OnInit } from '@angular/core';
import {
  Contacts,
  ContactsHandle,
  ContactsService,
  DEFAULT_CONTACTS
} from '../../../services/contacts/contacts.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Snack, SnackType, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import * as moment from 'moment';

@Component({
  selector: 'app-advocates',
  templateUrl: './advocates.component.html',
  styleUrls: ['./advocates.component.scss']
})
export class AdvocatesComponent implements OnInit {

  row = 0;
  searchTerm: string;
  sortBy: string;
  previousSortBy: string;
  sortAscending: boolean;
  sortAdvocateName: boolean;
  sortAdvocateUserName: boolean;
  sortCell: boolean;
  sortBar: boolean;
  sortEmail: boolean;
  sortAdmissionDate: boolean;
  sortPostalAddress: boolean;
  sortStreetAddress: boolean;

  showAddEditAdvocate: boolean;
  advocates: Contacts[] = [];
  advocatesHandle: ContactsHandle[] = [];
  selectedAdvocate: Contacts = DEFAULT_CONTACTS;
  showDeleteAdvPopPrompt: boolean;
  isDeleteInPeogress: boolean = false;
  advocateId: number;
  regionalBarId: number;
  AdvocateFullName: string;

  constructor(
    private loadingService: LoadingService,
    private contactService: ContactsService,
    private snackbarsService: SnackbarsService,
  ) { } // end constructor()

  ngOnInit(): void {
    this.getAllAdvocates();
  } // end ngOnInit()

  getAllAdvocates() {
    this.advocates = [];
    this.advocatesHandle = [];
    this.loadingService.showOverlay();
    this.contactService.getAllContactsByContactType(2).subscribe({ next: (data) => {
      // On next
      this.advocates = data.result;
      // console.log('ADVOCATES: ', this.advocates);
      if (this.advocates) {
        this.advocates.forEach(firm => {
          if(firm.AdmissionYear || firm.AdmissionMonth || firm.AdmissionDay) {
            // let admissionDate =  new Date('firm.AdmissionYear' + '-' + 'firm.AdmissionMonth' + '-' + 'firm.AdmissionDay');
            //moment({ day: firm.AdmissionDay, month: firm.AdmissionMonth, year: firm.AdmissionYear });
            // console.log('ADMISSION DATE: ', moment(admissionDate).format('YYYY-MM-DD'))
            firm.AdmissionDate = moment(firm.AdmissionYear + '/' + firm.AdmissionMonth + '/' + firm.AdmissionDay).format('YYYY-MM-DD');
          }
          if (!firm.RegionalBarName)
              firm.RegionalBarName = '-';
          this.advocatesHandle.push({
            data: firm,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          });
        });
      }
    },
    complete: () => {
      // On complete
      this.loadingService.hideOverlay();
    }
  })
  } // end getAllAdvocates()

  performSearch() {
    if (this.searchTerm) {
      this.advocatesHandle.forEach(firm => {
        if (
          !(firm.data.FullName.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(firm.data.RegionalBarName.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          firm.state.isVisible = false;
        } else {
          firm.state.isVisible = true;
        }
      });
    } else {
      this.advocatesHandle.forEach(firm => {
        firm.state.isVisible = true;
      });
    }
  } // end performSearch()

  clearSearch() {
    this.searchTerm = '';
    this.performSearch();
  } // end clearSearch()

  toggleSort(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch (this.sortBy) {
      case 'advocate-name':
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = true;
            this.sortAdvocateUserName = false;
            this.sortCell = false;
            this.sortBar = false;
            this.sortEmail = false;
            this.sortAdmissionDate = false;
            this.sortPostalAddress = false;
            this.sortStreetAddress = false;
            return a.data.FullName.toUpperCase().localeCompare(b.data.FullName.toUpperCase());
          }
          this.sortAdvocateName = false;
          return b.data.FullName.toUpperCase().localeCompare(a.data.FullName.toUpperCase());
        });
        break;

      case 'user-name':
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = false;
            this.sortAdvocateUserName = true;
            this.sortCell = false;
            this.sortBar = false;
            this.sortEmail = false;
            this.sortAdmissionDate = false;
            this.sortPostalAddress = false;
            this.sortStreetAddress = false;
            return a.data.UserName.toUpperCase().localeCompare(b.data.UserName.toUpperCase());
          }
          this.sortAdvocateUserName = false;
          return b.data.UserName.toUpperCase().localeCompare(a.data.UserName.toUpperCase());
        });
        break;

      case 'cell':
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = false;
            this.sortAdvocateUserName = false;
            this.sortCell = true;
            this.sortBar = false;
            this.sortEmail = false;
            this.sortAdmissionDate = false;
            this.sortPostalAddress = false;
            this.sortStreetAddress = false;
            return a.data.Cell.toUpperCase().localeCompare(b.data.Cell.toUpperCase());
          }
          this.sortCell = false;
          return b.data.Cell.toUpperCase().localeCompare(a.data.Cell.toUpperCase());
        });
        break;

      case 'bar-society':
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = false;
            this.sortAdvocateUserName = false;
            this.sortCell = false;
            this.sortBar = true;
            this.sortEmail = false;
            this.sortAdmissionDate = false;
            this.sortPostalAddress = false;
            this.sortStreetAddress = false;
            return a.data.RegionalBarName.toUpperCase().localeCompare(b.data.RegionalBarName.toUpperCase());
          }
          this.sortBar = false;
          return b.data.RegionalBarName.toUpperCase().localeCompare(a.data.RegionalBarName.toUpperCase());
        });
        break;

      case 'email':
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = false;
            this.sortAdvocateUserName = false;
            this.sortCell = false;
            this.sortBar = false;
            this.sortEmail = true;
            this.sortAdmissionDate = false;
            this.sortPostalAddress = false;
            this.sortStreetAddress = false;
            return a.data.Fax.toUpperCase().localeCompare(b.data.Fax.toUpperCase());
          }
          this.sortEmail = false;
          return b.data.Fax.toUpperCase().localeCompare(a.data.Fax.toUpperCase());
        });
        break;

      case 'admission-date':
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = false;
            this.sortAdvocateUserName = false;
            this.sortCell = false;
            this.sortBar = false;
            this.sortEmail = false;
            this.sortAdmissionDate = true;
            this.sortPostalAddress = false;
            this.sortStreetAddress = false;
            return a.data.AdmissionDate.toUpperCase().localeCompare(b.data.AdmissionDate.toUpperCase());
          }
          this.sortAdmissionDate = false;
          return b.data.AdmissionDate.toUpperCase().localeCompare(a.data.AdmissionDate.toUpperCase());
        });
        break;

      case 'postal-address':
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = false;
            this.sortAdvocateUserName = false;
            this.sortCell = false;
            this.sortBar = false;
            this.sortEmail = false;
            this.sortAdmissionDate = false;
            this.sortPostalAddress = true;
            this.sortStreetAddress = false;
            return a.data.PostalAddress.toUpperCase().localeCompare(b.data.PostalAddress.toUpperCase());
          }
          this.sortPostalAddress = false;
          return b.data.PostalAddress.toUpperCase().localeCompare(a.data.PostalAddress.toUpperCase());
        });
        break;

      case 'street-address':
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortAdvocateName = false;
            this.sortAdvocateUserName = false;
            this.sortCell = false;
            this.sortBar = false;
            this.sortEmail = false;
            this.sortAdmissionDate = false;
            this.sortPostalAddress = false;
            this.sortStreetAddress = true;
            return a.data.StreetAddress.toUpperCase().localeCompare(b.data.StreetAddress.toUpperCase());
          }
          this.sortStreetAddress = false;
          return b.data.StreetAddress.toUpperCase().localeCompare(a.data.StreetAddress.toUpperCase());
        });
        break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

  onAdvocateRowClick(advocate: Contacts) {

  } // end onAdvocateRowClick()
  
  onEditAdvocate(advocate: Contacts) {
    // let admissionDate = new Date(advocate.AdmissionYear, advocate.AdmissionMonth, advocate.AdmissionDay, 5, 0, 0, 0);
    console.log('SELECTED ADVOCATE: ', advocate);
    this.selectedAdvocate = advocate;
    this.showAddEditAdvocate = true;
  } // end onEditAdvocate()

  addNewContact() {
    this.selectedAdvocate = {
      AccountHolder: '',
      AccountNumber: '',
      AccountType: '',
      BranchCode: '',
      Cell: '',
      CollectThreshold: '',
      ContactContactType: 0,
      Locality: '',
      ContactLocality: 0,
      DefaultDailyRate: 0,
      DefaultHourlyRate: 0,
      DefaultPerKilometerRate: 0,
      Docex: '',
      Email: '',
      Fax: '',
      FeeTarget: 0,
      FullName: '',
      Id: 0,
      Identity: '',
      Image: '',
      IncomeTarget: 0,
      LastStatementDownload: '',
      Level: '',
      OfficeRoom: '',
      Phone: '',
      PostalAddress: '',
      SecurityLinks: '',
      Speciality: '',
      Status: '',
      StreetAddress: '',
      Suburb: '',
      ToCollect: 0,
      UserName: '',
      Vatno: '',
      WebSite: '',
      ZipCode: '',
      IsSelected: false,
      AdmissionDay : null,
      AdmissionMonth : null,
      AdmissionYear : null,
      AdmissionDate: null,
      AdmissionDateStr: '',
      RegionalBarId: 0,
      RegionalBarName: '',
    };
    this.showAddEditAdvocate = true;
  } // end addNewContact()

  onCloseModal(event: string) {
    switch (event) {
      case 'advocate':
        this.showAddEditAdvocate = false;
        break;
    }
  } // end onCloseModal()

  onSaveAdvocate(event) {
    this.getAllAdvocates();
    this.showAddEditAdvocate = false;
  } // end onSaveAdvocate()

  onDeleteAdvPopPromptShow(Id: number, AdvocateFullName: string = '', RegionalBarId : number) {
    this.showDeleteAdvPopPrompt = true;
    this.advocateId = Id;
    this.regionalBarId = RegionalBarId;
    this.AdvocateFullName = AdvocateFullName.split(" ")[0] != 'Advocate' ? 'Advocate ' + AdvocateFullName : this.AdvocateFullName = AdvocateFullName;
  }
  onHideDeleteAdvPopPrompt() {
    this.showDeleteAdvPopPrompt = false;
  } // end onHideDeletePopPrompt()

  onDeleteAdvocate() {
    if (this.isDeleteInPeogress)
      return;
    this.isDeleteInPeogress = true
    this.contactService.onDeleteAdvocate(this.advocateId, this.regionalBarId)
      .subscribe({
        next: (response) => {
          this.onHideDeleteAdvPopPrompt();
          this.getAllAdvocates();
          this.isDeleteInPeogress = false;
        },
      })
  }

} // end AdvocatesComponent {}
