import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService, httpOptions } from '../api.service';
import { retry, catchError } from 'rxjs/operators';
import { Fee, DEFAULT_FEE } from '../fee-items/fee-items.service';
import { Matter, DEFAULT_MATTER } from '../matters/matters.service';

export interface MultipleFee {
  FeeItem: Fee;
  Services: Matter[];
}

export const DEFAULT_MULTIPLE_FEE = {
  FeeItem: Object.assign({}, DEFAULT_FEE),
  Services: [Object.assign({}, DEFAULT_MATTER)],
};

@Injectable()
export class MultipleFeesService {
  multipleFee: MultipleFee;
  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end Constructor

  /**
   * POST: creates FeeItems for every Matter.
   * This object contains data for the FeeItem being captured.
   * This data originates in Add multiple fees.
   * @param {MultipleFee} fee The MultipleFee object.
   * @returns {Observable} Returns an observable.
   */
  createFee(multipleFee: MultipleFee): Observable<any> {
    return this.http.post(this.api.endpoints.multipleFees,
      multipleFee, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end createFee()
}
