import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TAG_ADD_TEAM_MEMBER } from '../../../services/tags/tags.service';
import { Assistant, DEFAULT_ASSISTANT, AddTeamMemberService } from '../../../services/addteam/add-team-member.service';
import { ToastService } from '../../../services/messaging/toast/toast.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import * as moment from 'moment';
import { DEFAULT_ACTIVITY_LOGS, ActivityLogs, ActivityLogsService } from '../../../services/activity-logs/activity-logs.service';

@Component({
  selector: 'app-add-team-member-modal',
  templateUrl: './add-team-member-modal.component.html',
  styleUrls: ['./add-team-member-modal.component.scss']
})
export class AddTeamMemberModalComponent implements OnInit, OnChanges {
  title = '';
  saveBtn: string;

  @Input() isVisible: boolean;
  @Input() teamMember: Assistant;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  userrole: any;
  selectedRole = '';

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  constructor(
    private util: UtilitiesService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private addTeamMemberService: AddTeamMemberService,
  ) { }

  get isSaveable(): boolean {
    return Boolean(this.teamMember.FirstName !== '' &&
            this.teamMember.LastName !== '' &&
            this.teamMember.Email !== '' &&
      this.teamMember.RoleName !== '' &&
      this.util.isValidEmail(this.teamMember.Email));
  } // end isSaveable()

  get hasContactEmailError(): boolean {
    return !this.util.isValidEmail(this.teamMember.Email);
  } // end hasContactEmailError()

  get canDeteleMember(): boolean {
    return Boolean(this.teamMember.ContactId > 1);
  } // end canDeteleMember()

  ngOnInit() {

  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {

    this.userrole = [
      { label: 'Practice Assistant', value: 'Practice Assistant' },
      { label: 'Admin Assistant', value: 'Admin Assistant' }
    ];

    if (this.teamMember && this.teamMember.ContactId > 1) {
      this.title = 'Edit a team member';
      this.saveBtn = 'UPDATE TEAM MEMBER';
    } else {
      this.title = 'Add a team member';
      this.saveBtn = 'SEND TEAM REQUEST';
    }

  } // end ngOnChanges()

  onShowDeleteMemberModal(assistant: Assistant) {
    this.loadingService.showOverlay();

    const snack: Snack = {
      label: 'Deleting team member...',
      action: null
    };

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Remove assistant (Team member)';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (add-team-member-modal)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Contact_ContactTypes';
    this.activityLog.JsonData = JSON.stringify(assistant);
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    this.addTeamMemberService.deleteAssistant(assistant.ContactId)
    .subscribe({
      next: (next) => {
      // On next
    },
    error: (error) => {
      snack.label = 'Something went wrong';
      snack.type = SnackType.ERROR;
      this.snackbarsService.dismiss().make(snack).show();
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On Complete
      snack.label = 'Team member deleted';
      this.snackbarsService.dismiss().make(snack).show();
      this.loadingService.hideOverlay();
    }
  });
  } // end onShowDeleteMemberModal()

  onSave(assistant: Assistant) {
    const isNewTeamMember: boolean = assistant.ContactId < 1;
    this.loadingService.showOverlay();

    const snack: Snack = {
      label: 'Submitting Team member request...',
      action: null
    };
    if (this.teamMember.RoleName === 'Practice Assistant') {
      this.teamMember.Role = 1;
    } else if (this.teamMember.RoleName === 'Admin Assistant') {
      this.teamMember.Role = 2;
    }
    // this.teamMember.ContactId = this.userProfileService.userProfile.serviceProviderID;
    this.teamMember.FullName = this.teamMember.FirstName + ' ' + this.teamMember.LastName;

    if (!isNewTeamMember) {
      // Update team member

      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Edit assistant (Team member)';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (add-team-member-modal)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'Contact_ContactTypes';
      this.activityLog.JsonData = JSON.stringify(assistant);
      // this.activityLogService.addActivityLog(this.activityLog).toPromise();

      this.addTeamMemberService.updateAssistant(assistant)
      .subscribe(next => {
        // On next
      },
      error => {
        snack.label = 'Something went wrong';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      () => {
        // On complete
        snack.label = 'Team member updated';
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      });
    } else {

      // if (this.teamMember.RoleName === 'Practice Assistant') {
      //   this.teamMember.Role = 1;
      // } else if (this.teamMember.RoleName === 'Admin Assistant') {
      //   this.teamMember.Role = 2;
      // }
      this.teamMember.ContactId = this.userProfileService.userProfile.serviceProviderID;
      // this.teamMember.FullName = this.teamMember.FirstName + ' ' + this.teamMember.LastName;

      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Add assistant (Team member)';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (add-team-member-modal)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'Contact_ContactTypes';
      this.activityLog.JsonData = JSON.stringify(assistant);
      // this.activityLogService.addActivityLog(this.activityLog).toPromise();

      this.addTeamMemberService
        .AddAssistant(this.teamMember)
        .subscribe({
          next: (team) => {
            // On next
          },
          error: (error) => {
            // On error
            snack.label = 'Something went wrong, please  contact auxcon for your request';
            snack.action = null;
            snack.type = SnackType.ERROR;
            this.loadingService.hideOverlay();
            this.snackbarsService.dismiss().make(snack).show();
          },
          complete: () => {
            // On complete
            snack.label = 'Team member will be added upon receipt of your request. ';
            this.loadingService.hideOverlay();
            this.snackbarsService.dismiss().make(snack).show();
          }
        });
      }
    this.dismiss();
  } // end onSave()

  onDismiss() {
    this.dismiss();
  } // end onDismiss()

  dismiss() {
    this.closeModal.emit(TAG_ADD_TEAM_MEMBER);
  } // end dismiss()

}
