import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Snack, SnackType, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { ChambersHandler, DateParameters, OnBoardingRegister, OnboardingService, RegionalBarHandler } from '../../../services/onboarding/onboarding.service';
import { environment } from '../../../../environments/environment'
import { Observable, of, timer } from "rxjs";
import { catchError, map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { FormGroup, Validators, FormBuilder, AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-onboarding-register',
  templateUrl: './onboarding-register.component.html',
  styleUrls: ['./onboarding-register.component.scss']
})

export class OnboardingRegisterComponent implements OnInit {
  bsValue = new Date();
  regionalBarDrp: any[];
  chambersDrp: any[];
  RegionalBarHandler: RegionalBarHandler[] = [];
  ChambersHandler: ChambersHandler[] = [];
  emailSendTo: string;
  isValidAdmissionMonth = false;
  isValidAdmissionYear = false;
  isEmailResponseSuccess = false;
  signupForm!: FormGroup;
  formSubmitted: Boolean = false;
  admissionDay: any[];
  admissionMonth: any[];
  admissionYear: any[];
  admissionDate: {
    day: string,
    month: string,
    year: string
  }
  onBoardingRegister: OnBoardingRegister = {
    FirstName: '',
    LastName: '',
    IDNumber: '',
    Gender: 'Female',
    Ethincity: 'African',
    AdmissionDate: this.bsValue,
    AdmissionDay: 0,
    AdmissionMonth: 0,
    AdmissionYear: 0,
    Seniority: 'Junior',
    ContactNumber: '',
    Email: '',
    BarSocietyID: 0,
    ChamberID: 0,
    TenantID: '',
    AccountType: '',
    Domain: '',
    ContactTypeID: 2,
  };
  dateParameters: DateParameters = {
    AdmissionDay: null,
    AdmissionMonth: null,
    AdmissionYear: null
  };

  showEmailExistsPopup: boolean;

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private onBoardingService: OnboardingService) { }

  ngOnInit(): void {
    this.getRegionalBar();

    this.signupForm = this.fb.group({
      firstName: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email], EmailValidator.createValidator(this.onBoardingService, this.loadingService)],
      contactNumber: ['', [Validators.required]],
      barSociety: ['', [Validators.required]],
      chamber: ['', [Validators.required]],
      ethnicity: ['African', [Validators.required]],
      gender: ['Female', [Validators.required]],
      seniority: ['Junior', [Validators.required]],
      SAIDnumber: [''],
      admissionDay: [''],
      admissionMonth: [''],
      admissionYear: ['', [Validators.required]],
    });

    this.signupForm.get('admissionDay').valueChanges.subscribe((val) => {
      if (val) {
        this.signupForm.get('admissionMonth').addValidators([Validators.required]);
      }
      else {
        this.signupForm.get('admissionMonth').clearValidators();
      }
      this.signupForm.get('admissionMonth').updateValueAndValidity();
    })

    this.admissionDay = [];
    for (let index = 1; index <= 31; index++) {
      this.admissionDay.push(index);
    }
    this.admissionYear = [];
    let years = moment().year();
    for (let index = 1960; index <= years; index++) {
      this.admissionYear.push({
        year: index
      });
    }
    this.admissionYear.sort((a, b) => (a.year < b.year ? 1 : -1));
  } // end ngOnInit()

  onEmailBlur() {
    const emailControl = this.signupForm.get('email');
    // if (emailControl.valid && emailControl.value) {
      this.onBoardingService.verifyDuplicateEmailPopup(emailControl.value).subscribe({
        next: (response) => {
          if(response === 'Email is already exist: 1') {
            this.showEmailExistsPopup = true;
          }
        },
        error: (error) => {
          if (error === 'Email is already exist') {
            this.showEmailExistsPopup = true;
            emailControl.setErrors({ duplicate: true });
          }
        }
      });
    // }
  }

  closeEmailExistsPopup() {
    this.showEmailExistsPopup = false;
  }

  onKeyUpName(event) {
    var k;
    k = event.keyCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 95 || k == 45 || k == 8 || (k >= 48 && k <= 57));
  }

  async reValidateEmail() {
    return new Promise((resolve, reject) => {
      const control = this.signupForm.get("email");
      control.updateValueAndValidity();
      setTimeout(function () {
        resolve(null);
      }, 2500);
    })
  }

  async onSavePreRegister(isMicrosoftAcc) {

    var email = this.signupForm.get('email').value;
    if (email) {
      this.loadingService.showOverlay();
      await this.reValidateEmail();
    }

    if (this.formIsInvalid() === true) {
      this.formSubmitted = true;
      this.loadingService.hideOverlay();
      return
    }
    else {
      let data: OnBoardingRegister = this.signupForm.value;
      this.onBoardingRegister.FirstName = this.getField('firstName').value;
      this.onBoardingRegister.LastName = this.getField('surname').value;
      this.onBoardingRegister.Email = this.getField('email').value;
      this.onBoardingRegister.ContactNumber = this.getField('contactNumber').value;
      this.onBoardingRegister.BarSocietyID = this.getField('barSociety').value;
      this.onBoardingRegister.ChamberID = this.getField('chamber').value;
      this.onBoardingRegister.Ethincity = this.getField('ethnicity').value;
      this.onBoardingRegister.Gender = this.getField('gender').value;
      this.onBoardingRegister.Seniority = this.getField('seniority').value;
      this.onBoardingRegister.IDNumber = this.getField('SAIDnumber').value;
      this.onBoardingRegister.AdmissionDay = this.getField('admissionDay').value;
      this.onBoardingRegister.AdmissionMonth = this.getField('admissionMonth').value;
      this.onBoardingRegister.AdmissionYear = this.getField('admissionYear').value;
      this.onBoardingRegister.AccountType = window['email-data'].AccountType;
      this.onBoardingRegister.Domain = window['email-data'].Domain;
      this.onBoardingRegister.TenantID = window['email-data'].TenantID;
    }

    this.onBoardingService.onSaveRegister(this.onBoardingRegister).subscribe({
      next: (response: any) => {
        this.emailSendTo = response.Email
        if (this.onBoardingRegister.AccountType != null && this.onBoardingRegister.AccountType == 'Non-Office') {
          this.isEmailResponseSuccess = true;
        }
        if (isMicrosoftAcc) {
          localStorage.setItem('OnBoardingPreRegistrationID', response.PreRegistrationsID)
          this.navigateToMicrosoft();
        }
      },
      error: (error) => {
        const snack: Snack = {
          label: error,
          type: SnackType.ERROR,
          action: null,
        };
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.loadingService.hideOverlay();
      }
    })
  }

  navigateToMicrosoft() {
    localStorage.setItem('OnBoardingPreRegistration', JSON.stringify(this.onBoardingRegister))
    if (this.onBoardingRegister.AccountType && this.onBoardingRegister.TenantID) {
      window.location.href = 'https://login.microsoftonline.com/' + this.onBoardingRegister.TenantID + '/oauth2/v2.0/authorize?client_id=' + environment.clientId + '&redirect_uri=' + environment.redirectUrl + 'onboarding/verify-user&response_mode=query&scope=openid&response_type=code&acr_values=urn%3amicrosoft%3apolicies%3amfa&prompt=select_account';
    }
    this.loadingService.hideOverlay();
  }

  getRegionalBar() {
    this.regionalBarDrp = [];
    this.onBoardingService.getBarSociety().subscribe({
      next: (response) => {
        if (response) {
          let res = [];
          response.forEach(element => {
            if (element.Name != "Other") {
              res.push(element);
            }
          });
          this.regionalBarDrp = res
          this.regionalBarDrp.forEach(item => {
            this.RegionalBarHandler.push({
              data: item,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          });
        }
      }
    })
  }

  getChambers() {
    this.chambersDrp = [];
    this.onBoardingService.getChambers(this.onBoardingRegister.BarSocietyID).subscribe({
      next: (response) => {
        if (response) {
          let res = [];
          response.forEach(element => {
            if ((element.ChamberName != "Other") && (element.ChamberName != "TBC")) {
              res.push(element);
            }
          });
          this.chambersDrp = res;
          this.chambersDrp.forEach(item => {
            this.ChambersHandler.push({
              data: item,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          });
        }
      }
    })
  }

  onChangeRegionalBar(event) {
    this.onBoardingRegister.BarSocietyID = event.target.value;
    this.onBoardingRegister.ChamberID = 0;
    this.getChambers();
  }

  formIsInvalid() {
    if (this.signupForm.invalid === false)
      return false;

    var firstName = this.getField('firstName').errors;
    var surname = this.getField('surname').errors;
    var email = this.getField('email').errors;
    var contact = this.getField('contactNumber').errors;
    var bar = this.getField('barSociety').errors;
    var chamber = this.getField('chamber').errors;
    var month = this.getField('admissionMonth').errors;
    var year = this.getField('admissionYear').errors;

    if (email.duplicate === false) {
      if (firstName || surname || contact || bar || chamber || month || year)
        return true;

      return false;
    }

    return this.signupForm.invalid;
  }

  getField(key) {
    return this.signupForm.get(key);
  }

}

export class EmailValidator {
  static createValidator(onBoardingService: OnboardingService, loadingService: LoadingService): AsyncValidatorFn {
    function extract(result) {
      if (result.duplicate === true)
        return { duplicate: true, AccountType: "Office" };

      window['email-data'] = result;
      result['duplicate'] = false;
      return result;
    }

    function extractError(error: any): Observable<any> {
      return of({ duplicate: true });
    }

    return (control: AbstractControl): Observable<ValidationErrors> => {
      return timer(500)
        .pipe(switchMap(() => {
          return onBoardingService
            .verifyDuplicateEmail(control.value)
            .pipe(
              catchError(extractError),
              map(extract));
        }));
    };
  }
}