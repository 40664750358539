import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';

@Injectable()
export class BriefsService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) {} // end constructor()

  getBriefs(id: number = null): Observable<any> {
    return this.http.get<any>(this.api.endpoints.briefs +
      '/' + this.api.serviceProviderID, this.api.httpOptions);
  } // end getBriefs()
} // end BriefsService{}
