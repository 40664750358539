import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { AdvocateHandle, ManageBlackListRequest, ManageBlackListService } from '../../../services/manage-BlackList/manage-BlackList.service';
import { ManageBlackListComponent } from '../../../pages/manage-BlackList/manage-BlackList.component';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';


@Component({
  selector: 'app-advocate-list',
  templateUrl: './advocate-list.component.html',
  styleUrls: ['./advocate-list.component.scss']
})
export class AdvocateListComponent implements OnInit {
  @Input() isGCBUserBar: boolean;
  
  sortBy: string;
  previousSortBy: string;
  sortAscending: boolean;
  sortAdvocateName: boolean;
  sortAdvocateUserName: boolean;
  sortCell: boolean;
  sortFax: boolean;
  sortEmail: boolean;
  sortVat: boolean;
  sortRegionalBar: boolean;
  sortPostalAddress: boolean;
  sortStreetAddress: boolean;
  searchTerm: string;
  currentAdvocateId: number;
  advocatesHandle: AdvocateHandle[] = [];
  advocateList: ManageBlackListRequest[] = [];
  showNewAdvocateModal: boolean = false
  showLawfirmTab: boolean = false;
  showAdvocateListTab: boolean = true;
  regionalBarName: string;
  showDeleteAdvPopPrompt: boolean;
  ReportingAdvocateId: number;
  ReportingBar: number;
  CurrentChamberId: number;
  isDeleteInPeogress: boolean = false;
  AdvocateFullName: string;
  RemoveAdvocateMsg: string;
  RegionalBarList : any;
  regionalbarFullName : string;

  get isGCBUser(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === 'GCB - User');
  } // end profileType()

  constructor(
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private manageBlackListService: ManageBlackListService,
    private manageBlacklistComponent: ManageBlackListComponent
  ) { 
    this.isGCBUserBar;
  }

  ngOnInit(): void {
    this.getAllAdvocates();
  }

  getAllAdvocates() {
    this.advocateList = [];
    this.advocatesHandle = [];
    this.loadingService.showOverlay();
    this.manageBlackListService.getLawfirmAdvocates(this.isGCBUserBar)
      .subscribe({
        next: (data) => {
          this.advocateList = data;
          if (this.advocateList) {
            this.RegionalBarList = data.RegionalBars
            this.ReportingBar =  data.RegionalBars[0].Id
            this.regionalbarFullName = data.RegionalBars[0].FullName
            data.LawfirmAdvocateList.forEach(item => {
              this.advocatesHandle.push({
                data: item,
                state: {
                  isVisible: true,
                  isSelected: false,
                  isOpen: false
                }
              });
            });
          }
        },
        complete: () => {
          this.loadingService.hideOverlay();
        }
      })
  } // end getAllAdvocates()

  performSearch() {
    if (this.searchTerm) {
      this.advocatesHandle.forEach(firm => {
        if (
          !(firm.data.FullName.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          firm.state.isVisible = false;
        } else {
          firm.state.isVisible = true;
        }
      });
    } else {
      this.advocatesHandle.forEach(firm => {
        firm.state.isVisible = true;
      });
    }
  } // end performSearch()

  clearSearch() {
    this.searchTerm = '';
    this.performSearch();
  } // end clearSearch()

  toggleSort(sortBy: string) {
    this.sortAdvocateName = false;
    this.sortAdvocateUserName = false;
    this.sortCell = false;
    this.sortFax = false;
    this.sortEmail = false;
    this.sortVat = false;
    this.sortPostalAddress = false;
    this.sortStreetAddress = false;
    this.sortRegionalBar = false;

    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch (this.sortBy) {
      case 'advocate-name':
        this.sortAdvocateName = true;
        this.sortRegionalBar = false;
        this.sortAdvocateUserName = false;
        this.sortCell = false;
        this.sortFax = false;
        this.sortEmail = false;
        this.sortVat = false;
        this.sortPostalAddress = false;
        this.sortStreetAddress = false;
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.FullName.toUpperCase().localeCompare(b.data.FullName.toUpperCase());
          }
          this.sortAdvocateName = false;
          return b.data.FullName.toUpperCase().localeCompare(a.data.FullName.toUpperCase());
        });
        break;

        case 'regional-bar':
        this.sortAdvocateName = false;
        this.sortRegionalBar = true;
        this.sortAdvocateUserName = false;
        this.sortCell = false;
        this.sortFax = false;
        this.sortEmail = false;
        this.sortVat = false;
        this.sortPostalAddress = false;
        this.sortStreetAddress = false;
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.BarSocietyName.toUpperCase().localeCompare(b.data.BarSocietyName.toUpperCase());
          }
          this.sortAdvocateUserName = false;
          return b.data.BarSocietyName.toUpperCase().localeCompare(a.data.BarSocietyName.toUpperCase());
        });
        break;

      case 'user-name':
        this.sortAdvocateName = false;
        this.sortRegionalBar = false;
        this.sortAdvocateUserName = true;
        this.sortCell = false;
        this.sortFax = false;
        this.sortEmail = false;
        this.sortVat = false;
        this.sortPostalAddress = false;
        this.sortStreetAddress = false;
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.UserName.toUpperCase().localeCompare(b.data.UserName.toUpperCase());
          }
          this.sortAdvocateUserName = false;
          return b.data.UserName.toUpperCase().localeCompare(a.data.UserName.toUpperCase());
        });
        break;

      case 'cell':
        this.sortAdvocateName = false;
        this.sortRegionalBar = false;
        this.sortAdvocateUserName = false;
        this.sortCell = true;
        this.sortFax = false;
        this.sortEmail = false;
        this.sortVat = false;
        this.sortPostalAddress = false;
        this.sortStreetAddress = false;
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.Cell.toUpperCase().localeCompare(b.data.Cell.toUpperCase());
          }
          this.sortCell = false;
          return b.data.Cell.toUpperCase().localeCompare(a.data.Cell.toUpperCase());
        });
        break;

      case 'email':
        this.sortAdvocateName = false;
        this.sortRegionalBar = false;
        this.sortAdvocateUserName = false;
        this.sortCell = false;
        this.sortFax = false;
        this.sortEmail = true;
        this.sortVat = false;
        this.sortPostalAddress = false;
        this.sortStreetAddress = false;
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.Email.toUpperCase().localeCompare(b.data.Email.toUpperCase());
          }
          this.sortEmail = false;
          return b.data.Email.toUpperCase().localeCompare(a.data.Email.toUpperCase());
        });
        break;

      case 'fax':
        this.sortAdvocateName = false;
        this.sortRegionalBar = false;
        this.sortAdvocateUserName = false;
        this.sortCell = false;
        this.sortFax = true;
        this.sortEmail = false;
        this.sortVat = false;
        this.sortPostalAddress = false;
        this.sortStreetAddress = false;
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.FaxNumber.toUpperCase().localeCompare(b.data.FaxNumber.toUpperCase());
          }
          this.sortFax = false;
          return b.data.FaxNumber.toUpperCase().localeCompare(a.data.FaxNumber.toUpperCase());
        });
        break;

      case 'vat':
        this.sortAdvocateName = false;
        this.sortRegionalBar = false;
        this.sortAdvocateUserName = false;
        this.sortCell = false;
        this.sortFax = false;
        this.sortEmail = false;
        this.sortVat = true;
        this.sortPostalAddress = false;
        this.sortStreetAddress = false;
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.VatNumber.toUpperCase().localeCompare(b.data.VatNumber.toUpperCase());
          }
          this.sortVat = false;
          return b.data.VatNumber.toUpperCase().localeCompare(a.data.VatNumber.toUpperCase());
        });
        break;

      case 'postal-address':
        this.sortAdvocateName = false;
        this.sortRegionalBar = false;
        this.sortAdvocateUserName = false;
        this.sortCell = false;
        this.sortFax = false;
        this.sortEmail = false;
        this.sortVat = false;
        this.sortPostalAddress = true;
        this.sortStreetAddress = false;
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.PostalAddress.toUpperCase().localeCompare(b.data.PostalAddress.toUpperCase());
          }
          this.sortPostalAddress = false;
          return b.data.PostalAddress.toUpperCase().localeCompare(a.data.PostalAddress.toUpperCase());
        });
        break;

      case 'street-address':
        this.sortAdvocateName = false;
        this.sortRegionalBar = false;
        this.sortAdvocateUserName = false;
        this.sortCell = false;
        this.sortFax = false;
        this.sortEmail = false;
        this.sortVat = false;
        this.sortPostalAddress = false;
        this.sortStreetAddress = true;
        this.advocatesHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.StreetAddress.toUpperCase().localeCompare(b.data.StreetAddress.toUpperCase());
          }
          this.sortStreetAddress = false;
          return b.data.StreetAddress.toUpperCase().localeCompare(a.data.StreetAddress.toUpperCase());
        });
        break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

  addNewAdvocate() {
    this.showNewAdvocateModal = true;
    //this.regionalBarName = this.regionalBarFullName;
  }
  closeAdvocateModal() {
    this.getAllAdvocates();
    this.clearSearch();
    this.showNewAdvocateModal = false;
  }
  isDirectCloseAdvocateModal(){
    this.clearSearch();
    this.showNewAdvocateModal = false;
    this.loadingService.hideOverlay();
  }

 
  onDeleteAdvPopPromptShow(Id: number, ChamberId: number,AdvocateFullName: string = '') {
    this.showDeleteAdvPopPrompt = true;
    this.ReportingAdvocateId = Id;
    this.CurrentChamberId = ChamberId;
    this.AdvocateFullName = AdvocateFullName.split(" ")[0] != 'Advocate' ? 'Advocate ' + AdvocateFullName : this.AdvocateFullName = AdvocateFullName;
    this.RemoveAdvocateMsg = this.isGCBUserBar ? 'You are about to delete ' + this.AdvocateFullName + ' from Bar Society list. Do you wish to proceed?' : 'You are about to delete ' + this.AdvocateFullName + ' from your Bar Society list. Do you wish to proceed?' 
  }
  onHideDeleteAdvPopPrompt() {
    this.showDeleteAdvPopPrompt = false;
  } // end onHideDeletePopPrompt()

  onDeleteAdvocate() {
    if (this.isDeleteInPeogress)
      return;
    this.isDeleteInPeogress = true
    this.manageBlackListService.onDeleteAdvocate(this.ReportingAdvocateId, this.CurrentChamberId, this.ReportingBar, this.isGCBUser)
      .subscribe({
        next: (response) => {
          this.onHideDeleteAdvPopPrompt();
          this.getAllAdvocates();
          this.clearSearch();
          this.isDeleteInPeogress = false;
        },
      })
  }

}
