import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { UsercurrencyDirective } from '../../../directive/usercurrency.directive';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { ActivityLogs, ActivityLogsService, DEFAULT_ACTIVITY_LOGS } from '../../../services/activity-logs/activity-logs.service';
import { ApiService } from '../../../services/api.service';
import { DEFAULT_FEE, DEFAULT_QUANTITY, DEFAULT_RATE, DEFAULT_RATE_TYPE, Fee, RATE_TYPE_DAILY, RATE_TYPE_DISBURSEMENT, RATE_TYPE_HOURLY, RATE_TYPE_NON_BILLABLE, RATE_TYPE_ONCE_OFF, RATE_TYPE_PER_KILOMETER, RATE_TYPE_PER_PAGE } from '../../../services/fee-items/fee-items.service';
import { HistoricalData, MattersService } from '../../../services/matters/matters.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Snack, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { DEFAULT_SUB_CATEGORY, SubCategory } from '../../../services/sub-categories/sub-categories.service';
import { UserProfile, UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-historical-fee-table',
  templateUrl: './historical-fee-table.component.html',
  styleUrls: ['./historical-fee-table.component.scss']
})
export class HistoricalFeeTableComponent implements OnInit {

  @Input() id: number;
  @Input() workingHistoricalFees: Fee[];
  @Input() subCategories: SubCategory[];
  @Input() userProfile: UserProfile;
  @Input() workingHistorical: HistoricalData;
  @Output() workingHistoricalFeesChange: EventEmitter<Fee[]> = new EventEmitter<Fee[]>();
  @Output() feeDelete: EventEmitter<Fee> = new EventEmitter<Fee>();
  @Output() workingHistoricalChange: EventEmitter<HistoricalData> = new EventEmitter<HistoricalData>();
  @ViewChild('rateinput') RateInput: ElementRef;
  @ViewChild('inputDiscount') DiscountInput: ElementRef;
  @ViewChild('inputPaymentReceived') inputPaymentReceived: ElementRef;
  @ViewChild('inputAttachmentFile') inputAttachmentFile: ElementRef;
  @ViewChild('inputUploadFile') inputUploadFile: ElementRef;

  activeTab = 'take-on-data';
  rateTypes = [
    RATE_TYPE_PER_KILOMETER,
    RATE_TYPE_HOURLY,
    RATE_TYPE_ONCE_OFF,
    RATE_TYPE_DAILY,
    RATE_TYPE_DISBURSEMENT,
    RATE_TYPE_NON_BILLABLE,
    RATE_TYPE_PER_PAGE
  ];

  currentFeesTotal = 0.00;
  currentFeesDiscount = 0;
  invoicelineCounter = 0;

  useDiscountPercentage: boolean;
  currentFeesGrandTotal = 0.00;

  invoiceNumber = 'AUX000000';
  invoiceAmount = 0.00;
  totalPayments = 0.00;
  totalOutstanding = 0.00;
  showDeleteFeeModal: boolean;
  quantityReadOnly: boolean;
  showAttorneyErrorPrompt: boolean;
  onEXCLVATClick: boolean;

  selectedFee: Fee;
  selectedSubCategory: SubCategory;

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)',
    PRACTICE_MANAGER: 'Practice Manager'
  };

  private _currentFeesTotal = 0.00;
  private previousFees: Fee[];
  private currency: CurrencyPipe = new CurrencyPipe('en-US');
  private feesReady: boolean;
  private matterReady: boolean;
  private hasError = false;

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

   // Is Internal Attorney
   get isAttorney(): boolean {
    let attorneyProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      attorneyProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
    } else {
      attorneyProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
    }
    return attorneyProfile;
  } // end isAttorney()

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    let lawfirmProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      lawfirmProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
    } else {
      lawfirmProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
    }
    return lawfirmProfile;
  } // end isLawfirm()

  get totalHoursWorked(): number {
    let total = 0;
    this.workingHistoricalFees.forEach(data => {
      if (data.RateType === 'Hourly') {
        total += data.Quantity;
      }
    });
    return total;
  } // end totalHoursWorked()

  hasRateError(fee: Fee): boolean {
    if (fee.Rate.toString().startsWith('-')) {
      this.hasError = true;
      return true;
    }
    this.hasError = false;
    return false;
  } // end hasRateError()

  get hasAnErrorOnRate(): boolean {
    return Boolean(this.hasError);
  }

  get isPracticeManager(): boolean {
    return true// Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  }


  constructor(
    private api: ApiService,
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private matterService: MattersService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    public userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private currencyDirective: UsercurrencyDirective,
  ) {
    this.feesReady = false;
      this.matterReady = false;
      this.showDeleteFeeModal = false;
      this.quantityReadOnly = true;
      this.useDiscountPercentage = false;
  } // end constructor()

  toggleExcludeVAT(){
    console.log("Check")
    if(this.workingHistorical.EXCLVAT === false){
    this.onEXCLVATClick = true}
    else{
      this.workingHistorical.EXCLVAT = !this.workingHistorical.EXCLVAT
    }
  }
  onEXCLVATClickContinue(){
    this.workingHistorical.EXCLVAT = !this.workingHistorical.EXCLVAT
    this.onEXCLVATClick = false
  }

  onEXCLVATClickCancel(){
    this.onEXCLVATClick = false
  }

  ngOnInit(): void {
    console.log('HISTORICAL FEES: ', this.workingHistoricalFees)
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.workingHistoricalFees && changes.workingHistoricalFees.currentValue) {
      this.feesReady = true;
      this.workingHistoricalFees.sort((a, b) => a.Date.toUpperCase().localeCompare(b.Date.toUpperCase()));
      this.initFeeDescriptions();
      this.calculateCurrentFeesTotal();
    }

    if (changes.workingHistorical && changes.workingHistorical.currentValue) {
      this.matterReady = true;
      this.initFeeDescriptions();
    }
  } // end ngOnChanges()

  onHideAttorneyErrorPrompt() {
    this.showAttorneyErrorPrompt = false;
  } // end onHideAttorneyErrorPrompt()

  onHourlyRateChange(fee: Fee, event) {
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onHourlyRateChange()

  onHourlyRateClicked(fee: Fee, event) {
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
      } else {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
      }
  } // end onHourlyRateClicked()

  onDiscountInputChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.DiscountInput.nativeElement.value = this.cpipe.transform(this.DiscountInput.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.DiscountInput.nativeElement.value = this.cpipe.transform(this.DiscountInput.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onDiscountInputChange()

  onPaymentReceivedInputChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.transform(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.transform(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onPaymentReceivedInputChange()

  onDiscountInputClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.DiscountInput.nativeElement.value = this.cpipe.parse(this.DiscountInput.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
      } else {
      this.DiscountInput.nativeElement.value = this.cpipe.parse(this.DiscountInput.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
      }
  } // end onHourlyRateClicked()

  onPaymentReceivedInputClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.parse(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
      } else {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.parse(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
      }
  } // end onPaymentReceivedInputClicked()

  initFeeDescriptions() {
    if (this.feesReady && this.matterReady) {
      this.workingHistoricalFees.map(fee => {
        if (!fee.FurtherDescription) {
          fee.FurtherDescription = fee.Subject
                                      .replace(/^[\w\s/\d.]+-\s/gi, '');
          fee.FurtherDescription = fee.FurtherDescription
                                      .replace(/[\s]+-[\s]+[\w]+$/gi, '')
                                      .replace(' - ' + this.workingHistorical.Status, '')
                                      .replace(' - ' + fee.MatterStatus, '')
                                      .trim();
        }
      });
    }
  } // end initFeeDescriptions()

  setActiveTab(tab: string) {
    this.activeTab = tab;
  } // end setActiveTab()

  getFeeDescription(subject: string): string {
    let feeDescription = '';
    const startIndex = subject.indexOf(' -');
    const endIndex = subject.indexOf(' -', startIndex);
    feeDescription = subject.substr(startIndex, (endIndex - startIndex));
    return feeDescription;
  } // end getFeeDescription()

  addAnotherFee() {
    if (this.workingHistorical.ClientRefNo !== '' && this.workingHistorical.InternalRefNo !== '' && this.workingHistorical.PlaintiffFullName !== '') {
      const rateType: string = DEFAULT_RATE_TYPE;
      let rate: number = DEFAULT_RATE;
      let qty: number = DEFAULT_QUANTITY;

      switch (rateType) {
        case RATE_TYPE_PER_KILOMETER:
          rate = this.workingHistorical && this.workingHistorical.PerKilometerRate
                  ? this.workingHistorical.PerKilometerRate
                  : this.userProfile.billingDetails.defaultPerKilometerRate;
                  qty = 1;
                  this.quantityReadOnly = false;
          break;

        case RATE_TYPE_HOURLY:
          rate = this.workingHistorical && this.workingHistorical.HourlyRate
                  ? this.workingHistorical.HourlyRate
                  : this.userProfile.billingDetails.defaultHourlyRate;
                  qty = 1;
                  this.quantityReadOnly = false;
          break;

        case RATE_TYPE_DAILY:
          rate = this.workingHistorical && this.workingHistorical.DailyRate
                  ? this.workingHistorical.DailyRate
                  : this.userProfile.billingDetails.defaultDailyRate;
                  qty = 1;
                  this.quantityReadOnly = false;
          break;

        case RATE_TYPE_ONCE_OFF:
          qty = 1;
          this.quantityReadOnly = true;
          break;
      }
      // this.invoicelineCounter = this.workingHistoricalFees && this.workingHistoricalFees.length;

      if (this.workingHistoricalFees && this.workingHistoricalFees.length > 0) {
        this.invoicelineCounter = this.workingHistoricalFees.length + 1;
      } else {
        this.invoicelineCounter = 0;
      }

      const fee = Object.assign({}, DEFAULT_FEE)  ;
      fee.Date = moment().toJSON();
      fee.Rate = rate;
      fee.Total = rate * qty;
      fee.Quantity = qty;
      fee.Date = moment().toJSON();
      fee.MatterStatus = this.workingHistorical.Status;
      fee.CanEdit = true;
      fee.LineCounter = this.invoicelineCounter;


      // Reset the selectedSubCategory
      this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);

      this.workingHistoricalFees.push(fee);
      this.workingHistoricalFeesChange.emit(this.workingHistoricalFees);

      this.calculateCurrentFeesTotal();
    } else {
      this.showAttorneyErrorPrompt = true;
    }
  } // end addAnotherFee()

  promptDeleteFee(fee: Fee) {
    this.selectedFee = fee;
    this.openModal('delete-fee-modal');
  } // end deleteFee()

  deleteFee(deletableFee: Fee) {
    // TODO: Delete the fee
    this.previousFees = this.util.objectCopy(this.workingHistoricalFees);
    this.workingHistoricalFees = this.workingHistoricalFees.filter(fee => {
      return !this.util.objectIsSame(deletableFee, fee);
    });
    this.workingHistoricalFeesChange.emit(this.workingHistoricalFees);

    if (deletableFee.FeeItemID) {
      this.feeDelete.emit(deletableFee);
    }

    this.calculateCurrentFeesTotal();
  } // end deleteFee()

  undoDelete(thisObj) {
    if (thisObj.previousFees) {
      this.snackbarsService.snackbarComponent.dismiss();
      thisObj.fees = thisObj.previousFees;
      thisObj.calculateCurrentFeesTotal();
      const snack: Snack = {
        label: 'Fee restored',
        action: null
      };
      this.snackbarsService.snackbarComponent.make(snack).show();
    }
  } // end undoDelete()

  openModal(modal: string) {
    switch (modal) {
      case 'delete-fee-modal':
        this.showDeleteFeeModal = true;
        break;
    }
  } // end openModal()
  closeModal(modal: string) {
    switch (modal) {
      case 'delete-fee-modal':
        this.showDeleteFeeModal = false;
        break;
    }
  } // end closeModal()
  onDiscountChange() {
    this.calculateCurrentFeesTotal();
  } // end onDiscountChange()

  onPaymentReceivedChange() {
    this.calculateCurrentFeesTotal();
  } // end onPaymentReceivedChange()

  toggleUseDiscountPercentage() {
    this.useDiscountPercentage = !this.useDiscountPercentage;
    let discount;

    if (this.useDiscountPercentage) {
      discount = (this.currentFeesDiscount / this.currentFeesTotal) * 100;
    } else {
      discount = (this.currentFeesDiscount / 100) * this.currentFeesTotal;
    }
    this.currentFeesDiscount = discount;
    this.onDiscountChange();
  } // end toggleUseDiscountPercentage()

  parseDate(date: string) {
    return this.util.parseDate(date);
  } // end parseDate()

  parseLineDate(lineDate: string): Date {
    if (lineDate) {
      return new Date(lineDate);
    } else {
      return null;
    }
  } // end parseLineDate()
  onValueChange(fee: Fee, value: Date): void {
    fee.Date = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  } // end onValueChange()

  getDescription(fee: Fee): string {
    const subject = fee.Subject
                      .replace(/^[\w\s/\d.]+-\s/gi, '')
                      .replace(/[\s]+-[\s]+[\w]+$/gi, '')
                      .replace(' - ' + this.workingHistorical.Status, '')
                      .replace(' - ' + fee.MatterStatus, '').trim();

    return subject;
  } // end getDescription()

  calculateFeeTotal(fee: Fee) {
    fee.Total = (fee.Rate * fee.Quantity);
    this.calculateCurrentFeesTotal();
  } // end calculateFeeTotal()

  selectAllContent($event) {
    $event.target.select();
  } // end selectAllContent($event)

  calculateCurrentFeesTotal() {
    let total = 0;
    this.workingHistoricalFees.forEach(fee => {
      total += fee.Total;
    });

    this.currentFeesTotal = total;
    this.calculateCurrentFeesGrandTotal();
  } // end calculateCurrentFeesTotal()

  calculateCurrentFeesGrandTotal() {
    if (this.workingHistorical.PaymentReceived) {
      this.workingHistorical.Total = (this.currentFeesTotal - this.workingHistorical.Discount) - this.workingHistorical.PaymentReceived;
    } else {
      this.workingHistorical.Total = this.currentFeesTotal - this.workingHistorical.Discount;
    }
  } // end calculateCurrentFeesGrandTotal()


  onFeeDescriptionSelect(subCategory: SubCategory, fee: Fee) {
    this.selectedSubCategory = Object.assign({}, subCategory);

    fee.SubCategoryID = this.selectedSubCategory.SubCategoryId;
    fee.FurtherDescription = this.selectedSubCategory.SubCategory;
    fee.RateType = this.selectedSubCategory.RateType;
    // fee.Rate =
    //   this.util.getRateFromRateType(this.userProfile, fee.RateType);
    this.onRateTypeChange(fee);
    this.onRateChange(fee);
    this.workingHistoricalFeesChange.emit(this.workingHistoricalFees);
    this.calculateFeeTotal(fee);
  } // end onFeeDescriptionSelect()

  onFeeDescriptionInput(event, fee: Fee) {
    if (this.selectedSubCategory) {
      if (event && event.inputType && event.inputType.localeCompare('deleteContentBackward') === 0) {
        // Assuming a SubCategory has been selected
        if (fee.SubCategoryID) {
          this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
          this.selectedSubCategory.SubCategoryId = 0;
          fee.SubCategoryID = 0;
          fee.FurtherDescription = event.target.value;
        }
      } else {
        const description = this.selectedSubCategory.SubCategory;
        this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
        this.selectedSubCategory.SubCategory = description;
        fee.FurtherDescription = event.target.value;
      }
    } else {
      // If not backspace
      this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
      this.selectedSubCategory.SubCategoryId = 0;
      fee.FurtherDescription = event.target.value;
      fee.SubCategoryID = 0;
    }
    this.workingHistoricalFeesChange.emit(this.workingHistoricalFees);
  } // end onFeeDescriptionInput()

  onRateTypeChange(fee: Fee) {
    fee.Quantity = DEFAULT_QUANTITY;
    switch (fee.RateType) {
      case RATE_TYPE_PER_KILOMETER:
        fee.Rate = this.workingHistorical.PerKilometerRate
                    ? this.workingHistorical.PerKilometerRate
                    : this.userProfile.billingDetails.defaultPerKilometerRate;
                    fee.Quantity = 1;
                    this.quantityReadOnly = false;
        break;

      case RATE_TYPE_HOURLY:
        fee.Rate = this.workingHistorical.HourlyRate
                    ? this.workingHistorical.HourlyRate
                    : this.userProfile.billingDetails.defaultHourlyRate;
                    fee.Quantity = 1;
                    this.quantityReadOnly = false;
        break;

      case RATE_TYPE_ONCE_OFF:
        fee.Rate = 1.00;
        fee.Quantity = 1;
        this.quantityReadOnly = true;
        break;

      case RATE_TYPE_DAILY:
        fee.Rate = this.workingHistorical.DailyRate
                    ? this.workingHistorical.DailyRate
                    : this.userProfile.billingDetails.defaultDailyRate;
                    fee.Quantity = 1;
                    this.quantityReadOnly = false;
        break;

      case RATE_TYPE_DISBURSEMENT:
        fee.Quantity = 1;
        fee.Rate = 1.00;
        this.quantityReadOnly = true;
        break;

      case RATE_TYPE_NON_BILLABLE:
        fee.Rate = 0;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;

      case RATE_TYPE_PER_PAGE:
        fee.Rate = 1.00;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;
    }
    this.calculateFeeTotal(fee);
  } // end onRateTypeChange()

  onRateFocus(event, fee = null) {
    this.util.formatCurrencyInput(event.target);

    if (fee) {
      this.calculateFeeTotal(fee);
    }
  } // end onRateFocus()

  onRateChange(event, fee: Fee = null) {
    this.util.formatCurrencyInput(event.target);

    if (fee) {
      this.calculateFeeTotal(fee);
    }
  } // end onRateChange()

  canChangeRateType(fee: Fee): boolean {
    return Boolean(!fee.SubCategoryID);
  } // end canChangeRateType()

  onQuantityFocus(event) {
    // this.util.formatCurrencyInput(event.target);
  } // end onQuantityFocus()

  /**
   * Sets the IsVatable checkbox checked property.
   * @param event The mouse event object.
   * @param value The new vatable value.
   */
  setIsVatable(event, value) {
    event.target.checked = value;
  } // end setIsVatable()

  /**
   * Toggles the vatability of the given fee.
   * @param {Fee} fee The vatable fee.
   */
  toggleIsVatable(fee: Fee) {
    if (fee.CanEdit && fee.RateType === 'Disbursement') {
      fee.IsVatable = !fee.IsVatable;
    }
  } // end toggleIsVatable()
} // end HistoricalFeeTableComponent()
