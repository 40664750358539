import { Component, OnInit, Input } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { AdvocateDetailStatementService } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { PATH_ATTORNEY_PER_CUSTOMER } from '../../../services/appdata/app-config.service';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-all-customer-attorney',
  templateUrl: './all-customer-attorney.component.html',
  styleUrls: ['./all-customer-attorney.component.scss']
})
export class AllCustomerAttorneyComponent implements OnInit {

  apiData: any = [];
  tabData: any = [];
  tooltipData: any = [];
  showTooltipOverlay = false;
  totalOutstanding = 0;
  @Input() activeTab: string;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private router: Router,
    private api: ApiService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private advocateDetailStatementService: AdvocateDetailStatementService
  ) { } // end constructor()

  ngOnInit() {
    if (this.advocateDetailStatementService.allCustomerActiveTab) {
      this.activeTab = this.advocateDetailStatementService.allCustomerActiveTab;
    } else if (this.activeTab == null || this.activeTab === undefined) {
      this.activeTab = 'all';
    }
    this.getApiData();
  } // end ngOnInit()

  onTabClick(tab: string) {
    this.activeTab = tab;
    this.switchTab();
  } // end onTabClick()

  getApiData(): void {
    this.loadingService.showOverlay();
    this.advocateDetailStatementService.getAllCustomers().subscribe({
      next: (response) => {
      // On next
      if (response) {
        this.apiData = response;
      }
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.switchTab();
      this.loadingService.hideOverlay();
    }
  });
  } // end getApiData()

  switchTab(): void {

    this.tabData = [];
    switch (this.activeTab) {
      case 'all':
        this.filterTabData('AllTotals');
        break;
      case 'current':
        this.filterTabData('CurrentTotals');
        break;
      case '30':
        this.filterTabData('ThirtyDaysTotals');
        break;
      case '60':
        this.filterTabData('SixtyDaysTotals');
        break;
      case '90':
        this.filterTabData('NinetyDaysTotals');
        break;
      case '98':
        this.filterTabData('NinetyEightDaysTotals');
        break;
    }
  } // end switchTab()

  filterTabData(key: string): void {
    this.tabData = this.apiData[key].sort((a, b) => b.Outstanding - a.Outstanding);
    this.totalOutstanding = 0;
    for (const item of this.tabData) {
      this.totalOutstanding += item.Outstanding;
    }
  } // end filterTabData()

  getBarWidth(value: number): string {
    let width = '0.95';
    if (value >= 1) {
      width = (value * 0.95).toString();
    }
    return width + '%';
  } // end getBarWidth()

  getBackgroundColor(type: string): string {
    if (type.toLowerCase() === 'all') {
      return '#646464';
    } else if (type.toLowerCase() === '0') {
      return '#8abd37';
    } else if (type.toLowerCase() === '30') {
      return '#e5c100';
    } else if (type.toLowerCase() === '60') {
      return '#eb9e02';
    } else if (type.toLowerCase() === '90') {
      return '#ff8000';
    } else if (type.toLowerCase() === '98') {
      return '#cf624f';
    }
  } // end getBackgroundColor()

  onHover(i: number, activeTab: string): void {
    let type = 0;
    if (activeTab === 'all') {
      type = null;
    } else if (activeTab === 'current') {
      type = +0;
    } else if (activeTab === '30') {
      type = +30;
    } else if (activeTab === '60') {
      type = +60;
    } else if (activeTab === '90') {
      type = +90;
    } else if (activeTab === '98') {
      type = +98;
    }
    this.showTooltipOverlay = true;
    this.tooltipData = [];
    this.advocateDetailStatementService.getByIndividualAttorneyCustomers(i).subscribe({
      next: (response) => {
      if (response) {
        if (type !== null) {
          response = response.filter(a => a.Due > 0 && a.DaysOverdue === type);
        } else {
          response = response.filter(a => a.Due > 0);
        }
        response.sort((a, b) => {
          return a.InvoiceDate.toUpperCase().localeCompare(b.InvoiceDate.toUpperCase());
        });
        if (response.length > 5) {
          this.tooltipData = response.slice(0, 5);
        } else {
          this.tooltipData = response;
        }
      }
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        // On complete
        this.showTooltipOverlay = false;
      }
    });

  } // end onHover()

  getBottomRow(index: number, length: number): string {
    if (length > 0 && index === length - 1) {
      return '20px';
    }
    return '0px';
  } // end getBottomRow()

  onViewCustomerReport(debtorID: string, selectedTab: string) {
    this.advocateDetailStatementService.activeTab = this.setTab(selectedTab);
    this.router.navigate([PATH_ATTORNEY_PER_CUSTOMER, this.api.serviceProviderID, debtorID]);
  } // end onViewCustomerReport()

  setTab(activeTab: string): string {
    let type = '';
    if (activeTab === 'all') {
      type = 'view-all';
    } else if (activeTab === 'current') {
      type = 'view-0-days';
    } else if (activeTab === '30') {
      type = 'view-30-days';
    } else if (activeTab === '60') {
      type = 'view-60-days';
    } else if (activeTab === '90') {
      type = 'view-90-days';
    } else if (activeTab === '98') {
      type = 'view-98-days';
    }
    return type;
  } // end setTab()

} // end AllCustomerAttorneyComponent{}
