import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import {
  InvoiceDetails, InvoiceDetailsService,
} from '../../../services/invoice-details/invoice-details.service';
import {
  NoteCategory,
  NotesObject,
  InstructionResponses,
  NotesObjectHandle,
  NotesService,
  DEFAULT_NOTES_OBJECT,
  Notes,
} from '../../../services/notes/notes.service';
import { ApiService } from '../../../services/api.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { InvoiceDocumentService } from '../../../services/invoice-document/invoice-document.service';
import * as moment from 'moment';

@Component({
  selector: 'app-live-statement-invoice-preview',
  templateUrl: './live-statement-invoice-preview.component.html',
  styleUrls: ['./live-statement-invoice-preview.component.scss']
})
export class LiveStatementInvoicePreviewComponent implements AfterViewInit, OnInit, OnChanges {

  invoice: InvoiceDetails;
  reportUrl: string;

  activeTab: string;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  // Notes
  onShowNoteDetail: boolean;
  noteCategory: NoteCategory[] = [];
  noteDetailData: NotesObject;
  invoicePlaintiff: string;
  invoiceDefendant: string;
  objectType: string;
  searchTerm: string;

  // Advocate Note View
  showAdvocateNoteDetail: boolean;
  selectedNote: NotesObject;
  instructionResponses: InstructionResponses[] = [];

  _fileName;
  @ViewChild('pdfViewer') public pdfViewer;
  @Input() notesHandle: NotesObjectHandle[];
  @Input() selectedTab: string;
  @Input() invoiceDetails: InvoiceDetails;
  @Input() invoiceId: number;
  @Input() plaintiff: string;
  @Input() defendant: string;
  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveNote: EventEmitter<any> = new EventEmitter<any>();

  isShowAddCreditModel = false;
  isShowWriteOffModel = false;
  isShowSendCreditNoteModel = false;
  showTransactionLinkedPrompt: boolean;


  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  // Is Practice Manager
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  }  // end isPracticeManager()

  constructor(
    private api: ApiService,
    private notesService: NotesService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private invoiceDetailService: InvoiceDetailsService,
    private invoiceDocumentService: InvoiceDocumentService
  ) { } // end constructor()

  ngAfterViewInit() {
    if (this.activeTab === 'invoice') {
      this.loadingService.showOverlay();
      this.reportUrl = this.api.endpoints.invoiceDocument +
        '?invoiceID=' + this.invoiceId;

      this.invoiceDocumentService.getInvoiceDocument(this.reportUrl).subscribe({
        next: (invoiceDoc) => {
        this.pdfViewer.name = 'Invoice.pdf';
        this.pdfViewer.pdfSrc = invoiceDoc;
        this.pdfViewer.refresh();
      },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
        }
      });
    }
  } // end ngAfterViewInit()

  ngOnInit() {
    if (this.selectedTab) {
      this.activeTab = this.selectedTab;
    } else {
      this.activeTab = 'invoice';
    }
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    // this.updateUIComponent();
  } // end ngOnChanges()

  setActiveTab(tab: string) {
    this.activeTab = tab;
    if (this.activeTab === 'invoice') {
      this.loadingService.showOverlay();
      this.reportUrl = this.api.endpoints.invoiceDocument +
        '?invoiceID=' + this.invoiceId;

      this.invoiceDocumentService.getInvoiceDocument(this.reportUrl).subscribe({
        next: (invoiceDoc) => {
        this.pdfViewer.name = 'Invoice.pdf';
        this.pdfViewer.pdfSrc = invoiceDoc;
        this.pdfViewer.refresh();
      },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
        }
      });
    }
    // this.updateUIComponent();
  } // end setActiveTab()

  onHideTransactionLinkedPrompt() {
    this.showTransactionLinkedPrompt = false;
  } // end onHideTransactionLinkedPrompt()

  onRemovePaymentTransaction() {
    this.showTransactionLinkedPrompt = false;
    this.loadingService.showOverlay();
    //  this.invoiceDetailService.removeTransactionInvoice(this.invoiceDetails.Id).toPromise();
    this.isShowAddCreditModel = true;
    this.loadingService.hideOverlay();
  } // end onRemovePaymentTransaction()

  @Input()
  set fileName(fileName: string) {
    this._fileName = fileName;
  }
  get fileName() {
    return this._fileName;
  }

  dismiss() {
    this.closeModal.emit('invoice');
  } // end dismiss()

  openModel(value) {
    switch (value) {
      case 'add-credit':
        if (this.invoiceDetails.TotalPaidAmount === this.invoiceDetails.InvoiceTotal) {
          this.showTransactionLinkedPrompt = true;
        } if (this.invoiceDetails.TotalPaidAmount + this.invoiceDetails.TotalWriteOffAmount === this.invoiceDetails.InvoiceTotal) {
          this.showTransactionLinkedPrompt = true;
        } else if (this.invoiceDetails.TotalCreditAmount === 0) {
          this.isShowAddCreditModel = true;
        }
        break;
      case 'write-off':
        if (this.invoiceDetails.OutstandingBalance > 0) {
          this.isShowWriteOffModel = true;
        }
        break;
      case 'send-credit':
        this.isShowSendCreditNoteModel = true;
        break;
    }
  }

  closeModel(value) {
    // this.invoiceDetails =  this.invoiceDetailService.getInvoiceDetails(this.invoiceId).toPromise();
    switch (value) {
      case 'add-credit':
        this.isShowAddCreditModel = false;
        if (this.invoiceDetails.CreditNoteId > 0) {
          this.isShowSendCreditNoteModel = true;
        }
        break;
      case 'write-off':
        this.isShowWriteOffModel = false;
        break;
      case 'send-credit':
        this.isShowSendCreditNoteModel = false;
        break;
      case 'advocate-note':
        this.showAdvocateNoteDetail = false;
        break;
    }

  } // end closeModel()

  performSearch() {
    if (this.searchTerm) {
      this.notesHandle.forEach(note => {
        if (
          !(note.data.ObjectTypeDescription.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(note.data.Subject.match(new RegExp(this.searchTerm, 'gi')))// &&
          // !(note.data.ExternalText.match(new RegExp(this.searchTerm, 'gi')))
          // !(note.data.InstructionText.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          note.state.isVisible = false;
        } else {
          note.state.isVisible = true;
        }
      });
    } else {
      this.notesHandle.forEach(note => {
        note.state.isVisible = true;
      });
    }
    // this.updateUIComponent();
  } // end performSearch()

  onNoteDetailView(data: NotesObject) {
    // this.notesHandle = [];
    this.loadingService.showOverlay();
    this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.noteDetailData = data;
    if (data.UserOwnerStatus === false) {
      data.UserOwnerStatus = true;
      //  this.notesService.putNoteInboxStatus(data).toPromise();
      this.notesHandle.forEach(x => {
        if (x.data === data) {
          x.data.UserOwnerStatus = true;
        }
      });
    }
    this.getNoteCategories();
    this.objectType = 'invoice';
    this.invoicePlaintiff = this.plaintiff;
    this.invoiceDefendant = this.defendant;
    this.onShowNoteDetail = true;
    this.loadingService.hideOverlay();
  } // end onNoteDetailView()

  onNoteAdvDetailView(data: NotesObject) {
    // this.notesHandle = [];
    this.instructionResponses = [];
    this.loadingService.showOverlay();
    this.selectedNote = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.selectedNote = data;
    if (data.UserAssignedStatus === false) {
      data.UserAssignedStatus = true;
      //  this.notesService.putNoteInboxStatus(data).toPromise();
      this.notesHandle.forEach(_note => {
        if (_note.data === data) {
          _note.data.UserAssignedStatus = true;
        }
      });
    }
    // this.instructionResponses =  this.notesService.getInstructionResponses(this.selectedNote.QuestionId)
    //   .toPromise();
    this.showAdvocateNoteDetail = true;
    this.loadingService.hideOverlay();
  } // end onNoteAdvDetailView()

  onAddNewNote() {
    this.loadingService.showOverlay();
    this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
    // this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
    // this.invoiceDetails =  this.invoiceDetailService.getInvoiceDetails(this.invoiceId).toPromise();
    // if (this.invoiceDetails) {
    //   const desc = this.invoiceDetails.InvoiceParties.split(' // ');
    //   if (desc.length > 0) {
    this.invoicePlaintiff = this.plaintiff;
    //   }
    //   if (desc.length > 1) {
    this.invoiceDefendant = this.defendant;
    //   }
    this.noteDetailData.ObjectId = this.invoiceDetails.Id;
    this.noteDetailData.ObjectTypeDescription = this.invoiceDetails.InvoiceNo;
    this.noteDetailData.CaptureDate = moment().toString();
    // }
    this.getNoteCategories();
    this.objectType = 'invoice';
    this.onShowNoteDetail = true;
    this.loadingService.hideOverlay();
  } // end onAddNewNote()

  onNoteSave(note: Notes) {
    this.loadingService.showOverlay();
    this.notesService.postNote(note).subscribe({
      next: (added_note) => {
        // On next
      },
      error: (error) => {
        // On Error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.notesHandle = [];
        let notes = [];
        if (this.isPracticeManager) {
          // notes =  this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
          //   this.invoiceDetails.Id, 4).toPromise();
        } else if (!this.isPracticeManager) {
          // notes =  this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
          //   this.invoiceDetails.Id, 4).toPromise();
        }
        notes.forEach(_notes => {
          this.notesHandle.push({
            data: _notes,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          });
        });
        this.loadingService.hideOverlay();
        this.onShowNoteDetail = false;
        // this.updateUIComponent();
      }
    });
  } // end onNoteSave()

  getNoteCategories() {
    // this.noteCategory =  this.notesService.getNoteCategory().toPromise();
    this.noteCategory = this.noteCategory.filter(c => c.ObjectTypeId === 4);
    this.noteCategory.sort((a, b) => {
      return a.Category.toUpperCase().localeCompare(b.Category.toUpperCase());
    });
  } // end getNoteCategories()

  onShowNoteDetailModalDismiss() {
    this.onShowNoteDetail = false;
  } // end onShowNoteDetailModalDismiss()

  updateUIComponent() {
    this.notesHandle.sort((a, b) => {
      return a.data.ObjectTypeDescription.toUpperCase().localeCompare(b.data.ObjectTypeDescription.toUpperCase());
    });
    $(function () {
      // Function Created By: Wiseman
      // Website: http://auxcon.co.za
      function groupTable($rows, startIndex, total) {
        if (total === 0) {
          return;
        }
        var i, currentIndex = startIndex, count = 1, lst = [];
        var tds = $rows.find('td:eq(' + currentIndex + ')');
        var ctrl = $(tds[0]);
        lst.push($rows[0]);
        for (i = 1; i <= tds.length; i++) {
          if (ctrl.text() === $(tds[i]).text()) {
            count++;
            $(tds[i]).addClass('deleted');
            lst.push($rows[i]);
          } else {
            if (count > 1) {
              ctrl.attr('rowspan', count);
              groupTable($(lst), startIndex + 1, total - 1);
            }
            count = 1;
            lst = [];
            ctrl = $(tds[i]);
            lst.push($rows[i]);
          }
        }
      }
      groupTable($('#conversationTable tr:has(td)'), 0, 1);
      $('#conversationTable .deleted').remove();
    });
  } // end updateUIComponent()

  getUnReadMessageFont(value: boolean): string {
    if (value === false) {
      return 'Bold';
    }
  } // end getUnReadMessageFont()

  OnAddResolution(note: Notes) {
    this.loadingService.showOverlay();
    this.notesService.putNote(note).subscribe({
      next: (updatedNote) => {
        // On next
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On compolete
        this.notesHandle = [];
        let notes = [];
        if (this.isPracticeManager) {
          // notes =  this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
          //   this.invoiceDetails.Id, 4).toPromise();
        } else if (!this.isPracticeManager) {
          // notes =  this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
          //   this.invoiceDetails.Id, 4).toPromise();
        }
        notes.forEach(_notes => {
          this.notesHandle.push({
            data: _notes,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          });
        });
        this.saveNote.emit();
        this.showAdvocateNoteDetail = false;
        this.onShowNoteDetail = false;
        // this.updateUIComponent();
        this.loadingService.hideOverlay();
      }
    });
  } // end OnAddResolution()
} // end LiveStatementInvoicePreviewComponent{}
