<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onCancel()">
          <i class="far fa-times-circle"></i>
        </button>
      </div> <!-- #end .modal-dismiss -->

      <div class="modal-body">
        <div class="container-fluid">

          <div class="row" *ngIf="showAddNewBrief === false">
            <label for="documentType" style="width: 100%;">Document</label>
            <select
              id="documentType"
              #documentType
              [(ngModel)]="selectedDocument"
              style="width: 100%;">
              <option value="0" disabled selected hidden>Select document to send with invoice</option>
              <option class="option-text" *ngFor="let document of briefDocuments" [value]="document.BriefId"><span>{{document.DocumentPath}} </span> - <span class="subtext">{{document.DocumentType}}</span> </option>
            </select>
          </div> <!-- #end .row -->

          <div class="row">
            <app-checkbox
            [(checked)]="showAddNewBrief"
            (click)="toggleShowAddNewBrief()"></app-checkbox>&nbsp;
            <span class="clickable"
            (click)="toggleShowAddNewBrief()">
          Add new document</span>
          </div> <!-- #end .row -->

          <div class="row" *ngIf="showAddNewBrief">
            <label for="documentType" style="width: 100%;">Document Type</label>
            <select
              id="documentType"
              [(ngModel)]="selectedDocumentType"
              style="width: 100%;">
              <option value="0" disabled selected hidden>Select document type</option>
              <option *ngFor="let document of documentTypes" [value]="document.ID">{{document.DocumentType}}</option>
            </select>
          </div> <!-- #end .row -->

          <div class="row m-0 pl-0 pb-0" *ngIf="showAddNewBrief">
            <div class="col-sm-9 pl-0 pr-5">
              <div class="custom-file">
                <input 
                  #inputAttachmentFile 
                  type="text" 
                  name="attachmentText" 
                  (click)="promptAttachment()" 
                  autocomplete="off"
                  class="form-control" 
                  [(ngModel)]="attachment">
                <input 
                  #inputUploadFile 
                  type="file" 
                  accept=".pdf, .docx" 
                  name="attachmentupload" 
                  id="attachmentUpload"
                  class="custom-file-input" 
                  (change)="fileAttachmentChange($event)" 
                  style="display: none">
              </div>
            </div>
            <div class="col-sm-3 pl-5 pr-5">
              <button class="btn btn-success form-control browse-file" (click)="promptAttachment()">Browse
                to file</button>
            </div>
          </div>
          <div class="row pl-3 m-0 pb-0">
            <label class="text-danger" for="">{{message}}</label>
          </div>

        </div> <!-- #end .container-fluid -->
      </div> <!-- #end .modal-body -->

      <div class="modal-footer">
        <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
        <button 
          class="btn btn-success" 
          (click)="onAddSelected()"
          [class.disabled]="!canAddDocument"
          [disabled]="!canAddDocument">Add file</button>
      </div> <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->