import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { PATH_SIGN_OUT, PATH_SUBSCRIPTION_BILLING, PATH_DASHBOARD } from '../../../services/appdata/app-config.service';
import { AuthService } from '../../../services/auth/auth.service';
import * as moment from 'moment';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';

@Component({
  selector: 'app-prompt-subscription',
  templateUrl: './prompt-subscription.component.html',
  styleUrls: ['./prompt-subscription.component.scss']
})
export class PromptSubscriptionComponent implements OnInit {

  FreeTrialDays: number;
  TrialDays: number;
  TrialPercentage: number;
  isShowFreeTrialPrompt: boolean = true;
  isShowUpgradeMessage: boolean = true;
  isShowPaymentDuePrompt: boolean = false;

  constructor(private router: Router, private authServices: AuthService, private api: ApiService, private userProfileService: UserProfileService, private snackbarsService: SnackbarsService) { }

  ngOnInit(): void {
    let FreeTrialDays = localStorage.getItem("freeTrialDays");
    let NextBillingDate = localStorage.getItem("nextBillingDate");
    let Status = localStorage.getItem("status");
    if (localStorage.getItem("status") !== 'undefined' && localStorage.getItem("status") !== null)
      this.checkUserSubscriptionDetails(Number(FreeTrialDays), NextBillingDate, Status);
    else {
      this.userProfileService.getContactSubscription().subscribe({
        next: (response: any) => {
          if (response) {
            localStorage.setItem("freeTrialDays", response.freeTrialDays);
            localStorage.setItem("nextBillingDate", response.nextBillingDate);
            localStorage.setItem("status", response.status);
            this.checkUserSubscriptionDetails(response.freeTrialDays, response.nextBillingDate, response.status);
          }
        },
        error: (error) => {
          const snack: Snack = {
            label: 'Error while get subscription details',
            action: null,
            type: SnackType.ERROR
          };
          this.snackbarsService.dismiss().make(snack).show();
        },
        complete: () => { }
      });
    }
  }

  checkUserSubscriptionDetails(FreeTrialDays: number, NextBillingDate: string, Status: string) {
    if (Status === "Free Trial") {
      this.FreeTrialDays = FreeTrialDays;
      const endDate = moment(NextBillingDate);
      this.TrialDays = endDate.diff(new Date(), 'days');
      this.TrialPercentage = Number((Number(this.FreeTrialDays) - (endDate.diff(moment(new Date()), 'days'))) * (100 / Number(this.FreeTrialDays)));

      if (moment(NextBillingDate).format('YYYY-MM-DD') >= moment(new Date()).format('YYYY-MM-DD'))
        this.isShowFreeTrialPrompt = false;
      else if (moment(NextBillingDate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD'))
        this.isShowFreeTrialPrompt = true;
    }
    else if (Status === "Active") {
      if (moment(NextBillingDate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD'))
        this.isShowPaymentDuePrompt = true;
    }
    else if (Status === "Cancelled")
      this.isShowUpgradeMessage = false;
  }

  signOut() {
    this.authServices.logout();
    this.router.navigate([PATH_SIGN_OUT]);
    sessionStorage.removeItem('userProfile');
    sessionStorage.clear();
    localStorage.clear();
    this.api.serviceProviderID = 0;
  }

  UpgradePlan() {
    this.router.navigate([PATH_SUBSCRIPTION_BILLING]);
  }
}
