<input
  type="text"
  placeholder="Select or start typing"
  (blur)="onBlur()"
  (focus)="onFocus()"
  (keyup)="onKeyUp($event)"
  (input)="onInput()"
  [(ngModel)]="matter.Description"
  (keyup.backspace)="onKeydown($event)"
  required>
<i
  class="fa fa-angle-down fa-lg input-arrow"
  (click)="toggleDropdown()"></i>
<div
  class="datalist"
  [class.show]="isActive">
  <div
    *ngFor="let item of matterList"
    class="datalist-item"
    [class.hide]="!item.state.isVisible"
    [class.selected]="item.state.isSelected"
    (click)="onSelect(item)">
    <span class="list-item-icon">
      <i [class]="iconClass"></i>
    </span>
    {{item.data.Description}}
  </div>
</div>