import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ManageBlackListService } from '../../../services/manage-BlackList/manage-BlackList.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { LENGTH_LONG, Snack, SnackType, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { AddLawfirm } from '../../../services/addlawfirm/add-lawfirm-attorney.service';

@Component({
  selector: 'app-barsociety-new-lawfirm-request-modal',
  templateUrl: './barsociety-new-lawfirm-request-modal.component.html',
  styleUrls: ['./barsociety-new-lawfirm-request-modal.component.scss']
})
export class BarsocietyNewLawfirmRequestModalComponent implements OnInit {
  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  addLawfirm: AddLawfirm = {
    LawfirmName: '',
    AttorneyName: '',
    ContactNumber: '',
    EmailAddress: '',
  };

  isValidLawFirmName = false;
  isValidPhoneNumber = false;
  isValidEmail = false;

  firmName = '';
  attorney = '';
  email = '';
  phoneNumber = '';



  get hasContactEmailError(): boolean {
    return !this.util.isValidEmail(this.email);
  } // end hasContactEmailError()

  get hasCellNumberError(): boolean {
    return !this.util.isValidPhoneNumber(this.phoneNumber,
      { code: this.userProfileService.userProfile.currencyDetails.code });
  } // end hasCellNumberError()

  get hasAttorneyNameError(): boolean {
    return !this.util.hasTextValue(this.attorney);
  } // end hasAttorneyNamerError()

  get hasFirmNameError(): boolean {
    return !this.util.hasTextValue(this.firmName);
  } // end hasFirmNameError()

  get isSubmittable(): Boolean {
    return Boolean(!this.hasFirmNameError) && Boolean(!this.hasCellNumberError) &&
      Boolean(!this.hasContactEmailError)
  }

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private manageBlackListService: ManageBlackListService,
    private userProfileService: UserProfileService,
    private snackbarsService: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }

  submitNewLawfirmRequest() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Sending request...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, LENGTH_LONG).show();
    this.addLawfirm = {
      LawfirmName: this.firmName,
      AttorneyName: this.attorney,
      ContactNumber: this.phoneNumber,
      EmailAddress: this.email
    };

    this.manageBlackListService.submitNewLawfirmRequest(this.addLawfirm).subscribe({
      next: (newAttorney) => {
        // On next
      },
      error: (error) => {
        // On error
        snack.label = 'Something went wrong, please contact auxcon for your request';
        snack.action = null;
        snack.type = SnackType.ERROR;
        this.loadingService.hideOverlay();
        this.snackbarsService.dismiss().make(snack).show();
        console.error('Something went wrong: ', error);
      },
      complete: () => {
        // On complete
        snack.label = 'Request sent successfully';
        this.clearInputs();
        this.onDismiss();
        this.loadingService.hideOverlay();
        this.snackbarsService.dismiss().make(snack).show();
      }
    });
  }

  clearInputs() {
    this.firmName = '';
    this.attorney = '';
    this.phoneNumber = '';
    this.email = '';
    this.isValidLawFirmName = false;
    this.isValidEmail = false;
    this.isValidPhoneNumber = false;
  } // end clearInputs()

  onLawFirmNameBlur() {
    if (!this.util.hasTextValue(this.firmName)) {
      this.isValidLawFirmName = true;
    } else {
      this.isValidLawFirmName = false;
    }
  } // end onLawFirmNameBlur()
  onBlurPhoneNumber() {
    if (!this.util.isValidPhoneNumber(this.phoneNumber,
      { code: this.userProfileService.userProfile.currencyDetails.code })) {
      this.isValidPhoneNumber = true;
    } else {
      this.isValidPhoneNumber = false;
    }
  } // end onBlurPhoneNumber()
  onBlurEmail() {
    if (!this.util.isValidEmail(this.email)) {
      this.isValidEmail = true;
    } else {
      this.isValidEmail = false;
    }
  } // end onBlurEmail()

  onFocusAttorney() {
    this.onLawFirmNameBlur();
  } // end onFocusAttorney()

  onFocusEmail() {
    this.onBlurPhoneNumber();
    this.onLawFirmNameBlur();
  } // end onFocusEmail()

  onDismiss() {
    this.loadingService.showOverlay();
    this.isVisible = false;
    this.closeModal.emit();
  } // end onDismiss()

}
