<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <div class="row">
          <div class="form-group">
            <label for="reference">Reference</label>
            <input
              type="text"
              class="form-control"
              id="reference"
              [(ngModel)]="this.workingReferenceData.Reference">
          </div> <!-- #end .form-group -->
        </div> <!-- #end .row -->
      </div> <!-- #end .modal-body .form -->

      <div class="modal-footer">
        <button
          class="btn btn-danger"
          (click)="dismiss()">Cancel</button>
        <button 
          class="btn btn-success"
          [class.disabled]="!isSaveable"
          [disabled]="!isSaveable"
          (click)="save()">Save</button>
      </div> <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->