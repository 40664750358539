
import {map,  retry, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api.service';

@Injectable()
export class InvoiceDocumentService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
  ) { } // end constructor()

  /**
   * GET: returns an invoice document (PDF)
   * @param {number} invoiceId The ID for a particular invoice.
   * @returns {Document} Returns an invoice as a PDF document.
   */
  // getInvoiceDocument(invoiceId: number): Observable<any> {
  //   return this.http.get(this.api.endpoints.invoiceDocument +
  //     '?invoiceID=' + invoiceId, this.api.httpOptions).pipe(
  //       retry(3),
  //       catchError(this.api.handleError)
  //     );
  // } // end getInvoiceDocument()
  getInvoiceDocument(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'blob'}, ).pipe(map(
        (res) => {
          return new Blob([res], {type: 'application/pdf'});
        }));
  } // end getInvoiceDocument()

  getQuotationDocument(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'blob'}, ).pipe(map(
        (res) => {
          return new Blob([res], {type: 'application/pdf'});
        }));
  } // end getQuotationDocument()

  getMultipleInvoiceDocument(invoiceIds: number[]): Observable<Blob> {
    return this.http.get(this.api.endpoints.invoiceDocument + '/MultipleInvoices', {
      params: { invoiceIds}, // Convert invoice IDs to comma-separated string
      responseType: 'blob' // Set response type to blob
    }).pipe(
      map((res: Blob) => res) // Map the response directly
    );
  } // end getMultipleInvoiceDocument()

  /**
   * POST: initiates new invoice document creation.
   * This will initiate the creation of a new invoice document (PDF).
   * @param {number} invoiceId The Invoice ID value.
   * @returns {string} Returns the name of the newly created invoice document.
   */
  createInvoiceDocument(invoiceId: number, createInvNoInvDate: boolean): Observable<any> {
    return this.http.post(this.api.endpoints.invoiceDocument +
      '?id=' + invoiceId + '&createInvNoInvDate=' + createInvNoInvDate, null, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end createInvoiceDocument()
} // end InvoiceDocumentService{}
