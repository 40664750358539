import { Component, OnInit } from '@angular/core';
import { PATH_AUXCON_ADMIN } from '../../../services/appdata/app-config.service';
import { AdvocateDetailStatementService, ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { CollectionsDailyReportDetail, CollectionsDailyReportDetailHandle, CollectionsDailyReportSummary, CollectionsDailyReportView,
  ContactsService } from '../../../services/contacts/contacts.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import * as moment from 'moment';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-collections-report',
  templateUrl: './collections-report.component.html',
  styleUrls: ['./collections-report.component.scss']
})
export class CollectionsReportComponent implements OnInit {

  excelURL: string;
  crumbs: ICrumb[];
  collectionsDailyReportView: CollectionsDailyReportView[] = [];
  collectionsDailyReportSummary: CollectionsDailyReportSummary[] = [];
  collectionsDailyReportDetail: CollectionsDailyReportDetail[] = [];
  collectionsDailyReportDetailHandle: CollectionsDailyReportDetailHandle[] = []

  row = 0;

  collectors = [];
  selectedCollector = 0;
  selectedCollectorName = '';
  selectedCollectionDate = '';
  selectedCollectionStartDate = '';
  collectionsStatusFilter: any;
  selectedStatus = '';
  selectedCollectionEndDate = '';
  exportExcelData: any[] = [];

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private cpipe: UsercurrencyPipe,
    private loadingService: LoadingService, 
    private contactService: ContactsService,
    private userProfileService: UserProfileService,
    private advocateDetailStatementService: AdvocateDetailStatementService
  ) { 
    this.excelURL = this.api.endpoints.auxconReportUtils + '/AuxconReportUtils';
  } // end constructor()

  ngOnInit(): void {
    this.selectedCollectionStartDate = moment().format('YYYY-MM-DD');
    this.selectedCollectionEndDate =  moment().add(+1, 'days').format('YYYY-MM-DD');
    this.collectors = [
      { label: 'Celeste Stander', value: 22116 },
      { label: 'Jackie Scheepers', value: 12402 },
      { label: 'Johanna Khosa', value: 17408 },
      { label: 'Thither Chiumba', value: 27272 }
    ];

    this.collectionsStatusFilter = [
      { label: 'None', value: 'None'},
      { label: 'Assigned - Not Worked', value: 'Assigned - Not Worked'},
      { label: 'Assigned - Worked', value: 'Assigned - Worked'},
      { label: 'Diarised - Not Worked', value: 'Diarised - Not Worked'},
      { label: 'Diarised - Worked', value: 'Diarised - Worked'},
      { label: 'Recent Instruction - Not Worked', value: 'Recent Instruction - Not Worked'},
      { label: 'Recent Instruction - Worked', value: 'Recent Instruction - Worked'},
    ]

    this.crumbs = [
      {
        label: 'Admin',
        link: PATH_AUXCON_ADMIN
      },
      {
        label: 'Contact Management'
      }
    ];

    this.getCollectionReportData();
  } // end ngOnInit()

  onDateValueChange(value: Date): void{
    if (value) {
      this.selectedCollectionStartDate =  moment(value[0]).format('YYYY-MM-DD');
      this.selectedCollectionEndDate =  moment(value[1]).format('YYYY-MM-DD')
      console.log('onDateValueChange Date Selected: ', value[0], value[1]);
    }
  } // end onDateValueChange()

  // onEndDateValueChange(value: Date): void{
  //   if (value) {
  //     this.selectedCollectionEndDate =  moment(value).format('YYYY-MM-DD')
  //   }
  // } // end onEndDateValueChange()

  // onEndDateValueChange(value: Date): void{
  //   if (value) {
  //     this.selectedCollectionEndDate =  moment(value).format('YYYY-MM-DD')
  //   }
  // } // end onEndDateValueChange()

  getCollectionReportData() {
    this.collectionsDailyReportSummary = [];
    this.loadingService.showOverlay();
    console.log('SELECTED DATE RANGE: ', this.selectedCollectionStartDate, moment(this.selectedCollectionStartDate).format('YYYY-MM-DD'));
    this.contactService.getCollectionsReport(this.selectedCollectionStartDate, this.selectedCollectionEndDate).subscribe({ next: (data) => {
        // On next
        this.collectionsDailyReportSummary = data;
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
      }
    });
  } // end getCollectionReportData()

  getCollectionReportDetail(collectorId: number) {
    this.loadingService.showOverlay();
    this.collectionsDailyReportDetail = [];
    this.contactService.getCollectionsReportDetail(collectorId, moment(this.selectedCollectionStartDate).format('YYYY-MM-DD'), moment(this.selectedCollectionEndDate).format('YYYY-MM-DD')).subscribe({ next: (data) => {
      // On next
        this.collectionsDailyReportDetail = data;
        this.onCollectionDetailsInit();
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
      }
    });
  } // end getCollectionReportDetail()

  onCollectionDetailsInit() {
    this.collectionsDailyReportDetail.forEach(data => {
      if (data) {
        this.collectionsDailyReportDetailHandle.push({
          data: data,
          state: {
            isVisible: true,
            isSelected: false,
            isOpen: false
          }
        });
      }
    })
  } // end onCollectionDetailInit()

  togglePanel(tab: string) {

  } // end togglePanel()

  onCollectorClick(data: any) {
    this.row = 0;
    if (data.CollectorId) {
      this.row = data.CollectorId;
      this.selectedCollectorName = data.Collector;
      this.getCollectionReportDetail(this.row);
      console.log('SELECTED COLLECTOR: ', data);
    }
  } // end onCollectorClick()
 
  convertToCurrency(amount: string): string {
    let amountValue = '';
    if (this.userProfileService.userProfile.isImpersonator) {
      amountValue = this.cpipe.transform(amount,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      amountValue = this.cpipe.transform(amount,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
    return amountValue;
  } // end convertToCurrency()

  onGenerateExcelReport() {
    this.exportExcelData = [];
    this.loadingService.showOverlay();
    this.collectionsDailyReportView.forEach(dataToExport => {
      const exportData = {
        'Law firm': dataToExport.Collector,
        'Total Amount Due': this.convertToCurrency(dataToExport.TotalAmount.toString()),
        'Advocate Count': dataToExport.AdvocateCount,
        'Invoice Count': dataToExport.InvoiceCount,
        'Collection Diary Date': moment(dataToExport.CollectionsDate).format('YYYY-MM-DD'),
        'Collector': dataToExport.Collector
      };
      this.exportExcelData.push(exportData);
    });
    this.http.post(this.excelURL, this.exportExcelData, {
      responseType: 'arraybuffer'
    }).subscribe({ next: (response) => this.advocateDetailStatementService.downloadExcelFile(response,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      , error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
      }
    });
  } // end onGenerateExcelReport()
} // end CollectionsReportComponent {}
