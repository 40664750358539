import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  ContactStickyNotes,
  AdvocateOverviewServiceService,
  ContactNoteActionLogsSTO
} from '../../../services/advocate-overview-service/advocate-overview-service.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { SnackbarsService, Snack } from '../../../services/messaging/snackbars/snackbars.service';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-advocate-info',
  templateUrl: './advocate-info.component.html',
  styleUrls: ['./advocate-info.component.scss']
})
export class AdvocateInfoComponent implements OnInit {

  editNote = false;
  contactStickyNote: ContactStickyNotes;
  @Input() workingStickyNote: ContactStickyNotes;
  @Input() contactNoteActionLogsSTO: ContactNoteActionLogsSTO[];
  @Output() saveNote: EventEmitter<any> = new EventEmitter<any>();

  get isNoteDirty() {
    return !this.util.objectIsSame(this.contactStickyNote, this.workingStickyNote);
  }

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private advocateOverviewService: AdvocateOverviewServiceService,
  ) { } // end contructor()

  ngOnInit() {
    this.contactStickyNote = Object.assign({}, this.workingStickyNote);
    // this.getNoteActionLogs();
  } // end ngOnInit()

  onNoteEditClicked() {
    this.saveNote.emit(this.workingStickyNote);
    // this.workingStickyNote = Object.assign(this.workingStickyNote, this.contactStickyNote);
    this.editNote = false;
    // this.getNoteActionLogs();
  } // end onNoteEditClicked()

  canEditNoteClicked() {
    this.editNote = true;
  } // end canEditNoteClicked()
} // end AdvocateInfoComponent{}
