import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PATH_SIGN_IN_LOADING } from '../../services/appdata/app-config.service';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'app-advocate-sign-in',
  templateUrl: './advocate-sign-in.component.html',
  styleUrls: ['./advocate-sign-in.component.scss']
})
export class AdvocateSignInComponent implements OnInit {

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private route: ActivatedRoute,
    private router: Router,
    private authService: MsalService,
  ) {
    document.title = 'Sign in - Auxcon';
  } // onstructor()

  ngOnInit() {
    let activeAccount = this.authService.instance.getActiveAccount();
    setTimeout(() => {
      if (activeAccount)
        this.router.navigate([PATH_SIGN_IN_LOADING]);
      else {
        if (this.msalGuardConfig.authRequest)
          this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        else
          this.authService.loginRedirect();
      }
    }, 3000);

    // if (this.msal.getUser()) {
    //   // if (this.adalService.userInfo.authenticated) {
    //   this.router.navigate([PATH_SIGN_IN_LOADING]);
    // } else {
    //    this.msal.loginPopup();
    // }

    // if (this.adalService.userInfo.authenticated) {
    // this.router.navigate([PATH_SIGN_IN_LOADING]);
    // this.router.navigate([PATH_RETURN_URL]);
    // } else {
    // this.adalService.login();
    // }
  } // end ngOnInit()
} // AdvocateSignInComponent{}
