<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div>
      <!--#end .modal-header -->

      <div class="modal-body form">
        <div class="row">
          <div class="form-group">
            <label for="full-name">Client<span title="Required field"> *</span> </label>
            <input
             type="text"
             class="form-control"
             [class.has-error]="hasFirstName"
             placeholder="Enter full name"
             (input)="$event.target.value"
             [(ngModel)]="clients.FullName"
             autofocus>
            <span
              *ngIf="hasFirstName"
              class="text-danger">Please enter a valid first name</span>
          </div>
          <!--#end .form-group -->
        <div class="form-group">
          <label for="email">Email Address<span title="Required field"> *</span></label>
          <input
            type="text"
            class="form-control"
            [class.has-error]="hasContactEmailError"
            placeholder="Enter email"
            
            (input)="$event.target.value"
            [(ngModel)]="clients.EmailAddress"
            autofocus>
            <!-- (blur)="onCheckEmailAddress()" -->
            <span
              *ngIf="hasContactEmailError"
              class="text-danger">Please enter a valid email address.</span>
        </div>
          <div class="form-group">
            <label for="identity-number">ID / Registration Number</label>
            <input
             type="text"
             placeholder="Enter ID Number"
             (input)="$event.target.value"
             [(ngModel)]="clients.IDNumber"
             autofocus>
          </div>
          <!--#end .form-group -->
        </div>
        <!--#end .row -->

        <div class="row">
          <div class="form-group">
            <label for="cell-phone">Cell Number<span title="Required field"> *</span></label>
            <input
             type="text"
             class="form-control"
             [class.has-error]="hasPhoneNumberError"
             placeholder="Enter cell number"
             
             (input)="$event.target.value"
             [(ngModel)]="clients.MobileNumber"
             autofocus>
            <!-- (blur)="onCheckPhoneNumber()" -->
            <span
              *ngIf="hasPhoneNumberError"
              class="text-danger">Please enter a valid cell number</span>
          </div>
          <!--#end .form-group -->
          <div class="form-group">
            <label for="phone-phone">Phone Number</label>
            <input
            type="text"
            placeholder="Enter phone number"
            (input)="$event.target.value"
            [(ngModel)]="clients.WorkPhone"
            autofocus>
          </div>
          <!--#end .form-group -->
          <div class="form-group">
            <label for="fax-number">Fax Number</label>
            <input
              type="text"
              placeholder="Enter fax number"
              (input)="$event.target.value"
              [(ngModel)]="clients.WorkFax"
              autofocus>
          </div>
        </div>
        <!--#end .row -->

        <div class="row">
          <!--#end .form-group -->
          <div class="form-group">
            <label for="vat">VAT Number</label>
            <input
             type="text"
             placeholder="Enter vat number"
             (input)="$event.target.value"
             [(ngModel)]="clients.VATNumber"
             autofocus>
          </div>
          <!--#end .form-group -->
            <div class="form-group">
              <label for="address">Address</label>
              <textarea
                class="form-control-address"
                type="text" id="address"
                [(ngModel)]="clients.StreetAddressFull"
                (click)="$event.target.select()"></textarea>
            </div>
            <!--#end .form-group -->
        </div>
        <!--#end .row -->

      </div>
      <!--#end .modal-body -->

      <div class="modal-footer">
        <button
          class="btn btn-success btn-end"
          [class.disabled]="!isSaveable"
          [disabled]="!isSaveable"
          (click)="save()">{{btnSave}}</button>
      </div>
      <!--#end .modal-footer -->

    </div>
    <!--#end .modal-dialog -->
  </div>
  <!--#end .modal-content -->
</div>
<!--#end .modal -->

<app-alert
  [title]="promptTitle"
  [message]="message"
  [isVisible]="showSaveContactModelPrompt"
  (cancel)="onHideSaveContactModelPrompt()"
  (confirm)="onHideSaveContactModelPrompt()"></app-alert>
