<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid">
  <div class="frame">
    <div class="tile">
      <!-- Invoice details -->
      <div class="well">
        <h2 class="well-title">Invoice details</h2>
        <div class="well-row">
          <span class="well-row-cell">Invoice amount</span>
          <span>{{invoice.InvoiceTotal | currency:'R '}}</span>
        </div> <!-- #end .well-row -->
        <div class="well-row">
          <span class="well-row-cell">Invoice date</span>
          <span>{{invoice.InvoiceDate | date:'yyyy-MM-dd'}}</span>
        </div> <!-- #end .well-row -->
      </div> <!-- #end .well -->

      <!-- Matter details -->
      <div class="well">
        <h2 class="well-title">Invoice details</h2>
        <div class="well-row">
          <span class="well-row-cell">Client name</span>
          <span>{{invoice.ClientName}}</span>
        </div><!-- #end .well-row -->
        <div class="well-row">
          <span class="well-row-cell">Reference</span>
          <span>{{invoice.InvoiceReference}}</span>
        </div><!-- #end .well-row -->
        <div class="well-row">
          <span class="well-row-cell">Plaintiff</span>
          <span>{{plaintiff}}</span>
        </div><!-- #end .well-row -->
        <div class="well-row">
          <span class="well-row-cell">Defendant</span>
          <span>{{defendant}}</span>
        </div><!-- #end .well-row -->
      </div> <!-- #end .well -->

    </div> <!-- #end .tile-->

    <div class="tile">
      <!-- Invoice PDF -->
      <div style="width:100%; height:700px;">
        <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" [openFile]="true" [viewBookmark]="true" [download]="true"
          [downloadFileName]="fileName"></ng2-pdfjs-viewer>
      </div>
    </div> <!-- #end .tile-->

  </div> <!-- #end .frame -->
</div> <!-- #end .container-fluid -->