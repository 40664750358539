import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { SubscriptionBillingService } from '../../../services/subscription-billing/SubscriptionBilling.service';
import { LENGTH_LONG, LENGTH_SHORT } from "../../../services/messaging/toast/toast.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-response-handler',
  templateUrl: './payment-response-handler.component.html',
  styleUrls: ['./payment-response-handler.component.scss']
})
export class PaymentResponseHandlerComponent implements OnInit {

  Status: string = '';
  isShowSuccess: boolean = false;
  isShowFailure: boolean = false;
  isShowCancel: boolean = false;
  isShowError: boolean = false;

  constructor(
    private subscriptionBillingService: SubscriptionBillingService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.Status = params['Status'];
    });
    if (this.Status == 'Cancel') {
      this.isShowCancel = true;
      this.removeCancelPayments();
    }
    else
      this.getPaymentDetails();
  }

  getPaymentDetails() {
    this.loadingService.showOverlay();
    this.subscriptionBillingService.getPaymentDetails(Number(localStorage.getItem("ContactSubscriptionPaymentsID"))).subscribe({
      next: (response: any) => {
        if (response) {
          if (response.PaymentStatus) {
            if (response.PaymentStatus.toLowerCase() == "complete") {
              this.isShowSuccess = true;
              localStorage.setItem("status", response.Status);
              localStorage.setItem("nextBillingDate", response.NextBillingDate);
              this.subscriptionBillingService.triggerRefresh(true);
            }
            else if (response.PaymentStatus.toLowerCase() == "fail")
              this.isShowFailure = true;
            else if (response.PaymentStatus.toLowerCase() == "cancel")
              this.isShowCancel = true;
            else
              this.isShowError = true;
          }
          else
            this.isShowError = true;
        }
      },
      error: (error) => {
        const snack: Snack = { label: "Something went wrong", type: SnackType.ERROR, action: null };
        this.snackbarsService.make(snack, LENGTH_SHORT).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.loadingService.hideOverlay();
      }
    })
  }

  removeCancelPayments() {
    this.loadingService.showOverlay();
    this.subscriptionBillingService.removeCancelPayments().subscribe({
      next: (response: any) => { },
      error: (error) => {
        const snack: Snack = { label: "Something went wrong", type: SnackType.ERROR, action: null };
        this.snackbarsService.make(snack, LENGTH_LONG).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.loadingService.hideOverlay();
      }
    })
  }
}