import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import {
    ProcessPaymentService,
    UnPaidInvoices,
    ProofOfPaymentLines,
    TransactionLinesAdded,
    BankTransaction
} from '../../../services/process-payment/process-payment.service';
import * as moment from 'moment';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { Router } from '@angular/router';
import {
    PATH_RECONCILE_BANK_TRANSACTIONS_INVOICE,
    PATH_PROOF_OF_PAYMENTS
} from '../../../services/appdata/app-config.service';
import { AddedInvoiceToTransaction } from '../invoices-added-bank-transaction/invoices-added-bank-transaction.component';
import { InvoiceDetails, InvoiceDetailsService, DEFAULT_INVOICE_DETAILS } from '../../../services/invoice-details/invoice-details.service';
import {
    NotesService, NotesObject, NotesObjectHandle,
} from '../../../services/notes/notes.service';
import { QuotationService, QuotationFeeItem } from '../../../services/quotation/quotation.service';

export interface TransactionLinesHandle {
    data: TransactionLinesAdded;
    state: {
        isVisible: boolean;
        isSelected: boolean;
        isOpen: boolean;
    };
}

@Component({
    selector: 'app-available-invoices-bank-transaction',
    templateUrl: './available-invoices-bank-transaction.component.html',
    styleUrls: ['./available-invoices-bank-transaction.component.scss']
})
export class AvailableInvoicesBankTransactionComponent implements OnInit, OnChanges {

    checkBoxSelected: any = [];
    showActions = false;
    searchTerm: any;
    promptInvoices = false;
    availableInvoiceHandle: TransactionLinesHandle[] = [];
    selectAll: boolean;

    invoices: any = [];
    _invoices: any = [];
    availableInvoices: any = [];

    sortBy: string;
    previousSortBy: string;
    sortAscending: boolean;
    sortDate: boolean;
    sortInvoice: boolean;
    sortLawfirm: boolean;
    sortMatter: boolean;
    sortReference: boolean;
    sortAmount: boolean;

    // for Invoice Preview Modal
    showProformaInvoice: boolean;
    invoicePlaintiff: string;
    invoiceDefendant: string;
    invoiceDetails: InvoiceDetails;
    feesQuoted: QuotationFeeItem[] = [];

    selectedInvoiceId = 0;
    proofOfPayments: any[] = [];
    popToDelete: any[] = [];
    showLinkedToPOPPrompt: boolean;

    // Invoice Note
    selectedTab: string;
    // notes
    notes: NotesObject[] = [];
    notesHandle: NotesObjectHandle[] = [];
    showInvoiceNoteDetail: boolean;
    objectType: string;
    onShowNoteDetail: boolean;
    noteDetailData: NotesObject;

    profileTypes = {
        ADVOCATE: 'Advocate',
        ASSISTANT: 'Assistant',
        ATTORNEY: 'Attorney',
        PRACTICE_MANAGER: 'Practice Manager',
        EXTERNAL_ATTORNEY: 'Attorney (External)',
        LAWFIRM: 'Lawfirm',
        EXTERNAL_LAWFIRM: 'Lawfirm (External)'
    };

    // @Input() availableInvoiceHandle: TransactionLinesHandle[];
    @Input() isAttorney: boolean;
    @Input() isLawfirm: boolean;
    @Input() selectedBankTransaction: BankTransaction;
    @Input() addedInvoiceToTransaction: AddedInvoiceToTransaction[];
    @Output() promptAvailableInvoices: EventEmitter<number> = new EventEmitter<number>();
    @Input() showInvoices: EventEmitter<any> = new EventEmitter<any>();
    @Input() putInvoices: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('inputCheckbox') inputCheckbox: ElementRef;
    @ViewChild('proformaInvoice') proformaInvoice: ElementRef;

    get currencySymbol(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
        } else {
            return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
        }
    } // end currencySymbol()

    // Is Practice Manager
    get isPracticeManager(): boolean {
        return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
    }  // end isPracticeManager()

    constructor(
        private notesService: NotesService,
        private loadingService: LoadingService,
        private quotationService: QuotationService,
        private snackbarsService: SnackbarsService,
        private userProfileService: UserProfileService,
        private invoiceDetailService: InvoiceDetailsService,
        private processPaymentService: ProcessPaymentService,
        private router: Router
    ) {
    } // end constructor()

    ngOnInit() {
        // this.showInvoices.subscribe((response) => {
        //     if (response) {
        //         this.invoices = [];
        //         this.promptInvoices = true;
        //         response.sort((a, b) => { return b.Rank - a.Rank });
        //         var id = 1;
        //         var data = [];
        //         for (let i of response) {
        //             i.orderId = id;
        //             data.push(i);
        //             id = id + 1;
        //         }
        //         this.getAvailableInvoices(data);
        //     } else {
        //         this.promptInvoices = false;
        //     }
        // });

        // this.putInvoices.subscribe((response) => {
        //     if (response) {
        //         var data = [];
        //         data.push(response);
        //         this.getAvailableInvoices(response);
        //     }
        // });
        this.processPaymentService.getProofOfPayment().subscribe({next: (_proof_of_payment) => {
            this.proofOfPayments = _proof_of_payment;
        }});
    } // end ngOnInit()

    getPossibleMatches(bankTransaction: BankTransaction) {
        this.clearSearch();
        this.availableInvoiceHandle = [];
        this.loadingService.showOverlay();
        this.processPaymentService.getPossibleMatches(bankTransaction.Id)
            .subscribe({next: (invoices) => {
                invoices.AvailableInvoices.forEach(invoice => {
                    if (invoice.Amount > 0) {
                        if (this.addedInvoiceToTransaction && this.addedInvoiceToTransaction.length > 0) {
                            // this.addedInvoiceToTransaction.forEach(added => {
                            //     if (added.data.InvoiceId === invoice.Inv)
                            // });
                            if (this.addedInvoiceToTransaction.find(line => line.data.InvoiceId === invoice.InvoiceId)) {

                            } else {
                                // bankTransaction.BankTransactionLines.forEach(line => {
                                // if (line.InvoiceId !== invoice.InvoiceId) {
                                this.availableInvoiceHandle.push({
                                    data: invoice,
                                    state: {
                                        isVisible: true,
                                        isSelected: false,
                                        isOpen: false
                                    }
                                });
                            }
                            // });
                        } else {
                            this.availableInvoiceHandle.push({
                                data: invoice,
                                state: {
                                    isVisible: true,
                                    isSelected: false,
                                    isOpen: false
                                }
                            });
                        }
                    }
                });
            },
                error: (error) => {
                    // On Error
                    this.loadingService.hideOverlay();
                },
                complete: () => {
                    // On complete
                    this.promptInvoices = true;
                    this.loadingService.hideOverlay();
                }
            });
    } // end getPossibleMatches()

    onViewInvoice(invoiceId: number) {
        this.loadingService.showOverlay();
        this.notes = [];
        this.notesHandle = [];
        this.selectedTab = 'invoice';
        if (invoiceId) {
            let _note = [];
            if (this.isPracticeManager) {
             this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
                invoiceId, 4).subscribe({next: (_n_note) => { _note = _n_note; }});
            } else if (!this.isPracticeManager) {
                this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
                    invoiceId, 4).subscribe({next: (_n_note) => { _note = _n_note; }});
            }
            if (!this.isPracticeManager) {
                this.notes = _note.filter(x => x.QuestionText);
            } else {
                this.notes = _note;
            }
            this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
            // this.showProformaInvoice = true;
            this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({next: (_invoice_detail) => {
                this.invoiceDetails = _invoice_detail;
                this.selectedInvoiceId = invoiceId;
                this.getAllQuotedFees(this.invoiceDetails.ServiceId);
                if (this.invoiceDetails) {
                    const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                    if (desc.length > 0) {
                        this.invoicePlaintiff = desc[0];
                    }
                    if (desc.length > 1) {
                        this.invoiceDefendant = desc[1];
                    }
                }
            }});

            this.notes.forEach(_notes => {
                this.notesHandle.push({
                    data: _notes,
                    state: {
                        isVisible: true,
                        isSelected: false,
                        isOpen: false
                    }
                });
            });
        }
        this.showProformaInvoice = true;
        this.loadingService.hideOverlay();
    } // end onViewInvoice()

    ngOnChanges(changes: SimpleChanges) {
        // if (changes.selectedBankTransaction && changes.selectedBankTransaction.currentValue) {
        //     if (this.selectedBankTransaction && this.selectedBankTransaction.Id > 0) {
        //         this.getPossibleMatches(this.selectedBankTransaction);
        //     } else {
        //         this.promptInvoices = false;
        //     }
        // }


        if (changes.addedInvoiceToTransaction && changes.addedInvoiceToTransaction.currentValue) {
            if (this.selectedBankTransaction && this.selectedBankTransaction.Id > 0) {
                this.getPossibleMatches(this.selectedBankTransaction);
            } else {
                this.promptInvoices = false;
            }
        }
        // if (changes.availableInvoiceHandle && changes.availableInvoiceHandle.currentValue) {
        //     if (this.availableInvoiceHandle && this.availableInvoiceHandle.length > 0) {
        //         this.promptInvoices = true;
        //     } else {
        //         this.promptInvoices = false;
        //     }
        // }
    } // end ngOnChanges()

    toggleSort(sortBy: string) {
        this.sortBy = sortBy;
        if (this.sortBy === this.previousSortBy) {
            this.sortAscending = !this.sortAscending;
        }

        switch (this.sortBy) {
            case 'date':
                this.availableInvoiceHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = true;
                        this.sortInvoice = false;
                        this.sortLawfirm = false;
                        this.sortMatter = false;
                        this.sortReference = false;
                        this.sortAmount = false;
                        return a.data.Date.toUpperCase().localeCompare(b.data.Date.toUpperCase());
                    }
                    this.sortDate = false;
                    return b.data.Date.toUpperCase().localeCompare(a.data.Date.toUpperCase());
                });
                break;
            case 'invoice':
                this.availableInvoiceHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortInvoice = true;
                        this.sortLawfirm = false;
                        this.sortMatter = false;
                        this.sortReference = false;
                        this.sortAmount = false;
                        return a.data.InvoiceNo.toUpperCase().localeCompare(b.data.InvoiceNo.toUpperCase());
                    }
                    this.sortInvoice = false;
                    return b.data.InvoiceNo.toUpperCase().localeCompare(a.data.InvoiceNo.toUpperCase());
                });
                break;
            case 'lawfirm':
                this.availableInvoiceHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortInvoice = false;
                        this.sortLawfirm = true;
                        this.sortMatter = false;
                        this.sortReference = false;
                        this.sortAmount = false;
                        return a.data.Client.toUpperCase().localeCompare(b.data.Client.toUpperCase());
                    }
                    this.sortLawfirm = false;
                    return b.data.Client.toUpperCase().localeCompare(a.data.Client.toUpperCase());
                });
                break;
            case 'matter':
                this.availableInvoiceHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortInvoice = false;
                        this.sortLawfirm = false;
                        this.sortMatter = true;
                        this.sortReference = false;
                        this.sortAmount = false;
                        return a.data.Matter.toUpperCase().localeCompare(b.data.Matter.toUpperCase());
                    }
                    this.sortMatter = false;
                    return b.data.Matter.toUpperCase().localeCompare(a.data.Matter.toUpperCase());
                });
                break;
            case 'reference':
                this.availableInvoiceHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortInvoice = false;
                        this.sortLawfirm = false;
                        this.sortMatter = false;
                        this.sortReference = true;
                        this.sortAmount = false;
                        return a.data.Reference.toUpperCase().localeCompare(b.data.Reference.toUpperCase());
                    }
                    this.sortReference = false;
                    return b.data.Reference.toUpperCase().localeCompare(a.data.Reference.toUpperCase());
                });
                break;
            case 'amount':
                this.availableInvoiceHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = false;
                        this.sortInvoice = false;
                        this.sortLawfirm = false;
                        this.sortMatter = false;
                        this.sortReference = false;
                        this.sortAmount = true;
                        return a.data.Amount - b.data.Amount;
                    }
                    this.sortAmount = false;
                    return b.data.Amount - a.data.Amount;
                });
                break;
        }
        this.previousSortBy = this.sortBy;
    } // end toggleSort()

    initAvailableInvoices() {
        this.availableInvoiceHandle = [];
        this.invoices.forEach(invoice => {
            this.availableInvoiceHandle.push({
                data: invoice,
                state: {
                    isVisible: true,
                    isSelected: false,
                    isOpen: false
                }
            });
        });
        this.sortData();
    } // end initAvailableInvoices()

    sortData() {
        this.availableInvoiceHandle.sort((a, b) => {
            return a.data.Rank - b.data.Rank;
        });
    } // end sortData()

    formatNumber(paid: string) {
        return Number((paid.toString().split('R')[1]).toString().split(' ').join(''));
    }

    // onChangeCheckBox(Invoice: any, isChecked: boolean) {
    //     if (isChecked) {
    //         this.checkBoxSelected.push(Invoice);
    //     } else {
    //         let index = this.checkBoxSelected.indexOf(Invoice);
    //         this.checkBoxSelected.splice(index, 1);
    //     }

    //     if (this.checkBoxSelected.length > 0) {
    //         this.showActions = true;
    //     } else {
    //         this.showActions = false;
    //     }
    // }

    toggleCheckbox(invoice: TransactionLinesHandle) {
        invoice.state.isSelected = !invoice.state.isSelected;
        if (this.availableInvoiceHandle.filter(_invoic => _invoic.state.isSelected === true).length > 0) {
            this.showActions = true;
            this.inputCheckbox.nativeElement.checked = false;
        } else {
            this.showActions = false;
            // this.inputCheckbox.nativeElement.checked = true;
        }
    } // end toggleCheckbox()

    toggleSelectAll() {
        // this.selectAll = !this.selectAll;
        this.availableInvoiceHandle.forEach(invoice => {
            if (invoice.state.isVisible) {
                invoice.state.isSelected = this.inputCheckbox.nativeElement.checked;
                this.showActions = this.inputCheckbox.nativeElement.checked;
            }
        });
    } // end toggleSelectAll()

    getAvailableInvoices(data: any) {
        for (let i of data) {
            this.invoices.push(i);
        }
        // this.invoices.sort((a, b) => { return a.Rank - b.Rank });
        this._invoices = this.invoices;
        this.initAvailableInvoices();
    }

    // getDateFormet(value: Date): string {
    //     return moment(value).format('DD/MM/YYYY');
    // }

    // numberConversion(value: number): string {
    //     return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    // }

    promptShowProofOfPaymentError() {
        let popLines: any;
        this.availableInvoiceHandle.forEach(invoice => {
            if (this.addedInvoiceToTransaction.find(line => line.data.InvoiceId === invoice.data.InvoiceId)) {

            } else {
                if (invoice.state.isSelected === true) {

                    if (this.proofOfPayments.filter(a => a.ProofOfPaymentLines
                        .find(c => c.InvoiceId === invoice.data.InvoiceId)).length > 0) {

                        popLines = this.proofOfPayments
                            .filter(a => a.ProofOfPaymentLines.find(c => c.InvoiceId === invoice.data.InvoiceId));
                        this.popToDelete.push(popLines[0]);
                        this.showLinkedToPOPPrompt = true;
                    } else {
                        this.postProofOfPaymentLines();
                    }
                }
            }
        });

    } // end promptShowProofOfPaymentError()

    postProofOfPaymentLines() {
        if (this.showActions) {
            const snack: Snack = {
                label: 'Add Bank Transaction Line',
                action: null
            };
            try {
                var data: any = [];
                this.availableInvoiceHandle.forEach(invoice => {
                    if (invoice.state.isSelected === true) {
                        invoice.state.isSelected = false;
                        invoice.data.Paid = invoice.data.Paid;

                        this.invoices = this.invoices.filter(inv => inv !== invoice.data);
                        this.availableInvoiceHandle = this.availableInvoiceHandle.filter(_inv => _inv.data !== invoice.data);
                        data.push(invoice.data);
                    }
                });
                this.showActions = false;
                // for (let i of this.checkBoxSelected) {
                //     data.push(i);
                //     let index = this.invoices.indexOf(i);
                //     this.invoices.splice(index, 1);
                // }
                this.promptAvailableInvoices.emit(data);
                data = [];
                // this.availableInvoiceHandle.forEach(inv => {
                //     if (inv.state.isSelected === true) {
                //         inv.state.isVisible = false;
                //         inv.state.isSelected = false;
                //     }
                // });
                // snack.label = 'Added Selected Transaction Line.';
                // snack.type = SnackType.SUCCESS;
                // this.snackbarsService.dismiss().make(snack).show();
            } catch (e) {
                snack.label = 'Something went wrong';
                snack.action = null;
                snack.type = SnackType.ERROR;
                this.snackbarsService.dismiss().make(snack).show();
            }

            this.showActions = false;
            this.selectAll = false;
            this.checkBoxSelected = [];
            this.inputCheckbox.nativeElement.checked = false;
            this.clearSearch();
        }
    }

    onHideLinkedToPOPPrompt() {
        this.showLinkedToPOPPrompt = false;
        // this.router.navigate([PATH_PROOF_OF_PAYMENTS]);
    } // end onHideLinkedToPOPPrompt()

    onProofOfPaymentDelete() {
        this.loadingService.showOverlay();
        for (const pop of this.popToDelete) {
             this.processPaymentService.deleteProofOfPayment(pop.Id).subscribe({next: (_delete) => {}});
        }
        this.postProofOfPaymentLines();
        this.showLinkedToPOPPrompt = false;
        this.loadingService.hideOverlay();
        this.popToDelete = [];
    } // end onProofOfPaymentDelete()

    // onKeyDown(event) {
    //     switch (event.key) {
    //         case 'Enter':
    //             this.performSearch();
    //             break;
    //     }
    // } // end onKeyDown()

    performSearch() {
        if (this.searchTerm) {
            this.availableInvoiceHandle.forEach(invoice => {
                if (
                    !(invoice.data.Date.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(invoice.data.InvoiceNo.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(invoice.data.Client.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(invoice.data.Matter.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(invoice.data.Reference.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(invoice.data.Amount.toString().match(new RegExp(this.searchTerm, 'gi')))
                ) {
                    invoice.state.isVisible = false;
                } else {
                    invoice.state.isVisible = true;
                }
            });
        } else {
            this.availableInvoiceHandle.forEach(invoice => {
                invoice.state.isVisible = true;
            });
        }
        // this.invoices = this._invoices.filter((f) => {
        //     return f.Client.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
        //         f.Reference.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
        //         f.Matter.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
        //         f.InvoiceNo.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
        //         this.getDateFormet(f.Date) === this.searchTerm ||
        //         'R' + this.numberConversion(f.Amount) === this.searchTerm ||
        //         'R' + this.numberConversion(f.Paid) === this.searchTerm;
        // });
    }

    clearSearch() {
        this.searchTerm = '';
        this.performSearch();
        this.sortData();
    } // end clearSearch()

    closeModal(value: string) {
        switch (value) {
            case 'proforma-invoice':
                this.showProformaInvoice = false;
                this.getPossibleMatches(this.selectedBankTransaction);
            break;
        }
    } // end closeModal()

    getAllQuotedFees(serviceId: number) {
        this.feesQuoted = [];
        this.quotationService.getActualFeesQuoted(serviceId).subscribe({next: (_actual_fees) => {
            this.feesQuoted = _actual_fees;
        }});
    } // end getAllQuotedFees()
} // end AvailableInvoicesBankTransactionComponent{}
