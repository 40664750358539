import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export interface Assistant {
  ContactId: number;
  FullName: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Role: number;
  RoleName: string;
  Status: string;
} // end Assistance{}

export const DEFAULT_ASSISTANT = {
  ContactId: 0,
  FullName: '',
  FirstName: '',
  LastName: '',
  Email: '',
  Role: 0,
  RoleName: '',
  Status: ''
};

@Injectable()
export class AddTeamMemberService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  /**
   * POST send request to add new Assistant to Auxcon team via api
   * @param {Assistant} Assistant by ServiceProviderId
   */
  AddAssistant(addAssistant: Assistant): Observable<Assistant> {
    const postAssistant = {
      ContactId: addAssistant.ContactId,
      FullName: addAssistant.FullName,
      FirstName: addAssistant.FirstName,
      LastName: addAssistant.LastName,
      Email: addAssistant.Email,
      Role: addAssistant.Role
    };

    return this.http.post<any>(
      this.api.endpoints.addAssistant +
      '?ServiceProviderId=' + this.api.serviceProviderID,
      postAssistant,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  } // end AddAssistant()

  getAssistant(): Observable<any> {
    return this.http.get<Assistant[]>(
      this.api.endpoints.addAssistant +
      '?ServiceProviderId=' + this.api.serviceProviderID,
      this.api.httpOptions
    ).pipe(
      retry(3),
      catchError(this.api.handleError)
    );
  } // end getAssistant()

  updateAssistant(assistant: Assistant): Observable<any> {
    return this.http.put(this.api.endpoints.addAssistant +
        '?ServiceProviderId=' + this.api.serviceProviderID,
        assistant, this.api.httpOptions)
        .pipe(
          retry(3),
          catchError(this.api.handleError)
        );
  } // end updateAssistant()

  deleteAssistant(assistantID: number): Observable<{}> {
    return this.http.delete<Assistant>(this.api.endpoints.addAssistant +
      '?ServiceProviderId=' + this.api.serviceProviderID +
      '&AssistantId=' + assistantID, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  }
} // end AddTeamMemberService()
