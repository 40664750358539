<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>

<div class="container-fluid">
    <nav class="report-nav">
        <a class="tab"
            (click)="togglePanel('collection-report')">Collections Report</a>
    </nav>

    <div class="contact-body row">
        <div class="left-block" style="width: 100%; min-height: 272px !important;">
            <div class="block-title">Collections Report</div>
            

            <!-- CollectorId, Start Date and End Date Report Filters -->
            <div class="row contact-search input-group" style="display: block;">
                <!-- <label for="collector">Select Collector: </label>
                <select class="w-20" [(ngModel)]="selectedCollector">
                    <option
                    *ngFor="let col of collectors"
                    [value]="col.value">{{col.label}}</option>
                </select> -->

                <label for="date" class="fromTo-date">Date Range: </label>
                <input
                    type="text"
                    name="fromDate"
                    readonly
                    class="input-date"
                    readonly
                    bsDaterangepicker
                    placeholder="2010-01-01"
                    placement="right"
                    [(ngModel)]="selectedCollectionDate"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                    (bsValueChange)="onDateValueChange($event)">
                    <!-- <i
                    class="fa fa-calendar"
                    (click)="_fromDate.toggle()"
                    [attr.aria-expanded]="_fromDate.isOpen"></i> -->

                 <!-- <label for="date" class="fromTo-date">End Date: </label>
                <input
                    type="text"
                    name="fromDate"
                    readonly
                    class="input-date"
                    #_fromDate="bsDatepicker"
                    readonly
                    bsDatepicker
                    placeholder="2010-01-01"
                    placement="bottom"
                    [(ngModel)]="selectedCollectionEndDate"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                    (bsValueChange)="onEndDateValueChange($event)"> -->
                    <!-- <i
                    class="fa fa-calendar"
                    (click)="_fromDate.toggle()"
                    [attr.aria-expanded]="_fromDate.isOpen"></i> -->

                    <p class="fas fa-arrow-alt-circle-right" (click)="getCollectionReportData()"></p>
            </div>

            <div class="row m-0">
                <div class="table-responsive" style="max-height: 596px; overflow-y: auto;">
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th scope="col">Collector</th>
                                <th scope="col">Assigned Files</th>
                                <th scope="col">Diarised Files</th>
                                <th scope="col">Recent Instruction Files</th>
                                <th scope="col">Total Files</th>
                                <th scope="col">Assigned Files Not Worked</th>
                                <th scope="col">Diarised Files Not Worked</th>
                                <th scope="col">Recent Instr. Files Not Worked</th>
                                <th scope="col">Total Files Not Worked</th>
                            </tr>
                            <tr class="tr" *ngFor="let collections of collectionsDailyReportSummary" [class.active]="row === collections.CollectorId">
                                <td (click)="onCollectorClick(collections)">{{ collections.Collector }}</td>
                                <td (click)="onCollectorClick(collections)">{{ collections.AssignedFiles }}</td>
                                <td (click)="onCollectorClick(collections)">{{ collections.DiarisedFiles }}</td>
                                <td (click)="onCollectorClick(collections)">{{ collections.RecentInstructionsFiles }}</td>
                                <td (click)="onCollectorClick(collections)">{{ collections.TotalFiles }}</td>
                                <td (click)="onCollectorClick(collections)">{{ collections.AssignedNotWorkedFiles }}</td>
                                <td (click)="onCollectorClick(collections)">{{ collections.DiarisedNotWorkedFiles }}</td>
                                <td (click)="onCollectorClick(collections)">{{ collections.RecentInstructionNotWorkedFiles }}</td>
                                <td (click)="onCollectorClick(collections)">{{ collections.TotalFilesNotWorked }}</td>
                            </tr>
                            <!-- <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td colspan="2">
                                    <button
                                        style="float: right;"
                                        class="btn btn-success"
                                        (click)="onGenerateExcelReport()">Generate Excel</button>
                                </td>                                
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>

            
            
        </div>
    </div>
</div>
<div class="container-fluid" *ngIf="collectionsDailyReportDetail && row">
    <div class="contact-body row">
        <div class="left-block" style="width: 100%;">
            <div class="block-title" style="width: 80%;">Selected Collector: {{selectedCollectorName}}</div>
            
            <div class="block-title" style="width: 20%;"><label>Filter: &nbsp;&nbsp;</label>
            <select
                id="status"
                style="width: 200px;"
                [(ngModel)]="selectedStatus">
                <option [value]="null">Choose a Status</option>
                <option 
                    *ngFor="let status of collectionsStatusFilter"
                    [value]="status.value"
                    [selected]="status.value === selectedStatus">{{status.label}}</option>
            </select></div>
            
            <div class="row m-0">
                <div class="table-responsive" style="max-height: 596px; overflow-y: auto;">
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <th scope="col">Law Firm</th>
                                <th scope="col">Total Amount</th>
                                <th scope="col">Advocate Count</th>
                                <th scope="col">Invoice Count</th>
                                <th scope="col">Assigned Date</th>
                                <th scope="col">Last Worked Date</th>
                                <th scope="col">Status</th>
                            </tr>
                            <tr class="tr" *ngFor="let collections of collectionsDailyReportDetailHandle">
                                <td *ngIf="collections.state.isVisible" (click)="onCollectorClick(collections.data)">{{ collections.data.Lawfirm }}</td>
                                <td *ngIf="collections.state.isVisible" (click)="onCollectorClick(collections.data)">{{ collections.data.TotalAmount | currency:currencySymbol}}</td>
                                <td *ngIf="collections.state.isVisible" (click)="onCollectorClick(collections.data)">{{ collections.data.AdvocateCount }}</td>
                                <td *ngIf="collections.state.isVisible" (click)="onCollectorClick(collections.data)">{{ collections.data.InvoiceCount }}</td>
                                <td *ngIf="collections.state.isVisible" (click)="onCollectorClick(collections.data)">{{ collections.data.AssignedDate | date:'yyyy-MM-dd'}}</td>
                                <td *ngIf="collections.state.isVisible" (click)="onCollectorClick(collections.data)">{{ collections.data.LastWorkedDate | date:'yyyy-MM-dd'}}</td>
                                <td *ngIf="collections.state.isVisible" (click)="onCollectorClick(collections.data)">{{ collections.data.Status }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>