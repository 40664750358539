<div class="display-options">
  <span class="column-search form">
    <button
    class="btn btn-text"
    (click)="onSearch()">
    <i class="fa fa-search"></i>
  </button>
    <input
      type="search"
      (click)="clearSearch()"
      placeholder="Search for matters, Law firms or invoices to see the results here"
      (keydown)="onKeyDown($event)"
      [(ngModel)]="searchTerm"
      autofocus>
  </span>
</div><!--#end .display-options -->

<!-- <div class="row row-search">
  <span class="column-input-search">
    <input
      type="search"
      placeholder="Start typing to search"
      (keydown)="onKeyDown($event)"
      [(ngModel)]="searchTerm"
      autofocus>
  </span>#end .column-input-search
  <span class="column-input-search-btn">
    <button
      class="btn btn-success"
      (click)="onSearch()">
      <i class="fa fa-search fa-lg"></i>
    </button>
  </span>#end .column-input-search-btn
</div>#end .row -->

<div class="row row-results">
  <div class="column-search-filters">
    <h2 class="title">Search filters</h2>
    <div class="form-group" [class.hide]="!searchTerm || !searchResults.length">
      <app-checkbox
        [(checked)]="filters.matters"
        (click)="toggleChecbox('matters')"></app-checkbox>
      <label for="filter-matters">Matters ({{totalMatters}})</label>
    </div>

    <div class="form-group" [class.hide]="!searchTerm || !searchResults.length">
      <app-checkbox
        [(checked)]="filters.lawFirms"
        (click)="toggleChecbox('lawFirms')"></app-checkbox>
      <label for="filter-law-firms">Law firms ({{totalLawfirms}})</label>
    </div>

    <div class="form-group" [class.hide]="!searchTerm || !searchResults.length">
      <app-checkbox
        [(checked)]="filters.invoices"
        (click)="toggleChecbox('invoices')"></app-checkbox>
      <label for="filter-invoices">Invoices ({{totalInvoices}})</label>
    </div>
  </div>

  <div class="column-search-results">
    <div
      *ngIf="!searchResults.length && !isLoading"
      class="search-results-placeholder">
      <h2 class="title">Start searching</h2>
      <h1><i class="fa fa-search fa-lg"></i></h1>
    </div>
    <h2 *ngIf="searchResults.length" class="title">Search results</h2>
    <div *ngIf="searchResults.length" class="table">

      <div class="table-body table-summary">
        <div class="table-row">
          <span class="column-search-summary">
            <span *ngIf="searchResults.length !== 1">
               There are {{searchResults.length}} search results
            </span>
            <span *ngIf="searchResults.length === 1">
               There is {{searchResults.length}} search result
            </span>
            &nbsp;for "{{searchTerm}}"
          </span>

          <span class="column-sort-by">
            <label for="sort-by">Sort by:</label>
            <select
              id="sort-by"
              (change)="sort()"
              [(ngModel)]="sortBy">
              <option
                *ngFor="let option of sortOptions"
                [value]="option">{{option | titlecase}}</option>
            </select>
            <button
              class="btn btn-toggle"
              (click)="toggleSortDirection()"
              [title]="sortAscending ? 'Toggle descending' : 'Toggle ascending'">
              <i
                class="fa"
                [class.fa-sort-amount-desc]="!sortAscending"
                [class.fa-sort-amount-asc]="sortAscending"></i>
            </button>
          </span>
        </div>
      </div><!--#end .table-body -->

      <div class="table-head">
        <div class="table-row">
          <span class="column-type">Type</span>
          <span class="column-description">Description</span>
          <!-- <span class="column-created">Created</span> -->
        </div>
      </div><!--#end .table-head -->

      <div
        [class.hide]="isLoading"
        class="table-body table-body-results">
        <app-search-results-table-row
          *ngFor="let result of filteredSearchResults; let i = index;"
          [resultItem]="result"
          [itemIndex]="i"></app-search-results-table-row>
      </div><!--#end .table-body -->
    </div><!--#end .table -->
    <div *ngIf="!searchResults.length"></div>
    <div *ngIf="isLoading">Searching...</div>
  </div><!--# .column-search-filters -->
</div><!--#end .row -->
