<div class="row">
 <!-- <app-practice-manager-dashboard-overview
 [widgetData]="widgetData"></app-practice-manager-dashboard-overview> -->

 <!-- Invoiced chart -->
 <!-- <app-practice-manager-invoiced-chart
 [dataMonthly]="invoicedData" [dataYearly]="invoicedYearlyData"></app-practice-manager-invoiced-chart> -->

 <!-- Receipt chart -->
 <!-- <app-practice-manager-receipt-chart
 [dataMonthly]="collectedData" [dataYearly]="collectedYearlyData"></app-practice-manager-receipt-chart> -->

</div> <!-- #end .row -->
<!-- Practice manager Summary -->
<app-practice-m-summary
*ngIf="practiceOverdueSummary"
[practiceAgeAnalysis]="practiceAgeAnalysis"
[practiceOverdueSummary]="practiceOverdueSummary"></app-practice-m-summary>