import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  UserProfile,
  UserProfileService,
} from '../../../services/user-profile/user-profile.service';
import {
  ContactRelationship,
  AdvocateOverviewServiceService,
  PersonalDetails,
  DEFAULT_PERSONAL_DETAILS,
  DEFAULT_CONTACT_RELATIONSHIP
} from '../../../services/advocate-overview-service/advocate-overview-service.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import * as moment from 'moment';
import { Advocates, AdvocatesService } from '../../../services/advocates/advocates.service';
import {
  Snack,
  SnackbarsService,
  SnackType
} from '../../../services/messaging/snackbars/snackbars.service';
import { LENGTH_LONG } from '../../../services/messaging/toast/toast.service';
import { DateParameters } from '../../../services/manage-BlackList/manage-BlackList.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {

  private profile: UserProfile;
  practiceType: any;
  practiceInvolvement: any;
  preAdvocacy: any;
  relationshipStatus: any;
  businessType: any;
  admissionDay: any[];
  admissionYear: any[];
  checkAdmissionMonth: number;
  checkAdmissionYear: number;
  checkAdmissionDay: number;
  isValidAdmissionMonth: boolean = false;
  isValidAdmissionYear: boolean = false;
  showBusinessRelationshipRemovePrompt: boolean;
  specialities: any;

  dateParameters: DateParameters = {
    AdmissionDay: null,
    AdmissionMonth: null,
    AdmissionYear: null
  };

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  advocates: Advocates[] = [];
  showAddRelationModal: boolean;

  contactRelationship: ContactRelationship[] = [];
  relationshipToDelete: ContactRelationship = Object.assign({}, DEFAULT_CONTACT_RELATIONSHIP);

  get internalAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile &&
      this.userProfileService.userProfile.isImpersonator) &&
      !Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY)
      && Boolean(this.userProfileService.userProfile.serviceProviderID === 9091 ||
        this.userProfileService.userProfile.serviceProviderID === 29096 ||
        this.userProfileService.userProfile.serviceProviderID === 21647);
  } // end internalAssistant()

  get isPracticeManager(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  } // end isPracticeManager()

  @Input() workingDetails: PersonalDetails;
  @Input() details: PersonalDetails;
  @Output() isValidateAdmissionDate: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private loadingService: LoadingService,
    private advocatesService: AdvocatesService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private advocateOverviewService: AdvocateOverviewServiceService
  ) { } // end constructor()

  onHideBusinessRelationshipRemovePrompt() {
    this.relationshipToDelete = Object.assign({}, DEFAULT_CONTACT_RELATIONSHIP);
    this.showBusinessRelationshipRemovePrompt = false;
  } // end onHideBusinessRelationshipRemovePrompt()

  isInputEmpty(field: string): boolean {
    return !this.workingDetails[field] || this.workingDetails[field].trim() === '';
  }

  ngOnInit() {
    this.admissionDay = [];
    for (let index = 1; index <= 31; index++) {
      this.admissionDay.push(index);
    }
    this.admissionYear = [];
    let years = moment().year();
    for (let index = 1960; index <= years; index++) {
      this.admissionYear.push({
        year: index
      });
    }

    this.admissionYear.sort((a, b) => (a.year < b.year ? 1 : -1));
    this.dateParameters.AdmissionDay = this.workingDetails.AdmissionDay;
    this.dateParameters.AdmissionMonth = this.workingDetails.AdmissionMonth;
    this.dateParameters.AdmissionYear = this.workingDetails.AdmissionYear;

    this.loadingService.showOverlay();
    this.contactRelationship = [];
    // this.contactRelationship = this.advocateOverviewService.getContactRelationships().toPromise();

    this.practiceType = [
      { label: 'Commercial', value: 'Commercial' },
      { label: 'Mixed', value: 'Mixed' },
      { label: 'Road Accident Fund', value: 'Road Accident Fund' },
    ];
    this.practiceInvolvement = [
      { label: 'Fairly Involved', value: 'Fairly Involved' },
      { label: 'Involved', value: 'Involved' },
      { label: 'Not Involved', value: 'Not Involved' },
    ];
    this.preAdvocacy = [
      { label: 'Attorney', value: 'Attorney' },
      { label: 'None', value: 'None' },
      { label: 'Other', value: 'Other' },
    ];
    this.relationshipStatus = [
      { label: 'Divorced', value: 'Divorced' },
      { label: 'In a Relationship', value: 'In a Relationship' },
      { label: 'Married', value: 'Married' },
      { label: 'Single', value: 'Single' },
      { label: 'Widowed', value: 'Widowed' },
    ];
    this.businessType = [
      { label: 'Business Relationship', value: 'Business Relationship' }
    ];
    this.specialities = [
      { label: 'Banking and Finance Law', value: 'Banking and Finance Law' },
      { label: 'Corporate and Commercial Law', value: 'Corporate and Commercial Law' },
      { label: 'Employment Law', value: 'Employment Law' },
      { label: 'Environmental and Mining Law', value: 'Environmental and Mining Law' },
      { label: 'Family and Matrimonial Law', value: 'Family and Matrimonial Law' },
      { label: 'General Litigation', value: 'General Litigation' },
      { label: 'Insolvency Law', value: 'Insolvency Law' },
      { label: 'Intellectual Property Law', value: 'Intellectual Property Law' },
      { label: 'Personal Injury Law', value: 'Personal Injury Law' },
      { label: 'Property Law', value: 'Property Law' },
      { label: 'Tax Law', value: 'Tax Law' },
      { label: 'Other', value: 'Other' },
    ];
    this.loadingService.hideOverlay();
  } // end ngOnInit()

  parseDate(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return new Date('1000-01-01');
    }
  } // end parseDate()

  // onDateValueChange(value: Date): void {
  //   this.workingDetails.AdmissionDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  // } // end onDateValueChange()

  toggleJunior() {
    this.workingDetails.Seniority = 'Junior';
  } // end toggleJunior()

  toggleSenior() {
    this.workingDetails.Seniority = 'Senior';
  } // end toggleSenior()

  onChangeEthnicity(event) {

  } // end onChangeEthnicity()

  onChangeGender(event) {
     
  } // end onChangeGender()

  getAdvocatesList() {
    // this.advocates =  this.advocatesService.getAdvocates().toPromise();
    this.advocates = this.advocates.filter(c => c.AdvocateId !== this.userProfileService.selectedUserProfile.serviceProviderID);
  } // end getAdvocatesList()

  onAddBusinessRelationship() {
    this.loadingService.showOverlay();
    this.getAdvocatesList();
    this.showAddRelationModal = true;
    this.loadingService.hideOverlay();
  } // end onAddBusinessRelationship()

  onDismissAddRelationModal() {
    this.showAddRelationModal = false;
  } // end onDismissAddRelationModal()

  onSaveAddRelation(data: ContactRelationship) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Add relationship...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.contactRelationship = [];
    this.advocateOverviewService.getContactRelationships().subscribe({
      next: (_data) => {
        // On next
        this.contactRelationship = _data;
      },
      error: (error) => {
        // On error
        snack.label = 'Error adding relationship.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        snack.label = 'Relationship added.';
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    });
    // _data => {
    //   // On next
    //   this.contactRelationship = _data;
    // },
    // error => {
    //   // On error
    //   snack.label = 'Error adding relationship.';
    //   snack.type = SnackType.ERROR;
    //   this.snackbarsService.dismiss().make(snack, 5000).show();
    //   this.loadingService.hideOverlay();
    // },
    // () => {
    //   // On complete
    //   snack.label = 'Relationship added.';
    //   snack.type = SnackType.SUCCESS;
    //   this.snackbarsService.dismiss().make(snack).show();
    //   this.loadingService.hideOverlay();
    // });
    this.showAddRelationModal = false;
  } // end onSaveAddRelation()

  onBusinessRelationshipRemove() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Remove relationship...',
      action: null
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    this.advocateOverviewService.deleteRelationship(this.relationshipToDelete.Id).subscribe({
      next: (todelete) => {
        this.contactRelationship = this.contactRelationship.filter(c => c.Id !== this.relationshipToDelete.Id);
      },
      error: (error) => {
        // On error
        const msg = 'Error removing relationship.';
        snack.label = msg;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.onHideBusinessRelationshipRemovePrompt();
        snack.label = 'Relationship removed.';
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    });
    // todelete => {
    //   // On next
    //   this.contactRelationship = this.contactRelationship.filter(c => c.Id !== this.relationshipToDelete.Id);
    // },
    // error => {
    //   // On error
    //   const msg = 'Error removing relationship.';
    //   snack.label = msg;
    //   snack.type = SnackType.ERROR;
    //   this.snackbarsService.dismiss().make(snack, 5000).show();
    //   this.loadingService.hideOverlay();
    // }, () => {
    //   // On complete
    //   this.onHideBusinessRelationshipRemovePrompt();
    //   snack.label = 'Relationship removed.';
    //   this.snackbarsService.dismiss().make(snack).show();
    //   this.loadingService.hideOverlay();
    // });
  } // end onBusinessRelationshipRemove()

  onRelationshipDelete(data: ContactRelationship) {
    this.relationshipToDelete = Object.assign({}, DEFAULT_CONTACT_RELATIONSHIP);
    this.relationshipToDelete = data;
    this.showBusinessRelationshipRemovePrompt = true;
  } // end onRelationshipDelete()

  validateAdmissionDate(day, month, year) {
    this.isValidAdmissionMonth = false;
    this.isValidAdmissionYear = false;

    if (day > 0) {
      if (month === null || month === "" || month === undefined || month == "0") {
        this.isValidAdmissionMonth = true;
      }
      if (year === null || year === "" || year === undefined || year == "0") {
        this.isValidAdmissionYear = true;
      }
    }
    if (month > 0) {
      if (year === null || year === "" || year === undefined || year == "0") {
        this.isValidAdmissionYear = true;
      }
    }
    this.isValidateAdmissionDate.emit({
      isValidAdmissionMonth: this.isValidAdmissionMonth, 
      isValidAdmissionYear: this.isValidAdmissionYear
    });
  }

  onChangeAdmissionDay(day) {
    this.validateAdmissionDate(this.workingDetails.AdmissionDay, this.workingDetails.AdmissionMonth, this.workingDetails.AdmissionYear);
  }

  onChangeAdmissionMonth(month) {
    this.validateAdmissionDate(this.workingDetails.AdmissionDay, this.workingDetails.AdmissionMonth, this.workingDetails.AdmissionYear);
  }

  onChangeAdmissionYear(year) {
    this.validateAdmissionDate(this.workingDetails.AdmissionDay, this.workingDetails.AdmissionMonth, this.workingDetails.AdmissionYear);
  }



} // end PersonalDetailComponent{}
