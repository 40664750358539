<div *ngIf="isVisible" class="modal">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
      </div> <!-- #end .modal-header -->

      <div class="modal-body">
        <div class="container-fluid">
          
          <div class="row fa-icon">
            <i class="fal fa-check"></i>
          </div>

        </div> <!-- #end .container-fluid -->
      </div> <!-- #end .modal-body -->

      <div class="modal-footer">
        <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
        <!-- <span></span> -->
        <button class="btn btn-success" (click)="onContinue()">Continue</button>
      </div>
      <!--#end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->
