<div class="row row-nav" style="margin-top: 8px;" (click)="onPageClick($event)">
  <nav class="navbar-tabs">
    <a
      class="tab"
      [class.active]="activeTab === 'current-matters'"
      (click)="setActiveTab('current-matters')">Current matters</a>
    <a
      class="tab"
      [class.active]="activeTab === 'quotation'"
      (click)="setActiveTab('quotation')">Requested Quotations
      <i *ngIf="requestedQuotationCount" class="fa fa-circle requested-quotation"><b>{{requestedQuotationCount}}</b></i>
    </a>
    <a
      class="tab"
      [class.active]="activeTab === 'accepted-quotation'"
      (click)="setActiveTab('accepted-quotation')">Accepted Quotations
    <i *ngIf="acceptedQuotationCount"
      class="fa fa-circle accepted-quotation"><b>{{acceptedQuotationCount}}</b></i>
    </a>

    <a
      class="tab" *ngIf="(signInAsPracticeManager || internalAssistant) && !importCaptureFeeUsers"
      [class.active]="activeTab === 'take-on-data'"
      (click)="setActiveTab('take-on-data')">Historical Data
    <!-- <i *ngIf="acceptedQuotationCount"
      class="fa fa-circle accepted-quotation"><b>{{acceptedQuotationCount}}</b></i> -->
    </a>

    <a
      class="tab" *ngIf="!((signInAsPracticeManager || internalAssistant) && !importCaptureFeeUsers)"
      [class.active]="activeTab === 'historical-data'"
      (click)="setActiveTab('historical-data')">Upload Historical Invoices
    <i *ngIf="folderCount"
      class="fa fa-circle accepted-quotation"><b>{{folderCount}}</b></i>
    </a>
  </nav><!--#end .navbar-tabs -->
</div><!--#end .row.row-nav -->

<div class="row" (click)="onPageClick($event)">
  <div class="column-display-area">
    <!-- Table header for Current Matters -->
    <div class="display-options" [class.hide]="activeTab !== 'current-matters'">
      <span class="column-heading">
      </span>
      <span class="column-search">
        <button class="btn btn-text" (click)="filterMatters()">
          <i class="fa fa-search"></i>
        </button>
        <input type="search" placeholder="Search current matters" (keyup)="filterMatters()" [(ngModel)]="searchValue">
      </span>
      <!-- <span></span> -->
      <span class="column-order-by">
        <label for="sort-by">Sort by:</label>
        <select
          id="sort-by"
          placeholder="Filter"
          [(ngModel)]="orderBy">
          <option value="" disabled selected hidden> Filter options </option>
          <option *ngFor="let option of orderingOptions" [value]="option">
            {{option | titlecase}}
          </option>
        </select>
        <button class="btn btn-toggle" (click)="toggleAscending()">
          <i class="fa" [class.fa-sort-amount-desc]="!sortAscending" [class.fa-sort-amount-asc]="sortAscending"></i>
        </button>
        <!--#end .btn.btn-toggle -->
      </span>
      <!--#end .column-order-by -->
    </div>
    <!--#end .display-options -->

    <!-- Historical Data -->
    <div class="display-options" [class.hide]="activeTab !== 'take-on-data'">
      <span class="column-heading">
      </span>
      <span class="column-search">
        <button class="btn btn-text" (click)="filterHistorical()">
          <i class="fa fa-search"></i>
        </button>
        <input type="search" placeholder="Search current matters" (keyup)="filterHistorical()" [(ngModel)]="searchValue">
      </span>
      <!-- <span></span> -->
      <span class="column-order-by">
        <label for="sort-by">Sort by:</label>
        <select
          id="sort-by"
          placeholder="Filter"
          [(ngModel)]="orderHistoricalBy">
          <option value="" disabled selected hidden> Filter options </option>
          <option *ngFor="let option of orderingOptions" [value]="option">
            {{option | titlecase}}
          </option>
        </select>
        <button class="btn btn-toggle" (click)="toggleHistoricalAscending()">
          <i class="fa" [class.fa-sort-amount-desc]="!sortHistoricalAscending" [class.fa-sort-amount-asc]="sortHistoricalAscending"></i>
        </button>
        <!--#end .btn.btn-toggle -->
      </span>
      <!--#end .column-order-by -->
    </div>
    <!--#end Histrical Data -->

    <!-- Table header for Requested quotation -->
    <div class="display-options" [class.hide]="activeTab !== 'quotation'">
      <span class="column-heading">
      </span>
      <span class="column-search">
        <button class="btn btn-text" (click)="searchQuotation()">
          <i class="fa fa-search"></i>
        </button>
        <input type="search" placeholder="Search requested quotation" (keyup)="searchQuotation()" [(ngModel)]="searchTermQuotation">
      </span>
      <!-- <span></span> -->
      <span class="column-order-by">
        <label for="sort-by">Sort by:</label>
        <select
          id="sort-by"
          placeholder="Filter"
          [(ngModel)]="orderByQuotation">
          <option value="" disabled selected hidden> Filter options </option>
          <option *ngFor="let option of orderingOptionsQuotation" [value]="option">
            {{option | titlecase}}
          </option>
        </select>
        <button class="btn btn-toggle" (click)="toggleAscendingQuotation()">
          <i class="fa" [class.fa-sort-amount-desc]="!sortAscendingQuotation" [class.fa-sort-amount-asc]="sortAscendingQuotation"></i>
        </button>
        <!--#end .btn.btn-toggle -->
      </span>
      <!--#end .column-order-by -->
    </div>
    <!--#end .display-options -->

    <!-- Table header for accepted quotation -->
    <div class="display-options" [class.hide]="activeTab !== 'accepted-quotation'">
      <span class="column-heading">
        <!-- <label for="heading">Current matters</label> -->
      </span>
      <span class="column-search">
        <button class="btn btn-text" (click)="searchQuotation()">
          <i class="fa fa-search"></i>
        </button>
        <input type="search" placeholder="Search accepted quotation" (keyup)="searchQuotation()" [(ngModel)]="searchTermQuotation">
      </span>
      <!-- <span></span> -->
      <span class="column-order-by">
        <label for="sort-by">Sort by:</label>
        <select
          id="sort-by"
          placeholder="Filter"
          [(ngModel)]="orderByQuotation">
          <option value="" disabled selected hidden> Filter options </option>
          <option *ngFor="let option of orderingOptionsQuotation" [value]="option">
            {{option | titlecase}}
          </option>
        </select>
        <button class="btn btn-toggle" (click)="toggleAscendingQuotation()">
          <i class="fa" [class.fa-sort-amount-desc]="!sortAscendingQuotation" [class.fa-sort-amount-asc]="sortAscendingQuotation"></i>
        </button>
        <!--#end .btn.btn-toggle -->
      </span>
      <!--#end .column-order-by -->
    </div>
    <!--#end .display-options -->

  <div class="well well-fluid" style="padding-top: 0;">    

    <!-- Current Matters table -->
    <div 
      class="table table-current-matters"
      [class.hide]="activeTab !== 'current-matters'">
      <div class="table-head">
        <div class="table-row">

          <span class="column-date clickable" (click)="toggleSort('date')">
            Date
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortDate"></i>
          </span>
          <span
            class="column-matter-details clickable"
            (click)="toggleSort('matter-details')">
            Matter details
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortMatter"></i>
          </span>
          <span
            class="column-law-firm clickable"
            *ngIf="!isAttorney"
            (click)="toggleSort('law-firm')">
            Law firm / Advocate
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortLawfirm"></i>
          </span>
          <span
            class="column-law-firm clickable"
            *ngIf="isAttorney"
            (click)="toggleSort('law-firm')">
            Clients
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortLawfirm"></i>
          </span>
          <span
          *ngIf="!isAttorney"
            class="column-attorney-reference clickable"
            (click)="toggleSort('attorney-reference')">
            Attorney reference
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortAttorney"></i>
          </span>
          <span
          *ngIf="isAttorney"
            class="column-attorney-reference clickable"
            (click)="toggleSort('attorney-reference')">
            Reference
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortAttorney"></i>
          </span>
          <span
            class="column-due clickable"
            (click)="toggleSort('due')">
            WIP
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortWIP"></i>
          </span>
          <span
            class="column-status clickable"
            (click)="toggleSort('status')">
            Status
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortStatus"></i>
          </span><!--#end .column-status -->
        </div><!--#end .table-row -->
      </div><!--#end .table-head -->

      <app-circular-indicator
        [isVisible]="!matterHandles"></app-circular-indicator>
      <div class="table-body" *ngIf="matterHandles">
        <div 
          *ngFor="let matter of matterHandles"
          class="table-row"
          [ngStyle]="{'color': getArbitrationColor(matter.data.IsArbitrationSplit)}"
          [class.hide]="!matter.state.isVisible">
          <span 
            class="column-date">{{matter.data.ServiceDate | date:'yyyy-MM-dd'}}</span>
          <span
            class="column-matter-details"
            [title]="matter.data.Description">{{matter.data.Description}}</span>
          <span 
            class="column-law-firm"
            [title]="matter.data.InternalRefNo">{{matter.data.InternalRefNo}}</span>
          <span 
            class="column-attorney-reference"
            [title]="matter.data.ClientRefNo">{{matter.data.ClientRefNo}}</span>
            <span 
            class="column-due"
            [title]="matter.data.Total">{{matter.data.Total | currency:currencySymbol}}</span>
          <span 
            class="column-status"
            [title]="matter.data.Status">{{matter.data.Status}}</span>
          <span class="column-briefs">
            <i class="fa fa-paperclip" style="font-size: 13px !important;"title="{{matter.data.Briefs}} documents attached to this matter." *ngIf="matter.data.Briefs > 0"></i>
          </span>
          <span class="column-check">
            <app-checkbox
              [(checked)]="matter.state.isSelected"
              (click)="toggleChecbox(matter)"></app-checkbox>
          </span>
          <span class="column-action">
            <span class="btn-group">
              <span
                *ngIf="matter.data.Status !== 'Outbox'"
                class="clickable"
                (click)="openMatter(matter.data)"><p style="margin-right: 5px; margin-bottom: 1px;">Open matter</p></span>
              <span
                *ngIf="matter.data.Status === 'Outbox'"
                class="clickable"
                (click)="previewMessage(matter.data)"><p style="margin-right: 5px; margin-bottom: 1px;">Preview message</p></span>
              <i 
                class="fa fa-angle-down fa-lg clickable" 
                (click)="togglePopupMenu(matter)"></i>
            </span>

            <app-single-matter-popup-menu
              #popupMenu
              id={{matter.data.ServiceID}}
              [isVisible]="matter.state.isOpen"
              [type]="matter.data.Status"
              [serviceID]="matter.data.ServiceID"
              [matter]="matter"
              (dismiss)="onDismissMenu($event)"
              (deleteMatter)="promptDeleteMatter($event)"
              (previewInvoice)="previewInvoice($event)"
              (previewMessage)="previewMessage($event)"
              (openMatter)="openMatter($event)"
              (viewQuotation)="viewQuotation($event)"></app-single-matter-popup-menu>
          </span>
        </div><!--#end .table-row -->
      </div><!--#end .table-body -->

      <div class="table-foot">
        <div class="table-row">

          <span class="column-add-another-matter">
            <span class="clickable" (click)="addMatter()">
              <i class="fa fa-plus-circle fa-lg auxcon-green"style="margin-right: 5px;"></i>
              <u class="auxcon-green">add another matter</u>
            </span><!--#end .clickable -->
          </span><!--#end .column-add-another-matter -->

          <span class="column-check">
            <span
              class="clickable"
              (click)="toggleSelectAll()">Select all</span>
            <app-checkbox
              [(checked)]="allSelected"
              (click)="toggleSelectAll()"></app-checkbox>
          </span><!--#end .column-check -->

          <span class="column-action">
            <span class="btn-group">
              <span
                class="clickable"
                (click)="openModal('add-fee-to-matters')">
                Add fee to selected</span>
                <i
                  class="fa fa-angle-up fa-lg clickable"
                  (click)="togglePopupMultipleMenu()"></i>
            </span><!--#end .btn-group -->
            <app-multiple-matters-popup-menu
              #popupMenu
              [isVisible]="showMultiplteMattersPopupMenu"
              (dismiss)="onDismissMultipleMatterMenu()"
              (generateMultipleInvoices)="onGenerateMultipleInvoices()"
              (deleteMultipleMatters)="onDeleteMultipleMatters()"></app-multiple-matters-popup-menu>
            <!-- TODO: Add fee to selected matters menu -->
          </span><!--#end span -->

        </div><!--#end .table-row -->

        <div class="table-row" *ngIf="importCaptureFeeUsers">

          <span class="column-add-another-matter">
            <span class="clickable" (click)="importMonthlyBilling()">
              <i class="fa fa-plus-circle fa-lg auxcon-green"style="margin-right: 5px;"></i>
              <u class="auxcon-green">import monthly billing</u>
            </span><!--#end .clickable -->
          </span><!--#end .column-add-another-matter -->

        </div><!--#end .table-row -->
      </div><!--#end .table-foot -->
    </div><!--#end .table -->

     <!-- Start Historical Data -->
     <div 
     class="table table-current-matters"
     [class.hide]="activeTab !== 'take-on-data'">
     <div class="table-head">
       <div class="table-row">

         <span class="column-date clickable" (click)="toggleHistoricalSort('date')">
           Date
           &nbsp;
           <i class="fa fa-sort" [class.fa-sort-down]="sortHistoricalDate"></i>
         </span>
         <span
           class="column-matter-details clickable"
           (click)="toggleHistoricalSort('matter-details')">
           Matter details
           &nbsp;
           <i class="fa fa-sort" [class.fa-sort-down]="sortHistoricalMatter"></i>
         </span>
         <span
           class="column-law-firm clickable"
           *ngIf="!isAttorney"
           (click)="toggleHistoricalSort('law-firm')">
           Law firm / Advocate
           &nbsp;
           <i class="fa fa-sort" [class.fa-sort-down]="sortHistoricalLawfirm"></i>
         </span>
         <span
           class="column-law-firm clickable"
           *ngIf="isAttorney"
           (click)="toggleHistoricalSort('law-firm')">
           Clients
           &nbsp;
           <i class="fa fa-sort" [class.fa-sort-down]="sortHistoricalLawfirm"></i>
         </span>
         <span
         *ngIf="!isAttorney"
           class="column-attorney-reference clickable"
           (click)="toggleHistoricalSort('attorney-reference')">
           Attorney reference
           &nbsp;
           <i class="fa fa-sort" [class.fa-sort-down]="sortHistoricalAttorney"></i>
         </span>
         <span
         *ngIf="isAttorney"
           class="column-attorney-reference clickable"
           (click)="toggleHistoricalSort('attorney-reference')">
           Reference
           &nbsp;
           <i class="fa fa-sort" [class.fa-sort-down]="sortHistoricalAttorney"></i>
         </span>
         <span
           class="column-due clickable"
           (click)="toggleHistoricalSort('due')">
           WIP
           &nbsp;
           <i class="fa fa-sort" [class.fa-sort-down]="sortHistoricalWIP"></i>
         </span>
         <span
           class="column-status clickable"
           (click)="toggleHistoricalSort('status')">
           Status
           &nbsp;
           <i class="fa fa-sort" [class.fa-sort-down]="sortHistoricalStatus"></i>
         </span><!--#end .column-status -->
       </div><!--#end .table-row -->
     </div><!--#end .table-head -->

     <app-circular-indicator
       [isVisible]="!historicalDataHandles"></app-circular-indicator>
     <div class="table-body" *ngIf="historicalDataHandles">
       <div 
         *ngFor="let matter of historicalDataHandles"
         class="table-row"
         [class.hide]="!matter.state.isVisible">
         <span 
           class="column-date">{{matter.data.ServiceDate | date:'yyyy-MM-dd'}}</span>
         <span
           class="column-matter-details"
           [title]="matter.data.Description">{{matter.data.Description}}</span>
         <span 
           class="column-law-firm"
           [title]="matter.data.InternalRefNo">{{matter.data.InternalRefNo}}</span>
         <span 
           class="column-attorney-reference"
           [title]="matter.data.ClientRefNo">{{matter.data.ClientRefNo}}</span>
           <span 
           class="column-due"
           [title]="matter.data.Total">{{matter.data.Total | currency:currencySymbol}}</span>
         <span 
           class="column-status"
           [title]="matter.data.Status">{{matter.data.Status}}</span>
         <span class="column-briefs">
           <i class="fa fa-paperclip" style="font-size: 13px !important;"title="{{matter.data.Briefs}} documents attached to this matter." *ngIf="matter.data.Briefs > 0"></i>
         </span>
         <span class="column-check">
           <app-checkbox
             [(checked)]="matter.state.isSelected"
             (click)="toggleChecbox(matter)"></app-checkbox>
         </span>
         <span class="column-action">
           <span class="btn-group">
             <span
               *ngIf="matter.data.Status !== 'Outbox'"
               class="clickable"
               (click)="openMatter(matter.data)"><p style="margin-right: 5px; margin-bottom: 1px;">Open matter</p></span>
             <span
               *ngIf="matter.data.Status === 'Outbox'"
               class="clickable"
               (click)="previewMessage(matter.data)"><p style="margin-right: 5px; margin-bottom: 1px;">Preview message</p></span>
             <i 
               class="fa fa-angle-down fa-lg clickable" 
               (click)="togglePopupMenu(matter)"></i>
           </span>

           <app-single-matter-popup-menu
             #popupMenu
             id={{matter.data.ServiceID}}
             [isVisible]="matter.state.isOpen"
             [type]="matter.data.Status"
             [serviceID]="matter.data.ServiceID"
             [matter]="matter"
             (dismiss)="onDismissMenu($event)"
             (deleteMatter)="promptDeleteMatter($event)"
             (previewInvoice)="previewInvoice($event)"
             (previewMessage)="previewMessage($event)"
             (openMatter)="openMatter($event)"
             (viewQuotation)="viewQuotation($event)"></app-single-matter-popup-menu>
         </span>
       </div><!--#end .table-row -->
     </div><!--#end .table-body -->

     <div class="table-foot">
       <div class="table-row">

         <span class="column-add-another-matter">
           <span class="clickable" (click)="importHistoricalData()">
             <i class="fa fa-plus-circle fa-lg auxcon-green"style="margin-right: 5px;"></i>
             <u class="auxcon-green">Import Historical Data</u>
           </span><!--#end .clickable -->
         </span><!--#end .column-add-another-matter -->

         <span class="column-check">
           <span
             class="clickable"
             (click)="toggleHistoricalSelectAll()">Select all</span>
           <app-checkbox
             [(checked)]="allSelected"
             (click)="toggleHistoricalSelectAll()"></app-checkbox>
         </span><!--#end .column-check -->

         <span class="column-action">
           <span class="btn-group">
             <span
               class="clickable"
               (click)="openModal('add-fee-to-matters')">
               Add fee to selected</span>
               <i
                 class="fa fa-angle-up fa-lg clickable"
                 (click)="togglePopupMultipleHistoricalMenu()"></i>
           </span><!--#end .btn-group -->
           <app-multiple-historical-popup-menu
             #popupHistoricalMenu
             [isVisible]="showMultiplteHistoricalsPopupMenu"
             (dismiss)="onDismissMultipleHistoricalMenu()"
             (generateMultipleInvoices)="onGenerateMultipleHistoricalInvoices()"
             (deleteMultipleMatters)="onDeleteMultipleHistoricalInvoices()"></app-multiple-historical-popup-menu>
           <!-- TODO: Add fee to selected matters menu -->
         </span><!--#end span -->

       </div><!--#end .table-row -->
     </div><!--#end .table-foot -->
   </div><!--#end .table -->

   <!-- end historical data -->

    <!-- Start Advocate Historical Data -->
    <div 
    class="table table-current-matters"
    [class.hide]="activeTab !== 'historical-data'">

    <div class="fileList" *ngFor="let folder of folders">
      <div class="added-file" *ngIf="folder">
        <div class="file-header">
          <h5 style="margin-top: 5px;"><b>Invoices</b></h5>

          <button class="file-dismiss"
            style="border: 0 !important; background-color:transparent !important; margin-top: 5px;">
          </button>
        </div>
        <div
          class="file-body"
          (click)="downloadFolder(folder.FolderName)">
          <u>{{folder.FolderName}}</u>
        </div>
        <div class="file-footer">
          Upload date: {{folder.EarliestUploadDate  | date: 'yyyy-MM-dd'}}
        </div>
      </div>
    </div>

    <div class="table-foot">
      <div class="table-row">

        <span class="column-add-another-matter">
          <span class="clickable" (click)="importAdvocateHistoricalData()">
            <i class="fa fa-plus-circle fa-lg auxcon-green"style="margin-right: 5px;"></i>
            <u class="auxcon-green">UPLOAD HISTORICAL INVOICES</u>
          </span><!--#end .clickable -->
        </span><!--#end .column-add-another-matter -->

      </div><!--#end .table-row -->
    </div><!--#end .table-foot -->
  </div><!--#end .table -->

  <!-- end Advocate historical data -->

    <!-- Requested quotation table -->
    <div 
      class="table table-current-matters"
      [class.hide]="activeTab !== 'quotation'">
      <div class="table-head">
        <div class="table-row">

          <span class="column-date clickable" (click)="toggleQuotationSort('date')">
            Date
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationDate"></i>
          </span>
          <span
            class="column-matter-details clickable"
            (click)="toggleQuotationSort('matter-details')">
            Matter details
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationMatter"></i>
          </span>
          <span
            class="column-law-firm clickable"
            *ngIf="!isAttorney"
            (click)="toggleQuotationSort('law-firm')">
            Law firm / Advocate
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationLawfirm"></i>
          </span>
          <span
            class="column-law-firm clickable"
            *ngIf="isAttorney"
            (click)="toggleQuotationSort('law-firm')">
            Clients
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationLawfirm"></i>
          </span>
          <span
          *ngIf="!isAttorney"
            class="column-attorney-reference clickable"
            (click)="toggleQuotationSort('attorney-reference')">
            Attorney reference
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationReference"></i>
          </span>
          <span
          *ngIf="isAttorney"
            class="column-attorney-reference clickable"
            (click)="toggleQuotationSort('attorney-reference')">
            Reference
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationReference"></i>
          </span>
          <span
            class="column-due clickable"
            (click)="toggleQuotationSort('due')">
            WIP
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationWIP"></i>
          </span>
          <span
            class="column-status clickable"
            (click)="toggleQuotationSort('status')">
            Status
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationStatus"></i>
          </span><!--#end .column-status -->
        </div><!--#end .table-row -->
      </div><!--#end .table-head -->

      <div class="table-body" *ngIf="matterQuotationHandle">
        <div 
          *ngFor="let quotation of matterQuotationHandle"
          class="table-row"
          [class.hide]="!quotation.state.isVisible">
          <span 
            class="column-date">{{quotation.data.MatterQuotationDate | date:'yyyy-MM-dd'}}</span>
          <span
            class="column-matter-details"
            [title]="quotation.data.Description">{{quotation.data.Description}}</span>
          <span 
            class="column-law-firm"
            [title]="quotation.data.LawFirm">{{quotation.data.LawFirm}}</span>
          <span 
            class="column-attorney-reference"
            [title]="quotation.data.ClientRefNo">{{quotation.data.ClientRefNo}}</span>
            <span 
            class="column-due"
            [title]="quotation.data.Total | currency:currencySymbol">{{quotation.data.Total | currency:currencySymbol}}</span>
          <span 
            class="column-status"
            [title]="quotation.data.QuotationStateStatus">{{quotation.data.QuotationStateStatus}}</span>
            <span class="column-briefs">
            </span>
          <span class="column-check">
            <app-checkbox
              [(checked)]="quotation.state.isSelected"
              (click)="toggleCheckboxQuotation(quotation)"></app-checkbox>
          </span>
          <span class="column-action">
            <span class="btn-group">
              <span
                *ngIf="quotation.data.Status !== 'Outbox'"
                class="clickable"
                (click)="openMatterQuotation(quotation.data)"><p style="margin-right: 5px; margin-bottom: 1px;">Open quotation</p>
              </span>
              <span
                *ngIf="quotation.data.Status === 'Outbox'"
                class="clickable" 
                (click)="previewQuotationMessage(quotation.data)"><p style="margin-right: 5px; margin-bottom: 1px;">Preview message</p>
              </span>
              <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupQuotationMenu(quotation)"></i>
            </span>


            <app-single-quotations-popup-menu
              #popupQuotationMenu id={{quotation.data.MatterQuotationId}}
              [isVisible]="quotation.state.isOpen" 
              [type]="quotation.data.Status" 
              [serviceID]="quotation.data.MatterQuotationId"
              [matter]="quotation" 
              (dismiss)="onDismissQuotationMenu($event)"
              (deleteMatterQuotation)="promptDeleteMatterQuotation($event)" 
              (previewInvoice)="previewQuotation($event)"
              (previewMessage)="previewQuotationMessage($event)" 
              (openMatter)="openMatterQuotation($event)"
              (moveToAccepted)="onAcceptReject($event, 'Accepted')"
              (moveToRejected)="onAcceptReject($event , 'Regected')">
            </app-single-quotations-popup-menu>
          </span>
        </div><!--#end .table-row -->
      </div><!--#end .table-body -->

      <div class="table-foot">
        <div class="table-row">

          <span class="column-add-another-matter">
            <span class="clickable" (click)="addQuotationMatter()">
              <i class="fa fa-plus-circle fa-lg auxcon-green" style="margin-left: 5px;"></i>
              <u class="auxcon-green">add another quotation</u>
            </span><!--#end .clickable -->
          </span><!--#end .column-add-another-matter -->

          <span class="column-check">
            <span
              class="clickable"
              (click)="toggleQuotationSelectAll()">Select all</span>
            <app-checkbox
              [(checked)]="selectAllQuotation"
              (click)="toggleQuotationSelectAll()"></app-checkbox>
          </span><!--#end .column-check -->

          <span class="column-action">
            <span class="btn-group">
              <span
                class="clickable"
                (click)="onDeleteMultipleQuotations()">
                Delete multiple quotes</span>
                <i
                  class="fa fa-angle-up fa-lg clickable"
                  (click)="onDeleteMultipleQuotations()"></i>
            </span><!--#end .btn-group -->
            <!-- TODO: Add fee to selected matters menu -->
          </span><!--#end span -->

        </div><!--#end .table-row -->
      </div><!--#end .table-foot -->
    </div><!--#end .table -->

    <!-- Accepted quotation table -->
    <div 
      class="table table-current-matters"
      [class.hide]="activeTab !== 'accepted-quotation'">
      <div class="table-head">
        <div class="table-row">

          <span class="column-date clickable" (click)="toggleSort('date')">
            Date
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationDate"></i>
          </span>
          <span
            class="column-matter-details clickable"
            (click)="toggleSort('matter-details')">
            Matter details
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationMatter"></i>
          </span>
          <span
            class="column-law-firm clickable"
            *ngIf="!isAttorney"
            (click)="toggleSort('law-firm')">
            Law firm / Advocate
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationAttorney"></i>
          </span>
          <!-- <span
            class="column-law-firm clickable">
            Attorney
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationAttorney"></i>
          </span> -->
          <span
          *ngIf="!isAttorney"
            class="column-attorney-reference clickable"
            (click)="toggleSort('attorney-reference')">
            Attorney reference
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationReference"></i>
          </span>
          <span
          *ngIf="isAttorney"
            class="column-attorney-reference clickable"
            (click)="toggleSort('attorney-reference')">
            Reference
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationReference"></i>
          </span>
          <span
            class="column-due clickable"
            (click)="toggleSort('due')">
            WIP
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationWIP"></i>
          </span>
          <!-- <span
            class="column-status clickable"
            (click)="toggleSort('status')">
            Feedback Status
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationStatus"></i>
          </span>#end .column-status -->
          <span
            class="column-status clickable"
            (click)="toggleSort('status')">
            Status
            &nbsp;
            <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationStatus"></i>
          </span><!--#end .column-status -->
        </div><!--#end .table-row -->
      </div><!--#end .table-head -->

      <div class="table-body" *ngIf="matterQuotationHandle">
        <div 
          *ngFor="let quotation of matterQuotationHandle"
          class="table-row"
          [class.hide]="!quotation.state.isVisible">
          <span 
            class="column-date">{{quotation.data.MatterQuotationDate | date:'yyyy-MM-dd'}}</span>
          <span
            class="column-matter-details"
            [title]="quotation.data.Description">{{quotation.data.Description}}</span>
          <span 
            class="column-law-firm"
            [title]="quotation.data.LawFirm">{{quotation.data.LawFirm}}</span>
          <span 
            class="column-attorney-reference"
            [title]="quotation.data.ClientRefNo">{{quotation.data.ClientRefNo}}</span>
            <span 
            class="column-due"
            [title]="quotation.data.Total | currency:currencySymbol">{{quotation.data.Total | currency:currencySymbol}}</span>
          <span 
            class="column-status"
            [title]="quotation.data.QuotationStateStatus">{{quotation.data.QuotationStateStatus}}</span>
            <span class="column-briefs">
            </span>
          <span class="column-check">
            <app-checkbox
              [(checked)]="quotation.state.isSelected"
              (click)="toggleCheckboxQuotation(quotation)"></app-checkbox>
          </span>
          <span class="column-action">
            <span class="btn-group">
              <span
                *ngIf="quotation.data.Status !== 'Outbox'"
                class="clickable"
                (click)="openAcceptedQuotation(quotation.data)">
                <p style="margin-right: 5px; margin-bottom: 1px;">Open quotation</p>
              </span>
              <span
                *ngIf="quotation.data.Status === 'Outbox'" 
                class="clickable" 
                (click)="previewQuotationMessage(quotation.data)">
                <p style="margin-right: 5px; margin-bottom: 1px;">Preview message</p>
              </span>
              <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupQuotationMenu(quotation)"></i>
            </span>

            <app-single-quotations-popup-menu
              #popupQuotationMenu id={{quotation.data.MatterQuotationId}}
              [isVisible]="quotation.state.isOpen" 
              [type]="quotation.data.Status" 
              [serviceID]="quotation.data.MatterQuotationId"
              [matter]="quotation" 
              (dismiss)="onDismissQuotationMenu($event)"
              (deleteMatterQuotation)="promptDeleteMatterQuotation($event)" 
              (previewInvoice)="previewQuotation($event)"
              (previewMessage)="previewQuotationMessage($event)" 
              (openMatter)="openMatterQuotation($event)">
            </app-single-quotations-popup-menu>
          </span>
        </div><!--#end .table-row -->
      </div><!--#end .table-body -->

      <div class="table-foot">
        <div class="table-row">

          <span class="column-add-another-matter">
            <span class="clickable" (click)="addQuotationMatter()">
              <i class="fa fa-plus-circle fa-lg auxcon-green" style="margin-left: 5px;"></i>
              <u class="auxcon-green">add another quotation</u>
            </span>
          </span>

          <span class="column-check">
            <span
              class="clickable"
              (click)="toggleQuotationSelectAll()">Select all</span>
            <app-checkbox
              [(checked)]="selectAllQuotation"
              (click)="toggleQuotationSelectAll()"></app-checkbox>
          </span><!--#end .column-check -->

          <span class="column-action">
            <span class="btn-group">
              <span
                class="clickable"
                (click)="onDeleteMultipleQuotations()">
                Delete multiple quotes</span>
                <i
                  class="fa fa-angle-up fa-lg clickable"
                  (click)="onDeleteMultipleQuotations()"></i>
            </span>
          </span><!--#end span -->

        </div><!--#end .table-row -->
      </div><!--#end .table-foot -->
    </div><!--#end .table -->

    <!-- Requested Matter Quotation Table -->
      <!-- <div class="table-responsive" *ngIf="activeTab === 'quotation'" style="max-height: 300px;">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td scope="col" style="font-size: 13px !important;" class="w7">Date
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationDate"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w28">Matter details
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationMatter"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w12">
                Law firm / Advocate
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationLawfirm"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w10">Attorney
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationAttorney"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w10 quotation-attorney-reference">Attorney reference
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationReference"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w8">WIP
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationWIP"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w7">Feedback Status
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationStatus"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w7">Status
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationStatus"></i>
              </td>
              <td scope="col" class="w3"></td>
              <td scope="col" class="w15"></td>
            </tr>
            <tr class="tr" *ngFor="let quotation of matterQuotationHandle">
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.MatterQuotationDate | date:'yyyy-MM-dd'}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.Description}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.LawFirm}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.AttorneyName}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.ClientRefNo}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.Total | currency:currencySymbol}}</td>
              <td *ngIf="quotation.state.isVisible" [title]="quotation.data.AcceptRejectNotes" scope="row">{{quotation.data.QuotationStateStatus}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.Status}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">
                <app-small-checkbox
                  [(checked)]="quotation.state.isSelected"
                  (click)="toggleCheckboxQuotation(quotation)"></app-small-checkbox>
              </td>
              <td *ngIf="quotation.state.isVisible" scope="row" class="column-action">
                <span class="btn-group">
                  <span
                  *ngIf="quotation.data.Status !== 'Outbox'"
                  class="clickable"
                  (click)="openMatterQuotation(quotation.data)">
                    <p style="margin-right: 5px; margin-bottom: 1px;">Open quotation</p>
                  </span>
                  <span *ngIf="quotation.data.Status === 'Outbox'" class="clickable" (click)="previewQuotationMessage(quotation.data)">
                    <p style="margin-right: 5px; margin-bottom: 1px;">Preview message</p>
                  </span>
                  <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupQuotationMenu(quotation)"></i>
                </span>
                
                <app-single-quotations-popup-menu
                  #popupQuotationMenu
                  id={{quotation.data.MatterQuotationId}}
                  [isVisible]="quotation.state.isOpen"
                  [type]="quotation.data.Status"
                  [serviceID]="quotation.data.MatterQuotationId"
                  [matter]="quotation"
                  (dismiss)="onDismissQuotationMenu($event)"
                  (deleteMatterQuotation)="promptDeleteMatterQuotation($event)"
                  (previewInvoice)="previewQuotation($event)"
                  (previewMessage)="previewQuotationMessage($event)"
                  (openMatter)="openMatterQuotation($event)">
                </app-single-quotations-popup-menu>
              </td>
            </tr>
          </tbody> -->
          <!-- <tfoot>
            <tr>
              <td colspan="2">
                <span class="column-add-another-matter">
                  <span class="clickable" (click)="addQuotationMatter()">
                    <i class="fa fa-plus-circle fa-lg auxcon-green"></i>
                    <u class="auxcon-green">add another quotation</u>
                  </span> -->
                  <!--#end .clickable -->
                <!-- </span> -->
                <!--#end .column-add-another-matter -->
              <!-- </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td><span class="clickable" (click)="toggleQuotationSelectAll()">Select all</span></td>
              <td>                
                <app-small-checkbox [(checked)]="selectAllQuotation" (click)="toggleQuotationSelectAll()"></app-small-checkbox>
              </td>
              <td>
                <span class="btn-group">
                  <span class="clickable" style="padding: 4px 3px;" (click)="onDeleteMultipleQuotations()">
                    Delete multiple quotations</span>
                  <i class="fa fa-angle-up fa-lg clickable" style="padding: 7px 10px;"></i>
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div> -->

    <!-- Accepted Matter Quotation Table -->
      <!-- <div class="table-responsive" *ngIf="activeTab === 'accepted-quotation'" style="max-height: 300px;">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td scope="col" style="font-size: 13px !important;" class="w7">Date
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationDate"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w28">Matter details
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationMatter"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w12">
                Law firm / Advocate
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationLawfirm"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w10">Attorney
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationAttorney"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w10 quotation-attorney-reference">Attorney reference
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationReference"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w8">WIP
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationWIP"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w7">Feedback Status
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationStatus"></i>
              </td>
              <td scope="col" style="font-size: 13px !important;" class="w7">Status
                <i class="fa fa-sort" [class.fa-sort-down]="sortQuotationStatus"></i>
              </td>
              <td scope="col" class="w3"></td>
              <td scope="col" class="w15"></td>
            </tr>
            <tr class="tr" *ngFor="let quotation of matterQuotationHandle">
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.MatterQuotationDate | date:'yyyy-MM-dd'}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.Description}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.LawFirm}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.AttorneyName}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.ClientRefNo}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.Total | currency:currencySymbol}}</td>
              <td *ngIf="quotation.state.isVisible" [title]="quotation.data.AcceptRejectNotes" scope="row">{{quotation.data.QuotationStateStatus}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">{{quotation.data.Status}}</td>
              <td *ngIf="quotation.state.isVisible" scope="row">
                <app-small-checkbox
                  [(checked)]="quotation.state.isSelected"
                  (click)="toggleCheckboxQuotation(quotation)"></app-small-checkbox>
              </td>
              <td *ngIf="quotation.state.isVisible" scope="row" class="column-action">
                <span class="btn-group">
                  <span
                  *ngIf="quotation.data.Status !== 'Outbox'"
                  class="clickable"
                  (click)="openAcceptedQuotation(quotation.data)">
                    <p style="margin-right: 5px; margin-bottom: 1px;">Open quotation</p>
                  </span>
                  <span *ngIf="quotation.data.Status === 'Outbox'" class="clickable" (click)="previewQuotationMessage(quotation.data)">
                    <p style="margin-right: 5px; margin-bottom: 1px;">Preview message</p>
                  </span>
                  <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupQuotationMenu(quotation)"></i>
                </span> -->
                
                <!-- <app-single-quotations-popup-menu
                  #popupQuotationMenu
                  id={{quotation.data.MatterQuotationId}}
                  [isVisible]="quotation.state.isOpen"
                  [type]="quotation.data.Status"
                  [serviceID]="quotation.data.MatterQuotationId"
                  [matter]="quotation"
                  (dismiss)="onDismissQuotationMenu($event)"
                  (deleteMatterQuotation)="promptDeleteMatterQuotation($event)"
                  (previewInvoice)="previewQuotation($event)"
                  (previewMessage)="previewQuotationMessage($event)"
                  (openMatter)="openMatterQuotation($event)">
                </app-single-quotations-popup-menu> -->
              <!-- </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2"> -->
                <!-- <span class="column-add-another-matter">
                  <span class="clickable" (click)="addQuotationMatter()">
                    <i class="fa fa-plus-circle fa-lg auxcon-green"></i>
                    <u class="auxcon-green">add another quotation</u>
                  </span> -->
                  <!--#end .clickable -->
                <!-- </span> -->
                <!--#end .column-add-another-matter -->
              <!-- </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td> -->
                <!-- <span class="clickable" (click)="toggleAcceptedQuotationSelectAll()">Select all</span> -->
              <!-- </td>
              <td> -->
                <!-- <app-small-checkbox [(checked)]="selectAllAcceptedQuotation" (click)="toggleAcceptedQuotationSelectAll()"></app-small-checkbox> -->
              <!-- </td>
              <td> -->
                <!-- <span class="btn-group">
                  <span class="clickable" style="padding: 4px 3px;" (click)="openModal('delete-multiple-accepted-quotation')">
                    Delete multiple quotations</span>
                  <i class="fa fa-angle-up fa-lg clickable" style="padding: 7px 10px;"></i>
                </span> -->
              <!-- </td>
            </tr>
          </tfoot>
        </table>
      </div> #end .table-responsive -->

  </div><!--#end .well .well-fluid -->
</div><!--#end .row -->

<app-delete-matter-modal
  #deleteMatterModal
  [matter]="selectedMatter"
  [isVisible]="showDeleteMatterModal"
  (closeModal)="closeModal('delete-matter-modal')"
  (confirmDelete)="deleteMatter($event)"></app-delete-matter-modal>
<app-delete-multiple-matter-modal
  #deleteMultipleMatterModal
  [matter]="selectedMatters"
  [isVisible]="showdeleteMultipleMatterModal"
  (closeModal)="closeModal('delete-multiple-matter-modal')"
  (confirmDelete)="deleteMatter($event)"></app-delete-multiple-matter-modal>

<app-delete-multiple-quotation-modal
  #deleteMultipleQuotationModal
  [quotation]="selectedQuotations"
  [isVisible]="showdeleteMultipleQuotationModal"
  (closeModal)="closeModal('delete-multiple-quotation-modal')"
  (confirmDelete)="deleteQuotation($event)"></app-delete-multiple-quotation-modal>

<app-proforma-invoice
  #proformaInvoice
  title="Quotation"
  popId="0"
  invoiceId="0"
  documentType="Quotation"
  [matter]="selectedMatter"
  [isVisible]="showProformaInvoice"
  (closeModal)="closeModal('proforma-invoice')"></app-proforma-invoice>
<app-add-multiple-fee-modal
  #addMultipleFeeModal
  [matters]="selectedMatters"
  [isVisible]="showAddMultipleFeesModal"
  (closeModal)="closeModal('add-multiple-fees-modal')"
  (save)="saveMultipleFees($event)"></app-add-multiple-fee-modal>
  
<app-alert
  [isVisible]="showDeleteMatterAlert"
  title="Payments linked to this matter!"
  [link]="supportEmailLink"
  linkType="email"
  message="You can not delete this invoice. For further assistance please contact the Auxcon Team."
  (cancel)="dismissDeleteMatterErrorAlert()"
  (confirm)="dismissDeleteMatterErrorAlert()"></app-alert>
  <app-alert
    [title]="errorTitle"
    [message]="errorMessage"
    [isVisible]="onShowMultipleMatterWithNoFeesChangesPrompt"
    (cancel)="onHidemultipleMatterWithNoFeesChangesPrompt()"
    (confirm)="onHidemultipleMatterWithNoFeesChangesPrompt()"></app-alert>

 <app-delete-quotation-modal
  [quotation]="selectedMatterQuotation"
  [isVisible]="showDeleteQuotationModal"
  (closeModal)="closeModal('delete-quotation')"
  (confirmDelete)="deleteQuotation($event)"></app-delete-quotation-modal>

  <app-add-quotation-accept-reject-reason
  *ngIf="showQuotationAcceptRejectReason"
  [isVisible]="showQuotationAcceptRejectReason"
  [matterQuotation]="acceptedRejectedMatterQuotation"
  [(manualAcceptReject)]="manualAcceptReject"
  (closeModal)="closeModal('quotation-note')"
  (saveAcceptRejectReason)="onAcceptReject($event)"></app-add-quotation-accept-reject-reason>

<app-import-historical
  *ngIf="showImportHistorical"
  [isVisible]="showImportHistorical"
  (closeModel)="closeModal('historical')"></app-import-historical>

<app-import-auxcon-data
  *ngIf="showImportAuxconData"
  [isVisible]="showImportAuxconData"
  (closeModel)="closeModal('auxcon-data')"></app-import-auxcon-data>

<app-upload-historical-data-modal
  *ngIf="onShowImportHistoricalDataModal"
  [userProfile]="userProfile.userProfile"
  (onCloseModal)="onCloseImportHistoricalDataModal()"
  (isImported)="onHistoricalDataImported($event)"></app-upload-historical-data-modal>