<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
      <div class="modal-dialog">
  
        <div class="modal-header">
          <h2>{{title}}</h2>
          <button class="modal-dismiss" (click)="onCancel()">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!--#end .modal-header -->
  
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
                <div class="form-group">
                    <label for="first-name">Date</label>
                    <input
                        type="text"
                        class="categotyText"
                        bsDatepicker
                        [bsValue]="parseTransactionDate(workingEditTransaction.Date)"
                        (ngModel)="workingEditTransaction.Date=$event.target.value"
                        (bsValueChange)="onValueChange(workingEditTransaction, $event)"
                        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                        placement="right">
                </div>
            </div>
            <div class="row">
                <div class="form-group">
                    <label for="first-name">Description</label>
                    <input
                        required
                        style="width: 100%;"
                        type="text"
                        class="categotyText"
                        placeholder="Type your transaction description"
                        [(ngModel)]="workingEditTransaction.TransactionDescription">
                </div>
            </div>
            <div class="row">
                <div class="form-group">
                    <label for="first-name">Amount</label>
                    <input
                        class="categotyText"
                        type="text"
                        required
                        style="text-align: right;"
                        [ngModel]="workingEditTransaction.Amount | currency: currencySymbol: currencySymbol:'1.2-2'"
                        [ngModelOptions]="{updateOn: 'blur'}"
                        (ngModelChange)="workingEditTransaction.Amount=$event"
                        (blur)="onAmountChange(workingEditTransaction, $event)"
                        (focus)="onAmountClicked(workingEditTransaction, $event)"
                        (focus)="$event.target.select()"
                        pattern="[P-R\s]*[0-9,.]+"
                        [ngClass]="{'red-border': workingEditTransaction.Amount === 'R0.00' || workingEditTransaction.Amount === 0}"
                        #rateinput>
                </div>
            </div>
          </div> <!-- #end .container-fluid -->
        </div>
        <!--#end .modal-body -->
  
        <div class="modal-footer">
          <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
          <button 
            class="btn btn-success"
            [class.disabled]="!isDirty"
            [disabled]="!isDirty"
            (click)="onSaveTransaction()">Save</button>
          <!-- <span></span> -->
          <!-- <span></span>
          <button class="btn btn-info" (click)="onConfirm()">{{btnTextConfirm}}</button> -->
        </div>
        <!--#end .modal-footer -->
  
      </div>
      <!--#end .modal-dialog -->
    </div>
    <!--#end .modal-content -->
  </div>
  <!--#end .modal -->