import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { TAG_ADD_MULTIPLE_FEES_MODAL } from '../../../services/tags/tags.service';
import { Matter } from '../../../services/matters/matters.service';
import { SubCategory, SubCategoriesService, DEFAULT_SUB_CATEGORY } from '../../../services/sub-categories/sub-categories.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import {
  Fee,
  DEFAULT_FEE,
} from '../../../services/fee-items/fee-items.service';
import * as moment from 'moment';
import { UserProfile, UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { UsercurrencyDirective } from '../../../directive/usercurrency.directive';

const MIN_COMMENT_LENGTH = 5;
const MAX_COMMENT_LENGTH = 500;
const TITLE_PRFIX = 'Add fee to matters';

interface MatterHandle {
  data: Matter;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

@Component({
  selector: 'app-add-multiple-fee-modal',
  templateUrl: './add-multiple-fee-modal.component.html',
  styleUrls: ['./add-multiple-fee-modal.component.scss']
})

export class AddMultipleFeeModalComponent implements OnInit, OnChanges {

  @Input() isVisible = true;
  @Input() matters: MatterHandle[];
  @Output() closeModal: EventEmitter<string> = new EventEmitter();
  @Output() confirmDelete: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<Fee> = new EventEmitter<Fee>();
  @ViewChild('rateinput') RateInput: ElementRef;

  title: string = TITLE_PRFIX;
  matterDescription: Matter[] = [];
  comments: string;
  numberOfCharactersLeft = 500;
  exceedsMaxCommentLength = false;
  subCategories: SubCategory[];
  selectedSubCategory: SubCategory = this.util.objectCopy(DEFAULT_SUB_CATEGORY);
  rateTypes: string[] = [
    'Hourly',
    'Daily',
    'Once Off',
    'Per Kilometer',
    'Per Page',
    'Disbursement',
    'Non Billable'
  ];
  fee: Fee = this.util.objectCopy(DEFAULT_FEE);
  quantityIsReadOnly = false;
  showQunatityToolTip = false;
  showUnsavedChangesPrompt: boolean;
  userProfile: UserProfile;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  onHourlyRateChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.RateInput.nativeElement.value = this.cpipe.transform(this.RateInput.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
      } else {
      this.RateInput.nativeElement.value = this.cpipe.transform(this.RateInput.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
      }
  } // end onHourlyRateChange()

  onHourlyRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.RateInput.nativeElement.value = this.cpipe.parse(this.RateInput.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
      } else {
      this.RateInput.nativeElement.value = this.cpipe.parse(this.RateInput.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
      }
  } // end onHourlyRateClicked()

  get canChangeRateType(): boolean {
    return Boolean(!this.selectedSubCategory.SubCategoryId);
    // return !(Boolean(this.selectedSubCategory) && Boolean(this.selectedSubCategory.SubCategoryId));
  } // end canChangeRateType()

  get calendarItemDate(): string {
    if (!this.fee) {
      const d = moment().format();
      return d.substr(0, d.indexOf('T'));
    }

    if (!this.fee.Date) {
      this.fee.Date = moment().format();
    }

    return this.fee.Date.substr(0, this.fee.Date.indexOf('T'));
  } // end get calendarItemDate()

  get isDirty(): boolean {
    return !this.util.objectIsSame(DEFAULT_FEE, this.fee) && this.isSaveable;
  } // end isDirty()

  get isSaveable(): boolean {
    return Boolean(this.fee.FurtherDescription);
  } // end isSaveable()

  constructor(
    private subCategoriesService: SubCategoriesService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private currencyDirective: UsercurrencyDirective
  ) { } // end constructor()

  ngOnInit() {
    this.fee = Object.assign({}, DEFAULT_FEE);
    const snack: Snack = {
      label: 'Loading Categories...',
      action: null
    };
    this.subCategoriesService.getSubCategoryAll().subscribe({
      next: (subCategories) => {
        this.subCategories = subCategories;
      },
      error: (error) => {
        const msg = 'Error loading sub-categories.';
        console.error(msg, error);
        snack.label = msg;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
      },
      complete: () => {
        this.subCategories.sort((a: SubCategory, b: SubCategory) => {
          return a.Category.localeCompare(b.Category);
        }).sort((a: SubCategory, b: SubCategory) => {
          return a.SubCategory.localeCompare(b.SubCategory);
        });
      }
    });
    let profile = this.userProfileService.userProfile;
    if (profile) {
      if (profile.isImpersonator) {
        this.userProfile = this.userProfileService.selectedUserProfile;
      } else {
        this.userProfile = this.userProfileService.userProfile;
      }
    }
    this.onRateChange(this.fee);
   } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (this.matters) {
      // this.title = TITLE_PRFIX + this.matters.Description;
      this.matters.forEach(s => this.matterDescription.push(s.data));
    }

    if (changes.isVisible && !changes.isVisible.previousValue) {
      this.fee = this.util.objectCopy(DEFAULT_FEE);
      this.selectedSubCategory = this.util.objectCopy(DEFAULT_SUB_CATEGORY);
    }
  } // end ngOnChange()

  validate() {
    let isOk = true;
    this.numberOfCharactersLeft = MAX_COMMENT_LENGTH - this.comments.length;

    if (this.comments.length < MIN_COMMENT_LENGTH) {
      isOk = false;
    }

    if (this.comments.length > MAX_COMMENT_LENGTH) {
      this.exceedsMaxCommentLength = true;
      isOk = false;
    } else {
      this.exceedsMaxCommentLength = false;
    }
  } // end validate(){}

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt =  false;
  } // end onHideUnsavedChangesPrompt()

  onDismiss() {
    if (this.isDirty) {
      this.showUnsavedChangesPrompt = true;
    } else {
      this.dismiss();
    }
  }

  dismiss() {
    this.fee = this.util.objectCopy(DEFAULT_FEE);
    this.selectedSubCategory = this.util.objectCopy(DEFAULT_SUB_CATEGORY);
    this.onHideUnsavedChangesPrompt();
    this.closeModal.emit(TAG_ADD_MULTIPLE_FEES_MODAL);
  } // end dismiss()

  onSave() {
    this.save.emit(this.fee);
    this.dismiss();
  } // end confirmDelete

  onSubCategorySelect(subCategory: SubCategory) {
    this.selectedSubCategory = subCategory;
    this.fee.SubCategoryID = this.selectedSubCategory.SubCategoryId;
    this.fee.FurtherDescription = this.selectedSubCategory.SubCategory;
    this.fee.Rate = this.selectedSubCategory.DefaultRate;
    this.fee.RateType = this.selectedSubCategory.RateType;
    this.onRateChange(this.fee);
  } // end onSubCategorySelect()

  onSubCategoryInput(event) {
    if (this.selectedSubCategory) {
      if (event.inputType.localeCompare('deleteContentBackward') === 0) {
        if (this.selectedSubCategory.SubCategoryId) {
          this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
          this.fee.FurtherDescription = event.target.value;
        } else {
          this.fee.FurtherDescription = event.target.value;
        }
      } else {
          this.fee.FurtherDescription = event.target.value;
      }
    } else {
        this.fee.FurtherDescription = event.target.value;
    }
    this.onRateChange(this.fee);
  } // end onSubCategoryInput()

  onRateChange(fee: Fee) {
    this.quantityIsReadOnly = false;
    switch (this.fee.RateType) {
      case 'Hourly':
        this.fee.Rate = this.userProfile.billingDetails.defaultHourlyRate;
        break;

      case 'Daily':
        this.fee.Rate = this.userProfile.billingDetails.defaultDailyRate;
        break;

      case 'Once Off':
        this.fee.Rate = 0;
        this.fee.Quantity = 1;
        this.quantityIsReadOnly = true;
        break;

      case 'Per Kilometer':
        this.fee.Rate = this.userProfile.billingDetails.defaultPerKilometerRate;
        break;

      case 'Per Page':
        this.fee.Rate = 0;
        break;

      case 'Disbursement':
        this.fee.Rate = 0;
        this.fee.Quantity = 1;
        this.quantityIsReadOnly = true;
        break;

      case 'Non Billable':
        this.fee.Rate =  0;
        this.fee.Quantity = 1;
        this.quantityIsReadOnly = false;
        break;
    }
    this.updateFeeTotal();
    if (this.fee && this.fee.Rate) {
      if (this.userProfileService.userProfile.isImpersonator) {
        if (this.RateInput && this.RateInput.nativeElement) {
          this.RateInput.nativeElement.value = this.cpipe.transform(this.RateInput.nativeElement.value,
            this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
          }
      } else {
        if (this.RateInput && this.RateInput.nativeElement) {
          this.RateInput.nativeElement.value = this.cpipe.transform(this.RateInput.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol, 2);
        }
      }
    }
  } // end onRateChange()
  calculateEndTime() {

  } // end calculateEndTime()

  onDateChange(value: Date): void {
    // TODO: Implement onDateChange
    this.fee.Date = value.toDateString();
  } // end onDateChange()

  onUpdateQuantity() {
    this.calculateEndTime();
    this.updateFeeTotal();
  } // end onUpdateQuantity()

  updateFeeTotal() {
    this.fee.Total =
      this.fee.Rate * this.fee.Quantity;
  } // end updateFeeTotal()

  showToolTip(toolTip: string) {
    switch (toolTip) {
      case 'quantity':
        this.showQunatityToolTip = true;
        break;
    }
  } // end showToolTip()

  hideToolTip(toolTip: string) {
    switch (toolTip) {
      case 'quantity':
        this.showQunatityToolTip = false;
        break;
    }
  } // end showToolTip()

  toggleToolTip(toolTip) {
    switch (toolTip) {
      case 'quantity':
        this.showQunatityToolTip = !this.showQunatityToolTip;
        break;
    }
  } // end toggleQuantityHelp()
  toggleVatable() {
    this.fee.IsVatable = !this.fee.IsVatable;
  } // end toggleVatable()
}
