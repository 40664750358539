<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <div style="width: 100%; height: 900px;">
        <ng2-pdfjs-viewer
          #pdfViewer
          [externalWindow]="false"
          [openFile]="true"
          [viewBookmark]="true"
          [download]="true"
          [downloadFileName]="fileName"></ng2-pdfjs-viewer>
        </div>
      </div> <!-- #end .modal-body .form -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->