import { Component, OnInit, Inject, HostListener, ViewChild } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthService } from '../app/services/auth/auth.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { AuthenticationGuard } from './common/guards/authentication-guard';
import { AppNavbarComponent } from './components/navbars/app-navbar/app-navbar.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Auxcon';
  reportUrl = 'Products';
  isLoggedIn = false;
  idToken: string;
  state: string;
  sessionState: string;
  pageClickEventsSubject: Subject<any>;
  onlineEvent: Observable<boolean>;
  offlineEvent: Observable<boolean>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  materializeNavbar: boolean;
  isAuthenticated = false;
  isIframe = false;
  loginDisplay = false;
  isOnboarding = false;
  isOutlookHandler = false;

  @HostListener('click', ['$event']) onPageClick(event) {
    this.pageClickEventsSubject.next(event);
  } // end onPageClick()

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    const supportPageOffset = window.pageXOffset !== undefined;
    const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    const y = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

    this.materializeNavbar = Boolean(y > 20);

  } // end onScroll()

  @ViewChild(AppNavbarComponent) navbar: AppNavbarComponent;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalAuthService: MsalService,
    private authenticationGuard: AuthenticationGuard,
    private msalBroadcastService: MsalBroadcastService,
    public authService: AuthService,
    private router: Router,
  ) { } // end constructor()

  ngOnInit() {
    document.addEventListener('online', this.onOnline);
    document.addEventListener('offline', this.onOffline);
    this.pageClickEventsSubject = new Subject<any>();

    this.authService.updateLoggedInStatus();

    this.msalAuthService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window


    setTimeout(() => {
      if (this.authenticationGuard.isAuthenticate()) {
        sessionStorage.removeItem('userProfile');
        sessionStorage.clear();
        localStorage.clear(); // TODO: Move to sign out page
        this.authService.logout();
      }
    }, 3.96e+7); // log the user out after 11 hours

  } // end ngOnInit()

  onActivate(event) {
    this.isOnboarding = this.router.url.includes('/onboarding') || this.router.url.includes('/ad-hoc-instruction') ? true : false;
    this.isOutlookHandler = this.router.url.includes('/outlook-handler') ? true : false;
  }

  onOnline() {
  } // end onOnline()

  onOffline() {
  } // end onOffline()
} // end AppComponent{}
