import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ModalStackService } from '../../../../services/modal-stack/modal-stack.service';
import { UtilitiesService } from '../../../../services/utilities.service';
import { UserProfileService, UserProfile, ContactsExtension } from '../../../../services/user-profile/user-profile.service';
import { UsercurrencyPipe } from '../../../../pipe/usercurrency.pipe';
import { UsercurrencyDirective } from '../../../../directive/usercurrency.directive';
import * as moment from 'moment';
import { AdvocateOverviewServiceService, FeeTariffs } from '../../../../services/advocate-overview-service/advocate-overview-service.service';
import { LoadingService } from '../../../../services/messaging/loading/loading.service';
import { Router } from '@angular/router';
import { PATH_MANAGE_BANK_ACCOUNTS } from '../../../../services/appdata/app-config.service';
import { DateParameters } from '../../../../services/manage-BlackList/manage-BlackList.service';
import { HttpClient } from '@angular/common/http';
import { MapsService } from '../../../../services/maps/maps.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit, OnChanges {
  @Input() userProfile: UserProfile;
  @Input() selectedTab: string;
  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() saveUserDetails: EventEmitter<any> =
    new EventEmitter<any>();
  @ViewChild('inputHourly') focusHourly: ElementRef;
  @ViewChild('inputDaily') focusDaily: ElementRef;
  @ViewChild('inputPerKilometer') focusPerKilometer: ElementRef;
  @ViewChild('inputFeeTarget') focusFeeTarget: ElementRef;
  @ViewChild('inputIncomeTarget') focusIncomeTarget: ElementRef;

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney'
  };
  dateParameters: DateParameters = {
    AdmissionDay: null,
    AdmissionMonth: null,
    AdmissionYear: null
  };

  title: string;
  tabView: string;
  firstName: string;
  lastName: string;
  address: string;
  professionalLevels: any;
  banks: any;
  aging: any;
  accountTypes: any;
  specialities: any;

  workingUserProfile: any;
  showUnsavedChangesPrompt: boolean;
  private profile: UserProfile;
  dashboardstatus: boolean;
  isArfraMember: boolean;

  showFeeTariffsModal: boolean;
  feeTariffs: FeeTariffs[] = [];

  vatBasedOn: any;
  admissionDay: any[];
  admissionYear: any[];
  checkAdmissionMonth: number = null;
  checkAdmissionYear: number = null;
  checkAdmissionDay: number = null;
  isValidAdmissionMonth = false;
  isValidAdmissionYear = false;



  places: any = [];
  inputTextValue = '';

  addresses: any = {
    StreetNameAndNumber: '',
    Suburb: '',
    City: '', 
    Province: '',
    Country: '',
    PostalCode: ''
  };

  constructor(
    private mapsService: MapsService,
    private router: Router,
    private http: HttpClient,
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private loadingService: LoadingService,
    private modalStackService: ModalStackService,
    private userProfileService: UserProfileService,
    private currencyDirective: UsercurrencyDirective,
    private advocateOverviewService: AdvocateOverviewServiceService
  ) {
    // this.specialities
    this.tabView = this.selectedTab; // 'personal';
    this.profile = userProfileService.userProfile;
  } // constructor()

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.workingUserProfile, this.userProfile);
  } // end isDirty()

  get isAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator);
  } // end isAssistant()

  get isAttorney(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
  } // end profileType()

  get currencySymbol(): string {
    return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
  } // end currencySymbol()

  changeStatus() {
    if (this.dashboardstatus) {
      this.dashboardstatus = false;
      this.workingUserProfile.settings.show_Dashboard = false;
    } else {
      this.dashboardstatus = true;
      this.workingUserProfile.settings.show_Dashboard = true;
    }
  } // end changeStatus()

  arfraMember() {
    if (this.isArfraMember) {
      this.isArfraMember = false;
      this.workingUserProfile.settings.arfra = false;
    } else {
      this.isArfraMember = true;
      this.workingUserProfile.settings.arfra = true;
    }
  } // end changeStatus()

  // Validations for Personal details data
  areValidPersonalDetails(): Boolean {
    return Boolean(!this.hasFirstName);
  } // end areValidPersonalDetails()

  get hasFirstName(): Boolean {
    return !this.util.hasTextValue(this.workingUserProfile.personalDetails.firstName);
  } // end hasFirstName()

  get hasLastName(): Boolean {
    return !this.util.hasTextValue(this.workingUserProfile.personalDetails.lastName);
  } // end hasLastName()

  get hasFeeTarget(): Boolean {
    return this.workingUserProfile.billingDetails.feeTarget > 0;
  } // end hasFeeTarget()

  get hasFeeIncome(): Boolean {
    return this.workingUserProfile.billingDetails.incomeTarget > 0;
  } // end hasFeeIncome()

  // Validations for Contacts details.
  areValidContactDetails(): Boolean {
    return Boolean(!this.hasPhoneNumberError) && Boolean(!this.hasFaxNumberError) &&
      Boolean(!this.hasCellNumberError) && Boolean(!this.hasContactEmailError);
  } // end areValidContactDetails()

  get hasPhoneNumberError(): boolean {
    return !this.util.isValidPhoneNumber(this.workingUserProfile.contactDetails.workPhone,
      { code: this.userProfileService.userProfile.currencyDetails.code });
  } // end hasCellNumberError()

  get hasFaxNumberError(): boolean {
    if (this.workingUserProfile.contactDetails.workFax) {
      return !this.util.isValidPhoneNumber(this.workingUserProfile.contactDetails.workFax,
        { code: this.userProfileService.userProfile.currencyDetails.code });
    } else {
      return false;
    }
  } // end hasCellNumberError()

  get hasCellNumberError(): boolean {
    return !this.util.isValidPhoneNumber(this.workingUserProfile.contactDetails.mobileNumber,
      { code: this.userProfileService.userProfile.currencyDetails.code });
  } // end hasCellNumberError()

  get hasContactEmailError(): boolean {
    return !this.util.isValidEmail(this.workingUserProfile.contactDetails.emailAddress);
  } // end hasContactEmailError()

  get hasSignedDate(): Boolean {

    return Boolean(this.workingUserProfile.settings.signedDate);
  } // end hasSignedDate()

  get hasAging(): boolean {
    return Boolean(Number(this.workingUserProfile.settings.aging) >= 0);
  } // end hasAging()

  get hasBankingDetails(): boolean {
    return this.util.hasTextValue(this.workingUserProfile.bankDetails.accountNumber) &&
      this.util.hasTextValue(this.workingUserProfile.bankDetails.accountHolder) &&
      Boolean(Number(this.workingUserProfile.bankDetails.branchCode) > 0) &&
      Boolean(this.workingUserProfile.bankDetails.bankID > 0) &&
      this.util.hasTextValue(this.workingUserProfile.bankDetails.accountType);
  } // end hasBankingDetails()

  get feeRates(): Boolean {
    return this.workingUserProfile.billingDetails.defaultDailyRate > 0 &&
      this.workingUserProfile.billingDetails.defaultHourlyRate > 0;
  } // end feeRates()

  get isSaveable(): boolean {
    var isValid = Boolean(this.areValidPersonalDetails())
      && Boolean(this.areValidContactDetails())
      && Boolean(this.hasSignedDate)
      && Boolean(this.hasAging)
      && Boolean(!this.isValidAdmissionYear)
      && Boolean(!this.isValidAdmissionMonth)

    if (isValid === true && !this.isAssistant) {
      return isValid && Boolean(this.hasFeeIncome) &&
        Boolean(this.hasFeeTarget) && Boolean(this.feeRates) && Boolean(this.hasBankingDetails)
        && Boolean(!this.isValidAdmissionYear) && Boolean(!this.isValidAdmissionMonth)
        && Boolean(this.areValidBillingDetails()) && Boolean(this.areValidBankindDetails());
    }

    return isValid;
  } // end isSaveable()

  areValidBillingDetails(): Boolean {
    return true;
  } // end areValidBillingDetails()
  areValidBankindDetails(): Boolean {
    return true;
  } // end areValidBankindDetails()

  ngOnInit() {
    // console.log('ADDRESS STATUS: ', this.userProfile);
    this.admissionDay = [];
    for (let index = 1; index <= 31; index++) {
      this.admissionDay.push(index);
    }
    this.admissionYear = [];
    let years = moment().year();
    for (let index = 1960; index <= years; index++) {
      this.admissionYear.push({
        year: index
      });
    }
    this.admissionYear.sort((a, b) => (a.year < b.year ? 1 : -1));

    this.workingUserProfile.contactsExtension = this.userProfile.contactsExtension;
    if (Boolean(!this.workingUserProfile.addresses)) {
      this.workingUserProfile.addresses = {
        addressID: 0,
        addressType: '',
        city: '',
        contactID: this.workingUserProfile.serviceProviderID,
        country: '',
        postalCode: '',
        stateProvince: '',
        street1: '',
        street2: '',
        suburb: ''
        };
      }
  } // end ngOnInit()

  onInputChanged(inputValue: string): void {
    // console.log('INPUT CHANGE: ', inputValue);
    if (inputValue) {
      this.userProfileService.searchAddress(inputValue).subscribe({ next: (data) => {
        // On next
        console.log('Data returned from api: ', data);
        this.places = data;
      },
      error:  (error) => {
        // On error
        console.log('Error getting Google Data', error);
      },
      complete: () => {
        // On complete
      }
    });
    }
  }

  selectPlace(selectedPlace: TypeaheadMatch): void {
  //  console.log('SELECTED ADDRESS: ', selectedPlace.item.terms);
    // For example, you can set it as the input text value.
      // this.inputTextValue = selectedPlace.item.structured_formatting.main_text;
      // this.workingUserProfile.addresses.Street1 = selectedPlace.item.structured_formatting.main_text;
      console.log('SELECTED DATA:', selectedPlace.item.structured_formatting.main_text);
      
      
      this.userProfileService.searchPlacesDetail(selectedPlace.item.place_id).subscribe({ next: (data) => {
        // On next
        
        if (data) {
          this.addresses.StreetNameAndNumber = data.address_components[0].long_name + ' ' +  data.address_components[1].long_name;
          this.workingUserProfile.addresses.Street1 = data.address_components[0].long_name + ' ' +  data.address_components[1].long_name;
          this.inputTextValue  = data.address_components[0].long_name + ' ' +  data.address_components[1].long_name;
          this.workingUserProfile.addresses.suburb = data.address_components[2].long_name;
          this.workingUserProfile.addresses.city = data.address_components[3].long_name;
          this.workingUserProfile.addresses.stateProvince = data.address_components[5].long_name;
          this.workingUserProfile.addresses.country = data.address_components[6].long_name;
          this.workingUserProfile.addresses.postalCode = data.address_components[7].long_name;
          // console.log('SELECTED ADDRESS :', this.workingUserProfile.addresses.Street1);
          }
      }});
  }

  FeeTariffsView() {
    this.feeTariffs = [];
    this.loadingService.showOverlay();
    this.advocateOverviewService.getFeeTariffs().subscribe({
      next: (data) => {
        // On next
        this.feeTariffs = data;
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
        this.showFeeTariffsModal = true;
      }
    });
  } // end FeeTariffsView()

  onFeeTariffsModalHide() {
    this.showFeeTariffsModal = false;
  } // end onFeeTariffsModalHide()


  parseSignedDate(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return new Date('1000-01-01');
    }
  } // end parseSignedDate()

  ngOnChanges(changes: SimpleChanges) {
    if (this.userProfileService.userProfile) {
      this.dashboardstatus = this.userProfileService.userProfile.settings.show_Dashboard;
      if (this.userProfileService.userProfile.settings.arfra) {
        this.isArfraMember = this.userProfileService.userProfile.settings.arfra;
      } else {
        this.isArfraMember = false;
      }
    }

    if (changes.isVisible && !changes.isVisible.previousValue) {
      this.workingUserProfile = this.util.objectCopy(this.userProfile);
    }

    if (changes.userProfile && changes.userProfile.currentValue) {
      if (!changes.userProfile.previousValue) {
        this.workingUserProfile = this.util.objectCopy(this.userProfile);
      }

      if (!changes.userProfile.previousValue &&
        changes.userProfile.currentValue.personalDetails) {
        this.workingUserProfile.personalDetails =
          this.util.objectCopy(this.userProfile.personalDetails);
      }
    }
    this.title = 'Edit user profile for ' + this.workingUserProfile.personalDetails.fullName;

    // this.isVisible = false;
    if (this.selectedTab) {

      this.tabView = this.selectedTab;
    } else {
      this.tabView = 'personal';
    }

    this.professionalLevels = [
      { label: 'Junior', value: 'Junior' },
      { label: 'Senior', value: 'Senior' },
    ];

    this.vatBasedOn = [
      { id: 'VAT on Paid', name: 'VAT on invoices paid ' },
      { id: 'VAT on Invoiced', name: 'VAT on invoices rendered' }
    ];

    this.specialities = [
      { label: 'Banking and Finance Law', value: 'Banking and Finance Law' },
      { label: 'Corporate and Commercial Law', value: 'Corporate and Commercial Law' },
      { label: 'Employment Law', value: 'Employment Law' },
      { label: 'Environmental and Mining Law', value: 'Environmental and Mining Law' },
      { label: 'Family and Matrimonial Law', value: 'Family and Matrimonial Law' },
      { label: 'General Litigation', value: 'General Litigation' },
      { label: 'Insolvency Law', value: 'Insolvency Law' },
      { label: 'Intellectual Property Law', value: 'Intellectual Property Law' },
      { label: 'Personal Injury Law', value: 'Personal Injury Law' },
      { label: 'Property Law', value: 'Property Law' },
      { label: 'Tax Law', value: 'Tax Law' },
      { label: 'Other', value: 'Other' },
    ];

    this.aging = [
      { id: 0, name: '0 Day' },
      { id: 30, name: '30 Days' },
      { id: 60, name: '60 Days' },
      { id: 90, name: '90 Days' },
      { id: 98, name: '98 Days' }
    ];

    this.banks = [
      { id: 1, name: 'First National Bank' },
      { id: 2, name: 'Absa Bank' },
      { id: 3, name: 'Nedbank' },
      { id: 4, name: 'INVESTEC BANK' },
      { id: 5, name: 'Standard Bank' },
      { id: 6, name: 'RMB Private Bank' },
      { id: 7, name: 'Capitec Bank' },
      { id: 8, name: 'Mercantile Bank' },
      { id: 9, name: 'Standard Bank - Private Bank' },
      { id: 10, name: 'Stanbic Bank' },
      { id: 11, name: 'Old Mutual Bank' },
      { id: 12, name: 'Discovery Bank' },
      { id: 13, name: 'Capitec Business Bank' }
    ];

    this.accountTypes = [
      { label: 'Cheque/Current', value: 'Cheque Account' },
      { label: 'Savings', value: 'Savings Account' },
      { label: 'Transmission', value: 'Transmission Account' }
    ];

    if (this.userProfile && this.userProfile.serviceProviderID === 13553
      || this.userProfileService.selectedUserProfile && this.userProfileService.selectedUserProfile.serviceProviderID === 13553) {
      this.aging.push(
        { id: 7, name: '7 Days' }
      )
      this.aging.sort((a, b) => {
        return a.id - b.id;
      })
    }

    //get admission date
    if (this.workingUserProfile.contactsExtension.admissionDay)
      this.checkAdmissionDay = this.workingUserProfile.contactsExtension.admissionDay;
    else
      this.checkAdmissionDay = 0;
    if (this.workingUserProfile.contactsExtension.admissionMonth)
      this.checkAdmissionMonth = this.workingUserProfile.contactsExtension.admissionMonth;
    else
      this.checkAdmissionMonth = 0;
    if (this.workingUserProfile.contactsExtension.admissionYear)
      this.checkAdmissionYear = this.workingUserProfile.contactsExtension.admissionYear;
    else
      this.checkAdmissionYear = 0;
  } // end ngOnChanges()

  updateBankAccounts() {
    this.router.navigate([PATH_MANAGE_BANK_ACCOUNTS]);
    this.onDismiss();
  } // updateBankAccounts()

  changeManualTransactionsStatus() {
    this.workingUserProfile.settings.manualTransaction = !this.workingUserProfile.settings.manualTransaction;
  } // end changeManualTransactionsStatus()

  onSignedDateChange(value: Date): void {
    this.workingUserProfile.settings.signedDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  } // end onSignedDateChange()

  onFeeTargetChange() {
    this.focusFeeTarget.nativeElement.value = this.cpipe.transform(this.focusFeeTarget.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol, 2);
  } // end onFeeTargetChange()
  onFeeTargetClicked() {
    this.focusFeeTarget.nativeElement.value = this.cpipe.parse(this.focusFeeTarget.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol);
  } // end onFeeTargetClicked()
  onIncomeTargetChange() {
    this.focusIncomeTarget.nativeElement.value = this.cpipe.transform(this.focusIncomeTarget.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol, 2);
  } // end onIncomeTargetChange()
  onIncomeTargetClicked() {
    this.focusIncomeTarget.nativeElement.value = this.cpipe.parse(this.focusIncomeTarget.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol);
  } // end onIncomeTargetClicked()
  onHourlyRateChange() {
    this.focusHourly.nativeElement.value = this.cpipe.transform(this.focusHourly.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol, 2);
  } // end onHourlyRateChange()
  onHourlyRateClicked() {
    this.focusHourly.nativeElement.value = this.cpipe.parse(this.focusHourly.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol);
  } // end onHourlyRateClicked()
  onDailyRateChange() {
    this.focusDaily.nativeElement.value = this.cpipe.transform(this.focusDaily.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol, 2);
  } // end onDailyRateChange()
  onDailyRateClicked() {
    this.focusDaily.nativeElement.value = this.cpipe.parse(this.focusDaily.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol);
  } // end onDailyRateClicked()
  onPerkilometerRateChange() {
    this.focusPerKilometer.nativeElement.value = this.cpipe.transform(this.focusPerKilometer.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol, 2);
  } // end onPerkilometerRateConPerkilometerRateClickedhange()
  onPerkilometerRateClicked() {
    this.focusPerKilometer.nativeElement.value = this.cpipe.parse(this.focusPerKilometer.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol);
  } // end onPerkilometerRateClicked()

  toggleModal(): void {
    if (this.isVisible) {
      this.show();
    } else {
      this.dismiss();
    }
  } // toggleModal()

  onDismiss() {
    // if isDirty
    if (this.isDirty) {
      // prompt to save changes
      this.showUnsavedChangesPrompt = true;
    } else {
      this.dismiss();
    }
  } // end onDismiss()

  dismiss(): void {
    this.onHideUnsavedChangesPrompt();
    this.workingUserProfile = this.util.objectCopy(this.userProfile);
    this.closeModal.emit('edit-profile');
  } // end dismiss()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onHideUnsavedChangesPrompt()

  show(): void {
    // Push on to modal stack
    // this.isVisible = true;
  } // end show()

  save(): void {
    // Combine firstName and lastName
    // const fullName = [
    //   this.workingUserProfile.personalDetails.title,
    //   this.workingUserProfile.personalDetails.firstName,
    //   this.workingUserProfile.personalDetails.lastName
    // ];
    // this.workingUserProfile.personalDetails.fullName = fullName.join(' ').trim();


    if (!this.workingUserProfile.contactsExtension) {
      this.workingUserProfile.contactsExtension = {} as ContactsExtension;
    }


    this.workingUserProfile.contactsExtension.admissionDay = this.checkAdmissionDay;
    this.workingUserProfile.contactsExtension.admissionMonth = this.checkAdmissionMonth;
    this.workingUserProfile.contactsExtension.admissionYear = this.checkAdmissionYear;


    this.saveUserDetails.emit(this.workingUserProfile);
    this.dismiss();
  } // end save()

  changeTabView(tabView: string) {
    this.tabView = tabView;
    if (this.tabView === 'billing') {
      // setTimeout(() => {
      //   this.focusPerKilometer.nativeElement.value = this.cpipe.transform(this.focusPerKilometer.nativeElement.value,
      //     this.currencySymbol, 2);
      //   this.focusHourly.nativeElement.value = this.cpipe.transform(this.focusHourly.nativeElement.value,
      //     this.currencySymbol, 2);
      //   this.focusDaily.nativeElement.value = this.cpipe.transform(this.focusDaily.nativeElement.value,
      //     this.currencySymbol, 2);
      //   this.focusFeeTarget.nativeElement.value = this.cpipe.transform(this.focusFeeTarget.nativeElement.value,
      //     this.currencySymbol, 2);
      //   this.focusIncomeTarget.nativeElement.value = this.cpipe.transform(this.focusIncomeTarget.nativeElement.value,
      //     this.currencySymbol, 2);
      // }, 100);
    }
  } // end changeTabView()

  onCurrencyInputFocus(event) {
    this.util.formatCurrencyInput(event.target);
  } // end onCurrencyInputFocus()

  onCurrencyInputChange(event) {
    this.util.formatCurrencyInput(event.target);
  } // end onCurrencyInputChange()

  onPhoneNumberInputFocus(event) {
    // this.util.formatPhoneNumberInput(event.target);
  } // end onPhoneNumberInputFocus()

  onPhoneNumberInputChange(event) {
    // this.util.formatPhoneNumberInput(event.target);
  } // end onPhoneNumberInputChange()

  onLastVATPeriodEndDateParse(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return new Date();
    }
  } // end onLastVATPeriodEndDateParse()

  onLastVATPeriodEndDateChange(value: Date): void {
    if (value) {
      this.workingUserProfile.personalDetails.lastVATPeriodEndDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
    }
  } // end onLastVATPeriodEndDateChange()


  validateAdmissionDate(day, month, year) {
    this.isValidAdmissionMonth = false;
    this.isValidAdmissionYear = false;

    if (day > 0) {
      if (month === null || month === "" || month === undefined || month == "0") {
        this.isValidAdmissionMonth = true;
      }
      if (year === null || year === "" || year === undefined || year == "0") {
        this.isValidAdmissionYear = true;
      }
    }
    if (month > 0) {
      if (year === null || year === "" || year === undefined || year == "0") {
        this.isValidAdmissionYear = true;
      }
    }
  }

  onChangeAdmissionDay(day) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  onChangeAdmissionMonth(month) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  onChangeAdmissionYear(year) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  onCheckboxChecked(event: Event): void {
    const isChecked: boolean = event.target['checked']; // or event.target.checked
   }

   toggleSelectAll(){
    this.workingUserProfile.settings.bcc = !this.workingUserProfile.settings.bcc;
   }


} // end ProfileModalComponent{}
