import { Injectable } from '@angular/core';

export const TAG_EDIT_PROFILE_MODAL = 'edit-profile';
export const TAG_EDIT_FEE_TEMPLATE_MODAL = 'edit-fee-template';
export const TAG_ADD_TEAM_MEMBER = 'app-add-team-member-modal';
export const TAG_ADD_NEW_LAW_FIRM_MODAL = 'add-new-law-firm-modal';
export const TAG_REQUEST_NEW_ADVOCATE_GCB_MODAL = 'app-request-new-advocate-gcb-modal';
export const TAG_ADD_ATTORNEY_FIRM_MODAL = 'add-new-attorney-modal';
export const TAG_DELETE_MATTER_MODAL = 'delete-matter-modal';
export const TAG_DELETE_MUTLIPLE_MATTERS_MODAL = 'app-delete-multiple-matter-modal';
export const TAG_CREATE_RECIPIENT_MODAL = 'create-recipient-modal';
export const TAG_ADD_EMAIL_ADDRESS_MODAL = 'add-email-address-modal';
export const TAG_SEND_TO_RECIPIENT_MODAL = 'send-to-recipient-modal';
export const TAG_PROFORMA_INVOICE_MODAL = 'proforma-invoice';
export const TAG_ADD_MULTIPLE_FEES_MODAL = 'app-add-multiple-fees-modal';
export const TAG_ADD_PLAINTIFF_MODAL = 'add-plaintiffdefendant-modal';
export const TAG_ADD_CLIENT_MODAL = 'app-add-new-client';
export const TAG_INVOICE_REVIEW_MODAL = 'invoice-review-modal';
export const TAG_PAYMENT_DETAIL_MODAL = 'payment-detail-modal';
export const TAG_ADD_INVOICE_NOTE_MODAL = 'app-add-invoice-note';
export const TAG_ADVOCATE_STATEMENT_MODAL = 'app-advocate-statement-modaladvocate-statement';
export const TAG_IMAGE_VIEWER_MODAL = 'app-image-viewer';
export const TAG_MANAGE_BLACKLIST_MODAL = 'app-add-BlackList-modal';

@Injectable()
export class TagsService {

  constructor() { } // end  constructor()

} // end TagsService{}
