<!-- <button  (click)="toast('this is a toast')">toast</button> -->
<div
  #container
  class="snackbar-container"
  [class.snackbar-danger]="type === 'snackbar-danger'"
  [class.snackbar-warning]="type === 'snackbar-warning'"
  [class.snackbar-primary]="type === 'snackbar-primary'"
  [class.snackbar-info]="type === 'snackbar-info'"
  [@snackbarState]="snackbarState">
  <span class="snackbar-text-label">{{text}}</span>
  <button
    class="snackbar-action"
    *ngIf="action"
    (click)="onAction()">{{action.label}}</button>
</div><!--#end .snackbar-container -->