<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">

<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onCancel()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body">
        <!-- <div class="form-control"> -->
          <!-- <label>Handed over to: </label> -->
          <input
            [(ngModel)]="selectedFirm" 
            [typeahead]="AllSelectedFirm" 
            typeaheadOptionField="FullName"
            [typeaheadSingleWords]="false"
            class="form-control"
            placeholder="Select or start typing" 
            (typeaheadOnSelect)="onLawfirmSelect($event)" 
            [typeaheadOptionsLimit]="20"
            typeaheadScrollable=true 
            typeaheadOptionsInScrollableView="10" 
            [typeaheadMinLength]="0" 
            [dropup]="false">
        <!-- </div> #end .container-fluid -->
      </div> <!-- #end .modal-body -->

      <div class="modal-footer">
        <button
          class="btn btn-danger"
          (click)="onCancel()">Cancel</button>
        <button
          class="btn btn-success"
          [class.disabled]="!isSaveable"
          [disabled]="!isSaveable"
          (click)="onContinue()">Proceed</button>
      </div> <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->