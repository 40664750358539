<div #scripts></div>
<div #control style="width:100%; height: 1000px">
  <!-- <div data-bind="dxReportViewer: $data"></div> -->
  <div class="flex-container">
    <!-- Report area -->
    <div style="width:100%; height:900px">
      <!-- <app-report-viewer [reportUrl]="reportUrl"></app-report-viewer> -->
      <ng2-pdfjs-viewer 
        #pdfViewer 
        [externalWindow]="false" 
        [openFile]="true" 
        [viewBookmark]="true" 
        [download]="true"
        [downloadFileName]="fileName"></ng2-pdfjs-viewer>
    </div>
  </div><!-- #flex-container -->
</div>