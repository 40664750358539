<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>

<div class="container-fluid container-page">
  
  <!-- <div class="well"> -->
    <div class="column-display-area">
      <div class="display-options">
        <span class="column-search">
          <button
            class="btn btn-text btn-input-group"
            (click)="filterFeeTemplates()">
            <i class="fa fa-search"></i>
          </button>
          <input
            type="search"
            placeholder="Search fee templates"
            (input)="filterFeeTemplates()"
            (keyup.enter)="filterFeeTemplates()"
            [(ngModel)]="searchTerm">
        </span> <!-- #end .column-search -->
        <span class="column-order-by">
          <label for="sort-by">Sort by:</label>
          <select
            id="sort-by"
            placeholder="Filter"
            (change)="orderFeeTemplate"
            [(ngModel)]="orderBy">
            <option value="" disabled selected hidden> Filter option</option>
            <option *ngFor="let option of orderOptions" [value]="option">
              {{option | titlecase}}
            </option>
          </select>
          <button
            class="btn btn-toggle"
            (click)="toggleSortAscending()">
            <i class="fa" [class.fa-sort-amount-desc]="!sortAscending" [class.fa-sort-amount-asc]="sortAscending"></i>
          </button>
          <!--#end .btn.btn-toggle -->
        </span> <!-- #end .column-order-by -->
      </div> <!-- #end .display-options -->
    
    <div class="table">
      <div class="table-head">
  
        <div class="table-row">
          <span
            class="column-description clickable"
            (click)="onOrderBy('Description')">
            Description
            <i
              class="fa fa-sort"
              [class.fa-sort-down]="sortDescription"></i>
          </span><!--#end .column-description -->
          <span
            class="column-category clickable"
            (click)="onOrderBy('Category')">
            Category
            <i
              class="fa fa-sort"
              [class.fa-sort-down]="sortCategory"></i>
          </span><!--#end .column-category -->
          <span
            class="column-rate-type clickable"
            (click)="onOrderBy('Rate type')">
            Rate type
            <i
              class="fa fa-sort"
              [class.fa-sort-down]="sortRateType"></i>
          </span><!--#end .column-rate-type -->
          <span class="column-action form">
            <div class="input-group">
              
            </div><!--#end .input-group -->
          </span><!--#end .column-action -->
        </div><!--#end .table-row -->

      </div><!--#end .table-head -->

      <div *ngIf="isLoading" class="table-body">
        <app-circular-indicator
          [isVisible]="isLoading"></app-circular-indicator>
      </div><!--#end .table-body -->

      <div *ngIf="!isLoading" class="table-body">
        <div
          *ngFor="let template of feeTemplatesHandles"
          class="table-row"
          [class.hide]="!template.state.isVisible">
          <span class="column-description">
            {{template.data.SubCategory}}
          </span>
          <!--#end .column-description -->
          <span class="column-category">
            {{template.data.Category}}
          </span>
          <!--#end .column-category -->
          <span class="column-rate-type">
            {{template.data.RateType}}
          </span>
          <!--#end .column-rate-type -->
          <span class="column-action">
            <span
              class="clickable"
              (click)="onEditTemplate(template.data)">Edit fee template</span>
          </span>
          <!--#end .column-action -->
        </div><!--#end .table-row -->
        
      </div><!--#end .table-body -->

      <div class="table-foot">
        <div class="table-row">
          <span
            class="clickable"
            (click)="onAddNewFeeTemplate()">
            <i class="fa fa-plus-circle fa-lg auxcon-green"></i>
            <u class="auxcon-green">Add a new fee template</u>
          </span>
        </div>
      </div><!--#end .table-foot -->

    </div><!--#end .table -->
  </div> <!-- #end .column-display-area -->
  <!-- </div>#end .well -->
</div><!--#end .container-fluid .container-page -->
<app-edit-fee-template-modal
  [isVisible]="showFeeTemplateModal"
  [feeTemplate]="activeFeeTemplate"
  (closeModal)="onCloseModal()"
  (saveFeeTemplate)="onSaveFeeTemplate($event)"></app-edit-fee-template-modal><!--#end app-edit-fee-template -->