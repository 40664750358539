import { Component, OnInit, OnDestroy, Inject, ViewChild, } from '@angular/core';

// import { DashboardDataService } from '../../services/dashboard-data/dashboard-data.service';
import { ActivatedRoute } from '@angular/router';
import { ListsService, LIST_NAME_LAW_FIRMS } from '../../services/lists/lists.service';
import { SnackbarsService, Snack, SnackType } from '../../services/messaging/snackbars/snackbars.service';
import { DashboardNewService } from '../../services/dashboard-new/dashboard-new.service';
// import { stat } from 'fs';
import { UserProfile, UserProfileService } from '../../services/user-profile/user-profile.service';
import {
    LoadingOverlayModalOptions,
    DEFAULT_LOADINGOVERLAY_OPTIONS } from '../../components/modals/loading-overlay-modal/loading-overlay-modal.component';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { ApiService } from '../../services/api.service';
import { IndexedDBService } from '../../services/indexed-db/indexed-db.service';
import { BriefsAndMattersTableComponent } from '../../components/tables/briefs-and-matters-table/briefs-and-matters-table/briefs-and-matters-table.component';

@Component({
  selector: 'app-advocate-dashboard',
  templateUrl: './advocate-dashboard.component.html',
  styleUrls: ['./advocate-dashboard.component.scss']
})
export class AdvocateDashboardComponent implements OnInit, OnDestroy {
  currentMattersData: any;
  invoicedData: any;
  collectedData: any;
  currentMattersYearlyData: any;
  invoicedYearlyData: any;
  collectedYearlyData: any;
  // dashboardData: any;
  currMonthly: any;
  currYearly: any;
  invMonthly: any;
  invYearly: any;
  collMonthly: any;
  collYearly: any;
  displayDashboard: boolean;
  userProfile: UserProfile;
  options: LoadingOverlayModalOptions = Object.assign({}, DEFAULT_LOADINGOVERLAY_OPTIONS);

  onShowImportHistoricalDataModal = false;

  @ViewChild(BriefsAndMattersTableComponent) briefsAndMattersTable: BriefsAndMattersTableComponent;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private api: ApiService,
    private route: ActivatedRoute,
    private authService: MsalService,
    private listsService: ListsService,
    private indexedDbService: IndexedDBService,
    private snackbarsService: SnackbarsService,
    private dashboardService: DashboardNewService,
    private userProfileService: UserProfileService
  ) {
    document.title = route.snapshot.data.title;
  } // end constructor()

  ngOnInit() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount) {
    } else {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }

    if (this.userProfileService.refreshSelectedProfile) {
      location.reload();
    }
    // console.log('SELECTED ADVOCATE: ', this.api.serviceProviderID,  this.userProfileService.selectedUserProfile);
    if (this.api.serviceProviderID && this.api.serviceProviderID != undefined) {
      this.userProfileService.isInDashboardScreen = true;
      // this.getCurrentMonthlyDashboard();
      this.getInvoicedMonthlyDashboard();
      this.getCollectedMonthlyDashboard();
      // this.getCurrentYearlyDashboard();
      this.getInvoicedYearlyDashboard();
      this.getCollectedYearlyDashboard();
    }

    this.userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
    if (this.userProfile) {
      if(this.userProfileService.isJustSignedIn && this.userProfile.settings.isHistoricalImported === false && !this.userProfile.isImpersonator) {
        this.onShowImportHistoricalDataModal = true;
        this.userProfileService.isJustSignedIn = false;
      }
      if (this.userProfile.serviceProviderID === 29723) {
        this.displayDashboard = false;
      } else {
        // Check if the user is working on behalf of another person
        if (this.userProfile.isImpersonator) {
          // Check if there is a profile for the user he/she working on behalf
          if (this.userProfileService.selectedUserProfile) {
            this.displayDashboard = this.userProfileService.selectedUserProfile.permissions.view_Dashboard;
            if (this.displayDashboard) {
              this.displayDashboard = this.userProfile.settings.show_Dashboard;
            }
          }
        } else {
          this.displayDashboard = this.userProfile.settings.show_Dashboard;
        }
      }
    }


    // if (!localStorage.getItem(LIST_NAME_LAW_FIRMS)) {
      this.listsService.buildLawFirmsList();
    // }
    
    this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData').then((data) => {
      if (!data || (data && data.data.length === 0)) {
          // If data is not found or empty, build the law firms list
          console.error('Check the list of data returned', data);
          this.listsService.buildLawFirmsList();
      }
  }).catch((error) => {
    // In case of error, also attempt to rebuild the list
    // (You may decide to handle this differently based on your application's needs)
    this.listsService.buildLawFirmsList();
    console.error('Error retrieving law firms from IndexedDB Wiseman: ', error);
  });
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  } // end  ngOnInit()

  onCloseImportHistoricalDataModal() {
    this.onShowImportHistoricalDataModal = false;
  } // end onCloseImportHistoricalDataModal()

  onHistoricalDataImported(event) {
    this.briefsAndMattersTable.loadFolders(); 
    this.onShowImportHistoricalDataModal = false;
  } // end onHistoricalDataImported()

  ngOnDestroy() {
    this.userProfileService.isInDashboardScreen = false;
  } // end ngOnDestroy()

  getCurrentMonthlyDashboard() {
    const snack: Snack = {
      label: 'Loading Dashboard data.',
      action: null
    };

    this.snackbarsService.make(snack, 5000).show();
    this.dashboardService.getCurrentMonthly().subscribe({ next: 
      (dashboard) => {
        this.currMonthly = dashboard;
        for (const property in this.currMonthly) {
          if (!this.currMonthly[property]) {
            this.currMonthly[property] = '';
          }
        }
        const msg = 'Dashboard data loaded.';
        snack.label = msg;
        this.snackbarsService.dismiss().make(snack).show();
      },
      error: (error) => {
        const msg = 'Error loading dashboard data.';
        snack.label = msg;
        snack.type = SnackType.ERROR;
        // this.snackbarsService.dismiss().make(snack).show();
      },
      complete: () => {
        this.dashboardService.getCurrentMattersData(this.currMonthly).subscribe(data => {
          this.currentMattersData = data;
        });
      }
    });
  } // end CurrentMonthlyDashboard

  getCurrentYearlyDashboard() {
    const snack: Snack = {
      label: 'Loading Dashboard data.',
      action: null
    };

    this.snackbarsService.make(snack, 5000).show();
    this.dashboardService.getCurrentYearly().subscribe({ next: 
      (dashboard) => {
        this.currYearly = dashboard;
        for (const property in this.currYearly) {
          if (!this.currYearly[property]) {
            this.currYearly[property] = '';
          }
        }
        const msg = 'Dashboard data loaded.';
        snack.label = msg;
        this.snackbarsService.dismiss().make(snack).show();
      },
      error: (error) => {
        const msg = 'Error loading dashboard data.';
        snack.label = msg;
        snack.type = SnackType.ERROR;
        // this.snackbarsService.dismiss().make(snack).show();
      },
      complete: () => {
        this.dashboardService.getCurrentYearlyMattersData(this.currYearly).subscribe(data => {
          this.currentMattersYearlyData = data;
        });
      }
    });
  } // end CurrentMonthlyDashboard

  getInvoicedMonthlyDashboard() {
    const snack: Snack = {
      label: 'Loading Dashboard data.',
      action: null
    };

    this.snackbarsService.make(snack, 5000).show();
    this.dashboardService.getInvoicedMonthly().subscribe({ next: 
      (dashboard) => {
        this.invMonthly = dashboard;
        for (const property in this.invMonthly) {
          if (!this.invMonthly[property]) {
            this.invMonthly[property] = '';
          }
        }
        const msg = 'Dashboard data loaded.';
        snack.label = msg;
        this.snackbarsService.dismiss().make(snack).show();
      },
      error: (error) => {
        const msg = 'Error loading dashboard data.';
        snack.label = msg;
        snack.type = SnackType.ERROR;
        // this.snackbarsService.dismiss().make(snack).show();
      },
      complete: () => {
        this.dashboardService.getInvoicedData(this.invMonthly).subscribe(data => {
          this.invoicedData = data;
        });
      }
    });
  } // end getInvoicedMonthlyDashboard

  getInvoicedYearlyDashboard() {
    const snack: Snack = {
      label: 'Loading Dashboard data.',
      action: null
    };

    this.snackbarsService.make(snack, 5000).show();
    this.dashboardService.getInvoicedYearly().subscribe({ next: 
      (dashboard) => {
        this.invYearly = dashboard;
        // console.log('INVOICE YEARLY: ', dashboard)
        for (const property in this.invYearly) {
          if (!this.invYearly[property]) {
            this.invYearly[property] = '';
          }
        }
        const msg = 'Dashboard data loaded.';
        snack.label = msg;
        this.snackbarsService.dismiss().make(snack).show();
      },
      error: (error) => {
        const msg = 'Error loading dashboard data.';
        snack.label = msg;
        snack.type = SnackType.ERROR;
        // this.snackbarsService.dismiss().make(snack).show();
      },
      complete: () => {
        this.dashboardService.getInvoicedYearlyData(this.invYearly).subscribe(data => {
          this.invoicedYearlyData = data;
        });
      }
    });
  } // end getInvoicedMonthlyDashboard

  getCollectedMonthlyDashboard() {
    const snack: Snack = {
      label: 'Loading Dashboard data.',
      action: null
    };

    this.snackbarsService.make(snack, 5000).show();
    this.dashboardService.getCollectedMonthly().subscribe({ next: 
      (dashboard) => {
        this.collMonthly = dashboard;
        for (const property in this.collMonthly) {
          if (!this.collMonthly[property]) {
            this.collMonthly[property] = '';
          }
        }
        const msg = 'Dashboard data loaded.';
        snack.label = msg;
        this.snackbarsService.dismiss().make(snack).show();
      },
      error: (error) => {
        const msg = 'Error loading dashboard data.';
        snack.label = msg;
        snack.type = SnackType.ERROR;
        // this.snackbarsService.dismiss().make(snack).show();
      },
      complete: () => {
        this.dashboardService.getCollectedData(this.collMonthly).subscribe(data => {
          this.collectedData = data;
        });
      }
    });
  } // end getCollectedMonthlyDashboard

  getCollectedYearlyDashboard() {
    const snack: Snack = {
      label: 'Loading Dashboard data.',
      action: null
    };

    this.snackbarsService.make(snack, 5000).show();
    this.dashboardService.getCollectedYearly().subscribe({ next: 
      (dashboard) => {
        this.collYearly = dashboard;
        for (const property in this.collYearly) {
          if (!this.collYearly[property]) {
            this.collYearly[property] = '';
          }
        }
        const msg = 'Dashboard data loaded.';
        snack.label = msg;
        this.snackbarsService.dismiss().make(snack).show();
      },
      error: (error) => {
        const msg = 'Error loading dashboard data.';
        snack.label = msg;
        snack.type = SnackType.ERROR;
        // this.snackbarsService.dismiss().make(snack).show();
      },
      complete: () => {
        this.dashboardService.getCollectedYearlyData(this.collYearly).subscribe(data => {
          this.collectedYearlyData = data;
        });
      }
    });
  } // end getCollectedYearlyDashboard

  

} // end AdvocateDashboardComponent{}
