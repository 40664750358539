import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ReportsService } from '../../../services/reports/reports.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { AgeAnalysisToolTipComponent } from '../age-analysis/age-analysis-tooltip/age-analysis-tooltip.component';
import { Router } from '@angular/router';
import { PATH_REPORT_ALL_CUSTOMERS } from '../../../services/appdata/app-config.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';


@Component({

  selector: 'age-analysis-report',
  templateUrl: './age-analysis.component.html',
  styleUrls: ['./age-analysis.component.scss']
})
export class AgeAnalysisReportComponent implements OnInit {
  isDataLoaded = false;
  agingCurrentTotal = 0;
  aging30Total = 0;
  aging60Total = 0;
  aging90Total = 0;
  aging98Total = 0;
  agingCurrentPercentage = 0;
  aging30Percentage = 0;
  aging60Percentage = 0;
  aging90Percentage = 0;
  aging98Percentage = 0;
  renderTooltipOverlay = true;
  data: any = [];
  totalOutstanding = 0;
  _currentTooltipData: any = [];
  _30TooltipData: any = [];
  _60TooltipData: any = [];
  _90TooltipData: any = [];
  _98TooltipData: any = [];
  _allTooltipData: any = [];
  apiData: any = [];

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onRowClick = new EventEmitter<string>();

  @ViewChild('AgeAnalysisToolTipComponent') ageAnalysisToolTipComponent: AgeAnalysisToolTipComponent;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private reportsService: ReportsService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private router: Router
  ) { } // end constructor()

  ngOnInit() {
    this.getAgeAnalysisReport();
  }

  getAgeAnalysisReport(): void {
    this.isDataLoaded = false;
    this.loadingService.showOverlay();
    this.reportsService.getAgeAnalysis().subscribe({ next: (response) => {

      if (response) {
        this.agingCurrentPercentage = response.agingCurrentPercentage;
        this.aging30Percentage = response.aging30Percentage;
        this.aging60Percentage = response.aging60Percentage;
        this.aging90Percentage = response.aging90Percentage;
        this.aging98Percentage = response.aging98Percentage;

        for (const item of response.frequencyTotals) {

          this.aging30Total += item.aging30Total;
          this.aging60Total += item.aging60Total;
          this.aging90Total += item.aging90Total;
          this.aging98Total += item.aging98Total;
          this.agingCurrentTotal += item.agingCurrentTotal;
          this.totalOutstanding += item.totalOutstanding;
        }
        this.data = response.frequencyTotals;

      }
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        this.isDataLoaded = true;
        this.getTooltipData();
        // this.loadingService.hideOverlay();
      }
    });
  }

  numberConversion(value: number): string {
    return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  getBarHeight(value: number): string {
    return (value * 150 / 100) + 'px';
  }

  getBarTextColor(value: number): string {
    const height = (value * 150 / 100);
    if (height < 17) {
      return '#000';
    }
    return '#fff';
  }

  getBarTextTopMargin(value: number): string {
    const height = (value * 150 / 100);
    if (height < 17) {
      return '-20px';
    }
    return ((height / 2) - 9) + 'px';
  }


  getTooltipData(): void {
    this.renderTooltipOverlay = false;
    this.reportsService.getAllCustomersReport().subscribe({ next: (response) => {
      this.apiData = response;

      this._currentTooltipData = this.setTooltipData('currentTotals');
      this._30TooltipData = this.setTooltipData('thirtyDaysTotals');
      this._60TooltipData = this.setTooltipData('sixtyDaysTotals');
      this._90TooltipData = this.setTooltipData('ninetyDaysTotals');
      this._98TooltipData = this.setTooltipData('ninetyEightDaysTotals');
      this._allTooltipData = this.setTooltipData('allTotals');
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        sessionStorage.setItem('allCustomerReportData', JSON.stringify(this.apiData));
        this.loadingService.hideOverlay();
        this.renderTooltipOverlay = true;
      }
    });
  }

  setTooltipData(key: string): any {
    this.apiData[key].sort((a, b) => b.outstanding - a.outstanding);
    if (this.apiData[key].length > 10) {
      return this.apiData[key].slice(0, 10);
    } else {
      return this.apiData[key];
    }
  }

  getBottomRow(index: number, length: number): string {
    if (length > 0 && index === length - 1) {
      return '20px';
    }
    return '0px';
  }

  receiveToolTipAge($event) {
    this.reportsService.allCustomerActiveTab = $event;
    this.router.navigate([PATH_REPORT_ALL_CUSTOMERS]);
    // this.onRowClick.emit($event);
  }
}

