import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


export interface ProcessedTransactionLine {
  Id: number;
  TransactionId: number;
  TransactionLinkId: number;
  Invoice: string;
  InvoiceId: number;
  LawFirm: string;
  LawFirmId: number;
  Matter: string;
  Reference: string;
  Amount: number;
  Paid: number;
  AssignedToLawFirm: boolean;
} // end ProcessedTransactionLine{}
export const DEFAULT_PROCESSED_TRANSACTION_LINE: ProcessedTransactionLine = {
  Id: 0,
  TransactionId: 0,
  TransactionLinkId: 0,
  Invoice: '',
  InvoiceId: 0,
  LawFirm: '',
  LawFirmId: 0,
  Matter: '',
  Reference: '',
  Amount: 0,
  Paid: 0,
  AssignedToLawFirm: false
}; // end DEFAULT_PROCESSED_TRANSACTION_LINE{}

export interface ProofOfPayment {
  Id: number;
  Date: string;
  Description: string;
  LawFirm: string;
  LawFirmId: number;
  Reference: string;
  Amount: number;
  Allocated: string;
  Attachment: string;
  HasAttachment: boolean;
  ProofOfPaymentLines: any;
} // end ProofOfPayment{}

export interface AddedProofOfPayment {
  Allocated: string;
  Amount: number;
  Attachment: string;
  Date: string;
  Description: string;
  HasAttachment: boolean;
  Id: number;
  LawFirm: string;
  LawFirmId: number;
  ProofOfPaymentLines: ProofOfPaymentLines[];
  Reference: string;
} // end AddedProofOfPayment{}

export const DEFAULT_ADDED_PROOF_OF_PAYMENT: AddedProofOfPayment = {
  Id: 0,
  Date: '',
  Description: '',
  LawFirm: '',
  LawFirmId: 0,
  Reference: '',
  Amount: 0,
  Allocated: '',
  Attachment: '',
  ProofOfPaymentLines: [],
  HasAttachment: false
};
export const DEFAULT_PROOF_OF_PAYMENT: ProofOfPayment = {
  Id: 0,
  Date: '',
  Description: '',
  LawFirm: '',
  LawFirmId: 0,
  Reference: '',
  Amount: 0,
  Allocated: '',
  HasAttachment: false,
  Attachment: '',
  ProofOfPaymentLines: []
};

export interface ProofOfPaymentLines {

  invoice: string;
  invoiceId: number;
  lawFirm: string;
  lawFirmId: number;
  matter: string;
  reference: string;
  amount: number;
  paid: number;
}

export interface POPLine {
  Amount: number;
  AssignedToLawFirm: boolean;
  Id: number;
  Invoice: string;
  InvoiceId: number;
  Matter: string;
  Paid: number;
  Reference: string;
}
export interface TransactionLinesAdded {
  TransactionId: number;
  TransactionLinkId: number;
  Amount: number;
  Client: string;
  ClientId: number;
  Date: string;
  InvoiceId: number;
  InvoiceNo: string;
  Matter: string;
  Paid: number;
  Rank: number;
  Reference: string;
  ReferenceMatch: string;
  orderId: number;
}
export const DEFAULT_TRANSACTIONS_LINE_ADDED: TransactionLinesAdded = {
  TransactionId: 0,
  TransactionLinkId: 0,
  Amount: 0,
  Client: '',
  ClientId: 0,
  Date: '',
  InvoiceId: 0,
  InvoiceNo: '',
  Matter: '',
  Paid: 0,
  Rank: 0,
  Reference: '',
  ReferenceMatch: '',
  orderId: 0
};
export const DEFAULT_POP_LINE: POPLine = {
  Amount: 0,
  AssignedToLawFirm: false,
  Id: 0,
  Invoice: '',
  InvoiceId: 0,
  Matter: '',
  Paid: 0,
  Reference: ''
};

export interface UnPaidInvoices {
  InvoiceId: number;
  Date: string;
  InvoiceNo: string;
  Client: string;
  ClientId: number;
  Matter: string;
  Reference: string;
  Amount: number;
  Paid: number;
  ReferenceMatch: string;
}

export interface BankTransaction {
  Id: number;
  Date: string;
  TransactionDescription: string;
  Amount: number;
  Allocated: string;
  Type: string;
  FitId: string;
  CheckNum: string;
  Processed: boolean;
  ProofOfPayment: any;
  BankStatementId: number;
  HasAttachment: boolean;
  BankTransactionLines: any;
  IsDuplicate: boolean;
  IsReferred: boolean;
  ProofOfPaymentId: string;
  AccountNo: string;
  HasProofOfPayment: boolean;
  POPDocumentPath: string;
  Reason: string;
  ExcludedFlag: boolean;
  IsRevised: boolean;
  AdHocCollection: boolean;
  Category: string;
}
export interface ManualBankTransaction {
  Id: number;
  DatePosted: string;
  Amount: number;
  Name: string;
  BankStatementId: number;
  FitId: string;
  CheckNum: string;
  Type: string;
  Processed: boolean;
  Reason: string;
  PersonalFlag: boolean;
  ExcludedFlag: boolean;
  ReferredFlag: boolean;
  DuplicateTransactionId: number;
  PossibleCancelledCheque: boolean;
  HasProofOfPayment: boolean;
  POPDocumentPath: string;
  IsRevised: boolean;
  AdHocCollection: boolean;
  Category: string;
}
export const DEFAULT_BANK_TRANSACTION: BankTransaction = {
  Id: 0,
  Date: '',
  TransactionDescription: '',
  Amount: 0,
  Allocated: '',
  Type: '',
  FitId: '',
  CheckNum: '',
  Processed: false,
  ProofOfPayment: [],
  BankStatementId: 0,
  BankTransactionLines: [],
  IsDuplicate: false,
  IsReferred: false,
  ProofOfPaymentId: '',
  HasAttachment: false,
  AccountNo: '',
  HasProofOfPayment: false,
  POPDocumentPath: '',
  Reason: '',
  ExcludedFlag: false,
  IsRevised: false,
  AdHocCollection: false,
  Category: ''
};

export interface BankStatementSummary {
  LastTransactionDate: string;
  BankName: string;
}

export const DEFAULT_BANK_STATEMENT_SUMMARY: BankStatementSummary = {
  LastTransactionDate: '',
  BankName: ''
};

export interface ProcessedTransactions {
  Allocated: string;
  Amount: number;
  BankStatementId: number;
  BankTransactionLines: BankTransactionLines[];
  CheckNum: string;
  Date: string;
  FitId: string;
  Id: number;
  IsDuplicate: boolean;
  IsReferred: boolean;
  Processed: boolean;
  ProofOfPayment: string;
  ProofOfPaymentId: string;
  TransactionDescription: string;
  Type: string;
  AccountNo: string;
  AdHocCollection: boolean;
  Category: string;
} // end ProcessedTransactions{}

export const DEFAULT_PROCESSEDTRANSACTIONS: ProcessedTransactions = {
  Allocated: '',
  Amount: 0,
  BankStatementId: 0,
  BankTransactionLines: [],
  CheckNum: '',
  Date: '',
  FitId: '',
  Id: 0,
  IsDuplicate: false,
  IsReferred: false,
  Processed: false,
  ProofOfPayment: '',
  ProofOfPaymentId: '',
  TransactionDescription: '',
  Type: '',
  AccountNo: '',
  AdHocCollection: false,
  Category: ''
}; // end DEFAULT_PROCESSEDTRANSACTIONS{}

export interface BankTransactionLines {
  Amount: number;
  AssignedToLawFirm: boolean;
  Id: number;
  Invoice: string;
  InvoiceId: number;
  InvoiceDate: string;
  LawFirm: string;
  LawFirmId: number;
  Matter: string;
  Paid: number;
  Reference: string;
  AdHocCollection: boolean;
} // end BankTransactionLines{}

export const DEFAULT_BANK_TRANSACTIONS: BankTransactionLines = {
  Amount: 0,
  AssignedToLawFirm: false,
  Id: 0,
  Invoice: '',
  InvoiceId: 0,
  InvoiceDate: '',
  LawFirm: '',
  LawFirmId: 0,
  Matter: '',
  Paid: 0,
  Reference: '',
  AdHocCollection: false
}; // end DEFAULT_BANK_TRANSACTIONS{}

export interface SendStatement {
  DaysSinceLast: number;
  EmailAddresses: string;
  IncludeInRun: boolean;
  LastStatementSent: string;
  LawFirm: string;
  LawFirmId: number;
  MessageId: number;
  TotalDue: number;
  AmountAllocatedToFirm: boolean;
  DebitOrder: boolean;
} // end SendStatement

export const DEFAULT_SEND_STATEMENT: SendStatement = {
  DaysSinceLast: 0,
  EmailAddresses: '',
  IncludeInRun: false,
  LastStatementSent: '',
  LawFirm: '',
  LawFirmId: 0,
  MessageId: 0,
  TotalDue: 0,
  AmountAllocatedToFirm: false,
  DebitOrder: false
}; // end DEFAULT_SEND_STATEMENT{}

export interface SendMessageRecipients {
  ID: number;
  MessageID: number;
  Email: string;
  Status: string;
  DateDelivered: string;
}
export const DEFAULT_SEND_MESSAGE_RECIPIENTS: SendMessageRecipients = {
  ID: 0,
  MessageID: 0,
  Email: '',
  Status: '',
  DateDelivered: ''
};

export interface WriteOffTransactionsLines {
  Id: number;
  TransactionDate: string;
  InvoiceType: string;
  Description: string;
  Amount: number;
  ServiceInvoice_Transaction: number;
  Contact_Transaction: number;
}

export const DEFAULT_WRITEOFF_TRANSACTIONS_LINE: WriteOffTransactionsLines = {
  Id: 0,
  TransactionDate: '',
  InvoiceType: '',
  Description: '',
  Amount: 0,
  ServiceInvoice_Transaction: 0,
  Contact_Transaction: 0
};

export interface CreditNote {
  CreditNoteDate: string;
  OriginalInvoiceNo: string;
  OriginalInvoice_Service: number;
  Notes: string;
  Total: number;
  VAT: number;
  TotalExcl: number;
  CreditNoteLines: CreditNoteLines[];

}

export const DEFAULT_CREDIT_NOTE: CreditNote = {
  CreditNoteDate: '',
  OriginalInvoiceNo: '',
  OriginalInvoice_Service: 0,
  Notes: '',
  Total: 0,
  VAT: 0,
  TotalExcl: 0,
  CreditNoteLines: []
};

export interface CreditNoteLines {
  Description: string;
  LineType: string;
  Total: number;
}

export const DEFAULT_CREDIT_NOTE_LINES: CreditNoteLines = {
  Description: '',
  LineType: '',
  Total: 0
}

@Injectable()
export class ProcessPaymentService {

  selectedTab: string;
  showDetails: boolean;
  selectedRowId: number;
  rowCount: number;
  selectedprocessedTab: string;
  constructor(
    private http: HttpClient,
    private api: ApiService,
  ) {

  } // end constructor()


  getProofOfPayment(): Observable<any> {
    return this.http.get(this.api.endpoints.proofOfPayment +
      '?serviceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getLawFirms(): Observable<any> {
    return this.http.get(this.api.endpoints.proofOfPaymentLawFirms +
      '?serviceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  removeProofOfPaymentDocument(data: ProofOfPayment): Observable<any> {
    return this.http.put(this.api.endpoints.proofOfPayment +
      '/RemovePopDoc?ServiceProviderID=' + this.api.serviceProviderID,
      data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end removeProofOfPaymentDocument()

  getMessageRecipients(messageId: number): Observable<any> {
    return this.http.get(this.api.endpoints.sendStatements +
      '/MessageRecipients?ServiceProviderID=' + this.api.serviceProviderID +
      '&MessageID=' + messageId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getMessageRecipients()

  getLastStatementDate(): Observable<any> {
    return this.http.get(this.api.endpoints.sendStatements +
      '/LastStatementDate?ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getLastStatementDate()

  getBankTransactionCount(): Observable<any> {
    return this.http.get(this.api.endpoints.bankTransactions +
      '/BankTransactionCount?ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getBanktransactionCount()

  postProofOfPayment(data: ProofOfPayment): Observable<any> {
    return this.http.post(this.api.endpoints.proofOfPayment +
      '?ContactId=' + this.api.serviceProviderID, data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  postProofDocumentAttachment(pOfPId: number, file: FormData): Observable<any> {
    return this.http.request('POST', this.api.endpoints.proofDocumentAttachment +
      '?ContactId=' + this.api.serviceProviderID + '&ProofOfPaymentId=' + pOfPId, {
      body: file
    });
  }

  deleteProofOfPayment(id: number): Observable<any> {

    return this.http.delete(this.api.endpoints.proofOfPayment +
      '?ProofOfPaymentId=' + id, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  deleteRecipient(id: number): Observable<any> {
    return this.http.delete(this.api.endpoints.sendStatements +
      '/DeleteMessageRecipient?ServiceProviderID=' + this.api.serviceProviderID +
      '&recipientID=' + id, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end deleteRecipient()

  putRecipient(recipient: SendMessageRecipients): Observable<any> {
    return this.http.put(this.api.endpoints.sendStatements +
      '/UpdateRecipient?ServiceProviderID=' + this.api.serviceProviderID,
      recipient, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }


  getUnPaidInvoicesByClient(clientId: number): Observable<any> {
    return this.http.get(this.api.endpoints.unPaidInvoicesByClientProofOfPayment +
      '?serviceProviderId=' + this.api.serviceProviderID + '&ClientId=' + clientId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  postProofOfPaymentLines(popId: number, clientId: number, data: any): Observable<any> {
    return this.http.post(this.api.endpoints.postProofOfPaymentLines +
      '?ContactId=' + clientId + '&ProofOfPaymentId=' + popId, data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getLinesByPopId(popId: number): Observable<any> {
    return this.http.get(this.api.endpoints.linesByPopIdProofOfPayment +
      '?serviceProviderId=' + this.api.serviceProviderID + '&ProofOfPaymentId=' + popId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  putProofOfPayment(data: any): Observable<any> {
    return this.http.put(this.api.endpoints.proofOfPayment +
      '?ContactId=' + this.api.serviceProviderID, data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  deleteRemoveProofOfPaymentLines(data: any): Observable<any> {
    return this.http.request('delete', this.api.endpoints.removeProofOfPaymentLines,
      { headers: this.api.httpOptions.headers, body: data }).pipe(
        catchError(this.api.handleError)
      );
  }




  postUploadBankStatement(file: FormData, excludeDebit: boolean): Observable<any> {
    return this.http.request('POST', this.api.endpoints.uploadBankStatement +
      '?ServiceProviderId=' + this.api.serviceProviderID + '&excludeDebit=' + excludeDebit, {
      body: file
    });
  } // end postUploadBankStatement()

  postUploadBankStatementNoDuplicate(file: FormData, excludeDebit: boolean): Observable<any> {
    return this.http.request('POST', this.api.endpoints.uploadBankStatementNoDuplicate +
      '?ServiceProviderId=' + this.api.serviceProviderID + '&excludeDebit=' + excludeDebit, {
      body: file
    });
  } // end postUploadBankStatement()

  postUploadBankStatementWithDate(file: FormData, excludeDebit: boolean, fromDate: any, toDate: any): Observable<any> {
    return this.http.request('POST', this.api.endpoints.uploadBankStatementWithDate +
      '?ServiceProviderId=' + this.api.serviceProviderID + '&excludeDebit=' + excludeDebit
      + '&statementFromParameters=' + fromDate
      + '&statementToParameters=' + toDate,
      {
        body: file
      });
  } // end postUploadBankStatementWithDate()

  getBankStatementSummary(): Observable<any> {
    return this.http.get(this.api.endpoints.bankStatementSummary +
      '?ServiceProviderID=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getBankStatementSummary()

  getBankTransactions(data: any): Observable<any> {

    return this.http.get(this.api.endpoints.bankTransactions +
      '?searchString=' + data + '&ServiceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  putExcludeBankTransaction(reason: any, data: any): Observable<any> {
    return this.http.put(this.api.endpoints.excludeBankTransaction +
      '?Reason=' + reason, data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  putPersonalBankTransaction(data: any): Observable<any> {
    return this.http.put(this.api.endpoints.personalBankTransaction, data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }
  postManualTransactions(BankTransactions: any): Observable<any> {
    return this.http.post(this.api.endpoints.bankTransactions +
    '/PostManualTransactions?ContactId=' + this.api.serviceProviderID, BankTransactions, this.api.httpOptions)
    .pipe(catchError(this.api.handleError)
    );
  } // end postmanualTransactions()

  putManualTransactions(BankTransactions: any): Observable<any> {
    console.log('TRANSACTIONS TO BE POSTED: ', BankTransactions);    
    return this.http.put(this.api.endpoints.bankTransactions +
    '/PutManualTransactions?ContactId=' + this.api.serviceProviderID, BankTransactions, this.api.httpOptions)
    .pipe(catchError(this.api.handleError)
    );
  } // end postmanualTransactions()

  putLawFirmBankTransaction(lawFirmId: number, data: any): Observable<any> {
    return this.http.put(this.api.endpoints.lawFirmBankTransaction +
      '?LawFirmId=' + lawFirmId, data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  putReferToClient(comment: any, data: any): Observable<any> {
    return this.http.put(this.api.endpoints.referToClient +
      '?Comments=' + comment, data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  deleteBankTransactions(id: number): Observable<any> {
    return this.http.delete(this.api.endpoints.bankTransactions +
      '?BankTransactionId=' + id, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getPossibleMatches(bankId: number): Observable<any> {
    return this.http.get(this.api.endpoints.possibleMatchesBankTransaction +
      '?ServiceProviderId=' + this.api.serviceProviderID + '&BankTransactionId=' + bankId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getPersonalBankTransactions(pageIndex: Number, takeRecords: Number, searchTerm: string): Observable<any> {
    return this.http.get(this.api.endpoints.processedBankTransactions +
      '/PersonalBankTransactions?ServiceProviderID=' + this.api.serviceProviderID + '&pageIndex=' + pageIndex + '&takeRecords=' + takeRecords + '&searchTerm=' + searchTerm, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getPersonalBankTransactions()

  getExcludedBankTransactions(pageIndex: Number, takeRecords: Number, searchTerm: string): Observable<any> {
    return this.http.get(this.api.endpoints.processedBankTransactions +
      '/ExcludedBankTransactions?ServiceProviderID=' + this.api.serviceProviderID + '&pageIndex=' + pageIndex + '&takeRecords=' + takeRecords + '&searchTerm=' + searchTerm, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getExcludedBankTransactions()

  getReferredBankTransactions(pageIndex: Number, takeRecords: Number, searchTerm: string): Observable<any> {
    return this.http.get(this.api.endpoints.processedBankTransactions +
      '/ReferredBankTransactions?ServiceProviderID=' + this.api.serviceProviderID + '&pageIndex=' + pageIndex + '&takeRecords=' + takeRecords + '&searchTerm=' + searchTerm, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getReferredBankTransactions()

  putMultipleBankTransactions(data: any): Observable<any> {
    return this.http.put(this.api.endpoints.bankTransactions + '/PutMultipleTransactions'
      , data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }
  putBankTransactions(data: any): Observable<any> {
    return this.http.put(this.api.endpoints.bankTransactions, data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  putWriteOffBankTransactions(data: any): Observable<any> {
    data.Contact_Transaction = this.api.serviceProviderID;
    return this.http.put(this.api.endpoints.bankTransactions + '/PutTransactions', data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  putCreditNote(data: any): Observable<any> {
    return this.http.put(this.api.endpoints.bankTransactions + '/PutCreditNote', data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getProcessedBankTransactions(pageIndex: Number, takeRecords: Number, searchTerm: string): Observable<any> {
    return this.http.get(this.api.endpoints.processedBankTransactions +
      '?ServiceProviderId=' + this.api.serviceProviderID + '&pageIndex=' + pageIndex + '&takeRecords=' + takeRecords + '&searchTerm=' + searchTerm, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  deleteProcessedBankTransactions(id: number): Observable<any> {
    return this.http.delete(this.api.endpoints.removeFromProcessed +
      '?BankTransactionId=' + id, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  removeTransactionLines(id: number): Observable<any> {
    return this.http.delete(this.api.endpoints.bankTransactions +
      '/RemoveTransactionLines?transactionLinkID=' + id, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end removeTransactionLines()

  deleteTransactionLines(id: number): Observable<any> {
    return this.http.delete(this.api.endpoints.bankTransactions +
      '/DeleteTransactionLines?transactionLinkID=' + id, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end removeTransactionLines()

  deleteWriteOffLines(id: number): Observable<any> {
    return this.http.delete(this.api.endpoints.bankTransactions +
      '/RemoveWriteOffLine?invoiceId=' + id, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end deleteWriteOffLines()

  getSendStatements(): Observable<any> {
    return this.http.get(this.api.endpoints.sendStatements + '?ServiceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  putSendStatements(data: any): Observable<any> {
    return this.http.put(this.api.endpoints.sendStatements + '?ServiceProviderId=' + this.api.serviceProviderID, data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  UpdateSageTransactions(): Observable<any> {
    return this.http.put(this.api.endpoints.sendStatements + '/UpdateSageTransactions?ServiceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end UpdateSageTransactions()

  putSendStatementRecipient(data: any): Observable<any> {
    return this.http.put(this.api.endpoints.sendStatements +
      '/UpdateSendRecipients?ServiceProviderID=' + this.api.serviceProviderID,
      data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

} // end ProcessPaymentService{}
