<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">

<div class="badge" *ngIf="type === types.LAW_FIRMS">
    <input required *ngIf="type === types.LAW_FIRMS" [(ngModel)]="matter.InternalRefNo" [typeahead]="lawfirmList"
        typeaheadOptionField="FullDescription" [typeaheadSingleWords]="false" (input)="onInput($event)"
        class="categotyText" style="width: 90% !important;" placeholder="Select or start typing"
        (typeaheadOnSelect)="onSelect($event)" [typeaheadOptionsLimit]="100" typeaheadScrollable=true
        (blur)="onLawfirmChange()" typeaheadOptionsInScrollableView="20" [typeaheadMinLength]="0">
    <i *ngIf="canAddLawfirm" title="Add New Law Firm" class="fa fa-plus-circle fa-lg"
        (click)="addNewLawfirmAttorney('law-firms')"></i>

</div>
<div class="badge" *ngIf="type === types.ATTORNEYS">
    <input *ngIf="type === types.ATTORNEYS" [(ngModel)]="matter.NewClientDetail" [typeahead]="attorneyList"
        typeaheadOptionField="FullName" (input)="onInput($event)" class="form-control" style="width: 90% !important;"
        (blur)="onLawfirmChange()" (focus)="onFocus()" placeholder="Select or start typing"
        (typeaheadOnSelect)="onSelect($event)" [typeaheadOptionsLimit]="50" typeaheadScrollable=true
        typeaheadOptionsInScrollableView="20" [typeaheadMinLength]="0">
    <i *ngIf="canAddAttorney || matter.NewClientDetail === ''" title="Add New Attorney" class="fa fa-plus-circle fa-lg"
        (click)="addNewLawfirmAttorney('attorneys')"></i>

</div>
<div class="badge" *ngIf="type === types.OTHER_CLIENTS">
    <input required *ngIf="type === types.OTHER_CLIENTS" [(ngModel)]="matter.InternalRefNo" [typeahead]="other_clients"
        typeaheadOptionField="FullName" [typeaheadSingleWords]="false" (input)="onInput($event)" class="categotyText"
        style="width: 90% !important;" placeholder="Select or start typing" (typeaheadOnSelect)="onSelect($event)"
        [typeaheadOptionsLimit]="100" typeaheadScrollable=true (blur)="onLawfirmChange()"
        typeaheadOptionsInScrollableView="20" [typeaheadMinLength]="0">
    <i *ngIf="canAddLawfirm" title="Add New CLient" class="fa fa-plus-circle fa-lg"
        (click)="addNewLawfirmAttorney('other-clients')"></i>

</div>

<app-new-law-firm-modal [isVisible]="showNewLawFirmModal" [showLawFirmTab]="showLawfirmTab"(closeModal)="onCloseModal($event)"></app-new-law-firm-modal>
<app-new-attorney-modal [isVisible]="showNewAttorneyModal" (closeModal)="onCloseModal($event)"></app-new-attorney-modal>
<app-add-new-client [isVisible]="showAddClientModal" [(clients)]="otherClient" (closeModal)="onCloseModal($event)"
    (saveClients)="onSaveOtherClients($event)"></app-add-new-client>
<app-alert title="PLEASE SEARCH AND SELECT LAW FIRM"
    message="Law Firm must be selected from predefined list. Could not find Law Firm? Select '(+)' to request to add a new Law Firm."
    [isVisible]="showLawfirmErrorPrompt" (cancel)="onHideLawfirmErrorPrompt()"
    (confirm)="onHideLawfirmErrorPrompt()"></app-alert>
<app-alert title="PLEASE SEARCH AND SELECT A CLIENT"
    message="Client must be selected from predefined list. Could not find a Client? Select '(+)' to add a new Client."
    [isVisible]="showClientErrorPrompt" (cancel)="onHideClientErrorPrompt()"
    (confirm)="onHideClientErrorPrompt()"></app-alert>
<app-alert title="PLEASE SEARCH AND SELECT ATTORNEY"
    message="Attorney must be selected from predefined list. Could not find Attorney? Select '(+)' to request to add a new Attorney."
    [isVisible]="showAttorneyErrorPrompt" (cancel)="onHideAttorneyErrorPrompt()"
    (confirm)="onHideAttorneyErrorPrompt()"></app-alert>

<app-prompt-save-contact-modal [title]="promptTitle" [message]="message" [isVisible]="showSaveContactModelPrompt"
    (cancel)="onHideSaveContactModelPrompt()"></app-prompt-save-contact-modal>