import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { LPCStatusDrpList, LPCStatusHandler, ManageBlackListService } from '../../../services/manage-BlackList/manage-BlackList.service';
import { LENGTH_LONG, Snack, SnackType, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-lpc-blacklist-modal',
  templateUrl: './edit-lpc-blacklist-modal.component.html',
  styleUrls: ['./edit-lpc-blacklist-modal.component.scss']
})
export class EditLpcBlacklistModalComponent implements OnInit {

  bsValue = new Date();
  @Input() isVisible: boolean;
  @Input() showAdvocateListTab: boolean;
  @Input() currentLawfirmID: number;
  @Input() CurrentReportingAdvocateName: number;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  lPCStatusDrp: any[];
  LPCStatusHandler: LPCStatusHandler[] = [];
  LPCStatus_id: any;
  LPCStatusID: number;
  LPCReferenceNo: string;
  DueDate: string;
  Notes: string;
  currentLawfirmId: number;
  lpcId: number;
  filterLPCstatus: any;

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private manageBlackListService: ManageBlackListService,
  ) { }

  ngOnInit(): void {
    this.getLPCStatus();
  }
  ngOnChanges() {
    this.lpcId = this.currentLawfirmID;
    this.CurrentReportingAdvocateName;
    if (this.lpcId) {
      this.getEditLPCDetails()
    }
  }

  getLPCStatus() {
    this.loadingService.showOverlay();
    this.lPCStatusDrp = [];
    this.manageBlackListService.getLPCStatusList().subscribe({
      next: (response) => {
        if (response) {
          this.lPCStatusDrp = response;
          this.lPCStatusDrp.forEach(item => {
            this.LPCStatusHandler.push({
              data: item,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          });
        }
      }
    })
  }

  getEditLPCDetails() {
    this.loadingService.showOverlay();
    if (this.lpcId) {
      this.manageBlackListService.getEditLPCDetails(this.lpcId).subscribe({
        next: (response) => {
          if (response) {
            this.LPCStatus_id = response.LPCStatusID;
            this.LPCReferenceNo = response.LPCReferenceNo;
            this.DueDate = response.DueDate;
            this.Notes = response.Notes;
          }
          this.loadingService.hideOverlay();
        },
      })
    }
  }

  updateLPCdetails(event) {
    this.loadingService.showOverlay();
    this.DueDate = this.DueDate ? moment(this.DueDate).format('YYYY-MM-DD HH:mm:ss') : null;
    const requestData = {
      currentLawfirmID: this.currentLawfirmID,
      LPCStatusID: this.LPCStatus_id == "null" ? 0 : Number(this.LPCStatus_id),
      LPCReferenceNo: this.LPCReferenceNo,
      DueDate: this.DueDate !== undefined && this.DueDate !== "undefined" ? this.DueDate : null,
      Notes: this.Notes
    };
    this.manageBlackListService.updateLPCdetails(requestData)
      .subscribe({
        next: (response) => {
        },
        error: (error) => {
          const snack: Snack = {
            label: error,
            type: SnackType.ERROR,
            action: null
          };
          this.snackbarsService.dismiss().make(snack).show();
        },
        complete: () => {
          const snack: Snack = {
            label: 'Updated recored successfully. ',
            type: SnackType.SUCCESS,
            action: null
          };
          this.snackbarsService.dismiss().make(snack).show();
          this.dismiss(true);
        }
      })
    this.loadingService.hideOverlay();
  }

  onSelectLPCstatus(event) {
    this.LPCStatusID = event.target.value;
  }

  dismiss(refreshGrid: boolean) {
    this.loadingService.showOverlay();
    this.closeModal.emit(refreshGrid);
  }

}
