<app-breadcrumb
    [crumbs]="crumbs"></app-breadcrumb>

    <div class="container-fluid">

        <div class="well">
            <h2 class="well-title">Advocate reports</h2>
            <div class="well-row">
                <button
                    class="btn btn-success btn-lg"
                    (click)="openReport('advocate-statement')">Advocate statements</button>
            </div> <!-- #end .well-row -->
        </div> <!-- #end .well -->

        <div class="well">
            <h2 class="well-title">Report group</h2>
            <div class="well-row">
                <button
                    class="btn btn-success btn-lg">Report name</button>
            </div> <!-- #end .well-row -->
                    
            <div class="well-row">
                <button
                    class="btn btn-success btn-lg">Report name</button>
            </div> <!-- #end .well-row -->

        </div> <!-- #end .well -->

        <div class="well">
            <h2 class="well-title">Report group</h2>

            <div class="well-row">
                <button
                    class="btn btn-success btn-lg">Report name</button>
            </div> <!-- #end .well-row -->
            
            <div class="well-row">
                <button 
                class="btn btn-success btn-lg"
                (click)="openReport('advocate-statement')">Report name</button>
            </div> <!-- #end .well-row -->
        </div> <!-- #end .well -->
            
    </div> <!-- #end .container-fuild -->