<div class="row">
  <!-- overview -->
  <!-- New Dashboard -->
  <app-dashboard-overview *ngIf="displayDashboard"></app-dashboard-overview>
  <!-- Current Matters -->
  
  <!-- <app-charts-summary
    *ngIf="displayDashboard"
    summaryTitle="Current Matters"
    summaryDescription="Work in progress"
    [dataMonthly]="currentMattersData"
    [dataYearly]="currentMattersYearlyData"></app-charts-summary> -->
    <div style="height: 15px;" *ngIf="!displayDashboard"></div>

  <!-- Invoiced -->
  <app-invoiced-chart *ngIf="displayDashboard" summaryTitle="Invoiced" summaryDescription="Invoiced matters"
    [dataMonthly]="invoicedData" [dataYearly]="invoicedYearlyData"></app-invoiced-chart>
  <!-- <app-chart-summary
    previousMonthsCanvasId="canvas-monthly-invoiced"
    previousYearsCanvasId="canvas-yearly-invoiced"
    summaryTitle="invoiced"
    [data]="invoicedData"></app-chart-summary> -->

  <!-- Collected -->
  <app-receipt-chart *ngIf="displayDashboard" summaryTitle="Collected" summaryDescription="Collected matters"
    [dataMonthly]="collectedData" [dataYearly]="collectedYearlyData"></app-receipt-chart>
  <!-- <app-chart-summary
    previousMonthsCanvasId="canvas-monthly-collected"
    previousYearsCanvasId="canvas-yearly-collected"
    summaryTitle="collected"
    [data]="collectedData"></app-chart-summary> -->
</div>
<!--#end .row -->

<!-- Briefs and Matters -->
<app-briefs-and-matters-table></app-briefs-and-matters-table>

<app-upload-historical-data-modal
  *ngIf="onShowImportHistoricalDataModal"
  [userProfile]="userProfile"
  (onCloseModal)="onCloseImportHistoricalDataModal()"
  (isImported)="onHistoricalDataImported($event)"></app-upload-historical-data-modal>