import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, Input, ViewChild } from '@angular/core';
import { jqxChartComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { months } from 'moment';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-invoiced-chart',
  templateUrl: './invoiced-chart.component.html',
  styleUrls: ['./invoiced-chart.component.scss']
})
export class InvoicedChartComponent implements AfterViewInit, OnChanges {
  @Input() previousMonthsCanvasId: string;
  @Input() previousYearsCanvasId: string;
  @Input() summaryTitle: string;
  @Input() summaryDescription: string;
  @Input() dataMonthly: any;
  @Input() dataYearly: any;

  @ViewChild('myChart') myChart: jqxChartComponent;
  @ViewChild('myChartYearly') myChartYearly: jqxChartComponent;

  activeTab = 'monthly';
  previousMonthsChart: any = [];
  previousYearsChart: any = [];

  invoicedMonthlyTarget = 'R ';
  invoicedYearlyRecord = 'R ';

  invoicedYearlyTarget = 'R ';
  invoicedMonthlyRecord = 'R ';

  //#region Monthly Chart setup
  // Chart data
  invoicedData: any = [
    { Invoiced: 'Nov', INVOICED: 0 },
    { Invoiced: 'Oct', INVOICED: 0 },
    { Invoiced: 'Sep', INVOICED: 0 },
  ]; // end invoicedData:

  redchart = 100;
  yellowchart = 200;
  greenchart = 300;

  padding: any = { left: 20, top: 5, right: 20, bottom: 5 };

  xAxis: any = {
    dataField: 'Invoiced',
    gridLines: { visible: false },
    flip: false,
    valuesOnTicks: false,
    labels: {
      angle: 0,
      formatFunction: (value: any) => {
        return value.toString();
      }
    },
    tickMarks: {
      visible: true,
      interval: 1,
      color: '#CACACA'
    }
  }; // end xAxis:

  valueAxis: any = {
    flip: true,
    labels: {
      visible: true,
      formatFunction: (value: number) => {
        return value / 1000000;
      }
    }
  }; // end valueAxis:

  seriesGroups: any[] = [{
    orientation: 'horizontal',
    type: 'column',
    useGradient: false,
    columnsGapPercent: 10,
    columnsMaxWidth: 30,
    toolTipFormatSettings: { thousandsSeparator: ',' },
    valueAxis:
    {
      flip: true,
      textRotation: -30,
      minValue: 0,
      maxValue: 300,
      unitInterval: 30,
      tickMarks: { color: '#BCBCBC' }
    },
    series: [
      {
        dataField: 'INVOICED',
        displayText: 'INVOICED (k)',
        labels: {
          visible: true,
        },
        colorFunction: (value: any, itemIndex: any, serie: any, group: any): any => {
          return (value <= this.redchart) ? '#d57f70' : (value <= this.yellowchart) ? '#dcb967' : '#99ba62';
        }
      }
    ],
    bands:
      [
        { minValue: 0, maxValue: 100, color: '#d57f70', opacity: 0.30, showLabel: true },
        { minValue: 100, maxValue: 200, color: '#dcb967', opacity: 0.30 },
        { minValue: 200, maxValue: 300, color: '#99ba62', opacity: 0.30 }
      ]
  }]; // end seriesGroups[]
  //#endregion Monthly Chart setup

  //#region Yearly Chart setup
  // Chart data yearly
  invoicedDataYearly: any = [
    { Invoiced: 'Nov', INVOICED: 0 },
    { Invoiced: 'Oct', INVOICED: 0 },
    { Invoiced: 'August', INVOICED: 0 },
  ]; // end invoicedData:

  redchartYearly = 100;
  yellowchartYearly = 200;
  greenchartYearly = 300;

  xAxisYearly: any = {
    dataField: 'Invoiced',
    gridLines: { visible: false },
    flip: true,
    valuesOnTicks: false,
    labels: {
      angle: 0,
      formatFunction: (value: any) => {
        return value.toString();
      }
    },
    tickMarks: {
      visible: true,
      interval: 1,
      color: '#CACACA'
    }
  }; // end xAxis:

  valueAxisYearly: any = {
    flip: true,
    labels: {
      visible: true,
      formatFunction: (value: number) => {
        return value / 1000000;
      }
    }
  }; // end valueAxis:

  seriesGroupsYearly: any[] = [{
    orientation: 'horizontal',
    type: 'column',
    useGradient: false,
    columnsGapPercent: 10,
    columnsMaxWidth: 30,
    toolTipFormatSettings: { thousandsSeparator: ',' },
    valueAxis:
    {
      flip: true,
      textRotation: -30,
      minValue: 0,
      maxValue: 300,
      unitInterval: 30,
      tickMarks: { color: '#BCBCBC' }
    },
    series: [
      {
        dataField: 'INVOICED',
        displayText: 'INVOICED (k)',
        labels: {
          visible: true,
        },
        colorFunction: (value: any, itemIndex: any, serie: any, group: any): any => {
          return (value <= this.redchart) ? '#d57f70' : (value <= this.yellowchart) ? '#dcb967' : '#99ba62';
        }
      }
    ],
    bands: [
      { minValue: 0, maxValue: 100, color: '#d57f70', opacity: 0.30, showLabel: true },
      { minValue: 100, maxValue: 200, color: '#dcb967', opacity: 0.30 },
      { minValue: 200, maxValue: 300, color: '#99ba62', opacity: 0.30 }
    ]
  }]; // end seriesGroups[]

  //#endregion Yearly Chart setup

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private userProfileService: UserProfileService
  ) { } // end constructor()

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataMonthly && this.dataMonthly.monthly.invoicedMaxValue > 0) {
        this.getInvoicedMatterData(this.dataMonthly.monthly);
      }
    if (this.dataYearly && this.dataYearly.yearly.thisYearInvoicedMaxValue > 0) {
        this.getInvoicedMatterDataYearly(this.dataYearly.yearly);
      }
  } // end ngOnChanges()

  ngAfterViewInit() {
  } // end ngAfterViewInit()

  getInvoicedMatterData(monthly: any) {

    if (this.dataMonthly) {
      // Monthly Target & Monthly record
      this.invoicedMonthlyTarget = monthly.invoicedMonthlyTarget;
      this.invoicedMonthlyRecord = monthly.invoicedMonthlyRecord;

      this.redchart = monthly.invoicedRangeStart;
      this.yellowchart = monthly.invoicedRangeEnd;
      this.greenchart = monthly.invoicedMaxValue;

      this.invoicedData = [
        {
          Invoiced: monthly.invoicedThismonthLabel,
          INVOICED: monthly.invoicedThismonthValue / 1000
        },
        {
          Invoiced: monthly.invoicedLastMonthLabel,
          INVOICED: monthly.invoicedLastMonthValue / 1000
        },
        {
          Invoiced: monthly.invoicedMonthBeforeLast,
          INVOICED: monthly.invoicedMonthBeforValue / 1000
        },
      ];

      this.seriesGroups =
        [
          {
            orientation: 'horizontal',
            type: 'column',
            useGradient: false,
            columnsGapPercent: 10,
            columnsMaxWidth: 30,
            toolTipFormatSettings: { thousandsSeparator: ',' },
            valueAxis:
            {
              flip: true,
              textRotationAngle: -30,
              minValue: 0,
              maxValue: monthly.invoicedMaxValue,
              unitInterval: monthly.invoicedMaxValue / 10,
              tickMarks: { color: '#e6e6e6' },
              formatSettings: { sufix: 'k' }
            },
            series: [
              {
                dataField: 'INVOICED',
                displayText: 'Invoiced (K)',
                labels: {
                  visible: true,
                },
                formatFunction: (value: any) => {
                  return this.currencySymbol + new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 }).format(value * 1000);
                },
                colorFunction: (value: any, itemIndex: any, serie: any, group: any): any => {
                  return (value <= this.redchart) ? '#d57f70' : (value <= this.yellowchart) ? '#dcb967' : '#99ba62';
                }
              }
            ],
            bands:
              [
                { minValue: 0, maxValue: monthly.invoicedRangeStart, color: '#d57f70', opacity: 0.30, showLabel: true },
                {
                  minValue: monthly.invoicedRangeStart,
                  maxValue: monthly.invoicedRangeEnd, color: '#dcb967', opacity: 0.30
                },
                {
                  minValue: monthly.invoicedRangeEnd,
                  maxValue: monthly.invoicedMaxValue, color: '#99ba62', opacity: 0.30
                }
              ]
          }
        ]; // end seriesGroups[]
    }
  } // end getInvoicedMatterData()

  getInvoicedMatterDataYearly(yearly: any) {

    if (this.dataYearly) {
      // Monthly Target & Monthly record
      this.invoicedYearlyTarget = yearly.InvoicedYearlyTarget;
      this.invoicedYearlyRecord = yearly.InvoicedYearlyRecord;

      this.redchartYearly = yearly.thisYearInvoicedRangeStart;
      this.yellowchartYearly = yearly.thisYearInvoicedRangeEnd;
      this.greenchartYearly = yearly.thisYearInvoicedMaxValue;

      this.invoicedDataYearly = [
        {
          Invoiced: yearly.thisYearInvoicedLabel,
          INVOICED: yearly.thisYearInvoicedTotalValue / 1000
        },
        {
          Invoiced: yearly.lastYearInvoicedLabel,
          INVOICED: yearly.lastYearInvoicedValue / 1000
        },
        {
          Invoiced: yearly.last2YearsInvoicedLabel,
          INVOICED: yearly.last2YearsInvoicedValue / 1000
        },
      ];

      this.seriesGroupsYearly =
        [
          {
            orientation: 'horizontal',
            type: 'column',
            useGradient: false,
            columnsGapPercent: 10,
            columnsMaxWidth: 30,
            toolTipFormatSettings: { thousandsSeparator: ',' },
            valueAxis:
            {
              flip: true,
              textRotationAngle: -30,
              minValue: 0,
              maxValue: yearly.thisYearInvoicedMaxValue,
              unitInterval: yearly.thisYearInvoicedMaxValue / 10,
              tickMarks: { color: '#e6e6e6' },
              formatSettings: { sufix: 'k' }
            },
            series: [
              {
                dataField: 'INVOICED',
                displayText: 'Invoiced (K)',
                labels: {
                  visible: true,
                },
                formatFunction: (value: any) => {
                  return this.currencySymbol + new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 }).format(value * 1000);
                },
                colorFunction: (value: any, itemIndex: any, serie: any, group: any): any => {
                  return (value <= this.redchartYearly) ? '#d57f70' : (value <= this.yellowchartYearly) ? '#dcb967' : '#99ba62';
                }
              }
            ],
            bands:
              [
                { minValue: 0, maxValue: yearly.thisYearInvoicedRangeStart, color: '#d57f70', opacity: 0.30 },
                {
                  minValue: yearly.thisYearInvoicedRangeStart,
                  maxValue: yearly.thisYearInvoicedRangeEnd, color: '#dcb967', opacity: 0.30
                },
                {
                  minValue: yearly.thisYearInvoicedRangeEnd,
                  maxValue: yearly.thisYearInvoicedMaxValue, color: '#99ba62', opacity: 0.30
                }
              ]
          }
        ]; // end seriesGroupsYearly[]
    }
  } // end getInvoicedMatterDataYearly()

  onTabClick(tab: string) {
    this.activeTab = tab;
    setTimeout(() => {
      if (this.dataYearly && this.dataYearly.yearly.thisYearInvoicedMaxValue > 0) {
        this.getInvoicedMatterDataYearly(this.dataYearly.yearly);
      }
    }, 100);
  } // end onTabClick()

}
