import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import {
  AddLawfirmAttorneyService,
  AddLawfirm, AddLawfirmAdvocate
} from '../../../../services/addlawfirm/add-lawfirm-attorney.service';

import {
  ToastService, LENGTH_LONG
} from '../../../../services/messaging/toast/toast.service';

import {
  SnackbarsService,
  Snack,
  SnackType
} from '../../../../services/messaging/snackbars/snackbars.service';

import {
  TAG_ADD_NEW_LAW_FIRM_MODAL
} from '../../../../services/tags/tags.service';
import { LoadingService } from '../../../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../../../services/utilities.service';
import { UserProfileService } from '../../../../services/user-profile/user-profile.service';
import * as moment from 'moment';
import { DEFAULT_ACTIVITY_LOGS, ActivityLogs, ActivityLogsService } from '../../../../services/activity-logs/activity-logs.service';
import { Console } from 'console';

const MIN_TEXTLENGTH = 4;
const MAX_TEXT_LENGTH = 100;
@Component({
  selector: 'app-new-law-firm-modal',
  templateUrl: './new-law-firm-modal.component.html',
  styleUrls: ['./new-law-firm-modal.component.scss']
})
export class NewLawFirmModalComponent implements OnInit, OnChanges {

  @Input() isVisible: boolean;
  @Input() showLawFirmTab: boolean;
  @Output() closeModal: EventEmitter<string> = new EventEmitter();

  title = 'New law firm request';
  addLawfirm: AddLawfirm = {
    LawfirmName: '',
    AttorneyName: '',
    ContactNumber: '',
    EmailAddress: '',      
  };

  firmName = '';
  attorney = '';
  email = '';
  phoneNumber = '';
  regionalBar = '';
  admissionDate : Date;
  admissionDateStr = '';
  defaultModel = {
    attorney: '',
    email: '',
    firmName: '',
    phoneNumber: '',
    // cell = '',
    // regionalBar = '',
    // admissionDate = ''
  };

  showUnsavedChangesPrompt: boolean;
  tabView: string;
  isValidLawFirmName = false;
  isValidAdvocateName = false;
  isValidPhoneNumber = false;
  isValidAdvocatePhoneNumber = false;
  isValidEmail = false;
  isValidAdvocateEmail = false;

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.defaultModel, {
      attorney: this.attorney,
      email: this.email,
      firmName: this.firmName,
      phoneNumber: this.phoneNumber
    });
  } // end isDirty()

  get hasContactEmailError(): boolean {
    return !this.util.isValidEmail(this.email);
  } // end hasContactEmailError()

  get hasCellNumberError(): boolean {
    return !this.util.isValidPhoneNumber(this.phoneNumber,
      { code: this.userProfileService.userProfile.currencyDetails.code });
  } // end hasCellNumberError()

  get hasAttorneyNameError(): boolean {
    return !this.util.hasTextValue(this.attorney);
  } // end hasAttorneyNamerError()

  get hasFirmNameError(): boolean {
    return !this.util.hasTextValue(this.firmName);
  } // end hasFirmNameError()

  get isSubmittable(): Boolean {
      return Boolean(!this.hasFirmNameError) && Boolean(!this.hasCellNumberError) &&
      Boolean(!this.hasContactEmailError)          
  }

  constructor(
    private util: UtilitiesService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private addLawfirmAttorneyService: AddLawfirmAttorneyService,
  ) {
    this.tabView = 'add-law-firm';    
  } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  attorneyIsValid(): boolean {
    let isValid = true;
    if (!this.attorney) {
      isValid = false;
    }
    return isValid;
  } // end attorneyIsValid()

  ngOnChanges(): void {
    if (!this.showLawFirmTab) {
      this.tabView = 'add-advocate';
      this.title = 'New Advocate request';
    }
  }
  phoneNumberIsValid(): boolean {
    let isValid = true;
    if (!this.phoneNumber) {
      isValid = false;
    }
    return isValid;
  } // end phoneNumberIsValid()

  emailIsValid(): boolean {
    let isValid = true;
    if (!this.email) {
      isValid = false;
    }
    return isValid;
  } // end emailIsValid()

  submitRequest() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Submitting Attorney / Law Firm request...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, LENGTH_LONG).show();
    this.addLawfirm = {
      LawfirmName: this.firmName,
      AttorneyName: this.attorney,
      ContactNumber: this.phoneNumber,
      EmailAddress: this.email
    };

    if (this.tabView === 'add-advocate') {
      this.addLawfirm.LawfirmName = this.firmName + ' - (Advocate)';
    }

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'New law firm request';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (new-attorey-modal)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Invoice';
    this.activityLog.JsonData = JSON.stringify(this.addLawfirm);
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    this.addLawfirmAttorneyService
    .addLawfirmAttorney(this.addLawfirm)
    .subscribe({
      next: (newAttorney) => {
      // On next
    },
    error: (error) => {
      // On error
      snack.label = 'Something went wrong, please contact auxcon for your request';
      snack.action = null;
      snack.type = SnackType.ERROR;
      this.loadingService.hideOverlay();
      this.snackbarsService.dismiss().make(snack).show();
      console.error('Something went wrong: ', error);
    },
   complete: () => {
      // On complete
      snack.label = 'Attorney / law firm will be added upon receipt of your request. ';
      this.clearInputs();
      this.loadingService.hideOverlay();
      this.snackbarsService.dismiss().make(snack).show();
    }
  });
    this.dismiss();
  } // end submitRequest()

  onDismiss() {
    
    if (this.isDirty) {
      this.showUnsavedChangesPrompt = true;
      console.log('IS DIRTY? ', this.isDirty);
    } else {
      this.dismiss();
    }
  } // end onDismiss()

  dismiss() {
    this.clearInputs();
    this.closeModal.emit(TAG_ADD_NEW_LAW_FIRM_MODAL);
  } // end submitRequest()

  clearInputs() {
    this.firmName = '';
    this.attorney = '';
    this.phoneNumber = '';
    this.email = '';
    this.regionalBar = '';
    this.admissionDate = null;
    this.isValidLawFirmName = false;
    this.isValidAdvocateName = false;
    this.isValidEmail = false;
    this.isValidAdvocateEmail = false;
    this.isValidPhoneNumber = false;
    this.isValidAdvocatePhoneNumber = false;
  } // end clearInputs()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end   onHideUnsavedChangesPrompt()

  onDismissPromptUnsavedChangesModal() {
    this.onHideUnsavedChangesPrompt();
    this.dismiss();
  } // end   onDismissPromptUnsavedChangesModal()

  changeTabView(selectedTab: string) {
    this.clearInputs();
    this.tabView = selectedTab;
    if (this.tabView === 'add-law-firm') {
      this.title = 'New Law firm request';
    } else if (this.tabView === 'add-advocate') {
      this.title = 'New Advocate Request';
    }
  } // end changeTabView()

  onLawFirmNameBlur() {
    if (!this.util.hasTextValue(this.firmName)) {
      this.isValidLawFirmName = true;
    } else {
      this.isValidLawFirmName = false;
    }
  } // end onLawFirmNameBlur()

  onBlurAdvocateName() {
    if (!this.util.hasTextValue(this.firmName)) {
      this.isValidAdvocateName = true;
    } else {
      this.isValidAdvocateName = false;
    }
  } // end onLawFirmNameBlur()

  onFocusAttorney() {
    this.onLawFirmNameBlur();
  } // end onFocusAttorney()

  onFocusAdvPhoneNumber() {
    this.onBlurAdvocateName();
  } // end onFocusAdvPhoneNumber()

  onBlurPhoneNumber() {
    if (!this.util.isValidPhoneNumber(this.phoneNumber,
      { code: this.userProfileService.userProfile.currencyDetails.code })) {
      this.isValidPhoneNumber = true;
    } else {
      this.isValidPhoneNumber = false;
    }
  } // end onBlurPhoneNumber()

  onBlurAdvPhoneNumber() {
    if (!this.util.isValidPhoneNumber(this.phoneNumber,
      { code: this.userProfileService.userProfile.currencyDetails.code })) {
      this.isValidAdvocatePhoneNumber = true;
    } else {
      this.isValidAdvocatePhoneNumber = false;
    }
  } // end onBlurAdvPhoneNumber()

  onFocusEmail() {
    this.onBlurPhoneNumber();
    this.onLawFirmNameBlur();
  } // end onFocusEmail()

  onFocusAdvEmail() {
    this.onBlurAdvocateName();
    this.onBlurAdvPhoneNumber();
  } // end onFocusAdvEmail()

  onBlurEmail() {
    if (!this.util.isValidEmail(this.email)) {
      this.isValidEmail = true;
    } else {
      this.isValidEmail = false;
    }
  } // end onBlurEmail()

  onBlurAdvEmail() {
    if (!this.util.isValidEmail(this.email)) {
      this.isValidAdvocateEmail = true;
    } else {
      this.isValidAdvocateEmail = false;
    }
  } // end onBlurAdvEmail()


} // end NewLawFirmModalComponent{}
