import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import {
  TAG_DELETE_MATTER_MODAL
} from '../../../../services/tags/tags.service';

const MIN_COMMENT_LENGTH = 5;
const MAX_COMMENT_LENGTH = 500;
const TITLE_PRFIX = 'Delete matter ';

@Component({
  selector: 'app-delete-matter-modal',
  templateUrl: './delete-matter-modal.component.html',
  styleUrls: ['./delete-matter-modal.component.scss']
})
export class DeleteMatterModalComponent implements OnInit, OnChanges {
  @Input() isVisible: boolean;
  @Input() matter: any;
  @Output() closeModal: EventEmitter<string> = new EventEmitter();
  @Output() confirmDelete: EventEmitter<any> = new EventEmitter();

  title: string = TITLE_PRFIX;
  comments: string;
  isDeleteable: boolean;
  numberOfCharactersLeft = 500;
  exceedsMaxCommentLength = false;

  constructor() {} // end constructor()

  ngOnInit() {} // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (this.matter) {
      this.title = TITLE_PRFIX + this.matter.Description;
    }
  } // end ngOnChange()

  validate() {
    let isOk = true;
    this.numberOfCharactersLeft =
      (this.comments) ? MAX_COMMENT_LENGTH - this.comments.length : MAX_COMMENT_LENGTH;

    if (this.comments.length < MIN_COMMENT_LENGTH) {
      isOk = false;
    }

    if (this.comments.length > MAX_COMMENT_LENGTH) {
      this.exceedsMaxCommentLength = true;
      isOk = false;
    } else {
      this.exceedsMaxCommentLength = false;
    }

    this.isDeleteable = isOk;
  } // end validate(){}

  dismiss() {
    this.comments = '';
    this.validate();
    this.closeModal.emit(TAG_DELETE_MATTER_MODAL);
  } // end dismiss()

  confirm() {
    this.confirmDelete.emit({
      matter: this.matter,
      comments: this.comments
    });
    this.dismiss();
  } // end confirmDelete
} // end DeleteMatterModalComponent{}
