<div class="well">
  <form [class.hide]="isLoading" class="form" autocomplete="off">

    <div class="form-group">
      <label for="date">Date</label>
      <input
        type="text"
        id="date"
        bsDatepicker
        [bsValue]="bsValue"
        (ngModel)="workingMatterQuotation.MatterQuotationDate=$event.target.value"
        (bsValueChange)="onDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        placement="right">
    </div> <!-- #end .form-group -->

    <div class="form-group">
      <label
        for="lawfirms"
        *ngIf="!isAttorney && !isLawfirm && !isLawfirmAssistant">Law firms / Advocates
        <span title="Required field"> *</span> &nbsp;
        <span *ngIf="isLawfirmRatingLoading">
          <img [src]="images.spinner.path" [alt]="images.spinner.label">
        </span>
        <span *ngIf="!isLawfirmSelected && !isLawfirmRatingLoading" class="fa fa-question-circle tool-tip"></span>
      </label>
      <app-datalist-quotation-lawfirm
        type="law-firms"
        *ngIf="!isAttorney && !isLawfirm && !isLawfirmAssistant"
        iconClass="fa fa-black-tie fa-lg"
        [list]="lawFirms"
        [matter]="workingMatterQuotation"
        (select)="onModelChange('law-firms', $event)"></app-datalist-quotation-lawfirm>

        <label for="clients" *ngIf="isAttorney || isLawfirm || isLawfirmAssistant">Clients<span title="Required field"> *</span></label>
        <app-datalist-quotation-lawfirm
          type="other-clients" *ngIf="isAttorney || isLawfirm || isLawfirmAssistant"
          iconClass="fa fa-black-tie fa-lg"
          [otherClients]="otherClients"
          [matter]="workingMatterQuotation"
          (select)="onModelChange('other-clients', $event)"></app-datalist-quotation-lawfirm>
    </div> <!-- #end .form-group -->

    <div class="form-group" *ngIf="!isAttorney && !isLawfirm && !isLawfirmAssistant">
      <label for="attorney">Attorney</label>
      <app-datalist-quotation-lawfirm
        type="attorneys"
        iconClass="fa fa-black-tie fa-lg"
        [list]="attorneys"
        [matter]="workingMatterQuotation"
        (select)="onModelChange('attorney', $event)"></app-datalist-quotation-lawfirm>
    </div> <!-- #end .form-group -->

    <div class="form-group">
      <label for="plaintiffs">Plaintiffs/Applicants<span title="Required field"> *</span> </label>
      <app-datalist-quotation-plaintiffs
        type="plaintiffs"
        iconClass="fa fa-black-tie fa-lg"
        [plaintiffsList]="plaintiffs"
        [matter]="workingMatterQuotation"
        (select)="onModelChange('plaintiffs', $event)"></app-datalist-quotation-plaintiffs>
    </div> <!-- #end .form-group -->

    <div class="form-group">
      <label for="defendants">Defendants/Respondents</label>
      <app-datalist-quotation-plaintiffs
        type="defendants"
        iconClass="fa fa-black-tie fa-lg"
        [defendantsList]="defendants"
        [matter]="workingMatterQuotation"
        (select)="onModelChange('defendants', $event)"></app-datalist-quotation-plaintiffs>
    </div> <!-- #end .form-group -->
  </form> <!-- #end .form -->

  <div class="form" [class.hide]="isLoading">

    <div class="form-group">
      <label for="hourlyRate">Hourly rate<span title="Required field"> *</span></label>
      <input
        type="text"
        id="hourlyRate"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingMatterQuotation.HourlyRate | currency: currencySymbol: currencySymbol:'1.2-2'"
        (ngModelChange)="workingMatterQuotation.HourlyRate=$event"
        (ngModelChange)="workingMatterQuotation.DailyRate=$event * 10"
        (blur)="onHourlyRateChange()"
        (focus)="onHourlyRateClicked()"
        pattern="[P-R\s]*[0-9,.]+"
        autocomplete="off"
        #inputHourly
        required>
    </div> <!-- #end .form-group -->

    <div class="form-group">
      <label for="dailyRate">Daily rate<span title="Required field"> *</span></label>
      <input
        type="text"
        id="dailyRate"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingMatterQuotation.DailyRate | currency: currencySymbol: currencySymbol:'1.2-2'"
        (ngModelChange)="workingMatterQuotation.DailyRate=$event"
        (blur)="onDailyRateChange()"
        (focus)="onDailyRateClicked()"
        pattern="[P-R\s]*[0-9,.]+"
        autocomplete="off"
        #inputDaily
        required>
    </div> <!-- #end .form-group -->

    <div class="form-group">
      <label for="perKilometerRate">Per kilometer rate<span title="Required field"> *</span></label>
      <input
        type="text"
        id="perKilometerRate"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingMatterQuotation.PerKilometerRate | currency: currencySymbol: currencySymbol:'1.2-2'"
        (ngModelChange)="workingMatterQuotation.PerKilometerRate=$event"
        (blur)="onPerkilometerRateChange()"
        (focus)="onPerkilometerRateClicked()"
        pattern="[P-R\s]*[0-9,.]+"
        autocomplete="off"
        #inputPerKilometer
        required>
    </div> <!-- #end .form-group -->

    <div class="form-group">
      <label for="paymentTerms">Payment term</label>
      <select
        id="paymentTerms"
        [(ngModel)]="workingMatterQuotation.PaymentTerms"
        (change)="onPaymentTermsSelect($event)">
        <option 
          *ngFor="let age of paymentTerms" 
          [value]="age.id" 
          [selected]="age.id === workingMatterQuotation.PaymentTerms">
          {{age.name}}
        </option>
      </select>
    </div> <!-- #end .form-group -->

    <div class="form-group">
      <label for="attorneyReference" *ngIf="isAttorney || isLawfirm || isLawfirmAssistant">Reference<span
          title="Required field"> *</span></label>
      <label for="attorneyReference" *ngIf="!isAttorney && !isLawfirm && !isLawfirmAssistant">Attorney reference<span
          title="Required field"> *</span></label>
      <input
        required
        type="text"
        placeholder="Attorney reference"
        [(ngModel)]="workingMatterQuotation.ClientRefNo"
        (input)="onModelChange('attorney-reference', {FullName: $event.target.value})"
        (click)="$event.target.select()">
    </div>
    <!--#end .form-group -->

    <div class="form-group">
      <label for="instructions">Instructions</label>
      <input
        type="text"
        placeholder="Instructions"
        [(ngModel)]="workingMatterQuotation.Instructions"
        (input)="onModelChange('instructions', {FullName: $event.target.value})"
        (click)="$event.target.select()">
    </div>
    <!--#end .form-group -->

  </div> <!-- #end .form -->

  <div class="form" style="justify-content: flex-start;" *ngIf="workingMatterQuotation.AcceptRejectNotes">
    <p style="color: #cf624f;"><strong>Reason for rejection: </strong>{{workingMatterQuotation.AcceptRejectNotes}}</p>
  </div>
</div>

<app-add-other-payment-terms
*ngIf="onShowPaymentTermsModal"
[isVisible]="onShowPaymentTermsModal"
(savePaymentTerms)="onSavePaymentTerms($event)"
(closeModal)="onPaymentTermsModalClose()"></app-add-other-payment-terms>