import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { Router } from '@angular/router';
import { ManageBlackListRequest, ManageBlackListService } from '../../services/manage-BlackList/manage-BlackList.service';
import { PATH_GCB_RESPONSE_HANDLER } from '../../services/appdata/app-config.service';
@Component({
  selector: 'app-blacklist-pdf-viewer',
  templateUrl: './blacklist-pdf-viewer.component.html',
  styleUrls: ['./blacklist-pdf-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlacklistPdfViewerComponent implements OnInit {
  @ViewChild('pdfViewer') public pdfViewer;
  viewAttachedPdf: boolean = false;
  fileName;
  lawfirmBlacklistId: number;
  filePath: string;
  statusUpdate: string;
  showApproveChangesPrompt: boolean;
  showRejectChangesPrompt: boolean;
  showApproveGCBReqChangesPrompt: boolean;
  showRejectGCBReqChangesPrompt: boolean;
  blackListLawfirmId: number;
  isGCBDeleteRequest: boolean = false;
  isFileExist: boolean = false;
  gcbNotes: string;
  approvedRejectedByID: number;

  constructor(
    private loadingService: LoadingService,
    private router: Router,
    private manageBlackListService: ManageBlackListService,
  ) { }

  ngOnInit(): void {    
    const decodedUrl = window.atob(decodeURIComponent(this.router.url.split('=')[1]));
    const params = new URLSearchParams(decodedUrl);
    this.lawfirmBlacklistId = Number(params.get('ID'));
    const status = params.get('status');
    this.approvedRejectedByID = Number(params.get('ApprovedRejectedByID'));

    if (status == "isGCBDeleteRequest")
      this.isGCBDeleteRequest = true;

    this.getAttachedDocumentFile('view');
  }

  getAttachedDocumentFile(action: string) {
    this.loadingService.showOverlay();
    if (this.lawfirmBlacklistId) {
      this.manageBlackListService.getAttachedDocumentFile(this.lawfirmBlacklistId,this.isGCBDeleteRequest)
        .subscribe({
          next: (response: any) => {
            if (response) {
              this.gcbNotes = response.GcbNotes;
              if (response.isFileExist) {
                this.isFileExist = true;
              }
              if (response.FileContent) {
                const base64Content = response.FileContent;
                const blob = this.base64ToBlob(base64Content, 'application/pdf');
                const url = window.URL.createObjectURL(blob);
                if (action == 'view') {
                  this.pdfViewer.name = response.FileName;
                  this.pdfViewer.pdfSrc = url;
                  this.pdfViewer.refresh();
                  this.loadingService.hideOverlay();
                }
                else if (action == 'download') {
                  const link = document.createElement('a');
                  link.href = url;
                  link.download = 'document.pdf';
                  window.open(url, '_blank');
                  this.loadingService.hideOverlay();
                }
              }
            }
            this.loadingService.hideOverlay();
          },
        })
    }
  }

  base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  onActionClickEvent() {
    this.onApproved_Rejected();
    this.onHideApproveChangesPrompt();
    this.onHideRejectChangesPrompt();
  }

  onShowApproveChangesPrompt(status: string) {
    this.blackListLawfirmId = this.lawfirmBlacklistId;
    this.statusUpdate = status;
    this.showApproveChangesPrompt = true;
  }
  downloadBlackListPDF() {
    this.getAttachedDocumentFile('download');
  }
  onHideApproveChangesPrompt() {
    this.showApproveChangesPrompt = false;
  }
  onHideRejectChangesPrompt() {
    this.showRejectChangesPrompt = false;
  }
  onShowRejectChangesPrompt(status: string) {
    this.blackListLawfirmId = this.lawfirmBlacklistId;
    this.statusUpdate = status;
    this.showRejectChangesPrompt = true;
  }
  onApproved_Rejected() {
    this.loadingService.showOverlay();
    let encyptedString = window.btoa(`LawfirmBlackRequest__Status=${this.statusUpdate}&ID=${this.blackListLawfirmId}__LawfirmBlackRequest`)
    this.router.navigate([PATH_GCB_RESPONSE_HANDLER + '/' + encyptedString]);
  }

  printPdf() {
    if (typeof this.pdfViewer.pdfSrc === 'string') {
      fetch(this.pdfViewer.pdfSrc)
        .then(response => response.blob())
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        })
        .catch(error => {
          console.error('Error fetching PDF:', error);
        });
    } else if (this.pdfViewer.pdfSrc instanceof Blob) {
      const blobUrl = window.URL.createObjectURL(this.pdfViewer.pdfSrc);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    } else {
      console.error('Invalid pdfSrc:', this.pdfViewer.pdfSrc);
    }
  }

  onShowGCBReqApproveChangesPrompt(status: string) {
    this.blackListLawfirmId = this.lawfirmBlacklistId;
    this.statusUpdate = status;
    this.showApproveGCBReqChangesPrompt = true;
  }
  onShowGCBReqRejectChangesPrompt(status: string) {
    this.blackListLawfirmId = this.lawfirmBlacklistId;
    this.statusUpdate = status;
    this.showRejectGCBReqChangesPrompt = true;
  }

  onActionGCBDeleteReqClickEvent() {
    this.onGCBDeleteRequApproved_Rejected();
    this.onHideApproveChangesPrompt();
    this.onHideRejectChangesPrompt();
  }

  onGCBDeleteRequApproved_Rejected() {
    this.loadingService.showOverlay();
    let encryptedString = window.btoa(`LawfirmBlackRequest__Status=${this.statusUpdate}&ID=${this.blackListLawfirmId}&FileName=${this.pdfViewer.name}&approvedRejectedByID=${this.approvedRejectedByID}&isGCBDeleteReq=true`);
    let encodedURL = `${PATH_GCB_RESPONSE_HANDLER}/${encryptedString}`;
    this.router.navigate([encodedURL]);     
  }

  onHideGCBApproveChangesPrompt() {
    this.showApproveGCBReqChangesPrompt = false;
  }

  onHideGCBRejectChangesPrompt() {
    this.showRejectGCBReqChangesPrompt = false;
  }
}