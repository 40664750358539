import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AuthenticationGuard } from '../../common/guards/authentication-guard';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-advocate-sign-out',
  templateUrl: './advocate-sign-out.component.html',
  styleUrls: ['./advocate-sign-out.component.scss']
})
export class AdvocateSignOutComponent implements OnInit {

  isAuthenticated = false;
  imageLogo: any = {
    path: '../../../assets/images/logos/auxcon-sign-in-brand.png',
    alt: 'Auxcon Logo'
  };

  constructor(
    private route: ActivatedRoute,
    private authenticationGuad: AuthenticationGuard,
    private authService: AuthService,
    private apiService: ApiService
  ) {
  } // end constructor()

  ngOnInit() {

    // this.isAuthenticated = this.authenticationGuad.isAuthenticate();
    // if (this.isAuthenticated) {
      sessionStorage.removeItem('userProfile');
      sessionStorage.clear();
      localStorage.clear(); // TODO: Move to sign out page
      this.apiService.serviceProviderID = 0;
      this.authService.logout();
    // }
  } // end ngOnInit()

} // end AdvocateSignOutComponent{}
