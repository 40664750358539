<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onDismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end modal-header -->

      <div class="modal-body form">
        <div class="row">
          <!-- Name -->
          <div class="form-group">  
            <label for="name">Name<span title="Required field"> *</span></label>
            <input
              type="text"
              placeholder="Enter name"
              [(ngModel)]="teamMember.FirstName"
              class="form-control"
              id="name"
              [disabled]="canDeteleMember">
          </div> <!-- #end form-group -->
          <!-- Surname -->
          <div class="form-group">  
            <label for="surname">Surname<span title="Required field"> *</span></label>
            <input
              type="text"
              placeholder="Enter surname"
              [(ngModel)]="teamMember.LastName"
              class="form-control"
              id="surname"
              [disabled]="canDeteleMember">
          </div> <!-- #end form-group -->
          <!-- Email -->
          <div class="form-group">  
            <label for="email">Email<span title="Required field"> *</span></label>
            <input
              type="text"
              placeholder="Enter email"
              [class.has-error]="hasContactEmailError"
              [(ngModel)]="teamMember.Email"
              class="form-control"
              id="email"
              [disabled]="canDeteleMember">
              <span
              *ngIf="hasContactEmailError"
              class="text-danger">Please enter a valid email address.</span>
          </div> <!-- #end form-group -->
          <!-- Role -->
          <div class="form-group">  
            <label for="role">Role<span title="Required field"> *</span></label>
            <select
              class="form-control"
              id="role"
              placeholder="Choose a role"
              [(ngModel)]="teamMember.RoleName">
              <option value="" disabled selected hidden>Choose a role</option>
              <option
              *ngFor="let role of userrole"
              [value]="role.value">{{role.label}}</option>>
            </select>
          </div> <!-- #end form-group -->
        </div> <!-- #end row -->
        <div class="row">
          <!-- Able to do in a Role -->
          <div class="form-group">
            <label *ngIf="teamMember.RoleName === 'Practice Assistant'">Practice Assistants are able to: </label>
            <br *ngIf="teamMember.RoleName === 'Practice Assistant'"/>
            <br *ngIf="teamMember.RoleName === 'Practice Assistant'"/>
            <li *ngIf="teamMember.RoleName === 'Practice Assistant'">View the dashboard including financial information</li>
            <li *ngIf="teamMember.RoleName === 'Practice Assistant'">View financial reports</li>
            <!-- <li *ngIf="teamMember.RoleName === 'Practice Assistant'">Send invoices</li> -->

            <label *ngIf="teamMember.RoleName === 'Admin Assistant'">Admin Assistants are <b>NOT</b> able to:</label>
            <br *ngIf="teamMember.RoleName === 'Admin Assistant'"/>
            <br *ngIf="teamMember.RoleName === 'Admin Assistant'"/>
            <li *ngIf="teamMember.RoleName === 'Admin Assistant'">View the dashboard including financial information</li>
            <li *ngIf="teamMember.RoleName === 'Admin Assistant'">View financial reports</li>
            <!-- <li *ngIf="teamMember.RoleName === 'Admin Assistant'">Edit fee</li> -->
          </div>
        </div>
      </div> <!-- #end modal-body form-->
      <div class="modal-footer">
        <button
          class="btn btn-danger btn-start"
          [class.disabled]="!canDeteleMember"
          [disabled]="!canDeteleMember"
          (click)="onShowDeleteMemberModal(teamMember)">Delete Member</button>
        <span></span>
        <button
        [class.disabled]="!isSaveable"
        [disabled]="!isSaveable"
        class="btn btn-success btn-end"
        (click)="onSave(teamMember)">{{saveBtn}}</button>
        </div>
    </div> <!-- #end modal-dialog -->
  </div> <!-- #end modal-content -->
</div> <!-- #end modal -->
