<!-- <app-login-navbar></app-login-navbar> -->
<app-navbar [class.material]="materializeNavbar" [pageClickEvents]="pageClickEventsSubject.asObservable()"
  *ngIf="!isOnboarding && !isOutlookHandler"></app-navbar>
<app-onboarding-header *ngIf="isOnboarding"></app-onboarding-header>
<router-outlet (activate)="onActivate($event)"></router-outlet>

<app-snackbar></app-snackbar>
<app-toast></app-toast>
<app-loading-overlay-modal></app-loading-overlay-modal>
<app-online-status-modal [onlineStatusMessage]="connectionStatusMessage" [onlineStatus]="connectionStatus">
</app-online-status-modal>

<div id="mybutton">
  <a title="Support" href="https://www.auxcon.co.za/support" target="_blank">
    <button class="support">Support <br />-</button>
    <!-- <i class="far fa-question-circle support">&nbsp; Support</i>  -->
  </a>
</div>