<div class="container-fluid" *ngIf="isDataLoaded===true">
    <div class="table-responsive">
        <table class="table table-striped " style="border: 0;">
            <thead>
                <tr class="">
                    <th class="w-20 align-bottom">Debtor</th>
                    <th class="w-10 align-bottom text-right tool-tip">Average Days to Payment &nbsp; <span class="fa fa-question-circle tool-tip">
                        <span style="font-weight:bold;"  class="tooltiptext">Calculation based on invoices issued and paid in the last three years.</span>
                    </span></th>
                    <th class="w-10 align-bottom text-right tool-tip">% Paid vs Invoiced &nbsp; <span class="fa fa-question-circle tool-tip">
                        <span style="font-weight:bold;"  class="tooltiptext">Total Paid vs Total Invoices expressed as a percentage.</span>
                    </span></th>
                    <th class="w-10 align-bottom text-right">Total Outstanding</th>
                    <th class="w-50"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let i of data;">
                    <td class="w-20"><u class="cursor"><a (click)="onViewCustomerReport(i.clientId)">{{i.debtor}}</a></u></td>
                    <td class="w-10 text-right tool-tip" style="font-weight:bold;" [ngStyle]="{'color': formatAverageDaysToPayment(i.averageDaysToPayment)}" >
                        {{getDays(i.averageDaysToPayment)}}
                    </td>
                    <td class="w-10 text-right tool-tip" style="font-weight:bold;"
                    [ngStyle]="{'color': formatPaidVsInvoiced(i.paidVsInvoiced)}">
                        {{i.paidVsInvoiced}}%
                    </td>
                    <td class="w-10 text-right">{{i.totalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    <td class="w-50">
                        <div>
                            <div class="border-rounded-right " [ngStyle]="{'width': getBarWidth(i.totalOutstanding)}">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="">
                    <th><u>Total</u></th>
                    <!-- <th class="text-right ">{{this.averageDays}} Days</th>
                    <th class="text-right">{{averagePercentage}}%</th> -->
                    <th></th>
                    <th></th>
                    <th class="text-right">{{allTotalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>