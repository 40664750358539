import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { AdvocateHealth } from '../../../services/practice-advocate-health/practice-advocate-health.service';
@Component({
  selector: 'app-manage-meeting-date',
  templateUrl: './manage-meeting-date.component.html',
  styleUrls: ['./manage-meeting-date.component.scss']
})
export class ManageMeetingDateComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() AdvocateDetails: AdvocateHealth;
  @Output() closemodal: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveLastMeetingDate: EventEmitter<any> = new EventEmitter<any>();
  updatedDate: any;
  constructor() { }

  ngOnInit(): void {
  }
  onCancel() {
    this.closemodal.emit();
  } // end onCancel()
  onDateValueChange(value: Date): void {
    //this.AdvocateDetails.LastMeetingDate = moment(value).format('YYYY-MM-DD');
    this.updatedDate = moment(value).format('YYYY-MM-DD');
  } // end onDateValueChange()
  onSave() {
    if (this.updatedDate) {
      this.AdvocateDetails.LastMeetingDate = this.updatedDate;
    }

    this.saveLastMeetingDate.emit(this.AdvocateDetails);
  }
}
