<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
      <div class="modal-dialog">
  
        <div class="modal-header">
          <h2>{{title}}</h2>
          <button class="modal-dismiss" (click)="onCancel()">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!--#end .modal-header -->
  
        <div class="modal-body">
          <div class="container-fluid">
            <div class="message">
              <p>{{message}}</p>
            </div>
            <table>
                <tr style="font-size: 14px;">
                    <th>Average days to pay:</th>
                    <td>&nbsp;&nbsp; {{lawfirmRatingsData.AverageDaysToPay}} Days</td>
                </tr>
                <tr style="font-size: 14px;">
                    <th>LPC Complaints: </th>
                    <td>&nbsp;&nbsp; {{lawfirmRatingsData.LPCComplaints}} </td>
                </tr>
                <tr style="font-size: 14px;">
                    <th>GCB Listings: </th>
                    <td>&nbsp;&nbsp; {{lawfirmRatingsData.GCBListing}}</td>
                </tr>
                <tr style="font-size: 14px;">
                    <th>In Legal:</th>
                    <td>&nbsp;&nbsp; {{lawfirmRatingsData.InLegal}} </td>
                </tr>
            </table>
                <div class="message">
                <p>Do you wish to proceed?</p>
              </div>
          </div> <!-- #end .container-fluid -->
        </div>
        <!--#end .modal-body -->
  
        <div class="modal-footer">
          <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
          <button class="btn btn-success" (click)="onConfirm()">Proceed</button>
          <!-- <span></span> -->
          <!-- <span></span>
          <button class="btn btn-info" (click)="onConfirm()">{{btnTextConfirm}}</button> -->
        </div>
        <!--#end .modal-footer -->
  
      </div>
      <!--#end .modal-dialog -->
    </div>
    <!--#end .modal-content -->
  </div>
  <!--#end .modal -->