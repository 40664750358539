import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { StatementByAdvocate, LiveStatementService } from '../../../services/live-statement/live-statement.service';
import { Router } from '@angular/router';
import { PATH_ADVOCATE_DETAIL, PATH_UNPAID_INVOICES } from '../../../services/appdata/app-config.service';
import { AdvocateDetailStatementService,
  AdvocateDetailStatement, 
  QueryReport} from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { ApiService } from '../../../services/api.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';

  const FIELD_ADVOCATE          =  'advocate';
  const FIELD_EMAIL             =  'email';
  const FIELD_CELL              =  'cell';
  const FIELD_MATTERS           =  'matters';
  const FIELD_OUTSTANDING       =  'outstanding';

  export interface LiveStatementHandle {
    data: StatementByAdvocate;
    state: {
      isVisible: boolean;
      showDropdown: boolean;
    };
  }

@Component({
  selector: 'app-live-statement',
  templateUrl: './live-statement.component.html',
  styleUrls: ['./live-statement.component.scss']
})
export class LiveStatementComponent implements OnInit, OnChanges {

  title = 'Live statement';
  activeTab: string;

  sortBy = '';
  sortAscending: boolean;
  sortAttorneyAscending: boolean;
  private previousAttorneySortBy: string;
  private previousSortBy: string;
  searchValue: string;

  @Input() data: StatementByAdvocate[];
  @ViewChild('advocateStatement') advocateStatement: ElementRef;

  liveStatementHandle: LiveStatementHandle[] = [];
  newLiveStatementHandle: LiveStatementHandle[] = [];
  activeLiveStatementHandle: LiveStatementHandle;
  showLiveStatementPopupMenu: boolean;
  showAdvocateStatement: boolean;
  selectedStatement: StatementByAdvocate;
  exportStatement: any[] = [];
  advocateDetailStatement: AdvocateDetailStatement[];
  advocateDetailStatementHandle: LiveStatementHandle[] = [];

  escalectedQueries: StatementByAdvocate[] = [];
  escalectedQueriesHandle: LiveStatementHandle[] = [];

  newInvoices: StatementByAdvocate[] = [];
  newInvoicesHandle: LiveStatementHandle[] = [];

  queryReport: QueryReport[] = [];

  orderBy = '';
  orderingOptions: string[] = [
    'advocate',
    'email',
    'cell',
    'matters',
    'outstanding'
];

profileTypes = {
  ADVOCATE: 'advocate',
  ASSISTANT: 'Assistant',
  ATTORNEY: 'Attorney',
  LAWFIRM: 'Lawfirm',
  PRACTICE_MANAGER: 'Practice Manager',
  EXTENAL_LAWFIRM: 'Lawfirm (External)',
  EXTENAL_ATTORNEY: 'Attorney (External)',
  BAR_SOCIETY: 'Bar Society - User',
  GCB: 'GCB - User',
  ADHOC: 'Ad-hoc',
  HEAD_OF_STATE: 'Head of State'
};

  excelURL: string;
  statementByAttorney: StatementByAdvocate[] = [];

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()
  
  constructor(
    private router: Router,
    private api: ApiService,
    private http: HttpClient,
    private cpipe: UsercurrencyPipe,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private liveStatementService: LiveStatementService,
    private advocateDetailStatementService: AdvocateDetailStatementService,
  ) {
    this.excelURL = this.api.endpoints.auxconReportUtils + '/AuxconReportUtils';
   } // end constructor()

  get grandTotal(): number {
    if (!this.liveStatementHandle) {
      return 0;
    }
    let total = 0;

    this.liveStatementHandle.forEach(item => {
      if(item.state.isVisible) {
        total += item.data.TotalOutstanding;
      }
    });
    return total;
  } // end grandTotal{}

  get escalationGrandTotal(): number {
    if (!this.liveStatementHandle) {
      return 0;
    }
    let total = 0;

    this.escalectedQueriesHandle.forEach(item => {
      if(item.state.isVisible) {
        total += item.data.TotalOutstanding;
      }
    });
    return total;
  } // end escalationGrandTotal{}

  get newInvoicesGrandTotal(): number {
    if (!this.newInvoicesHandle) {
      return 0;
    }
    let total = 0;

    this.newInvoicesHandle.forEach(item => {
      if(item.state.isVisible) {
        total += item.data.TotalOutstanding;
      }
    });
    return total;
  } // end newInvoicesGrandTotal{}

  get newInvoicesAdvocateCount(): number {
    const filteredItems = this.newInvoicesHandle.filter(x => x.data.InvoiceStatusGroupOutstanding > 0);

    const sumCurrentMatters = filteredItems.reduce((sum, item) => sum + item.data.CurrentMatters, 0);
    return sumCurrentMatters; // this.liveStatementHandle.filter(x => x.data.InvoiceStatusGroupOutstanding > 0).length;
  }

  get escaletedQuesriesCount(): number {
    const sumCurrentMatters = this.escalectedQueriesHandle.reduce((sum, item) => sum + item.data.CurrentMatters, 0);
    return sumCurrentMatters; // this.escalectedQueriesHandle.length;
  }

  get isHeadOfState(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.HEAD_OF_STATE)
  } // end isHeadOfState()

  isLoading = true;

  async ngOnInit() {
    this.advocateDetailStatementService.selectedAttorneyId = 0;
    this.setActiveTab('view-as-table');
    this.sortBy = FIELD_ADVOCATE;
    
    // this.advocateDetailStatement = await firstValueFrom(this.advocateDetailStatementService.getLawfirmStatement(this.api.serviceProviderID));
    try {
      this.advocateDetailStatement = await firstValueFrom(this.advocateDetailStatementService.getLawfirmStatement(this.api.serviceProviderID));
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      this.isLoading = false; // Set loading flag to false once data is loaded
    }
  
    // this.advocateDetailStatement = JSON.parse(sessionStorage.getItem('advocateDetailStatement'));
    if(this.isHeadOfState) {
      this.getEscalatedQueriesData();
    } 
      
    this.getNewInvoicesData();
    
    // console.log('advocateDetailStatement: ', this.advocateDetailStatement);
    // if (this.advocateDetailStatement && this.advocateDetailStatement.length > 0) {

    // } else {
      // this.advocateDetailStatementService.getLawfirmStatement(this.api.serviceProviderID).subscribe({ next: (onNext) => {
      //   if(onNext) {
      //     this.advocateDetailStatement = onNext;
      //   }
      // }});
    //   sessionStorage.setItem('advocateDetailStatement', JSON.stringify(this.advocateDetailStatement));
    // }
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.CalculatePercentage(this.data);
      this.data.forEach(statement => {
        statement.Email = 'mailto:' + statement.Email;
        // console.log('LIVE STATEMENT DATA: ', statement);
        this.liveStatementHandle.push({
          data: statement,
          state: {
            isVisible: true,
            showDropdown: false
          }
        });
      });
      this.liveStatementHandle.sort((a, b) => b.data.TotalOutstanding - a.data.TotalOutstanding);
    }
  } // end ngOnChanges()

  CalculatePercentage(data: StatementByAdvocate[]) {
    let total = 0;
    data.forEach(item => {
      total += item.TotalOutstanding;
    });
    data.forEach(c => {
      c.OutstandingPercentage = Math.round(c.TotalOutstanding * 100 / total);
    });
    // data.OutstandingPercentage = Math.round(data.TotalOutstanding * 100 / this.grandTotal);
  }

  onPageClick(event) {
    if (event.target.className) {
      if (!event.target.classList.contains('fa-angle-down') &&
      this.activeLiveStatementHandle) {
        // Close the open meun
        this.onDismissMenu(this.activeLiveStatementHandle);
      } else if (!event.target.classList.contains('fa-angle-up') &&
        !this.activeLiveStatementHandle) {
        this.onDismissLiveStatementMenu();
      }
    }
  } // end onPageClick()

  setActiveTab(tab: string) {
    this.activeTab = tab;
    if(this.activeTab === 'new-invoices') {
      this.liveStatementHandle.forEach(x => {
        if(x.data.InvoiceStatusGroupOutstanding > 0) {
          x.state.isVisible = true;
        } else {
          x.state.isVisible = false;
        }
      });
    } else if (this.activeTab === 'view-as-table') {
      this.liveStatementHandle.forEach(x => {
        x.state.isVisible = true;
      });
    } else if (this.activeTab === 'attorney') {
      this.advocateDetailStatementHandle = [];
      this.loadingService.showOverlay();
      this.liveStatementService.getAdvocateStatementByStateAttorneySummary().subscribe({ next:  (data) => {
        // On next
        this.statementByAttorney = data.StatementByAdvocate;
        
        this.statementByAttorney.forEach(attorney => {
          this.advocateDetailStatementHandle.push({
            data: attorney,
            state: {
              isVisible: true,
              showDropdown: false
            }
          });
        });
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
        }
      });
    } else if (this.activeTab === 'report-queries') {
      this.queryReport = [];
      this.loadingService.showOverlay();
      this.advocateDetailStatementService.getQueryReport().subscribe({ next:  (data) => {
        // On next
        this.queryReport = data;
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
        }
      });
    } else if (this.activeTab === 'escalation') {
      // if(!this.escalectedQueries) {
      //   this.getEscalatedQueriesData();
      // }
    }
  } // end setActiveTab()

  getEscalatedQueriesData() {
    this.escalectedQueriesHandle = [];
    this.escalectedQueries = [];
    // this.loadingService.showOverlay();
    this.liveStatementService.getEscaletedQueries().subscribe({ next:  (data) => {
      // On next
      this.escalectedQueries = data.StatementByAdvocate;
      
      this.escalectedQueries.forEach(attorney => {
        this.escalectedQueriesHandle.push({
          data: attorney,
          state: {
            isVisible: true,
            showDropdown: false
          }
        });
      });
    },
    error: (error) => {
      // On error
      // this.loadingService.hideOverlay();
    },
      complete: () => {
        // On complete
        // this.loadingService.hideOverlay();
      }
    });
  } // end getEscalatedQueriesData()

  getNewInvoicesData() {
    this.newInvoicesHandle = [];
    this.newInvoices = [];
    // this.loadingService.showOverlay();
    this.liveStatementService.getNewInvoices().subscribe({ next:  (data) => {
      // On next
      this.newInvoices = data.StatementByAdvocate;
      
      this.newInvoices.forEach(invoies => {
        this.newInvoicesHandle.push({
          data: invoies,
          state: {
            isVisible: true,
            showDropdown: false
          }
        });
      });
    },
    error: (error) => {
      // On error
      // this.loadingService.hideOverlay();
    },
      complete: () => {
        // On complete
        // this.loadingService.hideOverlay();
      }
    });
  } // end getNewInvoicesData()

  viewReport(advocate: StatementByAdvocate) {
    if(this.activeTab === 'escalation' || this.activeTab === 'new-invoices') {
      this.advocateDetailStatementService.activeTab = 'new-invoices';
    } else if(this.activeTab === 'attorney') {
      this.advocateDetailStatementService.activeTab = 'attorney';
    } else {
      this.advocateDetailStatementService.activeTab = 'view-all';
    }
    console.log(advocate);
    if (advocate) {
      // this.router.navigate([PATH_ADVOCATE_DETAIL]);
      this.router.navigate([PATH_ADVOCATE_DETAIL, advocate.ClientId, advocate.ServiceProviderId]);
    }
  } // end viewReport()

  viewAttorneyReport(attorney: StatementByAdvocate) {
    this.advocateDetailStatementService.activeTab = 'view-all';
      this.advocateDetailStatementService.selectedAttorneyId = attorney.ServiceProviderId;
    if (attorney) {
      // this.router.navigate([PATH_ADVOCATE_DETAIL]);
      this.router.navigate([PATH_ADVOCATE_DETAIL, attorney.ClientId, 0]);
    }
  } // end viewAttorneyReport()

  viewAllAdvocates() {
    this.advocateDetailStatementService.activeTab = 'view-all';
    this.router.navigate([PATH_ADVOCATE_DETAIL, this.api.serviceProviderID, 0]);
  } // end viewAllAdvocates()

  viewReportAll() {
  if(this.activeTab === 'attorney') {
    this.advocateDetailStatementService.activeTab = 'attorney';
  } else {
    this.advocateDetailStatementService.activeTab = 'view-all';
  }
    
    if (this.api.serviceProviderID) {
      this.router.navigate([PATH_UNPAID_INVOICES, this.api.serviceProviderID]);
    }
  } // end viewReportAll()

  togglePopupMenu(liveStatement: LiveStatementHandle) {
    liveStatement.state.showDropdown = !liveStatement.state.showDropdown;
    if (this.activeLiveStatementHandle &&
      this.activeLiveStatementHandle !== liveStatement) {
          this.activeLiveStatementHandle.state.showDropdown = false;
        }
        this.activeLiveStatementHandle = liveStatement;
  } // end togglePopupMenu()

  toggleListStatementMenu() {
    this.showLiveStatementPopupMenu = !this.showLiveStatementPopupMenu;
  } // end toggleListStatementMenu()

  onDismissMenu(liveStatement: LiveStatementHandle) {
    liveStatement.state.showDropdown = false;
    this.activeLiveStatementHandle = null;
  } // end onDismissMenu()

  onDismissLiveStatementMenu() {
    if (this.showLiveStatementPopupMenu) {
      this.showLiveStatementPopupMenu = false;
    }
  } // end onDismissLiveStatementMenu()

  closeModal(modal: string) {
    switch (modal) {
      case 'advocate-statement':
        this.showAdvocateStatement = false;
        break;
    }
  } // end closeModal()

  onStatementView(liveStatement: StatementByAdvocate) {
    this.selectedStatement = liveStatement;
    this.showAdvocateStatement = true;
  } // end onStatementView()

  toggleSort(sortBy: string) {
    this.sortBy = sortBy;

    if (this.sortBy === this.previousSortBy) {
      // toggle ascending
      this.sortAscending = !this.sortAscending;
    }
    switch (this.sortBy) {

      case 'advocate':
      this.liveStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.ProviderName.toUpperCase().localeCompare(b.data.ProviderName.toUpperCase());
        }
        return b.data.ProviderName.toUpperCase().localeCompare(a.data.ProviderName.toUpperCase());
      });
      break;

      case 'email':
      this.liveStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Email.toUpperCase().localeCompare(b.data.Email.toUpperCase());
        }
        return b.data.Email.toUpperCase().localeCompare(a.data.Email.toUpperCase());
      });
      break;

      case 'cell':
      this.liveStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Phone.toUpperCase().localeCompare(b.data.Phone.toUpperCase());
        }
        return b.data.Phone.toUpperCase().localeCompare(a.data.Phone.toUpperCase());
      });
      break;

      case 'matters':
      this.liveStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.CurrentMatters - b.data.CurrentMatters;
        }
        return b.data.CurrentMatters - a.data.CurrentMatters;
      });
      break;

      case 'outstanding':
      this.liveStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.TotalOutstanding - b.data.TotalOutstanding;
        }
        return b.data.TotalOutstanding - a.data.TotalOutstanding;
      });
      break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

  toggleAttorneySort(sortBy: string) {
    this.sortBy = sortBy;

    if (this.sortBy === this.previousSortBy) {
      // toggle ascending
      this.sortAscending = !this.sortAscending;
    }
    switch (this.sortBy) {

      case 'advocate':
      this.advocateDetailStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.ProviderName.toUpperCase().localeCompare(b.data.ProviderName.toUpperCase());
        }
        return b.data.ProviderName.toUpperCase().localeCompare(a.data.ProviderName.toUpperCase());
      });
      break;

      case 'email':
      this.advocateDetailStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Email.toUpperCase().localeCompare(b.data.Email.toUpperCase());
        }
        return b.data.Email.toUpperCase().localeCompare(a.data.Email.toUpperCase());
      });
      break;

      case 'cell':
      this.advocateDetailStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Phone.toUpperCase().localeCompare(b.data.Phone.toUpperCase());
        }
        return b.data.Phone.toUpperCase().localeCompare(a.data.Phone.toUpperCase());
      });
      break;

      case 'matters':
      this.advocateDetailStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.CurrentMatters - b.data.CurrentMatters;
        }
        return b.data.CurrentMatters - a.data.CurrentMatters;
      });
      break;

      case 'outstanding':
      this.advocateDetailStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.TotalOutstanding - b.data.TotalOutstanding;
        }
        return b.data.TotalOutstanding - a.data.TotalOutstanding;
      });
      break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleAttorneySort()

  clearSearch() {
    this.searchValue = null;
    this.liveStatementHandle.forEach(statement => {
      statement.state.isVisible = true;
    });
  }

  filterStatements() {
    if (this.searchValue) {
      this.liveStatementHandle.forEach(statement => {
        if (
          !(statement.data.ProviderName.match(new RegExp(this.searchValue, 'gi'))) &&
          !(statement.data.Email.match(new RegExp(this.searchValue, 'gi')))
          // !(statement.data.Phone.match(new RegExp(this.searchValue, 'gi')))
        ) {
          statement.state.isVisible = false;
        } else {
          statement.state.isVisible = true;
        }
      });
    } else {
      this.liveStatementHandle.forEach(statement => {
        statement.state.isVisible = true;
      });
    }
  } // end filterStatements()

  filterAttorneyStatements() {
    if (this.searchValue) {
      this.advocateDetailStatementHandle.forEach(statement => {
        if (
          !(statement.data.ProviderName.match(new RegExp(this.searchValue, 'gi'))) &&
          !(statement.data.Email.match(new RegExp(this.searchValue, 'gi')))
          // !(statement.data.Phone.match(new RegExp(this.searchValue, 'gi')))
        ) {
          statement.state.isVisible = false;
        } else {
          statement.state.isVisible = true;
        }
      });
    } else {
      this.advocateDetailStatementHandle.forEach(statement => {
        statement.state.isVisible = true;
      });
    }
  } // end filterAttorneyStatements()

  toggleAscending() {
    this.sortAscending = !this.sortAscending;
    this.orderLiveStatement();
  } // end toggleAscending()

  orderLiveStatement() {
    switch (this.orderBy) {
      case FIELD_ADVOCATE:
        this.liveStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.ProviderName.toUpperCase().localeCompare(b.data.ProviderName.toUpperCase());
        }
        return b.data.ProviderName.toUpperCase().localeCompare(a.data.ProviderName.toUpperCase());
      });
      break;

      case FIELD_EMAIL:
        this.liveStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.Email.toUpperCase().localeCompare(b.data.Email.toUpperCase());
          }
          return b.data.Email.toUpperCase().localeCompare(a.data.Email.toUpperCase());
        });
      break;

      case FIELD_CELL:
        this.liveStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.Phone.toUpperCase().localeCompare(b.data.Phone.toUpperCase());
          }
          return b.data.Phone.toUpperCase().localeCompare(a.data.Phone.toUpperCase());
        });
      break;

      case FIELD_MATTERS:
        this.liveStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.CurrentMatters - b.data.CurrentMatters;
          }
          return b.data.CurrentMatters - a.data.CurrentMatters;
        });
      break;

      case FIELD_OUTSTANDING:
        this.liveStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.TotalOutstanding - b.data.TotalOutstanding;
          }
          return b.data.TotalOutstanding - a.data.TotalOutstanding;
        });
      break;
    }
  } // end orderLiveStatement()

  toggleAttorneyAscending() {
    this.sortAttorneyAscending = !this.sortAttorneyAscending;
    this.orderAttorneyStatement();
  } // end toggleAttorneyAscending()

  orderAttorneyStatement() {
    switch (this.orderBy) {
      case FIELD_ADVOCATE:
        this.advocateDetailStatementHandle.sort((a, b) => {
        if (this.sortAttorneyAscending) {
          return a.data.ProviderName.toUpperCase().localeCompare(b.data.ProviderName.toUpperCase());
        }
        return b.data.ProviderName.toUpperCase().localeCompare(a.data.ProviderName.toUpperCase());
      });
      break;

      case FIELD_EMAIL:
        this.advocateDetailStatementHandle.sort((a, b) => {
          if (this.sortAttorneyAscending) {
            return a.data.Email.toUpperCase().localeCompare(b.data.Email.toUpperCase());
          }
          return b.data.Email.toUpperCase().localeCompare(a.data.Email.toUpperCase());
        });
      break;

      case FIELD_CELL:
        this.advocateDetailStatementHandle.sort((a, b) => {
          if (this.sortAttorneyAscending) {
            return a.data.Phone.toUpperCase().localeCompare(b.data.Phone.toUpperCase());
          }
          return b.data.Phone.toUpperCase().localeCompare(a.data.Phone.toUpperCase());
        });
      break;

      case FIELD_MATTERS:
        this.advocateDetailStatementHandle.sort((a, b) => {
          if (this.sortAttorneyAscending) {
            return a.data.CurrentMatters - b.data.CurrentMatters;
          }
          return b.data.CurrentMatters - a.data.CurrentMatters;
        });
      break;

      case FIELD_OUTSTANDING:
        this.advocateDetailStatementHandle.sort((a, b) => {
          if (this.sortAttorneyAscending) {
            return a.data.TotalOutstanding - b.data.TotalOutstanding;
          }
          return b.data.TotalOutstanding - a.data.TotalOutstanding;
        });
      break;
    }
  } // end orderAttorneyStatement()

  convertToCurrency(amount: string): string {
    let amountValue = '';
    if (this.userProfileService.userProfile.isImpersonator) {
      amountValue = this.cpipe.transform(amount,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      amountValue = this.cpipe.transform(amount,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
    return amountValue;
  } // end convertToCurrency()

  onGenerateExcel(event) {
    this.loadingService.showOverlay();
    if (event === 'generate-excel') {
      // Bind data
      this.advocateDetailStatement.forEach(data => {
          const exportDataModal = {
            'Advocate Name': data.ProviderFullName,
            'Invoice Date': moment(data.InvoiceDate).format('YYYY-MM-DD'),
            'Invoice Number': data.InvoiceNo,
            'Matter Description': data.ServiceDescription,
            'Reference': data.Reference,
            'Attorney Name': data.AttorneyName,
            'Due Amount': this.convertToCurrency(data.Due.toString()),
            'Paid Amount': this.convertToCurrency(data.Paid.toString()),
            'Total Amount': this.convertToCurrency(data.Total.toString()),
            'Terms': data.PaymentTerms,
            'Total Days Outstanding': data.TotalDaysOverdue,
          };
          this.exportStatement.push(exportDataModal);
      });
      this.http.post(this.excelURL, this.exportStatement, {
        responseType: 'arraybuffer'
      }).subscribe({ next: (response) => this.advocateDetailStatementService.downloadExcelFile(response,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        , error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
        }
      });
    } else {
      this.loadingService.hideOverlay();
    }
  } // end onGenerateExcel()

  getBarWidth(value: number): string {
    let width = '0.95';
    if (value >= 1) {
      width = (value * 0.95).toString();
    }
    return width + '%';
  }

  getBackgroundColor(type: string): string {
    if (type.toLowerCase() === 'all') {
      return '#8abd37';
    }
  }
}
