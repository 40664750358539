<div class="container-fluid">
    <img src="https://auxconrsadev.blob.core.windows.net/imagestore/auxcon_logo.png" style="padding-left:3%;">
    <hr>
    <div class="contentCenter">
        <div style="float: right; margin-bottom: 10px;">
            <button *ngIf="!isGCBDeleteRequest || !isFileExist" class="btn btn-info nav-button" type="cancel" (click)="downloadBlackListPDF()"
                style="background: #17A2B8;">
                <i class="fa fa-download" aria-hidden="true"></i> Download</button>
            <button *ngIf="!isGCBDeleteRequest || !isFileExist" class="btn nav-button printButton" type="cancel" (click)="printPdf()" 
            style="background: #1B6EC2;">
                <i class="fa fa-print" aria-hidden="true"></i> Print</button>
            <button *ngIf="!isGCBDeleteRequest" class="btn btn-success nav-button" type="cancel" (click)="onShowApproveChangesPrompt('Approved')"
                style="background: #28A745;">
                <i class="fa fa-check" aria-hidden="true"></i> Approve</button>
            <button *ngIf="!isGCBDeleteRequest" class="btn btn-danger nav-button" type="cancel" (click)="onShowRejectChangesPrompt('Rejected')"
                style="background: #DC3545;">
                <i class="fa fa-times" aria-hidden="true"></i> Reject</button>
            <button *ngIf="isGCBDeleteRequest" class="btn btn-success nav-button" type="cancel" (click)="onShowGCBReqApproveChangesPrompt('Approved')"
                style="background: #28A745;">
                <i class="fa fa-check" aria-hidden="true"></i> Approve</button>
            <button *ngIf="isGCBDeleteRequest" class="btn btn-danger nav-button" type="cancel" (click)="onShowGCBReqRejectChangesPrompt('Rejected')"
                style="background: #DC3545;">
                <i class="fa fa-times" aria-hidden="true"></i> Reject</button>
        </div>
        <div  style="font-size: 15px;">
            <span *ngIf="isGCBDeleteRequest"> <b>Reason for removal: </b>{{gcbNotes}}</span>
        </div>
        <div class="file" id="fileContainer">
            <div style="width:100%; height:585px;">
                <ng2-pdfjs-viewer #pdfViewer 
                [externalWindow]="false" 
                [openFile]="true" 
                [viewBookmark]="true"
                [download]="true" 
                [downloadFileName]="fileName"  
                [print]="true" ></ng2-pdfjs-viewer>
            </div>
        </div>
    </div>
</div> <!-- #end (click)="downloadPDF()".container-fluid -->
​
<app-prompt-unsaved-changes-modal title="Please confirm" message="Are you sure you want to approve this request ?"
    btnTextCancel="Cancel" btnTextContinue="Confirm" [isVisible]="showApproveChangesPrompt"
    (cancel)="onHideApproveChangesPrompt()" (continue)="onActionClickEvent()">
</app-prompt-unsaved-changes-modal>
<app-prompt-unsaved-changes-modal title="Please confirm" message="Are you sure you want to reject this request ?"
    btnTextCancel="Cancel" btnTextContinue="Confirm" [isVisible]="showRejectChangesPrompt"
    (cancel)="onHideRejectChangesPrompt()" (continue)="onActionClickEvent()">
</app-prompt-unsaved-changes-modal>
<app-prompt-unsaved-changes-modal title="Please confirm" message="Are you sure you want to approve this request ?"
    btnTextCancel="Cancel" btnTextContinue="Confirm" [isVisible]="showApproveGCBReqChangesPrompt"
    (cancel)="onHideGCBApproveChangesPrompt()" (continue)="onActionGCBDeleteReqClickEvent()">
</app-prompt-unsaved-changes-modal>
<app-prompt-unsaved-changes-modal title="Please confirm" message="Are you sure you want to reject this request ?"
    btnTextCancel="Cancel" btnTextContinue="Confirm" [isVisible]="showRejectGCBReqChangesPrompt"
    (cancel)="onHideGCBRejectChangesPrompt()" (continue)="onActionGCBDeleteReqClickEvent()">
</app-prompt-unsaved-changes-modal>