<div class="modal" *ngIf="isVisible">
    <div class="modal-content" [class.model-width]="type !== 'Exclude'">
        <div class="modal-dialog">
            <div class="modal-header">
                <h2>{{title}}</h2>
                <button class="modal-dismiss" (click)="dismiss()">
                    <i class="fal fa-times-circle"></i>
                </button>
            </div>
            <div class="modal-body p-0  form">
                <div class="row p-2">
                    <div class="row  heading" [class.font-weight]="type !== 'Exclude'">
                        <label for="comments">{{message}}</label>
                    </div>
                    <div class="row p-0">
                        <div class="form-group" *ngIf="type !== 'LawFirm' ">
                            <textarea type="text" [id]="comments" [(ngModel)]="comments"
                                (keyup)="validate()"></textarea>
                        </div>

                        <div class="form-group" *ngIf="type == 'LawFirm' ">
                            <div class="input-group">
                                <input [(ngModel)]="lawFirmName" name="lawFirm" [typeahead]="lawFirms"
                                    typeaheadOptionField="FullName" [typeaheadSingleWords]="false"
                                    (input)="onInput($event)" class="form-control" placeholder="Select or start typing"
                                    (typeaheadOnSelect)="onChangeLawFirm($event)" [typeaheadOptionsLimit]="50"
                                    typeaheadScrollable=true typeaheadOptionsInScrollableView="10"
                                    [typeaheadMinLength]="0" [dropup]="true" autocomplete="off" required>
                            </div>
                        </div>                        
                    </div>
                    <div class="row p-0" *ngIf="type == 'LawFirm' ">
                        <div class="form-group">
                            <label for="category">Category</label>
                            <select
                                class="form-control"
                                id="category"
                                [(ngModel)]="Category">
                                <option [value]="null">Choose a category</option>
                                <option *ngFor="let category of categories"
                                [value]="category.value">{{category.label}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success" [class.disabled]="!isConfirm" (click)="confirm()"
                    [disabled]="!isConfirm">{{confirmText}}</button>
            </div>
        </div>
    </div>
    <!--#end .modal-content -->
</div>
<!--#end .modal -->