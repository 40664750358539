import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import {
  NotesService,
  NoteCategory,
  NoteTypes,
  NotesObject,
  MultiInvoiceNote,
  DEFAULT_NOTE_CATEGORY,
  DEFAULT_NOTE_TYPE,
  MultiObjectNote,
  Notes,
  DEFAULT_NOTE,
  DEFAULT_MULTI_OBJECT_NOTE,
  NotesObjectHandle,
  InstructionResponses,
  DEFAULT_INSTRUCTION_RESPONSES,
} from '../../../services/notes/notes.service';
import {
  SnackbarsService,
} from '../../../services/messaging/snackbars/snackbars.service';
import {
  UserProfileService,
} from '../../../services/user-profile/user-profile.service';
// import { TypeaheadMatch } from 'ngx-bootstrap';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { InvoiceDetails, DEFAULT_INVOICE_DETAILS, InvoiceDetailsService } from '../../../services/invoice-details/invoice-details.service';
import { LawfirmSummaryOverview } from '../../../services/reports/reports.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-multiple-invoice-note',
  templateUrl: './multiple-invoice-note.component.html',
  styleUrls: ['./multiple-invoice-note.component.scss']
})
export class MultipleInvoiceNoteComponent implements OnInit {
  title = 'Note detail for multiple invoices';
  isInternalChecked: boolean;
  isExternalChecked: boolean;
  selectedNoteCategoryText: string;
  selectedNoteSubCategoryText: string;
  selectedNoteCategory: NoteCategory;
  selectedNoteSubCategory: NoteTypes;
  selectedActionDate: string;
  internalExternalText: string;
  internalText: string;
  resolutionText: string;
  noteSubCategoryList: NoteTypes[] = [];

  multiObjectNote: MultiObjectNote;
  selectedNote: Notes;
  collectorNewChecked: boolean;
  advocateNewChecked = true;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  // Invoice Note
  selectedTab: string;
  selectedInvoiceId = 0;
  // notes
  notes: NotesObject[] = [];
  showProformaInvoice: boolean;
  onShowNoteDetail: boolean;
  invoiceDetails: InvoiceDetails;
  invoicePlaintiff: string;
  invoiceDefendant: string;
  lawfirmSummaryData: LawfirmSummaryOverview;
  selectedNotesHandle: NotesObjectHandle[] = [];

  instructionText = '';
  selectedResponseText: string;
  selectedResponse: InstructionResponses;
  InstructionRequestButtonText: string;

  @Input() isVisible: boolean;
  @Input() objectType: string;
  @Input() isAdHocCollections: boolean;
  @Input() isAdHocRequest: boolean;
  @Input() lawfirmName: string;
  @Input() noteDetailData: NotesObject;
  @Input() noteCategory: NoteCategory[];
  @Input() multiInvoiceNote: MultiInvoiceNote[];
  @Input() multipleObjectNotes: NotesObject[];
  @Input() instructionResponses: InstructionResponses[];
  @Output() closemodal: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateNote: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveMultiNote: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveInstructionMultiNote: EventEmitter<any> = new EventEmitter<any>();

  get isExternalInternal(): boolean {
    let isExternal = false;
    if (this.noteDetailData && this.noteDetailData.PriorityText.length > 0) {
      isExternal = true;
    } else {
      isExternal = false;
    }
    return isExternal;
  } // end isExternalInternal()

  get isSaveable(): boolean{
    return Boolean(this.selectedResponse && this.selectedResponse.Id > 0)
  }

  get currencySymbol(): string {
    if (!this.userProfileService.userProfile)
      return 'R';
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  // Is Practice Manager
  get isPracticeManager(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  }  // end isPracticeManager()

  constructor(
    private noteService: NotesService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private invoiceDetailService: InvoiceDetailsService
  ) { } // end constructor()

  ngOnInit() {
    this.InstructionRequestButtonText = 'Save Instr.';
    if (this.lawfirmName) {
      this.title = 'Note detail on multiple invoices for ' + this.lawfirmName;
    }
    if (this.isAdHocCollections) {
      this.title = 'Ad-Hoc Request Invoices';
      // this.InstructionRequestButtonText = 'Ad-Hoc';
      this.InstructionRequestButtonText = 'New Ad-Hoc Collection Request';
      this.collectorNewChecked = this.isAdHocCollections;
      this.advocateNewChecked = !this.isAdHocCollections;
    }

    if (this.isAdHocRequest) {
      this.collectorNewChecked = this.isAdHocRequest;
      this.advocateNewChecked = !this.isAdHocRequest;
      if (this.noteDetailData && this.noteDetailData.Id > 0) {
        this.noteDetailData.Category = '';
        this.noteDetailData.InstructionResponseId = 0;
        this.noteDetailData.InstructionText = '';
        this.noteDetailData.NoteTypeId = 0;
      }
    }
  } // end ngOnInit()

  onCancel() {
    this.closemodal.emit();
  } // end onCancel()

  toggleNewColAdvCheckbox(value: string) {
    switch(value) {
      case 'collector':
        this.advocateNewChecked = false;
        this.collectorNewChecked = true;
      break;

      case 'advocate':
        this.advocateNewChecked = true;
        this.collectorNewChecked = false;
      break;
    }
  } // end toggleNewColAdvCheckbox()

  onInputResponse() {
    // To do, Add invoice details and law firm summary view html view of this component.
  } // end onInputResponse()

  onNoteResponseSelect(event: TypeaheadMatch): void {
    if (event) {
      this.selectedResponse = Object.assign({}, DEFAULT_INSTRUCTION_RESPONSES);
      this.selectedResponse = event.item;
      this.selectedResponseText = event.item.Response;
    }
  } // end onNoteResponseSelect()

  onInvoiceRemove(data: MultiInvoiceNote) {
    if (this.noteDetailData && this.noteDetailData.Id > 0) {
      data.PracticeManagerStatus = 'Removed';
      //  this.noteService.putMultiInvoiceObject(data).toPromise();
      const invoices = this.multiInvoiceNote.filter(_data => _data !== data);
      this.multiInvoiceNote = invoices;
      this.loadingService.hideOverlay();
    } else {
      const invoices = this.multiInvoiceNote.filter(_data => _data !== data);
      this.multiInvoiceNote = invoices;
    }
  } // end onInvoiceRemove()

  onInputCategory(event) {
    if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
      // this.selectedNoteCategoryText = '';
      this.selectedNoteCategory = Object.assign({}, DEFAULT_NOTE_CATEGORY);
      this.selectedNoteSubCategoryText = '';
      this.internalExternalText = '';
      this.selectedNoteSubCategory = Object.assign({}, DEFAULT_NOTE_TYPE);
      this.noteSubCategoryList = [];
    }
  } // end onInputCategory()

  onViewInvoice(note: MultiInvoiceNote) {
      if (note.InvoiceId) {
        this.loadingService.showOverlay();
        this.notes = [];
        this.selectedNotesHandle = [];
        this.selectedTab = 'invoice';
        if (note.InvoiceId) {
          let _note = [];
          if (this.isPracticeManager) {
            // _note =  this.noteService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
            //   note.InvoiceId, 4).toPromise();
          } else if (!this.isPracticeManager) {
            // _note =  this.noteService.getNotes(this.userProfileService.userProfile.serviceProviderID,
            //   note.InvoiceId, 4).toPromise();
          }
          if (!this.isPracticeManager) {
            this.notes = _note.filter(x => x.QuestionText);
          } else {
            this.notes = _note;
          }
          this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
          // this.showProformaInvoice = true;
          // this.invoiceDetails =  this.invoiceDetailService.getInvoiceDetails(note.InvoiceId).toPromise();
          this.selectedInvoiceId = note.InvoiceId;

          if (this.invoiceDetails) {
            const desc = this.invoiceDetails.InvoiceParties.split(' // ');
            if (desc.length > 0) {
              this.invoicePlaintiff = desc[0];
            }
            if (desc.length > 1) {
              this.invoiceDefendant = desc[1];
            }
          }

          this.notes.forEach(_notes => {
            this.selectedNotesHandle.push({
              data: _notes,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          });
        }
        this.showProformaInvoice = true;
        this.loadingService.hideOverlay();
      }
  } // end onViewInvoice()

  closeModal() {
    this.showProformaInvoice = false;
  } // end closeModal()

  onInputSubCategory(event) {
    if (event.inputType === 'deleteContdeleteContentBackwardentBackward' || Boolean(event.target.value.length < 3)) {
      // this.selectedNoteSubCategoryText = '';
      this.internalExternalText = '';
      this.selectedNoteSubCategory = Object.assign({}, DEFAULT_NOTE_TYPE);
    }
  } // end onInputSubCategory()

  toggleIntExtCheckbox(clicked: string) {
    this.isInternalChecked = !this.isInternalChecked;
  } // end toggleIntExtCheckbox()

  /**
   * Called when the date value has changed.
   * @param {Date} value The new date value.
   */
  onDateValueChange(value: Date): void {
    this.selectedActionDate = value.toDateString();
  } // end onDateValueChange()

  onNoteCategorySelect(event: TypeaheadMatch): void {
    if (event) {
      this.selectedNoteCategory = Object.assign({}, DEFAULT_NOTE_CATEGORY);
      this.selectedNoteCategory = event.item;
      this.selectedNoteCategoryText = event.item.Category;
      this.onGetNoteSubCategory(event.item.Id);
    }
  } // end onNoteCategorySelect()

  onNoteSubCategorySelect(event: TypeaheadMatch): void {
    if (event) {
      this.selectedNoteSubCategory = Object.assign({}, DEFAULT_NOTE_TYPE);
      this.selectedNoteSubCategory = event.item;
      this.selectedNoteSubCategoryText = event.item.Name;
      this.internalExternalText = event.item.Description;
    }
  } // end onNoteSubCategorySelect()

  getRemovedDataColor(data: MultiInvoiceNote): string {
    if (data.PracticeManagerStatus === 'Active' && data.AdvocateStatus === 'Removed') {
      return '#ffcccc';
    }
  } // end getRemovedDataColor()

  onGetNoteSubCategory(categoryId: number) {
    this.noteService.getNoteSubCategory(categoryId).subscribe({ next: (data) => {
      // On next
      this.noteSubCategoryList = data;
    },
    error: (error) => {
      // On error
    },
    complete: () => {
      // On complete
      this.noteSubCategoryList.sort((a, b) => {
        return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase());
      });
    }
  });
  } // end onGetNoteSubCategory()

  onAddNote() {
    this.loadingService.showOverlay();
    this.multiObjectNote = Object.assign({}, DEFAULT_MULTI_OBJECT_NOTE);
    this.selectedNote = Object.assign({}, DEFAULT_NOTE);
    this.selectedNote.ObjectId = this.noteDetailData.ObjectId;
    this.selectedNote.Subject = this.selectedNoteSubCategoryText;
    this.selectedNote.NoteTypeId = this.selectedNoteSubCategory.Id;
    if (this.selectedNoteSubCategory.Personal) {
      this.selectedNote.InternalText = this.internalExternalText;
    } else if (!this.selectedNoteSubCategory.Personal) {
      this.selectedNote.ExternalText = this.internalExternalText;
    }
    if (this.isInternalChecked) {
      this.selectedNote.InternalText = this.internalText;
    }
    this.selectedNote.UserAssigned = this.userProfileService.selectedUserProfile.serviceProviderID;
    this.selectedNote.UserOwner = this.userProfileService.userProfile.serviceProviderID;
    this.selectedNote.UserCreator = this.userProfileService.userProfile.serviceProviderID;
    this.selectedNote.Priority = this.selectedNoteSubCategory.DefaultPriority;
    this.selectedNote.CaptureDate = moment().format('YYYY-MM-DD HH:mm:ss');
    if (this.selectedActionDate) {
      this.selectedNote.ActionDate = moment(this.selectedActionDate).format('YYYY-MM-DD HH:mm:ss');
    }
    this.multiObjectNote.Notes = this.selectedNote;
    this.multiObjectNote.Lawfirm = this.lawfirmName;
    this.multiObjectNote.MultiInvoiceNote = this.multiInvoiceNote;
    this.saveMultiNote.emit(this.multiObjectNote);
    // this.loadingService.hideOverlay();
  } // end onAddNote()

  onIssueAnInstructionRequest() {
    this.loadingService.showOverlay();
    this.multiObjectNote = Object.assign({}, DEFAULT_MULTI_OBJECT_NOTE);
    this.selectedNote = Object.assign({}, DEFAULT_NOTE);
    this.selectedNote.ObjectId = this.noteDetailData.ObjectId;
    this.selectedNote.Subject = 'Instruction from PM';
    this.selectedNote.NoteTypeId = 306;

    
    this.selectedNote.InstructionText = this.internalExternalText;
    
    this.selectedNote.InstructionResponseId = this.selectedResponse.Id;
    if (this.noteDetailData && this.noteDetailData.UserAssigned > 0) {
        this.selectedNote.UserAssigned = this.noteDetailData.UserAssigned;
        this.selectedNote.UserOwner = this.noteDetailData.UserOwner;;
        this.selectedNote.UserCreator = this.noteDetailData.UserCreator;
        this.selectedNote.Id = this.noteDetailData.Id;
        this.selectedNote.MutliObjects = this.noteDetailData.MutliObjects;
      } else {
        if (this.isPracticeManager)
        {
          this.selectedNote.UserAssigned = this.userProfileService.selectedUserProfile.serviceProviderID
          this.selectedNote.UserOwner = this.userProfileService.userProfile.serviceProviderID;
          this.selectedNote.UserCreator = this.userProfileService.userProfile.serviceProviderID;
        } else {
          this.selectedNote.UserAssigned = this.userProfileService.userProfile.serviceProviderID
          this.selectedNote.UserOwner = this.userProfileService.userProfile.serviceProviderID;
          this.selectedNote.UserCreator = this.userProfileService.userProfile.serviceProviderID;
        }
    }
    
    // else {
      //   this.selectedNote.UserAssigned = this.userProfileService.selectedUserProfile.serviceProviderID;
      // }
      this.selectedNote.Priority = 1;
      this.selectedNote.CaptureDate = moment().format('YYYY-MM-DD HH:mm:ss');
      this.selectedNote.InstructionCaptureDate = moment().format('YYYY-MM-DD HH:mm:ss');
      
      this.multiObjectNote.Notes = this.selectedNote;
      this.multiObjectNote.Lawfirm = this.lawfirmName;
      this.multiObjectNote.MultiInvoiceNote = this.multiInvoiceNote;
      
    this.saveInstructionMultiNote.emit(this.multiObjectNote);

  } // end onIssueAnInstructionRequest()

  onResolutionSave() {
    this.loadingService.showOverlay();
    if (this.noteDetailData.MutliObjects) {
      this.multipleObjectNotes.forEach(data => {
        const _removed = this.multiInvoiceNote.filter(x => x.AdvocateStatus === 'Removed');
        if (_removed.filter(c => c.InvoiceId === data.ObjectId).length > 0) {

        } else {
          const noteToSave: Notes = {
            Id: data.Id,
            ObjectId: data.ObjectId,
            Subject: this.noteDetailData.Subject,
            NoteTypeId: this.noteDetailData.NoteTypeId,
            InternalText: this.noteDetailData.InternalText,
            ExternalText: this.noteDetailData.ExternalText,
            UserOwner: this.noteDetailData.UserOwner,
            UserCreator: this.noteDetailData.UserCreator,
            UserAssigned: this.noteDetailData.UserAssigned,
            Priority: this.noteDetailData.Priority,
            CaptureDate: this.noteDetailData.CaptureDate,
            ActionDate: this.noteDetailData.ActionDate,
            ActivityDate: this.noteDetailData.ActivityDate,
            InstructionResponseId: this.noteDetailData.InstructionResponseId,
            InstructionText: this.noteDetailData.InstructionText,
            ResolutionText: this.resolutionText,
            InternalInstructionNote: this.noteDetailData.InternalInstructionNote,
            MutliObjects: data.MutliObjects,
            InstructionCaptureDate: data.InstructionCaptureDate,
            ResolutionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
            IsVisibleUserOwner: false,
      IsVisibleUserCreator: false,
      IsVisibleUserAssigned: true
          };
          this.noteDetailData.UserCreatorStatus = true;
          this.noteDetailData.UserAssignedStatus = false;
          this.noteDetailData.UserOwnerStatus = false;
          //  this.noteService.putNoteInboxStatus(this.noteDetailData).toPromise();
          //  this.noteService.putNote(noteToSave).toPromise();
        }
      });
      this.updateNote.emit();
      this.loadingService.hideOverlay();
    }
  } // end onResolutionSave()
} // end MultipleInvoiceNoteComponent{}
