import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export interface StatementAdvocateSummary {
  zeroDays: number;
  thirtyDays: number;
  sixtyDays: number;
  ninetyDays: number;
  ninetyEightDays: number;
  invoiceStatusGroup: number;
  statementByAdvocate: StatementByAdvocate[];
} // end StatementAdvocateSummary{}

export interface StatementByAdvocate {
  ServiceProviderId: number;
  ClientId: number;
  AttorneyId: number;
  ProviderName: string;
  Email: string;
  Phone: string;
  ZeroDaysOutstanding: number;
  ThirtyDaysOutstanding: number;
  SixtyDaysOutstanding: number;
  NinetyDaysOutstanding: number;
  NinetyEightDaysOutstanding: number;
  InvoiceStatusGroupOutstanding: number;
  CurrentMatters: number;
  TotalOutstanding: number;
  OutstandingPercentage: number;
}

export const DEFAULT_STATEMENT_ADVOCATE_SUMMARY = {
  serviceProviderId: 0,
  clientId: 0,
  providerName: '',
  email: '',
  phone: '',
  zeroDaysOutstanding: 0,
  thirtyDaysOutstanding: 0,
  sixtyDaysOutstanding: 0,
  ninetyDaysOutstanding: 0,
  ninetyEightDaysOutstanding: 0,
  currentMatters: 0,
  totalOutstanding: 0
}; // end DEFAULT_STATEMENT_ADVOCATE_SUMMARY{}

export interface StatementInvoiceDetails {
  invoiceId: number;
  invoiceAmount: number;
  invoiceDate: string;
  terms: string;
  paymentDate: string;
  totalPaymentAmount: number;
  amountOutstanding: number;
} // end StatementInvoiceDetails{}

export const DEFAULT_STATEMENT_INVOICE_DETAILS = {
  invoiceId: 0,
  invoiceAmount: 0,
  invoiceDate: '',
  terms: '',
  paymentDate: '',
  totalOutstanding: 0,
  amountOutstanding: 0
}; // end DEFAULT_STATEMENT_INVOICE_DETAILS{}

export interface StatementMatterDetails {
  advocate: string;
  reference: string;
  plaintiff: string;
  defendant: string;
} // end StatementMatterDetails{}

export const DEFAULT_STATEMENT_MATTER_DETAILS = {
  advocate: '',
  reference: '',
  plaintiff: '',
  defendant: ''
}; // end DEFAULT_STATEMENT_MATTER_DETAILS{}

export interface Statement {
  id: number;
  advocateName: string;
  type: string;
  cellularNumber: string;
  currentMatters: number;
  totalOutstanding: number;
} // end Statement{}

export const DEFAULT_STATEMENT = {
  id: 0,
  advocate: '',
  type: '',
  cellularNumber: '',
  currentMatters: 0,
  totalOutstanding: 0
}; // end DEFAULT_STATEMENT{}

export interface InvoiceStatement {
  invoiceId: number;
  invoiceNo: string;
  invoiceDate: string;
  invoiceReference: string;
  matterId: number;
  matterDescriptiuon: string;
  attorney: string;
  advocate: string;
  invoiceAmount: number;
  paymentAmount: number;
  paymentStatus: string;
} // end InvoiceStatement{}

export const DEFAULT_INVOICE_STATEMENT = {
  invoiceId: 0,
  invoiceNo: '',
  invoiceDate: '',
  invoiceReference: '',
  matterId: 0,
  matterDescriptiuon: '',
  attorney: '',
  advocate: '',
  invoiceAmount: 0,
  paymentAmount: 0,
  paymentStatus: ''
}; // end DEFAULT_INVOICE_STATEMENT{}

export interface ThreeMonthsOutstandingLawFirm {
  Id: number;
  ThisMonthDue: number;
  ThisMonthDueSummary: number;
  ThisMonth: number;
  ThisMonthYear: number;
  LastMonthDue: number;
  LastMonthDueSummary: number;
  LastMonth: number;
  LastMonthYear: number;
  TotalToPayNextMonth: number;
  ToBeCollectedMonth: number;
  ToBeCollectedYear: number;
  TotalToPayNextMonthSummary: number;
}

export const DEFAULT_THREE_MONTHS_OUTSTANDING: ThreeMonthsOutstandingLawFirm = {
  Id: 0,
  ThisMonthDue: 0,
  ThisMonthDueSummary: 0,
  ThisMonth: 0,
  ThisMonthYear: 0,
  LastMonthDue: 0,
  LastMonthDueSummary: 0,
  LastMonth: 0,
  LastMonthYear: 0,
  TotalToPayNextMonth: 0,
  ToBeCollectedMonth: 0,
  ToBeCollectedYear: 0,
  TotalToPayNextMonthSummary: 0
};

export const INVOICE_STATEMENT_PAYMENT_STATUSES = [
  'Partial',
  'Full',
  '90 days'
]; // end INVOICE_STATEMENT_PAYMENT_STATUSES[]

@Injectable()
export class LiveStatementService {

   lawfirmId = 19934;

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { }

  /**
   * GET: Returns advocate list in a lawfirm
   * @returns {*} Returns all advocates in the lawfirm.
   */
  getAdvocateStatementAdvocateSummary(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.statementByAdvocate +
      '?ClientID=' + this.api.serviceProviderID,
      this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // getAdvocateStatementAdvocateSummary()

  getAdvocateStatementByStateAdvocateSummary(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.statementByStateAdvocate +
      '?ClientID=' + this.api.serviceProviderID,
      this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // getAdvocateStatementAdvocateSummary()

  getAdvocateStatementByStateAttorneySummary(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.statementByStateAttorney +
      '?ClientID=' + this.api.serviceProviderID,
      this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // getAdvocateStatementByStateAttorneySummary()

  getEscaletedQueries(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.lawfirmStatement + '/EscaletedQueries' +
      '?ClientID=' + this.api.serviceProviderID,
      this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // getEscaletedQueries()

  getNewInvoices(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.lawfirmStatement + '/NewInvoices' +
      '?ClientID=' + this.api.serviceProviderID,
      this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // getNewInvoices()

  getThreeMonthsLawfirmData(): Observable<any> {
    return this.http.get<any>(this.api.endpoints.threeMonthsLawfirmData +
      '?ClientId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  } // end getThreeMonthsLawfirmData()

} // end LiveStatementService{}
