<div *ngIf="isTargetMissed === true" class="row  widget3-header widget3-header2  text-white">
    <div class="col-11 header-text">In the last 4 months you missed your invoicing target {{invoicedMissed}} times and
        your
        collection target {{collectedMissed}} times.
    </div>
    <div class="col-1 fa-icon"><i title="Danger" class="fal fa-exclamation-circle"></i></div>
</div>

<div *ngIf="isTargetMissed === false" class="row  widget3-header widget3-header1 text-white">
    <div class="col-11 header-text">In the last 4 months you missed your invoicing target {{invoicedMissed}} times and
        your
        collection target {{collectedMissed}} times.
    </div>
    <div class="col-1 fa-icon"><i title="Healthy" class="fal fa-badge-check"></i></div>
</div>

<div class="row widget3-body" style="width: 100% !important;margin: 0 !important;">
    <div class="invoiced-collected-container">
        <div class="well p-0" style="background-color:#f5f6f6 !important; height: 0;">
            <div class="row p-0 m-0">
                <svg class="display-center" #timeScale id="timeScale1">
                </svg>
            </div>
            <div class="row col-md-2 offset-md-10 text-right">
                <u>View more ></u>
            </div>
        </div>
    </div>
</div>