import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ToastService } from '../messaging/toast/toast.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export interface OtherClients {
  Id: number;
  FullName: string;
  ContactID: number;
  EmailAddress: string;
  IDNumber: string;
  MobileNumber: string;
  WorkPhone: string;
  WorkFax: string;
  VATNumber: string;
  StreetAddressFull: string;
  Flag: boolean;
} // end OtherClients{}

export const DEFAULT_OTHERCLIENTS_TEMPLATE = {
  Id: 0,
  FullName: '',
  ContactID: 0,
  EmailAddress: '',
  IDNumber: '',
  MobileNumber: '',
  WorkPhone: '',
  WorkFax: '',
  VATNumber: '',
  StreetAddressFull: '',
  Flag: false
}; //  end DEFAULT_OTHERCLIENTS_TEMPLATE{}

@Injectable()
export class OtherClientsService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private toastService: ToastService
  ) { }

  getOtherClients(): Observable<any> {
    return this.http.get(this.api.endpoints.otherClients +
      '/' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getOtherClients()

  updateOtherClient(client: OtherClients): Observable<any> {
    return this.http.put(this.api.endpoints.otherClients +
      '?ServiceProviderID=' + this.api.serviceProviderID,
      client, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateOtherClient()

  DeleteClient(contactGroupID: number): Observable<any> {
    return this.http.put(this.api.endpoints.otherClients +
      '/DeleteClient?ServiceProviderID=' + this.api.serviceProviderID +
       '&contactGroupID=' + contactGroupID, null, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateOtherClient()

  createClient(client: OtherClients): Observable<any> {
    return this.http.post(this.api.endpoints.otherClients +
      '/OtherClients?ServiceProviderID=' + this.api.serviceProviderID,
      client, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end createClient()

  searchContacts(searchText: string): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.contactManager + this.api.endpoints.searchContact +
      '?searchString=' + searchText + '&ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end searchContacts()

} // end OtherClientsService{}
