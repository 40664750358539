<!-- Invoices available to add to this proof of payment -->
<div *ngIf="showUnPaid" class="row unpaid-container">
    <div class="row unpaid-header">
        Invoices available to add to this proof of payment
    </div>

    <div class="row unpaid-actions">
        <div class=" col-sm-2 m-auto">Actions</div>
        <div class=" col-sm-4 m-auto pl-0" [class.active]="showActions">
            <a (click)="postProofOfPaymentLines()"><i class="fal fa-file-plus"></i>
                <u>Add selected invoices</u>
            </a>
        </div>

        <div class=" col-sm-6 m-auto pl-0" >
            <a><i class="far fa-folder-plus"></i>
                <u>Add credit note against selected invoices</u>
            </a>
        </div>
    </div>

    <div class="row unpaid-search input-group">
        <input type="search"  class="form-control" tabindex="-1" placeholder="Search invoices" [(ngModel)]="searchTerm"
        (keyup)="onKeyDown($event)">
        <div class="input-group-append">
            <i *ngIf="!searchTerm" class="far fa-search"></i>
            <i *ngIf="searchTerm" (click)="clearSearch()" class="far fa-close"></i>
          </div>
    </div>
    <div class="row unpaid-body">
        <div class="table-responsive" style="max-height: 200px;">
            <table class="table ">
                <tbody>
                    <tr>
                        <th scope="row" style="text-align: left;">
                            <div class="checkbox">
                                <input
                                    #inputCheckbox
                                    type="checkbox"
                                    (change)="toggleSelectAll()">
                                <!-- <label for="u{{invoices.InvoiceId}}"></label> -->
                            </div>
                        </th>
                        <th
                        scope="col" style="min-width: 79px;"
                        (click)="toggleSort('date')">Date
                    <span style="float: rightl"><i class="fa fa-sort" [class.fa-sort-down]="sortDate"></i></span></th>
                        <th
                        scope="col"
                        (click)="toggleSort('invoice')">Invoice
                    <span style="float: rightl"><i class="fa fa-sort" [class.fa-sort-down]="sortInvoice"></i></span></th>
                        <th
                        scope="col"
                        (click)="toggleSort('lawfirm')">Law Firm
                    <span style="float: rightl"><i class="fa fa-sort" [class.fa-sort-down]="sortLawfirm"></i></span></th>
                        <th
                        scope="col"
                        (click)="toggleSort('matter')">Matter
                    <span style="float: rightl"><i class="fa fa-sort" [class.fa-sort-down]="sortMatter"></i></span></th>
                        <th
                        scope="col"
                        (click)="toggleSort('reference')">Reference
                    <span style="float: rightl"><i class="fa fa-sort" [class.fa-sort-down]="sortReference"></i></span></th>
                        <th
                        scope="col" style="min-width: 79px;"
                        (click)="toggleSort('amount')" class="text-right">Amount
                    <span style="float: rightl"><i class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i></span></th>
                        <th
                        scope="col" style="min-width: 79px;"
                        (click)="toggleSort('')" class="text-right">Paid</th>
                    </tr>
                    <tr *ngFor="let invoices of unPaidInvoiceHandles">
                        <td scope="row" *ngIf="invoices.state.isVisible">
                            <!-- <div class="checkbox">
                                <input type="checkbox" 
                                    (change)="onChangeCheckBox(invoices.data, $event.target.checked)"
                                    id="u{{invoices.data.InvoiceId}}">
                                <label for="u{{invoices.data.InvoiceId}}"></label>
                            </div> -->
                            <app-small-checkbox
                                [(checked)]="invoices.state.isSelected"
                                (click)="toggleCheckbox(invoices)"></app-small-checkbox>
                        </td>
                        <td *ngIf="invoices.state.isVisible" style="min-width: 79px;" (click)="toggleCheckbox(invoices)">{{invoices.data.Date | date:'yyyy-MM-dd'}}</td>
                        <td *ngIf="invoices.state.isVisible"><u class="clickable" (click)="onViewInvoice(invoices.data.InvoiceId)">{{invoices.data.InvoiceNo}}</u></td>
                        <td *ngIf="invoices.state.isVisible" (click)="toggleCheckbox(invoices)" class="text-truncate" [title]="invoices.data.Client">{{invoices.data.Client}}</td>
                        <td *ngIf="invoices.state.isVisible" (click)="toggleCheckbox(invoices)" class="text-truncate" [title]="invoices.data.Matter">{{invoices.data.Matter}}</td>
                        <td *ngIf="invoices.state.isVisible" (click)="toggleCheckbox(invoices)" class="text-truncate" [title]="invoices.data.Reference">{{invoices.data.Reference}}</td>
                        <td *ngIf="invoices.state.isVisible" style="min-width: 79px;" (click)="toggleCheckbox(invoices)" class="text-right">{{invoices.data.Amount  | currency:currencySymbol}}</td>
                        <td *ngIf="invoices.state.isVisible" style="min-width: 79px;" (click)="toggleCheckbox(invoices)" class="text-right">{{invoices.data.Paid  | currency:currencySymbol}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- <app-proforma-invoice 
    #proformaInvoice
    title="Invoice"
    popId="0"
    [invoiceId]="selectedInvoiceId" 
    documentType="Invoice"
    matter="null" 
    [isVisible]="showProformaInvoice" 
    (closeModal)="closeModal('proforma-invoice')">
</app-proforma-invoice> -->

<!-- <app-invoice-preview-modal 
    *ngIf="showProformaInvoice" 
    #invoicePreview 
    [plaintiff]="invoicePlaintiff"
    [defendant]="invoiceDefendant" 
    [invoiceId]="selectedInvoiceId" 
    [invoiceDetails]="invoiceDetails"
    [isVisible]="showProformaInvoice" 
    (closeModal)="closeModal('invoice')"></app-invoice-preview-modal> -->

<app-invoice-preview-modal
    *ngIf="showProformaInvoice" 
    #invoicePreview 
    [notesHandle]="notesHandle"
    [plaintiff]="invoicePlaintiff" 
    [selectedTab]="selectedTab" 
    [defendant]="invoiceDefendant"
    [invoiceId]="selectedInvoiceId" 
    [invoiceDetails]="invoiceDetails" 
    [isVisible]="showProformaInvoice"
    (closeModal)="closeModal('invoice')">
</app-invoice-preview-modal>