import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Advocates, DEFAULT_ADVOCATES } from '../../../services/advocates/advocates.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import {
  ContactRelationship,
  DEFAULT_CONTACT_RELATIONSHIP,
  AdvocateOverviewServiceService
} from '../../../services/advocate-overview-service/advocate-overview-service.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-add-relation-modal',
  templateUrl: './add-relation-modal.component.html',
  styleUrls: ['./add-relation-modal.component.scss']
})
export class AddRelationModalComponent implements OnInit {

  title = 'Add new relationship';
  selectedAdvocate: Advocates;
  businessType: any;
  contactRelationship: ContactRelationship = Object.assign({}, DEFAULT_CONTACT_RELATIONSHIP);
  selectedRelationshipStatus: string;
  selectedRelation: string;


  @Input() isVisible: boolean;
  @Input() advocates: Advocates[];
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveContact: EventEmitter<any> = new EventEmitter<any>();

  get isSaveable(): boolean {
    return Boolean(this.contactRelationship && this.contactRelationship.ChildId > 0) &&
            Boolean(this.selectedRelationshipStatus);
  } // end isSaveable()

  constructor(
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private advocateOverviewService: AdvocateOverviewServiceService
  ) { } // end constructor()

  ngOnInit() {
    this.businessType = [
      { label: 'Business Relationship', value: 'Business Relationship' }
    ];
  } // end ngOnInit()

  dismiss() {
    this.clearUI();
    this.cancel.emit();
  } // end dismiss()

  clearUI() {
    this.selectedRelationshipStatus = '';
    this.selectedRelation = '';
    this.selectedAdvocate = Object.assign({}, DEFAULT_ADVOCATES);
    this.contactRelationship = Object.assign({}, DEFAULT_CONTACT_RELATIONSHIP);
  } // end clearUI()

  onInput(event) {

  } // end onInput()

  onSelect(event: TypeaheadMatch): void {
    this.selectedAdvocate = Object.assign({}, DEFAULT_ADVOCATES);
    this.selectedAdvocate = event.item;
    this.contactRelationship = Object.assign({}, DEFAULT_CONTACT_RELATIONSHIP);
    // Set Selected object
    this.contactRelationship.ParentId = this.userProfileService.selectedUserProfile.serviceProviderID;
    this.contactRelationship.ChildId = this.selectedAdvocate.AdvocateId;
    this.contactRelationship.FullName = this.selectedAdvocate.Advocate;
    this.contactRelationship.Identity = this.selectedAdvocate.Identity;
    this.contactRelationship.UserName = this.selectedAdvocate.UserName;
    this.contactRelationship.Level = this.selectedAdvocate.Level;
    this.contactRelationship.CellPhone = this.selectedAdvocate.CellPhone;
    this.contactRelationship.WorkPhone = this.selectedAdvocate.WorkPhone;
    this.contactRelationship.Fax = this.selectedAdvocate.Fax;
    this.contactRelationship.Email = this.selectedAdvocate.Email;
    this.contactRelationship.VATNo = this.selectedAdvocate.VATNo;
    this.contactRelationship.OfficeRoom = this.selectedAdvocate.OfficeRoom;
    this.contactRelationship.StreetAddress = this.selectedAdvocate.StreetAddress;
    this.contactRelationship.PostalAddress = this.selectedAdvocate.PostalAddress;
    this.contactRelationship.Relationship = this.selectedRelationshipStatus;
    this.contactRelationship.RelationshipStatus = 'Active';
  } // end onSelect()

  onAdvocateChange() {

  } // end onAdvocateChange()

  onSave() {
    this.loadingService.showOverlay();
    this.advocateOverviewService.putAddNewRelationship(this.contactRelationship).subscribe({
      next: (response) => {
        // On next
      },
      error: (error) => {
        // on error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.saveContact.emit(this.contactRelationship);
        this.clearUI();
        this.loadingService.hideOverlay();
      }
    });
  } // end onSave()

  onCancel() {
    this.dismiss();
  } // end onCancel()
} // end AddRelationComponent{}
