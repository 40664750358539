import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BankAccountHandle } from '../../reconcile-bank-transaction/new-bank-transactions/new-bank-transactions.component';
import { BankAccounts } from '../../../services/bank-accounts/bank-accounts.service';

@Component({
  selector: 'app-bank-account-modal',
  templateUrl: './bank-account-modal.component.html',
  styleUrls: ['./bank-account-modal.component.scss']
})
export class BankAccountModalComponent implements OnInit {

  title = 'Select bank account';
  selectedAccount = '';

  @Input() isVisible: boolean;
  @Input() bankAccountHandle: BankAccountHandle[];
  @Input() selectedBankAccountHandle: BankAccounts;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();

  constructor() { } // end constructor()

  ngOnInit(): void {
  } // end ngOnInit()

  onChangeBankAccount(event) {
    this.selectedBankAccountHandle = this.bankAccountHandle.filter(x => x.data.AccountNumber == event.AccountNo)[0].data;
  } // end onChangeBankAccount()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onContinue() {
    this.continue.emit(this.selectedBankAccountHandle);
  } // end onContinue()
} // end BankAccountModalComponent()
