import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { Router } from '@angular/router';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

import {
  ResultItem
} from '../../../../pages/advocate-search/advocate-search.component';

import { PATH_MATTERS, PATH_INVOICE_PREVIEW, PATH_SEARCH_INVOICE_PREVIEW } from '../../../../services/appdata/app-config.service';
import { UserProfileService } from '../../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-search-results-table-row',
  templateUrl: './search-results-table-row.component.html',
  styleUrls: ['./search-results-table-row.component.scss'],
  animations: [
    trigger('detailState', [
      state('close', style({
        height: 0,
      })),
      state('open', style({
        height: 100,
      })),
      transition('close => open', animate('200ms ease-in')),
      transition('open => close', animate('200ms ease-out'))
    ])
  ]
})
export class SearchResultsTableRowComponent implements OnInit, OnChanges {

  showInvoice: boolean;
  @Input() resultItem: ResultItem;
  @Input() itemIndex: number;
  showDetails = false;
  title = 'Type';
  subtitle = 'Name';
  link: string;
  icon: any = {
    class: 'result-item-icon',
    text: 'I'
  };
  // downloadLink: string = 'https://auxconapicorev1-stagingauxconapi.azurewebsites.net/api/InvoiceDocument?invoiceID=';
  today: string;
  bgOffWhite = true;

  get currencySymbol(): string {
    return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
  } // end currencySymbol()

  get detailState() {
    return this.showDetails ? 'open' : 'close';
  }
  // private activeInvoiceHandle: ResultItem;
  constructor(
    private router: Router,
    private userProfileService: UserProfileService
  ) { } // end constructor()

  ngOnInit() {
    if (!this.resultItem) {
      this.resultItem = {
        Category: 'Service',
        SearchScore: 0,
        Results: null,
      };
    }
    this.today = new Date().toJSON();
  } // end ngOnInit()

  onViewInvoice(invoiceId: number) {
    this.router.navigate([PATH_SEARCH_INVOICE_PREVIEW + '/' + invoiceId]);
  } // end onViewInvoice()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resultItem && changes.resultItem.currentValue) {
      this.title = this.resultItem.Category;
      switch (this.title) {
        case 'Service':
          this.title = 'Matter';
          this.subtitle = this.resultItem.Results.Description;
          this.icon.class += ' matter-icon';
          this.icon.text = 'M';
          this.link = PATH_MATTERS;
          break;

        case 'Law Firm':
          this.subtitle = this.resultItem.Results.FullName;
          this.icon.class += ' law-firm-icon';
          this.icon.text = 'L';
          break;

        case 'Invoice':
          this.subtitle = this.resultItem.Results.ClientName;
          this.icon.class += ' invoice-icon';
          this.icon.text = 'I';
          // this.downloadLink += this.activeInvoiceHandle.Results.invoiceID;
          break;
      }
    }

    if (changes.itemIndex && changes.itemIndex.currentValue) {
      this.bgOffWhite = this.itemIndex === 0 || this.itemIndex % 2 === 0;
    }
  } // end ngOnChanges()

  /**
   * Hide or show result item details.
   */
  toggleDetails() {
    this.showDetails = !this.showDetails;
  //  this.downloadLink += this.resultItem.Results.Id;
    // this.showInvoice = true;
  } // end toggleDetails()

  onLinkClick() {
    if (this.link) {
      this.router.navigate([this.link, this.resultItem.Results.Id]);
    }
  } // end onLinkClick()

  closeModal(modal: string) {
    // TODO: Implement closeModal()
  } // end closeModal()
} // end Sear
