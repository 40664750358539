import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import {
  FinancialDetails,
  AdvocateOverviewServiceService,
  DEFAULT_FINANCIAL_DETAILS,
  FeeTariffs,
} from '../../../services/advocate-overview-service/advocate-overview-service.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import {
  BankAccounts,
  BankAccountsService,
  DEFAULT_BANK_ACCOUNT
} from '../../../services/bank-accounts/bank-accounts.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import {
  ActivityLogs,
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogsService
} from '../../../services/activity-logs/activity-logs.service';
import * as moment from 'moment';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-financial-details',
  templateUrl: './financial-details.component.html',
  styleUrls: ['./financial-details.component.scss']
})
export class FinancialDetailsComponent implements OnInit {
  // Payment Terms
  paymentTerms: any;
  financialStatus: any;
  vatBasedOn: any;
  // Assistant Permission
  assistantPermissions: any;
  // Account Type
  accountTypes: any;

  vatRegYes: boolean;
  vatRegNo: boolean;

  showAddEditBankAccount: boolean;
  addEditButtonText: string;
  selectedBankAccountHandle: BankAccounts = Object.assign({}, DEFAULT_BANK_ACCOUNT);
  addBankAccountHandle: BankAccounts = Object.assign({}, DEFAULT_BANK_ACCOUNT);

  showErrorMessagePrompt: boolean;
  showErrorDeletePrompt: boolean;
  deleteBankAccountHandle: BankAccounts = Object.assign({}, DEFAULT_BANK_ACCOUNT);

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  bankAccounts: BankAccounts[] = [];

  showFeeTariffsModal: boolean;
  feeTariffs: FeeTariffs[] = [];

  // vatSettingsStatus: boolean = false;
  showWorkInProgressPrompt: boolean;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  // @Input() financialDetails: FinancialDetails;
  // @Input() bankAccounts: BankAccounts[];

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  get internalAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile && this.userProfileService.userProfile.isImpersonator) &&
      !Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY)
      && Boolean(this.userProfileService.userProfile.serviceProviderID === 9091 ||
        this.userProfileService.userProfile.serviceProviderID === 29096 ||
        this.userProfileService.userProfile.serviceProviderID === 21647);
  } // end internalAssistant()

  get isPracticeManager(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  } // end isPracticeManager()

  get isAdvocate(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ADVOCATE);
  } // end isPracticeManager()

  checkInitialValidation: boolean = true;

  @Input() workingFinancialDetails: FinancialDetails;
  @ViewChild('inputHourly') focusHourly: ElementRef;
  @ViewChild('inputDaily') focusDaily: ElementRef;
  @ViewChild('inputPerKilometerRate') focusPerKilometerRate: ElementRef;
  @ViewChild('inputIncomeTarget') focusIncomeTarget: ElementRef;
  @ViewChild('inputFeeTarget') focusFeeTarget: ElementRef;
  @ViewChild('inputHourly') focusEffectiveTax: ElementRef;

  constructor(
    private api: ApiService,
    private cdr: ChangeDetectorRef,
    private cpipe: UsercurrencyPipe,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private bankAccountsService: BankAccountsService,
    private advocateOverviewService: AdvocateOverviewServiceService
  ) { } // end contructor()

  ngOnInit() {
    this.loadingService.showOverlay();
    this.getBankAccounts();

    this.paymentTerms = [
      { id: 0, name: '0 Days' },
      { id: 7, name: '7 Days' },
      { id: 30, name: '30 Days' },
      { id: 60, name: '60 Days' },
      { id: 90, name: '90 Days' },
      { id: 98, name: '98 Days' },
      { id: 99, name: 'Contingency' }
    ];

    this.vatBasedOn = [
      { id: 'VAT on Paid', name: 'VAT on invoices paid '},
      { id: 'VAT on Invoiced', name: 'VAT on invoices rendered'}
    ];

    if (this.api.serviceProviderID === 13553 || this.api.serviceProviderID === 13553) {
      this.paymentTerms.push(
        { id: 7, name: '7 Days' }
      )
      this.paymentTerms.sort((a, b) => {
        return a.id - b.id;
      })
    }

    this.assistantPermissions = [
      { value: 'Full', name: 'Full' },
      { value: 'Limited', name: 'Limited' }
    ];

    this.accountTypes = [
      { label: 'Cheque/Current', value: 'Cheque Account' },
      { label: 'Savings', value: 'Savings Account' },
      { label: 'Transmission', value: 'Transmission Account' }
    ];

    this.financialStatus = [
      { label: 'Healthy', value: 'Healthy' },
      { label: 'Neutral', value: 'Neutral' },
      { label: 'Danger', value: 'Danger' }
    ];
    this.loadingService.hideOverlay();
  } // end ngOnInit()

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  } // end ngAfterViewChecked()

  // changeVATSettingsStatus() {
  //   if (!this.vatSettingsStatus) {
  //     this.vatSettingsStatus = true;
  //   }
  // } // end changeVATSettingsStatus()

  parseDate(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return  new Date('1000-01-01');
    }
  } // end parseDate()

  parseVATDate(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return new Date();
    }
  } // end parseVATDate()

  onLastVATPeriodEndDateParse(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return new Date();
    }
  } // end onLastVATPeriodEndDateParse()

  onLastVATPeriodEndDateChange(value: Date): void {
    if (value) {
    this.workingFinancialDetails.LastVATPeriodEndDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
    }
  } // end onLastVATPeriodEndDateChange()


  onHourlyRateChange() {
    if(this.userProfileService.userProfile.isImpersonator) {
      this.focusHourly.nativeElement.value = 
        this.cpipe.transform(this.focusHourly.nativeElement.value,
      this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusHourly.nativeElement.value = 
        this.cpipe.transform(this.focusHourly.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onHourlyRateChange()
  onHourlyRateClicked() {
    if(this.userProfileService.userProfile.isImpersonator) {
    this.focusHourly.nativeElement.value = this.cpipe.parse(this.focusHourly.nativeElement.value,
      this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusHourly.nativeElement.value = this.cpipe.parse(this.focusHourly.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onHourlyRateClicked()
  onDailyRateChange() {
    if(this.userProfileService.userProfile.isImpersonator) {
    this.focusDaily.nativeElement.value = this.cpipe.transform(this.focusDaily.nativeElement.value,
      this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusDaily.nativeElement.value = this.cpipe.transform(this.focusDaily.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onDailyRateChange()
  onDailyRateClicked() {
    if(this.userProfileService.userProfile.isImpersonator) {
    this.focusDaily.nativeElement.value = this.cpipe.parse(this.focusDaily.nativeElement.value,
      this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusDaily.nativeElement.value = this.cpipe.parse(this.focusDaily.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onDailyRateClicked()

  onDefaultPerKilometerRateChange() {
    if(this.userProfileService.userProfile.isImpersonator) {
    this.focusPerKilometerRate.nativeElement.value = this.cpipe.transform(this.focusPerKilometerRate.nativeElement.value,
      this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusPerKilometerRate.nativeElement.value = this.cpipe.transform(this.focusPerKilometerRate.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onDefaultPerKilometerRateChange()
  onDefaultPerKilometerRateClicked() {
    if(this.userProfileService.userProfile.isImpersonator) {
    this.focusPerKilometerRate.nativeElement.value = this.cpipe.parse(this.focusPerKilometerRate.nativeElement.value,
      this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusPerKilometerRate.nativeElement.value = this.cpipe.parse(this.focusPerKilometerRate.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onDefaultPerKilometerRateClicked()
  onIncomeTargetChange() {
    if(this.userProfileService.userProfile.isImpersonator) {
    this.focusIncomeTarget.nativeElement.value = this.cpipe.transform(this.focusIncomeTarget.nativeElement.value,
      this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusIncomeTarget.nativeElement.value = this.cpipe.transform(this.focusIncomeTarget.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onIncomeTargetChange()
  onIncomeTargetClicked() {
    if(this.userProfileService.userProfile.isImpersonator) {
    this.focusIncomeTarget.nativeElement.value = this.cpipe.parse(this.focusIncomeTarget.nativeElement.value,
      this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusIncomeTarget.nativeElement.value = this.cpipe.parse(this.focusIncomeTarget.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onIncomeTargetClicked()
  onFeeTargetChange() {
    if(this.userProfileService.userProfile.isImpersonator) {
    this.focusFeeTarget.nativeElement.value = this.cpipe.transform(this.focusFeeTarget.nativeElement.value,
      this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusFeeTarget.nativeElement.value = this.cpipe.transform(this.focusFeeTarget.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    }
  } // end onFeeTargetChange()
  onFeeTargetClicked() {
    if(this.userProfileService.userProfile.isImpersonator) {
    this.focusFeeTarget.nativeElement.value = this.cpipe.parse(this.focusFeeTarget.nativeElement.value,
      this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusFeeTarget.nativeElement.value = this.cpipe.parse(this.focusFeeTarget.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onFeeTargetClicked()

  toggleVatReg() {
    this.workingFinancialDetails.IsVatRegistered = !this.workingFinancialDetails.IsVatRegistered;
    if(this.workingFinancialDetails.IsVatRegistered == true) {
      this.showWorkInProgressPrompt = true;
    }
  } // end toggleVatReg()

  onWorkInProgressClick() {
    this.showWorkInProgressPrompt = false;
  } // end onWorkInProgressClick()

  onHideWorkInProgressPrompt() {
    this.workingFinancialDetails.IsVatRegistered = !this.workingFinancialDetails.IsVatRegistered;
    this.showWorkInProgressPrompt = false;
  } // end onHideWorkInProgressPrompt()

  toggleHaveAccess() {
    this.workingFinancialDetails.HaveBankAccess = !this.workingFinancialDetails.HaveBankAccess;
  } // end toggleHaveAccess()

  onDateValueChange(value: Date): void {
    this.workingFinancialDetails.StatementRun = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  } // end onDateValueChange()

  onDateVATRegChange(value: Date): void {
    if (value) {
    this.workingFinancialDetails.VATRegistrationDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
    }
  } // end onDateVATRegChange()

  onEffectiveTaxChange() {
    this.focusEffectiveTax.nativeElement.value = this.focusEffectiveTax.nativeElement.value.replace(
      this.userProfileService.selectedUserProfile.currencyDetails.symbol, '\b\d+?.?\d+?%');
  } // end onEffectiveTaxChange()

  onEffectiveTaxClicked() {
    this.focusEffectiveTax.nativeElement.value = this.focusEffectiveTax.nativeElement.value.replace(
      this.focusEffectiveTax.nativeElement.value, /\d+% ?/g);
  } // end onEffectiveTaxClicked()

  toggleBankAccess(data: BankAccounts) {
    data.PracticeManagerAccess = !data.PracticeManagerAccess;
  } // end toggleBankAccess()

  closeModal(value: string) {
    this.showAddEditBankAccount = false;
  } // end closeModal()

  changeManualTransactionsStatus() {
    this.workingFinancialDetails.ManualTransaction = !this.workingFinancialDetails.ManualTransaction;
  } // end changeManualTransactionsStatus()

  onBankAccountSave(bank: BankAccounts) {
    this.addBankAccountHandle = bank;
    if (bank.SPAccType === 'Primary' && this.selectedBankAccountHandle.SPAccType !== bank.SPAccType ) {
      this.showErrorMessagePrompt = true;
    } else {
      this.loadingService.showOverlay();
      if (bank.Id === 0) {

        // Log activity Login
        const currentDate = new Date();
        this.activityLog.Action = 'Add Primary Bank Account';
        this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (manage-bank-account)';
        if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'BankAccount';
        this.activityLog.JsonData = JSON.stringify(bank);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});

        this.bankAccountsService.addBankAccount(bank).subscribe({
          next: (addedBank) => {
            this.getBankAccounts();
          },
          error: (error) => {
            this.loadingService.hideOverlay();
          },
          complete: () => {
            this.getBankAccounts();
            this.loadingService.hideOverlay();
          }
        });


        //   addedBank => {
        //   // On next
        // },
        //   error => {
        //     // On error
        //     this.loadingService.hideOverlay();
        //   },
        //   () => {
        //     // On complete
        //       this.getBankAccounts();
        //     this.loadingService.hideOverlay();
        //   });
      } else {
        if (bank.Id > 0) {

          // Log activity Login
          const currentDate = new Date();
          this.activityLog.Action = 'Add Bank Account';
          this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
          this.activityLog.LoggedApp = 'Web Application (manage-bank-account)';
          if (this.userProfileService.userProfile.isImpersonator) {
            this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
          } else {
            this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
          }
          this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
          this.activityLog.ActionTable = 'BankAccount';
          this.activityLog.JsonData = JSON.stringify(bank);
          this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});

          this.bankAccountsService.updateBankAccount(bank).subscribe({
            next: (bank_to_update) => {
              this.getBankAccounts();
            },
            error: (error) => {
              this.loadingService.hideOverlay();
            },
            complete: () => {
              // On complete
              this.getBankAccounts();
              this.loadingService.hideOverlay();
            }
          });

          // bank_to_update => {
          //   // On next
          // },
          //   error => {
          //     // On error
          //     this.loadingService.hideOverlay();
          //   },
          //   () => {
          //     // On complete
          //     this.getBankAccounts();
          //     this.loadingService.hideOverlay();
          //   });
        }
      }
    }
  } // end onBankAccountSave()

  onHideErrorMessagePrompt() {
    this.showErrorMessagePrompt = false;
  } // end onHideErrorMessagePrompt()

  onUpdateClick() {
    this.showErrorMessagePrompt = false;
    this.loadingService.showOverlay();
    if (isNaN(this.addBankAccountHandle.StatementReminderDay)) {
      this.addBankAccountHandle.StatementReminderDay = null;
    }
    if (this.addBankAccountHandle.Id === 0) {
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Add Bank Account';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (manage-bank-account)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'BankAccount';
      this.activityLog.JsonData = JSON.stringify(this.addBankAccountHandle);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});

      this.bankAccountsService.addBankAccount(this.addBankAccountHandle).subscribe({
        next: (addedBank) => {
          this.getBankAccounts();
        },
        error: (error) => {
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.getBankAccounts();
          this.loadingService.hideOverlay();
        }
      });


      // addedBank => {
      //   // On next
      // },
      //   error => {
      //     // On error
      //     this.loadingService.hideOverlay();
      //   },
      //   () => {
      //     // On complete
      //     this.getBankAccounts();
      //     this.loadingService.hideOverlay();
      //   });
    } else {
      if (this.addBankAccountHandle.Id > 0) {

        // Log activity Login
        const currentDate = new Date();
        this.activityLog.Action = 'Edit Bank Account';
        this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (manage-bank-account)';
        if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'BankAccount';
        this.activityLog.JsonData = JSON.stringify(this.addBankAccountHandle);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});

        this.bankAccountsService.updateBankAccount(this.addBankAccountHandle).subscribe({
          next: (bank_to_update) => {
            this.getBankAccounts();
          },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
          },
          complete: () => {
            // On complete
            // location.reload();
            this.getBankAccounts();
            this.addBankAccountHandle = Object.assign({}, DEFAULT_BANK_ACCOUNT);
            this.loadingService.hideOverlay();
          }
        });


        //   bank_to_update => {
        //   // On next
        // },
        //   error => {
        //     // On error
        //     this.loadingService.hideOverlay();
        //   },
        //   () => {
        //     // On complete
        //     // location.reload();
        //     this.getBankAccounts();
        //     this.addBankAccountHandle = Object.assign({}, DEFAULT_BANK_ACCOUNT);
        //     this.loadingService.hideOverlay();
        //   });
      }
    }
  } // end onUpdateClick()

  getBankAccounts() {
    this.bankAccounts = [];

    this.bankAccountsService.getBankAccounts().subscribe({
      next: (note) => {
        // On next
        if (note) {
          this.bankAccounts = note;
        }
      },
      error: (error) => {
        // On error
        // snack.label = 'Error loading sticky note.';
        // snack.type = SnackType.ERROR;
        // this.snackbarsService.dismiss().make(snack).show();
        // this.isLoadingProfileInfo = false;
        // this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        // this.workingStickyNote = Object.assign({}, this.contactStickyNote);
        // snack.label = 'Sticky note data loaded.';
        // snack.type = SnackType.SUCCESS;
        // this.snackbarsService.dismiss().make(snack).show();
        // this.isLoadingProfileInfo = false;
        // this.loadingService.hideOverlay();
      }
    });

    // this.bankAccounts = this.bankAccountsService.getBankAccounts().toPromise();
  } // end getBankAccounts()

  onAddNewBankAccount() {
    this.selectedBankAccountHandle = Object.assign({}, DEFAULT_BANK_ACCOUNT);
    this.addEditButtonText = 'Save';
    this.showAddEditBankAccount = true;
  } // end onAddNewBankAccount()

  onEditBankAccount(bankAccount: BankAccounts) {
    this.selectedBankAccountHandle = Object.assign({}, DEFAULT_BANK_ACCOUNT);
    this.selectedBankAccountHandle = bankAccount;
    this.addEditButtonText = 'Update';
    this.showAddEditBankAccount = true;
  } // end onEditBankAccount()

  onDeleteBankAccount(bankAccount: BankAccounts) {
    this.deleteBankAccountHandle = bankAccount;
    this.showErrorDeletePrompt = true;
  } // end onDeleteBankAccount()

  onDeleteClick() {
    this.showErrorDeletePrompt = false;
    this.loadingService.showOverlay();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete Bank Account';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (manage-bank-account)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'BankAccount';
    this.activityLog.JsonData = JSON.stringify(this.deleteBankAccountHandle);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (onnext) => {}});

    this.bankAccountsService.deleteBankAccount(this.deleteBankAccountHandle.Id).subscribe({ next: (_next) => {
      // On next
    },
    complete: () => {
      this.deleteBankAccountHandle = Object.assign({}, DEFAULT_BANK_ACCOUNT);
      this.getBankAccounts();
      this.loadingService.hideOverlay();
    }});
  } // end onDeleteClick()

  onHideErrorDeletePrompt() {
    this.showErrorDeletePrompt = false;
  } // end onHideErrorDeletePrompt()

  FeeTariffsView() {
    this.feeTariffs = [];
    this.loadingService.showOverlay();
    this.advocateOverviewService.getFeeTariffs().subscribe({
      next: (data) => {
        // On next
        this.feeTariffs = data;
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
        this.showFeeTariffsModal = true;
      }
    });
  } // end FeeTariffsView()

   onFeeTariffsModalHide() {
    this.showFeeTariffsModal = false;
  } // end onFeeTariffsModalHide()

} // end FinancialDetailsComponent{}
