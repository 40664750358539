<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>

<!-- Arbitration Table -->
<div class="container-fluid">
    <div class="well well-fluid">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <th>Law Firm</th>
                    <th>Matter Description</th>
                    <th>Arbitration Split %</th>
                    <th>Invoice Amount</th>
                    <th>Invoice Date</th>
                    <th>Recipient</th>
                    <th>Note</th>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    <tr *ngFor="let arbitration of arbitrationViewHandle">
                        <td *ngIf="arbitration.state.isVisible">{{ arbitration.data.ClientName }}</td>
                        <td *ngIf="arbitration.state.isVisible">{{ arbitration.data.InvoiceParties }}</td>
                        <td *ngIf="arbitration.state.isVisible">{{ arbitration.data.SplitPercentage }} %</td>
                        <td *ngIf="arbitration.state.isVisible">{{ arbitration.data.InvoiceTotal | currency:'R ' }}</td>
                        <td *ngIf="arbitration.state.isVisible">{{ arbitration.data.InvoiceDate | date:'yyyy-MM-dd' }}</td>
                        <td *ngIf="arbitration.state.isVisible">
                            <input
                                type="email"
                                class="form-control"
                                [ngModelOptions]="{ updateOn: 'blur' }"
                                [class.has-error]="hasValidEmails(arbitration)"
                                (input)="onReceipientInput($event, arbitration.data)"
                                [(ngModel)]="arbitration.data.Recipients"
                                [title]="arbitration.data.Recipients"
                                (click)="$event.target.select()">
                            <span *ngIf="hasValidEmails(arbitration)" class="text-danger">
                                Please enter a valid Email Address.
                            </span>
                        </td>
                        <td *ngIf="arbitration.state.isVisible">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Enter your note to attorney here"
                                [(ngModel)]="arbitration.data.InvoiceNote"
                                [title]="arbitration.data.InvoiceNote"
                                (focus)="$event.target.select()">
                        </td>
                        <td *ngIf="arbitration.state.isVisible">
                            <span class="btn-group">
                                <span class="clickable" style="min-width: 100px;" (click)="onViewInvoice(arbitration.data)">
                                    Preview Invoice
                                </span>
                                <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupMenu(arbitration)"></i>
                            </span>
                        </td>
                        <td style="text-align: center;">
                            <span *ngIf="arbitration.state.isLoad">
                                <img src="../../../assets/images/logos/spinner.gif" width="20" />
                            </span>
                            <span class="checkIcon" *ngIf="!arbitration.state.isLoad && arbitration.state.isGenerated">
                                <i class="fa fa-check"></i>
                            </span>
                            <span class="warningMessage" *ngIf="!arbitration.state.isLoad && !arbitration.state.isGenerated">
                                {{ arbitration.state.ErrorMessage }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Loading Template -->
<ng-template #loadingTemplate>
    <div class="loading-spinner text-center">
        <p>Loading arbitration invoices, please wait...</p>
        <img src="../../../assets/images/logos/spinner.gif" width="50" />
    </div>
</ng-template>

<!-- Invoice Preview Modal -->
<app-invoice-preview-modal
    *ngIf="showProformaInvoice"
    #invoicePreview
    [notesHandle]="notesHandle"
    [plaintiff]="invoicePlaintiff"
    [selectedTab]="selectedTab"
    [defendant]="invoiceDefendant"
    [invoiceId]="selectedInvoiceId"
    [invoiceDetails]="invoiceDetails"
    [feesQuoted]="feesQuoted"
    [isVisible]="showProformaInvoice"
    (closeModal)="closeModal()">
</app-invoice-preview-modal>
