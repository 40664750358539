<input
  [id]="id"
  [name]="name"
  type="text"
  [placeholder]="date"
  [value]="model | date:'yyyy-MM-dd'"
  (blur)="onBlur($event)"
  (focus)="onFocus($event)"
  (input)="onInput($event)"
  readonly><!--#end input -->
<i
  class="fa fa-calendar fa-lg input-arrow"
  (click)="toggleDropdown()"></i><!--#end .fa -->
<app-date-picker-modal
  [isVisible]="isOpen"
  (select)="onDateSelect($event)"
  (cancel)="hideModal()"></app-date-picker-modal><!--#end app-date-picker-modal -->
