<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>

<div class="container-fluid">
  <div class="frame">
    <div class="tile">
      <!-- Quotation details -->
      <div class="well">
        <h2 class="well-title">Quotation note</h2>
        <div class="well-row">
          <textarea
            #notes
            id="notes"
            placeholder="Enter your quotation note here"
            [(ngModel)]="workingQuotation.Notes"></textarea>
        </div>
      </div> <!-- #end .well -->
    </div> <!-- #end .tile -->

    <div class="tile">
      <!-- Quotation document preview -->
      <div style="width: 100%; height: 700px;">
        <ng2-pdfjs-viewer
          #pdfViewer
          [externalWindow]="false"
          [openFile]="true"
          [viewBookmark]="true"
          [download]="true"
          [downloadFileName]="fileName"></ng2-pdfjs-viewer>
      </div>
    </div> <!-- #end .tile -->

  </div> <!-- #end .frame -->

  <div class="well footer-buttons">
    <button
      style="width: 30%;"
      class="btn btn-danger"
      (click)="onEditQuotation()">Edit quotation</button>
    <button
      class="btn btn-success"
      (click)="onSaveNote()"
      style="width: 30%;">Send quotation</button>
  </div>
  <!-- #end .well .footer-buttons -->
</div> <!-- #end .container-fluid -->