import { Component, OnInit, ViewChild, Input, ElementRef, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { InvoiceDetailsService, InvoiceDetails, DEFAULT_INVOICE_DETAILS } from '../../services/invoice-details/invoice-details.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../services/messaging/snackbars/snackbars.service';
import { ApiService } from '../../services/api.service';
import { InvoiceDocumentService } from '../../services/invoice-document/invoice-document.service';
import { UtilitiesService } from '../../services/utilities.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { PATH_ADVOCATE_INVOICE_PREVIEW, PATH_INVOICE_EMAIL, PATH_MATTERS } from '../../services/appdata/app-config.service';
// import { SubscriptionLike as ISubscription } from 'rxjs';
import * as moment from 'moment';
import { ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import { InvoicesService, Invoice, DEFAULT_INVOICE } from '../../services/invoices/invoices.service';
// import { } from '@angular/core/testing';
import {
  ActivityLogs,
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogsService } from '../../services/activity-logs/activity-logs.service';

@Component({
  selector: 'app-advocate-invoice-preview',
  templateUrl: './advocate-invoice-preview.component.html',
  styleUrls: ['./advocate-invoice-preview.component.scss']
})
export class AdvocateInvoicePreviewComponent implements OnInit, OnDestroy {

  crumbs: ICrumb[];
  invoiceId: number;
  previewTitle = 'Generate invoice (Preview)';

  _fileName;
  @ViewChild('notes') notesElement: ElementRef;
  @ViewChild('pdfViewer') public pdfViewer;
  // invoiceDetails: InvoiceDetails = this.util.objectCopy

  invoiceDetails: InvoiceDetails = this.util.objectCopy(DEFAULT_INVOICE_DETAILS);
  workingInvoiceDetails: InvoiceDetails = this.util.objectCopy(DEFAULT_INVOICE_DETAILS);

  invoiceNote: string;
  showSaveInvoicePrompt: boolean;
  showUnsavedChangesPrompt: boolean;
  hasNotes: boolean;
  invoice: Invoice = Object.assign({}, DEFAULT_INVOICE);
  emailTo: string;
  showMenuPopup: boolean;
  showRecipientsModal = false;

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: "Practice Manager",
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  // private _subscriptions: ISubscription[] = [];
  // private _existRoute: string;
  // private _canIgnoreNavigationBlock: boolean;
  // private _exitRoute: string;

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.invoiceDetails, this.workingInvoiceDetails);
  } // end isDirty()

  get isSaveable(): boolean {
    // console.log('CAN SAVE: ', !Boolean(this.hasInvoiceDateLessThanError && this.isFutureDated));
    return !this.hasInvoiceDateLessThanError && !this.isFutureDated && Boolean(this.workingInvoiceDetails.Id);
  } // end isSaveable()

  get isAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator);
  } // end isAssistant()

  get isAssistantToLawfirm(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator)
      && Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isAssistantToLawfirm()

  // Is Internal Attorney
  get isAttorney(): boolean {
    let attorneyProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      attorneyProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
    } else {
      attorneyProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
    }
    return attorneyProfile;
  } // end isAttorney()

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    let lawfirmProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      lawfirmProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
    } else {
      lawfirmProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
    }
    return lawfirmProfile;
  } // end isLawfirm()

// Is Practice Manager
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  } // end isPracticeManager()

  get hasInvoiceDateLessThanError(): boolean {
    let highDate = moment('1978-12-18');
    this.workingInvoiceDetails.InvoiceLines.forEach(line => {
      if (line.Description !== 'Reduced fee' && line.Description !== 'Payment Received') {
        const lineDate = moment(line.Date);
        if (lineDate.isAfter(highDate)) {
          highDate = lineDate;
        }
      }
    });

    const invoiceDate = new Date(this.workingInvoiceDetails.InvoiceDate);
    const hd = moment(highDate.format('YYYY-MM-DD'));
    const id = moment(invoiceDate.toDateString());
    if (id.isSameOrAfter(hd)) {
      // console.log('Has Invoice Date Less Than Error: True');
      return false;
    }
    // console.log('Has Invoice Date Less Than Error: False');
    return true;
  }

  get isFutureDated(): boolean {
    const invoiceDate = new Date(this.workingInvoiceDetails.InvoiceDate);
    const now = moment().format('YYYY-MM-DD');
    const id = moment(invoiceDate.toDateString());
    if (id.isAfter(now)) {
      // console.log('IS FUTURE DATE: True');
      return true;
    }
    // console.log('IS FUTURE DATE: False');
    return false;
  }

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private invoiceService: InvoicesService,
    private snackbarService: SnackbarsService,
    private navigationService: NavigationService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private invoiceDetailService: InvoiceDetailsService,
    private invoiceDocumentService: InvoiceDocumentService,
  ) { } // end constructor()

  ngOnInit() {
    this.invoiceId = +this.route.snapshot.paramMap.get('id');
    this.crumbs = [
      {
        label: 'Invoice Preview'
      }
    ];
    this.loadingService.showOverlay();
    const url = this.api.endpoints.invoiceDocument + '?invoiceID=' + this.invoiceId;
    this.invoiceDocumentService.getInvoiceDocument(url)
      .subscribe({ next: (invoice_document) => {
        this.pdfViewer.name = 'invoice.pdf';
        this.pdfViewer.pdfSrc = invoice_document;
        this.pdfViewer.refresh();
      },
      error: (error) => {
        // On Error
        console.log('An error occurred: ', error);
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
      }
    });

     this.invoiceDetailService.getInvoiceDetails(this.invoiceId).subscribe({next: (_details) => {
      this.invoiceDetails = _details;
      if (this.invoiceDetails.InvoiceDate === null) {
        const d = new Date();
        this.invoiceDetails.InvoiceDate = this.util.parseDate(d.toString());
      }
     },
     error: (error) => {
       // On error
       this.loadingService.hideOverlay();
     },
     complete: () => {
       // On complete
       if (this.invoiceDetails &&
         this.invoiceDetails.InvoiceNote &&
         this.invoiceDetails.InvoiceNote.localeCompare('null') === 0) {
           this.invoiceDetails.InvoiceNote = '';
         }
      
        this.invoiceService.getInvoice(this.invoiceDetails.ServiceId).subscribe({
          next: (_invoice) => {
            this.invoice = _invoice;
          }
        });
        this.workingInvoiceDetails = this.util.objectCopy(this.invoiceDetails);
        if (this.workingInvoiceDetails.InvoiceNote && this.workingInvoiceDetails.InvoiceNote.length > 0) {
          this.hasNotes = true;
        } else {
          this.hasNotes = false;
        }
     }
    });

      // Scroll up
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  } // end ngOnInit()

  parseInvoiceDate(invoiceDate: string): Date {
    if (invoiceDate) {
      return new Date(invoiceDate);
    } else {
      return null;
    }
  } // end parseInvoiceDate()

  ngOnDestroy() {
  } // end ngOnDestroy()

  onEditMatterDetails() {
    this.loadingService.showOverlay();
    if (this.workingInvoiceDetails.InvoiceNote) {
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Add edit Note (On Revise)';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-Invoice-Preview)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'Invoice';
      this.activityLog.JsonData = JSON.stringify(this.workingInvoiceDetails.InvoiceNote);
      // this.activityLogService.addActivityLog(this.activityLog).toPromise();

      this.invoice.Notes = this.workingInvoiceDetails.InvoiceNote;
      this.invoiceService.updateInvoice(this.invoice).subscribe({ next: 
        (invoice) => {
        },
        error: (error) => {
          this.loadingService.hideOverlay();
        },
        complete: () => {
        this.loadingService.hideOverlay();
        this.router.navigate([PATH_MATTERS, this.invoiceDetails.ServiceId]);
      }
    });
    } else {
      this.loadingService.hideOverlay();
      this.router.navigate([PATH_MATTERS, this.invoiceDetails.ServiceId]);
    }
  } // end onEditMatterDetails()

  parseDate(date: string) {
    return this.util.parseDate(date);
  } // end parseDate()

  /**
  * Called when the value of the date changes.
  * @param {*} event The date change event.
  */
  onDateChange(invoiceDetail: InvoiceDetails, value: Date): void {
    if (value) {
      this.workingInvoiceDetails.InvoiceDate = moment(value).format('YYYY-MM-DD');
    }
  } // end onDateChange()

  onShowSaveInvoiceModal() {
    this.showSaveInvoicePrompt = true;
  } // end onShowCalendarItemModal()

  onHideSaveInvoiceModal() {
    this.showSaveInvoicePrompt = false;
  } // end onHideCalendarITemModal()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onHideUnsavedChangesPrompt()

  leavePage() {
    this.onHideUnsavedChangesPrompt();
  } // end leavePage()

  toggleMenu() {
    this.showMenuPopup = !this.showMenuPopup;
  } // end toggleMenu()

  onSaveToAdvocate() {
    this.onSend(this.userProfileService.selectedUserProfile.contactDetails.emailAddress);
  } // end onSaveToAdvocate()

  onSend(email: string) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Sending invoice to advocate...',
      action: null
    };
    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Send Invoice to Advocate';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-Invoice-Preview)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Invoice';
    this.activityLog.JsonData = JSON.stringify(this.workingInvoiceDetails);
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    this.snackbarService.snackbarComponent.make(snack).show();
    this.invoiceDetailService.sendInvoiceToAdvocate(this.workingInvoiceDetails,
      this.userProfileService.userProfile.personalDetails.fullName,
      this.userProfileService.selectedUserProfile.personalDetails.fullName,
      email).subscribe({next: 
      (invoice_sent) => {
        // On next
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
        snack.label = 'Invoice Sent.';
        this.snackbarService.dismiss().make(snack).show();
      }
    });
  } // end onSaveToAdvocate()

  /**
  * Saves the invoice details.
  */
  onSaveInvoiceDetails() {
    // console.log('ON SAVE INVOICE DETAILS: ', this.workingInvoiceDetails);
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Saving invoice details...',
      action: null
    };

    this.snackbarService.snackbarComponent.make(snack, 5000).show();
    this.workingInvoiceDetails.InvoiceDate = moment(this.workingInvoiceDetails.InvoiceDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'On Preview Email';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-Invoice-Preview)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Invoice';
    this.activityLog.JsonData = JSON.stringify(this.workingInvoiceDetails);
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    const uidSub = this.invoiceDetailService
      .updateInvoiceDetailsDate(this.workingInvoiceDetails.Id, this.workingInvoiceDetails)
      .subscribe({next: (invoiceDetails) => {
        this.workingInvoiceDetails = invoiceDetails;
        this.onSendViaEmail();
      },
      error: (error) => {
      const msg = 'Error saving details.';
      console.error(msg, error);
      snack.label = msg;
      snack.action = {
        label: 'Retry',
        handler: this.onSaveInvoiceDetails,
        params: this
      };
      snack.type = SnackType.ERROR;
      this.snackbarService.dismiss().make(snack).show();
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On Complete
      this.loadingService.hideOverlay();
      snack.label = 'Invoice details saved.';
      this.snackbarService.dismiss().make(snack).show();
    }
  });
  } // end onSaveInvoiceDetails()

  /**
   * Send the invoice to it's recepients.
   */
  onSendViaEmail() {
    this.loadingService.showOverlay();
    this.sentOnSaveInvoiceDetails();
    this.router.navigate([PATH_INVOICE_EMAIL, this.invoiceId, this.invoiceDetails.ServiceId]);
    this.loadingService.hideOverlay();
  } // end onSendViaEmail()

  onCloseModal() {
    this.showRecipientsModal = false;
  } // end onCloseModal()

  showRecipientDialog() {
    this.showMenuPopup = false;
    this.showRecipientsModal = true;
  } // end showRecipientDialog()

  onSendMessage(email: string) {
    this.onSend(email);
  } // end onSendMessage()

  //#region Saving on Send
  sentOnSaveInvoiceDetails() {
    this.workingInvoiceDetails.InvoiceDate = moment(this.workingInvoiceDetails.InvoiceDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'On Preview email';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-Invoice-Preview)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Invoice';
    this.activityLog.JsonData = JSON.stringify(this.workingInvoiceDetails);
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();
    const uidSub = this.invoiceDetailService
      .updateInvoiceDetails(this.workingInvoiceDetails.Id, this.workingInvoiceDetails)
      .subscribe({ next: (invoiceDetails) => {
        this.workingInvoiceDetails = invoiceDetails;
      },
        error: (error) => {
        },
        complete: () => {
          // On Complete
        }
      });
  } // end onSaveInvoiceDetails()
  //#endregion

  @Input()
  set fileName(fileName: string) {
    this._fileName = fileName;
  }
  get fileName() {
    return this._fileName;
  }
} // end AdvocateInvoicePreviewComponent{}
