import { Component, OnInit } from '@angular/core';
import { PracticeAdvocateHealthService, AdvocateHealth } from '../../../services/practice-advocate-health/practice-advocate-health.service';

interface AdvocateHealthHandle {
  data: AdvocateHealth;
  state: {
    isVisible: boolean;
    isOpen: boolean;
    isSelected: boolean;
  };
}

@Component({
  selector: 'app-practice-advocate-detail',
  templateUrl: './practice-advocate-detail.component.html',
  styleUrls: ['./practice-advocate-detail.component.scss']
})
export class PracticeAdvocateDetailComponent implements OnInit {

  advocateHealth: AdvocateHealth[] = [];
  advocateHealthHandle: AdvocateHealthHandle[] = [];

  constructor(
    private advocateHealthService: PracticeAdvocateHealthService
  ) { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  getPracticeAdvocateHealthData() {
    this.advocateHealthService.getAdvocateHealthData().subscribe({ next: (_data) => {
      // On next
      this.advocateHealth = _data;
    }});
  } // end getPracticeAdvocateHealthData()
} // end PracticeAdvocateDetailComponent{}
