import { Injectable } from '@angular/core';

@Injectable()
export class ModalStackService {
  modalsStack: Array<any>;

  constructor() { } // end constructor()

  /**
   * Adds a modal to the top of the stack. If the stack is not empty then the
   * preceeding modal is closed before the new one is added to the stack.
   * @param modal The new modal.
   * @returns {number} A Number, representing the new length of the stack array.
   */
  push(modal: any): number {
    return this.modalsStack.length;
  } // end push()

  /**
   * Removes the top-most modal from the stack.
   * @returns {any}
   */
  pop(): any {} // end pop()

  /**
   * Returns the current top-most modal on the stack or null if the stack is
   * empty.
   * @returns {any} The top-most modal on the stack or null if empty.
   */
  peek(): any {
    return this.modalsStack.length ? this.modalsStack : null;
  } // end peek()

  /**
   * Returns a number, representing the current length of the stack array.
   * @returns {number} A Number, representing the current length of the
   * stack array.
   */
  length(): number {
    return this.modalsStack.length;
  } // end length()

  /**
   * @returns {boolean} A boolean indicating whether the stack is empty or not.
   */
  isEmpty(): boolean {
    return (this.modalsStack.length > 0);
  } // isEmpty()

  /**
   * Empties the modal stack, closing each modal.
   */
  clear(): void {
    const length = this.length();
    for (let x = 0; x < length; x++) {
      this.pop();
    }
  } // end clear()
} // end ModalStackService{}
