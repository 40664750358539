import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export interface Category {
  Category: string;
  SubCategory?: string;
}

export const DEFAULT_CATEGORY = {
  Category: 'Draft'
};

@Injectable()
export class CategoriesService {
  private _categories: Category[] = [
    {
      Category: 'PhoneCall'
    },
    {
      Category: 'Draft'
    },
    {
      Category: 'Peruse'
    },
    {
      Category: 'Attendance'
    },
    {
      Category: 'Consulting'
    },
    {
      Category: 'Preparation'
    },
    {
      Category: 'Travel'
    },
    {
      Category: 'Correspondence'
    },
    {
      Category: 'Diarise'
    },
    {
      Category: 'DeleteAction'
    },
    {
      Category: 'Invoice'
    }
  ];

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  /**
   * GET: Returns all fee categories for a user.
   * @returns {*} Returns all fee categories for a user.
   */
  getCategories(): Observable<any> {
    return this.http.get<Category[]>(this.api.endpoints.category, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getCategories()
} // end CategoriesService{}
