import { Component, OnInit, Input } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import {
  PracticeAdvocateHealthService,
  PracticeAllCustomer,
  AllTotals,
  CurrentTotals,
  ThirtyDaysTotals,
  SixtyDaysTotals,
  NinetyDaysTotals,
  NinetyEightDaysTotals} from '../../../services/practice-advocate-health/practice-advocate-health.service';

  interface AllTotalsHandle {
    data: AllTotals;
    state: {
      isVisible: boolean;
      isSelected: boolean;
      isOpen: boolean;
    };
  }

interface CurrentTotalsHandle {
  data: CurrentTotals;
    state: {
      isVisible: boolean;
      isSelected: boolean;
      isOpen: boolean;
    };
  }

interface ThirtyDaysTotalsHandle {
  data: ThirtyDaysTotals;
    state: {
      isVisible: boolean;
      isSelected: boolean;
      isOpen: boolean;
    };
  }

interface SixtyDaysTotalsHandle {
  data: SixtyDaysTotals;
    state: {
      isVisible: boolean;
      isSelected: boolean;
      isOpen: boolean;
    };
  }

interface NinetyDaysTotalsHandle {
  data: NinetyDaysTotals;
    state: {
      isVisible: boolean;
      isSelected: boolean;
      isOpen: boolean;
    };
  }

interface NinetyEightDaysTotalsHandle {
  data: NinetyEightDaysTotals;
    state: {
      isVisible: boolean;
      isSelected: boolean;
      isOpen: boolean;
    };
  }

@Component({
  selector: 'app-practice-all-customer-report',
  templateUrl: './practice-all-customer-report.component.html',
  styleUrls: ['./practice-all-customer-report.component.scss']
})
export class PracticeAllCustomerReportComponent implements OnInit {

  apiData: PracticeAllCustomer;
  tabData: any = [];
  _tabData: any = [];
  totalOutstanding = 0;

  // tooltip
  tooltipData: any = [];
  showTooltipOverlay = false;

  // tabs Data
  allTotalsHandle: AllTotalsHandle[] = [];
  currentTotals: CurrentTotals[] = [];
  thirtyDaysTotals: ThirtyDaysTotals[] = [];
  sixtyDaysTotals: SixtyDaysTotals[] = [];
  ninetyDaysTotals: NinetyDaysTotals[] = [];
  ninetyEightDaysTotals: NinetyEightDaysTotals[] = [];

  // search
  searchterm: string;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  @Input() activeTab: string;
  constructor(
    private loadingService: LoadingService,
    private router: Router,
    private userProfileService: UserProfileService,
    private practiceAdvocateHealthService: PracticeAdvocateHealthService
  ) { } // end constructor()

  ngOnInit() {
    if (this.practiceAdvocateHealthService.allCustomerActiveTab) {
      this.activeTab = this.practiceAdvocateHealthService.allCustomerActiveTab;
    } else if (this.activeTab === null || this.activeTab === undefined) {
      this.activeTab = 'all';
    }
    this.getApiData();
  } // end ngOnInit()

  getApiData(): void {
    this.loadingService.showOverlay();
    this.practiceAdvocateHealthService.getPracticeAllCustomer().subscribe({ next: (response) => {
      // On next
      if (response) {
        this.apiData = response;
        this._tabData = this.tabData;
      }
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.switchTab();
      this.loadingService.hideOverlay();
    }
  });
  } // end getApiData()

  performSearch() {
    switch (this.activeTab) {
      case 'all':
        this.tabData = this._tabData.filter((s) => {
          return s.Debtor.toLowerCase().indexOf(this.searchterm.toLowerCase()) > -1;
        });
        this.getTotalAmount(this.tabData);
      break;
      case 'current':
        this.tabData = this._tabData.filter((s) => {
          return s.Debtor.toLowerCase().indexOf(this.searchterm.toLowerCase()) > -1;
        });
        this.getTotalAmount(this.tabData);
      break;
      case '30':
        this.tabData = this._tabData.filter((s) => {
          return s.Debtor.toLowerCase().indexOf(this.searchterm.toLowerCase()) > -1;
        });
        this.getTotalAmount(this.tabData);
      break;
      case '60':
        this.tabData = this._tabData.filter((s) => {
          return s.Debtor.toLowerCase().indexOf(this.searchterm.toLowerCase()) > -1;
        });
        this.getTotalAmount(this.tabData);
      break;
      case '90':
        this.tabData = this._tabData.filter((s) => {
          return s.Debtor.toLowerCase().indexOf(this.searchterm.toLowerCase()) > -1;
        });
        this.getTotalAmount(this.tabData);
      break;
      case '98':
        this.tabData = this._tabData.filter((s) => {
          return s.Debtor.toLowerCase().indexOf(this.searchterm.toLowerCase()) > -1;
        });
        this.getTotalAmount(this.tabData);
      break;
    }
  } // end performSearch()

  onTabClick(tab: string) {
    this.searchterm = '';
    this.activeTab = tab;
    this.switchTab();
  } // end onTabClick()

  switchTab(): void {
    this._tabData = [];
    this.tabData = [];
    switch (this.activeTab) {
      case 'all':
        this.filterTabData('AllTotals');
        break;
      case 'current':
        this.filterTabData('CurrentTotals');
        break;
      case '30':
        this.filterTabData('ThirtyDaysTotals');
        break;
      case '60':
        this.filterTabData('SixtyDaysTotals');
        break;
      case '90':
        this.filterTabData('NinetyDaysTotals');
        break;
      case '98':
        this.filterTabData('NinetyEightDaysTotals');
        break;
    }
  } // end switchTab()

  filterTabData(key: string): void {
    this.tabData = this.apiData[key].sort((a, b) => b.Outstanding - a.Outstanding);
    this._tabData = this.tabData;
    this.totalOutstanding = 0;
    for (const item of this.tabData) {
      this.totalOutstanding += item.Outstanding;
    }
  } // end filterTabData()

  getBarWidth(value: number): string {
    let width = '0.95';
    if (value >= 1) {
      width = (value * 0.95).toString();
    }
    return width + '%';
  } // end getBarWidth()

  getTotalAmount(value: any): void {
    this.totalOutstanding = 0;
    for (const i of value) {
      this.totalOutstanding += i.outstanding;
    }
  } // end getTotalAmount()

  onHover(i: number, activeTab: string): void {
    let type = 0;
    if (activeTab === 'all') {
      type = null;
    } else if (activeTab === 'current') {
      type = +0;
    } else if (activeTab === '30') {
      type = +30;
    } else if (activeTab === '60') {
      type = +60;
    } else if (activeTab === '90') {
      type = +90;
    } else if (activeTab === '98') {
      type = +98;
    }
    this.showTooltipOverlay = true;
    this.tooltipData = [];
    this.practiceAdvocateHealthService.getPracticeByCustomer(i).subscribe({ next: (response) => {
      if (response) {
        if (type !== null) {
          response = response.filter(a => a.Due > 0 && a.DaysOverdue === type);
        } else {
          response = response.filter(a => a.Due > 0);
        }
        response.sort((a, b) => {
          return a.InvoiceDate.toUpperCase().localeCompare(b.InvoiceDate.toUpperCase());
        });
        if (response.length > 5) {
          this.tooltipData = response.slice(0, 5);
        } else {
          this.tooltipData = response;
        }
      }
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        this.showTooltipOverlay = false;
      }
    });

  } // end onHover()

  getBackgroundColor(type: string): string {
    if (type.toLowerCase() === 'all') {
      return '#646464';
    } else if (type.toLowerCase() === '0') {
      return '#8abd37';
    } else if (type.toLowerCase() === '30') {
      return '#e5c100';
    } else if (type.toLowerCase() === '60') {
      return '#eb9e02';
    } else if (type.toLowerCase() === '90') {
      return '#ff8000';
    } else if (type.toLowerCase() === '98') {
      return '#cf624f';
    }
  } // end getBackgroundColor()

  getBottomRow(index: number, length: number): string {
    if (length > 0 && index === length - 1) {
      return '20px';
    }
    return '0px';
  } // end getBottomRow()

  onViewCustomerReport(value: number) {
  } // end onViewCustomerReport()

} // end PracticeAllCustomerReportComponent{}
