import { Component, OnInit } from '@angular/core';
import { Snack, SnackType, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { OnboardingService } from '../../../services/onboarding/onboarding.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PATH_SIGN_IN } from '../../../services/appdata/app-config.service';
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-onboarding-response-handler',
  templateUrl: './onboarding-response-handler.component.html',
  styleUrls: ['./onboarding-response-handler.component.scss']
})
export class OnboardingResponseHandlerComponent implements OnInit {

  PreRegistrationID: string;
  AccountType: any;
  isSuccess: boolean = false;
  isError: boolean = false;
  isSuccessMessage: boolean = false;
  ErrorMessage: string;

  constructor(
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private snackbarsService: SnackbarsService,
    private onBoardingService: OnboardingService,
  ) { }

  ngOnInit() {
    this.activatedRoute.params
      .subscribe(
        (params: Params) => {
          this.PreRegistrationID = params['id'];
          if (!params['id']) {
            this.PreRegistrationID = localStorage.getItem('OnBoardingPreRegistrationID');
          }
        }
      );
    this.createAccount();
  }

  createAccount() {
    this.loadingService.showOverlay();
    this.onBoardingService.createAccount(this.PreRegistrationID)
      .subscribe({
        next: (response: any) => {
          if (response) {
            if (response.isError) {
              this.isError = true;
              this.isSuccess = false;
              this.ErrorMessage = response.ErrorMessage;
            }
            else {
              this.isSuccess = true;
              this.isError = false;
              this.AccountType = response.AccountType;
              if (this.AccountType == 'Office') {
                this.isSuccessMessage = true;
                const snack: Snack = {
                  label: "You will be auto-redirected to the Auxcon dashboard shortly.",
                  type: SnackType.SUCCESS,
                  action: null,
                };              
                this.snackbarsService.dismiss().make(snack).show();
                this.loadingService.hideOverlay();
                setTimeout(() => {
                  //this.router.navigate([PATH_SIGN_IN]);
                  window.location.href = environment.redirectUrl + PATH_SIGN_IN;
                }, 5000)
              }
            }
          }
        },
        error: (error) => {
          const snack: Snack = {
            label: error,
            type: SnackType.ERROR,
            action: null,
          };
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          this.loadingService.hideOverlay();
        }
      })
  }

}
