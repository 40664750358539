import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ViewEncapsulation } from '@angular/core';
import { AgeAnalysisData } from '../../../services/age-analysis/age-analysis.service';
import { AgeAnalysisToolTipComponent } from '../../reports/age-analysis/age-analysis-tooltip/age-analysis-tooltip.component';
import { AdvocateDetailStatementService } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { ReportsService, PracticeManagementParameters } from '../../../services/reports/reports.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Router } from '@angular/router';
import { LawfirmAgeAnalysisTooltipComponent } from './lawfirm-age-analysis-tooltip/lawfirm-age-analysis-tooltip.component';
import { PATH_ALL_ADVOCATES, PATH_ATTORNEY_AGE_ANALYSIS, PATH_UNPAID_INVOICES } from '../../../services/appdata/app-config.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as d3 from 'd3';
import { jqxChartComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { ThreeMonthsOutstandingLawFirm } from '../../../services/live-statement/live-statement.service';
import { ApiService } from '../../../services/api.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-lawfirm-age-analysis',
  templateUrl: './lawfirm-age-analysis.component.html',
  styleUrls: ['./lawfirm-age-analysis.component.scss']
})
export class LawfirmAgeAnalysisComponent implements OnInit, OnChanges, OnDestroy {

  isDataLoaded = false;
  agingCurrentTotal = 0;
  aging30Total = 0;
  aging60Total = 0;
  aging90Total = 0;
  aging98Total = 0;
  agingCurrentPercentage = 0;
  aging30Percentage = 0;
  aging60Percentage = 0;
  aging90Percentage = 0;
  aging98Percentage = 0;
  agePercentageColor = '';
  chartPercentageColor = '';

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    LAWFIRM: 'Lawfirm',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTENAL_LAWFIRM: 'Lawfirm (External)',
    EXTENAL_ATTORNEY: 'Attorney (External)',
    BAR_SOCIETY: 'Bar Society - User',
    GCB: 'GCB - User',
    ADHOC: 'Ad-hoc',
    HEAD_OF_STATE: 'Head of State'
  };

  clickedChartElement = 10;

  thirtyDayDue = 235123;

  renderTooltipOverlay = true;
  _data: any = [];
  totalOutstanding = 0;
  _currentTooltipData: any = [];
  _30TooltipData: any = [];
  _60TooltipData: any = [];
  _90TooltipData: any = [];
  _98TooltipData: any = [];
  _allTooltipData: any = [];
  apiData: any = [];

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onRowClick: EventEmitter<string> = new EventEmitter<string>();
  @Input() data: AgeAnalysisData;
  @Input() threeMonthsFirmData: ThreeMonthsOutstandingLawFirm;
  @ViewChild('myChart') myChart: jqxChartComponent;
  @ViewChild('LawfirmAgeAnalysisTooltipComponent') ageAnalysisToolTipComponent: LawfirmAgeAnalysisTooltipComponent;
  graphWidth = 637;
  graphHeight = 180;
  graphMargin = { top: 20, right: 20, bottom: 60, left: 104 };
  subscription: Subscription;
  isTargetMissed = false;
  collectedMissed = 0;
  invoicedMissed = 0;
  totalInvoiced = '';
  totalCollected = '';

  sampleData: any[] = [
    { Month: 'Jan' , Outstanding: 0 },
    { Month: 'Feb', Outstanding: 0 },
    { Month: 'Mar', Outstanding: 0 }
  ];
  padding: any = { left: 1, top: 8, right: 1, bottom: 1 };
  titlePadding: any = { left: 90, top: 0, right: 0, bottom: 10 };

  xAxis: any =
    {
      dataField: 'Month',
      showGridLines: true
    };
  seriesGroups: any[] =
    [
      {
        type: 'column',
        useGradient: false,
        columnsGapPercent: 10,
        seriesGapPercent: 0,
        valueAxis:
        {
          unitInterval: 0,
          minValue: 0,
          maxValue: 0,
          displayValueAxis: true,
          description: '',
          axisSize: 'auto',
          tickMarksColor: '#888888',
          formatSettings: { sufix: 'k' }
        },
        series: [
          {
            dataField: 'Outstanding',
            colorFunction: (value: any, itemIndex: any, serie: any, group: any): any => {
              return '#bfbfbf';
            }
          }
        ]
      }
    ];

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  practiceManagementParameters: PracticeManagementParameters = {
    fromDate: '',
    toDate: ''
  };

  get isHeadOfState(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.HEAD_OF_STATE)
  } // end isHeadOfState()

  constructor(
    private router: Router,
    private api: ApiService,
    private reportService: ReportsService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private advocateDetailStatementService: AdvocateDetailStatementService,
  ) { } // end constructor()

  ngOnInit() {

  } // end ngOninit()

  getWidth(): any {
    if (document.body.offsetWidth < 550) {
      return '90%';
    }
    return 550;
  }

  ngOnDestroy() {
  } // end ngOnDestroy()

  onAgeAnalysisClicked() {
    this.router.navigate([PATH_ATTORNEY_AGE_ANALYSIS]);
  } // end onAgeAnalysisClicked()

  ngOnChanges(changes: SimpleChanges) {
    this.getAgeAnalysisReport();
     if (this.agePercentageColor) {
       this.getChartData(this.agePercentageColor);
     }
  } // end ngOnChanges()

  getChartData(color: string) {
    this.thirtyDayDue = this.threeMonthsFirmData.TotalToPayNextMonthSummary;

    let lastMonth; // = new Date();
    // if (this.threeMonthsFirmData && this.threeMonthsFirmData.LastMonthYear) {
    //   lastMonth = new Date(this.threeMonthsFirmData.LastMonthYear, this.threeMonthsFirmData.LastMonth, 0);
    // } else {
      lastMonth = new Date(+moment().add(-1, 'months').format('YYYY'), +moment().add(-1, 'months').format('MM'), 0);
    // }

    // This month Date.
    let thisMonth; //  = new Date();
    // if (this.threeMonthsFirmData && this.threeMonthsFirmData.ThisMonth) {
    //   thisMonth = new Date(this.threeMonthsFirmData.ThisMonth, this.threeMonthsFirmData.ThisMonthYear, 0);
    // } else {
      thisMonth = new Date(+moment().format('YYYY'), +moment().format('MM'), 0);
    // }

    const nextMonth = new Date(this.threeMonthsFirmData.ToBeCollectedYear, this.threeMonthsFirmData.ToBeCollectedMonth, 0);
    const _maxAmount = Math.max(this.threeMonthsFirmData.LastMonthDue,
      this.threeMonthsFirmData.ThisMonthDue, this.threeMonthsFirmData.TotalToPayNextMonth) / 3;
      const maxAmount = Math.round(_maxAmount / 1000) * 1000;

    this.sampleData = [
      { Month: moment(lastMonth).format('MMM YY'), Outstanding: this.threeMonthsFirmData.LastMonthDue },
      { Month: moment(thisMonth).format('MMM YY'), Outstanding: this.threeMonthsFirmData.ThisMonthDue },
      { Month: moment(nextMonth).format('MMM YY'), Outstanding: this.threeMonthsFirmData.TotalToPayNextMonth }
    ];
    this.seriesGroups = [
      {
        type: 'column',
        useGradient: false,
        columnsGapPercent: 10,
        seriesGapPercent: 0,
        valueAxis: {
          unitInterval: maxAmount,
          minValue: 0,
          maxValue: Math.max(
            this.threeMonthsFirmData.LastMonthDue,
            this.threeMonthsFirmData.ThisMonthDue,
            this.threeMonthsFirmData.TotalToPayNextMonth
          ),
          displayValueAxis: true,
          description: '',
          axisSize: 'auto',
          tickMarksColor: '#888888',
          formatSettings: { sufix: 'k' }, // For axis tick marks display
        },
        series: [
          {
            dataField: 'Outstanding', 
            displayText: 'R',
            
            // Custom tooltip format to show the appropriate summary data
            toolTipFormatFunction: (value: any, itemIndex: any, serie: any, group: any): string => {
              const month = this.sampleData[itemIndex].Month; // Get current month for the tooltip
              let displayValue;
              
              // Determine which summary data to show based on the month
              if (month === moment(lastMonth).format('MMM YY')) {
                displayValue = this.threeMonthsFirmData.LastMonthDueSummary; // Show summary for last month
              } else if (month === moment(thisMonth).format('MMM YY')) {
                displayValue = this.threeMonthsFirmData.ThisMonthDueSummary; // Show last month's actual due for this month
              } else if (month === moment(nextMonth).format('MMM YY')) {
                displayValue = this.threeMonthsFirmData.TotalToPayNextMonthSummary; // Show total to pay summary for next month
              }
    
              // Format the tooltip with full value including thousand separators
              return `R ${displayValue.toLocaleString()}`;
            },
            
            colorFunction: (value: any, itemIndex: any, serie: any, group: any): any => {
              const data = this.sampleData.filter(d => d.Month === moment().add(1, 'months').format('MMM YY'))[0];
              const colorPercentage = (this.threeMonthsFirmData.TotalToPayNextMonthSummary / this.data.Total) * 100;
              if (colorPercentage <= 25) {
                this.chartPercentageColor = '#cf624f';
              } else if (colorPercentage >= 26 && colorPercentage <= 49) {
                this.chartPercentageColor = '#eb9e02';
              } else if (colorPercentage >= 50 && colorPercentage <= 75) {
                this.chartPercentageColor = '#e5c100';
              } else if (colorPercentage >= 76) {
                this.chartPercentageColor = '#8abd37';
              }
              return value === data.Outstanding ? this.chartPercentageColor : '#bfbfbf';
            },
            click: function(e) {
              // Click event handler
            },
          },
        ],
      },
    ];
    

    // this.seriesGroups =
    // [
    //   {
    //     type: 'column',
    //     useGradient: false,
    //     columnsGapPercent: 10,
    //     seriesGapPercent: 0,
    //     valueAxis:
    //     {
    //       unitInterval: maxAmount,
    //         minValue: 0,
    //         maxValue: (Math.max(this.threeMonthsFirmData.LastMonthDue,
    //           this.threeMonthsFirmData.ThisMonthDue, this.threeMonthsFirmData.TotalToPayNextMonth)),
    //         displayValueAxis: true,
    //         description: '',
    //         axisSize: 'auto',
    //         tickMarksColor: '#888888',
    //         formatSettings: { sufix: 'k' }
    //       },
    //       series: [
    //         {
    //           dataField: 'Outstanding', displayText: 'R',
    //           colorFunction: (value: any, itemIndex: any, serie: any, group: any): any => {
    //             const data = this.sampleData.filter(d => d.Month === moment().add(1, 'months').format('MMM YY'))[0];
    //             const colorPercentage = this.threeMonthsFirmData.TotalToPayNextMonthSummary / this.data.Total * 100;
    //             if (colorPercentage <= 25) {
    //               this.chartPercentageColor = '#cf624f';
    //             } else if (colorPercentage >= 26 && colorPercentage <= 49) {
    //               this.chartPercentageColor = '#eb9e02';
    //             } else if (colorPercentage >= 50 && colorPercentage <= 75) {
    //               this.chartPercentageColor = '#e5c100';
    //             } else if (colorPercentage >= 76) {
    //               this.chartPercentageColor = '#8abd37';
    //             }
    //             return value === data.Outstanding ? this.chartPercentageColor : '#bfbfbf';
    //           },
    //           click: function(e) {
    //           }
    //         }
    //       ]
    //     }
    //   ];
  } // end getChartData()

  onChartClick() {
    // if(!this.isHeadOfState) {
    this.advocateDetailStatementService.activeTab = 'view-all';
    this.router.navigate([PATH_UNPAID_INVOICES, this.api.serviceProviderID]);
    // }
  } // end onChartClick()

  getAgeAnalysisReport(): void {
    this.isDataLoaded = false;
    this._data = [];
      if (this.data) {
        this.agingCurrentPercentage = Math.round(this.data.ZeroDays * 100 / this.data.Total);
        this.aging30Percentage = Math.round(this.data.ThirtyDays * 100 / this.data.Total);
        this.aging60Percentage = Math.round(this.data.SixtyDays * 100 / this.data.Total);
        this.aging90Percentage = Math.round(this.data.NinetyDays * 100 / this.data.Total);
        this.aging98Percentage = Math.round(this.data.NinetyEightDays * 100 / this.data.Total);

        const agingData: any = {
          aging30Total: this.data.ThirtyDays,
          aging60Total: this.data.SixtyDays ,
          aging90Total: this.data.NinetyDays,
          aging98Total: this.data.NinetyEightDays,
          agingCurrentTotal: this.data.ZeroDays,
          totalOutstanding: this.data.Total,
        };
        this.aging30Total = this.data.ThirtyDays;
        this.aging60Total = this.data.SixtyDays;
        this.aging90Total = this.data.NinetyDays;
        this.aging98Total = this.data.NinetyEightDays;
        this.agingCurrentTotal = this.data.ZeroDays;
        this.totalOutstanding = this.data.Total;

        if (this.aging98Percentage <= 25) {
          this.agePercentageColor = '#8abd37';
        } else if (this.aging98Percentage >= 26 && this.aging98Percentage <= 49) {
          this.agePercentageColor = '#e5c100';
        } else if (this.aging98Percentage >= 50 && this.aging98Percentage <= 75) {
          this.agePercentageColor = '#eb9e02';
        } else if (this.aging98Percentage >= 76) {
          this.agePercentageColor = '#cf624f';
        }
        this._data.push(agingData);
      }
        this.isDataLoaded = true;
  } // end getAgeAnalysisReport()

  getBarHeight(value: number): string {
    return (value * 150 / 100) + 'px';
  } // end getBarHeight()

  getBarTextColor(value: number): string {
    const height = (value * 150 / 100);
    if (height < 17) {
      return '#000';
    }
    return '#fff';
  } // end getBarTextColor()

  getBarTextTopMargin(value: number): string {
    const height = (value * 150 / 100);
    if (height < 17) {
      return '-20px';
    }
    return ((height / 2) - 9) + 'px';
  } // getBarTextTopMargin()

  getBottomRow(index: number, length: number): string {
    if (length > 0 && index === length - 1) {
      return '20px';
    }
    return '0px';
  } // getBarTextTopMargin()

  receiveToolTipAge($event) {
    this.advocateDetailStatementService.selectedFirmTab = $event;
    this.router.navigate([PATH_ALL_ADVOCATES]);
  } // end receiveToolTipAge()
} // end LawfirmAgeAnalysisComponent{}
