<div class="popup-menu card" [class.hide]="!isVisible">
  <ul class="menu-list">
    <li
      class="menu-item clickable visible-sm"
      (click)="onDownloadInvoice()">Download</li>
    <li
      *ngIf="options.previewMessage && message.data.status !== 'Sending' && message.data.source === 'Invoice'"
      class="menu-item clickable"
      (click)="onForwardInvoice()">Forward invoice</li>
    <li
      *ngIf="options.previewMessage && message.data.source === 'Invoice'"
      class="menu-item clickable"
      (click)="onResendInvoice()">Resend invoice</li>
    <li
      *ngIf="options.viewQuotation && message.data.status !== 'Sending'  && message.data.source === 'Invoice'"
      class="menu-item clickable"
      (click)="onEditInvoice()">Edit invoice</li>
    <li
      *ngIf="options.deleteMatter && message.data.status !== 'Sending' && message.data.source === 'Invoice'"
      class="menu-item clickable"
      (click)="onContinueWithMatter()">Continue with matter</li>
  </ul><!--#end .menu-list -->
</div><!--#end .popup-menu -->
