<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid container-page">
  <div class="well">

<div class="display-options">
  <span class="column-search">
    <button class="btn btn-text">
      <i class="fa fa-search"></i>
    </button>
    <input type="search" placeholder="Search clients" (keydown)="onSearchClient($event)" (click)="clearSearch()"
      [(ngModel)]="searchTerm">
  </span>
  <!-- <span></span> -->
  <span class="column-order-by">
    <label for="sort-by">Sort by:</label>
    <select id="sort-by" data-placeholder="'Another option'" placeholder="Filter" (change)="orderClients()"
      [(ngModel)]="orderBy">
      <!-- <option value="" disabled selected="selected">Filter option</option> -->
      <option value="" disabled selected hidden> Filter options </option>
      <option *ngFor="let option of orderingOptions" [value]="option">
        {{option | titlecase}}
      </option>
    </select>
    <button class="btn btn-toggle" (click)="toggleAscending()">
      <i class="fa" [class.fa-sort-amount-desc]="!sortAscending" [class.fa-sort-amount-asc]="sortAscending"></i>
    </button>
    <!--#end .btn.btn-toggle -->
  </span>
  <!--#end .column-order-by -->
</div>
<!--#end .display-options -->

    <div class="table">
      <div class="table-head">

        <div class="table-row">          
          <!-- #end .column-address -->
        </div>
        <!--#end .table-row -->

        <div class="table-row">
          <span class="column-name clickable" (click)="toggleSort('client-name')">
            Client Name
            <i class="fa fa-sort" [class.fa-sort-down]="sortClientName"></i>
          </span>
          <!--#end .column-name -->
          <span class="column-email clickable" (click)="toggleSort('email')">
            Email
            <i class="fa fa-sort" [class.fa-sort-down]="sortEmail"></i>
          </span>
          <!--#end .column-email -->
          <span class="column-id clickable">
            ID Number.
          </span>
          <!--#end .column-role -->

          <span class="column-cell-number clickable">
            Cell Number
          </span> 
          <!-- #end .column-cell-number -->

          <span class="column-phone-number clickable">
            Phone Number
          </span>
          <!--#end .column-phone-number -->

          <span class="column-fax-number clickable">
            Fax Number
          </span>
          <!--#end .column-fax-number -->

          <span class="column-vat-number clickable">
            VAT Number
          </span>
          <!--#end .column-vat-number -->

          <span class="column-address clickable">
            Address
          </span>
          <!-- #end .column-address -->
        </div>
        <!--#end .table-row -->

      </div>

      <div class="table-body" *ngIf="clientHandles">
        <div *ngFor="let clients of clientHandles"
          [class.hide]="!clients.state.isVisible"
          class="table-row">
          <span class="column-name"
            [title]="clients.data.FullName">
            {{clients.data.FullName}}
          </span>
          <!--#end .column-name -->
          <span class="column-email"
            [title]="clients.data.EmailAddress">
            {{clients.data.EmailAddress}}
          </span>
          <!--#end .column-email -->
          <span class="column-id"
            [title]="clients.data.IDNumber">
            {{clients.data.IDNumber}}
          </span>
          <!--#end .column-role -->
          <span class="column-cell-number"
            [title]="clients.data.MobileNumber">
            {{clients.data.MobileNumber}}
          </span>
          <!--#end .column-cell-number -->
          <span class="column-phone-number"
            [title]="clients.data.WorkPhone">
            {{clients.data.WorkPhone}}
          </span>
          <!--#end .column-phone-number -->
          <span class="column-fax-number"
            [title]="clients.data.WorkFax">
            {{clients.data.WorkFax}}
          </span>
          <!--#end .column-fax-number -->
          <span class="column-vat-number"
            [title]="clients.data.VATNumber">
            {{clients.data.VATNumber}}
          </span>
          <!--#end .column-vat-number -->
          <span class="column-address"
            [title]="clients.data.StreetAddressFull">
            {{clients.data.StreetAddressFull}}
          </span>
          <!--#end .column-address -->
          <!-- <span class="column-action"> -->
          <span class="clickable" style="padding: 0 !important;" (click)="onEditClient(clients.data)">
            <u><i class="fas fa-pencil-alt" aria-hidden="true"></i>
            Edit</u></span>
          <span class="clickable" style="padding: 0 !important;" (click)="onDeleteClient(clients.data)">
            <u><i class="fas fa-trash" aria-hidden="true"></i>
            Delete</u></span>
          <!-- </span> -->
          <!--#end .column-action -->
        </div>
        <!--#end .table-row -->

      </div>
      <!--#end .table-body -->

      <div class="table-foot">
        <div class="table-row">
          <span class="clickable" (click)="onAddNewClient()">
            <i class="fa fa-plus-circle fa-lg auxcon-green"></i>
            <u class="auxcon-green">ADD A CLIENT</u>
          </span>
        </div>
      </div>
      <!--#end .table-foot -->

      <!-- </div> -->
      <!--#end .table -->
    </div>
    <!--#end .well -->
  </div>
  <!--#end .container-fluid .container-page -->
</div>
<!-- #end container-fluid -->
<app-add-new-client
  [isVisible]="showAddClientModal"
  [(clients)]="activeClient"
  (closeModal)="onCloseModal()"
  (saveClients)="onSaveOtherClients($event)"></app-add-new-client>