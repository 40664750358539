import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { ApiService } from '../api.service';

export interface InviteUsers_Staging {
  // Id: string;
  EmailAddress: string;
  AccountType: string;
  TenantID: string;
  Domain: string;
  EntityType: string;
  EntityReference: string;
  UserFirstName: string;
  UserLastName: string;
  UserType: string;
  Status: string;
  StatusError: string;
  LastModifiedOn: string;
  IsClosed: boolean;
}
export const DEFAULT_INVITE_USERS_STAGING: InviteUsers_Staging = {
  // Id: '',
  EmailAddress: '',
  AccountType: '',
  TenantID: '',
  Domain: '',
  EntityReference: '',
  EntityType: '',
  UserFirstName: '',
  UserLastName: '',
  UserType: '',
  Status: '',
  StatusError: '',
  LastModifiedOn: '',
  IsClosed: false
};

export interface EntityContacts {
  ContactID: number;
  FullName: string;
  ContactTypeID: number;
}
@Injectable()
export class InviteService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor{}

  getEntity(entityTypeId: number): Observable<any> {
    console.log('API: ', this.api.endpoints.invite)
    return this.http.get<any>(this.api.endpoints.invite +
      '?contactTypeId=' + entityTypeId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      )
  } // end getEntity{}

  sendInvite(data: InviteUsers_Staging): Observable<any> {
    const postData = {
      id: 0,
      emailAddress: data.EmailAddress,
      accountType: data.AccountType,
      tenantID: data.TenantID,
      domain: data.Domain,
      entityType: data.EntityType,
      entityReference: data.EntityReference,
      userFirstName: data.UserFirstName,
      userLastName: data.UserLastName,
      userType: data.UserType,
      status: data.Status,
      statusError: data.StatusError,
      lastModifiedOn: data.LastModifiedOn,
      isClosed: data.IsClosed
    }
    return this.http.post<any>(this.api.endpoints.invite + '/SendInvite',
    data, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end sendInvite{}
} // end InviteService {}
