import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ManageBlackListRequest, ManageBlackListService } from '../../../services/manage-BlackList/manage-BlackList.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-add-lpc-blacklist-modal',
  templateUrl: './add-lpc-blacklist-modal.component.html',
  styleUrls: ['./add-lpc-blacklist-modal.component.scss']
})
export class AddLPCBlacklistModalComponent implements OnInit {
  @ViewChild('pdfViewer') public pdfViewer;

  @Input() isVisible: boolean;
  @Input() currentLawfirmId: number;
  @Input() selectedLawfirmName: string;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  isMandatory: boolean = false;
  manageLPCReportData: any[];
  currentLaufirmNme: string
  showDeletePopPrompt: boolean;
  isDeleteInPeogress: boolean = false;
  lawFirmBlackListId: number;
  showEditLPCModal: boolean = false
  currentLawfirmID: number;
  CurrentReportingAdvocateName: string;
  viewAttachedPdf: boolean = false;
  LPCDocumentName: string;

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private manageBlackListService: ManageBlackListService,
    private snackbarsService: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.currentLaufirmNme = this.selectedLawfirmName;
    this.manageLPCReports();
  }

  manageLPCReports() {
    this.loadingService.showOverlay();
    this.manageLPCReportData = [];
    if (this.currentLawfirmId) {
      this.loadingService.showOverlay();
      this.manageBlackListService.getbyLawfirmLPCReqList(this.currentLawfirmId)
        .subscribe({
          next: (response) => {
            this.manageLPCReportData = response;
            if (this.currentLawfirmId != null) {
              response.forEach(item => {
                this.manageLPCReportData.push()
              });
            }
          },
          complete: () => {
            // setTimeout(() => {
            this.loadingService.hideOverlay();
            // }, 3000);
          }
        })
    }
  }

  onEditLPC(LawfirmBlackListID, ReportingAdvocateName) {
    this.showEditLPCModal = true;
    this.currentLawfirmID = LawfirmBlackListID;
    this.CurrentReportingAdvocateName = ReportingAdvocateName;
  }
  closeEditLPCModal(refreshGrid: boolean) {
    this.loadingService.showOverlay();
    this.showEditLPCModal = false;
    if (refreshGrid) {
      this.manageLPCReports();
    }
    this.loadingService.hideOverlay();
  }

  downloadLPCDocument(filePath: string, LawfirmBlackListID: number,) {

    if (filePath == "LOD")
      this.LPCDocumentName = "LOD";
    else if (filePath == "LPC_Complaint")
      this.LPCDocumentName = "LPC Complaint";
    else if (filePath == "Statement")
      this.LPCDocumentName = "Advocate Statement";

    if (!filePath)
      return;
    else {
      this.viewAttachedPdf = true
      this.manageBlackListService.downloadLPCDocument(filePath, LawfirmBlackListID)
        .subscribe({
          next: (response) => {
            this.pdfViewer.name = 'name.pdf';
            this.pdfViewer.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
            this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
          },
        })
    }
  }

  dismissDownloadLPCDocument() {
    this.viewAttachedPdf = false;
  } // end dismiss()

  onDismiss() {
    this.loadingService.showOverlay();
    this.isVisible = false;
    this.isMandatory = false;
    this.closeModal.emit();
  } // end onDismiss()

  onDeletePopPromptShow(lawfirmBlacklistId: number) {
    this.showDeletePopPrompt = true;
    this.lawFirmBlackListId = lawfirmBlacklistId;
  }

  onHideDeletePopPrompt(e) {
    this.showDeletePopPrompt = false;
    if(e)
    this.closeModal.emit(true);
  } // end onHideDeletePopPrompt()

  onDeleteLawfirmListingsItem() {
    this.loadingService.showOverlay();
    if (this.isDeleteInPeogress)
      return;
    this.isDeleteInPeogress = true
    this.manageBlackListService.onDeleteLawfirmListingsItem(null, this.lawFirmBlackListId, null , true)
      .subscribe({
        next: (response) => {
          this.onHideDeletePopPrompt(true);
          this.manageLPCReports();
          this.isDeleteInPeogress = false;
        },
      })
  }

}
