import { Injectable } from '@angular/core';

@Injectable()
export class NavigationService {

  get canNavigate():boolean {
    return this._canNavigate;
  } // end canNavigate()

  set canNavigate(value: boolean) {
    this._canNavigate = value;
  } // end canNavigate()

  private _canNavigate: boolean;

  constructor() {
    this._canNavigate = true;
  } // end constructor()

} // end NavigationService{}
