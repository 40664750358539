import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { UserProfile, UserProfileService } from '../user-profile/user-profile.service';
import { catchError, retry } from 'rxjs/operators';
import { Category } from '../categories/categories.service';

export interface SubCategory extends Category {
  SubCategoryId: number;
  Category: string;
  SubCategory: string;
  RateType: string;
  ShowTimeAs: string;
  DefaultRate: number;
  UserName: string;
  IsMandatory: boolean;
}

export const DEFAULT_SUB_CATEGORY: SubCategory = {
  SubCategoryId: 0,
  Category: '',
  SubCategory: '',
  RateType: '',
  ShowTimeAs: '',
  DefaultRate: 0,
  UserName: '',
  IsMandatory: false
};

@Injectable()
export class SubCategoriesService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private userProfileService: UserProfileService
  ) { } // end constructor()

  /**
   * GET: returns all details for a single fee subcategory.
   * @param {number} subCategoryId The unique identifier of SubCategory
   * @returns {Observable<SubCategory>} Returns all details for a single fee
   * subcategory
   */
  getSubCategory(subCategoryId: number): Observable<any> {
    return this.http.get(this.api.endpoints.subCategory +
      '/' +  subCategoryId, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getSubCategory()

  /**
   * GET: return Category and SubCategory data
   * @returns {Observable<*>} Returns all fee categories and sub-categories
   * for a user.
   */
  getSubCategoryAll(): Observable<any> {
    let username = '';
    if (this.userProfileService.userProfile.isImpersonator) {
      username = this.userProfileService.selectedUserProfile.auxconUserName;
    } else {
      username = this.userProfileService.userProfile.auxconUserName;
    }

    return this.http.get(this.api.endpoints.subCategoryAll +
      '?UserName=' + username, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getSubCategoryAll()

  /**
   * PUT: updates category and subcategory configuration data
   * @param {SubCategory} subCategory The subcategory to be updated.
   * @returns {Observable<*>} Returns the newly updated subcategory.
   */
  updateSubCategory(subCategory: SubCategory): Observable<any> {
    return this.http.put(this.api.endpoints.subCategoryAll +
      '?id=' + subCategory.SubCategoryId, subCategory, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateSubCategory()
} // end SubCategoriesService{}
