import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdvocateDetailStatementService,
  AdvocateDetailStatement,
  ICrumb,
  LawfirmPaidStatement,
  DEFAULT_ADVOCATE_DETAIL_STATEMENT} from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import {
  PATH_ANALYTICS,
  PATH_ADVOCATE_DETAIL,
  PATH_REPORTS,
  PATH_ATTORNEY_DASHBOARD,
  PATH_ALL_ADVOCATES,
  PATH_UNPAID_INVOICES
} from '../../../services/appdata/app-config.service';
import { ReportsService, AllocatedToLawfirm } from '../../../services/reports/reports.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { ApiService } from '../../../services/api.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-advocate-detail',
  templateUrl: './advocate-detail.component.html',
  styleUrls: ['./advocate-detail.component.scss']
})
export class AdvocateDetailComponent implements OnInit, OnDestroy {

  private lawfirmId: number;
  private advocateId: number;
  advocateDetailStatement: AdvocateDetailStatement[];
  attorneyDetailStatement: AdvocateDetailStatement[];
  lawfirmPaidStatement: LawfirmPaidStatement[] = [];
  advocateDetail: AdvocateDetailStatement;
  crumbs: ICrumb[];
  showReportTable: boolean;
  generateButton = 'Generate Excel Statement';

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    LAWFIRM: 'Lawfirm'
  };

  allocatedToLawFirm: AllocatedToLawfirm[] = [];

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private advocateDetailService: AdvocateDetailStatementService,
    private loadingService: LoadingService,
    private snackbarService: SnackbarsService,
    private reportService: ReportsService,
    private userProfileService: UserProfileService,
    private advocateDetailStatementService: AdvocateDetailStatementService
  ) { } // end constructor()

  async ngOnInit() {
    this.advocateDetailStatement = [];
    this.attorneyDetailStatement = [];
    this.loadingService.showOverlay();
    // console.log('WHICH TAB WE ARE NAVIGATING TO: ', this.lawfirmId, this.advocateId, this.advocateDetailStatementService.activeTab);
    
    console.log('LOADED DATA: ', this.advocateDetailStatementService.activeTab);
     this.advocateDetailStatementService.selectedFirmTab = this.advocateDetailStatementService.activeTab;
    this.lawfirmId = +this.route.snapshot.paramMap.get('id');
    this.advocateId = +this.route.snapshot.paramMap.get('advocateId');

    this.advocateDetailService.lawfirmId = this.lawfirmId;
    this.advocateDetailService.advocateId = this.advocateId;
      this.crumbs = [
        {
          label: 'Live Statement',
          link: PATH_ATTORNEY_DASHBOARD
        }
      ];

    const snack: Snack = {
      label: 'loading Advocate Details..',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack).show();
  if (this.lawfirmId) {
    this.getAllocatedToLawfirm();

    console.log('ATTORNEY DASHOBOARD DATA VVPI: ', this.lawfirmId, this.advocateId);
    await lastValueFrom(this.advocateDetailService.getAdvocateDetailStatement(this.lawfirmId, this.advocateId)).then((data) => {
      this.advocateDetailStatement = data;
          this.loadingService.hideOverlay();
      });

    // if(this.advocateDetailStatementService.activeTab === 'view-all') {
    //   // this.advocateDetailStatement = JSON.parse(sessionStorage.getItem('advocateDetailStatement'))
    //   // .filter(s => s.ServiceProviderId === this.advocateId);
    //   if (this.advocateDetailStatement && this.advocateDetailStatement.length > 0) {

    //   } else {
    //     // this.advocateDetailService.
    //     // getAdvocateDetailStatement(this.lawfirmId, this.advocateId).subscribe({ next: (data) => {
    //     //   this.advocateDetailStatement = data;
    //     // }});
    //   }
    // } else {
    //   // this.advocateDetailService.
    //   // getAttorneyDetailStatement(this.lawfirmId, this.advocateId).subscribe({ next: (data) => {
    //   //   this.advocateDetailStatement = data;
    //   //   console.log('ATTORNEY DATA: ', data);
    //   // }});
    //   this.advocateDetailStatementService.activeTab === 'view-all'
    //   this.advocateDetailStatementService.selectedFirmTab = 'all';
    // }

    if (this.advocateDetailStatement && this.advocateDetailStatement.length > 0) {
    this.advocateDetail = this.advocateDetailStatement.filter(e => e.ServiceProviderId === this.advocateId)[0];
    if (this.advocateDetail && this.advocateDetail.ProviderFullName)  {
        this.crumbs.push({
          label: this.advocateDetail.ProviderFullName
        });
      }
        this.showReportTable = false;
    }
    } else {
      this.showReportTable = true;
    this.crumbs.push({
      label: 'Advocate statement'
    });
  }
  if (this.advocateId) {
    // this.lawfirmPaidStatement =  this.advocateDetailStatementService.getPaidByAdvocateStatement(this.advocateId)
    // .toPromise();
  //   this.lawfirmPaidStatement = JSON.parse(localStorage.getItem('lawfirmPaidStatement')).filter(s => s.AdvocateId === this.advocateId);
  // } else {
  //   // Bank transaction and paid invoices
  //   this.lawfirmPaidStatement = JSON.parse(localStorage.getItem('lawfirmPaidStatement'));
  //   if (this.lawfirmPaidStatement && this.lawfirmPaidStatement.length > 0) {

  //   } else {
      this.advocateDetailStatementService.getPaidStatement().subscribe({ next: (data) => {
        this.lawfirmPaidStatement = data
      },
      complete: () => {
        // localStorage.setItem('lawfirmPaidStatement', JSON.stringify(this.lawfirmPaidStatement));
      }
    });
    // }
    // this.advocateDetailStatementService.getPaidStatement().subscribe(paidData => {
    //   this.lawfirmPaidStatement = paidData;
    // });
  }
  this.loadingService.hideOverlay();
  } // end ngOnInit()

  getAllocatedToLawfirm() {
    this.allocatedToLawFirm = [];
    if (this.advocateId) {
      this.advocateDetailService.getAllocated_To_Lawfirm(this.advocateId).subscribe({ next: (data) => {
        this.allocatedToLawFirm =  data;
      }});
    }
  } // end getAllocatedToLawfirm()

  getAdvocateStatement(advocateNo) {
    this.allocatedToLawFirm = [];
    this.lawfirmPaidStatement = [];
    this.advocateDetailStatement = [];
    this.advocateDetail = Object.assign({}, DEFAULT_ADVOCATE_DETAIL_STATEMENT);
    this.loadingService.showOverlay();
    this.advocateDetailService.lawfirmId = this.lawfirmId;
    this.advocateDetailService.advocateId = advocateNo;
    if (advocateNo) {
      this.advocateDetailService.getAllocated_To_Lawfirm(advocateNo).subscribe({ next: (data) => {
        this.allocatedToLawFirm = data;
      }});
      // this.lawfirmPaidStatement =  this.advocateDetailStatementService.
      // getPaidByAdvocateStatement(advocateNo).toPromise();
      this.lawfirmPaidStatement = JSON.parse(localStorage.getItem('lawfirmPaidStatement'))
      .filter(s => s.AdvocateId === advocateNo);
    } else {
      // Bank transaction and paid invoices
      this.lawfirmPaidStatement = JSON.parse(localStorage.getItem('lawfirmPaidStatement'));
      if (this.lawfirmPaidStatement && this.lawfirmPaidStatement.length > 0) {

      } else {
        this.advocateDetailStatementService.getPaidStatement().subscribe({ next: (data) => {
          // On next
          this.lawfirmPaidStatement = data;
        },
      complete: () => {
        localStorage.setItem('lawfirmPaidStatement', JSON.stringify(this.lawfirmPaidStatement));
      }});
      }
    }
      this.crumbs = [
        {
          label: 'Live Statement',
          link: PATH_ATTORNEY_DASHBOARD
        }
      ];

    const snack: Snack = {
      label: 'loading Advocate Details..',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack).show();
    if (this.lawfirmId) {
      console.log('WHAT WE HAVE HERE?: ', this.advocateDetailService.activeTab);
      // this.advocateDetailStatement = JSON.parse(sessionStorage.getItem('advocateDetailStatement'))
      //   .filter(s => s.ServiceProviderId === advocateNo);
      // if (this.advocateDetailStatement && this.advocateDetailStatement.length > 0) {

      // } else {
        this.advocateDetailService.getAdvocateDetailStatement(this.lawfirmId, advocateNo).subscribe({next: (data) => {
          this.advocateDetailStatement = data;
        }});
      // }

      // this.advocateDetailService.getAdvocateDetailStatement(this.lawfirmId, advocateNo)
      //   .subscribe(advocate_detail => {
      this.advocateDetailStatement = this.advocateDetailStatement.filter(paid => paid.Due > 0);
      // this.advocateDetail = this.advocateDetailStatement;
      // this.advocateDetail = JSON.parse(sessionStorage.getItem('advocateDetailStatement'))
      //   .filter(e => e.ServiceProviderId === advocateNo)[0];
          // this.crumbs.push({
          //   label: 'Advocate statement',
          //   link: PATH_ADVOCATE_DETAIL
          // });
          this.crumbs.push({
            label: this.advocateDetail.ProviderFullName
          });
          if (this.advocateDetailService.activeTab) {

          } else {
           this.advocateDetailService.activeTab = 'view-all';
          }
          this.showReportTable = false;
          // this.loadingService.hideOverlay();
        // },
        //   error => {
        //     // On Error
        //     snack.label = 'Error Loading Advocate detail.';
        //     snack.type = SnackType.ERROR;
        //     this.snackbarService.dismiss().make(snack).show();
        //     this.loadingService.hideOverlay();
        //   },
        //   () => {
            // on complete
            // this.loadingService.hideOverlay();
          // });
    } else {
      this.showReportTable = true;
      this.crumbs.push({
        label: 'Advocate statement'
      });
    }
    this.loadingService.hideOverlay();
  } // end getAdvocateStatement()

  ngOnDestroy() {
    this.reportService.selectedReport = 'liveStatement';
  } // end ngOnDestroy()

} // end AdvocateDetailComponent{}
