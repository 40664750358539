import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvoiceDetails, InvoiceDetailsService } from '../../../services/invoice-details/invoice-details.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Snack, SnackbarsService, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { ApiService } from '../../../services/api.service';
import { InvoiceDocumentService } from '../../../services/invoice-document/invoice-document.service';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { PATH_MESSAGES } from '../../../services/appdata/app-config.service';

@Component({
  selector: 'app-invoice-preview',
  templateUrl: './invoice-preview.component.html',
  styleUrls: ['./invoice-preview.component.scss']
})
export class InvoicePreviewComponent implements OnInit {

  crumbs: ICrumb[];
  invoice: InvoiceDetails;
  invoiceId: number;
  plaintiff: string;
  defendant: string;

  _fileName;
  @ViewChild('pdfViewer') public pdfViewer;

  constructor(
    private route: ActivatedRoute,
    private invoiceDetailService: InvoiceDetailsService,
    private loadingService: LoadingService,
    private snackbarService: SnackbarsService,
    private api: ApiService,
    private invoiceDocumentService: InvoiceDocumentService
  ) { } // end constructor()

  ngOnInit() {
    this.invoiceId = +this.route.snapshot.paramMap.get('id');

     this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Loading invoice...',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, 5000).show();

    const url = this.api.endpoints.invoiceDocument + '?invoiceID=' + this.invoiceId;
    this.invoiceDocumentService.getInvoiceDocument(url)
      .subscribe(invoiceDoc => {
        // this._fileName = invoiceDoc;
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = invoiceDoc;
        this.pdfViewer.refresh();
      });

     this.invoiceDetailService.getInvoiceDetails(this.invoiceId)
     .subscribe({ next: (invoice_details) => {
       this.invoice = invoice_details;

       const desc = this.invoice.InvoiceParties.split(' // ');
       if (desc.length > 0) {
         this.plaintiff = desc[0];
       }
       if (desc.length > 1) {
         this.defendant = desc[1];
       }
     },
     error: (error) => {
       // On error
       snack.label = 'An error loading invoice,';
       snack.type = SnackType.ERROR;
       this.snackbarService.dismiss().make(snack).show();
       this.loadingService.hideOverlay();
     },
     complete: () => {
       // On complete
       snack.label = 'Invoice loaded.';
       snack.type = SnackType.SUCCESS;
       this.snackbarService.dismiss().make(snack).show();
       this.loadingService.hideOverlay();
      }
    });
    this.crumbs = [
      {
        label: 'Messages | Sent Items',
        link: PATH_MESSAGES
      },
      {
      label: 'Invoice',
    }];
  } // end ngOnInit()

  @Input()
  set fileName(fileName: string) {
    this._fileName = fileName;
  }
  get fileName() {
    return this._fileName;
  }

} // end InvoicePreviewComponent{}
