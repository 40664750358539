<div class="container-fluid">
  <div class="row">

    <div class="left-block col-sm-4">
      <div class="note-pane">
        <h5>Important Information Note</h5>
        <textarea
          *ngIf="editNote === false"
          class="note"
          [ngModel]="contactStickyNote && contactStickyNote.Note"
          placeholder="Enter the note here"
          readonly="true"></textarea>
        <textarea
          *ngIf="editNote === true"
          class="note"
          [(ngModel)]="workingStickyNote && workingStickyNote.Note"
          placeholder="Enter the note here"></textarea>
        <button
          *ngIf="editNote === false"
          class="btn btn-info"
          (click)="canEditNoteClicked()">Edit Client Information</button>
        <button
          *ngIf="editNote === true"
          class="btn btn-success"
          (click)="onNoteEditClicked()"
          [class.disabled]="!isNoteDirty"
          [disabled]="!isNoteDirty">SAVE</button>
      </div>
    </div>

    <div class="col-sm-4">
    </div>

    <div class="col-sm-4">
      <h5>User note logs</h5>
      <div class="table-responsive" style="max-height: 351px;">
        <table class="table">
          <tbody>
            <tr>
              <th scope="col">Action Date</th>
              <th scope="col">Logged By</th>
              <th scope="col">Action</th>
            </tr>
            <tr class="tr" *ngFor="let data of contactNoteActionLogsSTO">
              <td>{{data.NoteActionDate | date:'yyyy-MM-dd HH:mm'}}</td>
              <td>{{data.NoteLoggedByName}}</td>
              <td>{{data.NoteAction}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>