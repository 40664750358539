<div *ngIf="isVisible" class="modal">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onCancel()">
          <i class="fa fa-close"></i>
        </button>
      </div><!--#end .modal-header -->

      <div class="modal-body">
        <div class="container-fluid">
          <!-- <aside> -->
            <!-- <i class="fa fa-exclamation-triangle fa-lg text-warning"></i> -->
          <!-- </aside> -->
          <div class="row fa-icon">
            <!-- <i class="fal fa-exclamation-triangle"></i> -->
            <i class="fal fa-exclamation-triangle"></i>
            
          </div>
          <div class="row message">
            {{message}}
            <div *ngIf="link" class="link"><a [href]="formattedLink">{{link}}</a></div>
          </div>
        </div> <!-- #end .container-fluid -->
      </div><!--#end .modal-body -->

      <div class="modal-footer">
        <button
          class="btn btn-danger btn-end"
          (click)="onCancel()">Cancel
          </button>
        <button
          class="btn btn-success btn-end"
          (click)="onConfirm()">{{confirmText}}
          </button>
      </div><!--#end .modal-footer -->

    </div><!--#end .modal-dialog -->
  </div><!--#end .modal-content -->
</div><!--#end -->
