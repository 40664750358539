import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
    PATH_PROOF_OF_PAYMENTS,
    PATH_RECONCILE_BANK_TRANSACTIONS,
    PATH_SEND_STATEMENT,
    PATH_MANAGE_BANK_ACCOUNTS,PATH_PLATEFORM_BANK_TRANSACTION } from '../../services/appdata/app-config.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import {
        ProcessPaymentService,
        BankStatementSummary,
        DEFAULT_BANK_STATEMENT_SUMMARY } from '../../services/process-payment/process-payment.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';

@Component({
    selector: 'app-advocate-process',
    templateUrl: './advocate-process.component.html',
    styleUrls: ['./advocate-process.component.scss']
})
export class AdvocateProcessComponent implements OnInit {

    isUploadBankStatement = false;
    showImportedSuccessModal: boolean;
    count = 0;
    popCount = 0;
    bankStatementSummary: BankStatementSummary;
    profile: any;

    profileTypes = {
        ADVOCATE: 'Advocate',
        ASSISTANT: 'Assistant',
        ATTORNEY: 'Attorney',
        PRACTICE_MANAGER: 'Practice Manager',
        EXTERNAL_ATTORNEY: 'Attorney (External)',
        LAWFIRM: 'Lawfirm',
        EXTERNAL_LAWFIRM: 'Lawfirm (External)'
    };

    get isAssistant(): boolean {
        return Boolean(this.profile.isImpersonator) &&
            !Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY)
            && Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ASSISTANT);
    } // end isAssistant()
    constructor(
        private router: Router,
        private loadingService: LoadingService,
        private processPaymentService: ProcessPaymentService,
        private userProfileService: UserProfileService
        ) {
    } // end constructor()


    ngOnInit() {
        this.profile = this.userProfileService.userProfile;
        this.getNewBankTransactions();
    } // end ngOnit()

    getNewBankTransactions() {
        this.loadingService.showOverlay();
        // const data =  this.processPaymentService.getBankTransactions('').toPromise();
        this.processPaymentService.getBankTransactionCount().subscribe({next: (_next) => {this.count = _next; }});
        let pop = null;
        this.processPaymentService.getProofOfPayment().subscribe({next: (_pop) => { pop = _pop; 
            this.popCount = pop.length;
        }});
        // console.log('DD: ', pop.length);
        this.loadingService.hideOverlay();
    } // end getNewBankTransactions()


    onClick(reportName): void {
        this.loadingService.showOverlay();
        this.bankStatementSummary = Object.assign({}, DEFAULT_BANK_STATEMENT_SUMMARY);
        switch (reportName) {
            case 'proof-of-payments':
                this.loadingService.hideOverlay();
                this.router.navigate([PATH_PROOF_OF_PAYMENTS]);
                break;
            case 'reconcile-bank-transactions':
                this.processPaymentService.selectedTab = 'newBankTransactions';
                this.loadingService.hideOverlay();
                this.router.navigate([PATH_RECONCILE_BANK_TRANSACTIONS]);
                break;
            case 'processed-bank-transaction':
                this.processPaymentService.selectedTab = 'processedBankTransactions';
                this.loadingService.hideOverlay();
                this.router.navigate([PATH_RECONCILE_BANK_TRANSACTIONS]);
                break;
            case 'statement-of-account':
                this.loadingService.hideOverlay();
                this.router.navigate([PATH_SEND_STATEMENT]);
            break;
            case 'manage-bank-accounts':
                this.loadingService.hideOverlay();
                this.router.navigate([PATH_MANAGE_BANK_ACCOUNTS]);
            break;
            case 'account-transaction':
                this.loadingService.hideOverlay();
                this.router.navigate([PATH_PLATEFORM_BANK_TRANSACTION]);
            break;
            case 'import-bank-statements':
                this.processPaymentService.getBankStatementSummary().subscribe({ next: (bankInfo) => {
                    // On next
                    this.bankStatementSummary = bankInfo;
                },
                error: (error) => {
                    // On error
                    // this.bankStatementSummary.BankName = 'Stanbic Bank';
                    // this.bankStatementSummary.LastTransactionDate = '2019-04-26T00:00:00';
                    this.loadingService.hideOverlay();
                    this.isUploadBankStatement = true;
                    console.log('AN ERROR OCCURED: ', error);
                },
                complete: () => {
                    // on complete
                    this.loadingService.hideOverlay();
                    this.isUploadBankStatement = true;
                }
            });
            break;
        }
    } // end onClick()

    onCloseModel($event) {
        this.isUploadBankStatement = false;
    } // end onCloseModel()

    isImported(event) {
        console.log('IS IMPORTED? ', event);
        this.showImportedSuccessModal = event;
    } // end isImported()

    onCloseImportedSuccessModal() {
        this.showImportedSuccessModal = false;
    } // end onCloseImportedSuccessModal()

    onContinue() {
        this.router.navigate([PATH_RECONCILE_BANK_TRANSACTIONS]);
        this.showImportedSuccessModal = false;
    } // end onContinue()
} // end AdvocateProcessComponent{}
