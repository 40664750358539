<div *ngIf="!isEmailResponseSuccess" class="container-fluid page-wrapper">
    <form [formGroup]="signupForm">
        <div class="row justify-content-center" style="border: 1px solid #ccc;">
            
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-lg-12">
                        <h4 class="heading">GCB Defaulters List Registration</h4>
                    </div>
                </div>
                <div class="row" style="justify-content: start;">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label for="firstName">First Name<span class="text-danger">*</span></label>
                            <input type="text" class="form-control hb" (keypress)="onKeyUpName($event)"
                                autocomplete="off" maxlength="50" formControlName="firstName" required
                                aria-autocomplete="none">

                            <div
                                *ngIf="(signupForm.controls['firstName'].invalid && signupForm.controls['firstName'].touched) || (signupForm.controls['firstName'].invalid && formSubmitted===true)">
                                <span class="text-danger" *ngIf="signupForm.controls['firstName'].errors.required">
                                    This field is required
                                </span>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label for="surname">Surname<span class="text-danger">*</span></label>
                            <input type="text" class="form-control hb" id="surname" (keypress)="onKeyUpName($event)"
                                autocomplete="off" maxlength="50" formControlName="surname" required
                                aria-autocomplete="none">
                            <div
                                *ngIf="(signupForm.controls['surname'].invalid && signupForm.controls['surname'].touched) || (signupForm.controls['surname'].invalid && formSubmitted===true)">
                                <span class="text-danger" *ngIf="signupForm.controls['surname'].errors.required">
                                    This field is required
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label for="contactNumber">Contact Number<span class="text-danger">*</span></label>
                            <input type="text" class="form-control hb" id="phone-number"
                                onKeyPress="if(this.value.length==10) return false;" autocomplete="off"
                                formControlName="contactNumber" required aria-autocomplete="none" />
                            <div
                                *ngIf="(signupForm.controls['contactNumber'].invalid && signupForm.controls['contactNumber'].touched) || (signupForm.controls['contactNumber'].invalid && formSubmitted===true)">
                                <span class="text-danger" *ngIf="signupForm.controls['contactNumber'].errors.required">
                                    This field is required
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label for="email-address">Email Address<span class="text-danger">*</span></label>
                            <input 
                                type="text"
                                autocomplete="off"
                                class="form-control hb"
                                id="email"
                                formControlName="email"
                                required
                                name="email"
                                (blur)="onEmailBlur()"
                                aria-autocomplete="none" />
                            <div
                                *ngIf="(signupForm.controls['email'].invalid && signupForm.controls['email'].touched) || (signupForm.controls['email'].invalid && formSubmitted===true)">
                                <span class="text-danger" *ngIf="signupForm.controls['email'].errors.required">
                                    This field is required
                                </span>
                                <span class="text-danger" *ngIf="signupForm.controls['email'].errors.email">
                                    Please enter valid email address
                                </span>
                                <span class="text-danger" *ngIf="signupForm.controls['email'].errors.duplicate===true">
                                    Email already exist
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="barSociety">Bar Society</label><span class="text-danger">*</span>
                            <select class='select-option hb' name="barSociety" formControlName="barSociety" required
                                (change)='onChangeRegionalBar($event)'>
                                <option value="" selected="selected" [disabled]="false">-- Select --</option>
                                <option *ngFor='let bars of regionalBarDrp' [value]="bars.Id">{{bars.Name}}
                                </option>
                            </select>
                            <div
                                *ngIf="(signupForm.controls['barSociety'].invalid && signupForm.controls['barSociety'].touched) || (signupForm.controls['barSociety'].invalid && formSubmitted===true)">
                                <span class="text-danger" *ngIf="signupForm.controls['barSociety'].errors.required">
                                    This field is required
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="chamber">Chamber</label><span class="text-danger">*</span>
                            <select class='select-option hb' name="chamber" formControlName="chamber" required>
                                <option value="" selected="selected" [disabled]="false">-- Select --</option>
                                <option *ngFor='let chambers of chambersDrp' [value]="chambers.Id">
                                    {{chambers.ChamberName}}
                                </option>
                            </select>
                            <div
                                *ngIf="(signupForm.controls['chamber'].invalid && signupForm.controls['chamber'].touched) || (signupForm.controls['chamber'].invalid && formSubmitted===true)">
                                <span class="text-danger" *ngIf="signupForm.controls['chamber'].errors.required">
                                    This field is required
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ethnicity" style="margin-bottom: 2px;">Ethnicity</label>
                            <div class="d-flex justify-content-between">
                                <label for="African" class="radio-label">
                                    <input type="radio" name="ethnicity" id="African" value="African"
                                        formControlName="ethnicity" />
                                    <span class="checkmark"></span>
                                    African
                                </label>
                                <label for="white" class="radio-label">
                                    <input type="radio" name="ethnicity" id="white" value="White"
                                        formControlName="ethnicity" />
                                    <span class="checkmark"></span>
                                    White
                                </label>
                                <label for="coloured" class="radio-label">
                                    <input type="radio" name="ethnicity" id="coloured" value="Coloured"
                                        formControlName="ethnicity" />
                                    <span class="checkmark"></span>
                                    Coloured
                                </label>
                                <label for="indian/asian" class="radio-label">
                                    <input type="radio" name="ethnicity" id="indian/asian" value="Indian/Asin"
                                        formControlName="ethnicity" />
                                    <span class="checkmark"></span>
                                    Indian / Asian
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label for="gender" style="margin-bottom: 2px;">Gender</label>
                            <div class="radio-group">
                                <label for="male" class="radio-label">
                                    <input type="radio" name="gender" id="male" value="Male" formControlName="gender" />
                                    <span class="checkmark"></span>
                                    Male
                                </label>
                                <label for="female" class="radio-label">
                                    <input type="radio" name="gender" id="female" value="Female"
                                        formControlName="gender" />
                                    <span class="checkmark"></span>
                                    Female
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label for="seniority" style="margin-bottom: 2px;">Seniority</label>
                            <div class="radio-group">
                                <label for="senior" class="radio-label">
                                    <input type="radio" name="seniority" id="senior" value="Senior"
                                        formControlName="seniority" />
                                    <span class="checkmark"></span>
                                    Senior
                                </label>
                                <label for="junior" class="radio-label">
                                    <input type="radio" name="seniority" id="junior" value="Junior"
                                        formControlName="seniority" />
                                    <span class="checkmark"></span>
                                    Junior
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 pt-2">
                        <div class="form-group">
                            <label for="SAIDnumber">SA ID Number</label>
                            <input type="SAIDnumber" class="form-control hb" id="SAIDnumber"
                                onKeyPress="if(this.value.length==13) return false;" formControlName="SAIDnumber"
                                required autocomplete="off" aria-autocomplete="none">
                        </div>
                    </div>
                    <div class="col-lg-6 pt-2">
                        <div class="form-group row col-md-12 p-0">
                            <div class="form-group col-md-4">
                                <label>Admission Day</label>
                                <div class="input-group">
                                    <select name="admissionDay" id="admissionDay" class="input-date hb"
                                        formControlName="admissionDay">
                                        <option value="" selected="selected">Select Day</option>
                                        <option *ngFor="let day of admissionDay" value="{{day}}">{{day}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group col-md-4">
                                <label>Admission Month</label>
                                <div class="input-group">
                                    <select name="admissionMonth" id="admissionMonth" class="input-date hb"
                                        formControlName="admissionMonth">
                                        <option value="" selected="selected">Select Month</option>
                                        <option value="1">January</option>
                                        <option value="2">February</option>
                                        <option value="3">March</option>
                                        <option value="4">April</option>
                                        <option value="5">May</option>
                                        <option value="6">June</option>
                                        <option value="7">July</option>
                                        <option value="8">August</option>
                                        <option value="9">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </select>
                                    <div
                                        *ngIf="(signupForm.controls['admissionMonth'].invalid && signupForm.controls['admissionDay'].touched) ||  (signupForm.controls['admissionMonth'].invalid && signupForm.controls['admissionMonth'].touched) || (signupForm.controls['admissionMonth'].invalid && formSubmitted===true)">
                                        <span class="text-danger"
                                            *ngIf="signupForm.controls['admissionMonth'].errors.required">
                                            Month is required
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-md-4">
                                <label>Admission Year</label><span class="text-danger">*</span>
                                <div class="input-group">
                                    <select name="admissionYear" id="admissionYear" class="input-date hb"
                                        formControlName="admissionYear" required>
                                        <option value="" selected="selected">Select Year</option>
                                        <option *ngFor="let year of admissionYear" value="{{year.year}}">{{year.year}}
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="(signupForm.controls['admissionYear'].invalid && signupForm.controls['admissionDay'].touched) ||  (signupForm.controls['admissionYear'].invalid && signupForm.controls['admissionMonth'].touched) || (signupForm.controls['admissionYear'].invalid && signupForm.controls['admissionYear'].touched) || (signupForm.controls['admissionYear'].invalid && formSubmitted===true)">
                                        <span class="text-danger"
                                            *ngIf="signupForm.controls['admissionYear'].errors.required">
                                            Year is required
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-10" style="margin-top:40px;">
                <div class="row">
                    <div class="col-lg-12">
                        <button type=" button" style="float:right;" class="btn btn-primary"
                            (click)="signupForm.controls['email'].errors.AccountType == 'Non-Office' ? onSavePreRegister(false) : onSavePreRegister(true)">
                            {{signupForm.controls['email'].errors != null &&
                            signupForm.controls['email'].errors.AccountType === 'Non-Office' ? 'Verify' : 'Next'}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <app-prompt-unsaved-changes-modal
            *ngIf="showEmailExistsPopup"
            title="EMAIL ALREADY EXIST"
            message="Please take note that an account with this email address already exists. Please contact Auxcon at 012 343 9124 or email leigh-ann@auxcon.co.za to reset your login details."
            [isVisible]="showEmailExistsPopup"
            (cancel)="closeEmailExistsPopup()"
            btnTextCancel="Cancel"
            btnTextContinue="Ok"
            (continue)="closeEmailExistsPopup()"></app-prompt-unsaved-changes-modal>
    </form>
</div>

<div *ngIf="isEmailResponseSuccess" class="row container-fluid page-wrapper">
    <div class="row w-100" style="padding-top: 16vh; padding-bottom: 27vh;">
        <div class="col-sm-9" style="background: #fff;  padding: 20px; border-radius: 5px">
            <div class="row" style="color: #dbbb2d">
                <i class="fal fa-info-circle fa-10x" style="margin-bottom:25px;"></i>
            </div>
            <div class="alert alert-success Email-label-alert">
                <span>An email will be sent to {{emailSendTo}} containing a verification link.<br /></span>
            </div>
        </div>
    </div>

