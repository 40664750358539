<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid">

  <div class="bank-body">
    <div class="col-sm-12">
      <div class="row bank-account-search input-group" style="margin-bottom: 10px;">
        <input
          type="search"
          class="form-control"
          placeholder="Search"
          [(ngModel)]="searchTerm"
          (keyup)="onSearch()">
          <div class="input-group-append">
            <i *ngIf="!searchTerm" class="far fa-search"></i>
            <i *ngIf="searchTerm" (click)="clearSearch()" class="fa fa-close"></i>
          </div>
        </div>
  <!-- </div> -->
    <!-- <div class="col-sm-1">Actions</div>
    
  </div> -->
  
  <div class="table-responsive" style="height: 400px; padding-top: 5px;">
    <table class="table table-striped" style="border: 0;">
      <tbody class="tbody">
        <tr>
          <th
            scope="col"
            (click)="toggleSort('bank')"
            class="align-bottom">Bank
              <i class="fa fa-sort"
              [class.fa-sort-down]="sortBank">
            </i>
          <th
           scope="col"
           (click)="toggleSort('account-type')"
           class="align-bottom">Account Type
          <i class="fa fa-sort" [class.fa-sort-down]="sortAccountType"></i>
          <th
           scope="col"
           (click)="toggleSort('account-number')"
           class="align-bottom">Account Number
          <i class="fa fa-sort" [class.fa-sort-down]="sortAccountNumber"></i>
          <th
           scope="col"
           (click)="toggleSort('branch-code')"
           class="align-bottom">Branch Code
          <i class="fa fa-sort" [class.fa-sort-down]="sortBranchCode"></i>
          <th
           scope="col"
           (click)="toggleSort('primary')"
           class="align-bottom">Primary
          <i class="fa fa-sort" [class.fa-sort-down]="sortPrimary"></i>
          <th scope="col"> Last Transaction Date</th>
          <th scope="col">Active</th>
          <th></th>
        </tr>
        <tr
          *ngFor="let account of bankAccountHandle"
          class="tab-pane tab-fade"
          [class.hide]="!account.state.isVisible">
          <td width="20%">{{account.data.Bank}}</td>
          <td width="15%">{{account.data.AccountType}}</td>
          <td width="15%">{{account.data.AccountNumber}}</td>
          <td width="15%">{{account.data.BranchCode}}</td>
          <td width="15%">{{account.data.SPAccType}}</td>
          <td width="30%">{{account.data.LastStatementSent | date:'yyyy-MM-dd'}}</td>
          <td width="30%">{{account.data.Enabled}}</td>
          <td *ngIf="!isAssistant" (click)="onEditBankAccount(account.data)"><i class="fa fa-pencil" style="font-size: 12px;" aria-hidden="true"></i></td>
          <!-- <td *ngIf="account.data.SPAccType !=='Primary' && !isAssistant"  (click)="onDeleteBankAccount(account.data)"><i class="fa fa-trash-o" style="font-size: 14px; color: rgb(207, 98, 79);" aria-hidden="true"></i></td> -->
        </tr>
      </tbody>
    </table>
    <!-- <tfoot>
      <tr>
        <td>
          <div
            class="column-add-another-account clickable">
            <a (click)="openBankAccountModel()">
              <i class="fa fa-plus-circle auxcon-green"></i>
              <u class="auxcon-green">Add a bank account</u>
            </a>
          </div>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tfoot> -->
  </div> <!-- #end .table-responsive -->
  </div>
  </div>
  <div class="table-foot">
    <div class="table-row">
      <div *ngIf="!isAssistant" class="column-add-another-account clickable">
        <a (click)="openBankAccountModel()">
          <i class="fa fa-plus-circle auxcon-green"></i>
          <u class="auxcon-green">Add a bank account</u>
        </a>
      </div>
    </div>
  </div>

</div> <!-- #end .container-fluid -->

<app-add-edit-bank-account
  [isVisible]="showAddEditBankAccount"
  [addEditButtonText]="addEditButtonText"
  [bankAccountHandle]="selectedBankAccountHandle"
  (closeModal)="closeModal($event)"
  (saveBankAccount)="onSave($event)"></app-add-edit-bank-account>

  <app-profile-modal
    [isVisible]="showEditProfileModal"
    [userProfile]="profile"
    [selectedTab]="selectedTab"
    (closeModal)="closeModalProfile()"
    (saveUserDetails)="onSaveUserProfile($event)"></app-profile-modal>

    <app-prompt-unsaved-changes-modal
      title="Banking detail change"
      message="Are you sure you want to make this account your primary account? Primary account details appear on invoices and statements."
      [isVisible]="showErrorMessagePrompt"
      btnTextContinue="Ok"
      btnTextCancel="Cancel"
      (cancel)="onHideErrorMessagePrompt()"
      (continue)="onUpdateClick()"></app-prompt-unsaved-changes-modal>

    <app-prompt-unsaved-changes-modal
      title="Banking detail change"
      message="You are about to remove your secondary bank account, do you wish to proceed?"
      [isVisible]="showErrorDeletePrompt"
      btnTextContinue="Cancel"
      btnTextCancel="Proceed"
      (cancel)="onDeleteClick()"
      (continue)="onHideErrorDeletePrompt()"></app-prompt-unsaved-changes-modal>
