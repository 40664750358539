import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { BriefDocuments, BriefDocumentHandle } from '../../../services/matters/matters.service';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-brief-preview-modal',
  templateUrl: './brief-preview-modal.component.html',
  styleUrls: ['./brief-preview-modal.component.scss']
})
export class BriefPreviewModalComponent implements OnInit , AfterViewInit {

  title = 'Documents attached to invoice';
  showDocumentToggle = 'Show all documents attached to matter';
  showAllBrief = false;
  @Input() isVisible: boolean;
  @Input() briefDocumentHandle: BriefDocumentHandle[];
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();

  downloadLink = this.api.apiURL + '/MatterDocument?briefId=';

  constructor(
    private api: ApiService
  ) { } // end constructor()

  get hasSentWithDocuments(): boolean {
    return Boolean(this.briefDocumentHandle.filter(s => s.state.isVisible === true).length === 0);
  } // end hasSentWithDocuments()

  ngOnInit() {
  } // end ngOnInit()

  ngAfterViewInit() {
  } // end ngAfterViewInit()

  dismiss() {
    this.closeModal.emit('close-brief-preview');
  } // end dismiss()

  confirm() {
    this.closeModal.emit('close-brief-preview');
  } // end confirm()

  toggleShowAllDocuments() {
    this.showAllBrief = !this.showAllBrief;
    if (this.showAllBrief) {
      this.briefDocumentHandle.forEach(data => {
        data.state.isVisible = true;
        this.title = 'Documents attached to matter';
        this.showDocumentToggle = 'Show all documents attached to invoice';
      });
    } else {
      this.briefDocumentHandle.forEach(data => {
        if (data.data.Status) {
          data.state.isVisible = true;
        } else {
          data.state.isVisible = false;
        }
        this.title = 'Documents attached to invoice';
        this.showDocumentToggle = 'Show all documents attached to matter';
      });
    }
  } // end toggleShowAllDocuments()

  getExtension(filename) {
    return filename.toString().split('.')[1];
  } // end getExtension()
} // end BriefPreviewModalComponent{}
