import { Component, OnInit } from '@angular/core';
import {
  StickyNotes,
  StickyNoteHandle,
  StickyNoteService,
  DEFAULT_STICKY_NOTES,
  StickyNoteCategory,
  StickyNoteTypes,
  DEFAULT_STICKY_NOTE_CATEGORY,
  DEFAULT_STICKY_NOTE_TYPE
} from '../../../services/sticky-note/sticky-note.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import * as moment from 'moment';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit {

  stickyNote: StickyNotes[] = [];
  stickyNoteHandle: StickyNoteHandle[] = [];
  latestNote: StickyNotes;
  noteToSave = Object.assign({}, DEFAULT_STICKY_NOTES);

  stickyNoteCategory: StickyNoteCategory[] = [];
  selectedNoteCategoryText = '';
  selectedNoteSubCategoryText = '';
  selectedNoteCategory: StickyNoteCategory = Object.assign({}, DEFAULT_STICKY_NOTE_CATEGORY);
  stickyNoteTypes: StickyNoteTypes[] = [];
  selectedNoteType: StickyNoteTypes = Object.assign({}, DEFAULT_STICKY_NOTE_TYPE);

  get isSaveable(): boolean {
    return Boolean(this.selectedNoteCategory && this.selectedNoteCategory.Id > 0) &&
            Boolean(this.selectedNoteType && this.selectedNoteType.Id > 0) &&
            Boolean(this.noteToSave.Note);
  }

  constructor(
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private stickyNoteService: StickyNoteService,
    private userProfileService: UserProfileService
  ) { } // end contructor()

   ngOnInit() {
    this.latestNote = Object.assign({}, DEFAULT_STICKY_NOTES);
    this.latestNote = Object.assign({}, DEFAULT_STICKY_NOTES);
    this.loadingService.showOverlay();
    
    this.stickyNoteService.getStickyNoteCategory().subscribe({
      next: (data) => {
        this.stickyNoteCategory =  data;
      }
    })
    this.stickyNoteCategory.sort((a, b) => {
      return a.Category.toUpperCase().localeCompare(b.Category.toUpperCase());
    });
     this.stickyNoteService.getStickyNotes().subscribe({
       next: (_note) => {
        if (_note) {
          this.stickyNote = _note;
          this.stickyNote.sort((a, b) => b.CaptureDate.toUpperCase().localeCompare(a.CaptureDate.toUpperCase()));
          this.getStickyNoteInit();
          this.latestNote = this.stickyNote[0];
        }
       },
       error: (error) => {
        this.loadingService.hideOverlay();
       },
       complete: () => {
        this.loadingService.hideOverlay();
       }
     });
       
      
    //   _note => {
    //    // On next
    //    if (_note) {
    //      this.stickyNote = _note;
    //      this.stickyNote.sort((a, b) => b.CaptureDate.toUpperCase().localeCompare(a.CaptureDate.toUpperCase()));
    //      this.getStickyNoteInit();
    //      this.latestNote = this.stickyNote[0];
    //    }
    //  },
    //  error => {
    //    // On error
    //    this.loadingService.hideOverlay();
    //  },
    //  () => {
    //    // On complete
    //    this.loadingService.hideOverlay();
    //  });
  } // end ngOnInit()

  getStickyNoteInit() {
    this.stickyNoteHandle = [];
    if (this.stickyNote) {
      this.stickyNote.forEach(note => {
        this.stickyNoteHandle.push({
          data: note,
          state: {
            isVisible: true,
            isSelected: false,
            isOpen: false
          }
        });
      });
    }
  } // end getStickyNoteInit()

  onSelectCategory(category: TypeaheadMatch): void {
    this.selectedNoteType = Object.assign({}, DEFAULT_STICKY_NOTE_TYPE);
    this.selectedNoteCategory = Object.assign({}, DEFAULT_STICKY_NOTE_CATEGORY);
    this.selectedNoteCategoryText = category.item.Category;
    this.selectedNoteCategory = category.item;
    this.onGetSubCategory(category.item.Id);
  } // end onSelectCategory()

  onCategoryInput(event) {
    if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
      this.selectedNoteCategoryText = '';
      this.selectedNoteSubCategoryText = '';
      this.selectedNoteType = Object.assign({}, DEFAULT_STICKY_NOTE_TYPE);
      this.selectedNoteCategory = Object.assign({}, DEFAULT_STICKY_NOTE_CATEGORY);
      this.stickyNoteTypes = [];
    }
  } // end onCategoryInput()

  onGetSubCategory(categoryId) {
    this.loadingService.showOverlay();
    this.stickyNoteService.getStickyNoteType(categoryId).subscribe({
      next: (data) => {
        this.stickyNoteTypes = data;
      }
    });
    this.stickyNoteTypes.sort((a, b) => {
      return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase());
    });
    this.loadingService.hideOverlay();
  }

  onSelectSubCategory(event: TypeaheadMatch): void {
    this.selectedNoteType = Object.assign({}, DEFAULT_STICKY_NOTE_TYPE);
    this.selectedNoteSubCategoryText = event.item.Name;
    this.selectedNoteType = event.item;
  } // end onSelectSubCategory()

  onSubcategoryInput(event) {
    if (event.inputType === 'deleteContdeleteContentBackwardentBackward' || Boolean(event.target.value.length < 3)) {
      this.selectedNoteSubCategoryText = '';
      this.selectedNoteType = Object.assign({}, DEFAULT_STICKY_NOTE_TYPE);
    }
  } // end onSubcategoryInput()

  onNoteSave() {
    const snack: Snack = {
      label: 'Save note...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.loadingService.showOverlay();
    this.noteToSave.NoteLoggedById = this.userProfileService.userProfile.serviceProviderID;
    this.noteToSave.NoteLoggedBy = this.userProfileService.userProfile.personalDetails.fullName;
    this.noteToSave.NoteLoggedByProfileType = this.userProfileService.userProfile.profileType;
    this.noteToSave.ClientId = this.userProfileService.selectedUserProfile.serviceProviderID;
    this.noteToSave.NoteSubject = this.selectedNoteCategory.Category + ' - ' + this.selectedNoteType.Name;
    this.noteToSave.CaptureDate = moment().format('YYYY-MM-DD HH:mm:ss');

    this.stickyNoteService.postStickyNote(this.noteToSave).subscribe({
      next: (saved_data) => {

      },
      error: (error) => {
        snack.label = 'Error saving a note.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.noteToSave = Object.assign({}, DEFAULT_STICKY_NOTES);
        this.selectedNoteCategory = Object.assign({}, DEFAULT_STICKY_NOTE_CATEGORY);
        this.selectedNoteType = Object.assign({}, DEFAULT_STICKY_NOTE_TYPE);
        this.stickyNoteService.getStickyNotes().subscribe({
          next: (data) => {
            this.stickyNote = data;
          }
        });
        if (this.stickyNote) {
          this.stickyNote.sort((a, b) => b.CaptureDate.toUpperCase().localeCompare(a.CaptureDate.toUpperCase()));
          this.getStickyNoteInit();
          this.latestNote = Object.assign({}, DEFAULT_STICKY_NOTES);
          this.latestNote = this.stickyNote[0];
        }
        this.selectedNoteCategoryText = '';
        this.selectedNoteSubCategoryText = '';
        this.stickyNoteTypes = [];
        snack.label = 'Note saved.';
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    });


      // saved_data => {
      //   // On next
      // },
      // error => {
      //   // On error
      //   snack.label = 'Error saving a note.';
      //   snack.type = SnackType.ERROR;
      //   this.snackbarsService.dismiss().make(snack, 5000).show();
      //   this.loadingService.hideOverlay();
      // },
      // () => {
      //   // On complete
      //   this.noteToSave = Object.assign({}, DEFAULT_STICKY_NOTES);
      //   this.selectedNoteCategory = Object.assign({}, DEFAULT_STICKY_NOTE_CATEGORY);
      //   this.selectedNoteType = Object.assign({}, DEFAULT_STICKY_NOTE_TYPE);
      //   this.stickyNoteService.getStickyNotes().subscribe({
      //     next: (data) => {
      //       this.stickyNote = data;
      //     }
      //   });
      //   if (this.stickyNote) {
      //     this.stickyNote.sort((a, b) => b.CaptureDate.toUpperCase().localeCompare(a.CaptureDate.toUpperCase()));
      //     this.getStickyNoteInit();
      //     this.latestNote = Object.assign({}, DEFAULT_STICKY_NOTES);
      //     this.latestNote = this.stickyNote[0];
      //   }
      //   this.selectedNoteCategoryText = '';
      //   this.selectedNoteSubCategoryText = '';
      //   this.stickyNoteTypes = [];
      //   snack.label = 'Note saved.';
      //   snack.type = SnackType.SUCCESS;
      //   this.snackbarsService.dismiss().make(snack).show();
      //   this.loadingService.hideOverlay();
      // });
  } // end onNoteSave()

} // end ConversationComponent{}
