<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="container-fluid">
  <app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>

  <div class="messages-container">
    <div class="column-tabs">
      <div class="tab" [class.active]="activeTab === 'inbox'" (click)="setActiveTab(tabs.inbox)">Inbox
        <i title="Unread messages" *ngIf="inboxMessageCounter != 0"
          class="fas fa-circle message-icon"><b>{{inboxMessageCounter}}</b></i>
      </div>
      <!-- <div
        class="tab"
        [class.active]="activeTab === 'sent-items'"
        (click)="setActiveTab(tabs.sentItems)">Sent items</div> -->
    </div> <!-- #end .column-tabs -->

    <div class="column-display-area">
      <!-- Tab for inbox messages -->
      <div class="display-options" *ngIf="activeTab === 'inbox'">
        <span class="column-search">
          <button class="btn btn-text">
            <i class="fa fa-search"></i></button>
          <input class="input-search" type="search" placeholder="Search messages"
            (input)="this.searchItems.next($event)" [(ngModel)]="searchTerm">
        </span>
        <span class="column-order-by">
          <label for="sort-by">Sort by:</label>
          <select id="sort-by" data-placeholder="'Another option'" aria-placeholder="Filter" (change)="orderMessages()"
            [(ngModel)]="orderBy">
            <option value="" disabled selected hidden>Filter option</option>
            <option *ngFor="let option of orderingOptions" [value]="option">
              {{option | titlecase}}
            </option>
          </select>
          <button class="btn btn-toggle" (click)="toggleAscending()">
            <i class="fa" [class.fa-sort-amount-desc]="!sortAscending" [class.fa-sort-amount-asc]="sortAscending"></i>
          </button>
        </span>
        <button class="btn mt-2" (click)="refreshList()" style="color: #646464; font-weight: 100 !important;">
          <i class="fa fa-refresh"></i> Refresh
        </button>
      </div> <!-- #end .display-options -->

      <!-- Tab for sent items messages -->
      <div class="display-options" *ngIf="activeTab === 'sent-items'">
        <span class="column-search">
          <button class="btn btn-text" (click)="filterInboxMessages()">
            <i class="fa fa-search"></i></button>
          <input class="input-search" type="search" placeholder="Search messages" (keyup)="performSearch()"
            [(ngModel)]="searchTerm">
        </span>
        <span class="column-order-by">
          <label for="sort-by">Sort by:</label>
          <select id="sort-by" data-placeholder="'Another option'" aria-placeholder="Filter" (change)="orderMessages()"
            [(ngModel)]="orderBy">
            <option value="" disabled selected hidden>Filter option</option>
            <option *ngFor="let option of orderingOptions" [value]="option">
              {{option | titlecase}}
            </option>
          </select>
          <button class="btn btn-toggle" (click)="toggleAscending()">
            <i class="fa" [class.fa-sort-amount-desc]="!sortAscending" [class.fa-sort-amount-asc]="sortAscending"></i>
          </button>
        </span>
      </div> <!-- #end .display-options -->

      <!-- Inbox messages -->
      <div class="table" *ngIf="activeTab === 'inbox'">

        <div class="responsive-table" [class.hide]="activeTab !== tabs.inbox">
          <table class="table table-striped" id="detail_table" style="padding: 0 !important;" cellspacing="0">
            <tbody>
              <tr>
                <td class="clickable" (click)="toggleSort('inbox-date')">Date <i class="fa fa-sort"
                    [class.fa-sort-down]="sortNoteDate"></i></td>
                <td class="clickable" (click)="toggleSort('inbox-from')">From <i class="fa fa-sort"
                    [class.fa-sort-down]="sortFrom"></i></td>
                <td>IN RE</td>
                <!-- <i class="fa fa-sort" [class.fa-sort-down]="sortNoteType"></i> -->
                <td class="clickable" (click)="toggleSort('inbox-category')">Category <i class="fa fa-sort"
                    [class.fa-sort-down]="sortReference"></i></td>
                <td class="clickable" (click)="toggleSort('inbox-subcategory')">Subcategory <i class="fa fa-sort"
                    [class.fa-sort-down]="sortReference"></i></td>
                <td>Subject</td>
                <!-- <i class="fa fa-sort" [class.fa-sort-down]="sortNoteSubject"></i> -->
                <td></td>
                <td></td>
              </tr>
              <ng-container *ngFor="let inbox of mainInboxData">
                <tr class="accordion-toggle parent" [id]=inbox.data.Id [attr.data-target]="'#' + inbox.data.Id"
                  data-role="expander">
                  <td class="inbox-date-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserCreatorStatus)}">
                    {{inbox.data.CaptureDate | date:'yyyy-MM-dd'}}</td>
                  <td class="inbox-from-user-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserCreatorStatus)}">
                    {{inbox.data.UserAssignedFullName}}</td>
                  <td class="inbox-reference-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserCreatorStatus)}">
                    {{inbox.data.ObjectTypeDescription}}
                  </td>
                  <td class="inbox-category-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserCreatorStatus)}">
                    {{inbox.data.Category}}
                  </td>
                  <td class="inbox-subcategory-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserCreatorStatus)}">
                    {{inbox.data.Subject}}</td>
                  <td class="inbox-subject-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserCreatorStatus)}">
                    {{inbox.data.InternalText}}</td>
                  <td class="inbox-message-icon-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserCreatorStatus)}">
                    <i *ngIf="inbox.data.UserCreatorStatus === false" title="unread message" class="fa fa-envelope"></i>
                    <i *ngIf="inbox.data.UserCreatorStatus === true" class="fa fa-envelope-open">
                      <!-- <i *ngIf="getActionReminder(inbox.data)" class="fal fa-clock" title="a reminder"></i> -->
                    </i>
                  </td>
                  <td *ngIf="inbox.state.isVisible">
                    <span class="inbox-message-close-open" data-toggle="collapse" (click)="onRowClickExpand(inbox)"
                      [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserCreatorStatus)}">
                      <label *ngIf="!inbox.state.isOpen" class="clickable">Open</label>
                      <label *ngIf="inbox.state.isOpen" class="clickable">Close</label>
                      <i *ngIf="!inbox.state.isOpen" class="far fa-chevron-down"></i>
                      <i *ngIf="inbox.state.isOpen" class="far fa-chevron-up"></i>
                    </span>
                  </td>
                </tr>
                <tr *ngIf="inbox.state.isOpen" style="display: table-row !important;" [id]="inbox.data.Id"
                  class="accordion-body collapse reveal" [class]="'child-' + inbox.data.Id" aria-expanded="true">
                  <td colspan="7">
                    <div class="row" style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <i class="far fa-question-circle"></i>
                        <strong *ngIf="inbox.data.ObjectName === 'Invoice'"><label> {{inbox.data.QuestionId === null ?
                            'IN RE' : 'Request for instruction'}} on <u class="clickable" style="color:blue;"
                              (click)="onInvoicePreview(inbox.data)">{{inbox.data.ObjectTypeDescription}}</u> on
                            {{inbox.data.NoteAbout}}
                            {{inbox.data.Category}}</label></strong>
                        <strong *ngIf="inbox.data.ObjectName === 'Contact'"><label> {{inbox.data.QuestionId === null ?
                            'IN RE' : 'Request for instruction'}} {{inbox.data.ObjectTypeDescription}}
                            {{inbox.data.Category}}</label></strong>
                      </div>
                    </div>
                    <div class="row" *ngIf="inbox.data" style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <label><small>Last Instruction {{lastInstructionNotes &&
                            lastInstructionNotes.InstructionCaptureDate |
                            date:'yyyy-MM-dd'}}</small><br /><strong>{{lastInstructionNotes &&
                            lastInstructionNotes.UserCreatorFullName}}:</strong> {{lastInstructionNotes &&
                          lastInstructionNotes.ResponseText}} {{lastInstructionNotes &&
                          lastInstructionNotes.InstructionText}} {{lastInstructionNotes &&
                          lastInstructionNotes.InternalText}}</label>
                      </div>
                    </div>
                    <div class="row" style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <label><small>Collector note {{inbox.data.CaptureDate |
                            date:'yyyy-MM-dd'}}</small><br /><strong>{{inbox.data.UserOwnerFullName}}:</strong>
                          {{inbox.data.Category}} {{inbox.data.Subject}} {{inbox.data.InternalText}}</label>
                      </div>
                    </div>
                    <div class="row" *ngIf="inbox.data.QuestionId > 0 && inbox.data.InstructionResponseId"
                      style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <label><strong>Instruction question:</strong> {{inbox.data.QuestionText}}</label>
                      </div>
                    </div>
                    <!-- <div class="row" *ngIf="inbox.data.InstructionResponseId > 0" style="padding-top: 0; padding-bottom: 0;">

                      <div class="form-group">
                        <label><strong>You:</strong> {{inbox.data.ExternalText}}</label>
                      </div>
                    </div> -->
                    <div class="row" style="padding-top: 0; padding-bottom: 0;"
                      *ngIf="inbox.data.InstructionResponseId > 0">
                      <div class="form-group">
                        <label>
                          <strong *ngIf="inbox.data.NoteInsertedLevel === 3">Advocate: </strong>
                          <strong *ngIf="inbox.data.NoteInsertedLevel === 2">PM: </strong>
                          {{inbox.data.ResponseText === null ? '' : inbox.data.ResponseText}} {{
                          inbox.data.InstructionText}}</label>
                      </div>
                    </div>
                    <div class="row" style="padding-top: 0; padding-bottom: 0;"
                      *ngIf="inbox.data.InstructionResponseId > 0 && inbox.data.ResolutionText">
                      <div class="form-group">
                        <label><strong>You:</strong> {{ inbox.data.ResolutionText}}</label>
                      </div>
                    </div>
                    <div class="row" *ngIf="inbox.data.InstructionResponseId === 0 && collectorChecked">
                      <div class="control-group" style="padding-top: 0; padding-bottom: 0;">
                        <label for="action">Action</label>
                        <select class="form-control" id="action" (change)="onNoteResponseSelect()"
                          [(ngModel)]="selectedResponseId">
                          <option value=0 disabled selected hidden>Select action</option>
                          <option *ngFor="let action of instructionResponses" [value]="action.Id">
                            {{action.Response}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="control-group">
                        <label for="action">Comment</label>
                        <textarea
                          *ngIf="inbox.data.InstructionResponseId > 0 && inbox.data.ResponseText && !inbox.data.ResolutionText"
                          class="input-control" [(ngModel)]="resolutionText"></textarea>
                        <textarea *ngIf="inbox.data.InstructionResponseId === 0" class="input-control"
                          [(ngModel)]="instructionText"></textarea><!-- && inbox.data.QuestionId > 0 -->

                        <div class="option-groups" style="margin-right: 5px; margin-left: 5px;"
                          *ngIf="inbox.data.InstructionResponseId === 0">
                          <div class="visibility-group">
                            <div class="">
                              <div class="control-group">
                                <label style="width: 90% !important;" for="table">Respond to Collector </label>&nbsp;
                                <app-small-radiobox [(checked)]="collectorChecked"
                                  (click)="toggleColAdvCheckbox('collector')">
                                </app-small-radiobox>
                              </div>
                            </div>
                            <div class="">
                              <div class="control-group">
                                <label style="width: 90% !important;" for="table">Request Instruction </label>&nbsp;
                                <app-small-radiobox [(checked)]="advocateChecked"
                                  (click)="toggleColAdvCheckbox('advocate')">
                                </app-small-radiobox>
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          *ngIf="inbox.data.InstructionResponseId > 0 && inbox.data.ResponseText && !inbox.data.ResolutionText"
                          class="btn btn-success" (click)="onResolutionSave()" [class.disabled]="!isSaveable"
                          [disabled]="!isSaveable">Save</button>
                        <button
                          *ngIf="inbox.data.InstructionResponseId === 0 && inbox.data.QuestionId > 0 && advocateChecked && !collectorChecked"
                          class="btn btn-success" (click)="onResolutionSave()" [class.disabled]="!isInstructionSaveable"
                          [disabled]="!isInstructionSaveable">Forward to Advocate</button>
                        <button *ngIf="inbox.data.InstructionResponseId === 0 && !advocateChecked && collectorChecked"
                          class="btn btn-success" (click)="onInstructionSave()"
                          [class.disabled]="!isInstructionSaveable" [disabled]="!isInstructionSaveable">Send
                          instruction</button> <!-- && inbox.data.QuestionId > 0 -->
                        <button
                          *ngIf="!inbox.data.QuestionId > 0 && inbox.data.InstructionResponseId === 0 && ((advocateChecked && !collectorChecked) || (!advocateChecked && collectorChecked))"
                          class="btn btn-danger" (click)="onPMNoteDismiss(inbox)">Dismiss</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div> <!-- #end .responsive-table -->
        <div *ngIf="displayListCount > 0" style="float: left; padding: 10px; font-weight: 700; font-size: 12px;">
          {{ displayListCount + " out of " + displayTotalCount }}
        </div>
        <div *ngIf="currentCount < displayTotalCount" style="float: right;">
          <button class="btnLoadMoreEnable" (click)="LoadMore()">
            <a class="fa fa-arrow-down fa-lg" style="margin-right: 5px;color:#8abd37"></a>
            <u class="auxcon-green" style="text-transform:uppercase;color:#8abd37;font-size:12px;font-weight:700;">Load
              More</u>
          </button>
        </div>
      </div> <!-- #end .table -->


      <!-- Sent items messages -->
      <div class="table" *ngIf="activeTab === 'sent-items'">

        <div class="responsive-table" [class.hide]="activeTab !== tabs.sentItems">
          <table class="table table-striped" id="detail_table" style="padding: 0 !important;" cellspacing="0">
            <tbody>
              <tr>
                <td>Date <i class="fa fa-sort" [class.fa-sort-down]="sortNoteDate"></i></td>
                <td>To <i class="fa fa-sort" [class.fa-sort-down]="sortFrom"></i></td>
                <td>IN RE <i class="fa fa-sort" [class.fa-sort-down]="sortNoteType"></i></td>
                <td>Category <i class="fa fa-sort" [class.fa-sort-down]="sortReference"></i></td>
                <td>Subcategory <i class="fa fa-sort" [class.fa-sort-down]="sortReference"></i></td>
                <td>Subject <i class="fa fa-sort" [class.fa-sort-down]="sortNoteSubject"></i></td>
                <td></td>
                <td></td>
              </tr>
              <ng-container *ngFor="let inbox of sentNotesHandle">
                <tr class="accordion-toggle parent" [id]=inbox.data.Id [attr.data-target]="'#' + inbox.data.Id"
                  data-role="expander">
                  <td class="inbox-date-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onSentRowClickExpand(inbox)">
                    {{inbox.data.CaptureDate | date:'yyyy-MM-dd'}}</td>
                  <td class="inbox-from-user-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onSentRowClickExpand(inbox)">
                    {{inbox.data.UserAssignedFullName}}</td>
                  <td class="inbox-reference-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onSentRowClickExpand(inbox)">{{inbox.data.ObjectTypeDescription}}
                  </td>
                  <td class="inbox-category-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onSentRowClickExpand(inbox)">{{inbox.data.Category}}
                  </td>
                  <td class="inbox-subcategory-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onSentRowClickExpand(inbox)">
                    {{inbox.data.Subject}}</td>
                  <td class="inbox-subject-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onSentRowClickExpand(inbox)">{{inbox.data.QuestionText}} {{inbox.data.ExternalText}}</td>
                  <td class="inbox-message-icon-column clickable" *ngIf="inbox.state.isVisible" scope="row"
                    (click)="onSentRowClickExpand(inbox)">
                    <i class="fa fa-envelope-open">
                      <!-- <i *ngIf="getSentItemsActionReminder(inbox.data)" class="fal fa-clock" title="a reminder"></i> -->
                    </i>
                  </td>
                  <td *ngIf="inbox.state.isVisible">
                    <span class="inbox-message-close-open clickable" data-toggle="collapse"
                      (click)="onSentRowClickExpand(inbox)">
                      <label *ngIf="!inbox.state.isOpen">Open</label>
                      <label *ngIf="inbox.state.isOpen">Close</label>
                      <i *ngIf="!inbox.state.isOpen" class="far fa-chevron-down"></i>
                      <i *ngIf="inbox.state.isOpen" class="far fa-chevron-up"></i>
                    </span>
                  </td>
                </tr>
                <tr *ngIf="inbox.state.isOpen" [id]="inbox.data.Id" class="accordion-body collapse reveal"
                  [class]="'child-' + inbox.data.Id" aria-expanded="true">
                  <td colspan="7">
                    <div class="row" style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <i class="far fa-question-circle"></i>
                        <strong *ngIf="inbox.data.ObjectName === 'Invoice'"><label> {{inbox.data.QuestionId === null ?
                            'IN RE' : 'Request for instruction'}} <u class="clickable"
                              (click)="onInvoicePreview(inbox.data)">{{inbox.data.ObjectTypeDescription}}</u>
                            {{inbox.data.Category}}</label></strong>
                        <strong *ngIf="inbox.data.ObjectName === 'Contact'"><label> {{inbox.data.QuestionId === null ?
                            'IN RE' : 'Request for instruction'}} {{inbox.data.ObjectTypeDescription}}
                            {{inbox.data.Category}}</label></strong>
                      </div>
                    </div>
                    <div class="row" *ngIf="inbox.data.QuestionId > 0" style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <label><strong>Instruction question:</strong> {{inbox.data.QuestionText}}</label>
                      </div>
                    </div>
                    <div class="row" *ngIf="inbox.data.ExternalText" style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <label><strong>You:</strong> {{inbox.data.ExternalText}}</label>
                      </div>
                    </div>
                    <div class="row" *ngIf="inbox.data.QuestionId > 0" style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <label><small>Collector note</small><br /><strong>{{inbox.data.UserOwnerFullName}}:</strong>
                          {{inbox.data.InternalText}}</label>
                      </div>
                    </div>
                    <div class="row" *ngIf="inbox.data.Personal" style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <label><strong>Personal note:</strong> {{inbox.data.InternalText}}</label>
                      </div>
                    </div>
                    <div class="row" style="padding-top: 0; padding-bottom: 0;"
                      *ngIf="inbox.data.InstructionResponseId > 0">
                      <div class="form-group">
                        <label><strong *ngIf="inbox.data.NoteInsertedLevel === 3">Advocate: </strong>
                          <strong *ngIf="inbox.data.NoteInsertedLevel === 2">PM: </strong> {{inbox.data.ResponseText ===
                          null ? '' : inbox.data.ResponseText}} {{ inbox.data.InstructionText}}</label>
                      </div>
                    </div>
                    <div class="row" style="padding-top: 0; padding-bottom: 0;"
                      *ngIf="inbox.data.InstructionResponseId > 0 && inbox.data.ResolutionText">
                      <div class="form-group">
                        <label><strong>You:</strong> {{ inbox.data.ResolutionText}}</label>
                      </div>
                    </div>
                    <!-- <div class="row" *ngIf="inbox.data.InstructionResponseId === 0">
                      <div class="control-group" style="padding-top: 0; padding-bottom: 0;">
                        <label for="action">Action</label>
                        <input [(ngModel)]="selectedResponseText" [typeahead]="instructionResponses"
                          typeaheadOptionField="Response" [typeaheadSingleWords]="false" placeholder="Select or start typing"
                          (input)="onInputResponse($event)" (typeaheadOnSelect)="onNoteResponseSelect($event)"
                          [typeaheadOptionsLimit]="10" typeaheadScrollable=true typeaheadOptionsInScrollableView="5"
                          [typeaheadMinLength]="0" [dropup]="true">
                      </div>
                    </div> -->
                    <!-- <div class="row" *ngIf="inbox.data.InstructionResponseId > 0 && inbox.data.ResponseText && !inbox.data.ResolutionText">
                      <div class="control-group">
                        <label for="action">Comment</label>
                        <textarea class="input-control" [(ngModel)]="resolutionText"></textarea>
                        <button class="btn btn-success" (click)="onResolutionSave()" [class.disabled]="!isSaveable"
                          [disabled]="!isSaveable">SEND</button>
                      </div>
                    </div> -->
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div> <!-- #end .responsive-table -->

      </div> <!-- #end .table -->

    </div> <!-- #end .column-display-area -->
  </div> <!-- #end .messages-container -->
</div> <!-- #end .container-fluid -->

<app-invoice-preview-modal *ngIf="showProformaInvoice" [isVisible]="showProformaInvoice" #invoicePreview
  [notesHandle]="selectedNotesHandle" [plaintiff]="invoicePlaintiff" [selectedTab]="selectedTab"
  [defendant]="invoiceDefendant" [invoiceId]="selectedInvoiceId" [invoiceDetails]="invoiceDetails"
  (closeModal)="closeModal()">
</app-invoice-preview-modal>

<app-multiple-invoice-note *ngIf="onShowMultiInvoiceNote" [isVisible]="onShowMultiInvoiceNote"
  [lawfirmName]="lawfirmName" [objectType]="objectType" [noteDetailData]="noteDetailData" [noteCategory]="noteCategory"
  [multiInvoiceNote]="multiInvoiceNote" [multipleObjectNotes]="multipleObjectNotes"
  (closemodal)="onShowMultiInvoiceNoteModalDismiss()" (saveMultiNote)="onSaveMultiNote($event)"
  (updateNote)="onMultiInvoiceNoteUpdat()"></app-multiple-invoice-note>