<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div id="ReportPage" class="container-fluid">
  <!-- <h2 class="page-title">{{title}}</h2> -->
  <nav class="report-nav">
    <a class="tab" *ngIf="canViewFinanctialReport" [class.active]="reportType === 'practiceManagementReports'" (click)="togglePanel('practiceManagementReports', null)">My Practice</a>
    <a class="tab" [class.active]="reportType === 'businessReports'" (click)="togglePanel('businessReports', null)">Business Report</a>
    <a class="tab" *ngIf="canViewFinanctialReport" [class.active]="reportType === 'financialReports'" (click)="togglePanel('financialReports', null)">Financial Report</a>
    <a class="tab" *ngIf="(canViewFinanctialReport && (!isInternalLawfirm) && !isExternalAttorney && (!isAttorney) && !isAssistantToLawfirm) || isAdhoc" [class.active]="reportType === 'industryReport'" (click)="togglePanel('industryReport', null)">Industry Reports
      <i class="far fa-info-circle tooltipp">
        <div class="bottom" style="text-align: center">
          <span class="fa fa-info-circle" style="font-size: 25px; padding-top: 9px; padding-bottom: 5px;"></span>
          <h4><strong>Please note</strong></h4>
          <p>The payment data displayed on the Industry Report is over a period of 5 years, except for the GCB defaulters listings.</p>
          <i></i>
        </div>
      </i>
    </a>
    <a class="tab" *ngIf="userId === 9243" [class.active]="reportType === 'subscriptionSummaryReport'" (click)="togglePanel('subscriptionSummaryReport', null)">Auxcon Subscription</a>
  </nav>

  <div class="row reports-options" *ngIf="reportType !== 'subscriptionSummaryReport'">
    <!-- <div class="column-report-type">
  <div class="row reports-options m-0">
    <div class="col-sm-4">
      <label for="report-type">Report Type:</label>
      <select class="w-75" (change)="togglePanel($event.target.value)" [(ngModel)]="reportType">
        <option value="businessReports">Business Reports</option>
        <option *ngIf="canDisplayFinancialReport" value="financialReports">Financial Reports</option>
        <option *ngIf="isAttorney || isExternalAttorney || isInternalLawfirm" value="liveStatement">Live Statement</option>
        <option value="practiceManagementReports">Practice Management</option>
      </select>
    </div> -->
    <!--#end .column-report-type -->
    <div class="column-report-name" *ngIf="!showLiveStatementReports">
      <label for="report-type" class="report-name">Report:</label>
      <select class="w-75" (change)="viewReport()" [(ngModel)]="reportName">
        <!-- <option value="null">Choose a report</option> -->
        <!-- Financial Reports -->
        <option *ngIf="reportType === 'financialReports' && profile.serviceProviderID !== 29723" value="financialSummaryViewReport">Financial Summary View
          Report</option>
        <option *ngIf="reportType === 'financialReports' && profile.serviceProviderID !== 29723" value="creditNoteReport">Credit Note Report</option>
        <option *ngIf="reportType === 'financialReports' && profile.serviceProviderID !== 29723" value="invoiceHistory">Invoice History Report</option>
        <option *ngIf="reportType === 'financialReports' && !isAssistantToLawfirm && !isAttorney && !isLawfirm" value="invoiceHistoryByLawfirm">Invoice History By Law Firm
          Report</option>
        <option *ngIf="reportType === 'financialReports' && (isAssistantToLawfirm || isAttorney || isLawfirm)" value="invoiceHistoryByLawfirm">Invoice History By Client
          Report</option>
          <!-- <option *ngIf="reportType === 'financialReports' && profile.serviceProviderID !== 29723" value="monthlyMeeting">Monthly Meeting Report</option> -->
        <option *ngIf="reportType === 'financialReports' && profile.serviceProviderID !== 29723" value="recon">Reconciliation History Report</option>
        <option *ngIf="reportType === 'financialReports' && !isAssistantToLawfirm && !isAttorney && !isLawfirm" value="reconByLawfirm">Reconciliation History Report By Lawfirm</option>
        <option *ngIf="reportType === 'financialReports' && (isAssistantToLawfirm || isAttorney || isLawfirm)" value="reconByLawfirm">Reconciliation History Report By Client</option>
        <option *ngIf="reportType === 'financialReports' && profile.serviceProviderID !== 29723" value="unallocatedBankTransaction">Unallocated Transactions</option>
        <option *ngIf="reportType === 'financialReports' && profile.serviceProviderID !== 29723" value="invoicePaid">VAT on Invoice Paid Report</option>
        <option *ngIf="reportType === 'financialReports' && profile.serviceProviderID !== 29723" value="disbursement">98 Day Disbursement Report</option>
        <!-- Business Reports -->
        <option *ngIf="reportType === 'businessReports' && profile.serviceProviderID !== 29723" value="calendarAction">Calendar Action Report</option>
        <option *ngIf="reportType === 'businessReports' && profile.serviceProviderID !== 29723" value="deletedInvoice">Deleted Invoice Report</option>
        <option *ngIf="reportType === 'businessReports' && profile.serviceProviderID !== 29723" value="outstanding">Outstanding Invoice Report</option>
        <option *ngIf="reportType === 'businessReports' && profile.serviceProviderID !== 29723" value="practiceAssessment">Practice Assessment Report</option>
        <option *ngIf="reportType === 'businessReports' && userId === 12402" value="reconByLawfirm">Reconciliation History Report By Lawfirm</option>
        <option *ngIf="reportType === 'businessReports'" value="statementOfAccount">Statement of Account Report</option>
        <option *ngIf="reportType === 'businessReports' && profile.serviceProviderID !== 29723" value="matterStatementOfAccountReport">Matter Statement of Account</option>
        <option *ngIf="reportType === 'businessReports' && userId === 12402" value="unallocatedBankTransaction">Unallocated Transactions</option>
        <option *ngIf="reportType === 'financialReports' && profile.serviceProviderID !== 29723" value="writeOffReport">Write-Off Report</option>
        <option *ngIf="reportType === 'businessReports' && profile.serviceProviderID !== 29723" value="workInProgress">Work In Progress Report</option>
        <!-- Practice Management Reports -->
        <option *ngIf="reportType === 'practiceManagementReports'" value="practiceSummary">Practice Summary</option>
        <option *ngIf="reportType === 'practiceManagementReports'" value="ageAnalysis">Age Analysis</option>
        <option *ngIf="reportType === 'practiceManagementReports'" value="invoicedCollectedMonthlySummary">Invoiced &
          Collected Monthly Summary Report</option>
        <option *ngIf="reportType === 'practiceManagementReports'" value="allCustomersReport">All Customers Report
        </option>
        <option *ngIf="reportType === 'practiceManagementReports'" value="individualCustomerReport"> Individual
          Customer Report</option>
        <option *ngIf="reportType === 'practiceManagementReports'" value="customerReportPaymentRating">Customer Report -
          Payment Rating</option>
        <option *ngIf="reportType === 'practiceManagementReports' && (isInternalLawfirm || isExternalAttorney || isAttorney || isLawfirm)" value="liveStatement">Live Statement</option>
          <!-- Industry Report -->
        <option *ngIf="reportType === 'industryReport'" value="avarageDaysToPayReport">Average Days to Pay Report</option>
      </select>
    </div>
    <!--#end .column-report-name -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'invoicedCollectedMonthlySummary'">
      <label for="date" class="fromTo-date">From</label>
      <input
        type="text"
        name="fromDate"
        readonly
        class="input-date"
        #_fromDate="bsDatepicker"
        readonly
        bsDatepicker
        placeholder="2010-01-01"
        placement="bottom"
        [(ngModel)]="reportParameters.fromDate"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        (bsValueChange)="onPracticeManagementFromDateValueChange($event)">
        <i
          class="fa fa-calendar"
          (click)="_fromDate.toggle()"
          [attr.aria-expanded]="_fromDate.isOpen"></i>
      </div>
      <!-- <p *ngIf="isInvalidPracticeManagementFromToDate" for="fromDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->

    <!--#end .column-report-date -->
    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'invoicedCollectedMonthlySummary'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        readonly
        class="input-date"
        #_toDate="bsDatepicker"
        bsDatepicker
        placeholder="2010-01-01"
        placement="bottom"
        [(ngModel)]="reportParameters.toDate"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        (bsValueChange)="onPracticeManagementToDateValueChange($event)">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i>
      </div>
      <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'invoicedCollectedMonthlySummary'" (click)="onClickViewPracticeManagementReport()"></p>
      <!-- <p *ngIf="isInvalidPracticeManagementFromToDate" class="text-danger col-fs-2">To Date cannot be less than From Date.</p> -->
    <!--#end .column-report-date -->

    <!-- INDUSTRY REPORT PARAMETERS -->
      <div class="column-report-date col-fs-1" hidden *ngIf="_reportName === 'avarageDaysToPayReport'">
        <label for="date" class="fromTo-date">From</label>
        <input
          type="text"
          id="text"
          readonly
          class="input-date"
          bsDatepicker
          #_fromDate="bsDatepicker"
          placeholder="2010-01-01"
          [(ngModel)]="reportParameters.fromDate"
          (bsValueChange)="onAverageFromDateValueChange($event)"
          [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
          placement="bottom">
          <i
            class="fa fa-calendar"
            (click)="_fromDate.toggle()"
            [attr.aria-expanded]="_fromDate.isOpen"></i>
        </div> <!--#end .column-report-date From date -->
        <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->

    <!-- <div class="column-report-date col-fs-2" *ngIf="_reportName === 'avarageDaysToPayReport'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        id="text"
        class="input-date"
        bsDatepicker
        #_toDate="bsDatepicker"
        placeholder="2010-01-01"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        [(ngModel)]="reportParameters.toDate"
        (bsValueChange)="onPracticeManagementFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        placement="bottom">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i> -->
      <!-- </div> #end .column-report-date  To Date -->
      <p class="fas fa-arrow-alt-circle-right" hidden *ngIf="_reportName === 'avarageDaysToPayReport'" (click)="onAverageClickViewReport()"></p>
     <!-- end Average Days to Pay Report -->
   
      <!-- CALENDAR ACTION REPORT PARAMETERS -->
      <div class="column-report-date col-fs-1" *ngIf="_reportName === 'calendarAction'">
        <label for="date" class="fromTo-date">From</label>
        <input
          type="text"
          id="text"
          readonly
          class="input-date"
          bsDatepicker
          #_fromDate="bsDatepicker"
          placeholder="2010-01-01"
          [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
          (ngModel)="reportParameters.fromDate = $event.target.value"
          (bsValueChange)="onFromDateValueChange($event)"
          [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
          placement="bottom">
          <i
            class="fa fa-calendar"
            (click)="_fromDate.toggle()"
            [attr.aria-expanded]="_fromDate.isOpen"></i>
        </div> <!--#end .column-report-date From date -->
        <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->

    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'calendarAction'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_toDate="bsDatepicker"
        placeholder="2010-01-01"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        placement="bottom">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i>
      </div> <!--#end .column-report-date  To Date -->
      <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'calendarAction'" (click)="onClickViewReport()"></p>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->

    <!-- CREDIT NOTE REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'creditNoteReport'">
      <label for="date" class="fromTo-date">From</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        #_fromDate="bsDatepicker"
        bsDatepicker
        placeholder="2010-01-01"
        [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value"
        (bsValueChange)="onFromDateValueChange($event)"
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_fromDate.toggle()"
          [attr.aria-expanded]="_fromDate.isOpen"></i>
      </div> <!--#end .column-report-date From date -->
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->

 <div class="column-report-date col-fs-2" *ngIf="_reportName === 'creditNoteReport'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        #_toDate="bsDatepicker"
        bsDatepicker
        placeholder="2010-01-01"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)"
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i>
      </div> 
      <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'creditNoteReport'" (click)="onClickViewReport()"></p>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date  To Date -->

    <!-- WRITE-OFF REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'writeOffReport'">
      <label for="date" class="fromTo-date">From</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        #_fromDate="bsDatepicker"
        bsDatepicker
        placeholder="2010-01-01"
        [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value"
        (bsValueChange)="onFromDateValueChange($event)"
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_fromDate.toggle()"
          [attr.aria-expanded]="_fromDate.isOpen"></i>
      </div> <!--#end .column-report-date From date -->
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->

 <div class="column-report-date col-fs-2" *ngIf="_reportName === 'writeOffReport'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        #_toDate="bsDatepicker"
        bsDatepicker
        placeholder="2010-01-01"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)"
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i>
      </div> 
      <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'writeOffReport'" (click)="onClickViewReport()"></p>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date  To Date -->

    <!-- INVOICE HISTORY REPORT PARAMETERS -->
    <div class="column-report-date col-fs" style="width: 50%;" *ngIf="_reportName === 'invoiceHistory'">
      <label for="seniority">Export To</label>
      <select
        class="form-control"
        id="seniority"
        [(ngModel)]="reportParameters.format">
        <option [value]="null">Choose file type</option>
        <option
          *ngFor="let exportTo of exportToDocumentType"
          [value]="exportTo.value"
          [selected]="exportTo.value === reportParameters.format">{{exportTo.label}}</option>
      </select>
    </div>
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'invoiceHistory'">
      <label for="date" class="fromTo-date">From</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        #_fromDate="bsDatepicker"
        bsDatepicker
        placeholder="2010-01-01"
        [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value"
        (bsValueChange)="onFromDateValueChange($event)"
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_fromDate.toggle()"
          [attr.aria-expanded]="_fromDate.isOpen"></i>
      </div> <!--#end .column-report-date From date -->
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->

 <div class="column-report-date col-fs-2" *ngIf="_reportName === 'invoiceHistory'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        #_toDate="bsDatepicker"
        bsDatepicker
        placeholder="2010-01-01"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)"
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i>
      </div> 
      <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'invoiceHistory'" (click)="onClickViewReport()"></p>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date  To Date -->

    <!-- INVOICE HISTORY BY LAWFIRM REPORT PARAMETERS -->
    <div class="column-report-debtor badge" *ngIf="_reportName === 'invoiceHistoryByLawfirm'">
      <label *ngIf="!isAssistantToLawfirm && !isAttorney && !isLawfirm" for="" class="debtor-label">Law Firms:</label>
      <label *ngIf="isAssistantToLawfirm || isAttorney || isLawfirm" for="" class="debtor-label">Clients:</label>
      <input
        [(ngModel)]="selectedLawfirm"
        [typeahead]="AllSelectedFirm"
        typeaheadOptionField="FullName"
        [typeaheadSingleWords]="false"
        (input)="onInput($event)"
        (focus)="onFocus()"
        class="form-control"
        placeholder="Select or start typing"
        (typeaheadOnSelect)="onLawfirmSelect($event)"
        [typeaheadOptionsLimit]="50"
        typeaheadScrollable=true
        typeaheadOptionsInScrollableView="10"
        [typeaheadMinLength]="0"
        [dropup]="false">
      <!-- <p *ngIf="isInvalidSelectedLawfirm" class="text-danger">Please select Law firm.</p> -->
    </div>
    <!--#end .column-report-type -->

    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'invoiceHistoryByLawfirm'">
      <label for="date" class="fromTo-date">From</label>
        <input
          type="text"
          id="text"
          readonly
          class="input-date"
          bsDatepicker
          #_fromDate="bsDatepicker"
          placeholder="2010-01-01"
          [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
          (ngModel)="reportParameters.fromDate=$event.target.value"
          (bsValueChange)="onFromDateValueChange($event)"
          [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
          placement="bottom">
          <i
            class="fa fa-calendar"
            (click)="_fromDate.toggle()"
            [attr.aria-expanded]="_fromDate.isOpen"></i>
        </div> <!--#end .column-report-date From date -->
        <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->

    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'invoiceHistoryByLawfirm'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_toDate="bsDatepicker"
        placeholder="2010-01-01"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        placement="bottom">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i>
      </div> <!--#end .column-report-date  To Date -->
      <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'invoiceHistoryByLawfirm'" (click)="onClickViewReport()"></p>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->

    <!-- RECONCILIATION HISTORY REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'recon'">
      <label for="date" class="fromTo-date">From</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_fromDate="bsDatepicker"
        placeholder="2010-01-01"
        placement="bottom"
        [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value"
        (bsValueChange)="onFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_fromDate.toggle()"
          [attr.aria-expanded]="_fromDate.isOpen"></i>
      </div>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date From date -->
    
    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'recon'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_toDate="bsDatepicker"
        placeholder="2010-01-01"
        placement="bottom"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i>
      </div>
      <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'recon'" (click)="onClickViewReport()"></p>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date  To Date -->

    <!-- RECONCILIATION HISTORY BY LAWFIRM REPORT PARAMETERS -->
    <div class="column-report-debtor badge" *ngIf="_reportName === 'reconByLawfirm'">
      <label *ngIf="!isAssistantToLawfirm && !isAttorney && !isLawfirm" for="" class="debtor-label">Law Firms:</label>
      <label *ngIf="isAssistantToLawfirm || isAttorney || isLawfirm" for="" class="debtor-label">Clients:</label>
      <input
        [(ngModel)]="selectedLawfirm"
        [typeahead]="AllSelectedFirm" 
        typeaheadOptionField="FullName"
        [typeaheadSingleWords]="false" 
        (input)="onInput($event)" 
        (focus)="onFocus()" 
        class="form-control"
        placeholder="Select or start typing" 
        (typeaheadOnSelect)="onLawfirmSelect($event)" 
        [typeaheadOptionsLimit]="50"
        typeaheadScrollable=true 
        typeaheadOptionsInScrollableView="10" 
        [typeaheadMinLength]="0" 
        [dropup]="false">
    </div>
    <!--#end .column-report-type -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'reconByLawfirm'">
      <label for="date" class="fromTo-date">From</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_fromDate="bsDatepicker"
        placeholder="2010-01-01"
        placement="bottom"
        [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value"
        (bsValueChange)="onFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_fromDate.toggle()"
          [attr.aria-expanded]="_fromDate.isOpen"></i>
      </div>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date From date -->
    
    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'reconByLawfirm'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_toDate="bsDatepicker"
        placeholder="2010-01-01"
        placement="bottom"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i>
      </div>
      <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'reconByLawfirm'" (click)="onClickViewReport()"></p>
    <!--#end .column-report-date  To Date -->


    <!-- VAT ON INVOICE PAID REPORT PARAMETERS -->
    <div class="column-report-date col-fs" style="width: 50%;" *ngIf="_reportName === 'invoicePaid'">
      <label for="seniority">Export To</label>
      <select
        class="form-control"
        id="seniority"
        [(ngModel)]="reportParameters.format">
        <option [value]="null">Choose file type</option>
        <option
          *ngFor="let exportTo of exportToDocumentType"
          [value]="exportTo.value"
          [selected]="exportTo.value === reportParameters.format">{{exportTo.label}}</option>
      </select>
    </div>
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'invoicePaid'">
      <label for="date" class="fromTo-date">From</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_fromDate="bsDatepicker"
        placeholder="2010-01-01"
        placement="bottom"
        [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value"
        (bsValueChange)="onFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_fromDate.toggle()"
          [attr.aria-expanded]="_fromDate.isOpen"></i>
      </div>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date From date -->
    
    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'invoicePaid'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_toDate="bsDatepicker"
        placeholder="2010-01-01"
        placement="bottom"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i>
      </div>
      <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'invoicePaid'" (click)="onClickViewReport()"></p>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date  To Date -->


    <!-- 98 DAYS DISBURSEMENT REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'disbursement'">
      <label for="date" class="fromTo-date">From</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_fromDate="bsDatepicker"
        placeholder="2010-01-01"
        placement="bottom"
        [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value"
        (bsValueChange)="onFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_fromDate.toggle()"
          [attr.aria-expanded]="_fromDate.isOpen"></i>
      </div>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date From date -->
    
    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'disbursement'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_toDate="bsDatepicker"
        placeholder="2010-01-01"
        placement="bottom"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i>
      </div>
      <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'disbursement'" (click)="onClickViewReport()"></p>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date  To Date -->

    <!-- MONTHLY MEETING REPORT PARAMETERS -->
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'monthlyMeeting'">
      <label for="date" class="fromTo-date">From</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_fromDate="bsDatepicker"
        placeholder="2010-01-01"
        placement="bottom"
        [value]="reportParameters.fromDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.fromDate=$event.target.value"
        (bsValueChange)="onFromDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_fromDate.toggle()"
          [attr.aria-expanded]="_fromDate.isOpen"></i>
      </div>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-1">From Date cannot be greater than To Date.</p> -->
    <!--#end .column-report-date From date -->
    
    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'monthlyMeeting'">
      <label for="date" class="fromTo-date">To</label>
      <input
        type="text"
        id="text"
        readonly
        class="input-date"
        bsDatepicker
        #_toDate="bsDatepicker"
        placeholder="2010-01-01"
        placement="bottom"
        [value]="reportParameters.toDate | date:'yyyy-MM-dd'"
        (ngModel)="reportParameters.toDate=$event.target.value"
        (bsValueChange)="onToDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
        <i
          class="fa fa-calendar"
          (click)="_toDate.toggle()"
          [attr.aria-expanded]="_toDate.isOpen"></i>
      </div> <!--#end .column-report-date  To Date -->
      <p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'monthlyMeeting'" (click)="onClickViewReport()"></p>
      <!-- <p *ngIf="isInvalidFromToDate" class="text-danger col-fs-2">From Date cannot be greater than To Date.</p> -->


    <!-- FINANCIAL SUMMARY VIEW REPORT PARAMETERS -->
    <div class="column-report-date col-fs" style="width: 50%;" *ngIf="_reportName === 'financialSummaryViewReport'">
      <label for="seniority">Export To</label>
      <select
        class="form-control"
        id="seniority"
        [(ngModel)]="reportParameters.format">
        <option [value]="null">Choose file type</option>
        <option
          *ngFor="let exportTo of exportToDocumentType"
          [value]="exportTo.value"
          [selected]="exportTo.value === reportParameters.format">{{exportTo.label}}</option>
      </select>
    </div>
    <div class="column-report-date col-fs-1" *ngIf="_reportName === 'financialSummaryViewReport'">
      <label for="date">From Year</label>
      <!-- <div width="100%"> -->
        <select name="fromYear" id="fromYear" class="input-date" [(ngModel)]="reportParameters.fromYear">
          <option>2013</option>
        <option>2014</option>
        <option>2015</option>
        <option>2016</option>
        <option>2017</option>
        <option>2018</option>
        <option>2019</option>
        <option>2020</option>
        <option>2021</option>
        <option>2022</option>
        <option>2023</option>
        <option>2024</option>
        <option>2025</option>
        <option>2026</option>
        <option>2027</option>
        <option>2028</option>
        <option>2029</option>
        <option>2030</option>
        <option>2031</option>
        <option>2032</option>
        <option>2033</option>
        </select>
        <!-- </div> -->
      </div>
      <!-- <p *ngIf="isInvalidFromToYear" class="text-danger col-fs-1">From Year cannot be greater than To Year.</p> -->

    <div class="column-report-date col-fs-2" *ngIf="_reportName === 'financialSummaryViewReport'">
      <label style="width: 70px !important;" for="date">From Month</label>
      <!-- <div> -->
        <select name="fromMonth" id="fromMonth" class="input-date" [(ngModel)]="reportParameters.fromMonth">
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      <!-- </div> -->
    </div>
    <!-- <p *ngIf="isInvalidFromToMonth" class="text-danger col-fs-2">From Month cannot be greater than To Month on the same year.</p> -->
    <!--#end .column-report-date From date -->
    
    <div class="column-report-date col-fs-3" *ngIf="_reportName === 'financialSummaryViewReport'">
      <label for="date">To Year</label>
      <!-- <div width="100%"> -->
        <select name="toYear" id="toYear" class="input-date" [(ngModel)]="reportParameters.toYear">
          <option>2013</option>
        <option>2014</option>
        <option>2015</option>
        <option>2016</option>
        <option>2017</option>
        <option>2018</option>
        <option>2019</option>
        <option>2020</option>
        <option>2021</option>
        <option>2022</option>
        <option>2023</option>
        <option>2024</option>
        <option>2025</option>
        <option>2026</option>
        <option>2027</option>
        <option>2028</option>
        <option>2029</option>
        <option>2030</option>
        <option>2031</option>
        <option>2032</option>
        <option>2033</option>
        </select>
        <!-- </div> -->
      </div>
      <!-- <p *ngIf="isInvalidFromToYear" class="text-danger col-fs-3">From Year cannot be greater than To Year.</p> -->
      
      <div class="column-report-date col-fs-4" *ngIf="_reportName === 'financialSummaryViewReport'">
        <label for="date">To Month</label>
        <!-- <div> -->
          <select name="toMonth" id="toMonth" class="input-date"  [(ngModel)]="reportParameters.toMonth">
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <!-- </div> -->
        </div>
        <!-- <p *ngIf="isInvalidFromToMonth" class="text-danger col-fs-4">From Month cannot be greater than To Month on the same year.</p> -->
    <!--#end .column-report-date  To Date -->
<p class="fas fa-arrow-alt-circle-right" *ngIf="_reportName === 'financialSummaryViewReport'" (click)="selectedToMonth($event)"></p>

    <!-- <div class="column-report-debtor badge" *ngIf="_reportName === 'individualCustomerReport'"> -->
    <div class="column-report-debtor badge" *ngIf="_reportName === 'individualCustomerReport'">
      <label *ngIf="!isAssistantToLawfirm && !isAttorney && !isLawfirm" for="" class="debtor-label">Law Firms:</label>
      <label *ngIf="isAssistantToLawfirm || isAttorney || isLawfirm" for="" class="debtor-label">Clients:</label>
      <!-- <select (change)=" onDebtorValueChange($event.target.value)" [(ngModel)]="_debtorId">
        <option *ngFor="let i of debtorData;" value="{{i.contactID}}">{{i.fullName}}</option>
      </select>  -->
      <input
        [(ngModel)]="debtorName"
        [typeahead]="debtorData"
        typeaheadOptionField="fullName"
        [typeaheadSingleWords]="false"
        class="form-control"
        placeholder="Select or start typing"
        [typeaheadOptionsLimit]="100"
        typeaheadScrollable="true"
        typeaheadOptionsInScrollableView="20"
        [typeaheadMinLength]="0"
        (typeaheadOnSelect)="onDebtorSelect($event)" />
    </div> <!--#end .column-report-type -->


    <div class="column-report-debtor badge" *ngIf="_reportName === 'statementOfAccount'">
      <label *ngIf="!isAssistantToLawfirm && !isAttorney && !isLawfirm" for="" class="debtor-label">Law Firms:</label>
      <label *ngIf="isAssistantToLawfirm || isAttorney || isLawfirm" for="" class="debtor-label">Clients:</label>
      <!-- <select (change)=" onDebtorValueChange($event.target.value)" [(ngModel)]="_debtorId">
        <option *ngFor="let i of debtorData;" value="{{i.contactID}}">{{i.fullName}}</option>
      </select>  -->
      <input
        [(ngModel)]="selectedLawfirm"
        [typeahead]="SelectedFirm"
        typeaheadOptionField="FullName"
        [typeaheadSingleWords]="false"
        (input)="onInput($event)"
        (focus)="onFocus()"
        class="form-control"
        placeholder="Select or start typing"
        (typeaheadOnSelect)="onSelect($event)"
        [typeaheadOptionsLimit]="50"
        typeaheadScrollable=true
        typeaheadOptionsInScrollableView="10"
        [typeaheadMinLength]="0"
        [dropup]="false">
    </div>
    <!--#end .column-report-type -->
       <!-- Matter Statement of Account -->
    <div class="column-report-debtor badge" *ngIf="_reportName === 'matterStatementOfAccountReport'">
      <label *ngIf="!isAssistantToLawfirm && !isAttorney && !isLawfirm" for="" class="debtor-label">Law Firms:</label>
      <label *ngIf="isAssistantToLawfirm || isAttorney || isLawfirm" for="" class="debtor-label">Clients:</label>
      <!-- <select (change)=" onDebtorValueChange($event.target.value)" [(ngModel)]="_debtorId">
        <option *ngFor="let i of debtorData;" value="{{i.contactID}}">{{i.fullName}}</option>
      </select>  -->
      <input
        [(ngModel)]="selectedLawfirm"
        [typeahead]="SelectedFirm"
        typeaheadOptionField="FullName"
        [typeaheadSingleWords]="false"
        (input)="onInput($event)"
        (focus)="onFocus()"
        class="form-control"
        placeholder="Select or start typing"
        (typeaheadOnSelect)="onFirmSelect($event)"
        [typeaheadOptionsLimit]="50"
        typeaheadScrollable=true
        typeaheadOptionsInScrollableView="10"
        [typeaheadMinLength]="0"
        [dropup]="false">
    </div>
    <!--#end .column-report-type -->

    <div class="column-report-date badge col-fs-1" *ngIf="_reportName === 'matterStatementOfAccountReport'">
      <label style="width: 39px !important; margin-left: 4px;" for="date">Search:</label>
      <input 
        type="text" 
        class="form-control" 
        placeholder="Search for a matter, reference or Attorney" 
        [(ngModel)]="searchMatterStatementText">
    </div>
  <p class="fas fa-arrow-alt-circle-right" style="padding-left: 5px;" *ngIf="_reportName === 'matterStatementOfAccountReport'" (click)="onClickMatterStatementReport()"></p>
    <!-- <div></div> -->
  </div>
  
  <div *ngIf="!showLiveStatementReports" class="flex-container">
  <div style="width: 100%; margin-top: 3px;" *ngIf="userId === 9243 && _reportName === 'subscriptionSummaryReport'">
    <app-subscription-summary *ngIf="userId === 9243" [clientSubscriptionSummary]="clientSubscriptionSummary">
    </app-subscription-summary>
  </div>
    <!-- Practice Summary-->
    <div style="width:100%; margin-top:3px;" *ngIf="_reportName === 'practiceSummary'">
      <app-practice-summary (onViewMoreClick)=" receiveCustomerReport($event)"></app-practice-summary>
    </div>

    <!-- Invoiced & Collected Monthly Summary Report -->
    <div style="width:100%;margin-top:3px;" *ngIf="_reportName === 'invoicedCollectedMonthlySummary'">
      <invoiced-collected-summary-report [graphMargin]="{ top: 20, right: 20, bottom: 70, left: 80 }" [graphWidth]="960"
        [graphHeight]="400"></invoiced-collected-summary-report>
    </div>
    <!-- Age Analysis -->
    <div style="width:100%; margin-top:3px;" *ngIf="_reportName === 'ageAnalysis'">
      <age-analysis-report (onRowClick)="receiveAllCustomer($event)"></age-analysis-report>
    </div>
    <!-- All Customers Report -->
    <div style="width:100%; margin-top:3px;" *ngIf="_reportName === 'allCustomersReport'">
      <app-all-customers-report (onRowClick)="receiveDebtorID($event)" [activeTab]="_defaultTab">
      </app-all-customers-report>
    </div>
    <!-- Customer Report - Payment Rating -->
    <div style="width:100%; margin-top:3px;" *ngIf="_reportName === 'customerReportPaymentRating'">
      <app-customer-report-payment-rating (onRowClick)="receiveDebtorID($event)"></app-customer-report-payment-rating>
    </div>

    <!-- Industry Reports --> 
      <div style="width:100%; margin-top:3px; flex: none;" *ngIf="_reportName === 'avarageDaysToPayReport' && (!isInternalLawfirm) && !isExternalAttorney && (!isAttorney)">
        <app-industry-reports></app-industry-reports>
      </div>

    <!-- Customer Report - Payment Rating -->
    <div style="width:100%; margin-top:3px;" *ngIf="_reportName === 'individualCustomerReport'">
      <app-individual-customer-report
      [allocatedToLawFirm]="allocatedToLawFirm"
      [debtorId]="id"></app-individual-customer-report>
    </div>

    <!-- Report area -->
    <div style="width:100%; height:900px" *ngIf="isDocumentReport">
      <!-- BUSINESS REPORTS VIEWER -->
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'businessReports' && _reportName === 'calendarAction'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'businessReports' && _reportName === 'deletedInvoice'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'businessReports' && _reportName === 'outstanding'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'businessReports' && _reportName === 'practiceAssessment'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'businessReports' && _reportName === 'statementOfAccount'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'businessReports' && _reportName === 'matterStatementOfAccountReport'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'businessReports' && _reportName === 'workInProgress'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
  
      <!-- FINANCIALS REPORTS VIEWER -->
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'financialReports' && _reportName === 'financialSummaryViewReport'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'financialReports' && _reportName === 'invoiceHistory'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'financialReports' && _reportName === 'creditNoteReport'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'financialReports' && _reportName === 'writeOffReport'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'financialReports' && _reportName === 'invoiceHistoryByLawfirm'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'financialReports' && _reportName === 'monthlyMeeting'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'financialReports' && _reportName === 'recon'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'financialReports' || 'businessReports' && _reportName === 'reconByLawfirm'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'financialReports' && _reportName === 'invoicePaid'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'financialReports' && _reportName === 'disbursement'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
      <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" *ngIf="reportType === 'financialReports' || 'businessReports' && _reportName === 'unallocatedBankTransaction'" [openFile]="true" [viewBookmark]="true" [download]="true">
      </ng2-pdfjs-viewer>
    </div>
  </div><!-- #flex-container -->
  <app-age-analysis [data]="ageAnalysisData" *ngIf="showLiveStatementReports"></app-age-analysis>
  <app-live-statement [data]="statementByAdvocate" (searchClient)="onClientSearchChange($event)" *ngIf="showLiveStatementReports"></app-live-statement>
  <!-- Auxcon Subscription -->
  <app-alert
title="PLEASE SEARCH AND SELECT LAW FIRM"
message="Please select a Law Firm from predefined list."
[isVisible]="showDebtorErrorPrompt"
(cancel)="onHideDebtorErrorPrompt()"
(confirm)="onHideDebtorErrorPrompt()"></app-alert>

<app-alert
  [title]="erroMessageTitle"
  [message]="errorMessage"
  [isVisible]="showErrorMessageModelPrompt"
  (confirm)="onHideErrorMessageModelPrompt()"
  (cancel)="onHideErrorMessageModelPrompt()"></app-alert>
  
<app-prompt-unsaved-changes-modal title="Subscription require"
  message="You must upgrade your account in order to access this feature. Please contact Auxcon support"
  [isVisible]="showUpgradeReportPrompt"
  (cancel)="onHideUpgradeReportPrompt()" 
  btnTextCancel="Cancel" btnTextContinue="OK"
  (continue)="onDismiss()"></app-prompt-unsaved-changes-modal>
