<div class="modal" *ngIf="isVisible">
 <div class="modal-content">
   <div class="modal-dialog">

     <div class="modal-header">
       <h2>{{title}}</h2>
       <button class="modal-dismiss" (click)="dismiss()">
        <i class="fa fa-close"></i>
       </button>
      </div> <!-- #end modal-header -->
      
      <div class="modal-body form">
        
        <div style="width:100%; height:100%">
          <app-report-viewer 
            [reportType]="reportType"
            [serviceId]="selectedserviceId"
            [fileName]="selectedFileName"></app-report-viewer>
        </div>
          
      </div> <!-- #end modal-body form-->
    </div> <!-- #end modal-dialog-->
  </div> <!-- #end modal-content-->
</div> <!-- #end modal-->
