<div class="container-fluid">
  <div class="process-body row">

    <div class="left-block col-sm-6">
      <div class="block-title">Auxcon Subscription Summary</div>
      <div class="row m-0">
        <table class="table table-striped">
          <tbody>
            <tr>
              <th>Subscription</th>
              <th>Total Amount</th>
              <th>Number of Clients</th>
            </tr>
            <tr class="tr" [class.active]="row === 'Collections'">
              <td (click)="onRowClick('Collections')">Practice Management Fee</td>
              <td (click)="onRowClick('Collections')">{{clientSubscriptionSummary.CollectionsAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              <td (click)="onRowClick('Collections')">{{clientSubscriptionSummary.CollectionsClientCount}}</td>
            </tr>
            <tr class="tr" [class.active]="row === 'SiteRental'">
              <td (click)="onRowClick('SiteRental')">Monthly Site Rental</td>
              <td (click)="onRowClick('SiteRental')">{{clientSubscriptionSummary.MonthlySiteAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              <td (click)="onRowClick('SiteRental')">{{clientSubscriptionSummary.SiteRentalClientCount}}</td>
            </tr>
            <tr class="tr" [class.active]="row === 'Reconciliation'">
              <td (click)="onRowClick('Reconciliation')">Reconciliation</td>
              <td (click)="onRowClick('Reconciliation')">{{clientSubscriptionSummary.ReconciliationAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              <td (click)="onRowClick('Reconciliation')">{{clientSubscriptionSummary.ReconClientCount}}</td>
            </tr>
            <tr class="tr" [class.active]="row === 'Invoicing'">
              <td (click)="onRowClick('Invoicing')">Practice Management Fee - Invoicing</td>
              <td (click)="onRowClick('Invoicing')">{{clientSubscriptionSummary.InvoicingAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              <td (click)="onRowClick('Invoicing')">{{clientSubscriptionSummary.InvoicingClientCount}}</td>
            </tr>
            <tr class="tr" [class.active]="row === 'Expenses'">
              <td (click)="onRowClick('Expenses')">Practice Management Fee - Expenses</td>
              <td (click)="onRowClick('Expenses')">{{clientSubscriptionSummary.ExpenseAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              <td (click)="onRowClick('Expenses')">{{clientSubscriptionSummary.ExpensesClientCount}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="right-block col-sm-6">

      <div class="row m-0" *ngIf="row === 'Collections'">
        <div class="block-title">Auxcon Practice Management Clients</div>

        <div class="row reconcile-search input-group">
          <input
            style="margin-left: 10px !important;"
            type="search"
            #inputCheckbox
            class="form-control"
            placeholder="Search"
            [(ngModel)]="searchTerm"
            (keyup)="performSearch()">
            <i *ngIf="!searchTerm" class="far fa-search"></i>
            <i *ngIf="searchTerm" (click)="clearSearch()" class="far fa-close"></i>
        </div>
        <div class="table-responsive" style="margin-left: 12px;">
          <table class="table table-striped">
            <tbody>
              <tr>
                <th scope="col" (click)="toggleSort('advocate-name')">Advocate Name
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortAdvocateName"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('amount')">Practice Management Fee
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('invoiced-amount')">Invoiced Amount
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortInvoicedAmount"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('due-amount')">Auxcon Outstanding Amount
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortDueAmount"></i></span>
                </th>
              </tr>
              <tr *ngFor="let data of clientSubscriptionDetailHandle">
                <td *ngIf="data.state.isVisible">{{data.data.AdvocateName}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.ChargedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.InvoicedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.TotalOwingAuxcon | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>{{totalCollectionsAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                <th>{{totalInvoicedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                <th>{{totalDueAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div class="row m-0" *ngIf="row === 'SiteRental'">
        <div class="block-title">Auxcon Site Rental Clients</div>

        <div class="row reconcile-search input-group">
          <input 
            style="margin-left: 10px !important;" 
            type="search" 
            #inputCheckbox 
            class="form-control" 
            placeholder="Search"
            [(ngModel)]="searchTerm" 
            (keyup)="performSearch()">
          <i *ngIf="!searchTerm" class="far fa-search"></i>
          <i *ngIf="searchTerm" (click)="clearSearch()" class="far fa-close"></i>
        </div>

        <div class="table-responsive" style="margin-left: 12px;">
          <table class="table table-striped">
            <tbody>
              <tr>
                <th scope="col" (click)="toggleSort('advocate-name')">Advocate Name
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortAdvocateName"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('amount')">Site Rental Amount
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('invoiced-amount')">Invoiced Amount
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortInvoicedAmount"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('due-amount')">Amount Owe to Auxcon
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortDueAmount"></i></span>
                </th>
              </tr>
              <tr *ngFor="let data of clientSubscriptionDetailHandle">
                <td *ngIf="data.state.isVisible">{{data.data.AdvocateName}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.ChargedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.InvoicedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.TotalOwingAuxcon | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>{{totalCollectionsAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                <th>{{totalInvoicedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                <th>{{totalDueAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div class="row m-0" *ngIf="row === 'Reconciliation'">
        <div class="block-title">Auxcon Reconciliation Clients</div>

        <div class="row reconcile-search input-group">
          <input 
            style="margin-left: 10px !important;" 
            type="search" 
            #inputCheckbox 
            class="form-control" 
            placeholder="Search"
            [(ngModel)]="searchTerm" 
            (keyup)="performSearch()">
          <i *ngIf="!searchTerm" class="far fa-search"></i>
          <i *ngIf="searchTerm" (click)="clearSearch()" class="far fa-close"></i>
        </div>

        <div class="table-responsive" style="margin-left: 12px;">
          <table class="table table-striped">
            <tbody>
              <tr>
                <th scope="col" (click)="toggleSort('advocate-name')">Advocate Name
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortAdvocateName"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('amount')">Reconciliation Amount
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('invoiced-amount')">Invoiced Amount
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortInvoicedAmount"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('due-amount')">Auxcon Outstanding Amount
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortDueAmount"></i></span>
                </th>
              </tr>
              <tr *ngFor="let data of clientSubscriptionDetailHandle">
                <td *ngIf="data.state.isVisible">{{data.data.AdvocateName}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.ChargedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.InvoicedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.TotalOwingAuxcon | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>{{totalCollectionsAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                <th>{{totalInvoicedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                <th>{{totalDueAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div class="row m-0" *ngIf="row === 'Invoicing'">
        <div class="block-title">Auxcon Practice Management - Invoicing Clients</div>

        <div class="row reconcile-search input-group">
          <input 
            style="margin-left: 10px !important;" 
            type="search" 
            #inputCheckbox 
            class="form-control" 
            placeholder="Search"
            [(ngModel)]="searchTerm" 
            (keyup)="performSearch()">
          <i *ngIf="!searchTerm" class="far fa-search"></i>
          <i *ngIf="searchTerm" (click)="clearSearch()" class="far fa-close"></i>
        </div>

        <div class="table-responsive" style="margin-left: 12px;">
          <table class="table table-striped">
            <tbody>
              <tr>
                <th scope="col" (click)="toggleSort('advocate-name')">Advocate Name
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortAdvocateName"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('amount')">Practice Management Fee - Invoicing
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('invoiced-amount')"> Invoiced Amount
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortInvoicedAmount"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('due-amount')">Auxcon Outstanding Amount
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortDueAmount"></i></span>
                </th>
              </tr>
              <tr *ngFor="let data of clientSubscriptionDetailHandle">
                <td *ngIf="data.state.isVisible">{{data.data.AdvocateName}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.ChargedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.InvoicedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.TotalOwingAuxcon | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>{{totalCollectionsAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                <th>{{totalInvoicedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                <th>{{totalDueAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div class="row m-0" *ngIf="row === 'Expenses'">
        <div class="block-title">Auxcon Practice Management - Expenses Clients</div>

        <div class="row reconcile-search input-group">
          <input
            style="margin-left: 10px !important;" 
            type="search" 
            #inputCheckbox 
            class="form-control" 
            placeholder="Search"
            [(ngModel)]="searchTerm" 
            (keyup)="performSearch()">
          <i *ngIf="!searchTerm" class="far fa-search"></i>
          <i *ngIf="searchTerm" (click)="clearSearch()" class="far fa-close"></i>
        </div>

        <div class="table-responsive" style="margin-left: 12px;">
          <table class="table table-striped">
            <tbody>
              <tr>
                <th scope="col" (click)="toggleSort('advocate-name')">Advocate Name
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortAdvocateName"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('amount')">Practice Management Fee - Expenses
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('invoiced-amount')">Invoiced Amount
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortInvoicedAmount"></i></span>
                </th>
                <th scope="col" (click)="toggleSort('due-amount')">Auxcon Outstanding Amount
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortDueAmount"></i></span>
                </th>
              </tr>
              <tr *ngFor="let data of clientSubscriptionDetailHandle">
                <td *ngIf="data.state.isVisible">{{data.data.AdvocateName}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.ChargedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.InvoicedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td *ngIf="data.state.isVisible">{{data.data.TotalOwingAuxcon | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>{{totalCollectionsAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                <th>{{totalInvoicedAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                <th>{{totalDueAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

    </div>

  </div> <!-- #end .process-body ,row -->
</div> <!-- #end .container-fluid -->