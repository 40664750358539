
<app-multiple-invoice-note
    *ngIf="onShowMultiInvoiceNote"
    [isVisible]="onShowMultiInvoiceNote"
    [(isAdHocCollections)]="isAdHocCollections"
    [(isAdHocRequest)]="isAdHocRequest"
    [instructionResponses]="instructionResponses"
    [lawfirmName]="lawfirmName"
    [objectType]="objectType"
    [noteDetailData]="noteDetailData"
    [noteCategory]="noteCategory"
    [multiInvoiceNote]="multiInvoiceNote"
    (closemodal)="onShowMultiInvoiceNoteModalDismiss()"
    (saveMultiNote)="onSaveMultiNote($event)"
    (saveInstructionMultiNote)="onSaveInstructionOnMultiInvoice($event)"></app-multiple-invoice-note>

<app-prompt-unsaved-changes-modal
    *ngIf="showSavedInstructionPrompt"
    title="Instruction Saved"
    message="Your instruction has been saved."
    [isVisible]="showSavedInstructionPrompt"
    (cancel)="onHideSavedInstructionPrompt()"
    btnTextCancel="Cancel"
    btnTextContinue="Ok"
    (continue)="onHideSavedInstructionPrompt()"></app-prompt-unsaved-changes-modal>

<div  *ngIf="onShowCancel" class="row">
    <div class="col-sm-12">
        <h3 class="titleCancel">Ad-Hoc request link has expired.</h3>
        <div class="row icon-danger">
            <i class="fal fa-times-circle fa-6x"></i>
        </div>
        <div class="statement">
            <span>An instruction has already been inserted on this Ad-Hoc request. Please access your system to view same.</span>                        
        </div>
    </div>
</div>

<div  *ngIf="onShowSuccess" class="row">
    <div class="col-sm-12">
        <h3 class="titleCancel">Ad-Hoc instruction added.</h3>
        <div class="row icon-success">
            <i class="fal fa-check-circle fa-6x"></i>
        </div>
        <div class="statement">
            <span>An instruction has been successfully inserted for this Ad-Hoc request. Kindly access your system to view the details.</span>                        
        </div>
    </div>
</div>

<app-loading-overlay-modal></app-loading-overlay-modal>