<div class="container-fluid page-wrapper" *ngIf="isShowSuccess">
    <div class="row w-100" style="padding-top: 17vh;">
        <div class="col-sm-9 body">
            <div class="row icon-success">
                <i class="fal fa-check-circle fa-10x" style="margin-bottom: 25px;"></i>
            </div>
            <div class="alert alert-success">
                Your subscription has been successfully activated.
            </div>
        </div>
    </div>
</div>
<div class="container-fluid page-wrapper" *ngIf="isShowCancel">
    <div class="row w-100" style="padding-top: 17vh;">
        <div class="col-sm-9 body">
            <div class="row icon-warning">
                <i class="fal fa-exclamation-triangle fa-10x" style="margin-bottom: 25px;"></i>
            </div>
            <div class="alert alert-warning">
                You are cancelled the payment.
            </div>
        </div>
    </div>
</div>
<div class="container-fluid page-wrapper" *ngIf="isShowFailure || isShowError">
    <div class="row w-100" style="padding-top: 17vh;">
        <div class="col-sm-9 body">
            <div class="row icon-danger">
                <i class="fal fal fa-times-circle fa-10x fa-10x" style="margin-bottom: 25px;"></i>
            </div>
            <div class="alert alert-danger">
                <span *ngIf="isShowFailure">
                    Your payment is failed.
                </span>
                <span *ngIf="isShowError">
                    Something went wrong
                </span>
            </div>
        </div>
    </div>
</div>