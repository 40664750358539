import { Component, ViewChild, AfterViewInit, Input } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ReportsService, ReportParameters } from '../services/reports/reports.service';
import { LoadingService } from '../services/messaging/loading/loading.service';
import { InvoiceDocumentService } from '../services/invoice-document/invoice-document.service';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})
export class ReportViewerComponent implements AfterViewInit {
  _serviceId; // = 'WorkInProgressReport?ServiceProviderId=9098';   // 'WorkInProgressReport?ServiceProviderId=9098';
  _fileName;
  _advocateId;
  _lawfirmId;
  _reportType;
  reportUrl: string;

  @ViewChild('pdfViewer') public pdfViewer;

  constructor(
    private api: ApiService,
    private reportsService: ReportsService,
    private loadingService: LoadingService,
    private invoiceDocumentService: InvoiceDocumentService
  ) { }

  ngAfterViewInit() {
    // const reportUrl = this.koReportUrl,
    //   host = this.api.reportsApiURL + '/',
    //   container = this.renderer.createElement('div');
    // container.innerHTML = Html;
    // this.renderer.appendChild(this.scripts.nativeElement, container);
    // ko.applyBindings({
    //   handlerUri:  '/WebDocumentViewer/Invoke',
    //   reportUrl, // The URL of a report that is opened in the Document Viewer when the application starts.
    //   requestOptions: { // Options for processing requests from the Web Document Viewer.
    //     host, // URI of your backend project.
    //     invokeAction: '/WebDocumentViewer/Invoke',  // The URI path of the controller action that processes requests.
    //   }
    // }, this.control.nativeElement);

    this.loadingService.showOverlay();
    // document.getElementById('financialReport').style.width = '0';
     // reportUrl = '';
    if (this._reportType === 'Proforma') {

       this.reportUrl = this.api.endpoints.proformaInvoiceReport +
      '=' + this.serviceId;

      this.reportsService
        .getReports(this.reportUrl)
        .subscribe({ next: (res) => {
          this.pdfViewer.name = 'Proforma.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        },
          error: (error) => {
            // Error
          },
          complete: () => {
            // Complete
            this.loadingService.hideOverlay();
          }
        });

    } else if (this._reportType === 'Statement-of-Account') {
        this.reportUrl = this.api.endpoints.statementOfAccountsReport +
          '=' + this.lawfirmId +
          '&lawfirmId=' + this._advocateId;

        this.reportsService
          .getReports(this.reportUrl)
          .subscribe(res => {
            this.pdfViewer.name = 'StatementOfAccount.pdf';
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
          // },
          //   () => {
          //     // Error
          //   },
          //   () => {
          //     // Complete
        });
        this.loadingService.hideOverlay();
    } else if (this.reportType === 'ProofOfPayment') {

      this.reportUrl = this.api.endpoints.proofOfPaymentAttachment +
        '?Type=ProofOfPayment' +
        '&ServiceProviderID=' + this.api.serviceProviderID +
        '&popId=' + this.serviceId;

      this.reportsService
        .getReports(this.reportUrl)
        .subscribe(res => {
          this.pdfViewer.name = 'ProofOfPayment.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        // },
        //   () => {
        //     // Error
        //   },
        //   () => {
        //     // Complete
      });
      this.loadingService.hideOverlay();
    } else if (this.reportType === 'ProcessedProofOfPayment') {

      this.reportUrl = this.api.endpoints.proofOfPaymentAttachment +
        '?Type=ProcessedProofOfPayment' +
        '&ServiceProviderID=' + this.api.serviceProviderID +
        '&popId=' + this.serviceId;

      this.reportsService
        .getReports(this.reportUrl)
        .subscribe(res => {
          this.pdfViewer.name = 'ProofOfPayment.pdf';
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
        // },
        //   () => {
        //     // Error
        //   },
        //   () => {
        //     // Complete
      });
      this.loadingService.hideOverlay();

    } else if (this.reportType === 'Invoice') {
      this.reportUrl = this.api.endpoints.invoiceDocument +
      '?invoiceID=' + this.serviceId;

      this.invoiceDocumentService.getInvoiceDocument(this.reportUrl).subscribe({ next: (invoiceDoc) => {
        this.pdfViewer.name = 'Invoice.pdf';
        this.pdfViewer.pdfSrc = invoiceDoc;
        this.pdfViewer.refresh();
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // on Complete
        this.loadingService.hideOverlay();
      }
    });
    }
  }

  @Input()
  set serviceId(serviceId: string) {
    this._serviceId = serviceId;
  }
  get serviceId() {
    return this._serviceId;
  }

  @Input()
  set fileName(fileName: string) {
    this._fileName = fileName;
  }
  get fileName() {
    return this._fileName;
  }

  @Input()
  set reportType(reportType: string) {
    this._reportType = reportType;
  }
  get reportType() {
    return this._reportType;
  }

  @Input()
  set advocateId(advocateId: number) {
    this._advocateId = advocateId;
  }
  get advocateId() {
    return this._advocateId;
  }

  @Input()
  set lawfirmId(lawfirmId: number) {
    this._lawfirmId = lawfirmId;
  }
  get lawfirmId() {
    return this._lawfirmId;
  }
}
