import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentServiceService } from '../../../services/documents/document-service.service';
import * as moment from 'moment';
import { UserProfile } from '../../../services/user-profile/user-profile.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { catchError, forkJoin, of, tap } from 'rxjs';

@Component({
  selector: 'app-upload-historical-data-modal',
  templateUrl: './upload-historical-data-modal.component.html',
  styleUrls: ['./upload-historical-data-modal.component.scss']
})
export class UploadHistoricalDataModalComponent implements OnInit {

  title = 'Import Historical Data';
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message = '';
  contactId = 1; // Example contact ID
  previews: string[] = [];



  documentType: string;
  dataSource: string;
  reconDate: string;
  attachmentFile: File;
  attachmentName = '';
  attachment: string;
  disableThePopup: boolean = false;

  @Input() userProfile: UserProfile;
  @Output() onCloseModal = new EventEmitter<any>();
  @Output() isImported = new EventEmitter<any>();

  constructor(
    private loadingService: LoadingService,
    private documentService: DocumentServiceService
  ) { } // end constructor()

  ngOnInit(): void {
  } // end ngOnInit()

  promptAttachment() {
    jQuery('#attachmentUpload').val(null);
    jQuery('#attachmentUpload').trigger('click');
  } // end promptAttachment()

  attachmentChange(e) {
    this.attachmentFile = e.target.files[0];
    const ext = this.getExtension(this.attachmentFile.name);
    if (ext.toLowerCase() === 'xls' || ext.toLowerCase() === 'pdf' || ext.toLowerCase() === 'docx' || ext.toLowerCase() == 'xlsx') {
      this.attachment = this.attachmentFile.name;
      // this.message = '';
    } else {
      this.attachment = null;
      // this.message = 'Only allowed file with .ofx extension';
    }
  } // end attachmentChange()

  getExtension(filename) {
    return filename.toString().split('.')[1];
  } // end getExtension()

  onValueStartChange(value: Date): void {
    this.reconDate = value.toDateString();
  } // end onValueStartChange()

  onCancel() {
    if(this.userProfile.settings.isHistoricalImported === true) {
      this.loadingService.showOverlay();
      this.documentService.updateIsHistorical(this.userProfile.settings.isHistoricalImported).subscribe({ next: (data) => {
          // On next
        },
        error: (error) => {
          // on next
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
        }
      });
    }
    this.onCloseModal.emit();
  } // end onCancel()

  onUploadDocuments() {
    this.isImported.emit();
  } // end onUploadDocuments()

  toggleCheckbox() {
    this.disableThePopup = !this.disableThePopup;
    this.userProfile.settings.isHistoricalImported = this.disableThePopup;
  } // end toggleCheckbox()

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  } // end selectFile()

  selectFiles(event: any): void {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;

    if (this.selectedFiles && this.selectedFiles.length > 0) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  } // end selectFiles()

  uploadFiles(): void {
    let returnedObject: any[] = null;
    this.progressInfos = [];
    this.message = '';
    let completedUploads = 0;
    this.loadingService.showOverlay();
    let containerName = null;
    
    if (this.selectedFiles) {
        const files: File[] = [];
        for (let i = 0; i < this.selectedFiles.length; i++) {
            const file = this.selectedFiles.item(i) as File;
            files.push(file);
            this.progressInfos.push({ fileName: file.name, value: 0 });
        }

        // Upload all files at once
        this.documentService.uploadDocuments(files, this.documentType, moment(this.reconDate).format('YYYY-MM-DD HH:mm:ss'))
            .subscribe({
                next: (event) => {
                    if (event.status === 'progress') {
                        // Update the progress for each file
                        files.forEach((file, index) => {
                            this.progressInfos[index].value = event.percentage;
                        });
                    } else if (event.status === 'complete') {
                        this.message = 'Upload complete!';
                        if (event.data) {
                            returnedObject = event.data.uploadedDocuments;
                            containerName = event.data.containerName;  // Get the container name from the response
                        }
                    }
                },
                error: (err) => {
                    this.progressInfos.forEach((progressInfo, index) => {
                        this.progressInfos[index].value = 0; // Reset if there is an error
                    });
                    this.message = 'Could not upload the files';
                    console.error(err);
                    this.loadingService.hideOverlay();
                },
                complete: () => {
                    completedUploads = files.length; // All uploads are done
                    if (completedUploads === files.length) {
                        // All files have been uploaded, now send the email
                        this.documentService.sendHistoricalInvoices(this.documentType, moment(this.reconDate).format('YYYY-MM-DD HH:mm:ss'), containerName)
                        .subscribe({
                            next: () => {
                                this.message += ' Email sent successfully!';
                            },
                            error: (err) => {
                                this.message = 'Could not send the email';
                                console.error(err);
                                this.loadingService.hideOverlay();
                            },
                            complete: () => {
                                // Final completion of the entire process
                                this.isImported.emit();
                                this.loadingService.hideOverlay();
                            }
                        });
                    }
                }
            });
    }
} // end uploadFiles()


  // uploadFiles(): void {
  //   let returnedObject: any[] = null;
  //   this.progressInfos = [];
  //   this.message = '';
  //   let completedUploads = 0;
  //   this.loadingService.showOverlay();
  //   let containerName = null;
    
  //   if (this.selectedFiles) {
  //     const files: File[] = [];
  //     for (let i = 0; i < this.selectedFiles.length; i++) {
  //       const file = this.selectedFiles.item(i) as File;
  //       files.push(file);
  //       this.progressInfos.push({ fileName: file.name, value: 0 });
  //     }

  //     // Upload all files at once
  //     this.documentService.uploadDocuments(files, this.documentType, moment(this.reconDate).format('YYYY-MM-DD HH:mm:ss')).subscribe({
  //       next: (event) => {
  //         console.log('UPLOADED DOCUMENTS RESPONSE: ', event);
  //         if (event.status === 'complete') {
  //           this.message = 'Upload complete!';
  //         }
          
  //         if (event.data) {
  //           returnedObject = event.data.uploadedDocuments;
  //           containerName = event.data.containerName;  // Get the container name from the response
  //         }
  //       },
  //       error: (err) => {
  //         this.progressInfos.forEach((progressInfo, index) => {
  //           this.progressInfos[index].value = 0; // Reset if there is an error
  //         });
  //         this.message = 'Could not upload the files';
  //         console.error(err);
  //         this.loadingService.hideOverlay();
  //       },
  //       complete: () => {
  //         completedUploads = files.length; // All uploads are done
  //         if (completedUploads === files.length) {
  //           // All files have been uploaded, now send the email
  //           this.documentService.sendHistoricalInvoices(this.documentType, moment(this.reconDate).format('YYYY-MM-DD HH:mm:ss'), containerName)
  //             .subscribe({
  //               next: () => {
  //                 this.message += ' Email sent successfully!';
  //               },
  //               error: (err) => {
  //                 this.message = 'Could not send the email';
  //                 console.error(err);
  //                 this.loadingService.hideOverlay();
  //               },
  //               complete: () => {
  //                 // Final completion of the entire process
  //                 this.loadingService.hideOverlay();
  //                 this.onCloseModal.emit();
  //               }
  //             });
  //         }
  //       }
  //     });
  //   }
  // } // end uploadFiles()



  // uploadFiles(): void {
  //   let returnedObject: any[] = null;
  //   this.progressInfos = [];
  //   this.message = '';
  //   let completedUploads = 0;
  //   this.loadingService.showOverlay();

  //   if (this.selectedFiles) {
  //     const files: File[] = [];
  //     for (let i = 0; i < this.selectedFiles.length; i++) {
  //       const file = this.selectedFiles.item(i) as File;
  //       files.push(file);
  //       this.progressInfos.push({ fileName: file.name, value: 0 });
  //     }

  //     files.forEach((file, index) => {
  //       this.documentService.uploadDocuments([file], this.documentType, moment(this.reconDate).format('YYYY-MM-DD HH:mm:ss')).subscribe({
  //         next: (event) => {
  //           console.log('UPLOADED DOCUMENTS RESPONSE: ', event);
  //           if (event.status === 'progress') {
  //             this.progressInfos[index].value = event.percentage;
  //           } else if (event.status === 'complete') {
  //             this.message = 'Upload complete!';
  //           }
            
  //           if(event.data) {
  //             returnedObject = event.data;
  //           }
  //         },
  //         error: (err) => {
  //           this.progressInfos[index].value = 0; // Reset if there is an error
  //           this.message = 'Could not upload the file ' + file.name;
  //           console.error(err);
  //           this.loadingService.hideOverlay();
  //         },
  //         complete: () => {
  //           completedUploads++;
  //           if (completedUploads === files.length) {
  //             // All files have been uploaded, now send the email
  //             this.documentService.sendHistoricalInvoices(this.documentType, moment(this.reconDate).format('YYYY-MM-DD HH:mm:ss'), returnedObject[0].BlobContainerName)
  //               .subscribe({
  //                 next: () => {
  //                   this.message += ' Email sent successfully!';
  //                 },
  //                 error: (err) => {
  //                   this.message = 'Could not send the email';
  //                   console.error(err);
  //                   this.loadingService.hideOverlay();
  //                 },
  //                 complete: () => {
  //                   // Final completion of the entire process
  //                   this.loadingService.hideOverlay();
  //                   this.onCloseModal.emit();
  //                 }
  //               });
  //           }
  //         }
  //       });
  //     });
  //   }
  // } // end uploadFiles()
} // end UploadhistoricalDataModalComponent{}
