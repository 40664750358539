import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export interface PaymentDetail {
  InvoiceNo: string;
  PaymentDate: string;
  Reference: string;
  Split: string;
  Amount: number;
  InvoiceAmount: number;
  Outstanding: number;
  Status: string;
  TransactionAmount: number;
} // end paymentDetail{}

export const DEFAULT_PAYMENT_DETAIL = {
  InvoiceNo: '',
  PaymentDate: '',
  Reference: '',
  Split: '',
  Amount: '',
  InvoiceAmount: '',
  Outstanding: '',
  Status: '',
  TransactionAmount: 0
}; // end paymentDetail{}

@Injectable()
export class PaymentDetailService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  /**
   * GET: Returns all transactions in an invoice
   * @returns {invoiceID} Returns all transactions in an invoice.
   */
  getPaymentDetail(invoiceId: number): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.paymentDetail +
      '?InvoiceId=' + invoiceId, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  }
} // end PaymentDetail{}
