import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {
  ApiService,
  HTTP_STATUS_BAD_REQUEST,
  HTTP_STATUS_NETWORK_CONNECTION
} from '../api.service';
import { Observable } from 'rxjs';

import { ToastService, Toast } from '../messaging/toast/toast.service';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

export interface SearchPayload {
  page: number;
  pageSize: number;
  includeFacets: boolean;
  text: string;
  orderBy: string;
  queryType: string;
  searchMode: string;
}

@Injectable()
export class SearchService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private toastService: ToastService
  ) { } // end constructor()

  search(payload: SearchPayload): Observable<any> {
    return this.http.post<SearchPayload>(this.api.endpoints.search +
      '?ServiceProviderID=' + this.api.serviceProviderID, payload, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end search()
} // end SearchService{}
