<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onDismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div><!--#end .modal-header -->

      <div class="modal-body form">

        <!-- Attorney -->
        <div class="form-group">
          <label for="attorney">Attorney<span title="Required field"> *</span></label>
          <input
            type="text"
            class="form-control"
            [class.has-error]="isValidAttName"
            [id]="attorney"
            [(ngModel)]="attorney"
            (blur)="onBlurAttName()"
            placeholder="Name of attorney at law firm">
            <span
            *ngIf="isValidAttName"
            class="text-danger">Please enter a valid Attorney name.</span>
        </div>

        <!-- Firm name -->
        <div class="form-group">
          <label for="firm-name">Firm name<span title="Required field"> *</span></label>
          <input
            type="text"
            class="form-control"
            [class.has-error]="isValidLawFirmName"
            [id]="firm-name"
            [(ngModel)]="firmName"
            (blur)="onBlurLawFirmName()"
            (focus)="onFocusLawFirmName()"
            placeholder="Name of the law firm">
            <span
            *ngIf="isValidLawFirmName"
            class="text-danger">Please enter a valid Law firm name.</span>
        </div>

        <!-- Phone number -->
        <div class="form-group">
          <label for="phone-number">Phone number<span title="Required field"> *</span></label>
          <input
            type="text"
            class="form-control"
            [class.has-error]="isValidAttPhoneNumber"
            [id]="phone-number"
            [(ngModel)]="phoneNumber"
            (focus)="onFocusAttPhoneNumber()"
            (blur)="onBlurAttPhoneNumber()"
            placeholder="Attorney phone number">
            <span
            *ngIf="isValidAttPhoneNumber"
            class="text-danger">Please enter a valid phone number.</span>
        </div>

        <!-- Email address -->
        <div class="form-group">
          <label for="email-address">Email address<span title="Required field"> *</span></label>
          <input
            type="email"
            class="form-control"
            [class.has-error]="isValidAttEmail"
            [id]="email-address"
            [(ngModel)]="emailAddress"
            (blur)="onBlurEmail()"
            (focus)="onFocusEmail()"
            placeholder="Attorney email address">
            <span
            *ngIf="isValidAttEmail"
            class="text-danger">Please enter a valid email address.</span>
        </div>

      </div><!--#end .modal-body -->

      <div class="modal-footer">
        <button
          class="btn btn-success"
          [class.disabled]="!isSubmittable"
          (click)="submitRequest()"
          [disabled]="!isSubmittable">Submit Request</button>
      </div><!--#end .modal-footer -->

    </div><!--#end .modal-dialog -->
  </div><!--#end .modal-content -->
</div><!--#end .modal -->

<app-prompt-unsaved-changes-modal
  message="Are you sure you want to leave this screen? You have unsaved changes."
  [isVisible]="showUnsavedChangesPrompt"
  (cancel)="onHideUnsavedChangesPrompt()"
  (continue)="onDismissPromptUnsavedChangesModal()"></app-prompt-unsaved-changes-modal>
  <!--#end app-prompt-unsaved-changes-modal -->