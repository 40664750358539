<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>{{title}}</h2>
                <button class="modal-dismiss" (click)="dismiss()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body form">
                
                <div class="row">
                    <div class="form-group">
                        <label for="attorney-name">Attorney Name<span title="Required field"> *</span></label>
                        <input
                            class="form-group"
                            required
                            id="attorney-name"
                            placeholder="Enter an Attorney Name"
                            [(ngModel)]="newAttorney.FullName">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group">
                        <label for="attorney-name">Cell<span title="Required field"> *</span></label>
                        <input
                            class="form-group"
                            required
                            id="attorney-name"
                            placeholder="Enter Cell Number"
                            [(ngModel)]="newAttorney.Cell">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group">
                        <label for="attorney-name">Phone</label>
                        <input
                            class="form-group"
                            id="attorney-name"
                            placeholder="Enter Phone Number"
                            [(ngModel)]="newAttorney.Phone">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group">
                        <label for="attorney-name">Fax</label>
                        <input
                            class="form-group"
                            id="attorney-name"
                            placeholder="Enter Fax Number"
                            [(ngModel)]="newAttorney.Fax">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group">
                        <label for="attorney-name">Email<span title="Required field"> *</span></label>
                        <input
                            class="form-group"
                            required
                            id="attorney-name"
                            placeholder="Enter Email Address"
                            [(ngModel)]="newAttorney.Email">
                    </div>
                </div>

            </div>

            <div class="modal-footer">
                <button
                    class="btn btn-danger"
                    (click)="dismiss()">Cancel</button>
                <button
                    class="btn btn-success"
                    (click)="onAddAttorney()"
                    [class.disabled]="!isSaveable"
                    [disabled]="!isSaveable">Save</button>
            </div>

        </div>
    </div>
</div>