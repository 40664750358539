import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Modal } from '../../../classes/messaging/modal';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent extends Modal implements OnInit, OnChanges {

  @Input() isVisible: boolean;
  @Input() message: string;
  @Input() link: string;
  @Input() linkType: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();

  formattedLink: string;

  constructor(
    protected util: UtilitiesService
  ) {
    super(util);
    this.linkType = 'text';
  } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.linkType && changes.linkType.currentValue) {
      this.buildLink(this.linkType);
    }

    if (changes.link && changes.link.currentValue) {
      this.buildLink(this.linkType);
    }
  } // ngOnChanges()
  // mailto: supports@auxcon.co.za
  buildLink(linkType: string) {
    switch (linkType) {
      case 'email':
        this.formattedLink = 'mailto:' + this.link;
        break;
    }
  }

  onConfirm() {
    this.cancel.emit('cancel');
  } // end onConfirm()
} // end AlertComponent{}
