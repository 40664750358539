<div class="modal" *ngIf="isVisible" (click)="onModalClick($event)">
  <div class="modal-content">
    <div class="modal-dialog">
      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onShowUnsavedChangesPrompt()">
          <i class="fa fa-close"></i>
        </button>
      </div>
      <div class="modal-body form">
        <div class="row">
          <div class="form-group">
            <label for="choose-matter">Choose matter<span title="Required field"> *</span></label>
            <app-datalist-matter [list]="matters" [matter]="selectedMatter" (matterChange)="onMatterSelect($event)"
              (select)="onMatterSelect($event)"></app-datalist-matter>
          </div>
          <div class="input-group-append" *ngIf="isOutlookCalendarEntry">
            <span class="input-group-text" (click)="AddNewMatter()"
              style="height: 30px; margin-top: 27px; left: -8px; position: relative; cursor: pointer;">
              <i class="fa fa-plus"
                style="background: #8abd37; padding: 3px 4px; color: #e9ecef; border-radius: 15px; font-size: 10px;"></i>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <label for="choose-category">Choose description<span title="Required field"> *</span></label>
            <app-datalist-category type="sub-category" [list]="subCategories" [value]="calendarItem.FurtherDescription"
              [category]="selectedSubCategory" (categoryChange)="onSubCategoryChange($event)"
              (input)="onSubCategoryInput($event, calendarItem)"></app-datalist-category>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <label for="rate-type">Rate type</label>
            <input *ngIf="!canChangeRateType" type="text" [value]="calendarItem.RateType" readonly>
            <select *ngIf="canChangeRateType" id="rate-type" (change)="onRateTypeChange()"
              [(ngModel)]="calendarItem.RateType">
              <option *ngFor="let type of rateTypes" [value]="type">{{type}}</option>
            </select>
          </div>
          <div class="form-group" [ngSwitch]="calendarItem.RateType">
            <label for="rate">Rate</label>
            <input *ngSwitchCase="'Non Billable'" type="text" currencyMask [options]="{ prefix: currencySymbol }"
              [ngModel]="calendarItem.Rate" readonly>
            <input *ngSwitchDefault type="text" (focus)="onRateFocus($event)"
              [ngModel]="calendarItem.Rate | currency: currencySymbol: currencySymbol:'1.2-2'"
              [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="calendarItem.Rate=$event"
              (ngModelChange)="updateFeeTotal()" (blur)="onFocusRateChange()" (click)="onFocusRateClicked()"
              (click)="$event.target.select()" #inputRate pattern="[R\s]*[0-9,.]+">
          </div>
          <div class="form-group">
            <label for="date">Date</label>
            <input type="text" bsDatepicker [bsValue]="calendarItemDate"
              (ngModel)="calendarItemDate=$event.target.value" (bsValueChange)="onDateChange($event)"
              [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" placement="right">
          </div>
          <div class="form-group">
            <label for="quantity">Quantity
              <i class="fa fa-question-circle clickable tooltip" [class.active]="showQunatityToolTip"
                (blur)="hideToolTip('quantity')" (click)="toggleToolTip('quantity')">
                <span class="tooltiptext">Quantity in calendar is not editable for Daily, Hourly & Once Off rate
                  types.</span>
              </i>
            </label>
            <input type="number" class="quantity-input DisabledQuantity" (input)="onUpdateQuantity()"
              [(ngModel)]="calendarItem.Quantity" [value]="calendarItem.Quantity" [readOnly]="isQuantityEditable">
          </div>
          <div class="form-group">
            <label for="Discount">Discount
            </label>
            <input type="text" class="discount-input" (focus)="onDiscountFocus($event)"
              [ngModel]="calendarItem.Discount | currency: currencySymbol: currencySymbol:'1.2-2'"
              [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="calendarItem.Discount=$event"
              (ngModelChange)="updateFeeTotal()" (blur)="onDiscountChange()" (click)="onFocusRateClicked()"
              (click)="$event.target.select()" #inputDiscount pattern="[R\s]*[0-9,.]+">
          </div>
        </div>
        <div class="row" [class.hide]="calendarItem.RateType !== 'Hourly'">
          <div class="form-group">
            <label for="start-time">Start time</label>
            <input *ngIf="isSafari" type="time" atp-time-picker [class.has-error]="hasStartTimeAfterEndTimeError"
              [value]="startTime" (click)="OpenStartTime()" (focus)="OpenStartTime()">
            <input *ngIf="!isSafari" type="time" atp-time-picker [class.has-error]="hasStartTimeAfterEndTimeError"
              [value]="startTime" (click)="OpenStartTime()" (focus)="OpenStartTime()">
            <div *ngIf="hasStartTimeAfterEndTimeError" class="text-danger">The start time cannot be after the end time.
            </div>
          </div>
          <div class="form-group">
            <label for="end-time">End time</label>
            <input type="time" atp-time-picker *ngIf="isSafari" [class.has-error]="hasEndTimeBeforeStartTimeError"
              [value]="endTime" (click)="OpenEndTime()" (focus)="OpenEndTime()" style="background: none;">
            <input type="time" atp-time-picker *ngIf="!isSafari" [class.has-error]="hasEndTimeBeforeStartTimeError"
              [value]="endTime" (click)="OpenEndTime()" (focus)="OpenEndTime()" style="background: none;">
            <div *ngIf="hasEndTimeBeforeStartTimeError" class="text-danger">The end time cannot before the start time.
            </div>
          </div>
        </div>
        <div class="row" *ngIf="calendarItem.RateType === 'Disbursement'">
          <div class="form-group">
            <app-checkbox class="clickable" [checked]="calendarItem.IsVatable" (click)="toggleVatable()"></app-checkbox>
            <label for="vatable" class="clickable" (click)="toggleVatable()">Vatable</label>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <div class="fee-total">
              <span class="column-total-label"></span>
              <span class="column-total-value">Total Amount: {{calendarItem.Total | currency:currencySymbol}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="isOutlookCalendarEntry" class="btn btn-danger btn-start" (click)="onHideThisClick()">
          Hide This Event
        </button>
        <button *ngIf="!isOutlookCalendarEntry" class="btn btn-danger btn-start" [class.disabled]="!isDeleteable"
          [disabled]="!isDeleteable" (click)="onShowDeleteFeeModal()">Delete fee</button>
        <span></span>
        <button class="btn btn-success btn-end" [class.disabled]="!isSaveable" [disabled]="!isSaveable"
          (click)="onSave()">Save and close</button>
      </div>
    </div>
  </div>
</div>
<app-add-new-matter [isVisible]="isAddNewMatter" [EventDetails]="EventDetails"
  (closeNewMatterModal)="closeNewMatterModal($event)"></app-add-new-matter>
<app-prompt-unsaved-changes-modal message="Are you sure you want to leave this screen? You have unsaved changes."
  [isVisible]="showUnsavedChangesPrompt" (cancel)="onHideUnsavedChangesPrompt()" (continue)="dismiss()"
  (confirm)="onSave()"></app-prompt-unsaved-changes-modal>
<app-delete-fee-modal [isVisible]="showDeleteFeeModal" [fee]="calendarItem" (cancel)="onCloseDeleteFeeModal()"
  (confirm)="onDelete()"></app-delete-fee-modal>
<app-prompt-unsaved-changes-modal title="Please confirm"
  message="{{'Are you sure you want to hide ' + calendarItem.FurtherDescription + ' event?'}}" btnTextCancel="Cancel"
  btnTextContinue="Confirm" [isVisible]="showHideClendarPrompt" (cancel)="onHideOutlookPrompt()"
  (continue)="onHideThis()">