import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService, httpOptions } from '../api.service';
import { Observable } from 'rxjs';
import { CSDDocuments } from '../csd-documents/csd-documents.service';

@Injectable()
export class FileUploadService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  postBriefDocumentAttachement(serviceId: number, documentTypeId: number, file: FormData): Observable<any> {
    return this.http.request('POST', this.api.endpoints.services +
    '/BriefAttachment?ServiceProviderId=' + this.api.serviceProviderID +
      '&ServiceId=' + serviceId + '&DocumentTypeId=' + documentTypeId,
      {
        body: file
      });
  } // end postBriefDocumentAttachment()

  // postCSDDocumentAttachment(contactId: number, documentTypeId: number, file: FormData): Observable<any> {
  //   return this.http.request('POST', this.api.endpoints.csdDocuments +
  //   '/PostCSDDocument?ContactId=' + contactId +
  //   '&DocumentTypeId=' + documentTypeId,
  //   {
  //     body: file
  //   });
  // } // end postCSDDocumentAttachment()

  // putCSDDocumentAttachment(document: CSDDocuments, file: FormData): Observable<any> {
  //   return this.http.request('PUT', this.api.endpoints.csdDocuments +
  //   '/EditCSDDocument?contactId=' + document.ContactId +
  //   '&documentId=' + document.Id,
  //   {
  //     body: file
  //   });
  // } // end putCSDDocumentAttachment()

  // putCSDServiceDocumentAttachment(document: CSDDocuments, serviceId: number, file: FormData): Observable<any> {
  //   return this.http.request('PUT', this.api.endpoints.csdDocuments +
  //   '/EditCSDDocument?contactId=' + document.ContactId +
  //   '&documentId=' + document.Id +
  //   '&serviceId=' + serviceId,
  //   {
  //     body: file
  //   });
  // } // end putCSDDocumentAttachment()
} // end FileUploadServices
