import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { SnackbarsService, Snack } from '../../../services/messaging/snackbars/snackbars.service';
import {
  NotesObject,
  DEFAULT_NOTES_OBJECT,
  NotesService,
  Notes,
  NoteCategory,
  InstructionResponses,
} from '../../../services/notes/notes.service';
import {
  LawfirmSummaryOverview,
  ReportsService,
  DEFAUL_LAWFIRM_SUMMARY_OVERVIEW
} from '../../../services/reports/reports.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import * as moment from 'moment';

@Component({
  selector: 'app-client-note-modal',
  templateUrl: './client-note-modal.component.html',
  styleUrls: ['./client-note-modal.component.scss']
})
export class ClientNoteModalComponent implements OnInit, OnChanges {

  title = 'Advocate Description';
  clientDetails = 'Advocate Detail';
  notesDetails = 'Advocate Note';
  activeTab = 'notes-detail';

  isConvesrationChecked: boolean;
  isTableChecked: boolean;
  isInternalChecked: boolean;
  isExternalChecked: boolean;

  invoiceNoteDetail: any[] = [];
  noteDetailData: NotesObject;
  onShowNoteDetail: boolean;

  noteCategory: NoteCategory[] = [];
  selectedNote: NotesObject;

  // Law firm details
  lawfirmSummaryData: LawfirmSummaryOverview;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };
  showAdvocateNoteDetail: boolean;
  instructionResponses: InstructionResponses[] = [];
  objectType = 'contact';

  noteStatusUpdate = 'no';
  @Input() isVisible: boolean;
  @Input() selectedTab: string;
  @Input() notes: NotesObject[];
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveNote: EventEmitter<any> = new EventEmitter<any>();

  // Is Practice Manager
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER); // &&
  }  // end isPracticeManager()

  constructor(
    private notesService: NotesService,
    private loadingService: LoadingService,
    private reportsService: ReportsService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
  ) {
    this.isTableChecked = true;
  } // end constructor()

  ngOnInit() {
    this.notes.sort((a, b) => {
      return b.CaptureDate.toUpperCase().localeCompare(a.CaptureDate.toUpperCase());
    });
  } // end ngOnInit()

  pmLatestNote(data: NotesObject): string {
    let q_text = '';

      if (data && data.InternalText) {
        q_text = data.InternalText;
      } else if (data && data.ResponseText) {
        q_text = data.ResponseText + ' ' + data.InstructionText;
      } else if (data && data.QuestionText) {
        q_text = data.Subject + ' ' + data.QuestionText;
      } else if (data && data.ExternalText) {
        q_text = data.ExternalText;
      } else if (data && data.ResolutionText) {
        q_text = data.ResolutionText;
      }
      
      return q_text;
  } // end pmLatestNote()

  ngOnChanges(changes: SimpleChanges) {
    this.notes.sort((a, b) => {
      return a.ObjectTypeDescription.toUpperCase().localeCompare(b.ObjectTypeDescription.toUpperCase());
    });
    // this.invoiceNoteDetail.sort((a, b) => {
    //   return a.InvoiceNo.toUpperCase().localeCompare(b.InvoiceNo.toUpperCase());
    // });
    this.updateUIComponent();
  } // end ngOnChanges()

  setActiveTab(tab: string) {
    this.activeTab = tab;
  } // end setActiveTab()

  closeModel(value) {
    switch (value) {
      case 'advocate-note':
        this.showAdvocateNoteDetail = false;
      break;
    }
  } // end closeModel()

  noteUserLogger(note: NotesObject): string {
    let user = '';
    if (note.Personal) {
      user = note.UserCreatorFullName;
    } else if (!note.Personal && note.Category === 'Instruction') {
      user = note.UserCreatorFullName;
    } else if (!note.Personal && note.InstructionResponseId > 0) {
      user = note.UserCreatorFullName;
    } else {
      user = note.UserOwnerFullName;
    }
    return user;
  } // noteUserLogger()

  toggleCheckbox(clicked: string) {
    if (clicked === 'table') {
      this.isTableChecked = true;
      this.isConvesrationChecked = false;
      this.updateUIComponent();
    } else if (clicked === 'conversation') {
    this.isConvesrationChecked = true;
    this.isTableChecked = false;
    }
  } // end toggleCheckbox()

  toggleIntExtCheckbox(clicked: string) {
    if (clicked === 'internal') {
      this.isInternalChecked = true;
      this.isExternalChecked = false;
    } else if (clicked === 'external') {
    this.isExternalChecked = true;
    this.isInternalChecked = false;
    }
  } // end toggleIntExtCheckbox()

  dismiss() {
    this.invoiceNoteDetail = [];
    this.closeModal.emit(this.noteStatusUpdate);
  } // end dismiss()

  onAddNote() {
    const snack: Snack = {
      label: 'Note saved ...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.closeModal.emit(this.noteStatusUpdate);

  } // end onAddNote()

  updateUIComponent() {
    $(function () {
      // Function Created By: Wiseman
      // Website: http://auxcon.co.za
      function groupTable($rows, startIndex, total) {
        if (total === 0) {
          return;
        }
        var i, currentIndex = startIndex, count = 1, lst = [];
        var tds = $rows.find('td:eq(' + currentIndex + ')');
        var ctrl = $(tds[0]);
        lst.push($rows[0]);
        for (i = 1; i <= tds.length; i++) {
          if (ctrl.text() === $(tds[i]).text()) {
            count++;
            $(tds[i]).addClass('deleted');
            lst.push($rows[i]);
          } else {
            if (count > 1) {
              ctrl.attr('rowspan', count);
              groupTable($(lst), startIndex + 1, total - 1);
            }
            count = 1;
            lst = [];
            ctrl = $(tds[i]);
            lst.push($rows[i]);
          }
        }
      }
      groupTable($('#conversationTable tr:has(td)'), 0, 1);
      $('#conversationTable .deleted').remove();
    });
  } // end updateUIComponent()

  onNoteDetailView(data: NotesObject) {
    this.loadingService.showOverlay();
    this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.notesService.getPMInstructionResponses().subscribe({ next: (_data) => {
      // On next
      if (this.userProfileService.selectedUserProfile.regionalBarId !== 5) {
        this.instructionResponses = _data.filter(x => x.Id !== 175);
      } else {
        this.instructionResponses = _data;
      }
    }});
    this.noteDetailData = data;
    if (data.UserCreatorStatus === false) {
      data.UserCreatorStatus = true;
      this.noteStatusUpdate = 'yes';
       this.notesService.putNoteInboxStatus(data).subscribe({ next: (_next) => {}});
      this.notes.forEach(x => {
        if (x === data) {
          x.UserCreatorStatus = true;
        }
      });
    }
    this.onLawfirmSummary(data.ObjectId);
    this.onShowNoteDetail = true;
    this.loadingService.hideOverlay();
  } // end onNoteDetailView()

  onAdvNoteDetailView(data: NotesObject) {
    this.instructionResponses = [];
    this.loadingService.showOverlay();
    this.selectedNote = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.selectedNote = data;
    if (data.UserAssignedStatus === false) {
      data.UserAssignedStatus = true;
       this.notesService.putNoteInboxStatus(data).subscribe({ next: (_next) => {}});
      this.notes.forEach(_note => {
        if (_note === data) {
          _note.UserAssignedStatus = true;
        }
      });
    }
    this.notesService.getInstructionResponses(this.selectedNote.QuestionId).subscribe({ next: (_data) => {
      // On next
      if (this.userProfileService.selectedUserProfile.regionalBarId !== 5) {
        this.instructionResponses = _data.filter(x => x.Id !== 175);
      } else {
        this.instructionResponses = _data;
      }
    }});
    this.onLawfirmSummary(data.ObjectId);
    this.showAdvocateNoteDetail = true;
    this.loadingService.hideOverlay();
  } // end onAdvNoteDetailView()

  OnAddResolution(note: Notes) {
    this.loadingService.showOverlay();
    this.notesService.putNote(note).subscribe({
      next: (updatedNote) => {
        // On next
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On compolete
        this.onShowNoteDetail = false;
        this.loadingService.hideOverlay();
        this.saveNote.emit();
    }
  });
  } // end OnAddResolution()

  onShowNoteDetailModalDismiss() {
    this.onShowNoteDetail = false;
  } // end onShowNoteDetailModalDismiss()

  onAddNewNote() {
    this.loadingService.showOverlay();
    this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.noteDetailData.ObjectId = this.notes[0].ObjectId;
    this.noteDetailData.ObjectTypeDescription = this.notes[0].ObjectTypeDescription;
    this.noteDetailData.CaptureDate = moment().toString();
    this.onLawfirmSummary(this.notes[0].ObjectId);
    this.objectType = 'contact';
    this.instructionResponses  = [];
    this.notesService.getPMInstructionResponses().subscribe({ next: (_data) => {
      // On next
      if (this.userProfileService.selectedUserProfile.regionalBarId !== 5) {
        this.instructionResponses = _data.filter(x => x.Id !== 175);
      } else {
        this.instructionResponses = _data;
      }
    }});
    this.getNoteCategories();
    this.onShowNoteDetail = true;
    this.loadingService.hideOverlay();
  } // end onAddNewNote()

  getNoteCategories() {
    this.noteCategory = [];
    this.notesService.getNoteCategory().subscribe({ next: (_data) => {
      // On next
      if (this.userProfileService.userProfile.serviceProviderID === 29723) {
        this.noteCategory = _data.filter(a => a.ObjectTypeId === 2 && a.Category === 'Legal Files');
      } else {
        this.noteCategory = _data.filter(x => x.ObjectTypeId === 2);;
      }
    }});
    // this.noteCategory = this.noteCategory.filter(x => x.ObjectTypeId === 2);
    this.noteCategory.sort((a, b) => {
      return a.Category.toUpperCase().localeCompare(b.Category.toUpperCase());
    });
    
  } // end getNoteCategories()

  onLawfirmSummary(lawfirm: number) {
    this.loadingService.showOverlay();
    this.lawfirmSummaryData = Object.assign({}, DEFAUL_LAWFIRM_SUMMARY_OVERVIEW);
    this.reportsService.getLawfirmSummaryOverview(lawfirm).subscribe({ next: (_data) => {
      // On next
      this.lawfirmSummaryData = _data;
    }});
    this.loadingService.hideOverlay();
  } // end onLawfirmSummary()

  onNoteSave(note: Notes) {
    this.loadingService.showOverlay();
    this.notesService.postNote(note).subscribe({
      next: (added_note) => {
        // On next
      },
      error: (error) => {
        // On Error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.noteStatusUpdate = 'yes';
        this.onShowNoteDetail = false;
        this.saveNote.emit();
        this.loadingService.hideOverlay();
      }
    });
  } // end onNoteSave()

  onInstructionIssued(note: Notes) {
    note.NoteTypeId = 294;
    this.loadingService.showOverlay();
    this.notesService.postInstructionNote(note).subscribe({
      next: (added_note) => {
        // On next
      },
      error: (error) => {
        // On Error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.onShowNoteDetail = false;
        this.saveNote.emit(note);
        this.loadingService.hideOverlay();
      }
    });
  } // end onInstructionIssued()

  getUnReadMessageFont(value: boolean): string {
    if (value === false) {
      return 'Bold';
    }
  } // end getUnReadMessageFont()

  getAdvUnReadMessageFont(value: boolean): string {
    if (value === false) {
      return 'Bold';
    }
  } // end getAdvUnReadMessageFont()
} // end ClientNoteModalComponent{}
