<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid">
    <!-- <h2 class="page-title">PROCESS | SEND STATEMENTS</h2> -->
    <div *ngIf="isSent === false" class="send-body row">
        <div class="col-sm-12">

            <div class="row send-statement-search input-group" style="margin-bottom: 10px;">
                <input type="search" class="form-control" placeholder="Search" [(ngModel)]="searchTerm"
                    (keyup)="onSearch()">
                <div class="input-group-append">
                    <i *ngIf="!searchTerm" class="far fa-search"></i>
                    <i *ngIf="searchTerm" (click)="clearSearch()" class="fa fa-close"></i>
                </div>
            </div>

            <div class="table-responsive" style="max-height: 437px">
                <table class="table table-striped ">

                    <tbody>
                        <tr>
                            <th *ngIf="isAttorney || isAssistantToLawfirm || isLawfirm" scope="col"
                                (click)="toggleSort('law-firm')" class="align-bottom">Client
                                <i class="fa fa-sort" [class.fa-sort-down]="sortLawFirm"></i>
                            </th>
                            <th *ngIf="!isAttorney && !isAssistantToLawfirm && !isLawfirm" scope="col"
                                (click)="toggleSort('law-firm')" class="align-bottom">Law Firm
                                <i class="fa fa-sort" [class.fa-sort-down]="sortLawFirm"></i>
                            </th>
                            <th scope="col" class="align-bottom">Email Address
                            </th>
                            <th style="width: 13%;" scope="col" (click)="toggleSort('date')" class="align-bottom">Last
                                Statement Sent
                                <i class="fa fa-sort" [class.fa-sort-down]="sortDate"></i>
                            </th>
                            <th style="width: 19%;" scope="col" (click)="toggleSort('statement')"
                                class="text-truncate-days align-bottom ">
                                Days Since Last
                                Statement Sent
                                <i class="fa fa-sort" [class.fa-sort-down]="sortStatement"></i>
                            </th>
                            <th style="width: 5%;"></th>
                            <th style="width: 5%;"></th>
                            <th style="width: 10%;" scope="col" (click)="toggleSort('due')"
                                class="text-right align-bottom">Total
                                Due
                                <i class="fa fa-sort" [class.fa-sort-down]="sortDue"></i>
                            </th>
                            <th scope="col" class="text-truncate-include align-bottom">
                                <div class="checkbox">
                                    <input #inputCheckSelectAll type="checkbox" (change)="toggleSelectAll()">
                                </div>
                            </th>
                        </tr>
                        <tr *ngFor="let statement of sendStatementHandle">
                            <td *ngIf="statement.state.isVisible"><u>{{statement.data.LawFirm}}</u></td>
                            <td *ngIf="statement.state.isVisible" style="max-width: 180px;">
                                <div class="badge">
                                    <input required type="text" class="email-address"
                                        [title]="statement.data.EmailAddresses"
                                        [(ngModel)]="statement.data.EmailAddresses"
                                        (ngModelChange)="onEmailChanges(statement)" autocomplete="off" required>
                                    <i title="Save/Update email" class="fa fa-plus-circle fa-lg"
                                        (click)="showAddEmailModal(statement.data)"></i>
                                </div>
                                <span *ngIf="hasValidEmailError(statement.data)" class="text-danger">Please enter a
                                    valid email address.</span>
                            </td>
                            <td *ngIf="statement.state.isVisible" [ngStyle]="{'color': getCellColor(statement.data)}">
                                {{statement.data.LastStatementSent | date:'yyyy-MM-dd'}}</td>
                            <td *ngIf="statement.state.isVisible">{{statement.data.DaysSinceLast}} Days</td>
                            <td *ngIf="statement.state.isVisible">
                                <i class="far fa-landmark" style="cursor: pointer" (click)="onFirmAllocationClick()"
                                    *ngIf="statement.data.AmountAllocatedToFirm"></i>
                            </td>
                            <td *ngIf="statement.state.isVisible">
                                <i class="far fa-credit-card" style="cursor: pointer"
                                    *ngIf="statement.data.DebitOrder"></i>
                            </td>
                            <td *ngIf="statement.state.isVisible" class="text-right">{{statement.data.TotalDue |
                                currency:currencySymbol}}</td>

                            <td *ngIf="statement.state.isVisible" scope="row">
                                <!-- <div class="checkbox">
                                    <input type="checkbox" [(ngModel)]="statement.data.IncludeInRun"
                                        id="{{statement.data.MessageId}}">
                                    <label for="{{statement.data.MessageId}}"></label>
                                </div> -->
                                <app-small-checkbox [(checked)]="statement.state.isSelected"
                                    (click)="toggleCheckbox(statement)"></app-small-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <!-- <div *ngIf="isSent === false" class="row m-0 send-footer">
        <div class="col-sm-4 p-0 pr-10"><button class="form-control btn btn-danger" (click)="onCancelClicked()">CANCEL</button></div>
        <div class="col-sm-4 p-0 pl-5 pr-5"></div>
        <div class="col-sm-4 p-0 pl-10"><button class="form-control btn btn-success" (click)="onSendClick()">SEND
            </button></div>
    </div> -->
    <div class="well footer-buttons">
        <button style="width: 30%; align-self: left;" class="btn btn-danger" (click)="onCancelClicked()">CANCEL</button>
        <!-- <button
                style="width: 30%; align-self:center;"
                [class.disabled]="!canSaveEmails"
                [disabled]="!canSaveEmails"
                class="btn btn-primary"
                (click)="onSaveEmails()">SAVE EMAIL(S)</button> -->

        <button class="btn btn-success" [class.disabled]="!isSaveable" [disabled]="!isSaveable"
            style="width: 30%; align-self: right;" (click)="sendStatementToClient()">SEND</button>
    </div> <!-- #end .well -->

    <div *ngIf="isSent === true" class="sent-body row ">
        <div class="col-sm-12">
            <div class="row icon">
                <i class="fal fa-check-circle"></i>
            </div>
            <div class="row statement">
                Statements sent to Law Firms - ({{count}})
            </div>
            <div class="row statement" *ngIf="isSageAccountingPlatform">
                "{{totalAccountingTransaction}} accounting platform transactions in progress - (DEBIT AMOUNT - R
                {{totalDebitAmountTransaction |
                number : '1.2-2'}})" & (CREDIT AMOUNT - R
                {{totalCreditAmountTransaction | number : '1.2-2'}})
            </div>
        </div>
    </div>
</div>

<app-prompt-unsaved-changes-modal title="Unresolved Payment indication(s)"
    message="You have unresolved Indication(s). Do you wish to proceed with this statement run or resolve the indication(s)?"
    [isVisible]="showErrorMessagePrompt" btnTextContinue="Proceed" btnTextCancel="Resolve"
    (cancel)="onHideErrorMessagePrompt()" (continue)="sendIndicationStatementToClient()"></app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal title="Revised transaction(s)"
    message="You have revised transactions linked to invoice(s), if you proceed the invoice(s) will be unlinked from the transactions. Do you wish to proceed?"
    [isVisible]="showRevisedPrompt" btnTextContinue="Proceed" btnTextCancel="Resolve" (cancel)="onHideRevisedPrompt()"
    (continue)="onSendClick()"></app-prompt-unsaved-changes-modal>

<app-add-statement-recipient [isVisible]="showRecipientsModal" [sendMessageRecipient]="sendMessageRecipient"
    (closeModal)="onCloseModal()" (updateEmails)="onSaveOrUpdateEmails($event)">
</app-add-statement-recipient>

<app-prompt-unsaved-changes-modal message="You have unsaved changes. Are you sure you want to leave the page?"
    [isVisible]="showUnsavedChangesPrompt" (cancel)="onHideUnsavedChangesPrompt()" (continue)="leavePage()">
</app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal 
    *ngIf="showUnAllocatedTransactionPrompt"
    title="Unallocated transactions"
    message="You are unable to send statements due to unallocated transaction that needs to be posted to SAGE. Kindly allocate the transactions to proceed."
    [isVisible]="showUnAllocatedTransactionPrompt" 
    btnTextContinue="OK" 
    btnTextCancel="Cancel"
    (cancel)="onHideUnAllocatedTransactionPrompt()" 
    (continue)="onSendClick()"></app-prompt-unsaved-changes-modal>