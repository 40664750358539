import { Directive, HostListener, ElementRef, OnInit, ViewChild  } from '@angular/core';
import { UsercurrencyPipe } from '../pipe/usercurrency.pipe';

@Directive({
  selector: '[appUsercurrency]'
})
export class UsercurrencyDirective implements OnInit {
  // private el: any;

  @ViewChild('[appUsercurrency]') el: ElementRef;
  constructor(
    // private elementRef: ElementRef,
    private formatcurrencypipe: UsercurrencyPipe
  ) {
  }
  ngOnInit() {
    this.el.nativeElement.value = this.formatcurrencypipe.transform(this.el.nativeElement.value);
  }
  @HostListener('focus', ['$event.target.value', '$event'])
  onfocus(value, event) {
    this.el.nativeElement.value = this.formatcurrencypipe.parse(value); // opossite of transform
    if (event.which === 9) {
      return false;
    }
    this.el.nativeElement.select();
  }

  @HostListener('blur', ['$event.target.value'])
  onblur(value) {
    this.el.nativeElement.value = this.formatcurrencypipe.transform(value);
  }

  @HostListener('keydown', ['$event']) onkeydown(event) {
    let e = <KeyboardEvent> event;

    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
  }
}
