<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">

<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <div class="row">
          <div class="form-group">
            <label for="attorney">Attorney</label>
            <input
              [(ngModel)]="workingAttorneyData.AttorneyName"
              [typeahead]="attorneys"
              typeaheadOptionField="FullName"
              (input)="onInput($event)"
              class="form-control"
              (focus)="$event.target.select()"
              placeholder="Select or start typing"
              (typeaheadOnSelect)="onSelect($event)"
              [typeaheadOptionsLimit]="50"
              typeaheadScrollable=true
              typeaheadOptionsInScrollableView="20"
              [typeaheadMinLength]="0">
          </div> <!-- #end .form-group -->
        </div> <!-- #end .row -->
      </div> <!-- #end .modal-body .form -->

      <div class="modal-footer">
        <button
          class="btn btn-danger"
          (click)="dismiss()">Cancel</button>
        <button
          class="btn btn-success"
          [class.disabled]="!isSaveable"
          [disabled]="!isSaveable"
          (click)="save()">Update</button>
      </div> <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->