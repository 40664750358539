import { Component, OnInit } from '@angular/core';
import {
  BankAccountsService,
  BankAccounts,
  DEFAULT_BANK_ACCOUNT} from '../../../services/bank-accounts/bank-accounts.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { PATH_PROCESS } from '../../../services/appdata/app-config.service';
import { UserProfileService, UserProfile } from '../../../services/user-profile/user-profile.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { ApiService } from '../../../services/api.service';
import * as moment from 'moment';
import { DEFAULT_ACTIVITY_LOGS, ActivityLogs, ActivityLogsService } from '../../../services/activity-logs/activity-logs.service';

export interface BankAccountHandle {
  data: BankAccounts;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

@Component({
  selector: 'app-manage-bank-accounts',
  templateUrl: './manage-bank-accounts.component.html',
  styleUrls: ['./manage-bank-accounts.component.scss']
})
export class ManageBankAccountsComponent implements OnInit {
  title = 'Manage Bank Accounts';
  addEditButtonText: string;
  crumbs: ICrumb[];
  selectedBankAccountHandle: BankAccounts = Object.assign({}, DEFAULT_BANK_ACCOUNT);
  addBankAccountHandle: BankAccounts = Object.assign({}, DEFAULT_BANK_ACCOUNT);
  deleteBankAccountHandle: BankAccounts = Object.assign({}, DEFAULT_BANK_ACCOUNT);
  bankAccountHandle: BankAccountHandle[] = [];
  showAddEditBankAccount: boolean;
  showErrorMessagePrompt: boolean;
  selectedAdvocate: UserProfile;
  profile: any;
  showEditProfileModal: boolean;
  selectedTab: string;
  searchTerm: string;
  showErrorDeletePrompt: boolean;

  sortBank = false;
  sortAccountType = false;
  sortAccountNumber = false;
  sortBranchCode = false;
  sortPrimary = false;
  sortAscending = false;
  sortBy = '';
  previousSortBy = '';

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  get isAssistant(): boolean {
    return Boolean(this.profile.isImpersonator) &&
      !Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY)
      && Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ASSISTANT);
  } // end isAssistant()

  constructor(
    private api: ApiService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private bankAccountsService: BankAccountsService,
  ) { } // end constructor()

  ngOnInit() {
    this.getBankAccounts();
    this.crumbs = [
      {
        label: 'PROCESS',
        link: PATH_PROCESS
      },
      {
        label: this.title
      }
    ];
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    if (this.userProfileService.userProfile.isImpersonator) {
      this.profile = this.userProfileService.selectedUserProfile;
    } else {
      this.profile = this.userProfileService.userProfile;
    }
    this.selectedAdvocate = this.userProfileService.selectedUserProfile;
  } // end ngOnInit()

  onSearch() {
    if (this.searchTerm) {
      this.bankAccountHandle.forEach(bank => {
        if (
          !(bank.data.Bank.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(bank.data.AccountNumber.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          bank.state.isVisible = false;
        }
      });
    } else {
      this.bankAccountHandle.forEach(bank => {
        bank.state.isVisible = true;
      });
    }
  } // end onSearch()

  clearSearch() {
    this.searchTerm = '';
    this.onSearch();
  } // end clearSearch()

  getBankAccounts() {
    // this.loadingService.showOverlay();
    this.bankAccountHandle = [];
    this.bankAccountsService.getBankAccounts().subscribe({ next: (accounts) => {
      accounts.forEach(acc => {
        this.bankAccountHandle.push({
          data: acc,
          state: {
            isVisible: true,
            isSelected: false,
            isOpen: false
          }
        });
      });
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
     complete: () => {
      // On complete
      this.bankAccountHandle.sort((a, b) => {
        return a.data.SPAccType.toUpperCase().localeCompare(b.data.SPAccType.toUpperCase());
      });
      this.loadingService.hideOverlay();
    }
  });
  } // end getBankAccounts(0)

  openBankAccountModel() {
    this.selectedBankAccountHandle = Object.assign({}, DEFAULT_BANK_ACCOUNT);
    this.addEditButtonText = 'Save';
    this.showAddEditBankAccount = true;
  } // end openBankAccountModel()

  onEditBankAccount(bankAccount: BankAccounts) {
    // if (bankAccount.SPAccType === 'Primary') {
    //   this.selectedTab = 'banking';
    //   this.showEditProfileModal = true;
    // } else {
      this.selectedBankAccountHandle = Object.assign({}, DEFAULT_BANK_ACCOUNT);
      this.selectedBankAccountHandle = bankAccount;
      this.addEditButtonText = 'Update';
      this.showAddEditBankAccount = true;
    // }
  } // end onEditBankAccount()

  onDeleteBankAccount(bankAccount: BankAccounts) {
    this.deleteBankAccountHandle = bankAccount;
    this.showErrorDeletePrompt = true;
  } // end onDeleteBankAccount()

  onDeleteClick() {
    this.showErrorDeletePrompt = false;
    this.loadingService.showOverlay();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete Bank Account';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (manage-bank-account)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'BankAccount';
    this.activityLog.JsonData = JSON.stringify(this.deleteBankAccountHandle);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

    this.bankAccountsService.deleteBankAccount(this.deleteBankAccountHandle .Id).subscribe({next: (_deleteAccount) => {
      this.deleteBankAccountHandle  = Object.assign({}, DEFAULT_BANK_ACCOUNT);
      this.getBankAccounts();
      this.loadingService.hideOverlay();
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    }
  });
  } // end onDeleteClick()

  onHideErrorDeletePrompt() {
    this.showErrorDeletePrompt = false;
  } // end onHideErrorDeletePrompt()

  onUpdateClick() {
    this.showErrorMessagePrompt = false;
    this.loadingService.showOverlay();
    if (this.addBankAccountHandle.Id === 0) {
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Add Bank Account';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (manage-bank-account)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'BankAccount';
      this.activityLog.JsonData = JSON.stringify(this.addBankAccountHandle);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

      this.bankAccountsService.addBankAccount(this.addBankAccountHandle).subscribe({ next: (addedBank) => {
        // On next
      },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          setTimeout(() => {
          this.getBankAccounts();
          }, 2500);
          this.loadingService.hideOverlay();
        }
      });
    } else {
      if (this.addBankAccountHandle.Id > 0) {

        // Log activity Login
        const currentDate = new Date();
        this.activityLog.Action = 'Edit Bank Account';
        this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (manage-bank-account)';
        if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'BankAccount';
        this.activityLog.JsonData = JSON.stringify(this.addBankAccountHandle);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

        this.bankAccountsService.updateBankAccount(this.addBankAccountHandle).subscribe({ next: (bank_to_update) => {
          // On next
        },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
          },
          complete: () => {
            // On complete
            // location.reload();
            if (this.addBankAccountHandle.SPAccType === 'Primary') {
              this.getBankAccounts();
              // this.getProfile();
              this.loadingService.hideOverlay();
            } else {
              setTimeout(() => {
                this.getBankAccounts();
              }, 2500);
              this.loadingService.hideOverlay();
            }
            this.addBankAccountHandle = Object.assign({}, DEFAULT_BANK_ACCOUNT);
          }
        });
      }
    }
  } // end onUpdateClick()

  closeModal(event: string) {
    // this.loadingService.showOverlay();
    this.showAddEditBankAccount = false;
    // setTimeout(() => {
    //   this.getBankAccounts();
    // }, 2500);
    // this.getBankAccounts();
  } // end closeModal()

  onSave(bank: BankAccounts) {
    this.addBankAccountHandle = bank;
    if (bank.SPAccType === 'Primary') {
      this.showErrorMessagePrompt = true;
    } else {
      this.loadingService.showOverlay();
      if (bank.Id === 0) {

        // Log activity Login
        const currentDate = new Date();
        this.activityLog.Action = 'Add Primary Bank Account';
        this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (manage-bank-account)';
        if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'BankAccount';
        this.activityLog.JsonData = JSON.stringify(bank);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

       this.bankAccountsService.addBankAccount(bank).subscribe({ next: (addedBank) => {
          // On next
        },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          setTimeout(() => {
            this.getBankAccounts();
          }, 2500);
          this.loadingService.hideOverlay();
        }
      });
      } else {
        if (bank.Id > 0) {

          // Log activity Login
          const currentDate = new Date();
          this.activityLog.Action = 'Add Bank Account';
          this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
          this.activityLog.LoggedApp = 'Web Application (manage-bank-account)';
          if (this.userProfileService.userProfile.isImpersonator) {
            this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
          } else {
            this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
          }
          this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
          this.activityLog.ActionTable = 'BankAccount';
          this.activityLog.JsonData = JSON.stringify(bank);
          this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

         this.bankAccountsService.updateBankAccount(bank).subscribe({ next: (bank_to_update) => {
            // On next
          },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
          },
          complete: () => {
            // On complete
            // location.reload();
            // if (bank.SPAccType === 'Primary') {
            //   this.getProfile();
            // }
            setTimeout(() => {
              this.getBankAccounts();
            }, 2500);
            this.loadingService.hideOverlay();
          }
        });
        }
      }
    }
  } // end closeModal()

  // getProfile() {
  //   this.userProfileService.getUserProfile().subscribe({ next:
  //     (profile: UserProfile) => {
  //       if (profile.isImpersonator === true) {
  //         this.api.serviceProviderID = profile.impersonates[0].serviceProviderID;
  //         const advocateUser = profile.impersonates[0];

  //         const fullName = profile.personalDetails && profile.personalDetails.fullName
  //           ? profile.personalDetails.fullName.replace('Advocate', '').split(' ')
  //           : [];
  //         const advfullName = advocateUser.personalDetails && advocateUser.personalDetails.fullName
  //           ? advocateUser.personalDetails.fullName.replace('Advocate', '').split(' ')
  //           : [];

  //         if (fullName.length) {
  //           // if (fullName.length === 2) {
  //           profile.personalDetails.firstName = fullName[0];
  //           profile.personalDetails.lastName = profile.personalDetails.fullName.replace(fullName[0], '');
  //           // } else if (fullName.length > 2) {
  //           //   profile.personalDetails.title = fullName[0];
  //           //   profile.personalDetails.firstName = fullName[1];
  //           //   profile.personalDetails.lastName = fullName[fullName.length - 1];
  //           // }
  //         }
  //         if (advfullName.length) {
  //           // if (advfullName.length === 2) {
  //           advocateUser.personalDetails.firstName = advfullName[0];
  //           advocateUser.personalDetails.lastName = profile.personalDetails.fullName.replace(fullName[0], '');
  //           // } else if (advfullName.length > 2) {
  //           //   advocateUser.personalDetails.title = advfullName[0];
  //           //   advocateUser.personalDetails.firstName = advfullName[1];
  //           //   advocateUser.personalDetails.lastName = advfullName[advfullName.length - 1];
  //           // }
  //         }
  //         sessionStorage.setItem('advocateUserProfil', JSON.stringify(advocateUser));
  //         sessionStorage.setItem('userProfile', JSON.stringify(profile));
  //         this.userProfileService.userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
  //         this.userProfileService.selectedUserProfile = advocateUser;

  //       } else {
  //         this.api.serviceProviderID = profile.serviceProviderID;
  //         // profile.profileType = 'Lawfirm';
  //         const fullName = profile.personalDetails && profile.personalDetails.fullName
  //           ? profile.personalDetails.fullName.split(' ')
  //           : [];
  //         if (fullName.length) {
  //           if (fullName.length === 2) {
  //             profile.personalDetails.firstName = fullName[0];
  //             profile.personalDetails.lastName = fullName[1];
  //           } else if (fullName.length > 2) {
  //             profile.personalDetails.title = fullName[0];
  //             profile.personalDetails.firstName = fullName[1];
  //             profile.personalDetails.lastName = fullName[fullName.length - 1];
  //           }
  //         }
  //         sessionStorage.setItem('userProfile', JSON.stringify(profile));
  //         this.userProfileService.userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
  //       }
  //     },
  //     error: (error) => {
  //       const msg = 'Error loading user profile.';
  //       console.error(msg, error);
  //       // this.router.navigate([PATH_SIGN_OUT]);
  //     },
  //     complete: () => {
  //       this.profile = this.userProfileService.userProfile;
  //       this.selectedAdvocate = this.userProfileService.selectedUserProfile;
  //       location.reload();
  //     }
  //   });
  // } // end getProfile()
  onHideErrorMessagePrompt() {
    this.showErrorMessagePrompt = false;
    this.showAddEditBankAccount = true;
    this.selectedBankAccountHandle = this.addBankAccountHandle;
    // this.getBankAccounts();
  } // end onHidePrimaryBankErrorPrompt()

  closeModalProfile() {
    this.showEditProfileModal = false;
  } // end closeModal()

  /**
  * Called when the user requests for user profile to be updated.
  * @param {UserProfile} event The triggering event.
  */
  onSaveUserProfile(userProfile: UserProfile) {
    const snack: Snack = {
      label: 'Saving user details...',
      action: null
    };

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Update user profile Bank Account (and profile)';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (manage-bank-account)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'BankAccount';
    this.activityLog.JsonData = JSON.stringify(userProfile);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

    this.snackbarsService.make(snack, 5000).show();
    if (this.userProfileService.userProfile.isImpersonator) {
      this.userProfileService
        .updateUserProfile(userProfile).subscribe({ next: 
          (profile) => {
            // this.getProfile();
            // On next: returns empty body in this case
            // this.userProfileService.userProfile.impersonates. = userProfile;
            // this.profile = userProfile;
            // sessionStorage.setItem('userProfile', JSON.stringify(userProfile));
          },
          error: (error) => {
            const msg = 'Error updating user details.';
            console.error(msg, error);
            snack.label = msg;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack, 5000).show();
          },
          complete: () => {
            // On complete
            snack.label = 'User details saved.';
            this.snackbarsService.dismiss().make(snack).show();
            location.reload();
          }
        });
    } else {
    this.userProfileService
      .updateUserProfile(userProfile).subscribe({ next: 
        (profile) => {
          // On next: returns empty body in this case
          this.userProfileService.userProfile = userProfile;
          this.profile = userProfile;
          sessionStorage.setItem('userProfile', JSON.stringify(userProfile));
        },
        error: (error) => {
          const msg = 'Error updating user details.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
        },
        complete: () => {
          // On complete
          snack.label = 'User details saved.';
          this.snackbarsService.dismiss().make(snack).show();
          location.reload();
        }
      });
    }
  } // end onSaveUserProfile()

  toggleSort(sortBy: string) {
    this.sortBank = false;
    this.sortAccountType = false;
    this.sortAccountNumber = false;
    this.sortBranchCode = false;
    this.sortPrimary = false;

    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch (this.sortBy) {
      case 'bank':
        this.sortBank = true;
        this.sortAccountType = false;
        this.sortAccountNumber = false;
        this.sortBranchCode = false;
        this.sortPrimary = false;
        this.bankAccountHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.Bank.toUpperCase().localeCompare(b.data.Bank.toUpperCase());
          }
          return b.data.Bank.toUpperCase().localeCompare(a.data.Bank.toUpperCase());
        });
      break;

      case 'account-type':
        this.sortBank = false;
        this.sortAccountType = true;
        this.sortAccountNumber = false;
        this.sortBranchCode = false;
        this.sortPrimary = false;
        this.bankAccountHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.AccountType.toUpperCase().localeCompare(b.data.AccountType.toUpperCase());
          }
          return b.data.AccountType.toUpperCase().localeCompare(a.data.AccountType.toUpperCase());
        });
      break;

      case 'account-number':
        this.sortBank = false;
        this.sortAccountType = false;
        this.sortAccountNumber = true;
        this.sortBranchCode = false;
        this.sortPrimary = false;
        this.bankAccountHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.AccountNumber.toUpperCase().localeCompare(b.data.AccountNumber.toUpperCase());
          }
          return b.data.AccountNumber.toUpperCase().localeCompare(a.data.AccountNumber.toUpperCase());
        });
      break;

      case 'branch-code':
        this.sortBank = false;
        this.sortAccountType = false;
        this.sortAccountNumber = false;
        this.sortBranchCode = true;
        this.sortPrimary = false;
        this.bankAccountHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.BranchCode.toUpperCase().localeCompare(b.data.BranchCode.toUpperCase());
          }
          return b.data.BranchCode.toUpperCase().localeCompare(a.data.BranchCode.toUpperCase());
        });
      break;

      case 'primary':
        this.sortBank = false;
        this.sortAccountType = false;
        this.sortAccountNumber = false;
        this.sortBranchCode = false;
        this.sortPrimary = true;
        this.bankAccountHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.SPAccType.toUpperCase().localeCompare(b.data.SPAccType.toUpperCase());
          }
          return b.data.SPAccType.toUpperCase().localeCompare(a.data.SPAccType.toUpperCase());
        });
      break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

} // end ManageBankAccountsComponent{}
