import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

import { InvoiceDetails } from '../../../services/invoice-details/invoice-details.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import {
  CreditNote, DEFAULT_CREDIT_NOTE, CreditNoteLines, DEFAULT_CREDIT_NOTE_LINES, ProcessPaymentService
} from '../../../services/process-payment/process-payment.service';
import * as moment from 'moment';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { firstValueFrom } from 'rxjs';

const TITLE_ADD_CREDIT = 'Add Credit Note to Invoice';

@Component({
  selector: 'app-add-credit-modal',
  templateUrl: './add-credit-modal.component.html',
  styleUrls: ['./add-credit-modal.component.scss']
})
export class AddCreditModalComponent implements OnInit {

  title = TITLE_ADD_CREDIT;

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Input() invoiceId: number;
  @Input() invoiceDetails: InvoiceDetails;
  date = new Date();
  addCredit: any = {
    Notes: '',
    Date: moment().format('YYYY-MM-DD'),
    Amount: 0,
    AmountExcl: 0,
    VAT: 0
  };

  btnText = 'ADD CREDIT';
  showErrorMessagePrompt = false;
  vatNumber: number;
  vatPercentage: number;

  // On Save Credit Note Prompt
  showAddCreditNotePrompt: boolean;
  sendCreditNoteMessage: string;

  toCreditNote: CreditNote = Object.assign({}, DEFAULT_CREDIT_NOTE);
  toCreditNoteLines: CreditNoteLines = Object.assign({}, DEFAULT_CREDIT_NOTE_LINES);


  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private userProfileService: UserProfileService,
    private cpipe: UsercurrencyPipe,
    private loadingService: LoadingService,
    private processPaymentService: ProcessPaymentService
  ) { }

  ngOnInit() {
    // Check if user is an assistant or not
    this.addCredit.Amount = Number(this.invoiceDetails.InvoiceTotal) - Number(this.invoiceDetails.TotalPaidAmount);
    if (this.userProfileService.userProfile.isImpersonator) {
      if (this.userProfileService.selectedUserProfile.personalDetails.vatNumber) {
        this.vatNumber = +this.userProfileService.selectedUserProfile.personalDetails.vatNumber;
      } else {
        this.vatNumber = 0;
      }
    } else {
      if (this.userProfileService.userProfile.personalDetails.vatNumber) {
        this.vatNumber = +this.userProfileService.userProfile.personalDetails.vatNumber;
      } else {
        this.vatNumber = 0;
      }
    }

    // check if 14% or 15% vat
    const date = new Date('2018-04-01');
    if (moment(this.invoiceDetails.InvoiceDate) > moment(date)) {
      this.vatPercentage = 1.15;
    } else {
      this.vatPercentage = 1.14;
    }
    this.inclVat();
  }

  dismiss() {
    this.closeModal.emit('add-credit');
  } // end dismiss()

  onHideAddCreditNotePrompt() {
    this.showAddCreditNotePrompt = false;
    // this.closeModal.emit('add-credit');
  } // end onHideAddCreditNotePrompt()

  onShowAddCreditNote() {
    this.sendCreditNoteMessage = 'You are about to create and send a credit note on ' + this.invoiceDetails.InvoiceNo +
    '. Do you wish to proceed?';
    this.showAddCreditNotePrompt = true;
  } // end onShowAddCreditNote()

  async onAddCredit() {
    this.loadingService.showOverlay();
    this.toCreditNote = Object.assign({}, DEFAULT_CREDIT_NOTE);
    // CreditNoteLines
    this.toCreditNoteLines = Object.assign({}, DEFAULT_CREDIT_NOTE_LINES);
    this.toCreditNoteLines.Description = 'Credit Note for ' + this.invoiceDetails.InvoiceNo;
    this.toCreditNoteLines.LineType = 'Credit Note';
    this.toCreditNoteLines.Total = this.addCredit.Amount;
    // CreditNote
    this.toCreditNote.CreditNoteDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
    this.toCreditNote.OriginalInvoiceNo = this.invoiceDetails.InvoiceNo;
    this.toCreditNote.Notes = this.addCredit.Notes;
    this.toCreditNote.Total = Number(parseFloat(this.addCredit.Amount).toFixed(2)); // Math.round(Number(this.addCredit.Amount));
    this.toCreditNote.TotalExcl = Number(parseFloat(this.addCredit.AmountExcl).toFixed(2));
    // Math.round(Number(this.addCredit.AmountExcl));
    this.toCreditNote.VAT = Number(parseFloat(this.addCredit.VAT).toFixed(2)); // Math.round(Number(this.addCredit.VAT));
    this.toCreditNote.OriginalInvoice_Service = this.invoiceId;
    this.toCreditNote.CreditNoteLines = [];
    this.toCreditNote.CreditNoteLines.push(this.toCreditNoteLines);

    await firstValueFrom(this.processPaymentService.putCreditNote(this.toCreditNote));
    // setTimeout(() => {
    this.loadingService.hideOverlay();
    this.closeModal.emit('add-credit');
    // }, 500);

  }

  onDateChange(value: Date): void {
    this.addCredit.Date = value;
  }

  isAmount: boolean = true;
  onAmountChange(line: any, event) {
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
    this.inclVat();

    if (!this.addCredit.Amount) {
      this.isAmount = false;
    } else {
      this.isAmount = true;
    }

    if (this.addCredit.Amount > this.invoiceDetails.InvoiceTotal) {
      this.showErrorMessagePrompt = true;
    }
  }

  onAmountExclChange(line: any, event) {
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
    this.exclVat();

    if (!this.addCredit.AmountExcl) {
      this.isAmount = false;
    } else {
      this.isAmount = true;
    }

    if (this.addCredit.AmountExcl > this.invoiceDetails.InvoiceTotalExcl) {
      this.showErrorMessagePrompt = true;
    }
  }

  inclVat() {
    if (this.vatNumber > 0) {
      const amountExc: number = this.addCredit.Amount;
      const vat: number = amountExc - (amountExc / this.vatPercentage);
      this.addCredit.VAT = vat;
      this.addCredit.AmountExcl = Number(amountExc) - Number(vat);
    } else {
      this.addCredit.AmountExcl = this.addCredit.Amount;
    }
  }

  exclVat() {
    if (this.vatNumber > 0) {
      const amountInc: number = this.addCredit.AmountExcl;
      const vat: number = (amountInc * this.vatPercentage) - amountInc;
      this.addCredit.VAT = vat;
      this.addCredit.Amount = Number(amountInc) + Number(vat);
    } else {
      this.addCredit.Amount = this.addCredit.AmountExcl;
    }
  }

  onAmountClicked(line: any, event) {
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  }

  onAmountExclClicked(line: any, event) {
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }

    if (this.addCredit.AmountExcl > this.invoiceDetails.InvoiceTotalExcl) {
      this.showErrorMessagePrompt = true;
    }
  } // end onAmountExclClicked()

  isNotes: boolean = true;
  onCreditNotesBlur() {
    if (!this.addCredit.Notes) {
      this.isNotes = false;
    } else {
      this.isNotes = true;
    }
  }

  isDate: boolean = true;
  onCreditDateBlur() {
    if (!this.addCredit.Date) {
      this.isDate = false;
    } else {
      this.isDate = true;
    }
  }

  onHideErrorMessagePrompt() {
    this.addCredit.Amount = 0;
    this.showErrorMessagePrompt = false;
  }
}
