<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onDismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body">
        <div class="row">
          <div class="form-group">
            <label for="bank-name">Name</label>
            <input 
              *ngIf="workingBankAccountHandle.Id > 0"
              class="form-control"  
              type="text" 
              id="bank-name"
              [(ngModel)]="workingBankAccountHandle.BankName" 
              autocomplete="nocxua">

            <input 
              *ngIf="workingBankAccountHandle.Id === 0"
              class="form-control"
              type="text" 
              id="bank-name"
              [(ngModel)]="workingBankAccountHandle.BankName" 
              autocomplete="nocxua">
          </div>
          <!-- Bank account no -->
          <div class="form-group">
            <label for="bank-account-no">Bank account no.</label>
            <input 
              *ngIf="workingBankAccountHandle.Id > 0"
              class="form-control"  
              type="text" 
              id="bank-account-no"
              [(ngModel)]="workingBankAccountHandle.AccountNumber" 
              autocomplete="nocxua">

            <input 
              *ngIf="workingBankAccountHandle.Id === 0"
              class="form-control"
              type="text" 
              id="bank-account-no"
              [(ngModel)]="workingBankAccountHandle.AccountNumber" 
              autocomplete="nocxua">
          </div>

          <!-- Bank account holder -->
          <div class="form-group">
            <label for="bank-account-holder">Bank account holder</label>
            <input class="form-control" type="text" id="bank-account-holder"
              [(ngModel)]="workingBankAccountHandle.AccountHolder" (click)="$event.target.select()"
              autocomplete="nocxua">
          </div>

          <!-- Branch code -->
          <div class="form-group">
            <label for="branch-code">Branch code</label>
            <input
              *ngIf="workingBankAccountHandle.Id > 0"
              class="form-control" 
              readonly 
              type="number" 
              id="branch-code" 
              [(ngModel)]="workingBankAccountHandle.BranchCode"
              (click)="$event.target.select()">

              <input
              *ngIf="workingBankAccountHandle.Id === 0"
              class="form-control" 
              type="number" 
              id="branch-code" 
              [(ngModel)]="workingBankAccountHandle.BranchCode"
              (click)="$event.target.select()">
          </div>

        </div> <!-- #end .row -->

        <div class="row">

          <!-- Bank name -->
          <div class="form-group">
            <label for="bank-name">Bank name</label>
            <select 
              disabled
              *ngIf="workingBankAccountHandle.Id > 0"
              class="form-control" id="bank-name" [(ngModel)]="workingBankAccountHandle.BankId">
              <option [value]="null">Choose a bank</option>
              <option *ngFor="let bank of banks" [value]="bank.id"
                [selected]="bank.id === workingBankAccountHandle.BankId">
                {{bank.name}}</option>
            </select>

            <select
              *ngIf="workingBankAccountHandle.Id === 0"
              class="form-control" id="bank-name" [(ngModel)]="workingBankAccountHandle.BankId">
              <option [value]="null">Choose a bank</option>
              <option *ngFor="let bank of banks" [value]="bank.id"
                [selected]="bank.id === workingBankAccountHandle.BankId">
                {{bank.name}}</option>
            </select>
          </div>

          <!-- Account type -->
          <div class="form-group">
            <label for="account-type">Account type</label>
            <select 
              disabled
              *ngIf="workingBankAccountHandle.Id > 0"
              class="form-control" id="account-type" [(ngModel)]=" workingBankAccountHandle.AccountType">
              <option [value]="null">Choose an account type</option>
              <option *ngFor="let type of accountTypes" [value]="type.value"
                [selected]="type.value ===  workingBankAccountHandle.AccountType">
                {{type.label}}</option>
            </select>

            <select              
              *ngIf="workingBankAccountHandle.Id === 0"
              class="form-control" id="account-type" [(ngModel)]=" workingBankAccountHandle.AccountType">
              <option [value]="null">Choose an account type</option>
              <option *ngFor="let type of accountTypes" [value]="type.value"
                [selected]="type.value ===  workingBankAccountHandle.AccountType">
                {{type.label}}</option>
            </select>
          </div>

          <!-- Account profile type -->
          <div class="form-group">
            <label for="account-type">Profile bank account type</label>
            <select *ngIf="workingBankAccountHandle.SPAccType === 'Primary'" disabled title="Please change seconday account to Primary!" class="form-control" id="account-type" [(ngModel)]=" workingBankAccountHandle.SPAccType">
              <option [value]="null">Choose an account type</option>
              <option *ngFor="let type of bankProfileType" [value]="type.value"
                [selected]="type.value ===  workingBankAccountHandle.SPAccType">
                {{type.label}}</option>
            </select>

            <select *ngIf="workingBankAccountHandle.SPAccType != 'Primary'" class="form-control" id="account-type" [(ngModel)]=" workingBankAccountHandle.SPAccType">
              <option [value]="null">Choose an account type</option>
              <option *ngFor="let type of bankProfileType" [value]="type.value"
                [selected]="type.value ===  workingBankAccountHandle.SPAccType">
                {{type.label}}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="account-type">Has access</label>
            <div class="practice-manager-access">
              <i class="fa fa-toggle-on active" id="practice-manager-access"
                *ngIf="workingBankAccountHandle.PracticeManagerAccess" (click)="changeStatus()"></i>
              <i class="fa fa-toggle-on fa-rotate-180 inactive" id="practice-manager-access"
                *ngIf="!workingBankAccountHandle.PracticeManagerAccess" (click)="changeStatus()"></i>
            </div>
          </div>
        </div>
        <!--#end .row -->
        <div class="row">
          <!-- Practice Manager has access to account -->
          <div class="form-group">
            <label for="advocate-preference-date">Satement Reminder Day</label>
            <select *ngIf="workingBankAccountHandle.Id > 0" disabled class="form-control" id="advocate-preference-date"
              [(ngModel)]="workingBankAccountHandle.StatementReminderDay">
              <option [value]="null">Remove Preference Date</option>
              <option *ngFor="let date of preferrenceDate" [value]="date.value"
                [selected]="date.value === workingBankAccountHandle.StatementReminderDay">
                {{date.value}}</option>
            </select>

            <select *ngIf="workingBankAccountHandle.Id === 0" class="form-control" id="advocate-preference-date"
              [(ngModel)]="workingBankAccountHandle.StatementReminderDay">
              <option [value]="null">Remove Preference Date</option>
              <option *ngFor="let date of preferrenceDate" [value]="date.value"
                [selected]="date.value === workingBankAccountHandle.StatementReminderDay">
                {{date.value}}</option>
            </select>
            <!-- <input type="text" id="advocate-preference-date" class="input"
              [(ngModel)]="workingBankAccountHandle.StatementReminderDay == 0 ? '' : workingBankAccountHandle.StatementReminderDay"> -->
          </div>
          <div class="form-group"></div>
          <div class="form-group"></div>
          <div class="form-group">
            <label for="account-type">Active</label>
            <div class="practice-manager-access">
              <i class="fa fa-toggle-on active" id="practice-manager-access"
                *ngIf="workingBankAccountHandle.Enabled" (click)="activateStatus()"></i>
              <i class="fa fa-toggle-on fa-rotate-180 inactive" id="practice-manager-access"
                *ngIf="!workingBankAccountHandle.Enabled" (click)="activateStatus()"></i>
            </div>
          </div>
        </div>
      </div> <!-- #end .modal-body -->

      <div class="modal-footer">
        <button class="btn btn-danger" (click)="onShowUnSavedChangesPrompt()">Cancel</button>
        <button class="btn btn-success" (click)="onSave()">{{addEditButtonText}}</button>
      </div>
      <!--#end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->

<app-prompt-unsaved-changes-modal message="Are you sure you want to leave this screen? You have unsaved changes."
  [isVisible]="showUnsavedChangesPrompt" (cancel)="onHideUnsavedChangesPrompt()" (continue)="dismiss()"
  (confirm)="onSave()"></app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal 
  *ngIf="showDeactivatePrimaryAccountPrompt"
  title="Deactivate Bank Account"
  message="Please take note that you are not able to deactivate a primary account. Please change the status to secondary in order to deactivate the selected account."
  [isVisible]="showDeactivatePrimaryAccountPrompt"
  (cancel)="onHideDeactivatePrimaryAccountPrompt()"
  (continue)="onHideDeactivatePrimaryAccountPrompt()"
  btnTextCancel="Cancel"
  btnTextContinue="OK"></app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal 
  *ngIf="showDeactivatePrimaryOnlyAccountPrompt"
  title="Deactivate Bank Account"
  message="Please take note that there should be one or more accounts active on your profile. Please add another account in order to deactivate the selected account."
  [isVisible]="showDeactivatePrimaryOnlyAccountPrompt"
  (cancel)="onHideDeactivatePrimaryOnlyAccountPrompt()"
  (continue)="onHideDeactivatePrimaryOnlyAccountPrompt()"
  btnTextCancel="Cancel"
  btnTextContinue="OK"></app-prompt-unsaved-changes-modal>

<app-alert title="INVALID FIELDS" message="Invalid Statement Reminder Day,Please Mention Month Date Between 1 to 31."
  [isVisible]="showUnInvalidRemiderDatePrompt" (cancel)="onHideshowUnInvalidRemiderDatePromptPrompt()"
  (confirm)="onHideshowUnInvalidRemiderDatePromptPrompt()"></app-alert>