<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onShowUnsavedChangesPrompt()">
          <i class="fa fa-close"></i>
        </button>
      </div>
      <!--#end .modal-header -->

      <div class="modal-body form">
        <div class="table-body">
        <table style="width: 100%;">
          <tbody>
            <tr>
              <th>Recipients</th>
            </tr>
            <tr *ngFor="let email of workingSendMessageRecipient">
              <td>
                <div class="badge">
                  <input
                    style="width: 478px;"
                    type="text"
                    class="form-control"
                    [class.has-error]="hasContactEmailError(email)"
                    placeholder="Enter recipients"
                    id="recipients"
                    [(ngModel)]="email.Email" autofocus>
                    <i title="delete an email" (click)="emailsToDelete(email)" class="fa fa-minus-circle fa-lg"></i>
                  </div>
                  <span *ngIf="hasContactEmailError(email)" style="font-size: 12px;" class="text-danger">Please enter a valid email address.</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td><span class="column-add-another-email">
                <span class="clickable" style="font-size: 12px;" (click)="addAnotherEmail()">
                  <i class="fa fa-plus-circle auxcon-green"></i>
                  <u class="auxcon-green">add another email</u>
                </span>
              </span></td>
            </tr>
          </tfoot>
        </table>
        </div> <!-- end .table-body-->
        <!-- <div class="row"> -->
          <!-- <div class="form-group"> -->
            <!-- <label for="recipients">Recipients:</label> -->
            <!-- <input type="text" class="form-control" [class.has-error]="hasContactEmailError"
              placeholder="Enter recipients" id="recipients" [(ngModel)]="this.workingSendStatement.EmailAddresses" autofocus>
          </div> -->
          <!--#end .form-group -->
        <!-- </div> -->
        <!--#end .row -->
      </div>
      <!--#end .modal-body -->
      <div class="modal-footer">
        <button class="btn btn-danger" (click)="onShowUnsavedChangesPrompt()">Cancel</button>
        <button class="btn btn-success"
          [class.disabled]="!isSaveable"
          [disabled]="!isSaveable"
          (click)="save()">Add/Update Email</button>
      </div>
      <!--#end .modal-footer -->
    </div>
    <!--#end .modal-dialog -->
  </div>
  <!--#end .modal-content -->
</div>
<!--#end .modal -->

<app-prompt-unsaved-changes-modal
  message="Are you sure you want to leave this screen?"
  [isVisible]="showUnsavedChangesPrompt" 
  *ngIf="showUnsavedChangesPrompt"
  (cancel)="onHideUnsavedChangesPrompt()" 
  (continue)="dismiss()"
  (confirm)="save()"></app-prompt-unsaved-changes-modal>

  <app-alert
    title="Email address required"
    message="At least one valid email address is required."
    [isVisible]="showDeleteEmailModelPrompt"
    *ngIf="showDeleteEmailModelPrompt"
    (cancel)="onHideDeleteEmailModelPrompt()"></app-alert>