import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UtilitiesService } from '../../../services/utilities.service';
import {
  SendStatement,
  DEFAULT_SEND_STATEMENT,
  SendMessageRecipients,
  ProcessPaymentService,
  DEFAULT_SEND_MESSAGE_RECIPIENTS } from '../../../services/process-payment/process-payment.service';
import { TAG_ADD_EMAIL_ADDRESS_MODAL } from '../../../services/tags/tags.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { forkJoin } from 'rxjs';

const TITLE_ENTER_RECIPIENTS = 'Add or edit email';

@Component({
  selector: 'app-add-statement-recipient',
  templateUrl: './add-statement-recipient.component.html',
  styleUrls: ['./add-statement-recipient.component.scss']
})
export class AddStatementRecipientComponent implements OnInit, OnChanges {

  title: string = TITLE_ENTER_RECIPIENTS;
  workingSendStatement: SendStatement;
  workingSendMessageRecipient: SendMessageRecipients[];
  emailToBeDeletedRecipient: SendMessageRecipients[] = [];
  showUnsavedChangesPrompt: boolean;
  showDeleteEmailModelPrompt: boolean;

  @Input() isVisible: boolean;
  @Input() _title: string;
  // @Input() sendStatement: SendStatement;
  @Input() sendMessageRecipient: SendMessageRecipients[];
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() saveRecipients: EventEmitter<any> = new EventEmitter();
  @Output() updateEmails: EventEmitter<any> = new EventEmitter();

   hasContactEmailError(emails: any): boolean {
    return !this.util.areValidEmails(emails.Email);
  } // end hasContactEmailError()

  get isSaveable(): Boolean {
    return !Boolean(this.workingSendMessageRecipient.filter(rec => this.util.isValidEmail(rec.Email) === false).length > 0);
  } // end isSaveable()

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.workingSendMessageRecipient, this.sendMessageRecipient);
  }

  constructor(
    private util: UtilitiesService,
    private processPaymentService: ProcessPaymentService,
    private loadingService: LoadingService
  ) {
    // this.reset();
  } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  onHideDeleteEmailModelPrompt() {
    this.showDeleteEmailModelPrompt = false;
  } // end onHideDeleteEmailModelPrompt()

  ngOnChanges(changes: SimpleChanges) {

    if (changes.isVisible && changes.isVisible.currentValue) {
      this.workingSendMessageRecipient = this.util.objectCopy(this.sendMessageRecipient);
    }
  } // end ngOnChanges()

  private reset() {
    // this.sendStatement = Object.assign({}, DEFAULT_SEND_STATEMENT);
    this.workingSendStatement = Object.assign({}, DEFAULT_SEND_STATEMENT);
  }

  dismiss() {
    // this.reset();
    this.emailToBeDeletedRecipient = [];
    this.showUnsavedChangesPrompt = false;
    this.closeModal.emit(TAG_ADD_EMAIL_ADDRESS_MODAL);
  } // end dismiss()

  onShowUnsavedChangesPrompt() {
    if (this.isDirty) {
      this.showUnsavedChangesPrompt = true;
    } else {
      this.dismiss();
    }
  } // end onShowUnsavedChangesPrompt()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onShowUnsavedChangesPrompt()

//  save() {
//    this.loadingService.showOverlay();
//       for (let email of this.workingSendMessageRecipient) {
//       this.processPaymentService.putRecipient(email).subscribe({ next: (data) => {
//         // On next
//       }});
//       }
//       for (let toDelete of this.emailToBeDeletedRecipient) {
//         this.onDeleteEmail(toDelete);
//       }

//    this.updateEmails.emit();
//    this.emailToBeDeletedRecipient = [];
//     this.dismiss();
//   } // end save()

save() {
  this.loadingService.showOverlay();

  const requests = this.workingSendMessageRecipient.map(email =>
    this.processPaymentService.putRecipient(email)
  );

  forkJoin(requests).subscribe({
    next: (data) => {
      // Code inside this block will be executed after all requests are completed
      for (let toDelete of this.emailToBeDeletedRecipient) {
        this.onDeleteEmail(toDelete);
      }

      this.updateEmails.emit();
      this.emailToBeDeletedRecipient = [];
      this.dismiss();
    },
    error: (error) => {
      // Handle error if needed
    }
  });
} // end save()

  emailsToDelete(email: SendMessageRecipients) {
    if (email.ID === 0) {
      this.workingSendMessageRecipient = this.workingSendMessageRecipient.filter(em => em !== email);
    } else {
      if (this.workingSendMessageRecipient.filter(rec => rec.ID > 0).length === 1) {
        this.showDeleteEmailModelPrompt = true;
        } else {
          this.workingSendMessageRecipient = this.workingSendMessageRecipient.filter(rec => rec !== email);
          this.emailToBeDeletedRecipient.push(email);
      }
    }
  } // end emailsToDelete()

  onDeleteEmail(email: SendMessageRecipients) {
        // this.loadingService.showOverlay();
        // this.workingSendMessageRecipient = this.workingSendMessageRecipient.filter(em => {
        //   return !this.util.objectIsSame(email, em);
        // });

        if (email.ID) {
          this.processPaymentService.deleteRecipient(email.ID).subscribe({ next: (data) => {
            // On next
          },
          error: (error) => {
            // this.loadingService.hideOverlay();
          },
          complete: () => {
            // this.updateEmails.emit();
            // this.sendMessageRecipient = this.util.objectCopy(this.workingSendMessageRecipient);
            // this.loadingService.hideOverlay();
          }
        });
        }
  } // end onDeleteEmail()

 addAnotherEmail() {
    const recipient = Object.assign({}, DEFAULT_SEND_MESSAGE_RECIPIENTS);
    recipient.MessageID = this.workingSendMessageRecipient.filter(m_id => m_id)[0].MessageID;
    recipient.Status = 'New';
    recipient.DateDelivered = this.workingSendMessageRecipient.filter(m_id => m_id)[0].DateDelivered;
    this.workingSendMessageRecipient.push(recipient);
  } // end addAnotherEmail()

} // end AddStatementRecipientComponent{}
