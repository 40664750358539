import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserProfileService } from '../../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-practice-age-analysis-tooltip',
  templateUrl: './practice-age-analysis-tooltip.component.html',
  styleUrls: ['./practice-age-analysis-tooltip.component.scss']
})
export class PracticeAgeAnalysisTooltipComponent implements OnInit {

  tooltipData: any = [];
  @Input() _tooltipData: any;
  @Input() age: string;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onToolTipClick = new EventEmitter<string>();

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()
  constructor(
    private userProfileService: UserProfileService
  ) { } // end constructor()

  ngOnInit() {
    this.tooltipData = this._tooltipData;
  } // end ngInit()

  getBottomRow(index: number, length: number): string {
    if (length > 0 && index === length - 1) {
      return '20px';
    }
    return '0px';
  } // end getBottomRow()
} // end PracticeAgeAnalysisTooltipComponent{}
