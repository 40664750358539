<div class="panel-area">
  <div class="row">
    <div class="form-group">
      <label for="telephone-number">Telephone number</label>
      <input 
        *ngIf="!internalAssistant"
        type="text" 
        class="input" 
        id="telephone-number" 
        placeholder="Telephone number"
        [(ngModel)]="workingOverviewContactDetails.Phone">
      <input 
        *ngIf="internalAssistant"
        readonly
        type="text" 
        class="input" 
        id="telephone-number" 
        placeholder="Telephone number"
        [(ngModel)]="workingOverviewContactDetails.Phone">
    </div>
    <div class="form-group">
      <label for="advocate-cell-number">Cell number<span title="Required field"> *</span></label>
      <input
        *ngIf="!internalAssistant"
        type="text" 
        id="advocate-cell-number" 
        class="input" 
        placeholder="Cell number"
        [(ngModel)]="workingOverviewContactDetails.CellPhone"
        [ngClass]="{'invalid-input': !workingOverviewContactDetails.CellPhone}">
      <input
        *ngIf="internalAssistant"
        readonly
        type="text" 
        id="advocate-cell-number" 
        class="input" 
        placeholder="Advocate Cell number"
        [(ngModel)]="workingOverviewContactDetails.CellPhone"
        [ngClass]="{'invalid-input': !workingOverviewContactDetails.CellPhone}">
    </div>    
    <div class="form-group">
      <label for="facimile number">Facimile number</label>
      <input 
        *ngIf="!internalAssistant"
        type="text" 
        id="facimile number" 
        class="input" 
        placeholder="Facimile number"
        [(ngModel)]="workingOverviewContactDetails.Fax">
      <input 
        *ngIf="internalAssistant"
        readonly
        type="text" 
        id="facimile number" 
        class="input" 
        placeholder="Facimile number"
        [(ngModel)]="workingOverviewContactDetails.Fax">
    </div>
    <div class="form-group">
      <label for="email-address">Email address<span title="Required field"> *</span></label>
      <input 
        *ngIf="!internalAssistant"
        type="text" 
        class="input" 
        id="email-address" 
        placeholder="Email address"
        [(ngModel)]="workingOverviewContactDetails.Email"
        [ngClass]="{'invalid-input': !workingOverviewContactDetails.Email}">
      <input 
        *ngIf="internalAssistant"
        readonly
        type="text" 
        class="input" 
        id="email-address" 
        placeholder="Email address"
        [(ngModel)]="workingOverviewContactDetails.Email"
        [ngClass]="{'invalid-input': !workingOverviewContactDetails.Email}">
    </div>
  </div>
  <div class="row">
    <div class="form-group" style="display: block;">
      <label for="bar-society">Bar Society<span title="Required field"> *</span></label>
        <app-datalist-chambers
          *ngIf="!internalAssistant"
          type="bar-society"
          [barSociety]="barSociety"
          [(advocateChambers)]="workingAdvocateChambers"
          (select)="onModelChange('bar-society', $event)"></app-datalist-chambers>
        <input
          *ngIf="internalAssistant" 
          readonly 
          type="text" 
          class="input" 
          id="practice-type"
          [(ngModel)]="workingAdvocateChambers && workingAdvocateChambers.BarSociety"
          [ngClass]="{'invalid-input': !workingAdvocateChambers.BarSociety}">
    </div>
    <div class="form-group" style="display: block;">
      <label for="bar-society">Chambers<span title="Required field"> *</span></label>
        <app-datalist-chambers
          type="chambers" 
          *ngIf="!internalAssistant"
          [barSociety]="barSociety" 
          [(advocateChambers)]="workingAdvocateChambers && workingAdvocateChambers"
          [chambers]="chambers"
          (select)="onModelChange('chambers', $event)"></app-datalist-chambers>
        <input
          *ngIf="internalAssistant"
          readonly 
          type="text" 
          class="input" 
          id="practice-type"
          [(ngModel)]="workingAdvocateChambers && workingAdvocateChambers.Chamber">
    </div>
    <div class="form-group">
      <label for="chambers-address">Chambers Address<span title="Required field"> *</span></label>
      <textarea
        *ngIf="!internalAssistant"
        type="text"
        id="chambers-address"
        class="address-form"
        placeholder="Chambers Address"
        [(ngModel)]="workingAdvocateChambers && workingAdvocateChambers.Address"></textarea>
      <textarea
        *ngIf="internalAssistant"
        readonly
        type="text"
        id="chambers-address"
        class="address-form"
        placeholder="Chambers Address"
        [(ngModel)]="workingAdvocateChambers && workingAdvocateChambers.Address"></textarea>
    </div>
    <div class="form-group">
      <!-- <label for="chambers-address">Room Number</label>
      <input
        *ngIf="!internalAssistant"
        type="text"
        id="chambers-address"
        class="input"
        placeholder="Chambers Room Number"
        [(ngModel)]="workingAdvocateChambers && workingAdvocateChambers.RoomNumber">
      <input
        *ngIf="internalAssistant"
        readonly
        type="text"
        id="chambers-address"
        class="input"
        placeholder="Chambers Room Number"
        [(ngModel)]="workingAdvocateChambers && workingAdvocateChambers.RoomNumber"> -->
    </div>
  </div>
  <div class="row" *ngIf="!internalAssistant && isPracticeManager">
    <div class="form-group">
      <label for="meeting-type-preference">Meeting type preference</label>
        <select
          *ngIf="!internalAssistant"
          id="meeting-type=preference"
          [(ngModel)]="workingOverviewContactDetails.MeetingType">
          <option value="" disabled selected hidden>Select Meeting type preference</option>
          <option
            *ngFor="let meet of meetingType"
            [value]="meet.value"
            [selected]="meet.value === workingOverviewContactDetails.MeetingType">{{meet.label}}</option>
        </select>
        <input
          *ngIf="internalAssistant" 
          readonly 
          type="text" 
          class="input" 
          id="meeting-type=preference" 
          [(ngModel)]="workingOverviewContactDetails.MeetingType">
    </div>
    <div class="form-group">
      <label for="skype-name">Skype name</label>
      <input 
        *ngIf="!internalAssistant"
        type="text" 
        class="input" 
        id="skype-name" 
        placeholder="Skype name"
        [(ngModel)]="workingOverviewContactDetails.MeetingAppName">
      <input 
        *ngIf="internalAssistant"
        readonly
        type="text" 
        class="input" 
        id="skype-name" 
        placeholder="Skype name"
        [(ngModel)]="workingOverviewContactDetails.MeetingAppName">
    </div>
    <div class="form-group">
      <label for="facimile number">Meeting frequency</label>
      <select id="practice-type"
      *ngIf="!internalAssistant"
      [(ngModel)]="workingOverviewContactDetails.MeetingFrequency">
        <option value="" disabled selected hidden>Select Meeting frequency</option>
        <option
          *ngFor="let frequent of meetingFequency"
          [value]="frequent.value"
          [selected]="frequent.value === workingOverviewContactDetails.MeetingFrequency">{{frequent.label}}</option>
      </select>
      <input
        *ngIf="internalAssistant" 
        readonly 
        type="text" 
        class="input" 
        id="practice-type"
        [(ngModel)]="workingOverviewContactDetails.MeetingFrequency">
    </div>
    <div class="form-group">
      <label for="website-address">Website address</label>
      <input
        *ngIf="!internalAssistant"
        type="text" 
        class="input" 
        id="website-address" 
        placeholder="Website address"
        [(ngModel)]="workingOverviewContactDetails.Website">
      <input
        *ngIf="internalAssistant"
        readonly
        type="text" 
        class="input" 
        id="website-address" 
        placeholder="Website address"
        [(ngModel)]="workingOverviewContactDetails.Website">
    </div>
  </div>
</div>

<!-- <button
  *ngIf="!internalAssistant"
  class="btn btn-success save-advocate-details"
  (click)="onContactDetailSave()">SAVE CONTACT DETAILS</button> -->