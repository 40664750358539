import { Pipe, PipeTransform } from '@angular/core';
// import { UserProfileService } from '../services/user-profile/user-profile.service';
// import { connect } from 'tls';
const padding = '000000';

@Pipe({
  name: 'usercurrency'
})
export class UsercurrencyPipe implements PipeTransform {
  // private prefix: string;
  private decimal_separator: string;
  private thousands_separator: string;
  private suffix: string;

  constructor(
    // private userProfileService: UserProfileService
  ) {
    // this.prefix =  this.currencySymbol + ' ';
    this.suffix = '';
    this.decimal_separator = '.';
    this.thousands_separator = ',';
  }

  transform(value: string, prefix: string = '', fractionSize: number = 0): string {

    if (parseFloat(value) % 1 !== 0) {
      fractionSize = 2;
    }
    let [integer, fraction = ''] = (parseFloat(value).toString() || '').toString().split('.');

    fraction = fractionSize > 0
      ? this.decimal_separator + (fraction + padding).substring(0, fractionSize) : '';
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousands_separator);
    if (isNaN(parseFloat(integer))) {
      integer = '0';
    }
    return prefix + ' ' + integer + fraction + this.suffix;

  }

  parse(value: string, prefix: string = '', fractionSize: number = 2): string {
    let [integer, fraction = ''] = (value || '').replace(prefix + ' ', '')
      .replace(this.suffix, '')
      .split(this.decimal_separator);

    integer = integer.replace(new RegExp(this.thousands_separator, 'g'), '');

    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
      ? this.decimal_separator + (fraction + padding).substring(0, fractionSize)
      : '';

    return integer + fraction;
  }

  // get currencySymbol(): string {
  //   let currency_Symbol: string;
  //   if (this.userProfileService.userProfile) {
  //     if (this.userProfileService.userProfile.currencyDetails) {
  //       currency_Symbol = this.userProfileService.userProfile.currencyDetails.symbol;
  //     }
  //   } else {
  //     currency_Symbol = 'R ';
  //   }
  //   return currency_Symbol;
  // } // end currencySymbol()

}
