<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div>
      <!--#end .modal-header -->

      <div class="modal-body">
        <div class="container-fluid">
          <!-- Comments -->
          <div class="row">
            <i class="fal fa-exclamation-triangle"></i>
          </div>
          <div class="message">
            <p>{{fee.Subject}}</p>
            <p>Are you sure you want to delete this fee?</p>
          </div>
        </div>
      </div>
      <!--#end .modal-body -->

      <div class="modal-footer">
        <button
        class="btn btn-danger"
        (click)="onCancel()">Cancel</button>
        <button
          class="btn btn-success"
          (click)="onConfirm()">Confirm</button>
      </div>
      <!--#end .modal-footer -->

    </div>
    <!--#end .modal-dialog -->
  </div>
  <!--#end .modal-content -->
</div>
<!--#end .modal -->