import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import {
  PATH_DASHBOARD,
  PATH_PRACTICE_MANAGER_DASHBOARD,
  PATH_CALENDAR,
  PATH_REPORTS,
  PATH_SEARCH,
  PATH_USER_SINGUP,
  PATH_MESSAGES,
  PATH_MATTERS,
  PATH_INVOICE_EMAIL,
  PATH_INVOICE_REVIEW,
  PATH_EDIT_FEE_TEMPLATES,
  PATH_SIGN_OUT,
  PATH_SIGN_IN,
  PATH_PROCESS_INVOICES,
  PATH_SIGN_IN_LOADING,
  PATH_EDIT_MY_TEAM,
  PATH_ATTORNEY_DASHBOARD,
  PATH_ADVOCATE_DETAIL,
  PATH_ANALYTICS,
  PATH_UNPAID_INVOICES,
  PATH_INVOICE,
  PATH_INVOICE_PREVIEW,
  PATH_ADD_EDIT_CLIENTS,
  PATH_ADVOCATE_INVOICE_PREVIEW,
  PATH_PROCESS,
  PATH_PROOF_OF_PAYMENTS,
  PATH_RECONCILE_BANK_TRANSACTIONS,
  PATH_CUSTOMER_PAYMENT_RATING,
  PATH_INDIVIDUAL_CUSTOMER,
  PATH_CUSTOMER_INVOICE_PREVIEW,
  PATH_REPORT_AGE_ANALYSIS,
  PATH_REPORT_ALL_CUSTOMERS,
  PATH_AGE_ANALYSIS_INDIVIDUAL_CUSTOMER,
  PATH_AGE_ANALYSIS_CUSTOMER_INVOICE_PREVIEW,
  PATH_REPORT_INVOICE_COLLECTED,
  PATH_REPORT_ALLCUSTOMER_REPORT,
  PATH_RINDIVIDUAL_CUSTOMER,
  PATH_RINVOICE_PREVIEW,
  PATH_THE_INDIVIDUAL_CUSTOMER,
  PATH_CREATE_NEW_USER,
  PATH_USER_SAGE_VALIDATION,
  PATH_THE_INVOICE_PREVIEW,
  PATH_SEARCH_INVOICE_PREVIEW,
  PATH_SEND_STATEMENT,
  PATH_MANAGE_BANK_ACCOUNTS,
  PATH_RECONCILE_BANK_TRANSACTIONS_INVOICE,
  PATH_ALL_ADVOCATES,
  PATH_PRACTICE_MANAGER_REPORTS,
  PATH_PRACTICE_ALL_CUSTOMER,
  PATH_ATTORNEY_AGE_ANALYSIS,
  PATH_ATTORNEY_ALL_CUSTOMER,
  PATH_ATTORNEY_PER_CUSTOMER,
  PATH_PRACTICE_MANAGER_MESSAGES,
  PATH_PRACTICE_ADVOCATE_OVERVIEW,
  PATH_QUOTATION,
  PATH_QUOTATION_PREVIEW,
  PATH_CANCELLED_SUBSCRIPTION,
  PATH_QUOTATION_EMAIL,
  PATH_PLATEFORM_BANK_TRANSACTION,
  PATH_AUXCON_ADMIN,
  PATH_CONTACT_MANAGEMENT,
  PATH_COLLECTION_REPORT,
  PATH_REPORT_TAX_LIABILITY,
  PATH_MANAGE_BLACKLIST,
  PATH_GCB_RESPONSE_HANDLER,
  PATH_BLACKLIST_PDF_VIEWER,
  PATH_ONBOARDING_REGISTER,
  PATH_ONBOARDING_VERIFY_USER,
  PATH_SUBSCRIPTION_BILLING,
  PATH_PAYMENT_RESPONSE,
  PATH_SUBSCRIPTION_PROMPT,
  PATH_AD_HOC_COLLECTIONS,
  PATH_OUTLOOK_HANDLER,
  PATH_OUTLOOK_SYNC,
  PATH_ARBITRATION_INVOICES,
  PATH_ONBOARDING_REGISTER_AD_HOC
} from './services/appdata/app-config.service';

import { AdvocateDashboardComponent } from "./pages/advocate-dashboard/advocate-dashboard.component";
import { AdvocateCalendarComponent } from "./pages/advocate-calendar/advocate-calendar.component";
import { AdvocateReportsComponent } from "./pages/advocate-reports/advocate-reports.component";
import { AdvocateSearchComponent } from "./pages/advocate-search/advocate-search.component";
import { AdvocateMessagesComponent } from "./pages/advocate-messages/advocate-messages.component";
import { AdvocateMattersComponent } from "./pages/advocate-matters/advocate-matters.component";
import { AdvocateProcessComponent } from "./pages/advocate-process/advocate-process.component";
import { AdvocateLandingComponent } from './pages/advocate-landing/advocate-landing.component';
import { UserSingupComponent } from './pages/user-singup/user-singup.component';
import { AdvocateInvoiceEmailComponent } from './pages/advocate-invoice-email/advocate-invoice-email.component';
import { AdvocateInvoiceReviewComponent } from './pages/advocate-invoice-review/advocate-invoice-review.component';
import { AdvocateEditFeeTemplatesComponent } from './pages/advocate-edit-fee-templates/advocate-edit-fee-templates.component';
import { AdvocateSignOutComponent } from './pages/advocate-sign-out/advocate-sign-out.component';
import { AdvocateSignInComponent } from './pages/advocate-sign-in/advocate-sign-in.component';
import { AdvocateProcessInvoicesComponent } from './pages/advocate-process-invoices/advocate-process-invoices.component';
import { AuthenticationGuard } from './common/guards/authentication-guard';
import { NavigationGuard } from './common/guards/navigation.guard';
import { CreateNewUserComponent } from "./pages/create-new-user/create-new-user.component";
import { AdvocateSignInLoadingComponent } from './pages/advocate-sign-in-loading/advocate-sign-in-loading.component';
import { AdvocateEditMyTeamComponent } from './pages/advocate-edit-my-team/advocate-edit-my-team.component';
import { AttorneyDashboardComponent } from './pages/attorney-dashboard/attorney-dashboard.component';
import { AdvocateDetailComponent } from './pages/analytics/advocate-detail/advocate-detail.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { UnpaidInvoicesComponent } from './pages/analytics/unpaid-invoices/unpaid-invoices.component';
import { InvoiceComponent } from './pages/analytics/invoice/invoice.component';
import { InvoicePreviewComponent } from './pages/advocate-messages/invoice-preview/invoice-preview.component';
import { AddEditClientsComponent } from './pages/add-edit-clients/add-edit-clients.component';
import { AdvocateInvoicePreviewComponent } from './pages/advocate-invoice-preview/advocate-invoice-preview.component';
import { ManageProofOfPaymentsComponent } from './pages/advocate-process/manage-proof-of-payments/manage-proof-of-payments.component';
import {
  ReconcileBankTransactionsComponent
} from './pages/advocate-process/reconcile-bank-transactions/reconcile-bank-transactions.component';
import { SendBankStatementsComponent } from './pages/advocate-process/send-bank-statements/send-bank-statements.component';

import { ReportPaymentRatingComponent } from './pages/advocate-reports/report-payment-rating/report-payment-rating.component';
import {
  ReportIndividualCustomerComponent
} from './pages/advocate-reports/report-payment-rating/report-individual-customer/report-individual-customer.component';
import {
  ReportCustomerInvoicePreviewComponent
} from './pages/advocate-reports/report-payment-rating/report-individual-customer/report-customer-invoice-preview/report-customer-invoice-preview.component';
import { ReportAgeAnalysisComponent } from './pages/advocate-reports/report-age-analysis/report-age-analysis.component';
import {
  ReportAllCustomersComponent
} from './pages/advocate-reports/report-age-analysis/report-all-customers/report-all-customers.component';
import {
  AgeAnalysisIndividualCustomerComponent
} from './pages/advocate-reports/report-age-analysis/report-all-customers/age-analysis-individual-customer/age-analysis-individual-customer.component';
import {
  AgeAnalysisInvoiceComponent
} from './pages/advocate-reports/report-age-analysis/report-all-customers/age-analysis-individual-customer/age-analysis-invoice/age-analysis-invoice.component';
import { ReportInvoiceCollectedComponent } from './pages/advocate-reports/report-invoice-collected/report-invoice-collected.component';
import { ReportAllcustomerReportComponent } from './pages/advocate-reports/report-allcustomer-report/report-allcustomer-report.component';
import {
  RIndividualCustomerComponent
} from './pages/advocate-reports/report-allcustomer-report/r-individual-customer/r-individual-customer.component';
import {
  RIndividualInvoiceComponent
} from './pages/advocate-reports/report-allcustomer-report/r-individual-customer/r-individual-invoice/r-individual-invoice.component';
import { TheIndividualCustomerComponent } from './pages/advocate-reports/the-individual-customer/the-individual-customer.component';
import {
  TheInvoicePreviewComponent
} from './pages/advocate-reports/the-individual-customer/the-invoice-preview/the-invoice-preview.component';
import { SearchedInvoicePreviewComponent } from './pages/advocate-search/searched-invoice-preview/searched-invoice-preview.component';
import { ManageBankAccountsComponent } from './pages/advocate-process/manage-bank-accounts/manage-bank-accounts.component';
import {
  ReconInvoicePreviewComponent
} from './pages/advocate-process/reconcile-bank-transactions/recon-invoice-preview/recon-invoice-preview.component';
import { AllAdvocatesComponent } from './pages/attorney-dashboard/all-advocates/all-advocates.component';
import { PracticeMDashboardComponent } from './pages/practice-m-dashboard/practice-m-dashboard.component';
import { PracticeManagerReportComponent } from './pages/advocate-reports/practice-manager-report/practice-manager-report.component';
import { PracticeAdvocateDetailComponent } from './pages/advocate-reports/practice-advocate-detail/practice-advocate-detail.component';
import {
  PracticeAllCustomerComponent
} from './pages/advocate-reports/practice-manager-report/practice-all-customer/practice-all-customer.component';
import { AttorneyAgeAnalysisComponent } from './pages/attorney-dashboard/attorney-age-analysis/attorney-age-analysis.component';
import { AttorneyAllCustomerComponent } from './pages/attorney-dashboard/attorney-all-customer/attorney-all-customer.component';
import {
  AttorneyPerCustomerComponent
} from './pages/attorney-dashboard/attorney-all-customer/attorney-per-customer/attorney-per-customer.component';
import { PractiseManagerMessagesComponent } from './pages/practise-manager-messages/practise-manager-messages.component';
import { AdvocateOverviewComponent } from './pages/advocate-overview/advocate-overview.component';
import { AdvocateQuotationComponent } from './pages/advocate-quotation/advocate-quotation.component';
import { AdvocateQuotationPreviewComponent } from './pages/advocate-quotation-preview/advocate-quotation-preview.component';
import { CancelledSubscriptionComponent } from './pages/error/cancelled-subscription/cancelled-subscription.component';
import { AdvocateQuotationEmailComponent } from './pages/advocate-quotation-email/advocate-quotation-email.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ContactManagementComponent } from './pages/admin/contact-management/contact-management.component';
import { PlateformBankTransactionsComponent } from './pages/advocate-process/plateform-bank-transactions/plateform-bank-transactions.component';
import { UserSageValidationComponent } from './pages/user-sage-validation/user-sage-validation.component';
import { CollectionsReportComponent } from './pages/admin/collections-report/collections-report.component';
import { ManageBlackListComponent } from './pages/manage-BlackList/manage-BlackList.component';
import { GCBResponseHandlerComponent } from './pages/gcb-response-handler/gcb-response-handler.component';
import { components } from 'knockout';
import { BlacklistPdfViewerComponent } from "./pages/blacklist-pdf-viewer/blacklist-pdf-viewer.component";
import { AdvocateTaxComponent } from "./pages/advocate-reports/advocate-tax/advocate-tax.component";
import { OnboardingRegisterComponent } from "./components/onboarding/onboarding-register/onboarding-register.component";
import { OnboardingResponseHandlerComponent } from "./components/onboarding/onboarding-response-handler/onboarding-response-handler.component";
import { SubscriptionBillingComponent } from "./components/subscriptions/subscription-billing/subscription-billing.component";
import { PromptSubscriptionComponent } from "./components/modals/prompt-subscription/prompt-subscription.component";
import { PaymentResponseHandlerComponent } from "./components/subscriptions/payment-response-handler/payment-response-handler.component";
import { AdHocInstructionComponent } from "./pages/ad-hoc-instruction/ad-hoc-instruction.component";
import { OutlookHandlerComponent } from "./components/outlook-calendar/outlook-handler/outlook-handler.component"
import { OutlookSyncComponent } from "./components/outlook-sync/outlook-sync.component";
import { AdvocateArbitrationInvoicesComponent } from "./pages/advocate-arbitration-invoices/advocate-arbitration-invoices.component";
import { AdHocRegistrationComponent } from "./components/onboarding/ad-hoc-registration/ad-hoc-registration.component";

const routes: Routes = [
  {
    path: '', redirectTo: PATH_SIGN_IN_LOADING,
    pathMatch: 'full',
    data: { title: 'Auxcon' },
  },
  {
    path: PATH_OUTLOOK_HANDLER, component: OutlookHandlerComponent
  },
  {
    path: PATH_ONBOARDING_REGISTER, component: OnboardingRegisterComponent
  },
  {
    path: PATH_ONBOARDING_REGISTER_AD_HOC, component: AdHocRegistrationComponent
  },
  {
    path: PATH_ONBOARDING_VERIFY_USER + '/:id', component: OnboardingResponseHandlerComponent
  },
  {
    path: PATH_ONBOARDING_VERIFY_USER, component: OnboardingResponseHandlerComponent
  },
  {
    path: PATH_SUBSCRIPTION_BILLING, component: SubscriptionBillingComponent
  },
  {
    path: PATH_SUBSCRIPTION_PROMPT, component: PromptSubscriptionComponent
  },
  {
    path: PATH_PAYMENT_RESPONSE, component: PaymentResponseHandlerComponent
  },
  {
    path: 'ad-hoc-instruction/:id', component: AdHocInstructionComponent
  },
  // {
  //   path: 'ad-hoc-instruction/:id',
  //   component: AdHocInstructionComponent,
  //   canActivate: [AuthenticationGuard, NavigationGuard],
  //   canActivateChild: [AuthenticationGuard],
  //   children: [
  //     {
  //       path: 'ad-hoc-instruction/:id',
  //       component: AdHocInstructionComponent,
  //     },
  //   ],
  //   data: { title: "Ad-Hoc Collection - Auxcon" },
  // },
  // {
  //   path: PATH_SIGN_IN_LOADING,
  //   component: AdvocateSignInLoadingComponent,
  //   data: { title: 'Auxcon' }
  // },
  {
    path: PATH_DASHBOARD,
    component: AdvocateDashboardComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_DASHBOARD,
        component: AdvocateDashboardComponent,
      },
    ],
    data: { title: "Dashboard - Auxcon" },
  },
  {
    path: PATH_CREATE_NEW_USER,
    component: CreateNewUserComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_CREATE_NEW_USER,
        component: CreateNewUserComponent,
      },
    ],
    data: { title: "Create New User - Auxcon" },
  },
  {
    path: PATH_ARBITRATION_INVOICES + "/:id",
    component: AdvocateArbitrationInvoicesComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ARBITRATION_INVOICES + "/:id",
        component: AdvocateArbitrationInvoicesComponent,
      },
    ],
    data: { title: 'Arbitration - Auxcon' }
  },
  {
    path: PATH_PRACTICE_MANAGER_DASHBOARD,
    component: PracticeMDashboardComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_PRACTICE_MANAGER_DASHBOARD,
        component: PracticeMDashboardComponent,
      },
    ],
    data: { title: 'Practice - Auxcon' }
  },
  {
    path: PATH_PRACTICE_ADVOCATE_OVERVIEW,
    component: AdvocateOverviewComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_PRACTICE_ADVOCATE_OVERVIEW,
        component: AdvocateOverviewComponent,
      },
    ],
    data: { title: "Advocate-Overview - Auxcon" },
  },
  {
    path: PATH_PLATEFORM_BANK_TRANSACTION,
    component: PlateformBankTransactionsComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_PLATEFORM_BANK_TRANSACTION,
        component: PlateformBankTransactionsComponent
      },
    ],
    data: { title: 'Platform Bank Transactions - Auxcon' }
  },

  {
    path: PATH_AUXCON_ADMIN,
    component: AdminComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_AUXCON_ADMIN,
        component: AdminComponent
      },
    ],
    data: { title: 'Auxcon-Admin - Auxcon' }
  },
  {
    path: PATH_CONTACT_MANAGEMENT,
    component: ContactManagementComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_CONTACT_MANAGEMENT,
        component: ContactManagementComponent
      },
    ],
    data: { title: 'Contact-Management - Auxcon' }
  },
  {
    path: PATH_COLLECTION_REPORT,
    component: CollectionsReportComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_COLLECTION_REPORT,
        component: CollectionsReportComponent
      },
    ],
    data: { title: 'Contact-Management - Auxcon' }
  },
  {
    path: PATH_PRACTICE_MANAGER_REPORTS,
    component: PracticeManagerReportComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_PRACTICE_MANAGER_REPORTS,
        component: PracticeManagerReportComponent,
      },
    ],
    data: { title: "Practice-report - Auxcon" },
  },
  {
    path: PATH_PRACTICE_ALL_CUSTOMER,
    component: PracticeAllCustomerComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_PRACTICE_ALL_CUSTOMER,
        component: PracticeAllCustomerComponent,
      },
    ],
    data: { title: "Practice-report - Auxcon" },
  },
  {
    path: PATH_ADVOCATE_DETAIL,
    component: PracticeAdvocateDetailComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ADVOCATE_DETAIL,
        component: PracticeAdvocateDetailComponent,
      },
    ],
    data: { title: "Practice - Auxcon" },
  },
  {
    path: PATH_ATTORNEY_PER_CUSTOMER + "/:id/:advocateId",
    component: AttorneyPerCustomerComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ATTORNEY_PER_CUSTOMER,
        component: AttorneyPerCustomerComponent,
      },
    ],
    data: { title: "Report - Auxcon" },
  },
  {
    path: PATH_ALL_ADVOCATES,
    component: AllAdvocatesComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ALL_ADVOCATES,
        component: AllAdvocatesComponent,
      },
    ],
    data: { title: "Live Statement - Auxcon" },
  },
  {
    path: PATH_PROCESS,
    component: AdvocateProcessComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_PROCESS,
        component: AdvocateProcessComponent,
      },
    ],
    data: { title: "Process - Auxcon" },
  },
  {
    path: PATH_PROOF_OF_PAYMENTS,
    component: ManageProofOfPaymentsComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_PROOF_OF_PAYMENTS,
        component: ManageProofOfPaymentsComponent,
      },
    ],
    data: { title: "Proof of payment - Auxcon" },
  },
  {
    path: PATH_RECONCILE_BANK_TRANSACTIONS,
    component: ReconcileBankTransactionsComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_RECONCILE_BANK_TRANSACTIONS,
        component: ReconcileBankTransactionsComponent,
      },
    ],
    data: { title: "Bank transaction - Auxcon" },
  },
  {
    path: PATH_CREATE_NEW_USER,
    component: CreateNewUserComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_CREATE_NEW_USER,
        component: CreateNewUserComponent,
      },
    ],
    data: { title: "Create New User - Auxcon" },
  },
  {
    path: PATH_USER_SAGE_VALIDATION,
    component: UserSageValidationComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_USER_SAGE_VALIDATION,
        component: UserSageValidationComponent,
      },
    ],
    data: { title: "User Is Valid For Sage - Auxcon" },
  },
  {
    path: PATH_OUTLOOK_SYNC,
    component: OutlookSyncComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_OUTLOOK_SYNC,
        component: OutlookSyncComponent,
      },
    ],
    data: { title: "Outlook 365 Sync - Auxcon" },
  },
  {
    path: PATH_SEND_STATEMENT,
    component: SendBankStatementsComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_SEND_STATEMENT,
        component: SendBankStatementsComponent,
      },
    ],
    data: { title: "Send statement - Auxcon" },
  },
  {
    path: PATH_ADD_EDIT_CLIENTS,
    component: AddEditClientsComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ADD_EDIT_CLIENTS,
        component: AddEditClientsComponent,
      },
    ],
    data: { title: "Add or Edit Clients - Auxcon" },
  },
  {
    path: PATH_CALENDAR,
    component: AdvocateCalendarComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_CALENDAR,
        component: AdvocateCalendarComponent,
      },
    ],
    data: { title: "Calendar - Auxcon" },
  },
  {
    path: PATH_MANAGE_BLACKLIST,
    component: ManageBlackListComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_MANAGE_BLACKLIST,
        component: ManageBlackListComponent
      },
    ],
    data: { title: 'Manage BlackList - Auxcon' }
  },
  {
    path: PATH_REPORTS,
    component: AdvocateReportsComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_REPORTS,
        component: AdvocateReportsComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_INDIVIDUAL_CUSTOMER + "/:id",
    component: ReportIndividualCustomerComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_INDIVIDUAL_CUSTOMER,
        component: ReportIndividualCustomerComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_RINDIVIDUAL_CUSTOMER + "/:id",
    component: RIndividualCustomerComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_RINDIVIDUAL_CUSTOMER,
        component: RIndividualCustomerComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_AGE_ANALYSIS_INDIVIDUAL_CUSTOMER + "/:id",
    component: AgeAnalysisIndividualCustomerComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_AGE_ANALYSIS_INDIVIDUAL_CUSTOMER,
        component: AgeAnalysisIndividualCustomerComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_CUSTOMER_PAYMENT_RATING,
    component: ReportPaymentRatingComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_CUSTOMER_PAYMENT_RATING,
        component: ReportPaymentRatingComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_REPORT_INVOICE_COLLECTED,
    component: ReportInvoiceCollectedComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_REPORT_INVOICE_COLLECTED,
        component: ReportInvoiceCollectedComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_REPORT_TAX_LIABILITY,
    component: AdvocateTaxComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_REPORT_TAX_LIABILITY,
        component: AdvocateTaxComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_REPORT_ALLCUSTOMER_REPORT,
    component: ReportAllcustomerReportComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_REPORT_ALLCUSTOMER_REPORT,
        component: ReportAllcustomerReportComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_REPORT_AGE_ANALYSIS,
    component: ReportAgeAnalysisComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_REPORT_AGE_ANALYSIS,
        component: ReportAgeAnalysisComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_REPORT_ALL_CUSTOMERS,
    component: ReportAllCustomersComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_REPORT_ALL_CUSTOMERS,
        component: ReportAllCustomersComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_REPORTS + "/:id",
    component: AdvocateReportsComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_REPORTS,
        component: AdvocateReportsComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_SEARCH,
    component: AdvocateSearchComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_SEARCH,
        component: AdvocateSearchComponent,
      },
    ],
    data: { title: "Search - Auxcon" },
  },
  {
    path: PATH_ANALYTICS,
    component: AnalyticsComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ANALYTICS,
        component: AnalyticsComponent,
      },
    ],
    data: { title: "Analytics - Auxcon" },
  },
  {
    path: PATH_MESSAGES,
    component: AdvocateMessagesComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_MESSAGES,
        component: AdvocateMessagesComponent,
      },
    ],
    data: { title: "Messages - Auxcon" },
  },
  {
    path: PATH_PRACTICE_MANAGER_MESSAGES,
    component: PractiseManagerMessagesComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_PRACTICE_MANAGER_MESSAGES,
        component: PractiseManagerMessagesComponent,
      },
    ],
    data: { title: "Messages - Auxcon" },
  },
  {
    path: PATH_RECONCILE_BANK_TRANSACTIONS_INVOICE + "/:id",
    component: ReconInvoicePreviewComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_RECONCILE_BANK_TRANSACTIONS_INVOICE,
        component: ReconInvoicePreviewComponent,
      },
    ],
    data: { title: "Invoice Preview - Auxcon" },
  },
  {
    path: PATH_INVOICE_PREVIEW + "/:id",
    component: InvoicePreviewComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_INVOICE_PREVIEW,
        component: InvoicePreviewComponent,
      },
    ],
    data: { title: "Invoice Preview - Auxcon" },
  },
  {
    path: PATH_QUOTATION_PREVIEW + "/:id",
    component: AdvocateQuotationPreviewComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_QUOTATION_PREVIEW,
        component: AdvocateQuotationPreviewComponent,
      },
    ],
    data: { title: "Quotation Preview - Auxcon" },
  },
  {
    path: PATH_AGE_ANALYSIS_CUSTOMER_INVOICE_PREVIEW + "/:id",
    component: AgeAnalysisInvoiceComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_AGE_ANALYSIS_CUSTOMER_INVOICE_PREVIEW,
        component: AgeAnalysisInvoiceComponent,
      },
    ],
    data: { title: "Invoice Preview - Auxcon" },
  },
  {
    path: PATH_RINVOICE_PREVIEW + "/:id",
    component: RIndividualInvoiceComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_RINVOICE_PREVIEW,
        component: RIndividualInvoiceComponent,
      },
    ],
    data: { title: "Invoice Preview - Auxcon" },
  },
  {
    path: PATH_THE_INDIVIDUAL_CUSTOMER + "/:id",
    component: TheIndividualCustomerComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_THE_INDIVIDUAL_CUSTOMER,
        component: TheIndividualCustomerComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_THE_INVOICE_PREVIEW + "/:id",
    component: TheInvoicePreviewComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_THE_INVOICE_PREVIEW,
        component: TheInvoicePreviewComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_MANAGE_BANK_ACCOUNTS,
    component: ManageBankAccountsComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_MANAGE_BANK_ACCOUNTS,
        component: ManageBankAccountsComponent,
      },
    ],
    data: { title: "Process - Auxcon" },
  },
  {
    path: PATH_SEARCH_INVOICE_PREVIEW + "/:id",
    component: SearchedInvoicePreviewComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_SEARCH_INVOICE_PREVIEW,
        component: SearchedInvoicePreviewComponent,
      },
    ],
    data: { title: "Reports - Auxcon" },
  },
  {
    path: PATH_CUSTOMER_INVOICE_PREVIEW + "/:id",
    component: ReportCustomerInvoicePreviewComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_CUSTOMER_INVOICE_PREVIEW,
        component: ReportCustomerInvoicePreviewComponent,
      },
    ],
    data: { title: "Invoice Preview - Auxcon" },
  },
  {
    path: PATH_ADVOCATE_INVOICE_PREVIEW + "/:id",
    component: AdvocateInvoicePreviewComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ADVOCATE_INVOICE_PREVIEW,
        component: AdvocateInvoicePreviewComponent,
      },
    ],
    data: { title: "Invoice Preview - Auxcon" },
  },
  {
    path: PATH_MATTERS,
    component: AdvocateMattersComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_MATTERS,
        component: AdvocateMattersComponent,
      },
    ],
    data: { title: "Matters - Auxcon" },
  },
  {
    path: PATH_MATTERS + "/:id",
    component: AdvocateMattersComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_MATTERS + "/:id",
        component: AdvocateMattersComponent,
      },
    ],
    data: { title: "Matters - Auxcon" },
  },
  {
    path: PATH_QUOTATION,
    component: AdvocateQuotationComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_QUOTATION,
        component: AdvocateQuotationComponent,
      },
    ],
    data: { title: "Quotation - Auxcon" },
  },
  {
    path: PATH_QUOTATION + "/:id",
    component: AdvocateQuotationComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_QUOTATION + "/:id",
        component: AdvocateQuotationComponent,
      },
    ],
    data: { title: "Quotation - Auxcon" },
  },
  {
    path: PATH_INVOICE_EMAIL + "/:id/:serviceId",
    component: AdvocateInvoiceEmailComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_INVOICE_EMAIL + "/:id/:serviceId",
        component: AdvocateInvoiceEmailComponent,
      },
    ],
    data: { title: "Invoice Email - Auxcon" },
  },
  {
    path: PATH_QUOTATION_EMAIL + "/:id",
    component: AdvocateQuotationEmailComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_QUOTATION_EMAIL + "/:id",
        component: AdvocateQuotationEmailComponent,
      },
    ],
    data: { title: "Invoice Quotation - Auxcon" },
  },
  {
    path: PATH_INVOICE_REVIEW + "/:id/:serviceId",
    component: AdvocateInvoiceReviewComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_INVOICE_REVIEW,
        component: AdvocateInvoiceReviewComponent,
      },
    ],
    data: { title: "Invoice Review - Auxcon" },
  },
  {
    path: PATH_EDIT_FEE_TEMPLATES,
    component: AdvocateEditFeeTemplatesComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_EDIT_FEE_TEMPLATES,
        component: AdvocateEditFeeTemplatesComponent,
      },
    ],
    data: { title: "Fee Edit - Auxcon" },
  },
  {
    path: PATH_EDIT_MY_TEAM,
    component: AdvocateEditMyTeamComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_EDIT_MY_TEAM,
        component: AdvocateEditMyTeamComponent,
      },
    ],
    data: { title: "My Team Edit - Auxcon" },
  },
  {
    path: PATH_ATTORNEY_DASHBOARD,
    component: AttorneyDashboardComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ATTORNEY_DASHBOARD,
        component: AttorneyDashboardComponent,
      },
    ],
    data: { title: "Age analysis - Auxcon" },
  },
  {
    path: PATH_ATTORNEY_AGE_ANALYSIS,
    component: AttorneyAgeAnalysisComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ATTORNEY_AGE_ANALYSIS,
        component: AttorneyAgeAnalysisComponent,
      },
    ],
    data: { title: "Age analysis - Auxcon" },
  },
  {
    path: PATH_ATTORNEY_ALL_CUSTOMER,
    component: AttorneyAllCustomerComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ATTORNEY_ALL_CUSTOMER,
        component: AttorneyAllCustomerComponent,
      },
    ],
    data: { title: "All customer - Auxcon" },
  },
  {
    path: PATH_ADVOCATE_DETAIL,
    component: AdvocateDetailComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ADVOCATE_DETAIL,
        component: AdvocateDetailComponent,
      },
    ],
    data: { title: "Advocate statement - Auxcon" },
  },
  {
    path: PATH_ADVOCATE_DETAIL + "/:id/" + PATH_INVOICE,
    component: InvoiceComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ADVOCATE_DETAIL,
        component: InvoiceComponent,
      },
    ],
    data: { title: "Advocate statement - Auxcon" },
  },
  {
    path: PATH_ADVOCATE_DETAIL + "/:id/:advocateId",
    component: AdvocateDetailComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_ADVOCATE_DETAIL,
        component: AdvocateDetailComponent,
      },
    ],
    data: { title: "Advocate statement - Auxcon" },
  },
  {
    path: PATH_UNPAID_INVOICES + "/:id",
    component: UnpaidInvoicesComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_UNPAID_INVOICES,
        component: UnpaidInvoicesComponent,
      },
    ],
    data: { title: "Unpaid invoices - Auxcon" },
  },
  {
    path: PATH_PROCESS_INVOICES,
    component: AdvocateProcessInvoicesComponent,
    canActivate: [AuthenticationGuard, NavigationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: PATH_PROCESS_INVOICES,
        component: AdvocateProcessInvoicesComponent,
      },
    ],
    data: { title: "Process invoices - Auxcon" },
  },
  {
    path: PATH_SIGN_IN,
    component: AdvocateSignInComponent,
    data: { title: "Sign in - Auxcon" },
  },
  {
    path: PATH_SIGN_IN_LOADING,
    component: AdvocateSignInLoadingComponent,
    data: { title: "Sign in - Auxcon" },
  },
  {
    path: PATH_SIGN_OUT,
    component: AdvocateSignOutComponent,
    data: { title: "Sign out - Auxcon" },
  },
  {
    path: PATH_CANCELLED_SUBSCRIPTION,
    component: CancelledSubscriptionComponent,
    data: { title: 'Inactive Profile - Auxcon' }
  },
  {
    path: PATH_GCB_RESPONSE_HANDLER + "/:id",
    component: GCBResponseHandlerComponent,
    data: { title: 'GCB Response Handler - Auxcon' }
  },
  {
    path: PATH_USER_SINGUP + "/:id",
    component: UserSingupComponent,
    data: { title: "Sign up - Auxcon" },
  },
  {
    path: PATH_BLACKLIST_PDF_VIEWER,
    component: BlacklistPdfViewerComponent,
    data: { title: "Black List - Auxcon" },
  },
  {
    path: '**',
    component: AdvocateSignInComponent,
    data: { title: "Sign in - Auxcon" },
  },
  // {
  //   path: PATH_AD_HOC_COLLECTIONS + "/:Id",
  //   component: AdHocInstructionComponent,
  //   // canActivate: [AuthenticationGuard, NavigationGuard],
  //   // canActivateChild: [AuthenticationGuard],
  //   data: { title: "Ad-Hoc Request - Auxcon" },
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { } // end AppRoutingModule{}
