import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatterQuotationHandle, MatterQuotationDTO } from '../../../services/quotation/quotation.service';
// import { OnChanges } from '@angular/core/src/metadata/lifecycle_hooks';

const MIN_COMMENT_LENGTH = 5;
const MAX_COMMENT_LENGTH = 500;
const TITLE_PRFIX = 'Delete quotation ';

@Component({
  selector: 'app-delete-multiple-quotation-modal',
  templateUrl: './delete-multiple-quotation-modal.component.html',
  styleUrls: ['./delete-multiple-quotation-modal.component.scss']
})
export class DeleteMultipleQuotationModalComponent implements OnInit, OnChanges {

  @Input() isVisible: boolean;
  @Input() quotation: MatterQuotationHandle[];
  @Output() closeModal: EventEmitter<string> = new EventEmitter();
  @Output() confirmDelete: EventEmitter<any> = new EventEmitter();

  title: string = TITLE_PRFIX;
  matterDescription: MatterQuotationDTO[] = [];
  comments: string;
  isDeleteable: boolean;
  numberOfCharactersLeft = 500;
  exceedsMaxCommentLength = false;

  constructor() { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (this.quotation) {
      this.quotation.forEach(s => this.matterDescription.push(s.data));
    }
  } // end ngOnChange()

  validate() {
    let isOk = true;
    this.numberOfCharactersLeft = MAX_COMMENT_LENGTH - this.comments.length;

    if (this.comments.length < MIN_COMMENT_LENGTH) {
      isOk = false;
    }

    if (this.comments.length > MAX_COMMENT_LENGTH) {
      this.exceedsMaxCommentLength = true;
      isOk = false;
    } else {
      this.exceedsMaxCommentLength = false;
    }

    this.isDeleteable = isOk;
  } // end validate()

  dismiss() {
    this.comments = '';
    this.validate();
    this.quotation = [];
    this.matterDescription = [];
    this.closeModal.emit('delete-multiple-quotation');
  } // end dismiss()

  confirm() {
    this.matterDescription.forEach(mat => {
      this.confirmDelete.emit({
        quotation: mat,
        comments: this.comments
      });
    });
    this.dismiss();
  } // end confirmDelete
} // end DeleteMultipleQuotationModalComponent{}
