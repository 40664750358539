<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>{{title}}</h2>
                <button class="modal-dismiss" (click)="dismiss()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body form">
                <div class="row">
                    <div class="form-group">
                        <label for="entity-type">Entity Type</label>
                    </div>
                    <div class="form-group">
                        <select class="form-control"
                                id="entity-type"
                                [(ngModel)]="inviteUsers_Staging.EntityType"
                                (change)="onSelectedEntityType($event)">
                            <option [value]="null">Choose a Entity Type</option>
                            <option
                                *ngFor="let type of entityTypes"
                                [value]="type.name"
                                [selected]="inviteUsers_Staging.EntityType === type.name">{{type.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="row" *ngIf="selectedEntity === 'Regional Bar'">
                    <div class="form-group">
                        <label for="regional-bar">Regional Bar</label>
                    </div>
                    <div class="form-group">
                        <select class="form-control" 
                                id="regional-bar"
                                [(ngModel)]="inviteUsers_Staging.EntityReference"
                                (change)="onSelectedEntity($event)">
                            <option [value]="null">Select Regional Bar</option>
                            <option
                                *ngFor="let entity of entityContact"
                                [value]="entity.FullName"
                                [selected]="entity.FullName === inviteUsers_Staging.EntityReference">{{entity.FullName}}</option>
                        </select>
                    </div>
                </div>

                <!-- <div class="row" *ngIf="selectedEntity === 'GCB'">
                    <div class="form-group">
                        <label for="entity-type">GCB</label>
                    </div>
                    <div class="form-group">
                        <select class="form-control" 
                                id="entity-type"
                                [(ngModel)]="inviteUsers_Staging.EntityReference"
                                (change)="onSelectedEntity($event)">
                            <option [value]="null">Select GCB</option>
                            <option
                                *ngFor="let entity of entityContact"
                                [value]="entity.FullName"
                                [selected]="entity.FullName === inviteUsers_Staging.EntityReference">{{entity.FullName}}</option>
                        </select>
                    </div>
                </div> -->

                <!-- <div class="row" *ngIf="selectedEntity === 'Ad-hoc'">
                    <div class="form-group">
                        <label for="entity-type">Ad-hoc</label>
                    </div>
                    <div class="form-group">
                        <select class="form-control" 
                                id="entity-type"
                                [(ngModel)]="inviteUsers_Staging.EntityReference"
                                (change)="onSelectedEntity($event)">
                            <option [value]="null">Select Ad-hoc</option>
                            <option
                                *ngFor="let entity of entityContact"
                                [value]="entity.FullName"
                                [selected]="entity.FullName === inviteUsers_Staging.EntityReference">{{entity.FullName}}</option>
                        </select>
                    </div>
                </div> -->

                <div class="row">
                    <div class="form-group">
                        <label for="email">Email Address</label>
                    </div>
                    <div class="form-group">
                        <input
                            class="form-group"
                            required
                            id="email-address"
                            placeholder="Email Address"
                            [(ngModel)]="inviteUsers_Staging.EmailAddress">
                    </div>
                </div>

                <div class="row" *ngIf="selectedEntity === 'Ad-hoc'">
                    <div class="form-group">
                        <label for="user-type">User Type</label>
                    </div>
                    <div class="form-group">
                        <select class="form-control"
                                id="user-type"
                                [(ngModel)]="inviteUsers_Staging.UserType">
                            <option [value]="null">Choose a User Type</option>
                            <option
                                *ngFor="let type of userTypes"
                                [value]="type.value"
                                [selected]="inviteUsers_Staging.UserType === type.value">{{type.name}}</option>
                        </select>
                    </div>
                </div>
                <!-- <div class="row" *ngIf="inviteUsers_Staging.EntityType === 'Ad-hoc'">
                    <div class="form-group">
                        <label for="entity-type">Type</label>
                    </div>
                    <div class="form-group">
                        <select class="form-control" id="entity-type">
                            <option [value]="null">Choose a Entity Type</option>
                            <option
                                *ngFor="let type of entityTypes"
                                value="type.id">type.name</option>
                        </select>
                    </div>
                </div> -->

                <div class="row">
                    <div class="form-group">
                        <label for="invite-button"></label>
                    </div>
                    <div class="form-group">
                        <button 
                            class="btn btn-success"
                            [class.disabled]="!canSendInvite"
                            [disabled]="!canSendInvite"
                            (click)="onSendInvite()">Invite</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-success-or-error
  *ngIf="onSuccessShow"
  [isVisible]="onSuccessShow"
  [isSuccessMessage]="isSuccessMessage"
  [title]="messageTitle"
  [message]="inviteMessage"
  (cancel)="onSuccessMessageHide()"></app-success-or-error>