import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
    ApiService
} from '../api.service';
import { catchError, retry } from 'rxjs/operators';

export interface CheckSageUser {
    SageUserName: string;
    Password: string;
    CompanyId: string;
}

export interface AdvocateSageSetupResponse {
    ContactId: number;
    CompanyId: number;
    CompanyName : string;
    isAlreadySynced : boolean;
    BankDetails: AdvocateSageBankDetails[];
    VatDetails: AdvocateSageVatDetails[];
    AccountTypeDetails: SageAccountTypeDetails[];
    SageUserName: string;
    SagePassword: string;
    LastTransactionPostedDate: Date;
    LastTransactionPostedDateStr : string;
}

export interface SageAccountTypeDetails {
    AccountType: string;
    SageAccountTypeId: number;
    Message: string;
    IsMatched: boolean;
}

export interface AdvocateSageBankDetails {
    AuxconBankId: number;
    SageBankId: number;
    Message: string;
    IsMatched: boolean;
}
export interface AdvocateSageVatDetails {
    AuxconVatId: string;
    SageVatId: number;
    Message: string;
    IsMatched: boolean;
}

@Injectable()
export class UserSageValidationService {
    constructor(
        private http: HttpClient,
        private api: ApiService
    ) {

    }

    getCurrentAdvocateSageDetails(): Observable<any>  {
        const requestData = {
            contactId :  Number(this.api.serviceProviderID),
        }
        return this.http.post<any>(
            this.api.endpoints.getCurrentAdvocateSageDetails, requestData,
            this.api.httpOptions
        ).pipe(
            retry(0),
            catchError(this.api.handleError)
        );
    }

    removeAdvocateSageSetup(): Observable<any>  {
        const requestData = { contactId :  Number(this.api.serviceProviderID) }
        return this.http.post<any>(
            this.api.endpoints.removeAdvocateSageSetup, requestData,
            this.api.httpOptions
        ).pipe(
            retry(0),
            catchError(this.api.handleError)
        );
    }

    checkSageUserValid(sageUserName: string, sagePassword: string): Observable<any> {
        const requestData = {
            serviceProvider: Number(this.api.serviceProviderID),
            sageUserName: sageUserName,
            sagePassword: sagePassword,
        }
        return this.http.post<any>(
            this.api.endpoints.sageUserValidation, requestData,
            this.api.httpOptions
        ).pipe(
            retry(0),
            catchError(this.api.handleError)
        );
    }

    advocateSageSetup(sageUserName: string, sagePassword: string, companyId: number): Observable<any> {
        const requestData = {
            serviceProvider: Number(this.api.serviceProviderID),
            sageUserName: sageUserName,
            sagePassword: sagePassword,
            companyId: companyId,
            contactId :  Number(this.api.serviceProviderID),
        }
        return this.http.post<any>(
            this.api.endpoints.advocateSageSetup, requestData,
            this.api.httpOptions
        ).pipe(
            retry(0),
            catchError(this.api.handleError)
        );
    }

    saveAdvocateSageUser(requestData: AdvocateSageSetupResponse, UserName: string, Password: string,): Observable<any> {
        requestData.SageUserName = UserName;
        requestData.SagePassword = Password;
        return this.http.post<any>(
            this.api.endpoints.saveAdvocateSageUser, requestData,
            this.api.httpOptions
        ).pipe(
            retry(0),
            catchError(this.api.handleError)
        );
    }

}