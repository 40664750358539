<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>

<div class="container-fluid">
  <div class="frame">
    <div class="tile">
      <!-- Invoice details -->
      <div class="well">
        <h2 class="well-title">Invoice date</h2>
        <div class="well-row invoice-date">
          <!-- <span class="well-row-cell">Invoice date</span> -->
          <!-- <span>{{invoice.InvoiceTotal | currency:'R '}}</span> -->
          <!-- <input
            type="text"
            class="form-control"
            [class.has-error]="hasInvoiceDateLessThanError || isFutureDated"
            bsDatepicker
            [value]="workingInvoiceDetails.InvoiceDate | date:'yyyy-MM-dd'"
            (ngModel)="workingInvoiceDetails.InvoiceDate"
            (bsValueChange)="onDateChange(invoiceDetails, $event)"
            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
            placement="right"
            [class.disabled]="workingInvoiceDetails.InvoiceNo !== 'AUX000000' && workingInvoiceDetails.InvoiceNo !== null && workingInvoiceDetails.InvoiceDate !== null"
              [disabled]="workingInvoiceDetails.InvoiceNo !== 'AUX000000' && workingInvoiceDetails.InvoiceNo !== null && workingInvoiceDetails.InvoiceDate !== null">
          </div> -->

          <input
              *ngIf="!isPracticeManager && workingInvoiceDetails.InvoiceNo != 'AUX000000'"
              type="text"
              [value]="parseInvoiceDate(workingInvoiceDetails.InvoiceDate) | date:'yyyy-MM-dd'"
              readonly>

            <input
              *ngIf="isPracticeManager || workingInvoiceDetails.InvoiceNo == 'AUX000000'"
              type="text"
              class="form-control"
              [class.has-error]="hasInvoiceDateLessThanError || isFutureDated"
              bsDatepicker
              [bsValue]="parseInvoiceDate(workingInvoiceDetails.InvoiceDate)"
              (ngModel)="workingInvoiceDetails.InvoiceDate=$event.target.value"
              (bsValueChange)="onDateChange(invoiceDetails, $event)"
              [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
              placement="right">
          </div>
           <!-- #end .well-row -->
          <p *ngIf="hasInvoiceDateLessThanError" class="text-danger">The invoice date cannot be before the last line date.</p>
          <p *ngIf="isFutureDated" class="text-danger">The system does not allow future dating of Invoices.</p>
        <!-- <div class="well-row">
          <span class="well-row-cell">Invoice date</span>
          <span>{{invoice.InvoiceDate | date:'yyyy-MM-dd'}}</span> -->
        </div> 

      <!-- Matter details -->
      <div class="well">
        <h2 class="well-title">Invoice note</h2>
        <div class="well-row">
          <textarea
            #notes 
            id="notes" 
            placeholder="Enter your note to attorney here"
            [(ngModel)]="workingInvoiceDetails.InvoiceNote"></textarea>
          <!-- <textarea
            readonly
            *ngIf="hasNotes"
            #notes 
            id="notes" 
            placeholder="Enter your note to attorney here"
            [(ngModel)]="workingInvoiceDetails.InvoiceNote"></textarea> -->
      <!--   </div> #end .well-row -->
     <!--   </div> #end .well -->

      <!-- <div class="well">
        <div class="well-row"> -->
          
            <!-- <span></span> -->
          <!-- <button 
            class="btn btn-success" 
            [class.disabled]="!'false'" 
            [disabled]="!'false'"
            (click)="onShowSaveInvoiceModal()"></button> -->

        </div><!-- #end .well-row -->
      </div> <!-- #end .well -->


      
      

    </div> <!-- #end .tile-->

    <div class="tile">
      <!-- Invoice PDF -->
      <div style="width:100%; height:700px;">
        <ng2-pdfjs-viewer 
          #pdfViewer
          [externalWindow]="false" 
          [openFile]="true" 
          [viewBookmark]="true" 
          [download]="true"
          [downloadFileName]="fileName"></ng2-pdfjs-viewer>
      </div>
    </div> <!-- #end .tile-->

  </div> <!-- #end .frame -->

  <!-- <div class="well">
    <div class="btn-group">
      <span
        class="clickable"
        (click)="resendInvoice()">Resend invoice</span>
        <i class="fa fa-angle-down fa-lg clickable"></i>
    </div>  #end .btn-group 
  </div> #end .well -->
<div class="well footer-buttons">
  <!-- <h2 class="well-title">Invoice note</h2> -->
  <!-- <div class="well-row"> -->
    <button style="width: 30%; align-self: left;" class="btn btn-danger" (click)="onEditMatterDetails()">Edit matter</button>
    <!-- <button class="btn btn-success" style="width: 30%;" (click)="onSaveInvoiceDetails()">Save invoice</button> -->
    <!-- <button style="width: 100%" *ngIf="isAssistant" class="btn btn-success" (click)="onSaveToAdvocate()">Send invoice to advocate</button> -->
    <span class="btn-group" *ngIf="isAssistant && !isAssistantToLawfirm">
      <span>
        <span style="padding: 0px !important;"
          (click)="onSaveToAdvocate()"><p>Send invoice to advocate</p>
        </span>
        <i
          class="fa fa-angle-down clickable"
          (click)="toggleMenu()"></i>
        </span>
    </span>
    <span class="btn-group" *ngIf="isAssistant && isAssistantToLawfirm">
      <span>
        <span style="padding: 0px !important;"
          (click)="onSaveToAdvocate()"><p>Send invoice to attorney</p>
        </span>
        <i
          class="fa fa-angle-down clickable"
          (click)="toggleMenu()"></i>
        </span>
    </span>
    
    <button class="btn btn-success" style="width: 30%; align-self: right;" [class.disabled]="!isSaveable" [disabled]="!isSaveable"
      (click)="onSaveInvoiceDetails()">Send invoice</button>

 <!-- </div> #end .well-row -->
</div> <!-- #end .well -->
<div>
  <app-send-invoice-to #popupMenu [isVisible]="showMenuPopup" (isAdvocate)="showRecipientDialog()"></app-send-invoice-to>
</div>

<!-- <div class="well" *ngIf="isAssistant">
  <div class="well-row">
    <button style="width: 100%" class="btn btn-success">Send to advocate</button>
    <span></span>
     <button 
            class="btn btn-success" 
            [class.disabled]="!'false'" 
            [disabled]="!'false'"
            (click)="onShowSaveInvoiceModal()"></button>

  </div>#end .well-row -->
<!-- </div> #end .well -->
</div> <!-- #end .container-fluid -->

<app-save-invoice-modal 
  [isVisible]="showSaveInvoicePrompt" 
  (cancel)="onHideSaveInvoiceModal()"
  (confirm)="onSendViaEmail()"></app-save-invoice-modal>

<app-prompt-unsaved-changes-modal 
  message="You have unsaved changes. Are you sure you want to leave the page?"
  [isVisible]="showUnsavedChangesPrompt" 
  (cancel)="onHideUnsavedChangesPrompt()" (continue)="leavePage()">
</app-prompt-unsaved-changes-modal>

<app-create-recipient-modal
  _title="Send invoice to:"
  _btnText="Send"
  [isVisible]="showRecipientsModal"
  (closeModal)="onCloseModal()"
  (saveRecipients)="onSendMessage($event)"></app-create-recipient-modal>