<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
      <div class="modal-dialog">
  
        <div class="modal-header">
          <h2>{{title}}</h2>
          <button class="modal-dismiss" (click)="onCancel()">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!--#end .modal-header -->
  
        <div class="modal-body">
          <div class="container-fluid">
            <!-- <aside>
              <i class="fa fa-exclamation-triangle fa-lg text-warning"></i>
            </aside> -->
            <div class="row">
              <i class="fal fa-exclamation-triangle"></i>
            </div>
            <div class="message">
              <p>{{message}}</p>
            </div>
          </div> <!-- #end .container-fluid -->
        </div>
        <!--#end .modal-body -->
  
        <div class="modal-footer">
          <!-- <button class="btn btn-danger" (click)="onCancel()">{{btnTextCancel}}</button> -->
          <button class="btn btn-info" (click)="onConfirm()">{{btnTextConfirm}}</button>
          <button class="btn btn-success" (click)="onContinue()">{{btnTextContinue}}</button>
          <!-- <span></span> -->
          <!-- <span></span>-->
        </div>
        <!--#end .modal-footer -->
  
      </div>
      <!--#end .modal-dialog -->
    </div>
    <!--#end .modal-content -->
  </div>
  <!--#end .modal -->