<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>

<app-matter-quotation-details
  *ngIf="!isOnLoading"
  [(workingMatterQuotation)]="workingMatterQuotation"
  [lawFirms]="lawFirms"
  [attorneys]="attorneys"
  [plaintiffs]="plaintiffs"
  [otherClients]="otherClients"
  [defendants]="defendants"
  [attorneyRefs]="attorneyRefs"
  [instructions]="instructions"
  [userProfile]="userProfile"></app-matter-quotation-details>

  <!-- [documentTypes]="documentTypes" -->
  <!-- [briefDocuments]="briefDocuments" -->
  <app-quotation-matter-fees
    *ngIf="!isOnLoading" 
    (feeDelete)="onFeeDelete($event)"
    (feeActualDelete)="onActualFeeDelete($event)"
    [(workingQuotationFees)]="workingQuotationFees"
    [(workingActualQuotationFees)]="workingActualQuotationFees"
    (workingFeesChange)="onWorkingQuoteChange($event)"
    (workingActualFeesChange)="onWorkingActualQuoteChange($event)"
    [userProfile]="userProfile"
    [subCategories]="subCategories"
    (selectedTab)="onSelectedTab($event)"
    [(workingMatterQuotation)]="workingMatterQuotation"></app-quotation-matter-fees>


  <div
    class="row matter-actions"
    *ngIf="!isOnLoading">
    <button
      *ngIf="!quotationFeeReadOnly"
      class="btn btn-danger"
      [class.disabled]="!isDeletable"
      [disabled]="!isDeletable"
      (click)="promptDelete()">Delete this quotation</button>
    <button
      *ngIf="quotationFeeReadOnly"
      class="btn btn-danger"
      [class.disabled]="!isDeletable"
      [disabled]="!isDeletable"
      (click)="promptDelete()">Delete this matter</button>
    <div></div>
    <button
      *ngIf="selectedFeeTab === 'actual-fees'"
      class="btn btn-success"
      [class.disabled]="!quotationFeeReadOnly"
      [disabled]="!quotationFeeReadOnly"
      (click)="onAddActualNote()">Add invoice note</button>
    <button
      *ngIf="selectedFeeTab === 'quotation-fees' && !quotationFeeReadOnly"
      class="btn btn-success"
      [class.disabled]="!canAddNote"
      [disabled]="!canAddNote"
      (click)="onAddInvoiceNote()">Add quotation note</button>
    <button
      *ngIf="selectedFeeTab === 'quotation-fees' && quotationFeeReadOnly"
      class="btn btn-success"
      (click)="onAddInvoiceNote()">Add invoice note</button>
    <div></div>
    <button
      *ngIf="selectedFeeTab === 'actual-fees'"
      class="btn btn-info"
      [class.disabled]="!isActualGeneratable"
      [disabled]="!isActualGeneratable"
      (click)="onGenerateActualFees()">Generate invoice</button>
    <!-- <button
      *ngIf="selectedFeeTab === 'quotation-fees' && !quotationFeeReadOnly"
      class="btn btn-info"
      [class.disabled]="!isGeneratable"
      [disabled]="!isGeneratable"
      (click)="generateInvoice()">Generate quotation</button> -->

      <!-- <div class="btn-group" role="group" aria-label="Basic example">
        <button
          type="button"
          [class.disabled]="!isGeneratable"
          [disabled]="!isGeneratable"
          class="btn btn-info"
          (click)="generateInvoice()">Generate</button>
        <button
          type="button"
          class="btn btn-info"
          (click)="onManualAcceptOrReject('Accepted')">Accept</button>
        <button
          type="button"
          class="btn btn-info"
          (click)="onManualAcceptOrReject('Rejected')">Reject</button>
      </div> -->
<div class="btn-group" dropdown [dropup]="isDropup" *ngIf="selectedFeeTab === 'quotation-fees' && !quotationFeeReadOnly">
  <button
    id="button-dropup"
    dropdownToggle
    [class.disabled]="!isGeneratable"
    [disabled]="!isGeneratable"
    type="button"
    class="btn btn-info dropdown-toggle"
    aria-controls="dropdown-dropup">Generate quotation <span class="caret"></span>
  </button>
  <ul id="dropdown-dropup" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-dropup">
    <li role="menuitem"><a style="width: 185px; text-align: center;" (click)="generateInvoice()" class="dropdown-item">GENERATE QUOTATION</a></li>
    <li role="menuitem" [class.disabled]="!canAcceptReject"><a style="width: 185px; text-align: center;" (click)="onManualAcceptOrReject('Accepted')" class="dropdown-item" [class.disabled]="!canAcceptReject">ACCEPT QUOTATION</a></li>
    <li role="menuitem" [class.disabled]="!canAcceptReject"><a style="width: 185px; text-align: center;" (click)="onManualAcceptOrReject('Rejected')" class="dropdown-item" [class.disabled]="!canAcceptReject">REJECT QUOTATION</a></li>
  </ul>
</div>

    <button
      *ngIf="selectedFeeTab === 'quotation-fees' && quotationFeeReadOnly"
      class="btn btn-info"
      [class.disabled]="!isGeneratable"
      [disabled]="!isGeneratable"
      (click)="generateInvoice()">Generate invoice</button>
    <div></div>
    <!-- <button
      *ngIf="userProfileService.userProfile.serviceProviderID === 28883"
      class="btn btn-info"
      [class.disabled]="!isGeneratable" 
      [disabled]="!isGeneratable"
      (click)="onGenerateInvoiceLineToExcel()">Export</button> -->
    <div
      *ngIf="userProfileService.userProfile.serviceProviderID === 28883"></div>
    <button
      *ngIf="selectedFeeTab === 'actual-fees'"
      class="btn btn-success"
      [class.disabled]="!isActualSaveable" 
      [disabled]="!isActualSaveable" 
      (click)="saveActual()">Save this matter</button>
    <button
      *ngIf="selectedFeeTab === 'quotation-fees' && !quotationFeeReadOnly"
      class="btn btn-success"
      [class.disabled]="!isSaveable" 
      [disabled]="!isSaveable" 
      (click)="save()">Save this quotation</button>
    <button
      *ngIf="selectedFeeTab === 'quotation-fees' && quotationFeeReadOnly"
      class="btn btn-success"
      [class.disabled]="!isSaveable" 
      [disabled]="!isSaveable" 
      (click)="save()">Save this matter</button>
  </div>
  <app-prompt-unsaved-changes-modal
    message="You have unsaved changes. Are you sure you want to leave the page?"
    [isVisible]="showUnsavedChangesPrompt"
    (cancel)="onHideUnsavedChangesPrompt()"
    (continue)="leavePage()">
  </app-prompt-unsaved-changes-modal>

  <app-prompt-unsaved-changes-modal
    title="Fees exceeded quotation amount"
    message="Your fees has exceeded your agreed quotation amount. Do you wish to proceed?"
    [isVisible]="showFeeTotalExceedQuotedPrompt"
    (cancel)="onHideFeeTotalExceedQuotedPrompt()"
    btnTextCancel="No, Cancel"
    btnTextContinue="Yes, Save"
    (continue)="ignoreAndSave()"></app-prompt-unsaved-changes-modal>
  
  <app-prompt-unsaved-changes-modal
    title="Generate actual fees"
    message="You are about to convert your actual fees into an invoice. Do you wish to proceed?"
    [isVisible]="showGenerateActualFeesPrompt"
    (cancel)="onHideGenerateActualFeesPrompt()"
    btnTextCancel="No, Cancel"
    btnTextContinue="Yes, Proceed"
    (continue)="CopyActualFees ()"></app-prompt-unsaved-changes-modal>

  <app-prompt-unsaved-changes-modal
    title="Generate quoted fees"
    message="You are about to convert your quoted fees into an invoice. Do you wish to proceed?"
    [isVisible]="showGenerateQuotationFeesPrompt"
    (cancel)="onHideGenerateQuotationFeesPrompt()"
    btnTextCancel="No, Cancel"
    btnTextContinue="Yes, Proceed"
    (continue)="CopyFromQuotationFees ()"></app-prompt-unsaved-changes-modal>

  <app-add-quotation-note
    *ngIf="showAddQuotationNoteModal"
    [isVisible]="showAddQuotationNoteModal"
    [quotationSummary]="quotationSummary"
    [workingMatterQuotation]="workingMatterQuotation"
    (closeModal)="closeModal('add-quotation-note')"></app-add-quotation-note>
  
  <app-add-actual-quotation-note
    *ngIf="showAddActualQuotationNoteModal"
    [isVisible]="showAddActualQuotationNoteModal"
    [quotationSummary]="quotationSummary"
    [workingMatterQuotation]="workingMatterQuotation"
    (closeModal)="closeModal('add-actual-quotation-note')"></app-add-actual-quotation-note>

  <app-delete-quotation-modal
    [quotation]="selectedMatterQuotation"
    [isVisible]="showDeleteQuotationModal"
    (closeModal)="closeModal('delete-quotation')"
    (confirmDelete)="deleteQuotation($event)"></app-delete-quotation-modal>

  <app-add-quotation-accept-reject-reason
    *ngIf="showQuotationAcceptRejectReason"
    [isVisible]="showQuotationAcceptRejectReason"
    [matterQuotation]="workingMatterQuotation"
    [(manualAcceptReject)]="manualAcceptReject"
    (closeModal)="closeModal('quotation-note')"
    (saveAcceptRejectReason)="onAcceptReject($event)"></app-add-quotation-accept-reject-reason>